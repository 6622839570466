import PropTypes from 'prop-types';
import React from 'react';

import { CircleLoader, TextArea } from 'common-src/components/base';
import { Colors } from 'common-src/styles';

import Icons from 'src/assets/Icons';

import styles from './SendSmsInput.module.scss';

const SendSmsInput = ({ phoneNumber, text, onTextChange, error, isLoading, onSmsSendClick }) => {
  const isDisabled = !phoneNumber || isLoading || text.length === 0;

  return (
    <div className={styles.container}>
      <TextArea
        id="send-sms-input"
        classNames={[styles.inputContainer, 'm-b-16']}
        inputClassNames={[styles.input, error ? styles.error : '']}
        fixedRows={3}
        onTextChange={onTextChange}
        value={text}
        placeholder="SMS text"
        description={`Used characters: ${text.length}`}
        withError={!!error}
        errorText={error}
        size="small"
        disabled={!phoneNumber || isLoading}
      />
      <p
        className={[styles.button, isDisabled ? styles.disabled : ''].join(' ')}
        role="presentation"
        onClick={() => {
          if (!isDisabled) {
            onSmsSendClick?.();
          }
        }}
      >
        {!isLoading && <img className={styles.icon} src={Icons.sendIcon} alt="send-sms-icon" />}
        {isLoading && <CircleLoader color={Colors.white} strokeWidth={2} circleRadius={9} />}
      </p>
    </div>
  );
};

SendSmsInput.propTypes = {
  phoneNumber: PropTypes.string,
  text: PropTypes.string,
  onTextChange: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onSmsSendClick: PropTypes.func,
};

export default SendSmsInput;
