export const ContactType = {
  Voice: 'voice',
  QueueCallback: 'queue_callback',
  Chat: 'chat',
  Task: 'task',
};

export const InitiationMethod = {
  Outbound: 'outbound',
  Inbound: 'inbound',
  Transfer: 'transfer',
  Callback: 'callback',
  QueueTransfer: 'queue_transfer',
};

export const Status = {
  Connecting: 'connecting',
  Error: 'error',
  Ended: 'ended',
  Init: 'init',
  Incoming: 'incoming',
  Pending: 'pending',
  Connected: 'connected',
  Missed: 'missed',
  Rejected: 'rejected',
  Paused: 'paused',
};
