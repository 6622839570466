import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { closeAlert, getAlerts } from 'src/features/alerts';
import useCustomSelector from 'src/hooks/useCustomSelector';

import Alert from './Alert';
import styles from './AlertGroup.module.scss';

const AlertGroup = () => {
  const dispatch = useDispatch();
  const [prevAlertsCount, setPrevAlertsCount] = useState(0);

  const alerts = useCustomSelector((state) => getAlerts(state));

  useEffect(() => {
    setPrevAlertsCount(alerts.length);
  }, [alerts]);

  const renderAlert = ({ id, type, title, message, isAutoDismiss }) => (
    <Alert
      id={`alert-${type}-${id}`}
      key={id}
      type={type}
      title={title}
      message={message}
      isAutoDismiss={isAutoDismiss}
      onClose={() => dispatch(closeAlert(id))}
    />
  );

  return (
    !_.isEmpty(alerts) && (
      <div id="alert-container" className={styles.container}>
        {alerts.map((alert, index) => (
          <div
            key={alert.id}
            className={`${styles.alert} ${index >= prevAlertsCount ? styles.slideIn : ''}`}
          >
            {renderAlert(alert)}
          </div>
        ))}
      </div>
    )
  );
};

export default AlertGroup;
