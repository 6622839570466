import orm from 'common-src/orm';

export const singleModelSelector = (state, modelName, id) => {
  const sess = orm.session(state.orm);
  return sess[modelName]?.withId(id);
};

export const allModelsSelector = (state, modelName, query = {}) => {
  const sess = orm.session(state.orm);
  return sess[modelName]?.all().filter(query).toModelArray() || [];
};
