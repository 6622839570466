// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionsRow-module__button___JR4zj {
  padding: 5px 18px;
  font-size: 12px !important;
}

.ActionsRow-module__alert___HZvg5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/table/ActionsRow/ActionsRow.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,0BAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ActionsRow-module__button___JR4zj`,
	"alert": `ActionsRow-module__alert___HZvg5`
};
module.exports = ___CSS_LOADER_EXPORT___;
