import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PatientInteraction from 'common-src/models/PatientInteraction';
import { getPatientOncehubBookingsRequest } from 'common-src/models/PatientOncehubBooking';

import useEventsLinking from 'src/hooks/useEventsLinking';

import FormHeader from '../FormHeader';
import Conversations from './Conversations';
import styles from './EmbeddedInfo.module.scss';
import ManualTimeAddition from './ManualTimeAddition';
import ScheduledVisits from './ScheduledVisits';

const EmbeddedInfo = ({
  modules,
  timeLogs,
  onTimeLogChange,
  patientId,
  hasRelevantConversations,
  setHasRelevantConversations,
  regalTaskIds,
  setRegalTaskIds,
  awscCallIds,
  setAwscCallIds,
  isEncounterNotScheduled,
  setIsEncounterNotScheduled,
  linkedVisitId,
  setLinkedVisitId,
  documentationToEdit,
  errors,
  classNames = [],
}) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState({ linkedConversations: true, linkedVisits: true });

  const { regalTaskInfos, awscCalls, clients } = useEventsLinking(patientId);

  const hasSelectedModule = modules.find((module) => module.isSelected);

  useEffect(() => {
    dispatch(getPatientOncehubBookingsRequest({ patientId }, {}));
  }, []);

  const renderLinkedConversations = () => (
    <>
      <FormHeader
        title="Linked conversations"
        isExpanded={expanded.linkedConversations}
        onClick={() =>
          setExpanded((prev) => ({ ...prev, linkedConversations: !prev.linkedConversations }))
        }
      />
      {expanded.linkedConversations && (
        <div className={styles.wrapper}>
          <Conversations
            hasRelevantConversations={hasRelevantConversations}
            setHasRelevantConversations={setHasRelevantConversations}
            regalTaskIds={regalTaskIds}
            setRegalTaskIds={setRegalTaskIds}
            regalTaskInfos={regalTaskInfos}
            awscCallIds={awscCallIds}
            setAwscCallIds={setAwscCallIds}
            awscCalls={awscCalls}
            clients={clients}
          />
          <ManualTimeAddition
            timeLogs={timeLogs}
            onTimeLogChange={onTimeLogChange}
            errors={errors}
          />
        </div>
      )}
    </>
  );

  const renderLinkedVisits = () => (
    <>
      <FormHeader
        title="Linked visits"
        isExpanded={expanded.linkedVisits}
        onClick={() => setExpanded((prev) => ({ ...prev, linkedVisits: !prev.linkedVisits }))}
      />
      {expanded.linkedVisits && (
        <div className={styles.wrapper}>
          <ScheduledVisits
            patientId={patientId}
            isEncounterNotScheduled={isEncounterNotScheduled}
            setIsEncounterNotScheduled={setIsEncounterNotScheduled}
            linkedVisitId={linkedVisitId}
            setLinkedVisitId={setLinkedVisitId}
            documentationToEdit={documentationToEdit}
          />
        </div>
      )}
    </>
  );

  if (!hasSelectedModule) return null;

  return (
    <div id="linked-conversations-visits" className={[...classNames].join(' ')}>
      {renderLinkedConversations()}
      {renderLinkedVisits()}
    </div>
  );
};

EmbeddedInfo.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  patientId: PropTypes.number,
  hasRelevantConversations: PropTypes.bool,
  setHasRelevantConversations: PropTypes.func,
  regalTaskIds: PropTypes.array,
  setRegalTaskIds: PropTypes.func,
  awscCallIds: PropTypes.array,
  setAwscCallIds: PropTypes.func,
  timeLogs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      timeSpent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onTimeLogChange: PropTypes.func,
  isEncounterNotScheduled: PropTypes.bool,
  setIsEncounterNotScheduled: PropTypes.func,
  linkedVisitId: PropTypes.number,
  setLinkedVisitId: PropTypes.func,
  documentationToEdit: PropTypes.exact(PatientInteraction.schema),
  classNames: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
};

export default EmbeddedInfo;
