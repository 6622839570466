// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExemptionPopup-module__grid___aI6ho {
  display: grid;
  padding: 25px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}

.ExemptionPopup-module__buttonsContainer___RF19s {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/ExemptionPopup/ExemptionPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;;AAEA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `ExemptionPopup-module__grid___aI6ho`,
	"buttonsContainer": `ExemptionPopup-module__buttonsContainer___RF19s`
};
module.exports = ___CSS_LOADER_EXPORT___;
