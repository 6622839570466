import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

const useAutoSave = (delay, value, callback) => {
  const [loading, setLoading] = useState(false);
  const [savedAt, setSavedAt] = useState(null);
  const savedCallback = useRef();
  const prevValue = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(async () => {
      if (value && !_.isEqual(prevValue.current, value)) {
        setLoading(true);
        await savedCallback.current();
        setLoading(false);
        setSavedAt(new Date());
      }

      prevValue.current = value;
    }, delay);

    return () => clearInterval(id);
  }, [delay, value]);

  return { loading, savedAt };
};

export default useAutoSave;
