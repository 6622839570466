import PropTypes from 'prop-types';
import React from 'react';

import styles from './NoneView.module.scss';

const NoneView = ({ size = 2, text = 'None', classNames = [] }) => {
  let noneViewClasses = '';

  switch (size) {
    case 0:
      noneViewClasses = styles.noneTextSmall;
      break;
    case 1:
      noneViewClasses = styles.noneTextMedium;
      break;
    default:
      noneViewClasses = styles.noneTextLarge;
      break;
  }

  return (
    <p
      id="none-view"
      className={[
        styles.container,
        'vertically-centered',
        'horizontally-centered',
        'flex-column',
        ...classNames,
      ].join(' ')}
    >
      <span className={noneViewClasses}>{text}</span>
    </p>
  );
};

NoneView.propTypes = {
  size: PropTypes.number,
  text: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default NoneView;
