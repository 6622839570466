import _ from 'lodash';

import { validatePatient } from 'common-src/models/Patient';

export const catchErrors = (formData) => {
  const errors = {};

  Object.entries(formData).forEach(([field, value]) => {
    const err = validatePatient(field, value);
    if (err[field]) {
      Object.assign(errors, { [`${field}`]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};
