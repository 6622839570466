export const loadScript = (src, position, id, callback) => {
  if (!position) {
    return;
  }

  const existingScript = document.getElementById(id);

  if (existingScript) {
    if (callback && typeof callback === 'function') {
      callback();
    }
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.id = id;
  script.src = src;

  script.onload = () => {
    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  position.appendChild(script);
};
