import moment from 'moment-timezone';

import { isEmailValid } from 'common-src/utils/validationUtils';

export const getIsButtonEnabled = (data, errors, validateEmail) => {
  if (
    !data.startDate ||
    !data.endDate ||
    Object.values(data.types).every((v) => !v) ||
    Object.values(errors).some((e) => e) ||
    (validateEmail && !data.email)
  )
    return false;

  return true;
};

export const validateData = (field, value, data) => {
  let errMsg = '';

  if (field.startsWith('types')) {
    return {
      types: Object.values(data.types).every((v) => !v) ? 'Please select at least one type' : '',
    };
  }

  switch (field) {
    case 'startDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (value && moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        errMsg = 'Date should not be in the future';
      } else if (value && moment(value).isAfter(data.endDate)) {
        errMsg = 'Start date should be before end date';
      }
      break;
    case 'endDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (value && moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        errMsg = 'Date should not be in the future';
      } else if (value && moment(value).isBefore(data.startDate)) {
        errMsg = 'End date should be after start date';
      }
      break;
    case 'email':
      if (value && !isEmailValid(value)) {
        errMsg = 'Email is not valid';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};

export const getPayload = (data) => {
  const payload = { ...data, ...data.types };
  delete payload.types;

  return payload;
};

const generatePDFName = (data, patientId) => {
  const docTypes = data.types;
  let docType = '';

  switch (true) {
    case Object.values(docTypes).filter((t) => t).length > 1:
      docType = 'Documentation';
      break;
    case docTypes.chartNotes:
      docType = 'Chart_Notes';
      break;
    case docTypes.bgmReadingLogs:
      docType = 'BGM_Reading_Logs';
      break;
    case docTypes.ordersAndConsent:
      docType = 'Orders_And_Consent';
      break;
    case docTypes.rpmClaimDocumentation:
      docType = 'RPM_Claim_Documentation';
      break;
    case docTypes.emClaimDocumentation:
      docType = 'EM_Claim_Documentation';
      break;
    case docType.hawbClaimDocumentation:
      docType = 'HAWB_Claim_Documentation';
      break;
    case docType.ccmClaimDocumentation:
      docType = 'CCM_Claim_Documentation';
      break;
    default:
      docType = 'Documentation';
  }

  return `${patientId}_${docType}_${data.startDate.replace(/-/g, '')}_${data.endDate.replace(
    /-/g,
    '',
  )}__${moment().utc().format('YYYYMMDD')}`;
};

export const downLoadPDF = async (res, data, patientId) => {
  const blob = await res.blob();
  const blobObj = new Blob([blob], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blobObj);
  const downloadLink = document.createElement('a');

  downloadLink.href = url;
  downloadLink.download = generatePDFName(data, patientId);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(downloadLink);
};
