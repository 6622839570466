import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Menu, Tooltip } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import { deleteOrmItem } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import {
  changeStatus,
  deleteSmartGoal,
  inactiveSmartGoalStatuses,
} from 'common-src/models/PatientSmartGoal';
import PatientSmartGoal from 'common-src/models/PatientSmartGoal/PatientSmartGoal';

import Icons from 'src/assets/Icons';
import { ActionsButton } from 'src/components/buttons';
import { CreatorItem, TextItem } from 'src/components/table';
import BaseTable from 'src/components/table/BaseTable';

import { StageOfChangeConfig, TypesConfig } from './constants';
import { getProgressById, getStageOfChangeById, getTypeLabelById } from './helpers';
import { GoalItem } from './items';
import styles from './SmartGoalsList.module.scss';

const MAX_GOAL_TYPES = 3;

const headers = [
  { column: 'goal', label: 'Goal', width: '18%', sortBy: 'smartGoal.goal' },
  { column: 'goalOrigin', label: 'Creator', width: '15%', sortBy: 'value' },
  { column: 'goalType', label: 'Type', width: '15%' },
  { column: 'startDate', label: 'Start Date', width: '10%', sortBy: 'text' },
  {
    column: 'followUpDate',
    label: 'Follow-up Date',
    width: '10%',
    sortBy: 'text',
  },
  { column: 'progress', label: 'Progress', width: '16%', sortBy: 'currentStatus.label' },
  { column: 'stageOfChange', label: 'Stage', width: '12%', sortBy: 'currentStatus.label' },
  { column: 'actions', label: '', width: '4%' },
];

const SmartGoalsList = ({ editHandler, actionsAllowed, smartGoals = [], patient = {} }) => {
  const dispatch = useDispatch();

  const renderGoalTypes = (row, isActive) => {
    const goalTypes = row?.typeIds?.map((typeId) => getTypeLabelById(typeId));

    const title = goalTypes?.map((type) => <p key={type}>{type}</p>);

    const body = goalTypes?.map((type, index, arr) => {
      if (arr.length > MAX_GOAL_TYPES) {
        if (index < MAX_GOAL_TYPES - 1) {
          return <TextItem key={type} isActive={isActive} text={type} disableTooltip />;
        }

        if (index === MAX_GOAL_TYPES - 1) {
          return (
            <TextItem
              key={type}
              isActive={isActive}
              text={`+${arr.length - MAX_GOAL_TYPES + 1}`}
              disableTooltip
            />
          );
        }

        return;
      }

      return <TextItem key={type} isActive={isActive} text={type} disableTooltip />;
    });

    return (
      <Tooltip title={title}>
        <div>{body}</div>
      </Tooltip>
    );
  };

  const renderFollowUpDate = (item, isActive) => {
    const text = moment(item?.followUpDate).isBefore(new Date())
      ? 'Finished'
      : moment(item?.followUpDate).format('MM/DD/YYYY');
    return <TextItem isActive={isActive} text={text} />;
  };

  const getData = () =>
    smartGoals.map((item) => {
      const isDisabled = !actionsAllowed || inactiveSmartGoalStatuses.includes(item.progressStatus);
      const isActive = !inactiveSmartGoalStatuses.includes(item.progressStatus);
      const currentProgressStatus = getProgressById(item?.progressStatus);
      const progressOptions = TypesConfig.map((op) => ({
        ...op,
        onClick: (option) =>
          dispatch(changeStatus({ ...item.ref, patientId: patient.id, progressStatus: option.id })),
      }));

      const currentStageOfChangeStatus = getStageOfChangeById(item.stageOfChangeStatus);
      const stageOfChangeOptions = StageOfChangeConfig.map((op) => ({
        ...op,
        onClick: (option) =>
          dispatch(
            changeStatus({ ...item.ref, patientId: patient.id, stageOfChangeStatus: option.id }),
          ),
      }));

      return {
        goal: <GoalItem isActive={isActive} smartGoal={item} />,
        goalOrigin: (
          <CreatorItem
            creator={item?.createdBy}
            creatorName={item?.createdBy?.getName(false, true)}
            isActive={isActive}
            value={item?.createdBy?.getName(false, true)}
          />
        ),
        goalType: renderGoalTypes(item, isActive),
        startDate: (
          <TextItem
            isActive={isActive}
            text={item?.startDate ? moment(item.startDate).format('MM/DD/YYYY') : '-'}
          />
        ),
        followUpDate: renderFollowUpDate(item, isActive),
        progress: (
          <Menu
            button={
              currentProgressStatus ? (
                <StatusItem
                  isActive
                  text={currentProgressStatus.label}
                  textColor={currentProgressStatus.textColor}
                  backgroundColor={currentProgressStatus.backgroundColor}
                />
              ) : (
                '-'
              )
            }
            options={progressOptions}
            menuStatus={currentProgressStatus?.id || '-'}
          />
        ),
        stageOfChange: (
          <Menu
            button={
              currentStageOfChangeStatus ? (
                <StatusItem
                  isActive
                  text={currentStageOfChangeStatus.label}
                  textColor={currentStageOfChangeStatus.textColor}
                  backgroundColor={currentStageOfChangeStatus.backgroundColor}
                />
              ) : (
                '-'
              )
            }
            options={stageOfChangeOptions}
            menuStatus={currentStageOfChangeStatus?.id || '-'}
            disabled={isDisabled}
          />
        ),
        actions: (
          <ActionsButton
            id="smart-goal-actions-button"
            options={[
              {
                id: 'edit-smart-goal',
                text: 'Edit',
                iconSrc: Icons.boldGearIcon,
                onClick: async () => editHandler(item.id),
              },
              {
                id: 'delete-smart-goal',
                text: 'Delete',
                iconSrc: Icons.boldCloseIcon,
                onClick: async () =>
                  dispatch(
                    deleteSmartGoal(item.id, {
                      successBlock: () => {
                        deleteOrmItem(PatientSmartGoal.modelName, item.id);
                      },
                    }),
                  ),
              },
            ]}
            paperWidth={150}
            disabled={isDisabled}
          />
        ),
      };
    });

  return (
    <BaseTable
      headers={headers}
      data={getData()}
      name="smart-goals"
      classNames={['p-b-10']}
      emptyText="No smart goals"
      emptyClassNames={[styles.empty]}
    />
  );
};

SmartGoalsList.propTypes = {
  smartGoals: PropTypes.arrayOf(PropTypes.exact(PatientSmartGoal.schema)),
  patient: PropTypes.exact(Patient.schema),
  editHandler: PropTypes.func,
  actionsAllowed: PropTypes.bool,
};

export default SmartGoalsList;
