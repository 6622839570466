export const getPatientsPaths = (state) => state.tabsState?.patientDetails;
export const getIsPatientSidebarExtended = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.isExtended;
export const getPatientDocumentationTabInfo = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.documentation;
export const getPatientTimelineTabInfo = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.timeline;
export const getPatientLastMedicationsPull = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.lastMedicationsPull;
export const getPatientMainTab = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.mainTab;
export const getSettingsPath = (state) => state.tabsState?.settings?.lastPath;
export const getActiveTabs = (state) => state.tabsState.activeTabs;
export const getPatientNote = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.docId;
export const getNoteData = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.noteData;
export const getNoteScroll = (state, patientId) =>
  state.tabsState?.patientDetails?.[patientId]?.noteScroll;
export const getDashboardFilters = (state) => state.tabsState?.dashboard?.filters || {};
export const getIsCurieAISelected = (state, patientId) =>
  !!state.tabsState?.patientDetails?.[patientId]?.isCurieAISelected;
