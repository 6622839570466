import PropTypes from 'prop-types';
import React from 'react';

import { TextArea } from 'common-src/components/base';
import {
  WellnessPlanDataByField,
  WellnessPlanFormFields,
} from 'common-src/models/PatientWellnessPlan';

import commonStyles from '../commonStyles.module.scss';
import styles from './WellnessPlanForm.module.scss';

const WellnessPlanForm = ({ wellnessPlan, setWellnessPlan, setHasDetectedChanges }) => {
  const onTextChange = (field, value) => {
    setWellnessPlan((prev) => ({ ...prev, [field]: value }));
    setHasDetectedChanges(new Date());
  };

  return (
    <div className={[styles.container, 'flex-column', 'gap-14'].join(' ')}>
      <span className={['font-w-600', 'font-s-16'].join(' ')}>Wellness Plan</span>
      <span className={['font-w-500', 'font-s-14'].join(' ')}>
        When creating a wellness plan, write in first person (“I” or “My”). Note that a wellness
        plan may remain the same from previous visits or you may update as appropriate.
      </span>
      {WellnessPlanFormFields.map((field) => {
        const data = WellnessPlanDataByField[field];
        if (!data) return null;

        const { label, description } = data;
        return (
          <TextArea
            key={field}
            id={label.toLowerCase()}
            classNames={['m-b-16']}
            inputClassNames={[commonStyles.inputText]}
            onTextChange={(newValue) => onTextChange(field, newValue)}
            value={wellnessPlan[field] ?? ''}
            label={label}
            placeholder={`Enter ${label}`}
            description={description}
            maxRows={20}
            size="small"
            withError={false}
          />
        );
      })}
    </div>
  );
};

WellnessPlanForm.propTypes = {
  wellnessPlan: PropTypes.object,
  setWellnessPlan: PropTypes.func,
  setHasDetectedChanges: PropTypes.func,
};

export default WellnessPlanForm;
