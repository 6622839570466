import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Tooltip } from 'common-src/components/base';

import styles from './TextWithTooltipItem.module.scss';

const TextWithTooltipItem = ({ tooltipText, headerText = '-' }) => (
  <div className={[styles.container, 'flex-row', 'gap-8'].join(' ')}>
    <span className={[styles.text, 'font-w-500', 'font-s-14'].join(' ')}>{headerText}</span>
    {tooltipText && (
      <Tooltip title={tooltipText}>
        <img src={CommonIcons.tooltipIcon} alt="tooltip-icon" />
      </Tooltip>
    )}
  </div>
);

TextWithTooltipItem.propTypes = {
  headerText: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default TextWithTooltipItem;
