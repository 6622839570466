/* eslint-disable unused-imports/no-unused-vars */
export const getIsButtonEnabled = (address, errors) => {
  const { street2: _, ...requiredFields } = address;

  if (
    Object.values(requiredFields).some((value) => !value) ||
    Object.values(errors).some((e) => e)
  ) {
    return false;
  }

  return true;
};
