import moment from 'moment-timezone';

export const getInputFromPatientGlucoseReading = (glucoseReading, timeZone) => {
  const date = glucoseReading.recordedAtLocal
    ? moment.utc(glucoseReading.recordedAtLocal)
    : moment(glucoseReading.recordedAt).tz(timeZone);

  return {
    id: glucoseReading.id,
    effectiveDate: date.format('YYYY-MM-DD'),
    hour: date.hours(),
    data: {
      v: glucoseReading.value,
    },
    timeZoneAbbr: moment(glucoseReading.recordedAt).tz(timeZone).format('z'),
    getRecordedAt: (format) =>
      glucoseReading.recordedAtLocal ? date.format(format) : date.tz(timeZone).format(format),
  };
};

export const inputsFilterByDate = (input, days, endDate) => {
  if (endDate && endDate !== 'Invalid date') {
    return (
      input.effectiveDate <= endDate &&
      input.effectiveDate >= moment(endDate).subtract(days, 'days').format('YYYY-MM-DD')
    );
  }

  return input.effectiveDate >= moment().subtract(days, 'days').format('YYYY-MM-DD');
};
