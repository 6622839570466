export const validatePatientEncounter = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'type':
      if (!value) {
        errMsg = 'Type should not be empty';
      }
      break;
    case 'startDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      }
      break;
    case 'endDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      }
      break;
    case 'status':
      if (!value) {
        errMsg = 'Status should not be empty';
      }
      break;
    case 'patientReported':
      if (!value) {
        errMsg = 'Source should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
