import PropTypes from 'prop-types';
import React from 'react';

import { moduleTypes } from 'common-src/models/PatientInteraction';

import useDocumentationConfig from 'src/hooks/useDocumentationConfig';
import useDocumentationErrors from 'src/hooks/useDocumentationErrors';
import useSaveDocumentation from 'src/hooks/useSaveDocumentation';
import {
  DocumentForm,
  SectionFooter,
  SectionHeader,
} from 'src/pages/DocumentationIndex/components';

import styles from '../commonStyles.module.scss';

const HoldStatusForm = ({ patientId, documentationId }) => {
  const { handleError, errors } = useDocumentationErrors();
  const {
    patient,
    documentationToEdit,
    modules,
    primaryModuleTypeId,
    setModules,
    showBillingInfo,
    serviceAt,
  } = useDocumentationConfig(patientId, documentationId, moduleTypes.HoldRequest.id);
  const {
    savedAt,
    isDraftCompleting,
    isDraftSaving,
    hasDetectedChanges,
    setHasDetectedChanges,
    handleButtonClick,
  } = useSaveDocumentation({
    withBilling: false,
    handleError,
    modules,
    documentationToEdit,
    patientId,
    primaryModuleTypeId,
    serviceAt,
  });

  return (
    <>
      <SectionHeader patient={patient} />
      <section className={[styles.section, 'relative'].join(' ')}>
        <DocumentForm
          modules={modules}
          setModules={setModules}
          setHasDetectedChanges={setHasDetectedChanges}
          errors={errors}
          handleError={handleError}
        />
      </section>
      <SectionFooter
        isLoading={isDraftCompleting}
        isDraftSaving={isDraftSaving}
        isUpdating={!!hasDetectedChanges}
        draftSavedAt={savedAt}
        showBillingInfo={showBillingInfo}
        withBackButton={false}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};

HoldStatusForm.propTypes = {
  patientId: PropTypes.number,
  documentationId: PropTypes.string,
};

export default HoldStatusForm;
