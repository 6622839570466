import { CLEAR_TIMER, SET_TIMER } from './actionTypes';

export const clearTimer = (patientId) => ({
  type: CLEAR_TIMER,
  payload: { patientId },
});

export const setTimer = (patientId, timer) => ({
  type: SET_TIMER,
  payload: { patientId, timer },
});
