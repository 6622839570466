import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { apiRequest } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import { getPatientCgmGlucoseReadingsRequest } from 'common-src/models/PatientCgmGlucoseReading';
import { getPatientLabResults } from 'common-src/models/PatientLabReading';

import { RequestHandlerScreen } from 'src/components/base';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import { CGMChart, CGMScatterChart } from 'src/pages/PatientDetails/fragments/Biometrics/charts';
import {
  CGM_POLLING_FREQUENCY,
  CGMPeriodRange,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';
import {
  CGMFiltersHeader,
  CGMHeader,
  CGMSubHeader,
} from 'src/pages/PatientDetails/fragments/Biometrics/headers';
import { DailyProfilesTable } from 'src/pages/PatientDetails/fragments/Biometrics/tables';

import styles from './CGMTab.module.scss';
import { getLastLabResults } from './helpers';

const CGMTab = ({ patient }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState(CGMPeriodRange[3].value);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [isLoading, setIsLoading] = useState(false);

  const labResults = useCustomSelector((state) => getPatientLabResults(state, patient?.id));

  const { lastHbA1c } = getLastLabResults(labResults);
  const { showAlert, AlertType } = useAlert();

  const shouldShowScatter = [0, CGMPeriodRange[0].value, CGMPeriodRange[1].value].includes(
    selectedPeriod,
  );

  const getCGMData = (withLoading = false) => {
    if (!startingDate || moment(endingDate).diff(moment(startingDate), 'days') > 28) return;

    if (withLoading) setIsLoading(true);

    if (shouldShowScatter) {
      dispatch(
        getPatientCgmGlucoseReadingsRequest(
          {
            patient_id: patient.id,
            end_date: moment(endingDate).format('YYYY-MM-DD'),
            start_date: moment(startingDate).format('YYYY-MM-DD'),
            limit: 289,
          },
          {
            successBlock: () => {
              if (withLoading) setIsLoading(false);
            },
            errorBlock: (err) => {
              showAlert(AlertType.Error, '', err);
              if (withLoading) setIsLoading(false);
            },
          },
        ),
      );
    }

    apiRequest({
      endpoint: 'patientCgmGlucoseReadings/aggregate',
      queryParams: {
        patient_id: patient.id,
        end_date: moment(endingDate).format('YYYY-MM-DD'),
        start_date: moment(startingDate).format('YYYY-MM-DD'),
      },
    })
      .then((res) => res.json())
      .then((cgmData) => {
        if (cgmData?.detail) {
          showAlert(AlertType.Error, '', cgmData.detail);
          setData({});
        } else if (!_.isEqual(cgmData, data)) {
          setData(cgmData);
        }
        if (withLoading) setIsLoading(false);
      });
  };

  useEffect(() => {
    getCGMData(true);
  }, [startingDate, endingDate]);

  useEffect(() => {
    if (selectedPeriod === CGMPeriodRange[0].value) {
      const today = moment().format('YYYY-MM-DD HH:mm');
      setEndingDate(today);
      setStartingDate(today);
      return;
    }

    if (selectedPeriod === CGMPeriodRange[1].value) {
      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm');
      setEndingDate(yesterday);
      setStartingDate(yesterday);
      return;
    }

    setStartingDate(moment(endingDate).subtract(selectedPeriod, 'days').format('YYYY-MM-DD HH:mm'));
  }, [selectedPeriod]);

  useInterval(() => {
    getCGMData();
  }, CGM_POLLING_FREQUENCY);

  const renderChart = () =>
    shouldShowScatter ? (
      <CGMScatterChart patientId={patient?.id} startDate={startingDate} endDate={endingDate} />
    ) : (
      <CGMChart patientId={patient?.id} data={data?.hourlyAggregation} />
    );

  const renderContent = () => {
    if (isLoading) {
      return <RequestHandlerScreen isRequesting requestingText="Pulling data..." />;
    }

    return (
      <>
        <CGMHeader patient={patient} data={data} a1c={lastHbA1c?.value} />
        <div className={styles.scroll}>
          <CGMSubHeader patient={patient} data={data} a1c={lastHbA1c?.value} />
          <div className={styles.chartWrapper}>{renderChart()}</div>
          <DailyProfilesTable selectedPeriod={selectedPeriod} data={data?.dailyAggregation} />
        </div>
      </>
    );
  };

  return (
    <>
      <CGMFiltersHeader
        patient={patient}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        startingDate={startingDate}
        setStartingDate={setStartingDate}
        endingDate={endingDate}
        setEndingDate={setEndingDate}
      />
      {renderContent()}
    </>
  );
};

CGMTab.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default CGMTab;
