// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertGroup-module__container___V14hx {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  gap: 5px;
  min-width: 25vw;
  max-width: 600px;
  margin: 10px;
}
.AlertGroup-module__container___V14hx .AlertGroup-module__alert___nxrIn {
  transition: transform 0.2s ease-out;
}
.AlertGroup-module__container___V14hx .AlertGroup-module__alert___nxrIn.AlertGroup-module__slideIn___VbkC7 {
  transform: translateX(100%);
}`, "",{"version":3,"sources":["webpack://./src/components/base/AlertGroup/AlertGroup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;EACE,mCAAA;AACJ;AACI;EACE,2BAAA;AACN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AlertGroup-module__container___V14hx`,
	"alert": `AlertGroup-module__alert___nxrIn`,
	"slideIn": `AlertGroup-module__slideIn___VbkC7`
};
module.exports = ___CSS_LOADER_EXPORT___;
