import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './DateSeparator.module.scss';

const DateSeparator = ({ classNames, date, format = 'YYYY-MM-DD' }) => {
  const containerClassNames = [styles.container, 'vertically-centered', 'gap-16'];
  if (classNames) {
    containerClassNames.push(...classNames);
  }

  return (
    <div className={containerClassNames.join(' ')}>
      <div className={styles.line} />
      <p className={['font-w-500', 'font-s-12'].join(' ')}>{moment(date).format(format)}</p>
      <div className={styles.line} />
    </div>
  );
};

DateSeparator.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.string,
  format: PropTypes.string,
};

export default DateSeparator;
