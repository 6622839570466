import _ from 'lodash';
import moment from 'moment-timezone';

import { validatePatientSurgicalHistory } from 'common-src/models/PatientSurgicalHistory';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientSurgicalHistory(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => ({
  patientId,
  procedure: data?.procedure,
  dateOfSurgery: _.isEmpty(data?.dateOfSurgery)
    ? null
    : moment(data?.dateOfSurgery).format('YYYY-MM-DD'),
  comments: _.isEmpty(data?.comments) ? null : data?.comments,
});

export const extractSurgicalHistory = (surgicalHistory) => ({
  procedure: surgicalHistory?.procedure,
  dateOfSurgery: surgicalHistory?.dateOfSurgery,
  comments: surgicalHistory?.comments || undefined,
});

export const getIsButtonEnabled = (data, errors, surgicalHistory) => {
  if (
    Object.values(data).every((e) => !e) ||
    Object.values(errors).some((e) => e) ||
    (surgicalHistory && _.isEqual(data, extractSurgicalHistory(surgicalHistory)))
  )
    return false;

  return true;
};
