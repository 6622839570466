import {
  ALL_TASK_TYPE_REASONS,
  ALL_TASK_TYPES,
  PatientTaskStatus,
  TaskReason,
  TaskType,
} from './constants';

export const getStatusById = (statusId) =>
  Object.values(PatientTaskStatus).find((item) => item.value === statusId);

export const getTaskTypeOptions = () => [ALL_TASK_TYPES, ...Object.values(TaskType)];

export const getTaskTypeById = (value) =>
  Object.values(TaskType).find((taskType) => taskType.value === value);

export const getTaskReasonOptions = () => [ALL_TASK_TYPE_REASONS, ...Object.values(TaskReason)];

export const getTaskReasonById = (value) =>
  Object.values(TaskReason).find((taskReason) => taskReason.value === value);
