export const HistoryEventTypes = Object.freeze({
  OutgoingMessages: {
    value: 1,
    label: 'Outgoing Messages',
  },
  IncomingMessages: {
    value: 2,
    label: 'Incoming Messages',
  },
  OutgoingCalls: {
    value: 3,
    label: 'Outgoing Calls',
  },
  IncomingCalls: {
    value: 4,
    label: 'Incoming Calls',
  },
  Reminders: {
    value: 5,
    label: 'Reminders',
  },
  Alerts: {
    value: 6,
    label: 'Alerts',
  },
  ScheduledVisits: {
    value: 7,
    label: 'Scheduled Visits',
  },
  NewDocumentation: {
    value: 8,
    label: 'New Documentation',
  },
  StatusUpdates: {
    value: 9,
    label: 'Status Updates',
  },
  NewGoals: {
    value: 10,
    label: 'New Goals',
  },
  ShippingUpdates: {
    value: 11,
    label: 'Shipping Updates',
  },
  CgmOrders: {
    value: 12,
    label: 'CGM Orders',
  },
});
