import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'common-src/components/base';
import PatientSmartGoal from 'common-src/models/PatientSmartGoal';
import { getDateWithTimeZone } from 'common-src/utils/dateUtils';

import styles from './GoalItem.module.scss';

const GoalItem = ({ isActive, smartGoal }) => {
  const updatedAtDate = smartGoal?.updatedAt
    ? getDateWithTimeZone(smartGoal?.updatedAt, 'MM/DD/YYYY')
    : '-';

  return (
    <Tooltip title={smartGoal?.goal || '-'}>
      <div
        style={{ opacity: isActive ? 1 : 0.5 }}
        className={[styles.container, 'gap-2', 'flex-column'].join(' ')}
      >
        <span className={[styles.title, 'font-w-500', 'font-s-14'].join(' ')}>
          {smartGoal?.goal || '-'}
        </span>
        <span
          className={[styles.date, 'font-w-500', 'font-s-12'].join(' ')}
        >{`Updated: ${updatedAtDate}`}</span>
      </div>
    </Tooltip>
  );
};

GoalItem.propTypes = {
  smartGoal: PropTypes.exact(PatientSmartGoal.schema),
  isActive: PropTypes.bool,
};

export default GoalItem;
