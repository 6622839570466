import _ from 'lodash';

import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientDevices = (state, patientId) =>
  allModelsSelector(state, 'PatientDevice', (device) => device.patientId === patientId).sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );

export const getPatientMostRecentDevice = (state, patientId) => {
  const devices = allModelsSelector(
    state,
    'PatientDevice',
    (device) => device.patientId === patientId,
  );

  return _.orderBy(
    devices,
    ({ lastReadingAt, createdAt }) => {
      const sortField = lastReadingAt || createdAt;
      return new Date(sortField);
    },
    ['desc'],
  )?.[0];
};
