import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientVitals = (state, patientId) =>
  allModelsSelector(state, 'PatientVital', (r) => r.patientId === patientId).sort(
    (a, b) =>
      new Date(b.dateObserved) - new Date(a.dateObserved) ||
      new Date(b.updatedAt) - new Date(a.updatedAt),
  );

export const getLastPatientVital = (state, patientId) => getPatientVitals(state, patientId)?.[0];

export const getLastPatientHeightAndWeight = (state, patientId) => {
  const patientVitals = getPatientVitals(state, patientId);
  const height = patientVitals.find((pv) => !!pv.height);
  const weight = patientVitals.find((pv) => !!pv.weight);

  return { height, weight };
};
