export const TimeLogType = Object.freeze({
  LiveInteractionAudio: 'Live interaction - Audio',
  LiveInteractionVideo: 'Live interaction - Video',
  AsynchronousMessage: 'Asynchronous message',
  DataReview: 'Data review',
  Documentation: 'Documentation',
  CareCoordination: 'Care coordination',
  OtherBillable: 'Other - Billable',
  OtherNonBillable: 'Other - Non Billable',
});

export const TimeLogTypeOptions = Object.values(TimeLogType).map((type) => ({
  label: type,
  value: type,
}));
