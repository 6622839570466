import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import { Colors, ColorsNew } from 'common-src/styles';

import styles from './FormButtons.module.scss';

const FormButtons = ({
  isLoading,
  isEditMode,
  isButtonEnabled,
  small,
  buttonLabel,
  editHandler,
  submitHandler,
  cancelHandler,
  disabled,
  sectionName,
  isSectionForm = false,
}) => {
  const renderEditButton = () => (
    <Button
      id={sectionName ? `edit-${buttonLabel}-button-${sectionName}` : `edit-${buttonLabel}-button`}
      classNames={[isSectionForm ? styles.sectionIconButton : styles.iconButton, 'gap-8']}
      iconSrc={CommonIcons.editIcon}
      text={`Edit ${buttonLabel}`}
      onClick={editHandler}
      disabled={disabled}
      backgroundColor="transparent"
      borderColor="transparent"
      textColor={ColorsNew.darkGreen}
    />
  );

  const renderCancelAndSaveButtons = () => (
    <div className={[styles.buttonsWrapper, 'gap-16', small ? styles.small : ''].join(' ')}>
      <Button
        id="cancel-button"
        classNames={[isSectionForm ? styles.button : '']}
        text="Cancel"
        onClick={() => cancelHandler()}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
      />
      <Button
        id="submit-button"
        classNames={[isSectionForm ? styles.button : '']}
        text="Save Updates"
        onClick={() => submitHandler()}
        disabled={!isButtonEnabled}
        isLoading={isLoading}
      />
    </div>
  );

  return isEditMode ? renderCancelAndSaveButtons() : renderEditButton();
};

FormButtons.propTypes = {
  isLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isButtonEnabled: PropTypes.bool,
  small: PropTypes.bool,
  buttonLabel: PropTypes.string,
  editHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  disabled: PropTypes.bool,
  isSectionForm: PropTypes.bool,
  sectionName: PropTypes.string,
};

export default FormButtons;
