import PropTypes from 'prop-types';
import React from 'react';

import styles from '../commonStyles.module.scss';

const StatusItem = ({ backgroundColor, value = '-' }) => (
  <span className={[styles.container, 'font-s-10'].join(' ')} style={{ backgroundColor }}>
    {value}
  </span>
);

StatusItem.propTypes = {
  value: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default StatusItem;
