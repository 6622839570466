export const MedicalHistoryTabs = {
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
  ALL: {
    text: 'All',
  },
};

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: MedicalHistoryTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: MedicalHistoryTabs.NOT_ACTIVE.text,
    count: 0,
  },
  ALL: {
    text: MedicalHistoryTabs.ALL.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'name', label: 'Diagnosis', width: '27%', sortBy: 'title' },
  { column: 'comments', label: 'Comments', width: '25%', sortBy: 'text' },
  { column: 'dxDate', label: 'Dx Date', width: '10%', sortBy: 'text' },
  {
    column: 'createdBy',
    label: 'Created By',
    width: '16%',
    sortBy: 'value',
  },
  { column: 'actions', label: 'Actions', width: '22%' },
];
