import _ from 'lodash';

import { StatusesConfig, validatePatientSupplement } from 'common-src/models/PatientSupplement';

import { SourceOptions, StatusesOptions } from './constants';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientSupplement(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => ({
  patientId,
  name: data?.name,
  dosageText: _.isEmpty(data?.dosageText) ? null : data?.dosageText,
  form: _.isEmpty(data?.form) ? null : data?.form,
  status: data?.status?.value || StatusesConfig[4].id,
  frequency: _.isEmpty(data?.frequency) ? null : data?.frequency,
  patientReported: data?.patientReported?.value === false ? data?.patientReported?.value : true,
  comments: _.isEmpty(data?.comments) ? null : data?.comments,
});

export const extractSupplement = (supplement) => ({
  name: supplement?.name,
  dosageText: supplement?.dosageText,
  form: supplement?.form,
  status: StatusesOptions.find((status) => status.value === supplement?.status),
  frequency: supplement?.frequency,
  patientReported: SourceOptions.find((source) => source.value === supplement?.patientReported),
  comments: supplement?.comments || undefined,
});

export const getIsButtonEnabled = (data, errors, supplement, isDuplicate) => {
  if (
    Object.values(data).every((e) => !e) ||
    Object.values(errors).some((e) => e) ||
    (supplement && !isDuplicate && _.isEqual(data, extractSupplement(supplement))) ||
    !data.name
  )
    return false;

  return true;
};
