import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import PatientSupplement, { getStatusById } from 'common-src/models/PatientSupplement';

import { InfoItem } from 'src/components/elements';
import BasePopup from 'src/popups/BasePopup';

import styles from './SupplementDetailsPopup.module.scss';

const SupplementDetailsPopup = ({ supplement, onClose }) => (
  <BasePopup id="supplement-details" open onClose={onClose} title={supplement.name}>
    <div className={styles.firstGrid}>
      <InfoItem
        title="Date Added"
        content={supplement.createdAt && moment(supplement.createdAt).format('MM/DD/YYYY')}
        textId="date-added"
        small
      />
      <InfoItem
        title="Added By"
        content={supplement.createdBy?.getName(true, false)}
        textId="added-by"
        small
      />
      <InfoItem title="Source" content={supplement.source || '-'} textId="source" small />
      <InfoItem title="Dosage" content={supplement.dosageText || '-'} textId="dosage" small />
      <InfoItem title="Form" content={supplement.form || '-'} textId="form" small />
      <InfoItem title="Frequency" content={supplement.frequency || '-'} textId="frequency" small />
    </div>
    <InfoItem
      classNames={[styles.comments, 'primary-border-b', 'primary-border-t']}
      title="Comments"
      content={supplement.comments}
      textId="comments"
      small
    />
    <div className={styles.secondGrid}>
      <InfoItem
        title="Last Updated"
        content={supplement.updatedAt && moment(supplement.updatedAt).format('MM/DD/YYYY')}
        textId="date-updated"
        small
      />
      <InfoItem
        title="Last Updated By"
        content={supplement.updatedBy?.getName(true, false)}
        textId="updated-by"
        small
      />
      <InfoItem
        title="Status"
        content={getStatusById(supplement?.status).label}
        textId="status"
        small
      />
    </div>
  </BasePopup>
);

SupplementDetailsPopup.propTypes = {
  onClose: PropTypes.func,
  supplement: PropTypes.exact(PatientSupplement.schema),
};

export default SupplementDetailsPopup;
