export const validatePatientSdoh = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'sdohCodeId':
      if (!value) {
        errMsg = 'Social issue area should not be empty';
      }
      break;
    case 'status':
      if (!value) {
        errMsg = 'Status should not be empty';
      }
      break;
    case 'note':
      if (value && String(value).length > 512) {
        errMsg = 'Note should be 512 characters at most';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
