export const SmartGoalTypes = Object.freeze({
  Nutrition: { id: 1, label: 'Nutrition', priority: 1 },
  PhysicalActivity: { id: 2, label: 'Physical Activity', priority: 4 },
  BGMonitoring: { id: 3, label: 'BG Monitoring', priority: 7 },
  Medications: { id: 4, label: 'Medications', priority: 2 },
  StressAndCoping: { id: 5, label: 'Stress and Coping', priority: 5 },
  Hydration: { id: 6, label: 'Hydration', priority: 8 },
  WellnessAndSafety: { id: 7, label: 'Wellness and Safety', priority: 3 },
  ProviderFollowUp: { id: 8, label: 'Provider Follow Up', priority: 6 },
  Other: { id: 9, label: 'Other', priority: 9 },
});

export const ProgressStatuses = Object.freeze({
  DidNotProgress: { id: 1 },
  Progressing: { id: 2 },
  Achieved: { id: 3 },
  Canceled: { id: 4 },
});

export const StageOfChangeStatuses = Object.freeze({
  PreContemplation: { id: 1 },
  Contemplation: { id: 2 },
  Preparation: { id: 3 },
  Action: { id: 4 },
  Maintenance: { id: 5 },
  Relapse: { id: 6 },
});

export const inactiveSmartGoalStatuses = [
  ProgressStatuses.Achieved.id,
  ProgressStatuses.Canceled.id,
];
