import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import { EmptyPlaceholder } from 'src/components/elements';

const AvatarPlaceholder = ({ text }) => (
  <EmptyPlaceholder
    icon={
      <img
        src={CommonIcons.personIcon}
        alt="person-icon"
        style={{ width: '72px', height: '72px' }}
      />
    }
    text={text}
  />
);

AvatarPlaceholder.propTypes = {
  text: PropTypes.string,
};

export default AvatarPlaceholder;
