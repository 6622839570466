import _ from 'lodash';

import {
  EncounterStatus,
  EncounterType,
  validatePatientEncounter,
} from 'common-src/models/PatientEncounter';

import { initialState, SourceOptions } from './constants';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientEncounter(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => {
  const body = {
    type: data.type.value,
    startDate: data.startDate,
    endDate: data.endDate,
    status: data.status.value,
    patientReported: data.patientReported.value,
    provider: data.provider,
    comments: data.comments,
  };

  if (patientId) {
    Object.assign(body, { patientId });
  }

  return body;
};

export const extractPatientEncounter = (data) => ({
  type: Object.values(EncounterType).find((x) => x.value === data.type) || null,
  startDate: data.startDate || null,
  endDate: data.endDate || null,
  status: Object.values(EncounterStatus).find((x) => x.value === data.status) || null,
  patientReported: SourceOptions.find((x) => x.value === data.patientReported) || null,
  provider: data.provider || '',
  comments: data.comments || '',
});

export const getIsButtonEnabled = (data, errors, encounter) => {
  if (
    _.isEqual(initialState, data) ||
    Object.values(errors).some((e) => e) ||
    (encounter && _.isEqual(data, extractPatientEncounter(encounter)))
  )
    return false;

  return true;
};
