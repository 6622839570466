import PropTypes from 'prop-types';
import React from 'react';

import styles from './MasterSection.module.scss';

const MasterSection = ({ children, classNames }) => {
  const containerClassNames = [styles.container, 'flex-column'];
  if (classNames) {
    containerClassNames.push(classNames);
  }

  return (
    <aside id="master-section" className={containerClassNames.join(' ')}>
      {children}
    </aside>
  );
};

MasterSection.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default MasterSection;
