import _ from 'lodash';
import moment from 'moment-timezone';

const calculateMaxCount = (startDate, endDate, readingFrequency) => {
  if (!startDate || !endDate) return 0;

  const startMoment = moment(startDate).startOf('day');
  const isToday = moment(endDate).isSame(moment(), 'day');
  const endMoment = isToday ? moment(new Date()) : moment(endDate).endOf('day');
  const diffInMinutes = endMoment.diff(startMoment, 'minutes');

  return readingFrequency !== 0 ? Number((diffInMinutes / readingFrequency).toFixed(0)) : 0;
};

export const hasEmptyChartData = (data) => {
  if (_.isEmpty(data)) return true;

  const areAllValuesP10Null = data?.p10 ? _.every(data?.p10, _.isNull) : true;
  const areAllValuesP25Null = data?.p25 ? _.every(data?.p25, _.isNull) : true;
  const areAllValuesP50Null = data?.p50 ? _.every(data?.p50, _.isNull) : true;
  const areAllValuesP75Null = data?.p75 ? _.every(data?.p75, _.isNull) : true;
  const areAllValuesP90Null = data?.p90 ? _.every(data?.p90, _.isNull) : true;

  return (
    areAllValuesP10Null &&
    areAllValuesP25Null &&
    areAllValuesP50Null &&
    areAllValuesP75Null &&
    areAllValuesP90Null
  );
};

export const getPatientGlucoseValues = (data, patientAge, patientA1c) => {
  const isEmpty = hasEmptyChartData(data?.hourlyAggregation);
  const averageGlucose = data?.averageValue ? data.averageValue : '-';
  const maxCount = calculateMaxCount(data?.startDate, data?.endDate, data?.readingFrequency);

  const timeCgmActive = maxCount ? (data?.totalCount / maxCount) * 100 : 0;
  const veryHigh = data?.totalCount ? (data?.veryHighCount / data?.totalCount) * 100 : 0;
  const high = data?.totalCount ? (data?.highCount / data?.totalCount) * 100 : 0;
  const targetRange = data?.totalCount ? (data?.targetRangeCount / data?.totalCount) * 100 : 0;
  const low = data?.totalCount ? (data?.lowCount / data?.totalCount) * 100 : 0;
  const veryLow = data?.totalCount ? (data?.veryLowCount / data?.totalCount) * 100 : 0;

  switch (true) {
    case isEmpty:
      return {
        averageGlucose: {
          value: '-',
        },
        timeCgmActive: {
          value: '-',
          isCritical: false,
        },
        veryHigh: {
          value: '-',
          isCritical: false,
        },
        high: {
          value: '-',
          isCritical: false,
        },
        targetRange: {
          value: '-',
          isCritical: false,
        },
        low: {
          value: '-',
          isCritical: false,
        },
        veryLow: {
          value: '-',
          isCritical: false,
        },
      };
    case patientAge <= 65 && patientA1c < 9:
      return {
        averageGlucose: {
          value: `${averageGlucose} mg/dL`,
        },
        timeCgmActive: {
          value: `${Math.min(100, timeCgmActive.toFixed(0))}%`,
          isCritical: timeCgmActive < 70,
        },
        veryHigh: {
          value: `${veryHigh.toFixed(2)}%`,
          isCritical: veryHigh > 5,
        },
        high: {
          value: `${high.toFixed(2)}%`,
          isCritical: high > 25,
        },
        targetRange: {
          value: `${targetRange.toFixed(2)}%`,
          isCritical: targetRange < 70,
        },
        low: {
          value: `${low.toFixed(2)}%`,
          isCritical: low > 4,
        },
        veryLow: {
          value: `${veryLow.toFixed(2)}%`,
          isCritical: veryLow > 1,
        },
      };
    default:
      return {
        averageGlucose: {
          value: `${averageGlucose} mg/dL`,
        },
        timeCgmActive: {
          value: `${Math.min(100, timeCgmActive.toFixed(0))}%`,
          isCritical: timeCgmActive < 70,
        },
        veryHigh: {
          value: `${veryHigh.toFixed(2)}%`,
          isCritical: veryHigh > 10,
        },
        high: {
          value: `${high.toFixed(2)}%`,
          isCritical: high > 25,
        },
        targetRange: {
          value: `${targetRange.toFixed(2)}%`,
          isCritical: targetRange < 50,
        },
        low: {
          value: `${low.toFixed(2)}%`,
          isCritical: low > 1,
        },
        veryLow: {
          value: `${veryLow.toFixed(2)}%`,
          isCritical: veryLow > 1,
        },
      };
  }
};

export const getGmiAndGv = (data, patientAge, patientA1c) => {
  const isEmpty = hasEmptyChartData(data?.hourlyAggregation);
  const gmi = data?.gmi ? Number(data.gmi) : 0;
  const glucoseVariability = data?.glucoseVariability ? Number(data.glucoseVariability) : 0;

  switch (true) {
    case isEmpty:
      return {
        gmi: {
          value: '-',
          isCritical: false,
        },
        glucoseVariability: {
          value: '-',
          isCritical: false,
        },
      };
    case patientAge <= 65 && patientA1c < 9:
      return {
        gmi: {
          value: `${gmi.toFixed(2)}%`,
          isCritical: gmi > 7,
        },
        glucoseVariability: {
          value: `${glucoseVariability.toFixed(2)}%`,
          isCritical: glucoseVariability >= 36,
        },
      };
    default:
      return {
        gmi: {
          value: `${gmi.toFixed(2)}%`,
          isCritical: gmi > 8,
        },
        glucoseVariability: {
          value: `${glucoseVariability.toFixed(2)}%`,
          isCritical: glucoseVariability >= 36,
        },
      };
  }
};
