import PropTypes from 'prop-types';
import React from 'react';

import { Colors, ColorsNew } from 'common-src/styles';

import styles from './Badge.module.scss';

const Badge = ({
  id,
  max = 99,
  count = 0,
  height = 20,
  showZero = true,
  classNames = [],
  textColor = Colors.white,
  backgroundColor = ColorsNew.darkGreen,
}) => {
  if (!showZero && count === 0) return null;

  return (
    <span
      id={id}
      className={[
        styles.badge,
        'vertically-centered ',
        'horizontally-centered ',
        'font-s-12',
        'font-w-500',
        ...classNames,
      ].join(' ')}
      style={{ backgroundColor, color: textColor, height: `${height}px` }}
    >
      {count <= max ? count : `${max}+`}
    </span>
  );
};

Badge.propTypes = {
  id: PropTypes.string,
  max: PropTypes.number,
  count: PropTypes.number,
  showZero: PropTypes.bool,
  height: PropTypes.number,
  textColor: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
};

export default Badge;
