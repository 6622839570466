import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientHistoryEvent extends Model {
  static modelName = 'PatientHistoryEvent';

  static fields = {
    id: attr(),
    senderId: attr(),
    typeId: attr(),
    meta: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: attr(),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'historyEvents',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      senderId: PropTypes.number,
      typeId: PropTypes.number,
      meta: PropTypes.object,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientHistoryEvent;
