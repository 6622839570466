import PropTypes from 'prop-types';
import React from 'react';

import styles from './ExtendedItem.module.scss';

const ExtendedItem = ({ name, onClick, isSelected = false, hasActionItem = false }) => (
  <div
    id={`${name?.split(' ')?.[0].toLowerCase()}-extended-menu`}
    className={styles.container}
    onClick={onClick}
    role="presentation"
  >
    <span className={[styles.dot, styles.orange, isSelected ? styles.selected : ''].join(' ')} />
    <span
      className={[styles.name, 'font-w-500', 'font-s-16', isSelected ? styles.selected : ''].join(
        ' ',
      )}
    >
      {name}
    </span>
    {hasActionItem && (
      <span id="submenu-red-dot-indicator" className={[styles.dot, styles.red].join(' ')} />
    )}
  </div>
);

ExtendedItem.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  hasActionItem: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ExtendedItem;
