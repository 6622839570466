import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AddressInput, Input, Select } from 'common-src/components/base';
import { stateOptions } from 'common-src/constants/stateOptions';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { getFormattedPhoneNumber } from 'common-src/models/PatientPhoneNumber/helpers';
import {
  getPatientPreferredPharmacies,
  validatePatientPreferredPharmacy,
} from 'common-src/models/PatientPreferredPharmacy';
import { cleanedText, PHONE_MASK } from 'common-src/presenters';

import { AddButton, RemoveButton } from 'src/components/buttons';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from '../commonStyles.module.scss';
import { catchErrors, extractPharmacy, getInitialPharmacyData, getRequests } from './helpers';

const PreferredPharmacy = ({ patientId }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const preferredPharmacies = useCustomSelector((state) =>
    getPatientPreferredPharmacies(state, patientId),
  );

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: getRequests(formData, preferredPharmacies),
        successBlock: () => {
          setIsEditMode(false);
          setIsLoading(false);
          showAlert(AlertType.Success, '', 'You have successfully updated pharmacies.');
        },
        errorBlock: (err) => {
          setIsLoading(false);
          showAlert(AlertType.Error, '', err.message);
        },
      }),
    );
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    setErrors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'pharmacies',
  );

  useEffect(() => {
    const data = !_.isEmpty(preferredPharmacies)
      ? preferredPharmacies.map((pharmacy) => extractPharmacy(pharmacy))
      : [];

    setInitialData(data);
    setFormData(data);
  }, [preferredPharmacies]);

  const handleInputChange = (field, newValue) => {
    const [index, ...rest] = field.split('.');
    const validationField = rest.join('.');
    const err = validatePatientPreferredPharmacy(validationField, newValue);

    handleErrors({ [field]: err[validationField] });
    setFormData((prev) => {
      const newFormData = [...prev];
      newFormData[index] = {
        ...newFormData[index],
        [rest[0]]: newValue,
      };
      return newFormData;
    });
  };

  const handleAddressChange = (field, newValue) => {
    const [index, ...rest] = field.split('.');
    const validationField = rest.join('.');
    const err = validatePatientPreferredPharmacy(validationField, newValue);

    handleErrors({ [field]: err[validationField] });
    setFormData((prev) => {
      const newFormData = [...prev];
      newFormData[index] = {
        ...newFormData[index],
        address: {
          ...newFormData[index].address,
          [rest[1]]: newValue,
        },
      };
      return newFormData;
    });
  };

  const renderRemoveButton = (pharmacy) => {
    if (!isEditMode) return <div />;

    return (
      <RemoveButton
        id="remove-pharmacy"
        onClick={() => {
          const { errors } = catchErrors(_.without(formData, pharmacy));
          setErrors(errors);
          setFormData((prev) => _.without(prev, pharmacy));
        }}
      />
    );
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-pharmacy"
        classNames={[styles.button]}
        label="Add Pharmacy"
        onClick={() => setFormData((prev) => [...prev, getInitialPharmacyData(patientId)])}
      />
    );
  };

  const renderForm = () => (
    <>
      {formData.map((pharmacy, index) => (
        <div key={`pharmacy-${index}`} className={styles.grid}>
          <Input
            id={`pharmacy-name-${index}`}
            label="Full Name"
            onTextChange={(value) => handleInputChange(`${index}.name`, value)}
            value={pharmacy.name}
            placeholder="Enter Full Name"
            readOnly={!isEditMode}
            required
            size="small"
            errorText={errors[`${index}.name`]}
          />
          <Input
            id={`pharmacy-phone-${index}`}
            label="Phone Number"
            onTextChange={(value) => handleInputChange(`${index}.phone`, cleanedText(value))}
            value={isEditMode ? pharmacy.phone : getFormattedPhoneNumber(pharmacy.phone)}
            mask={PHONE_MASK}
            placeholder="Enter phone number"
            readOnly={!isEditMode}
            size="small"
            errorText={errors[`${index}.phone`]}
          />
          <span />
          <span />
          <span />
          <AddressInput
            key={`pharmacy-street1-${index}`}
            id={`pharmacy-street1-${index}`}
            classNames={[styles.width50]}
            errorText={errors[`${index}.address.street1`]}
            label="Address 1"
            placeholder="Enter street address"
            value={pharmacy.address.street1}
            onChange={(addressInfo) => {
              Object.keys(addressInfo).map((field) =>
                handleAddressChange(`${index}.address.${field}`, addressInfo[field] || ''),
              );
            }}
            onTextChange={(text) => handleAddressChange(`${index}.address.street1`, text)}
            readOnly={!isEditMode}
            size="small"
          />
          <Input
            key={`pharmacy-street2-${index}`}
            id={`pharmacy-street2-${index}`}
            errorText={errors[`${index}.address.street2`]}
            label="Address 2"
            onTextChange={(value) => handleAddressChange(`${index}.address.street2`, value)}
            value={pharmacy.address.street2}
            placeholder="Enter apt, unit, ste, etc."
            readOnly={!isEditMode}
            size="small"
          />
          <span />
          <span />
          <Input
            key={`pharmacy-city-${index}`}
            id={`pharmacy-city-${index}`}
            errorText={errors[`${index}.address.city`]}
            label="City"
            onTextChange={(value) => handleAddressChange(`${index}.address.city`, value)}
            value={pharmacy.address.city}
            placeholder="Enter city"
            readOnly={!isEditMode}
            size="small"
          />
          <Select
            key={`pharmacy-state-${index}`}
            id={`pharmacy-state-${index}`}
            errorText={errors[`${index}.address.state`]}
            label="State"
            placeholder="Select state"
            options={stateOptions}
            onChange={(selection) => handleAddressChange(`${index}.address.state`, selection.value)}
            value={stateOptions.find((op) => op.value === pharmacy.address.state)}
            paperHeight={350}
            isSearchable
            readOnly={!isEditMode}
            size="small"
            position="absolute"
          />
          <Input
            key={`pharmacy-zip-code-${index}`}
            id={`pharmacy-zip-code-${index}`}
            errorText={errors[`${index}.address.zipcode`]}
            label="Zip Code"
            onTextChange={(value) => handleAddressChange(`${index}.address.zipcode`, value)}
            value={pharmacy.address.zipcode}
            placeholder="Enter zip code"
            readOnly={!isEditMode}
            size="small"
          />
          {renderRemoveButton(pharmacy)}
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
    </>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

PreferredPharmacy.propTypes = {
  patientId: PropTypes.number,
};

export default PreferredPharmacy;
