import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getClientsRequest = (params) =>
  restRequest({ uri: 'clients', method: HttpMethods.Get, ...params });

export const getClientRequest = (id, params) =>
  restRequest({ uri: `clients/${id}`, method: HttpMethods.Get, ...params });

export const updateClientRequest = (id, body, params) =>
  restRequest({ uri: `clients/${id}`, method: HttpMethods.Put, body, ...params });

export const createClientRequest = (body, params) =>
  restRequest({ uri: 'clients', method: HttpMethods.Post, body, ...params });

export const offboardClient = (id, params) =>
  restRequest({ uri: `clients/offboard/${id}`, method: HttpMethods.Put, body: {}, ...params });
