import PropTypes from 'prop-types';
import React from 'react';

import { Accordion } from 'common-src/components/base';

import { SocialHistory } from './forms';
import styles from './Social.module.scss';

const Social = ({ patientId, actionsAllowed }) => (
  <article className={styles.article}>
    <div className={styles.content}>
      <Accordion
        title="Social history"
        isDefaultExpanded
        content={<SocialHistory patientId={patientId} disabled={!actionsAllowed} />}
      />
    </div>
  </article>
);

Social.propTypes = {
  patientId: PropTypes.number,
  actionsAllowed: PropTypes.bool,
};

export default Social;
