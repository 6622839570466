import { inactiveSmartGoalStatuses } from 'common-src/models/PatientSmartGoal';

import { SmartGoalTabs } from './constants';

export const getTabInfo = (goals = []) => {
  const activeGoals = [];
  const inactiveGoals = [];

  [...goals].forEach((goal) => {
    if (inactiveSmartGoalStatuses.includes(goal.progressStatus)) {
      inactiveGoals.push(goal);
      return;
    }

    activeGoals.push(goal);
  });

  const tabInfo = {
    ACTIVE: {
      text: SmartGoalTabs.ACTIVE.text,
      count: activeGoals.length,
    },
    PAST_GOALS: {
      text: SmartGoalTabs.PAST_GOALS.text,
      count: inactiveGoals.length,
    },
  };

  return {
    tabInfo,
    activeGoals,
    inactiveGoals,
  };
};
