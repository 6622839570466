import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientCareProvidersRequest = (patientId, params) =>
  restRequest({
    uri: 'patientCareProviders',
    query: { patient_id: patientId },
    method: HttpMethods.Get,
    ...params,
  });

export const createCareProvider = (body, params) =>
  restRequest({
    uri: 'patientCareProviders',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateCareProvider = (body, params) =>
  restRequest({
    uri: `patientCareProviders/${body.id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const deleteCareProvider = (id, params) =>
  restRequest({
    uri: `patientCareProviders/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
