import { roles } from 'common-src/models/Client/constants';

export const getIsLoggedIn = (state) => state.auth.isLoggedIn;
export const getCurrentUserId = (state) => state.auth.userId;
export const getCurrentOrgId = (state) => state.auth.orgId;
export const getCurrentRoleId = (state) => state.auth.roleId;
export const getRoleConfig = (state) => state.auth.roleConfig;
export const getLoginError = (state) => state.auth.loginError;
export const getIsSuperUser = (state) => state.auth?.isSuper || false;
export const getCalculatedRoleId = (state) => {
  const isSuperUser = getIsSuperUser(state);
  return isSuperUser ? roles.Admin.id : getCurrentRoleId(state);
};
