import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomCheckbox } from 'common-src/components/base';

import { getClientById, getClientName, getDataByType } from './helpers';
import styles from './RegalInfoItem.module.scss';

const RegalInfoItem = ({ info, regalTaskIds, setRegalTaskIds, relatedClients }) => {
  if (!info) return null;

  const {
    id,
    clientId,
    type,
    startedAt,
    taskReservationAcceptedAt,
    taskReservationCreatedAt,
    taskCreatedAt,
    talkTime,
    wrapupTime,
  } = info;
  const { label, icon } = getDataByType(type);
  const isChecked = regalTaskIds.includes(id);
  const client = getClientById(relatedClients, clientId);

  const checkboxHandler = () =>
    setRegalTaskIds((prev) => (prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]));

  const getTaskCreatedAt = () => {
    const createdAt =
      startedAt || taskReservationAcceptedAt || taskReservationCreatedAt || taskCreatedAt;
    const isToday = moment(createdAt).isSame(moment(), 'day');

    if (isToday) {
      return `Today at ${moment(createdAt).format('h:mmA')}`;
    }

    return moment(createdAt).format('MM/DD/YYYY [at] h:mmA');
  };

  const getDuration = () => {
    let duration = 0;

    if (talkTime) {
      duration += talkTime;
    }

    if (wrapupTime) {
      duration += wrapupTime;
    }

    if (!duration) return '';

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor(duration / 60) % 60;
    const seconds = Math.ceil(duration % 60);

    return `${hours ? `${hours} hours ` : ''}${minutes ? `${minutes} min ` : ''}${
      seconds ? `${seconds} seconds` : ''
    }`;
  };

  return (
    <div
      className={[
        styles.container,
        isChecked ? styles.checked : '',
        'flex-row',
        'p-12',
        'm-t-16',
        'primary-border',
        'border-r-12',
        'gap-10',
      ].join(' ')}
      role="presentation"
      onClick={checkboxHandler}
    >
      <img className={styles.icon} src={icon} alt="regal-event-icon" />
      <div className="flex-column flex-1 gap-4">
        <span id="provider-name" className={[styles.title, 'font-s-12', 'font-w-600'].join(' ')}>
          {getClientName(client)}
        </span>
        <p className={[styles.content, 'font-w-700'].join(' ')}>
          <span id="regal-type" className="font-s-16">
            {label}
          </span>
          <span id="regal-time" className="font-s-10 m-l-5">
            {getTaskCreatedAt()}
          </span>
        </p>
        <span
          id="regal-duration-and-id"
          className={[styles.addition, 'font-s-10', 'font-w-500'].join(' ')}
        >
          {getDuration()} - ID {id}
        </span>
      </div>
      <CustomCheckbox
        id={`${id}-link-event-checkbox`}
        classNames={[styles.checkbox, 'm-r-20']}
        header={isChecked ? 'Event linked' : 'Link Event'}
        onChange={checkboxHandler}
        checked={isChecked}
      />
    </div>
  );
};

RegalInfoItem.propTypes = {
  info: PropTypes.object,
  regalTaskIds: PropTypes.array,
  setRegalTaskIds: PropTypes.func,
  relatedClients: PropTypes.array,
};

export default RegalInfoItem;
