import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  getPatientCareProviders,
  providerTypeOptions,
  validatePatientCareProvider,
} from 'common-src/models/PatientCareProvider';
import { getFormattedPhoneNumber } from 'common-src/models/PatientPhoneNumber/helpers';
import { cleanedText, PHONE_MASK } from 'common-src/presenters';

import { AddButton, RemoveButton } from 'src/components/buttons';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from '../commonStyles.module.scss';
import {
  catchErrors,
  extractCareProvider,
  getInitialProviderData,
  getRequests,
  resetCareProviderErrors,
} from './helpers';

const Providers = ({ patientId }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const careProviders = useCustomSelector((state) => getPatientCareProviders(state, patientId));

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: getRequests(formData, careProviders),
        successBlock: () => {
          setIsEditMode(false);
          setIsLoading(false);
          showAlert(AlertType.Success, '', 'You have successfully updated providers.');
        },
        errorBlock: (err) => {
          setIsLoading(false);
          showAlert(AlertType.Error, '', err.message);
        },
      }),
    );
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'providers',
  );

  useEffect(() => {
    const data = !_.isEmpty(careProviders)
      ? careProviders.map((contact) => extractCareProvider(contact))
      : [];

    setInitialData(data);
    setFormData(data);
  }, [careProviders]);

  const handleInputChange = (field, newValue) => {
    const validationField = field.split('.')[1];
    const err = validatePatientCareProvider(validationField, newValue);

    handleErrors({ [field]: err[validationField] });
    setFormData((prev) => _.set(prev, field, newValue === null ? null : newValue));
  };

  const renderRemoveButton = (contact) => {
    if (!isEditMode) return <div />;

    return (
      <div className="flex-row">
        <RemoveButton
          id="remove-provider"
          onClick={() => {
            handleErrors(resetCareProviderErrors(formData, contact));
            setFormData((prev) => _.without(prev, contact));
          }}
        />
        <div />
      </div>
    );
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-provider"
        classNames={[styles.button]}
        label="Add Provider"
        onClick={() => setFormData((prev) => [...prev, getInitialProviderData(patientId)])}
      />
    );
  };

  const renderForm = () => (
    <>
      {formData.map((provider, index) => (
        <div key={`care-provider-${index}`} className={styles.grid}>
          <Input
            id={`provider-name-${index}`}
            label="Full Name"
            onTextChange={(value) => handleInputChange(`${index}.name`, value)}
            value={provider.name}
            placeholder="Enter Full Name"
            readOnly={!isEditMode}
            required
            size="small"
            errorText={errors[`${index}.name`]}
          />
          <Select
            id={`provider-type-${index}`}
            placeholder="Provider Type"
            options={providerTypeOptions}
            value={providerTypeOptions.find((op) => op.value === provider.typeId)}
            label="Provider Type"
            required
            readOnly={!isEditMode}
            onChange={(selection) => handleInputChange(`${index}.typeId`, selection?.value)}
            isSearchable
            position="absolute"
            paperHeight={250}
            size="small"
            errorText={errors[`${index}.typeId`]}
          />
          <Input
            id={`provider-phone-${index}`}
            label="Phone Number"
            onTextChange={(value) => handleInputChange(`${index}.phone`, cleanedText(value))}
            value={isEditMode ? provider.phone : getFormattedPhoneNumber(provider.phone)}
            mask={PHONE_MASK}
            placeholder="Enter phone number"
            readOnly={!isEditMode}
            size="small"
            errorText={errors[`${index}.phone`]}
          />
          <Input
            id={`provider-fax-${index}`}
            label="Fax"
            onTextChange={(value) => handleInputChange(`${index}.fax`, cleanedText(value))}
            value={isEditMode ? provider.fax : getFormattedPhoneNumber(provider.fax)}
            mask={PHONE_MASK}
            placeholder="Fax"
            readOnly={!isEditMode}
            size="small"
            errorText={errors[`${index}.fax`]}
          />
          {renderRemoveButton(provider)}
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
    </>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

Providers.propTypes = {
  patientId: PropTypes.number,
};

export default Providers;
