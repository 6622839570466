import { ExemptionSource, ExemptionType } from 'common-src/models/PatientBillingExemption';

export const initialState = {
  exemptionType: ExemptionType.QMB.value,
  startDate: null,
  endDate: null,
  source: ExemptionSource.UserInput.value,
};

export const UPDATABLE_FIELDS = ['endDate'];
