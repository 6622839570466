import _ from 'lodash';

import { ACTIVE_STATUSES, ALL_PLAN_STATUSES } from 'common-src/models/PatientCoverage';

import { KnownPlansTabs } from './constants';

export const getTabInfo = (plans = [], filters) => {
  const activePlans = [];
  const inactivePlans = [];

  const sortedPlans = [...plans]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .filter((plan) =>
      filters.status.value === ALL_PLAN_STATUSES.value
        ? plan
        : plan.status === filters.status.value,
    );

  sortedPlans.forEach((plan) => {
    if (ACTIVE_STATUSES.includes(plan.status)) {
      activePlans.push(plan);
      return;
    }

    inactivePlans.push(plan);
  });

  const tabInfo = {
    ACTIVE: {
      text: KnownPlansTabs.ACTIVE.text,
      count: activePlans.length,
    },
    NOT_ACTIVE: {
      text: KnownPlansTabs.NOT_ACTIVE.text,
      count: inactivePlans.length,
    },
    ALL: {
      text: KnownPlansTabs.ALL.text,
      count: sortedPlans.length,
    },
  };

  return {
    tabInfo,
    activePlans,
    inactivePlans,
    allPlans: sortedPlans,
  };
};

export const getTableHeaders = (showHierarchy) =>
  _.compact([
    { column: 'payorName', label: 'Payor Name', width: '20%', sortBy: 'text' },
    { column: 'planName', label: 'Plan Name', width: '20%', sortBy: 'text' },
    showHierarchy && { column: 'hierarchy', label: 'Hierarchy', width: '10%', sortBy: 'text' },
    { column: 'memberId', label: 'Member ID', width: '10%', sortBy: 'text' },
    { column: 'startDate', label: 'Start Date', width: '10%', sortBy: 'value' },
    { column: 'endDate', label: 'End Date', width: '10%', sortBy: 'value' },
    { column: 'status', label: 'Status', width: '15%', sortBy: 'text' },
    { column: 'actions', label: 'Actions', width: '5%' },
  ]);
