import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const createPatientDevice = (body, params) =>
  restRequest({
    uri: 'patientDevices',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const getPatientDevicesRequest = (query, params) =>
  restRequest({
    uri: 'patientDevices',
    query,
    method: HttpMethods.Get,
    ...params,
  });
