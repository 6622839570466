import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, Menu } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';

import styles from './ActionsButton.module.scss';

const ActionsButton = ({ id, options, disabled, paperWidth, classNames = [] }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <Menu
      id={id}
      button={
        <Button
          id="actions-button"
          classNames={[styles.button, ...classNames]}
          iconSrc={CommonIcons.actions}
          text="Actions"
          onClick={() => setIsMenuOpened(true)}
          disabled={disabled}
          textColor={ColorsNew.darkGreen}
          backgroundColor={isMenuOpened ? ColorsNew.lighterGreen : 'transparent'}
          borderColor={isMenuOpened ? ColorsNew.lighterGreen : 'transparent'}
          width={105}
        />
      }
      options={options}
      paperWidth={paperWidth || 250}
      onCloseMenu={() => setIsMenuOpened(false)}
    />
  );
};

ActionsButton.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classNames: PropTypes.arrayOf(PropTypes.string),
  paperWidth: PropTypes.number,
};

export default ActionsButton;
