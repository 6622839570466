import PropTypes from 'prop-types';
import React from 'react';

import styles from './Indicator.module.scss';

const Indicator = ({ text }) => (
  <div className={styles.wrapper}>
    <span
      className={[
        styles.text,
        'font-s-12',
        'font-w-500',
        'p-12',
        'secondary-border',
        'border-r-6',
      ].join(' ')}
    >
      {text}
    </span>
  </div>
);

Indicator.propTypes = {
  text: PropTypes.string,
};

export default Indicator;
