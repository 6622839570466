import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import styles from './Option.module.scss';

const Option = ({ option, isSelected, onClick }) => (
  <div
    className={[styles.option, isSelected ? styles.selected : '', 'vertically-centered'].join(' ')}
    onClick={(e) => onClick(e)}
    role="presentation"
  >
    <span id={`option-${option.label}`} className={['flex-1', 'font-w-400', 'font-s-12'].join(' ')}>
      {option.label}
    </span>
    {isSelected && <img src={CommonIcons.circleCheckIcon} alt="check-icon" />}
  </div>
);

Option.propTypes = {
  onClick: PropTypes.func,
  option: PropTypes.object,
  isSelected: PropTypes.bool,
};

export default Option;
