import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import PatientSurgicalHistory from 'common-src/models/PatientSurgicalHistory';

import { InfoItem } from 'src/components/elements';
import BasePopup from 'src/popups/BasePopup';

import styles from './SurgicalHistoryDetailsPopup.module.scss';

const SurgicalHistoryDetailsPopup = ({ surgicalHistory, onClose }) => (
  <BasePopup id="supplement-details" open onClose={onClose} title={surgicalHistory.procedure}>
    <div className={styles.firstGrid}>
      <InfoItem
        title="Date Added"
        content={
          surgicalHistory.createdAt && moment(surgicalHistory.createdAt).format('MM/DD/YYYY')
        }
        textId="date-added"
        small
      />
      <InfoItem
        title="Added By"
        content={surgicalHistory.createdBy?.getName(true, false)}
        textId="added-by"
        small
      />
      <InfoItem
        title="Date Of Surgery"
        content={
          surgicalHistory?.dateOfSurgery
            ? moment(surgicalHistory.dateOfSurgery).format('MM/DD/YYYY')
            : '-'
        }
        textId="date-of-surgery"
        small
      />
    </div>
    <InfoItem
      classNames={[styles.comments, 'primary-border-b', 'primary-border-t']}
      title="Comments"
      content={surgicalHistory.comments}
      textId="comments"
      small
    />
    <div className={styles.secondGrid}>
      <InfoItem
        title="Last Updated"
        content={
          surgicalHistory.updatedAt && moment(surgicalHistory.updatedAt).format('MM/DD/YYYY')
        }
        textId="date-updated"
        small
      />
      <InfoItem
        title="Last Updated By"
        content={surgicalHistory.updatedBy?.getName(true, false)}
        textId="updated-by"
        small
      />
    </div>
  </BasePopup>
);

SurgicalHistoryDetailsPopup.propTypes = {
  onClose: PropTypes.func,
  surgicalHistory: PropTypes.exact(PatientSurgicalHistory.schema),
};

export default SurgicalHistoryDetailsPopup;
