import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { clearOrm } from 'common-src/features/orm/actions';
import { apiRequest, HttpMethods } from 'common-src/features/rest';

import { loginError, loginSuccess, logOut, logOutError } from './actions';
import { GOOGLE_SSO_LOGIN_REQUEST, LOGIN_REQUEST, LOGOUT_REQUEST } from './actionTypes';
import { googleSsoEndpoint, loginEndpoint, logOutEndpoint } from './api';

function* executeLoginRequest({ endpoint, payload, errorBlock }) {
  try {
    const response = yield call(apiRequest, { endpoint, method: HttpMethods.Post, body: payload });
    const responseBody = yield response.json();
    if (response.status !== 200) {
      const errorMessage =
        responseBody?.message ||
        (response?.status === 401
          ? 'Incorrect username or password'
          : 'An error occurred on login');

      throw new Error(errorMessage);
    }

    const { perryClinicOrgId, user, bearerToken } = responseBody;
    const { id, orgId, email, roleId, isSuper } = user;

    yield put(
      loginSuccess({ userId: id, orgId, email, roleId, perryClinicOrgId, bearerToken, isSuper }),
    );
  } catch (e) {
    console.error('error', e);

    const { message } = e;
    yield put(clearOrm());
    yield put(loginError(message));
    if (errorBlock) errorBlock(message);
    console.error('Got error', message);
  }
}

function* handleLoginRequest(action) {
  const {
    username,
    password,
    userCategoryId,
    usernameType,
    successBlock,
    errorBlock,
    offlineBlock,
  } = action.payload;

  const loginPayload = {
    username,
    password,
    user_category_id: userCategoryId,
    username_type: usernameType,
    client_type: 'wa',
  };

  yield call(executeLoginRequest, {
    endpoint: loginEndpoint,
    payload: loginPayload,
    successBlock,
    errorBlock,
    offlineBlock,
  });
}

function* handleGoogleSsoRequest(action) {
  const { token, successBlock, errorBlock } = action.payload;

  yield call(executeLoginRequest, {
    endpoint: googleSsoEndpoint,
    payload: { token },
    successBlock,
    errorBlock,
  });
}

function* handleLogout(action) {
  const { successBlock, errorBlock, isSessionExpired } = action.payload;
  try {
    if (!isSessionExpired) {
      yield call(apiRequest, {
        endpoint: logOutEndpoint,
        method: HttpMethods.Post,
      });
    }
    // NOTE(peter): Intentionally do not handle this logout response
    // The logout request itself requires a valid session auth cookie so it may fail if the cookie expires

    if (successBlock) successBlock();
    yield put(logOut());
  } catch (e) {
    const { message } = e;
    if (errorBlock) errorBlock(message);
    yield put(logOutError(message));
  }
}

function* loginWatcher() {
  yield takeEvery(LOGIN_REQUEST, handleLoginRequest);
}

function* logoutWatcher() {
  yield takeEvery(LOGOUT_REQUEST, handleLogout);
}

function* googleSsoWatcher() {
  yield takeEvery(GOOGLE_SSO_LOGIN_REQUEST, handleGoogleSsoRequest);
}

export function* authWatcher() {
  yield all([fork(loginWatcher), fork(logoutWatcher), fork(googleSsoWatcher)]);
}
