export const AllergyType = Object.freeze({
  FoodIntolerance: { value: 1, label: 'Food intolerance' },
  FoodAllergy: { value: 2, label: 'Food allergy' },
  DrugAllergy: { value: 3, label: 'Drug allergy' },
  PropensityToAdverseReactionsToSubstance: {
    value: 4,
    label: 'Propensity to adverse reactions to substance',
  },
  PropensityToAdverseReactionsToFood: {
    value: 5,
    label: 'Propensity to adverse reactions to food',
  },
  AllergyToSubstance: { value: 6, label: 'Allergy to substance' },
  PropensityToAdverseReactionsToDrug: {
    value: 7,
    label: 'Propensity to adverse reactions to drug',
  },
  PropensityToAdverseReactions: { value: 8, label: 'Propensity to adverse reactions' },
  DrugIntolerance: { value: 9, label: 'Drug intolerance' },
});

export const longAllergyTypeLabels = [
  AllergyType.PropensityToAdverseReactionsToSubstance.value,
  AllergyType.PropensityToAdverseReactionsToFood.value,
  AllergyType.PropensityToAdverseReactionsToDrug.value,
  AllergyType.PropensityToAdverseReactions.value,
];

export const AllergyReaction = Object.freeze({
  SkinRash: { value: 1, label: 'Skin Rash' },
  Hives: { value: 2, label: 'Hives' },
  RespiratoryDistress: { value: 3, label: 'Respiratory Distress' },
  LaryngealEdema: { value: 4, label: 'Laryngeal Edema' },
  AsthmaticAttack: { value: 5, label: 'Asthmatic Attack' },
  Syncope: { value: 6, label: 'Syncope' },
  Anaphylaxis: { value: 7, label: 'Anaphylaxis' },
  Diarrhea: { value: 8, label: 'Diarrhea' },
  Dizziness: { value: 9, label: 'Dizziness' },
  Wheezing: { value: 10, label: 'Wheezing' },
  Other: { value: 11, label: 'Other' },
  Unknown: { value: 12, label: 'Unknown' },
});

export const AllergySeverity = Object.freeze({
  Mild: { value: 1, label: 'Mild', color: '#9D97C7' },
  Moderate: { value: 2, label: 'Moderate', color: '#F1BF72' },
  Severe: { value: 3, label: 'Severe', color: '#F06C67' },
  Fatal: { value: 4, label: 'Fatal', color: '#F06C67' },
});
