import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

const useTime = (delayInMillis, format, tz, timeToFormat) => {
  const [time, setTime] = useState(timeToFormat || new Date());
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const formattedTime = moment(time)
    .tz(tz || timeZone)
    .format(format);

  useEffect(() => {
    const id = setInterval(() => {
      setTime(timeToFormat || new Date());
    }, delayInMillis);

    return () => {
      clearInterval(id);
    };
  }, [delayInMillis]);

  return {
    time: formattedTime,
  };
};

export default useTime;
