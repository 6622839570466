export const PayorType = Object.freeze({
  Medicare: { value: 1, label: 'Medicare' },
  MA: { value: 2, label: 'Medicare Advantage' },
  Commercial: { value: 3, label: 'Commercial' },
  Medicaid: { value: 4, label: 'Medicaid' },
  Other: { value: 5, label: 'Other' },
  Supplemental: { value: 6, label: 'Supplemental' },
});

export const PayorTypeOptions = Object.values(PayorType).filter(
  (type) => type.value !== PayorType.Other.value,
);

export const PlanSource = Object.freeze({
  Wave: { value: 1, label: 'Wave' },
  Salesforce: { value: 2, label: 'Salesforce' },
  UserInput: { value: 3, label: 'User Input' },
});

export const PlanType = Object.freeze({
  PlanA: { value: 1, label: 'Plan A' },
  PlanB: { value: 2, label: 'Plan B' },
  PlanC: { value: 3, label: 'Plan C' },
  PlanD: { value: 4, label: 'Plan D' },
  PlanE: { value: 5, label: 'Plan E' },
  PlanF: { value: 6, label: 'Plan F' },
  PlanG: { value: 7, label: 'Plan G' },
  PlanH: { value: 8, label: 'Plan H' },
  PlanI: { value: 9, label: 'Plan I' },
  PlanJ: { value: 10, label: 'Plan J' },
  PlanK: { value: 11, label: 'Plan K' },
  PlanL: { value: 12, label: 'Plan L' },
  PlanMA: { value: 13, label: 'Plan MA' },
  PlanMW: { value: 14, label: 'Plan MW' },
  PlanMX: { value: 15, label: 'Plan MX' },
  PlanMY: { value: 16, label: 'Plan MY' },
  PlanM6: { value: 17, label: 'Plan M6' },
  PlanN: { value: 18, label: 'Plan N' },
  PlanP5: { value: 19, label: 'Plan P5' },
  PlanP6: { value: 20, label: 'Plan P6' },
  PlanP7: { value: 21, label: 'Plan P7' },
  PlanRE: { value: 22, label: 'Plan RE' },
  PlanRH: { value: 23, label: 'Plan RH' },
  PlanV1: { value: 24, label: 'Plan V1' },
});

export const PlanStatus = Object.freeze({
  Verified: { value: 1, label: 'Verified', color: '#637768' },
  Expired: { value: 2, label: 'Expired', color: '#F06C67' },
  NeedsVerification: { value: 3, label: 'Needs Verification', color: '#F1BF72' },
  EnteredInError: { value: 4, label: 'Entered in Error', color: '#F06C67' },
});

export const ALL_PLAN_STATUSES = Object.freeze({ value: -1, label: 'All Statuses' });

export const planStatusOptions = [ALL_PLAN_STATUSES, ...Object.values(PlanStatus)];

export const SubscriberRelationship = Object.freeze({
  Self: { value: 1, label: 'Self' },
  Spouse: { value: 2, label: 'Spouse' },
  Child: { value: 3, label: 'Child' },
});

export const ACTIVE_STATUSES = [PlanStatus.Verified.value, PlanStatus.NeedsVerification.value];

export const UPDATABLE_COVERAGE_FIELDS = [
  'memberId',
  'status',
  'startDate',
  'endDate',
  'subscriberFirstName',
  'subscriberLastName',
  'subscriberDateOfBirth',
];

export const PrescriptionProviderOptions = Object.freeze({
  ePrescription: {
    label: 'ePrescription',
    value: 1,
  },
  faxWrittenOrder: {
    label: 'Fax Written Order',
    value: 2,
  },
});

export const PrescriptionDestinations = Object.freeze({
  AdvancedDiabetesSupply: 'Advanced Diabetes Supply',
  Smiles: 'Smiles',
  USMed: 'US Med',
  CCSMedical: 'CCS Medical',
  LocalPharmacy: 'Local Pharmacy',
  FaxWrittenOrder: 'Fax Written Order',
});

export const Payors = Object.freeze({
  UnitedHealthcare: { value: 'UHC', label: 'United Healthcare' },
  Humana: { value: 'HUMANA', label: 'Humana' },
  Medicare: { value: 'MCNY', label: 'Medicare' },
});

export const MemberIdValidationByPayor = Object.freeze({
  MCNY: {
    regex: new RegExp(/^(?!.*[SLOIBZ])[1-9][A-Z][\dA-Z]\d[A-Z][\dA-Z]\d[A-Z]{2}\d{2}$/, 'gi'),
    msg: 'Enter 1 numeric (cannot be 0), + 1 alpha, + 1 alpha/numeric, + 1 numeric, + 1 alpha, + 1 alpha numeric , + 1 numeric, + 2 alpha, + 2 numeric. (S, L, O, I, B, Z are NOT allowed in any position',
  },
  MCRR: {
    regex: new RegExp(/^(?!.*[SLOIBZ])[1-9][A-Z][\dA-Z]\d[A-Z][\dA-Z]\d[A-Z]{2}\d{2}$/, 'gi'),
    msg: 'Enter 1 numeric (cannot be 0), + 1 alpha, + 1 alpha/numeric, + 1 numeric, + 1 alpha, + 1 alpha numeric , + 1 numeric, + 2 alpha, + 2 numeric. (S, L, O, I, B, Z are NOT allowed in any position',
  },
  AMBETTER: {
    regex: new RegExp(/^(U)\d{8,10}$/, 'gi'),
    msg: 'Member ID must be the letter U followed by 8-10 numeric values',
  },
  BCBSIL: {
    regex: new RegExp(/^[a-zA-Z]{3}[a-zA-Z0-9]{6,14}$/, 'gi'),
    msg: 'Member ID must be 3 letters followed by 6 to 14 letters or numbers',
  },
  BCBSMC: {
    regex: new RegExp(
      /^((YDJ)|(YDL)|(XOD)|(XOJ)|(ZGD)|(ZGJ)|(YID)|(YIJ)|(YUX)|(YUB)|(ZZT)).+/,
      'gi',
    ),
    msg: 'Member ID must begin with YDJ, YDL, XOD, XOJ, ZGD, ZGJ, YID, YIJ, YUX, ZZT or YUB',
  },
  BCBSTX: {
    regex: new RegExp(/^[a-zA-Z]{3}[a-zA-Z0-9]{6,14}$/, 'gi'),
    msg: 'Member ID must be 3 letters followed by 6 to 14 letters or numbers',
  },
  BCBSVA: {
    regex: new RegExp(/^[a-zA-Z]{3}[a-zA-Z0-9]{6,14}$/, 'gi'),
    msg: 'Member ID must be 3 letters followed by 6 to 14 letters or numbers',
  },
  CIGNA: {
    regex: new RegExp(/^[A-Z\d ]{7,13}(00){0,1}$/, 'gi'),
    msg: 'Member ID must be 7 to 13 alphanumeric, with or without an additional 00 at the end. A space is allowed in the id',
  },
  KAISERMA: {
    regex: new RegExp(/^[A-Z\d]{2,10}$/, 'gi'),
    msg: 'Member ID cannot exceed 10 alphanumeric characters',
  },
  MDAL: {
    regex: new RegExp(/^(5)\d{12}$/, 'gi'),
    msg: 'Member ID must be the number 5 followed by 12 numeric values',
  },
  MDFL: {
    regex: new RegExp(/^\d{10}$/, 'gi'),
    msg: 'Member ID must be 10 numeric values',
  },
  MDTX: {
    regex: new RegExp(/^\d{9}$/, 'gi'),
    msg: 'Member ID must be 9 numeric values',
  },
  MDVA: {
    regex: new RegExp(/^\d{12}$/, 'gi'),
    msg: 'Member ID must be 12 numeric values',
  },
  PEOPLE: {
    regex: new RegExp(/^(G)\d{10}$/, 'gi'),
    msg: 'Member ID must be the letter G followed by 10 numeric values',
  },
  UHC: {
    regex: new RegExp(/^\d{9}$/, 'gi'),
    msg: 'Member ID must be 9 numeric values',
  },
  HUMANA: {
    regex: new RegExp(/^(H)\d{8}$/, 'gi'),
    msg: 'Member ID must be the letter H followed by 8 numeric values',
  },
  AETNAMC: {
    regex: new RegExp(/^\d{12}$/, 'gi'),
    msg: 'Member ID must be 12 digits',
  },
  AMERIGROUP: {
    regex: new RegExp(/^[0-9]{3}[a-zA-Z]{1}[0-9]{5}$/, 'gi'),
    msg: 'Member ID must be 9 digits with a letter in the 4th spot',
  },
  CRPLS: {
    regex: new RegExp(/^\d{9}$/, 'gi'),
    msg: 'Member ID must be 9 digits',
  },
  CLOVER: {
    regex: new RegExp(/^(CP)\d{7}$/, 'gi'),
    msg: 'Member ID must begin with CP followed by 7 digits',
  },
  HEALTHSUN: {
    regex: new RegExp(/^(HS)\d{6}$/, 'gi'),
    msg: 'Member ID must begin with HS followed by 6 digits',
  },
  DEVOTED: {
    regex: new RegExp(/^[0-9a-zA-Z]{6}$/, 'gi'),
    msg: 'Member ID must be 6 alphanumeric values',
  },
  CARENCARE: {
    regex: new RegExp(/^(CC)\d{7}$/, 'gi'),
    msg: 'Member ID must begin with CC followed by 7 digits',
  },
  FREEDOM: {
    regex: new RegExp(/^(P)\d{10}$/, 'gi'),
    msg: 'Member ID must begin with P followed by 10 digits',
  },
  HFHPADVNT: {
    regex: new RegExp(/^\d{11}$/, 'gi'),
    msg: 'Member ID must be 11 digits',
  },
  SUPERIORHLTH: {
    regex: new RegExp(/^(C)\d{10}$/, 'gi'),
    msg: 'Member ID must begin with C followed by 10 digits',
  },
});
