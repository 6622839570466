import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientSmartGoal extends Model {
  static modelName = 'PatientSmartGoal';

  static fields = {
    id: attr(),
    goal: attr(),
    typeIds: attr(),
    isPatientOriginated: attr(),
    startDate: attr(),
    followUpDate: attr(),
    isActive: attr(),
    progressStatus: attr(),
    stageOfChangeStatus: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'smartGoalsChanger'),
    createdBy: fk('Client', 'smartGoalsCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'smartGoals',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      goal: PropTypes.string,
      typeIds: PropTypes.arrayOf(PropTypes.number),
      isPatientOriginated: PropTypes.bool,
      startDate: PropTypes.string,
      followUpDate: PropTypes.string,
      isActive: PropTypes.bool,
      progressStatus: PropTypes.number,
      stageOfChangeStatus: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientSmartGoal;
