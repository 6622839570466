import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'common-src/components/base';
import { getScheduleVisitQueryParams, LinkType } from 'common-src/models/PatientOncehubBooking';
import { getPrimaryPatientPhoneNumber } from 'common-src/models/PatientPhoneNumber';
import PatientTask, {
  getTaskReasonById,
  getTaskTypeById,
  PatientTaskStatus,
} from 'common-src/models/PatientTask';
import { updatePatientTask } from 'common-src/models/PatientTask/actions';
import { Colors, ColorsNew } from 'common-src/styles';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './CurieAICard.module.scss';

const CurieAICard = ({ task, patientId }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const primaryPatientPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patientId),
  );

  const { showAlert, AlertType } = useAlert();

  const linkHandler = () => {
    if (!task.link) return;

    const params = task.link.includes(LinkType.Oncehub.toLowerCase())
      ? getScheduleVisitQueryParams(patient, primaryPatientPhoneNumber, LinkType.Oncehub)
      : '';

    window.open(`${task.link}${params}`, '_blank');
  };

  const completeTaskHandler = () => {
    setIsLoading(true);
    dispatch(
      updatePatientTask(
        task.id,
        { status: PatientTaskStatus.Completed.value },
        {
          successBlock: () => {
            showAlert(AlertType.Success, '', 'Task completed!');
            setIsLoading(false);
          },
          errorBlock: (err) => {
            showAlert(AlertType.Error, '', err);
            setIsLoading(false);
          },
        },
      ),
    );
  };

  return (
    <div className={[styles.card, 'm-b-20', 'primary-border', 'border-r-10'].join(' ')}>
      <header className={[styles.header, 'primary-border-b'].join(' ')}>
        <h1 className={['font-s-14', 'font-w-700', 'm-b-6'].join(' ')}>
          {getTaskTypeById(task?.typeId)?.label || ''}
        </h1>
        <p className={['font-s-10', 'font-w-400'].join(' ')}>
          {getTaskReasonById(task?.reasonId)?.label || ''}
        </p>
      </header>
      <p
        className={[
          styles.link,
          'font-s-10',
          'font-w-500',
          'primary-border-b',
          task.link ? 'cursor-pointer' : '',
        ].join(' ')}
        onClick={linkHandler}
        role="presentation"
      >
        {task.subject}
      </p>
      <footer className={[styles.footer].join(' ')}>
        <Button
          id="complete-button"
          classNames={[styles.button]}
          text="Complete"
          onClick={completeTaskHandler}
          textColor={Colors.white}
          backgroundColor={ColorsNew.baseGreen}
          borderColor="transparent"
          isLoading={isLoading}
        />
      </footer>
    </div>
  );
};

CurieAICard.propTypes = {
  task: PropTypes.exact(PatientTask.schema),
  patientId: PropTypes.number,
};

export default CurieAICard;
