import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './ConfirmReassignmentPopup.module.scss';

const ConfirmReassignmentPopup = ({ open, onClose, onSubmit }) => {
  const { renderButtons } = usePopup();
  return (
    <BasePopup id="confirm-reassignment" open={open} onClose={onClose}>
      <div className={[styles.header, 'vertically-centered'].join(' ')}>
        <span className={styles.title}>Confirm reassignment</span>
        <img
          id="close-button"
          className="icon-btn"
          src={CommonIcons.closeIcon}
          alt="close-icon"
          onClick={onClose}
          role="presentation"
        />
      </div>
      <span className={[styles.subtitle, 'font-s-14'].join(' ')}>
        Are you sure you want to reassign this patient issue to another team member? This will
        remove the issue from your My Tasks list.
      </span>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        buttonClassName: styles.button,
        onClose,
        onSubmit: (loadingCallback) => {
          loadingCallback();
          onSubmit();
          onClose();
        },
        submitButtonText: 'Confirm',
        isSubmitEnabled: true,
      })}
    </BasePopup>
  );
};

ConfirmReassignmentPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmReassignmentPopup;
