import { NavigationData } from 'src/features/tabsState';

export const getIsExtended = (fragment) => {
  const extendableRoutes = [];
  Object.values(NavigationData)
    .filter((data) => data.items)
    .forEach((item) => {
      extendableRoutes.push(...item.routes);
    });

  return extendableRoutes.includes(fragment);
};

export const getActionItemsRoutes = () => {
  const actionItemRoutes = [];
  Object.values(NavigationData).forEach((navigationItem) => {
    if (navigationItem.isEligibleForActionItems) {
      if (!navigationItem?.items) {
        // no sub items case
        actionItemRoutes.push(navigationItem.routes[0]);
      } else {
        navigationItem.items.forEach((item) => {
          if (item?.isEligibleForActionItems) {
            actionItemRoutes.push(item.route);
          }
        });
      }
    }
  });

  return actionItemRoutes;
};
