import { InteractionReason } from 'common-src/models/PatientInteraction';

export const getSubOptions = (notePermissions, type, onClick) => {
  if (type.reasonId) return [];

  return notePermissions[type.id].map((reasonId) => ({
    ...Object.values(InteractionReason).find((r) => r.id === Number(reasonId)),
    onClick: () => onClick?.(type.id, reasonId),
  }));
};
