import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Menu } from 'common-src/components/base';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';

import { PaymentLinks } from 'src/fragments/DevicesFragment/constants';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './Header.module.scss';

const Header = () => {
  const isReadingFrequencyEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'readingFrequency'),
  );

  const renderPaymentLinksMenu = () => {
    if (!isReadingFrequencyEnabled) return null;

    const options = Object.values(PaymentLinks).map((x) => ({
      text: x.label,
      id: x.value,
      onClick: () => window.open(x.value, '_blank'),
    }));

    return (
      <Menu
        classNames={['m-r-40']}
        button={
          <div
            id="payment-links-button"
            className={[
              styles.buttonWrapper,
              'vertically-centered',
              'font-w-500',
              'gap-6',
              'font-s-14',
            ].join(' ')}
          >
            <span>Payment Links</span>
            <img className={styles.icon} src={CommonIcons.linkOutIcon} alt="link-icon" />
          </div>
        }
        options={options}
      />
    );
  };

  return (
    <div
      id="order-list-header"
      className={[styles.container, 'vertically-centered', 'm-l-40', 'primary-border-b'].join(' ')}
    >
      <span className={[styles.title, 'font-w-700', 'font-s-22'].join(' ')}>Order History</span>
      {renderPaymentLinksMenu()}
    </div>
  );
};

Header.propTypes = {};

export default Header;
