import _ from 'lodash';

import {
  AllergyReaction,
  AllergySeverity,
  AllergyType,
  validatePatientAllergy,
} from 'common-src/models/PatientAllergy';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientAllergy(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => {
  const body = {
    name: data.name,
    startDate: data.startDate,
    endDate: data.endDate,
    type: data.type.value,
    reaction: data.reaction.value,
    severity: data.severity.value,
    comments: data.comments,
  };

  if (patientId) {
    Object.assign(body, { patientId });
  }

  return body;
};

export const extractPatientAllergy = (allergy = {}) => ({
  name: allergy.name,
  startDate: allergy.startDate || null,
  endDate: allergy.endDate || null,
  type: Object.values(AllergyType).find((x) => x.value === allergy.type) || null,
  reaction: Object.values(AllergyReaction).find((x) => x.value === allergy.reaction) || null,
  severity: Object.values(AllergySeverity).find((x) => x.value === allergy.severity) || null,
  comments: allergy.comments || undefined,
});

export const getIsButtonEnabled = (data, errors, allergy, isDuplicate) => {
  if (
    Object.values(data).every((e) => !e) ||
    Object.values(errors).some((e) => e) ||
    (allergy && !isDuplicate && _.isEqual(data, extractPatientAllergy(allergy)))
  )
    return false;

  return true;
};
