import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

import { ExemptionStatus } from './constants';

export const getExemptionsRequest = (query, params) =>
  restRequest({
    uri: 'billingExemptions',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addExemption = (body, params) =>
  restRequest({
    uri: 'billingExemptions',
    method: HttpMethods.Post,
    body: { ...body, exemptionRate: 100, status: ExemptionStatus.Active.value },
    ...params,
  });

export const updateExemption = (id, body, params) =>
  restRequest({
    uri: `billingExemptions/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });
