import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientTasksRequest = (query, params) =>
  restRequest({
    uri: 'patientTasks',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientTask = (body, params) =>
  restRequest({
    uri: 'patientTasks',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientTask = (id, body, params) =>
  restRequest({
    uri: `patientTasks/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });
