import { LOGOUT } from 'common-src/features/auth/actionTypes';
import {
  CACHE_PATIENT,
  SET_CLIENTS_CACHED,
  SET_ORGANIZATIONS_CACHED,
} from 'common-src/features/cache/actionTypes';
import { createReducer } from 'common-src/utils/reducerUtils';

const initialState = {
  isOrganizationsCached: false,
  isClientsCached: false,
  patients: {},
  taskEvents: {},
};

const entityHandlers = {
  [SET_ORGANIZATIONS_CACHED]: (state, payload) => ({
    ...state,
    isOrganizationsCached: payload.isCached,
  }),
  [SET_CLIENTS_CACHED]: (state, payload) => ({
    ...state,
    isClientsCached: payload.isCached,
  }),
  [CACHE_PATIENT]: (state, { patientId, tab }) => ({
    ...state,
    patients: {
      ...state.patients,
      [patientId]: {
        ...state.patients[patientId],
        [tab]: true,
      },
    },
  }),
  [LOGOUT]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
