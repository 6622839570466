import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { getActivePatientSdohNames } from 'common-src/models/PatientSdoh';

import useCustomSelector from 'src/hooks/useCustomSelector';
import { DocumentForm } from 'src/pages/DocumentationIndex/components';

import { SdohContent, SocialIssueContent, SocialIssuesOptions } from './constants';
import { getSocialIssueValuesByNames } from './helpers';

const SdohForm = ({
  patientId,
  sdoh,
  setSdoh,
  modules,
  setHasDetectedChanges,
  errors,
  handleError,
  classNames = [],
}) => {
  const activeSdohNames = useCustomSelector((state) => getActivePatientSdohNames(state, patientId));

  const [sdohData, setSdohData] = useState([SdohContent]);
  const [didInitialUpdate, setDidInitialUpdate] = useState(false);

  const hasSelectedModule = useMemo(
    () => !_.isEmpty(modules.filter((m) => m.isSelected)),
    [modules],
  );

  // prefill patient sdoh values
  useEffect(() => {
    const patientSdoh = getSocialIssueValuesByNames(activeSdohNames);

    setSdohData([
      _.set(SdohContent, 'sections[0].contents[0]', {
        ...SocialIssueContent,
        choices: _.isEmpty(activeSdohNames)
          ? SocialIssuesOptions
          : _.difference(SocialIssuesOptions, _.intersection(patientSdoh, SocialIssuesOptions)),
        value: [],
        label: _.isEmpty(activeSdohNames)
          ? 'Select an area of concern'
          : `Current patient social issues: ${patientSdoh.join(', ')}`,
      }),
    ]);
  }, [activeSdohNames]);

  useEffect(() => {
    if (!_.isEmpty(sdoh) && didInitialUpdate) {
      setSdohData([sdoh]);
    }
  }, [JSON.stringify(sdoh)]);

  const getUpdatedData = (newData) => {
    // Initial change has passed, don't do any changes
    if (didInitialUpdate) return newData;

    const socialValue = _.get(newData, 'sections[0].contents[0].value');

    // saved values
    const savedSocialValue = _.get(sdoh, 'sections[0].contents[0].value', []);
    const yesNoValue = _.get(sdoh, 'sections[0].contents[1].value', null);
    const notesValue = _.get(sdoh, 'sections[0].contents[2].value', '');

    const socialValueForUpdate = _.isEmpty(savedSocialValue) ? [] : savedSocialValue;

    const shouldDoUpdate =
      _.isEmpty(socialValue) && (!_.isEmpty(socialValueForUpdate) || !!yesNoValue || !!notesValue);

    if (shouldDoUpdate) {
      _.set(newData, 'sections[0].contents[0].value', socialValueForUpdate);
      _.set(newData, 'sections[0].contents[1].value', yesNoValue);
      _.set(newData, 'sections[0].contents[2].value', notesValue);

      setSdohData([newData]);
    }

    return newData;
  };

  if (!hasSelectedModule) return null;

  return (
    <DocumentForm
      modules={sdohData}
      setModules={(mod) => {
        const sdohNewData = mod?.[0] || {};

        const updatedData = getUpdatedData(sdohNewData);

        setDidInitialUpdate(true);
        setSdoh(updatedData);
      }}
      setHasDetectedChanges={setHasDetectedChanges}
      errors={errors}
      classNames={classNames}
      handleError={handleError}
    />
  );
};

SdohForm.propTypes = {
  patientId: PropTypes.number,
  sdoh: PropTypes.object,
  setSdoh: PropTypes.func,
  modules: PropTypes.arrayOf(PropTypes.object),
  setHasDetectedChanges: PropTypes.func,
  errors: PropTypes.object,
  handleError: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default SdohForm;
