import { EncounterStatus } from 'common-src/models/PatientEncounter';

export const SourceOptions = [
  { value: true, label: 'Patient Statement' },
  { value: false, label: 'Medical Record' },
];

export const initialState = {
  type: null,
  startDate: null,
  endDate: null,
  status: EncounterStatus.Completed,
  patientReported: SourceOptions[0],
  provider: '',
  comments: '',
};
