import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Input } from 'common-src/components/base';
import { validatePatient } from 'common-src/models/Patient';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './EditStripsPopup.module.scss';
import { getIsButtonEnabled } from './helpers';

const EditStripsPopup = ({ open, onClose, glucoseStripCount, onEditStripsSubmit, buttonText }) => {
  const initialGlucoseStripCount = glucoseStripCount || 0;
  const [count, setCount] = useState(initialGlucoseStripCount);

  const { errors, setErrors, renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback) => {
    loadingCallback();
    onEditStripsSubmit(count);
  };

  const onChangeHandler = (key, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatient(key, value) }));
    setCount(value);
  };

  return (
    <BasePopup id="edit-strips" open={open} onClose={onClose} title="Edit test strip count">
      <Input
        id="strips-count-input"
        classNames={[styles.text]}
        errorText={errors.glucoseStripCount}
        label="Estimated remaining"
        onTextChange={(value) => onChangeHandler('glucoseStripCount', value)}
        value={count}
        placeholder="Estimated remaining"
        type="number"
        required
      />
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(count, initialGlucoseStripCount, errors),
        submitButtonText: buttonText || 'Reset strip count',
      })}
    </BasePopup>
  );
};

EditStripsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  glucoseStripCount: PropTypes.number,
  onEditStripsSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default EditStripsPopup;
