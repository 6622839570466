/* eslint-disable jsx-a11y/no-autofocus */
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import styles from './TimeItem.module.scss';

const TimeItem = ({
  type,
  value,
  onFocus,
  autoFocus,
  increaseHandler,
  decreaseHandler,
  onChangeHandler,
}) => (
  <div className={[styles.container, 'vertically-centered', 'flex-column'].join(' ')}>
    <img
      src={CommonIcons.arrowDownIcon}
      alt="arrow-up-icon"
      onClick={increaseHandler}
      role="presentation"
      style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
    />
    <input
      type={type}
      value={value <= 9 ? `0${value}` : value}
      onFocus={onFocus}
      autoFocus={autoFocus}
      className={styles.input}
      onChange={(e) => onChangeHandler(e.target.value)}
    />
    <img
      src={CommonIcons.arrowDownIcon}
      alt="arrow-down-icon"
      onClick={decreaseHandler}
      role="presentation"
      style={{ cursor: 'pointer' }}
    />
  </div>
);

TimeItem.propTypes = {
  type: PropTypes.string,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  increaseHandler: PropTypes.func,
  decreaseHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
};

export default TimeItem;
