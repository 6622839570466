import _ from 'lodash';

export const getIsButtonEnabled = (data, reasonOptions, errors) => {
  if (errors && data.isSchedule && Object.values(errors).some((e) => e)) return false;

  return (
    data.type &&
    (_.isEmpty(reasonOptions) ? true : data.reason) &&
    (data.isSchedule ? data.scheduledFor : true)
  );
};
