import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientCoveragesRequest = (query, params) =>
  restRequest({
    uri: 'patientCoverages',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addPatientCoverage = (body, params) =>
  restRequest({
    uri: 'patientCoverages',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientCoverage = (id, body, params) =>
  restRequest({
    uri: `patientCoverages/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });
