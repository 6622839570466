// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabPopup-module__container___WphkQ {
  padding: 20px 30px;
}
.LabPopup-module__container___WphkQ .LabPopup-module__buttonsContainer___ECV5k {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding-top: 19px;
  margin-top: 20px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/LabPopup/LabPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,2CAAA;EACA,oBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,SAAA;AACJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LabPopup-module__container___WphkQ`,
	"buttonsContainer": `LabPopup-module__buttonsContainer___ECV5k`
};
module.exports = ___CSS_LOADER_EXPORT___;
