import _ from 'lodash';

import { CLIENT_ROOT } from 'src/constants/paths';
import { TabState } from 'src/features/tabsState';

const urlRegexSplitter = new RegExp(/[?//,;&:#$+=]/);

const generateTabsByPath = (path) => ({
  SETTINGS: {
    pathName: `${CLIENT_ROOT}/settings`,
    getLabel: () => 'Settings',
  },
  PATIENTS: {
    pathName: `${CLIENT_ROOT}/patients`,
    getLabel: () => {
      if (path.includes('details')) {
        const pathSegments = path.split(urlRegexSplitter);
        return `Member Details: ${pathSegments[4]}`;
      }

      return 'Members List';
    },
  },
  MANAGE_CLIENTS: {
    pathName: `${CLIENT_ROOT}/manage-clients`,
    getLabel: () => 'Manage Clients',
  },
  DASHBOARD: {
    pathName: `${CLIENT_ROOT}/dashboard`,
    getLabel: () => 'Dashboard',
  },
});

export const getTab = (path) => {
  if (!path) return { label: '', path };

  const tabs = generateTabsByPath(path);
  const tab = _.filter(Object.values(tabs), (t) => path.startsWith(t.pathName));

  return tab?.[0] || { getLabel: () => '', pathName: path };
};

export const getPathByLabel = (label, patientsPaths, settingsPath) => {
  if (label.startsWith('Settings')) return settingsPath;
  if (label.startsWith('Member Details')) {
    const parts = label.split(' ');
    const patientId = parts[2];
    return (
      patientsPaths?.[Number(patientId)]?.path || `${CLIENT_ROOT}/patients/details/${patientId}`
    );
  }
  if (label.startsWith('Manage Clients')) return `${CLIENT_ROOT}/manage-clients`;
  if (label.startsWith('Dashboard')) return `${CLIENT_ROOT}/dashboard`;
};

export const getTabStateByLabel = (label) => {
  if (label.startsWith('Settings')) return TabState.SETTINGS;
  if (label.startsWith('Manage Clients')) return TabState.MANAGE_CLIENTS;
  if (label.startsWith('Dashboard')) return TabState.DASHBOARD;

  return '';
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

export const getAllowedTabs = (permissions) => {
  const allowedTabs = ['Dashboard', 'Settings', 'Member Details'];
  if (permissions.canManageClients) {
    allowedTabs.push('Manage Clients');
  }
  return allowedTabs;
};
