import moment from 'moment-timezone';

import { AllergyTabs } from './constants';

export const getTabInfo = (allergies = []) => {
  const activeAllergies = [];
  const inactiveAllergies = [];

  [...allergies]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((allergy) => {
      if (allergy.activeTill && moment(allergy.activeTill).isBefore(moment.now())) {
        inactiveAllergies.push(allergy);
        return;
      }

      activeAllergies.push(allergy);
    });

  const tabInfo = {
    ACTIVE: {
      text: AllergyTabs.ACTIVE.text,
      count: activeAllergies.length,
    },
    NOT_ACTIVE: {
      text: AllergyTabs.NOT_ACTIVE.text,
      count: inactiveAllergies.length,
    },
  };

  return {
    tabInfo,
    activeAllergies,
    inactiveAllergies,
  };
};
