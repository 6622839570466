/* eslint-disable no-case-declarations */
import _ from 'lodash';
import moment from 'moment-timezone';

import { moduleTypes } from 'common-src/models/PatientInteraction';
import {
  OncehubBookingStatuses,
  ScheduleVisitLinks,
  VisitType,
} from 'common-src/models/PatientOncehubBooking';

export const getWarningMessage = (label, scheduledVisits, patientInteractions, patient) => {
  switch (label) {
    case ScheduleVisitLinks.MonthlyWellnessVisit.label:
    case ScheduleVisitLinks.AccountabilityCoach.label:
      const mwvBooking = scheduledVisits?.find(
        (visit) =>
          (visit.visitType === VisitType.MonthlyWellnessVisit ||
            visit?.eventName?.toLowerCase()?.includes('wellness')) &&
          [
            OncehubBookingStatuses.scheduled.value,
            OncehubBookingStatuses.rescheduled.value,
          ].includes(visit.status) &&
          moment(visit.scheduledAt)
            .tz(patient.timeZone)
            .isAfter(moment().tz(patient.timeZone).subtract(5, 'minutes')),
      );

      return mwvBooking
        ? 'Are you sure you want to schedule this visit? ' +
            'This member already has a monthly visit scheduled. Please check the Visits tab.'
        : null;
    case ScheduleVisitLinks.NutritionConsult.label:
      const mntVisit = patientInteractions?.find(
        (pi) =>
          !_.isEmpty(
            _.intersection(
              [
                moduleTypes.MNTNote.id,
                moduleTypes.NutritionConsult.id,
                moduleTypes.NutritionFollowUp.id,
              ],
              pi.moduleTypes || [],
            ),
          ) &&
          pi.isDraft === false &&
          !!pi.serviceAt,
      );

      return mntVisit
        ? 'Are you sure you want to schedule this visit? This member has already completed an MNT. ' +
            'Please schedule a follow-up with their assigned dietitian or another dietitian licensed in their state.'
        : null;
    case ScheduleVisitLinks.BiannualVisit.label:
      const biannualVisit = patientInteractions?.find(
        (pi) =>
          (pi.moduleTypes || []).includes(moduleTypes.BiannualVisit.id) &&
          pi.isDraft === false &&
          !!pi.serviceAt,
      );

      const biannualBooking = scheduledVisits?.find(
        (visit) =>
          (visit.visitType === VisitType.BiannualVisit ||
            visit?.eventName?.toLowerCase()?.includes('biannual')) &&
          [
            OncehubBookingStatuses.scheduled.value,
            OncehubBookingStatuses.rescheduled.value,
          ].includes(visit.status) &&
          moment(visit.scheduledAt)
            .tz(patient.timeZone)
            .isAfter(moment().tz(patient.timeZone).subtract(5, 'minutes')),
      );

      return biannualBooking ||
        (!!biannualVisit?.serviceAt &&
          moment(biannualVisit.serviceAt).diff(moment(), 'days') < 5 * 30)
        ? 'Are you sure you want to schedule this visit? ' +
            'This member already has a biannual scheduled or completed in the past 5 months.'
        : null;
    default:
      return null;
  }
};
