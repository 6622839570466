import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import PatientLabReading, {
  getLastLabResults,
  getReadingPeriod,
  getReadingValue,
} from 'common-src/models/PatientLabReading';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { InfoCard } from 'src/components/elements';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './Header.module.scss';

const Header = ({ patientId, labResults = [] }) => {
  const dispatch = useDispatch();

  const actions = useCustomSelector((state) => getRoleConfig(state)?.patientChart?.actions);

  const showLabResultPopup = () => dispatch(openModal(ModalType.LAB, { patientId }));

  const { lastHbA1c, hbA1cPriorDiff } = getLastLabResults(labResults);

  return (
    <div className={styles.container}>
      <InfoCard
        id="most-recent-HbA1c"
        title="Most Recent HbA1c"
        content={getReadingValue(lastHbA1c)}
        addition={hbA1cPriorDiff}
        subtitle={lastHbA1c?.source}
        date={getReadingPeriod(lastHbA1c)}
      />
      <Button
        id="add-lab-result"
        iconSrc={CommonIcons.plusIcon}
        text="Add Result"
        onClick={() => showLabResultPopup()}
        disabled={!actions?.canAddLabResult}
        textColor={ColorsNew.darkGreen}
        borderColor={ColorsNew.darkGreen}
        backgroundColor={Colors.white}
        classNames={[styles.button]}
      />
    </div>
  );
};

Header.propTypes = {
  patientId: PropTypes.number,
  labResults: PropTypes.arrayOf(PropTypes.exact(PatientLabReading.schema)),
};

export default Header;
