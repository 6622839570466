import {
  CLOSE_PATIENT_DETAILS,
  RESET_DATA_ON_CLOSE,
  RESET_TAB_INFO,
  RESET_TABS_INFO,
  SAVE_DASHBOARD_FILTERS,
  SAVE_IS_CURIE_AI_SELECTED,
  SAVE_IS_PATIENT_SIDEBAR_EXTENDED,
  SAVE_NOTE_DATA,
  SAVE_NOTE_SCROLL,
  SAVE_PATIENT_DETAILS_TAB_INFO,
  SAVE_PATIENT_DOCUMENTATION_TAB_INFO,
  SAVE_PATIENT_LAST_MEDICATIONS_PULL,
  SAVE_PATIENT_MAIN_TAB,
  SAVE_PATIENT_NOTE,
  SAVE_PATIENT_TIMELINE_TAB_INFO,
  SAVE_SETTINGS_TAB_INFO,
  SET_ACTIVE_TABS,
} from './actionTypes';

export const savePatientDetailsTabInfo = (patientId, path) => ({
  type: SAVE_PATIENT_DETAILS_TAB_INFO,
  payload: { patientId, path },
});

export const saveSettingsTabInfo = (lastPath) => ({
  type: SAVE_SETTINGS_TAB_INFO,
  payload: { lastPath },
});

export const resetTabInfo = (tab) => ({
  type: RESET_TAB_INFO,
  payload: { tab },
});

export const resetTabsInfo = (tabs) => ({
  type: RESET_TABS_INFO,
  payload: { tabs },
});

export const resetDataOnClose = (tab) => ({
  type: RESET_DATA_ON_CLOSE,
  payload: { tab },
});

export const saveIsPatientSidebarExtended = (patientId, isExtended) => ({
  type: SAVE_IS_PATIENT_SIDEBAR_EXTENDED,
  payload: { patientId, isExtended },
});

export const savePatientDocumentationTabInfo = (patientId, tabInfo) => ({
  type: SAVE_PATIENT_DOCUMENTATION_TAB_INFO,
  payload: { patientId, tabInfo },
});

export const savePatientTimelineTabInfo = (patientId, tabInfo) => ({
  type: SAVE_PATIENT_TIMELINE_TAB_INFO,
  payload: { patientId, tabInfo },
});

export const savePatientLastMedicationsPull = (patientId, lastPullAt) => ({
  type: SAVE_PATIENT_LAST_MEDICATIONS_PULL,
  payload: { patientId, lastPullAt },
});

export const savePatientMainTab = (patientId, mainTab) => ({
  type: SAVE_PATIENT_MAIN_TAB,
  payload: { patientId, mainTab },
});

export const closePatientDetails = (patientId) => ({
  type: CLOSE_PATIENT_DETAILS,
  payload: { patientId },
});

export const setActiveTabs = (activeTabs) => ({
  type: SET_ACTIVE_TABS,
  payload: { activeTabs },
});

export const savePatientNote = (patientId, docId) => ({
  type: SAVE_PATIENT_NOTE,
  payload: { patientId, docId },
});

export const saveNoteData = (patientId, noteData) => ({
  type: SAVE_NOTE_DATA,
  payload: { patientId, noteData },
});

export const saveNoteScroll = (patientId, scroll) => ({
  type: SAVE_NOTE_SCROLL,
  payload: { patientId, scroll },
});

export const saveDashboardFilters = (filters) => ({
  type: SAVE_DASHBOARD_FILTERS,
  payload: { filters },
});

export const saveIsCurieAISelected = (patientId, isSelected) => ({
  type: SAVE_IS_CURIE_AI_SELECTED,
  payload: { patientId, isSelected },
});
