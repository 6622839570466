import { useEffect, useRef } from 'react';

import Config from 'common-src/config';

const useDocumentTitle = (title) => {
  const initialTitle = useRef(document.title);

  useEffect(() => {
    document.title = title ? `${title} | ${Config.APP_TITLE}` : Config.APP_TITLE;
  }, [title]);

  useEffect(() => () => (document.title = initialTitle.current), []);
};

export default useDocumentTitle;
