import PropTypes from 'prop-types';
import React from 'react';

import { capitalizeFirstLetter } from 'common-src/utils/stringUtils';

import usePopup from 'src/hooks/usePopup';

import BasePopup from '../BasePopup';
import styles from './WarningPopup.module.scss';

const WarningPopup = ({
  onSubmit,
  onClose,
  message,
  type,
  title,
  showCancelButton = true,
  submitButtonText = 'Confirm',
}) => {
  const { renderButtons } = usePopup();

  return (
    <BasePopup
      id="request-status"
      onClose={onClose}
      open
      title={title || capitalizeFirstLetter(type)}
    >
      <p className={[styles.message, 'font-w-500', 'font-s-16'].join(' ')}>{message}</p>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose: showCancelButton ? onClose : null,
        onSubmit,
        isSubmitEnabled: true,
        submitButtonText,
      })}
    </BasePopup>
  );
};

WarningPopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
};

export default WarningPopup;
