export const EncounterTabs = Object.freeze({
  COMPLETED: {
    text: 'Completed',
  },
  IN_PROGRESS: {
    text: 'In Progress',
  },
  ARCHIVED: {
    text: 'Archived',
  },
});

export const DefaultTabInfo = Object.freeze({
  COMPLETED: {
    text: EncounterTabs.COMPLETED.text,
    count: 0,
  },
  IN_PROGRESS: {
    text: EncounterTabs.IN_PROGRESS.text,
    count: 0,
  },
  ARCHIVED: {
    text: EncounterTabs.ARCHIVED.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'type', label: 'Provider Type', width: '20%', sortBy: 'title' },
  { column: 'comments', label: 'Comments', width: '29%', sortBy: 'text' },
  { column: 'startDate', label: 'Start Date', width: '11%', sortBy: 'value' },
  { column: 'status', label: 'Status', width: '11%' },
  { column: 'actions', label: 'Actions', width: '29%' },
];
