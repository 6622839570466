export const LabsTabs = Object.freeze({
  Timeline: {
    value: 0,
    label: 'Timeline',
  },
  List: {
    value: 1,
    label: 'List',
  },
});

export const defaultFilters = {
  tab: LabsTabs.List.value,
  startDate: null,
  endDate: null,
  types: [],
  hideReported: false,
};
