import PropTypes from 'prop-types';
import React from 'react';

import styles from './MasterDetailLayout.module.scss';

const MasterDetailLayout = ({ children, classNames }) => {
  const containerClassNames = [styles.root];
  if (classNames) {
    containerClassNames.push(...classNames);
  }

  return (
    <section id="master-detail-container" className={containerClassNames.join(' ')}>
      {children}
    </section>
  );
};

MasterDetailLayout.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default MasterDetailLayout;
