import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useForceSubmitNote = (isRequestRunning) => {
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.forceSubmit || isRequestRunning) return;

    setTimeout(() => {
      const button = document.getElementById('done-button');
      if (!button) return;

      button.click();
    }, 200);
  }, [location?.state?.forceSubmit, isRequestRunning]);
};

export default useForceSubmitNote;
