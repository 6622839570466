import { attr, fk, Model } from 'redux-orm';

class AwscCall extends Model {
  static modelName = 'AwscCall';

  static fields = {
    id: attr(),
    interactionId: attr(),
    initiationMethod: attr(),
    disposition: attr(),
    agentInteractionDuration: attr(),
    afterContactWorkDuration: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'awscCallChanger'),
    clientId: fk({
      to: 'Client',
      as: 'client',
      relatedName: 'clientAwscCall',
    }),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientAwscCall',
    }),
  };
}

export default AwscCall;
