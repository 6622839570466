import { DxStatusesConfig } from 'common-src/models/PatientMedicalProblem';

export const initialState = {
  diagnosis: null,
  status: null,
  dxDate: null,
  comments: '',
};

export const dxStatusesOptions = DxStatusesConfig.filter((status) => !status.isDeprecated).map(
  (status) => ({
    value: status.id,
    label: status.label,
  }),
);
