import { SdohStatus } from './constants';

export const getStatusById = (statusId) =>
  Object.values(SdohStatus).find((item) => item.value === statusId);

export const getSdohName = (sdohCode) => {
  if (!sdohCode) return '-';

  const code = sdohCode?.icd10Code;
  const name = sdohCode?.name || '-';

  return code ? `${code} ${name}` : name;
};
