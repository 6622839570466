import { cleanedText } from 'common-src/presenters';

export const validatePatientEmergencyContact = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'name':
      if (!value) {
        errMsg = 'Name should not be empty';
      }
      break;
    case 'relationship':
      if (!value) {
        errMsg = 'Relationship should not be empty';
      }
      break;
    case 'phone':
      if (!value) {
        errMsg = 'Phone number should not be empty';
      } else if (cleanedText(value).length !== 10) {
        errMsg = 'Phone number is not valid';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
