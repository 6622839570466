import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircleLoader } from 'common-src/components/base';
import { deleteOrmItem } from 'common-src/features/rest';
import PatientInteraction, {
  deleteDraftApiRequest,
  InteractionStatus,
  NotTrackableDocumentations,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import { ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import { getPatientNote, savePatientNote } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './ActionsRow.module.scss';

const ActionsRow = ({ draft, dispatch }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const patientNoteId = useCustomSelector((state) => getPatientNote(state, draft?.patientId));

  const editDraft = () => {
    if (patientNoteId) {
      dispatch(
        openModal(ModalType.WARNING, {
          title: 'Notice',
          message:
            'A note is currently in progress. Please pause or finish the current note before attempting to resume another note on the patient’s chart.',
          onSubmit: () => {},
          submitButtonText: 'Close',
          showCancelButton: false,
        }),
      );
      return;
    }

    if (!_.isEmpty(_.intersection(NotTrackableDocumentations, draft.moduleTypes))) {
      window.open(
        `/dashboard-client/documentation/details/${draft.patientId}/${draft.id}/edit`,
        '',
        'width=1200,height=800,left=300,top=100',
      );
      return;
    }

    dispatch(
      updatePatientDraftApiRequest(
        draft.id,
        {
          draftData: draft.draftData,
          moduleTypes: draft.moduleTypes,
          primaryModuleTypeId: draft.primaryModuleTypeId,
          serviceAt: draft.serviceAt,
          status: InteractionStatus.Active,
        },
        {
          successBlock: () => {
            navigate(`/dashboard-client/patients/details/${draft.patientId}/note/${draft.id}/edit`);
            dispatch(savePatientNote(draft.patientId, draft.id));
          },
        },
      ),
    );
  };

  const deleteDraft = () => {
    setLoading(true);
    dispatch(
      deleteDraftApiRequest(draft.id, {
        successBlock: () => {
          deleteOrmItem(PatientInteraction.modelName, draft.id);
          setLoading(false);
        },
        errorBlock: () => setLoading(false),
      }),
    );
  };

  const submitDraft = () => {
    navigate(`/dashboard-client/patients/details/${draft.patientId}/note/${draft.id}/edit`, {
      state: { forceSubmit: true },
    });
  };

  const renderButtons = () => (
    <>
      <span
        className={styles.button}
        style={{ backgroundColor: '#555671B2' }}
        onClick={editDraft}
        role="presentation"
      >
        Resume
      </span>
      <span
        className={styles.button}
        style={{ backgroundColor: '#F01F51' }}
        onClick={deleteDraft}
        role="presentation"
      >
        Discard
      </span>
      {_.isEmpty(_.intersection(NotTrackableDocumentations, draft.moduleTypes)) && (
        <span
          className={styles.button}
          style={{ backgroundColor: '#375844CC' }}
          onClick={submitDraft}
          role="presentation"
        >
          Submit
        </span>
      )}
    </>
  );

  const renderLoading = () => (
    <CircleLoader
      color={ColorsNew.darkGreen}
      strokeWidth={2}
      circleRadius={9}
      classNames={[styles.loader]}
    />
  );

  return <div className="flex-row gap-12">{loading ? renderLoading() : renderButtons()}</div>;
};

ActionsRow.propTypes = {
  draft: PropTypes.exact(PatientInteraction.schema),
  dispatch: PropTypes.func,
};

export default ActionsRow;
