import PropTypes from 'prop-types';
import React from 'react';

import { Accordion } from 'common-src/components/base';
import { getRoleConfig, isViewOnly } from 'common-src/features/auth';
import Patient from 'common-src/models/Patient';

import useCustomSelector from 'src/hooks/useCustomSelector';

import { AlertSettings, DiabeticMonitoring } from './forms';
import styles from './Orders.module.scss';

const Orders = ({ patient }) => {
  const permissions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.alerts,
  );

  return (
    <article className={[styles.article, 'relative'].join(' ')}>
      <div className={[styles.wrapper, 'flex-column', 'gap-16'].join(' ')}>
        <Accordion
          title="Diabetic monitoring journeys"
          isDefaultExpanded
          content={<DiabeticMonitoring patient={patient} />}
        />
        {permissions && (
          <Accordion
            title="Alert settings"
            isDefaultExpanded
            content={<AlertSettings patient={patient} isViewOnly={isViewOnly(permissions)} />}
          />
        )}
      </div>
    </article>
  );
};

Orders.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Orders;
