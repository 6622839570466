export const ScheduledCallTabs = {
  UPCOMING: {
    text: 'Upcoming',
  },
  PAST: {
    text: 'Past',
  },
  CANCELED: {
    text: 'Canceled',
  },
  ALL: {
    text: 'All Visits',
  },
};
