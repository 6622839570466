import PropTypes from 'prop-types';
import React from 'react';

import Patient from 'common-src/models/Patient';
import {
  getGmiAndGv,
  getLatestCgmGlucoseReading,
} from 'common-src/models/PatientCgmGlucoseReading';
import { getCGMAuthStatus } from 'common-src/models/PatientCgmOrder';
import { getAge } from 'common-src/utils/dateUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import { CGMInfoItem, CGMStatusItem } from 'src/pages/PatientDetails/fragments/Biometrics/items';
import { CGMManufacturers } from 'src/popups/CGMAuthPopup/constants';

import styles from './CGMSubHeader.module.scss';

const CGMSubHeader = ({ patient, data, a1c }) => {
  const latestReading = useCustomSelector((state) =>
    getLatestCgmGlucoseReading(state, patient?.id),
  );
  const transformedData = getGmiAndGv(data, getAge(patient?.dob), a1c);
  const cgmConfig = getCGMAuthStatus(patient, latestReading);

  return (
    <div className="p-l-30">
      <h2 className={[styles.title, 'font-s-20'].join(' ')}>Ambulatory Glucose Profile (AGP)</h2>
      <div className={[styles.wrapper, 'p-b-16', 'm-b-40', 'primary-border-b'].join(' ')}>
        <div className={['gap-8', 'flex-column', 'm-t-5'].join(' ')}>
          <p className={[styles.description, 'font-w-500', 'font-s-12'].join(' ')}>
            Summary of glucose values from the report period, with median (50%) and other
            percentiles shown as if occurring on a single day.
          </p>
          <CGMInfoItem
            indicatorId="gmi-value"
            title="Glucose Management Indicator (GMI)"
            value={transformedData.gmi.value}
            isCritical={transformedData.gmi.isCritical}
            tooltipMessage="Indicates the average A1C level that would be expected based on mean glucose measured in a given interval (most accurate with 14+ days of data)"
          />
          <CGMInfoItem
            indicatorId="glucose-variability-value"
            title="Glucose Variability"
            value={transformedData.glucoseVariability.value}
            isCritical={transformedData.glucoseVariability.isCritical}
            tooltipMessage="The measurement of fluctuations of glucose over a given interval of time"
          />
        </div>
        <div className={[styles.statuses, 'm-t-5'].join(' ')}>
          <CGMStatusItem
            title="Data Status"
            status={cgmConfig.cgmStatus}
            description={
              patient?.cgmType === CGMManufacturers.Dexcom.value
                ? 'Delayed 3hrs'
                : 'Delayed 5-25min'
            }
          />
          {/* TODO: update rxStatus when info is available */}
          <CGMStatusItem title="Rx Status" status="-" description="-" />
        </div>
      </div>
    </div>
  );
};

CGMSubHeader.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  data: PropTypes.object,
  a1c: PropTypes.number,
};

export default CGMSubHeader;
