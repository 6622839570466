import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';

import styles from './AddButton.module.scss';

const AddButton = ({ id, label, onClick, disabled = false, classNames = [] }) => (
  <Button
    id={id}
    classNames={[styles.button, 'gap-8', ...classNames]}
    iconSrc={CommonIcons.plusIcon}
    text={label}
    onClick={onClick}
    textColor={ColorsNew.darkGreen}
    backgroundColor="transparent"
    borderColor="transparent"
    disabled={disabled}
  />
);

AddButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default AddButton;
