import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'common-src/components/base';

import styles from './TextItem.module.scss';

const TextItem = ({
  isActive,
  isErrored,
  text = '-',
  addition = '',
  classNames = [],
  onClick = () => {},
  isUnderlined = false,
  disableTooltip = false,
}) => (
  <Tooltip title={`${text} ${addition}`} disableHoverListener={disableTooltip}>
    <div
      style={{
        opacity: isActive ? 1 : 0.5,
        textDecoration: isErrored ? 'line-through' : isUnderlined ? 'underline' : 'initial',
        cursor: isUnderlined ? 'pointer' : 'inherit',
        display: addition ? 'flex' : 'block',
      }}
      className={[styles.container, ...classNames].join(' ')}
      onClick={onClick}
      role="presentation"
    >
      <span className={[styles.text, 'font-w-500', 'font-s-14'].join(' ')}>{text}</span>
      {addition && (
        <span className={[styles.addition, 'font-w-500', 'font-s-12', 'm-l-5'].join(' ')}>
          {addition}
        </span>
      )}
    </div>
  </Tooltip>
);

TextItem.propTypes = {
  isActive: PropTypes.bool,
  isErrored: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  disableTooltip: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classNames: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  addition: PropTypes.string,
};

export default TextItem;
