import {
  PayorType,
  PlanSource,
  PlanStatus,
  SubscriberRelationship,
} from 'common-src/models/PatientCoverage';

export const MedicaidDefaultState = {
  payorType: PayorType.Medicaid.value,
  subscriberRelationship: SubscriberRelationship.Self.value,
  payorName: undefined,
  payorId: undefined,
  memberId: undefined,
  status: PlanStatus.NeedsVerification.value,
  source: PlanSource.UserInput.value,
  planName: undefined,
  startDate: null,
  endDate: null,
};

export const MedicareDefaultState = {
  payorType: PayorType.Medicare.value,
  subscriberRelationship: SubscriberRelationship.Self.value,
  payorName: 'Medicare New York',
  payorId: 'MCNY',
  memberId: undefined,
  status: PlanStatus.NeedsVerification.value,
  source: PlanSource.UserInput.value,
  partA: false,
  partB: false,
  planName: undefined,
  startDate: null,
  endDate: null,
};

export const MADefaultState = {
  payorType: PayorType.MA.value,
  subscriberRelationship: SubscriberRelationship.Self.value,
  payorName: undefined,
  payorId: undefined,
  memberId: undefined,
  status: PlanStatus.NeedsVerification.value,
  source: PlanSource.UserInput.value,
  contractId: undefined,
  planId: undefined,
  planName: undefined,
  startDate: null,
  endDate: null,
};

export const CommercialDefaultState = {
  payorType: PayorType.Commercial.value,
  subscriberRelationship: SubscriberRelationship.Self.value,
  payorName: undefined,
  payorId: undefined,
  memberId: undefined,
  status: PlanStatus.NeedsVerification.value,
  source: PlanSource.UserInput.value,
  planName: undefined,
  startDate: null,
  endDate: null,
  subscriberFirstName: undefined,
  subscriberLastName: undefined,
  subscriberDateOfBirth: null,
};

export const SupplementalDefaultState = {
  payorType: PayorType.Supplemental.value,
  subscriberRelationship: SubscriberRelationship.Self.value,
  payorName: undefined,
  payorId: undefined,
  memberId: undefined,
  status: PlanStatus.NeedsVerification.value,
  source: PlanSource.UserInput.value,
  planName: undefined,
  startDate: null,
  endDate: null,
  planType: null,
};

export const requiredPlanFields = ['payorId', 'startDate'];

export const requiredSubscriberFields = [
  'subscriberFirstName',
  'subscriberLastName',
  'subscriberDateOfBirth',
];

export const temporaryFields = ['state', 'partA', 'partB'];
