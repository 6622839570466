import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientSupplement extends Model {
  static modelName = 'PatientSupplement';

  static fields = {
    id: attr(),
    name: attr(),
    dosage: attr(),
    units: attr(),
    instructions: attr(),
    activeTill: attr(),
    dosageText: attr(),
    form: attr(),
    status: attr(),
    frequency: attr(),
    patientReported: attr(),
    comments: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'patientSupplementChanger'),
    createdBy: fk('Client', 'patientSupplementCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'supplements',
    }),
  };

  get source() {
    return this.patientReported ? 'Patient Statement' : 'Medical Record';
  }

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
      dosage: PropTypes.number,
      units: PropTypes.string,
      instructions: PropTypes.string,
      activeTill: PropTypes.string,
      dosageText: PropTypes.string,
      form: PropTypes.string,
      status: PropTypes.number,
      frequency: PropTypes.string,
      patientReported: PropTypes.bool,
      comments: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientSupplement;
