import {
  LabReadingStatus,
  PatientLabReadingTypes,
} from 'common-src/models/PatientLabReading/constants';
import { allModelsSelector } from 'common-src/utils/selectorUtils';

import { getLabReadingCategory } from './helpers';

export const getPatientLabResults = (state, patientId) =>
  allModelsSelector(state, 'PatientLabReading', (r) => r.patientId === patientId).sort((a, b) => {
    if (b?.readingDate === a?.readingDate) return new Date(b.updatedAt) - new Date(a.updatedAt);
    return new Date(b.readingDate) - new Date(a.readingDate);
  });

export const getPatientLabResultsByType = (state, patientId, typeId) =>
  allModelsSelector(
    state,
    'PatientLabReading',
    (r) =>
      r.patientId === patientId && r.typeId === typeId && r?.status !== LabReadingStatus.Inactive,
  );

export const getPatientLabResultsByCategory = (state, patientId, category, readingDate) =>
  allModelsSelector(
    state,
    'PatientLabReading',
    (r) =>
      !r.patientReported &&
      r.patientId === patientId &&
      getLabReadingCategory(r.typeId) === category &&
      r.readingDate === readingDate,
  );

export const getLastA1cReading = (state, patientId) => {
  const allResults = getPatientLabResults(state, patientId);
  return allResults.find(
    (result) =>
      result.typeId === PatientLabReadingTypes.A1c.id &&
      result.status !== LabReadingStatus.Inactive,
  );
};
