import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { apiRequest } from 'common-src/features/rest';

import { STORAGE_KEY_APP_VERSION } from 'src/constants/storageKeys';
import {
  setCurrentVersion,
  setIsRunningLatestVersion,
  setShouldShowUpdateModal,
} from 'src/features/appVersion';

const useVersionCheck = () => {
  const dispatch = useDispatch();

  const updateVersion = (latestVersion) => {
    localStorage.setItem(STORAGE_KEY_APP_VERSION, latestVersion);
    dispatch(setCurrentVersion(latestVersion));
  };

  const compareVersions = (latestVersion) => {
    const savedVersion = localStorage.getItem(STORAGE_KEY_APP_VERSION);
    dispatch(setIsRunningLatestVersion(!savedVersion || latestVersion === savedVersion));
    dispatch(setShouldShowUpdateModal(true));
  };

  useEffect(() => {
    const fetchAppVersion = (isInitialCall = false) => {
      apiRequest({
        endpoint: 'appVersion',
      })
        .then((res) => res.json())
        .then((appVersion) => {
          if (isInitialCall) {
            updateVersion(appVersion);
          } else {
            compareVersions(appVersion);
          }
        })
        .catch((e) => console.error('Error while getting app version', e));
    };

    fetchAppVersion(true);

    const appInterval = setInterval(() => fetchAppVersion(false), 5 * 60 * 1000);

    return () => clearInterval(appInterval);
  }, []);
};

export default useVersionCheck;
