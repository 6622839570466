import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import {
  activeStatusesIds,
  getPatientSupplements,
  getPatientSupplementsRequest,
  getStatusById,
  makeSupplementInactive,
} from 'common-src/models/PatientSupplement';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultilineTextItem,
  MultiTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, SupplementsTabs, TableHeaders } from './constants';
import { getTabInfo } from './helpers';
import styles from './Supplements.module.scss';

const Supplements = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const supplements = useCustomSelector((state) => getPatientSupplements(state, patientId));

  const tabs = Object.keys(SupplementsTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredSupplements, setFilteredSupplements] = useState([]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const { isRequesting } = useRequestLoading([getPatientSupplementsRequest({ patientId })]);

  useInterval(() => {
    dispatch(getPatientSupplementsRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, activeSupplements, inactiveSupplements } = getTabInfo(supplements);
    setTabInfo(tabInfo);
    setFilteredSupplements(selectedTab === tabs[0] ? activeSupplements : inactiveSupplements);
  }, [selectedTab, supplements]);

  const showAddSupplementPopup = () => dispatch(openModal(ModalType.SUPPLEMENT, { patientId }));

  const showSupplementDetailsPopup = (supplement) =>
    dispatch(openModal(ModalType.SUPPLEMENT_DETAILS, { supplement }));

  const getData = () =>
    filteredSupplements.map((supplement) => {
      const isDisabled =
        (supplement.activeTill && moment(supplement.activeTill).isBefore(moment.now())) ||
        !activeStatusesIds.includes(supplement?.status) ||
        !actionsAllowed;
      const currentStatus = getStatusById(supplement?.status);

      return {
        name: (
          <MultiTextItem
            isActive
            title={supplement?.name || '-'}
            subtitle={supplement?.dosageText || '-'}
            onClick={() => showSupplementDetailsPopup(supplement)}
          />
        ),
        comments: <MultilineTextItem isActive text={supplement?.comments || '-'} maxLines={2} />,
        createdAt: (
          <TextItem
            isActive
            text={moment(supplement.createdAt).format('MM/DD/YYYY')}
            value={supplement.createdAt}
          />
        ),
        status: (
          <StatusItem
            isActive
            withArrow={false}
            text={currentStatus?.label || '-'}
            textColor={currentStatus?.textColor}
            backgroundColor={currentStatus?.backgroundColor}
          />
        ),
        actions: (
          <ActionsRow
            options={[
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.SUPPLEMENT, { patientId, supplement }),
              },
              {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeSupplementInactive(supplement.id, {}),
                showWarning: true,
              },
              isDisabled && {
                id: 'duplicate-button',
                text: 'Duplicate',
                backgroundColor: ColorsNew.baseGreen,
                color: 'white',
                disabled: !actionsAllowed,
                onClick: openModal(ModalType.SUPPLEMENT, {
                  patientId,
                  supplement,
                  isDuplicate: true,
                }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling supplements..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="new-supplement-button"
            text="Add Supplement"
            onClick={() => showAddSupplementPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="supplement"
        classNames={[
          'medical-table-new',
          filteredSupplements?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No supplements"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'updatedAt',
          label: 'Last Updated',
          width: '13%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredSupplements}
        selectedTab={selectedTab}
      />
    </>
  );
};

Supplements.propTypes = {
  patientId: PropTypes.number.isRequired,
  actionsAllowed: PropTypes.bool,
};

export default Supplements;
