import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import PatientCgmOrder from 'common-src/models/PatientCgmOrder';

import Header from './Header';
import Item from './Item';
import styles from './OrderHistoryList.module.scss';

const OrderHistoryList = ({ actions, cgmOrders = [] }) => {
  const renderEmpty = () => (
    <div
      id="empty-orders-item"
      className={[styles.empty, 'gap-10', 'vertically-centered', 'primary-border'].join(' ')}
    >
      <img className={styles.icon} src={CommonIcons.warningIcon} alt="empty-orders-icon" />
      <span id="empty-orders-text" className={[styles.text, 'font-w-700', 'font-s-16'].join(' ')}>
        No orders sent
      </span>
    </div>
  );

  const renderOrders = () => (
    <div id="orders-container" className={['flex-column', 'm-t-20'].join(' ')}>
      {cgmOrders.map((order) => (
        <Item key={order.id} order={order} actions={actions} />
      ))}
    </div>
  );

  return (
    <div className="flex-column">
      <Header />
      {_.isEmpty(cgmOrders) ? renderEmpty() : renderOrders()}
    </div>
  );
};

OrderHistoryList.propTypes = {
  cgmOrders: PropTypes.arrayOf(PropTypes.exact(PatientCgmOrder.schema)),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default OrderHistoryList;
