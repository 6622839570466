import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import Icons from 'src/assets/Icons';
import { updateContactToDial } from 'src/features/awsConnect';

import styles from './DialButton.module.scss';

const DialButton = ({ phoneNumber, patientId, classNames = [] }) => {
  const dispatch = useDispatch();

  return (
    <img
      className={[styles.button, ...classNames].join(' ')}
      src={Icons.callIcon}
      alt="dial-phone-number"
      role="presentation"
      onClick={() => dispatch(updateContactToDial({ phoneToDial: phoneNumber, patientId }))}
    />
  );
};

DialButton.propTypes = {
  phoneNumber: PropTypes.string,
  patientId: PropTypes.number,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default DialButton;
