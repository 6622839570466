import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import PatientLabReading from 'common-src/models/PatientLabReading';
import { getLabReadingLabel, getLabReadingUnit } from 'common-src/models/PatientLabReading/helpers';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';

import styles from './MedicalRecordsTable.module.scss';

const MedicalRecordsTable = ({ patientId, records = [] }) => {
  const dispatch = useDispatch();

  const showTrendedResultsPopup = (labReadingType) =>
    dispatch(openModal(ModalType.TRENDED_RESULTS, { patientId, typeId: labReadingType }));

  return (
    <>
      <div className={[styles.grid, 'font-s-16', 'font-w-600', 'm-b-10'].join(' ')}>
        <span>Chemistry Name</span>
        <span>Value</span>
      </div>
      {[...records]
        .sort((a, b) => getLabReadingLabel(a.typeId).localeCompare(getLabReadingLabel(b.typeId)))
        .map(({ id, typeId, readingValue }) => (
          <div
            key={id}
            id={id}
            className={[styles.grid, 'font-w-500', 'm-b-6', 'font-s-14'].join(' ')}
          >
            <span>{getLabReadingLabel(typeId)}</span>
            <span>
              {readingValue} {getLabReadingUnit(typeId)}
            </span>
            <img
              className={styles.icon}
              src={CommonIcons.linkOutIcon}
              alt="link-out-icon"
              role="presentation"
              onClick={() => showTrendedResultsPopup(typeId)}
            />
          </div>
        ))}
    </>
  );
};

MedicalRecordsTable.propTypes = {
  records: PropTypes.arrayOf(PropTypes.exact(PatientLabReading.schema)),
  patientId: PropTypes.number,
};

export default MedicalRecordsTable;
