import { ActivePatientTaskStatuses } from 'common-src/models/PatientTask';

import { TasksTabs } from './constants';

export const getTabInfo = (tasks = []) => {
  const activeTasks = [];
  const completedTasks = [];

  [...tasks]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((task) => {
      if (!ActivePatientTaskStatuses.includes(task.status)) {
        completedTasks.push(task);
        return;
      }

      activeTasks.push(task);
    });

  const tabInfo = {
    ACTIVE: {
      text: TasksTabs.ACTIVE.text,
      count: activeTasks.length,
    },
    COMPLETE: {
      text: TasksTabs.COMPLETE.text,
      count: completedTasks.length,
    },
    ALL: {
      text: TasksTabs.ALL.text,
      count: tasks.length,
    },
  };

  return {
    tabInfo,
    activeTasks,
    completedTasks,
    allTasks: tasks,
  };
};
