/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import moment from 'moment-timezone';

import { ColorsNew } from 'common-src/styles';

import { DEFAULT_CGM_MAX_VALUE } from 'src/pages/PatientDetails/fragments/Biometrics/constants';

const MAX_Y_OFFSET = 100;
const LABEL_DEVIATION = 8;

export const generateHourlyLineLabels = (timeZone) => {
  if (!timeZone) return [];

  const startDate = moment().tz(timeZone).startOf('day');
  const endDate = moment().tz(timeZone).endOf('day');

  const hours = [];

  while (startDate <= endDate) {
    hours.push(startDate.format('YYYY-MM-DD HH:mm'));
    startDate.add(1, 'hour');
  }

  return hours;
};

export const generateLineData = (data = [], labels) =>
  data.slice(0, labels.length).map((value, i) => ({ x: labels[i], y: value }));

export const generateLine = (
  label,
  data,
  backgroundColor,
  pointBackgroundColor,
  hoverPointBackgroundColor,
  borderWidth,
  isDashed,
  fill,
  order,
) => ({
  label,
  data,
  pointRadius: (ctx) => {
    const labelsLength = ctx?.chart?.data?.labels?.length || 1;
    return [...Array(labelsLength - 1).fill(0), 4];
  },
  backgroundColor,
  borderColor: backgroundColor,
  pointBackgroundColor,
  pointBorderColor: pointBackgroundColor,
  borderDash: isDashed ? [5, 5] : [],
  borderWidth,
  fill,
  order,
  datalabels: {
    color: ColorsNew.darkGreen,
    anchor: 'center',
    align: 'right',
    display: (ctx) => {
      const metasets = ctx.chart._metasets;
      const lastPoint10 = metasets[0].data.slice(-1)[0].parsed;
      const lastPoint25 = metasets[1].data.slice(-1)[0].parsed;
      const lastPoint50 = metasets[2].data.slice(-1)[0].parsed;
      const lastPoint75 = metasets[3].data.slice(-1)[0].parsed;
      const lastPoint90 = metasets[4].data.slice(-1)[0].parsed;

      let visibleLabels = ['10%', '25%', '50%', '75%', '90%'];
      if (
        (lastPoint10?.y ?? 0) + LABEL_DEVIATION >= lastPoint25?.y ||
        (lastPoint25?.y ?? 0) + LABEL_DEVIATION >= lastPoint50?.y ||
        (lastPoint50?.y ?? 0) + LABEL_DEVIATION >= lastPoint75?.y ||
        (lastPoint75?.y ?? 0) + LABEL_DEVIATION >= lastPoint90?.y
      ) {
        visibleLabels = _.without(visibleLabels, '25%', '75%');
      }

      if (
        (lastPoint10?.y ?? 0) + LABEL_DEVIATION >= lastPoint50?.y ||
        (lastPoint50?.y ?? 0) + LABEL_DEVIATION >= lastPoint90?.y
      ) {
        visibleLabels = _.without(visibleLabels, '10%', '90%');
      }

      return (
        visibleLabels.includes(ctx.dataset.label) && ctx.dataIndex === ctx.dataset.data.length - 1
      );
    },
    formatter: () => label,
  },
  hoverBackgroundColor: hoverPointBackgroundColor,
  hoverBorderColor: hoverPointBackgroundColor,
  hoverBorderWidth: 2,
});

export const getMaxYValue = (data) => {
  const p10 = data?.p10 || [];
  const p25 = data?.p25 || [];
  const p50 = data?.p50 || [];
  const p75 = data?.p75 || [];
  const p90 = data?.p90 || [];
  const allValues = [...p10, ...p25, ...p50, ...p75, ...p90];

  const maxReadingValue = _.max(allValues) || DEFAULT_CGM_MAX_VALUE;
  const maxYPoint = maxReadingValue + MAX_Y_OFFSET;
  const remainderFromOffset = maxYPoint % MAX_Y_OFFSET;

  return maxYPoint - remainderFromOffset;
};
