import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, CircleLoader, Tooltip } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';
import { isNumberValid } from 'common-src/utils/validationUtils';

import styles from './Input.module.scss';

const Input = ({
  mask,
  classNames,
  leftContent,
  optionText,
  optionId,
  description,
  readOnlyLeftContent,
  decimalPlaceCount,
  id = '',
  label = '',
  value = '',
  prefix = '',
  minValue = 0,
  type = 'text',
  errorText = '',
  size = 'medium',
  isFloat = false,
  required = false,
  withError = true,
  warningText = '',
  tooltipText = '',
  placeholder = '',
  containerId = '',
  disabled = false,
  readOnly = false,
  autoFocus = false,
  debounced = false,
  isLoading = false,
  onBlur = () => {},
  onClick = () => {},
  updateTimeout = 400,
  shouldReset = false,
  inputClassNames = [],
  onTextChange = () => {},
  onOptionClick = () => {},
  onFinishEditing = () => {},
}) => {
  const [textValue, setTextValue] = useState(null);

  const ref = useRef(null);

  const isSmall = size === 'small';
  const containerClasses = ['flex-column', 'border-r-6'];

  if (classNames) {
    containerClasses.push(...classNames);
  }

  if (label || tooltipText) {
    containerClasses.push('gap-6');
  }

  useEffect(() => {
    if (!autoFocus || !ref.current) return;

    ref.current.focus();
  }, [autoFocus]);

  useEffect(() => {
    if (!debounced) return;

    const debounceFn = setTimeout(() => {
      if (textValue !== null) {
        onFinishEditing(type === 'number' && textValue.length ? Number(textValue) : textValue);
      }
    }, updateTimeout);

    return () => clearTimeout(debounceFn);
  }, [textValue]);

  useEffect(() => {
    if (!shouldReset) return;

    setTextValue(null);
    onFinishEditing(type === 'number' ? undefined : '');
  }, [shouldReset]);

  const renderHeader = () => {
    if (!label && !tooltipText && !optionText) return null;

    return (
      <div className="flex-row gap-6 vertically-centered">
        {label && (
          <span
            className={[
              styles.label,
              'font-w-500',
              isSmall ? 'font-s-12' : 'font-s-14',
              errorText && !readOnly ? styles.withError : '',
            ].join(' ')}
          >{`${label}${required ? '*' : ''}`}</span>
        )}
        {tooltipText && (
          <Tooltip title={tooltipText}>
            <img className={styles.tooltipIcon} src={CommonIcons.tooltipIcon} alt="tooltip-icon" />
          </Tooltip>
        )}
        {optionText && (
          <Button
            id={optionId}
            classNames={[styles.optionButton]}
            text={optionText}
            backgroundColor="transparent"
            borderColor="transparent"
            textColor={ColorsNew.darkOrange}
            onClick={onOptionClick}
          />
        )}
      </div>
    );
  };

  const onChangeHandler = (value) => {
    if (type === 'number') {
      const floatValue = value.replace(',', '.');
      if (!isNumberValid(floatValue)) return;

      const intValue = floatValue.length < 1 ? undefined : Number(floatValue);
      if (minValue > intValue) return;

      const numberValue = isFloat || intValue === undefined ? floatValue : intValue;

      if (
        isFloat &&
        decimalPlaceCount &&
        floatValue.includes('.') &&
        floatValue.split('.')[1].length > decimalPlaceCount
      ) {
        return;
      }

      if (debounced) {
        setTextValue(numberValue);
        return;
      }

      onTextChange(numberValue);
      return;
    }

    if (debounced) {
      setTextValue(value);
      return;
    }

    onTextChange(value);
  };

  const renderContent = () => {
    if (readOnly) {
      return (
        <p
          id={id}
          className={[styles.readOnly, 'font-w-500', isSmall ? 'font-s-14' : 'font-s-18'].join(' ')}
        >
          {readOnlyLeftContent && readOnlyLeftContent}
          {prefix}
          {value || '-'}
        </p>
      );
    }

    if (mask) {
      return (
        <InputMask mask={mask} value={value} onChange={(e) => e && onTextChange(e.target.value)}>
          {(inputProps) => (
            <div
              className={[styles.wrapper, errorText ? styles.withError : '', 'border-r-6'].join(
                ' ',
              )}
            >
              <input
                id={id}
                placeholder={placeholder}
                className={[isSmall ? 'font-s-14' : 'font-s-16', 'font-w-500'].join(' ')}
                disabled={disabled}
                {...inputProps}
              />
            </div>
          )}
        </InputMask>
      );
    }

    const inputValue =
      textValue === null
        ? value
        : type === 'number'
          ? textValue === undefined
            ? ''
            : textValue
          : textValue;

    return (
      <div
        className={[
          styles.wrapper,
          errorText ? styles.withError : '',
          'border-r-6',
          'flex-row',
          ...inputClassNames,
        ].join(' ')}
      >
        {leftContent && <div className={['flex-row', 'm-l-8'].join(' ')}>{leftContent}</div>}
        {prefix && (
          <span
            className={[
              'vertically-centered',
              isSmall ? 'font-s-14' : 'font-s-16',
              'font-w-500',
            ].join(' ')}
          >
            {prefix}
          </span>
        )}
        <input
          ref={ref}
          id={id}
          className={[
            isLoading ? styles.withLoader : '',
            isSmall ? 'font-s-14' : 'font-s-16',
            'font-w-500',
          ].join(' ')}
          type={type === 'password' ? 'password' : 'text'}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => onChangeHandler(e.target.value)}
          disabled={disabled}
          onBlur={onBlur}
          onClick={(e) => {
            e.stopPropagation();
            onClick(e);
          }}
        />
        {isLoading && (
          <CircleLoader
            classNames={['absolute']}
            strokeWidth={2}
            circleRadius={12}
            color={ColorsNew.darkGreen}
            style={{ bottom: '8px', right: '8px' }}
          />
        )}
      </div>
    );
  };

  const renderDescription = () => {
    if (!description) return null;

    return (
      <span
        className={[
          styles.description,
          'font-w-400',
          'font-s-12',
          errorText || warningText ? 'm-t-4' : '',
        ].join(' ')}
      >
        {description}
      </span>
    );
  };

  const renderError = () => {
    if (!withError) return null;

    return (
      <span
        className={[
          'font-w-400',
          isSmall ? 'font-s-10' : 'font-s-12',
          warningText ? styles.warning : styles.error,
        ].join(' ')}
      >
        {errorText || warningText}
      </span>
    );
  };

  return (
    <div id={containerId} className={containerClasses.join(' ')}>
      {renderHeader()}
      {renderContent()}
      {renderDescription()}
      {renderError()}
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  mask: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  isFloat: PropTypes.bool,
  prefix: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  withError: PropTypes.bool,
  autoFocus: PropTypes.bool,
  minValue: PropTypes.number,
  classNames: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
  onTextChange: PropTypes.func,
  tooltipText: PropTypes.string,
  placeholder: PropTypes.string,
  warningText: PropTypes.string,
  leftContent: PropTypes.element,
  onOptionClick: PropTypes.func,
  optionText: PropTypes.string,
  optionId: PropTypes.string,
  isLoading: PropTypes.bool,
  debounced: PropTypes.bool,
  updateTimeout: PropTypes.number,
  onFinishEditing: PropTypes.func,
  inputClassNames: PropTypes.arrayOf(PropTypes.string),
  shouldReset: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  readOnlyLeftContent: PropTypes.element,
  containerId: PropTypes.string,
  decimalPlaceCount: PropTypes.number,
};

export default Input;
