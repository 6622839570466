import { attr, fk, Model } from 'redux-orm';

class PatientGlucoseReading extends Model {
  static modelName = 'PatientGlucoseReading';

  static fields = {
    id: attr(),
    recordedAt: attr(),
    recordedAtLocal: attr(),
    deviceId: attr(),
    value: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: attr('Client', 'patientGlucoseReadingChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'glucoseReadings',
    }),
  };
}

export default PatientGlucoseReading;
