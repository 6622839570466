import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCurrentOrgId, getIsLoggedIn } from 'common-src/features/auth';

import { CLIENT_ROOT } from 'src/constants/paths';
import useCustomSelector from 'src/hooks/useCustomSelector';

const DEFAULT_PATHS = [CLIENT_ROOT, `${CLIENT_ROOT}/`, '/', '/login'];

const useProtectedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedIn = useCustomSelector(getIsLoggedIn);
  const currentOrgId = useCustomSelector(getCurrentOrgId);

  const getPath = () => {
    if (location.state?.redirectPath && !DEFAULT_PATHS.includes(location.state.redirectPath)) {
      return location.state?.redirectPath;
    }

    if (location.pathname && !DEFAULT_PATHS.includes(location.pathname)) {
      return location.pathname;
    }

    return `${CLIENT_ROOT}/dashboard`;
  };

  useEffect(() => {
    if (!isLoggedIn || !currentOrgId) {
      navigate('/login', { state: { redirectPath: location.pathname } });
      return;
    }

    navigate(getPath());
  }, [isLoggedIn, currentOrgId]);
};

export default useProtectedRoutes;
