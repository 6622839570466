import React from 'react';

import useTabs from 'src/hooks/useTabs';

import TabItem from './TabItem';
import styles from './TabsNavigation.module.scss';

const TabsNavigation = () => {
  const { tabs, onCloseTab, onSelectTab } = useTabs();

  return (
    <div className={[styles.container, 'flex-row'].join(' ')}>
      {tabs.map((tab) => (
        <TabItem key={tab} tabName={tab} onSelectTab={onSelectTab} onCloseTab={onCloseTab} />
      ))}
    </div>
  );
};

export default TabsNavigation;
