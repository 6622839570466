import moment from 'moment-timezone';

export const validateExemption = (field, value, data) => {
  let errMsg = '';
  switch (field) {
    case 'exemptionType':
      if (!value) {
        errMsg = 'Type should not be empty';
      }
      break;
    case 'startDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (value && moment(value).isAfter(data.endDate)) {
        errMsg = 'Start date should be before end date';
      }
      break;
    case 'endDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (value && moment(value).isBefore(data.startDate)) {
        errMsg = 'End date should be after start date';
      }
      break;
    case 'source':
      if (!value) {
        errMsg = 'Source should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
