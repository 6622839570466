import PropTypes from 'prop-types';
import React from 'react';

import styles from './CGMStatusItem.module.scss';

const CGMStatusItem = ({ title, status, description }) => (
  <div className={['flex-column', 'font-w-500', 'gap-4'].join(' ')}>
    <span className={[styles.title, 'font-s-10'].join(' ')}>{title}</span>
    <span className={[styles.status, 'font-s-14'].join(' ')}>{status}</span>
    <span className={[styles.description, 'font-s-12'].join(' ')}>{description}</span>
  </div>
);

CGMStatusItem.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
};

export default CGMStatusItem;
