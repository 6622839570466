import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, TextArea } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import PatientSurgicalHistory, {
  addSurgicalHistory,
  makeSurgicalHistoryInactive,
  updateSurgicalHistory,
  validatePatientSurgicalHistory,
} from 'common-src/models/PatientSurgicalHistory';
import { getChangedValues } from 'common-src/utils/objectUtils';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import { catchErrors, extractSurgicalHistory, getIsButtonEnabled, getRequestBody } from './helpers';
import styles from './SurgicalHistoryPopup.module.scss';

const UPDATABLE_FIELDS = ['dateOfSurgery', 'comments'];

const SurgicalHistoryPopup = ({ patientId, surgicalHistory, onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!surgicalHistory) return;
    setData(extractSurgicalHistory(surgicalHistory));
  }, [surgicalHistory]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const updatedFields = Object.keys(
      getChangedValues(data, extractSurgicalHistory(surgicalHistory)),
    );
    const shouldEdit = _.isEmpty(
      updatedFields.filter((field) => !UPDATABLE_FIELDS.includes(field)),
    );

    const requests = [];
    if (shouldEdit) {
      requests.push(updateSurgicalHistory(surgicalHistory.id, getRequestBody(data, patientId)));
    } else {
      requests.push(addSurgicalHistory(getRequestBody(data, patientId)));
      if (surgicalHistory) {
        requests.push(makeSurgicalHistoryInactive(surgicalHistory.id));
      }
    }

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Surgical History ${shouldEdit ? 'edited' : 'added'}!`);

          if (!shouldClose) {
            setData(initialState);
            setAutoFocus(true);
            return;
          }

          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientSurgicalHistory(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onDateSelectedHandler = (date) => {
    const value = date ? moment(date).format('YYYY-MM-DD') : null;
    setData((prev) => ({ ...prev, dateOfSurgery: value }));
  };

  const onClear = () => setData(initialState);

  return (
    <BasePopup
      id="supplement"
      open
      onClose={onClose}
      title={surgicalHistory?.id ? 'Edit Surgical History' : 'Add Surgical History'}
    >
      <div className={styles.grid}>
        <Input
          id="procedure"
          placeholder="Enter procedure"
          label="Procedure Name"
          required
          value={data.procedure}
          errorText={errors.procedure}
          onTextChange={(value) => onChangeHandler('procedure', value)}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
        />
        <DatePickerNew
          id="date-of-surgery-picker"
          header="Date of surgery"
          placeholder="Date of surgery"
          value={data.dateOfSurgery}
          onDateSelected={onDateSelectedHandler}
          tooltipText="The date, or approximate date the surgery was done."
        />
        <TextArea
          id="comments"
          classNames={[styles.width100]}
          placeholder="Enter comments"
          value={data.comments}
          onTextChange={(value) => onChangeHandler('comments', value)}
          fixedRows={4}
          label="Comments"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, surgicalHistory),
        submitButtonText: 'Save and Close',
        onClear: !surgicalHistory?.id ? onClear : null,
        renderSaveButton: !surgicalHistory?.id,
      })}
    </BasePopup>
  );
};

SurgicalHistoryPopup.propTypes = {
  patientId: PropTypes.number,
  surgicalHistory: PropTypes.exact(PatientSurgicalHistory.schema),
  onClose: PropTypes.func,
};

export default SurgicalHistoryPopup;
