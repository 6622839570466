export const getWeightChange = (vitals, currentVital, currentIndex) => {
  const priorWeightRecord = vitals.filter((v) => v.weight)?.[1];
  if (!priorWeightRecord) return '';

  const priorWeightRecordIndex = vitals.findIndex((v) => v.id === priorWeightRecord.id);

  if (currentVital.weight && currentIndex < priorWeightRecordIndex) {
    const diff = currentVital.weight - priorWeightRecord.weight;
    const fixedDiff = Number.isInteger(diff) ? diff : diff.toFixed(2);

    return `${fixedDiff > 0 ? `+${fixedDiff}` : fixedDiff}lbs`;
  }

  return '';
};

export const getVitalValue = (value) => {
  if (!value) return '-';

  return Number.isInteger(value) ? value : value.toFixed(2);
};
