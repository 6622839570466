import moment from 'moment-timezone';

import { TimeZonesByState, UsTimeZones, ZipCodes } from 'common-src/constants/timeZone';

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('ss', 4);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 28);
moment.relativeTimeThreshold('M', 12);

export const getDateWithTimeZone = (time, format, tz) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return moment(time)
    .tz(tz || timeZone)
    .format(format);
};

export const getTimeZone = (zipcode, state) => {
  const index = ZipCodes[zipcode];

  if (Number.isInteger(index)) {
    return UsTimeZones[index];
  }

  return TimeZonesByState[state];
};

export const formatTime = (seconds) =>
  [parseInt(seconds / 60 / 60), parseInt((seconds / 60) % 60), parseInt(seconds % 60)]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');

export const isDateValid = (date) =>
  typeof date === 'string' && new Date(date).toString() !== 'Invalid Date';

export const getAge = (date) => {
  if (!isDateValid(date)) return '-';

  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const formatDuration = (seconds) => {
  if (isNaN(seconds) || seconds < 0) {
    return '-';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours} h `;
  }

  if (minutes > 0 || hours > 0) {
    result += `${minutes} min `;
  }

  result += `${remainingSeconds} sec`;

  return result.trim();
};

export const getClosestDate = (datesArray) => {
  if (!Array.isArray(datesArray) || datesArray.length === 0) return null;

  const now = moment();
  let closestPastDate = null;
  let closestFutureDate = null;

  datesArray.forEach((dateStr) => {
    const date = moment(dateStr);
    if (!date.isValid()) {
      return;
    }

    if (date.isSameOrBefore(now)) {
      if (closestPastDate === null || now.diff(date) < now.diff(closestPastDate)) {
        closestPastDate = date;
      }
    }

    if (date.isAfter(now)) {
      if (closestFutureDate === null || date.diff(now) < closestFutureDate.diff(now)) {
        closestFutureDate = date;
      }
    }
  });

  return closestPastDate
    ? closestPastDate.format('MM/DD/YYYY')
    : closestFutureDate.format('MM/DD/YYYY');
};

export const secondsToMinutes = (seconds) => (seconds ? Math.round(seconds / 60) : 0);

export const minutesToSeconds = (minutes) => (minutes ? minutes * 60 : 0);
