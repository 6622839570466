const MARGIN_TOP = 5;

export const getPaperStyles = (paperRef, selectRef) => {
  const { height: paperHeight } = paperRef.current.getBoundingClientRect();
  const {
    width: selectWidth,
    bottom: selectBottom,
    top: selectTop,
  } = selectRef.current.getBoundingClientRect();

  const styles = { width: `${selectWidth}px` };

  if (selectBottom + paperHeight < window.innerHeight) {
    Object.assign(styles, { top: `${selectBottom + MARGIN_TOP}px` });
  } else {
    Object.assign(styles, { top: `${selectTop - paperHeight - MARGIN_TOP}px` });
  }

  return styles;
};
