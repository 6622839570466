import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';

import Patient from 'common-src/models/Patient';
import { getData } from 'common-src/models/PatientGlucoseReading/selectors';
import { Colors, ColorsNew } from 'common-src/styles';

import { NoneView } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import { BGMPeriodsFilter } from 'src/pages/PatientDetails/fragments/Biometrics/constants';
import { BGMSubHeader } from 'src/pages/PatientDetails/fragments/Biometrics/headers';

import styles from './BGMChart.module.scss';
import {
  generateChartData,
  generateChartLabels,
  getDataRange,
  getFilteredData,
  getGradient,
  getPointColor,
  getTooltipBody,
  getTooltipFooter,
  getYAxesConfig,
} from './helpers';

const LINE_DEVIATION = 10;

const BGMChart = ({ patient, dayTimePeriod, endingDate, selectedPeriod, setIsChartDrawn }) => {
  const [filteredData, setFilteredData] = useState([]);

  const { data, timeZone } = useCustomSelector((state) =>
    getData(state, patient.id, selectedPeriod, BGMPeriodsFilter[dayTimePeriod.value], endingDate),
  );

  const chartData = useMemo(() => generateChartData(filteredData), [filteredData]);

  const chartLabels = useMemo(
    () => generateChartLabels(selectedPeriod, endingDate),
    [selectedPeriod, endingDate],
  );

  const { minValue, maxValue, avgValue } = useMemo(
    () => getDataRange(filteredData),
    [filteredData],
  );

  const shouldDisplayLine = minValue + LINE_DEVIATION <= maxValue;
  const lowValue = patient.bgmHypoglycemicThreshold;
  const highValue = patient.bgmHyperglycemicThreshold;
  const axisYValues = {
    lowValue: typeof lowValue === 'number' ? lowValue : null,
    minValue: shouldDisplayLine ? minValue : null,
    avgValue: shouldDisplayLine ? (avgValue + LINE_DEVIATION <= maxValue ? avgValue : null) : null,
    highValue: typeof highValue === 'number' ? highValue : null,
    maxValue,
  };
  const ticks = Object.values(axisYValues).map((item) => ({ value: item }));

  useEffect(() => {
    if (data.length === 0) {
      setFilteredData([]);
      return;
    }

    (async () => {
      const subtractHours = 6;
      const receivedData = await getFilteredData(
        [...data].sort(
          (a, b) =>
            new Date(b.getRecordedAt('YYYY-MM-DD HH:mm:ss')) -
            new Date(a.getRecordedAt('YYYY-MM-DD HH:mm:ss')),
        ),
        (inputs, firstEl) =>
          inputs.filter((input) =>
            moment(input.getRecordedAt('YYYY-MM-DD HH:mm:ss')).isSameOrAfter(
              moment(firstEl?.getRecordedAt('YYYY-MM-DD HH:mm:ss')).subtract(
                subtractHours,
                'hours',
              ),
            ),
          ),
        (inputs, firstEl) =>
          inputs.filter((input) =>
            moment(input.getRecordedAt('YYYY-MM-DD HH:mm:ss')).isSameOrBefore(
              moment(firstEl?.getRecordedAt('YYYY-MM-DD HH:mm:ss')).subtract(
                subtractHours,
                'hours',
              ),
            ),
          ),
        selectedPeriod <= 30,
      );
      setFilteredData(receivedData);
    })();
  }, [data.length, selectedPeriod]);

  const lineData = {
    datasets: [
      {
        data: chartData,
        animation: false,
        borderColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }

          return getGradient(ctx, chartArea);
        },
        pointStyle: 'circle',
        borderWidth: 3,
        hoverBorderWidth: 3,
        pointRadius: 6,
        pointHoverRadius: 7,
        pointBorderColor: 'white',
        pointHoverBorderColor: 'white',
        backgroundColor: (ctx) => getPointColor(filteredData[ctx.dataIndex], axisYValues),
        hoverBackgroundColor: (ctx) => getPointColor(filteredData[ctx.dataIndex], axisYValues),
      },
    ],
    labels: chartLabels,
  };

  const lineOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        position: 'nearest',
        backgroundColor: ColorsNew.lightGreen,
        bodyColor: ColorsNew.darkGreen,
        footerColor: ColorsNew.darkGreen,
        borderColor: 'rgba(155, 206, 182, 0.9)',
        borderWidth: 1,
        displayColors: false,
        bodyFont: { size: 12, lineHeight: 1.4 },
        footerFont: { weight: 400, size: 11 },
        caretPadding: 20,
        caretSize: 0,
        padding: { top: 5, bottom: 5, left: 10, right: 10 },
        callbacks: {
          label: (item) => getTooltipBody(item, filteredData, timeZone),
          footer: (items) => getTooltipFooter(items, selectedPeriod, timeZone),
          title: () => '',
        },
      },
    },
    animation: {
      duration: 0,
      onComplete: () => {
        setIsChartDrawn(true);
      },
    },
    clip: false,
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        distribution: 'series',
        type: 'time',
        time: {
          unit: 'day',
          stepSize: 1,
          displayFormats: { hour: 'M/D h A', day: 'M/D', week: 'M/D/YYYY' },
        },
        ticks: { source: 'auto', color: ColorsNew.mediumGreen },
        border: {
          display: false,
        },
        grid: {
          drawOnChartArea: false,
          tickColor: Colors.darkGray,
          drawTicks: false,
        },
      },
      y: {
        min: Math.min(axisYValues.minValue, axisYValues.lowValue) - 10,
        max: Math.max(axisYValues.maxValue, axisYValues.highValue),
        display: false,
        grid: {
          display: false,
        },
      },
      y1: getYAxesConfig(
        'left',
        (t) => !t?.label?.includes('high') && !t?.label?.includes('low'),
        axisYValues,
        ticks,
      ),
      y2: getYAxesConfig(
        'right',
        (t) => t?.label?.includes('high') || t?.label?.includes('low'),
        axisYValues,
        ticks,
      ),
    },
  };

  const renderChart = () => (
    <>
      <BGMSubHeader endingDate={endingDate} selectedPeriod={selectedPeriod} />
      <div className={styles.wrapper}>
        <Line data={lineData} options={lineOptions} />
      </div>
    </>
  );

  const renderEmpty = () => (
    <div className={styles.empty}>{_.isEmpty(data) && <NoneView text="No Data Available" />}</div>
  );

  return (
    <div className={styles.container}>
      {filteredData.length >= 1 ? renderChart() : renderEmpty()}
    </div>
  );
};

BGMChart.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  dayTimePeriod: PropTypes.object,
  endingDate: PropTypes.string,
  selectedPeriod: PropTypes.number,
  setIsChartDrawn: PropTypes.func,
};

export default BGMChart;
