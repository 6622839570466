import React from 'react';

import { CommonImages } from 'common-src/assets/Images';

import useDocumentTitle from 'src/hooks/useDocumentTitle';

import styles from './Login.module.scss';
import LoginForm from './LoginForm';

const Login = () => {
  useDocumentTitle('');

  return (
    <div className={styles.container}>
      <p className={styles.logoContainer}>
        <img className={styles.logo} src={CommonImages.logoBird} alt="logo" />
        <span className={styles.logoText}>Perry</span>
      </p>
      <article className={styles.formContainer}>
        <div className={styles.form}>
          <p className={styles.header}>
            <span className={styles.headerText}>Welcome to Perry</span>
            <span className={styles.subheaderText}>Login with your Google Account</span>
          </p>
          <LoginForm />
        </div>
      </article>
    </div>
  );
};

export default Login;
