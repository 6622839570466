export const getInitialTemplateValues = (modules) =>
  modules.map(({ id, moduleType, title, isExpanded, isSelected, sections, version }) => ({
    id,
    moduleType,
    title,
    version,
    isExpanded: !!isExpanded,
    isSelected: !!isSelected,
    sections: sections.map(({ id, conditions, contents, header, instructions }) => ({
      id,
      conditions,
      header,
      instructions,
      contents: contents.map((q) => {
        const { type, defaultValue } = q;
        const question = q;

        switch (type) {
          case 'bool':
            question.value = null;
            break;
          case 'checkbox':
          case 'multiSelect':
            question.value = [];
            break;
          case 'radio':
          case 'text':
          case 'number':
          case 'date':
            question.value = defaultValue || '';
            break;
          default:
            // Do nothing
            break;
        }

        return question;
      }),
    })),
  }));
