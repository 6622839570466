import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import PatientMedicalProblem, { getDxStatusById } from 'common-src/models/PatientMedicalProblem';

import { InfoItem } from 'src/components/elements';
import BasePopup from 'src/popups/BasePopup';

import styles from './ProblemDetailsPopup.module.scss';

const ProblemDetailsPopup = ({ open, onClose, description, medicalProblem }) => (
  <BasePopup id="problem-details" open={open} onClose={onClose}>
    <div className={[styles.container, 'flex-column', 'gap-18'].join(' ')}>
      <div className={[styles.titleContainer, 'vertically-centered', 'primary-border-b'].join(' ')}>
        <div className={[styles.textContainer, 'gap-20', 'vertically-centered'].join(' ')}>
          <span id="icd10-description" className={[styles.titleText, 'font-s-22'].join(' ')}>
            {description}
          </span>
          <span id="icd10-code" className={[styles.icd10Text, 'font-w-600', 'font-s-14'].join(' ')}>
            {medicalProblem?.icd10Code || '-'}
          </span>
        </div>
        <img
          id="close-btn"
          className="icon-btn"
          src={CommonIcons.closeIcon}
          alt="close-icon"
          onClick={onClose}
          role="presentation"
        />
      </div>
      <div className={styles.itemsRow}>
        <InfoItem
          classNames={[styles.item]}
          textId="dx-date"
          title="Dx Date"
          content={
            medicalProblem?.dxDate ? moment(medicalProblem?.dxDate).format('MM/DD/YYYY') : '-'
          }
          small
        />
        <InfoItem
          classNames={[styles.item]}
          textId="created-by"
          title="Created By"
          content={medicalProblem?.createdBy?.getName(false, true)}
          small
        />
        <InfoItem
          classNames={[styles.item]}
          textId="dx-status"
          title="Status"
          content={getDxStatusById(medicalProblem?.dxStatus)?.label || '-'}
          small
        />
      </div>
      <div className={styles.itemsRow}>
        <InfoItem
          classNames={[styles.item]}
          textId="last-modified"
          title="Last Modified"
          content={
            medicalProblem?.updatedAt ? moment(medicalProblem?.updatedAt).format('MM/DD/YYYY') : '-'
          }
          small
        />
        <InfoItem
          classNames={[styles.item]}
          textId="last-modified-by"
          title="Last Modified By"
          content={medicalProblem?.updatedBy?.getName(false, true)}
          small
        />
      </div>
      <div className={styles.separator} />
      <InfoItem
        classNames={[styles.padding]}
        textId="comments"
        title="Comments"
        content={medicalProblem?.comments || '-'}
        small
      />
    </div>
  </BasePopup>
);

ProblemDetailsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  medicalProblem: PropTypes.exact(PatientMedicalProblem.schema),
};

export default ProblemDetailsPopup;
