import _ from 'lodash';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { getRoleConfig } from 'common-src/features/auth';
import { abortControllers } from 'common-src/features/rest';

import { AlertGroup, ModalGroup, RequestHandlerScreen } from 'src/components/base';
import { MainNavigation, TabsNavigation } from 'src/components/navigation';
import { AwsConnectDialer } from 'src/components/widgets';
import { CLIENT_ROOT } from 'src/constants/paths';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useSettingsPolling from 'src/hooks/useSettingsPolling';

import { lazyRetry } from './helpers';

const DocumentationIndex = lazy(() => lazyRetry(() => import('src/pages/DocumentationIndex')));
const Settings = lazy(() => lazyRetry(() => import('src/pages/Settings')));
const PatientDetails = lazy(() => lazyRetry(() => import('src/pages/PatientDetails')));
const ManageClients = lazy(() => lazyRetry(() => import('src/pages/ManageClients')));
const DashboardPage = lazy(() => lazyRetry(() => import('src/pages/DashboardPage')));

const ROUTES_WITHOUT_LEFT_NAVIGATION = [
  `${CLIENT_ROOT}/documentation/details/`,
  `${CLIENT_ROOT}/documentation/patient/`,
];

const Dashboard = () => {
  const location = useLocation();

  const permissions = useCustomSelector(getRoleConfig);

  const shouldShowTopNavigation = _.isEmpty(
    _.filter(ROUTES_WITHOUT_LEFT_NAVIGATION, (route) => location.pathname?.startsWith(route)),
  );

  const patientId = location?.pathname?.match(/\/[0-9]+\//g, '')?.[0]?.replaceAll('/', '');

  useSettingsPolling();

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      abortControllers.forEach((c) => c.abort());
    });
  }, []);

  const renderNavigation = () => {
    if (!shouldShowTopNavigation) return null;

    return (
      <nav>
        <TabsNavigation />
        <MainNavigation />
      </nav>
    );
  };

  const renderScreen = () => (
    <Suspense fallback={<RequestHandlerScreen isRequesting requestingText="Loading Perry" />}>
      <Routes>
        <Route
          path={`${CLIENT_ROOT}/patients/details/:patientId/*`}
          element={<PatientDetails key={`patient-details-${patientId || ''}`} />}
        />
        <Route
          path={`${CLIENT_ROOT}/documentation/details/:patientId/:documentationId/*`}
          element={<DocumentationIndex />}
        />
        <Route path={`${CLIENT_ROOT}/settings/*`} element={<Settings />} />
        {permissions.canManageClients && (
          <Route path={`${CLIENT_ROOT}/manage-clients`} element={<ManageClients />} />
        )}
        <Route path={`${CLIENT_ROOT}/dashboard/*`} element={<DashboardPage />} />
      </Routes>
    </Suspense>
  );

  return (
    <>
      {renderNavigation()}
      <main>{renderScreen()}</main>
      <ModalGroup />
      <AlertGroup />
      <AwsConnectDialer />
    </>
  );
};

export default Dashboard;
