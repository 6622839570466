export const initialState = {
  name: '',
  startDate: null,
  endDate: null,
  type: null,
  reaction: null,
  severity: null,
  comments: '',
};

export const UPDATABLE_FIELDS = ['startDate', 'endDate', 'comments'];
