import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePathSelector = (saveStateFunc) => {
  const location = useLocation();
  const pathRef = useRef(location?.pathname);

  useEffect(
    () => () => {
      saveStateFunc(pathRef.current);
    },
    [],
  );

  useEffect(() => {
    const route = pathRef.current.split('/')[2];
    if (!location?.pathname.includes(route)) return;

    pathRef.current = location?.pathname;
  }, [location?.pathname]);

  return null;
};

export default usePathSelector;
