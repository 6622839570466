import { CLEAR, SET_ORM } from './actionTypes';

export const setOrm = (orm) => ({
  type: SET_ORM,
  payload: { orm },
});

export const clearOrm = () => ({
  type: CLEAR,
});
