import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import styles from './BGMReadingItem.module.scss';
import { getColor, getTooltipStyles } from './helpers';

const BGMReadingItem = ({ reading }) => {
  const [open, setOpen] = useState(false);
  const [positionStyles, setPositionStyles] = useState({});

  const itemRef = useRef(null);
  const tooltipRef = useRef(null);

  const { data, timeZoneAbbr } = reading;
  const { v: value } = data;

  useEffect(() => {
    if (!itemRef.current || !tooltipRef.current) return;

    setPositionStyles(getTooltipStyles(itemRef, tooltipRef));
  }, [itemRef.current, tooltipRef.current, window.innerHeight, window.innerWidth]);

  return (
    <div className="relative">
      <span
        ref={itemRef}
        className={[
          styles.item,
          'flex-row',
          'vertically-centered',
          'horizontally-centered',
          'border-r-6',
          'font-s-14',
          'font-w-600',
        ].join(' ')}
        style={{ backgroundColor: getColor(value) }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {value}
      </span>
      {open && (
        <div
          ref={tooltipRef}
          className={[styles.tooltip, 'fixed', 'flex-column', 'border-r-6', 'p-12'].join(' ')}
          style={{ backgroundColor: getColor(value), ...positionStyles }}
        >
          <p className="font-s-12 font-w-600 m-b-6">{value} mg/dL</p>
          <p className="font-s-10 font-w-500">
            {reading.getRecordedAt('MM/DD/YYYY hh:mm A')} {timeZoneAbbr}
          </p>
        </div>
      )}
    </div>
  );
};

BGMReadingItem.propTypes = {
  reading: PropTypes.object,
};

export default BGMReadingItem;
