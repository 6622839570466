import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  getLabReadingLabel,
  getPatientLabResultsByType,
} from 'common-src/models/PatientLabReading';

import { TabsViewNew } from 'src/components/tabs';
import useCustomSelector from 'src/hooks/useCustomSelector';
import BasePopup from 'src/popups/BasePopup';

import { PopupTabs } from './constants';
import { GraphTab, TableTab } from './tabs';
import styles from './TrendedResultsPopup.module.scss';

const TrendedResultsPopup = ({ open, onClose, typeId, patientId }) => {
  const tabs = Object.keys(PopupTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const results = useCustomSelector((state) =>
    getPatientLabResultsByType(state, patientId, typeId),
  );

  const renderTab = () => {
    switch (selectedTab) {
      case tabs[0]:
        return <GraphTab results={results} />;
      case tabs[1]:
        return <TableTab results={results} />;
      default:
        return null;
    }
  };

  return (
    <BasePopup
      id="trended-results"
      open={open}
      onClose={onClose}
      title="Trended Results"
      subtitle={getLabReadingLabel(typeId)}
      customStyle={{ minWidth: '50vw', minHeight: '60vh' }}
    >
      <div className={styles.wrapper}>
        <TabsViewNew
          classNames={[styles.tabs, 'm-b-40', 'gap-30']}
          onTabSelected={setSelectedTab}
          options={PopupTabs}
        />
        {renderTab()}
      </div>
    </BasePopup>
  );
};

TrendedResultsPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  typeId: PropTypes.number,
  patientId: PropTypes.number,
};

export default TrendedResultsPopup;
