export const REQUEST_PAGE_SIZE = 10;
export const BOTTOM_DEVIATION = 2500;

export const AppointmentType = {
  canceled: 'Canceled',
  scheduled: 'Scheduled',
  rescheduled: 'Rescheduled',
};

export const CallMethod = {
  Outbound: 'OUTBOUND',
  Inbound: 'INBOUND',
  Transfer: 'TRANSFER',
  Callback: 'CALLBACK',
  Api: 'API',
  QueueTransfer: 'QUEUE_TRANSFER',
  ExternalOutbound: 'EXTERNAL_OUTBOUND',
  Monitor: 'MONITOR',
  Disconnect: 'DISCONNECT',
};

export const ChatDirection = {
  Outbound: 'OUTBOUND',
  Inbound: 'INBOUND',
};

export const ChatStatus = {
  Sent: 'SENT',
  Inbound: 'SUCCESS',
};

export const MessageTypes = {
  Documentation: 1,
  TextMessage: 2,
  MissedCall: 3,
  CompletedCall: 4,
  PlatformStatus: 5,
  Appointment: 6,
  DeviceOrdered: 7,
  DeviceShipped: 8,
  DeviceDelivered: 9,
  ClinicalAlert: 10,
  HoldRequest: 11,
  PotentialMissedCall: 12,
  Reminder: 13,
  CgmUsageAlert: 14,
  SmartGoalCreated: 15,
  SmartGoalAccomplished: 16,
  CgmOrder: 17,
  AwscCall: 18,
  AwscChatMessage: 19,
};

export const initialFilters = {
  types: [],
  alertsOnly: false,
  messagesOnly: false,
  appointmentsOnly: false,
};

export const initialSmsConfig = {
  text: '',
  error: '',
  isLoading: false,
};
