import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import PatientMedication, {
  getFrequencyById,
  getRouteById,
  getSourceById,
  getStatusById,
} from 'common-src/models/PatientMedication';

import { InfoItem } from 'src/components/elements';
import BasePopup from 'src/popups/BasePopup';

import styles from './MedicationDetailsPopup.module.scss';

const MedicationDetailsPopup = ({ medication, onClose }) => (
  <BasePopup
    id="medication-details"
    open
    onClose={onClose}
    title={medication.med?.fullName?.split(', ', 3)?.join(', ') || ''}
  >
    <div className={styles.firstGrid}>
      <InfoItem
        title="Date Written"
        content={medication.createdAt && moment(medication.createdAt).format('MM/DD/YYYY')}
        textId="date-written"
        small
      />
      <InfoItem
        title="Date Filled"
        content={medication.filledDate && moment(medication.filledDate).format('MM/DD/YYYY')}
        textId="date-filled"
        small
      />
      <InfoItem
        title="Expiration"
        content={medication.endDate && moment(medication.endDate).format('MM/DD/YYYY')}
        textId="expiration"
        small
      />
      <InfoItem
        title="Prescriber"
        content={medication?.prescriber || '-'}
        textId="prescriber"
        small
      />
      <InfoItem
        classNames={[styles.width75]}
        title="Pharmacy"
        content={medication?.pharmacy || '-'}
        textId="pharmacy"
        small
      />
      <InfoItem
        title="Source"
        content={getSourceById(medication?.source)?.label || '-'}
        textId="source"
        small
      />
      <InfoItem
        title="Route"
        content={getRouteById(medication?.route)?.label || '-'}
        textId="route"
        small
      />
      <InfoItem
        title="Frequency"
        content={getFrequencyById(medication?.frequency)?.label || '-'}
        textId="frequency"
        small
      />
      <div />
    </div>
    <InfoItem
      classNames={[styles.instructions, 'primary-border-t']}
      title="Instructions"
      content={medication?.instructions?.replace(/\s+/g, ' ') || '-'}
      textId="instructions"
      small
      multiline
    />
    <InfoItem
      classNames={[styles.notes, 'primary-border-b']}
      title="Notes"
      content={medication.notes}
      textId="notes"
      small
    />
    <div className={styles.secondGrid}>
      <InfoItem
        title="Last Updated"
        content={medication.updatedAt && moment(medication.updatedAt).format('MM/DD/YYYY')}
        textId="last-updated"
        small
      />
      <InfoItem
        classNames={[styles.width75]}
        title="Last Updated By"
        content={medication.updatedBy?.getName(true, false)}
        textId="updated-by"
        small
      />
      <InfoItem
        title="Status"
        content={getStatusById(medication?.status)?.label || '-'}
        textId="status"
        small
      />
    </div>
  </BasePopup>
);

MedicationDetailsPopup.propTypes = {
  onClose: PropTypes.func,
  medication: PropTypes.exact(PatientMedication.schema),
};

export default MedicationDetailsPopup;
