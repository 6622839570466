import _ from 'lodash';

import { SmartGoalTypes } from 'common-src/models/PatientSmartGoal';

import { StageOfChangeConfig, TypesConfig } from './constants';

export const getProgressById = (id) => _.find(TypesConfig, (type) => type.id === id);

export const getStageOfChangeById = (id) => _.find(StageOfChangeConfig, (state) => state.id === id);

export const getTypeLabelById = (id) => {
  switch (id) {
    case SmartGoalTypes.BGMonitoring.id: {
      return 'Monitoring';
    }
    case SmartGoalTypes.Hydration.id: {
      return 'Hydration';
    }
    case SmartGoalTypes.Medications.id: {
      return 'Medication';
    }
    case SmartGoalTypes.Nutrition.id: {
      return 'Nutrition';
    }
    case SmartGoalTypes.PhysicalActivity.id: {
      return 'Physical Activity';
    }
    case SmartGoalTypes.ProviderFollowUp.id: {
      return 'Provider Follow-up';
    }
    case SmartGoalTypes.StressAndCoping.id: {
      return 'Stress and Coping';
    }
    case SmartGoalTypes.WellnessAndSafety.id: {
      return 'Wellness and Safety';
    }
    case SmartGoalTypes.Other.id: {
      return 'Other';
    }
    default: {
      return 'Other';
    }
  }
};
