export const PaymentLinks = Object.freeze({
  ReplacementCharger: {
    label: 'Replacement Charger',
    value: 'https://buy.stripe.com/7sI14WasZ42i2re7sw',
  },
  ReplacementMeter: {
    label: 'Replacement Meter',
    value: 'https://buy.stripe.com/dR600S7gN9mC5Dq28e',
  },
  ReplacementLancingTool: {
    label: 'Replacement Lancing Tool',
    value: 'https://buy.stripe.com/bIY00S58F7eugi4aEH',
  },
  ReplacementLancets: {
    label: 'Replacement Lancets',
    value: 'https://buy.stripe.com/cN29BsgRncyO0j6fZ0',
  },
  ReplacementStrips: {
    label: 'Replacement Strips',
    value: 'https://buy.stripe.com/3csfZQeJf42i0j6001',
  },
});
