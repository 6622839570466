import moment from 'moment-timezone';

import { ExemptionStatus, ExemptionType } from 'common-src/models/PatientBillingExemption';

import { ExemptionsTabs } from './constants';

export const getTabInfo = (exemptions = []) => {
  const activeExemptions = [];
  const inactiveExemptions = [];

  const sortedExemptions = [...exemptions].sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

  sortedExemptions.forEach((exemption) => {
    if (
      exemption.status !== ExemptionStatus.Active.value ||
      (exemption.exemptionType === ExemptionType.MFA.value &&
        moment(exemption.endDate).isBefore(moment().format('YYYY-MM-DD')))
    ) {
      inactiveExemptions.push(exemption);
      return;
    }

    activeExemptions.push(exemption);
  });

  const tabInfo = {
    ACTIVE: {
      text: ExemptionsTabs.ACTIVE.text,
      count: activeExemptions.length,
    },
    NOT_ACTIVE: {
      text: ExemptionsTabs.NOT_ACTIVE.text,
      count: inactiveExemptions.length,
    },
    ALL: {
      text: ExemptionsTabs.ALL.text,
      count: sortedExemptions.length,
    },
  };

  return {
    tabInfo,
    activeExemptions,
    inactiveExemptions,
    allExemptions: sortedExemptions,
  };
};
