import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { getRoleConfig } from 'common-src/features/auth';

import MasterDetailLayout from 'src/components/navigation/MasterDetailLayout';
import {
  DetailSection,
  MasterSection,
} from 'src/components/navigation/MasterDetailLayout/sections';
import { CallsScreen, FeatureFlagsScreen, MyAccountScreen } from 'src/components/screens';
import AvatarPlaceholder from 'src/components/widgets/AvatarPlaceholder';
import { saveSettingsTabInfo } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import usePathSelector from 'src/hooks/usePathSelector';

import styles from './Settings.module.scss';
import SettingsNav from './SettingsNav';

const Settings = () => {
  const dispatch = useDispatch();
  const canSetFeatureFlags = useCustomSelector((state) => getRoleConfig(state).canSetFeatureFlags);

  useDocumentTitle('Account Details');
  usePathSelector((path) => dispatch(saveSettingsTabInfo(path)));

  return (
    <article>
      <header className={[styles.header, 'vertically-centered'].join(' ')}>
        Edit account information and preferences
      </header>
      <MasterDetailLayout classNames={[styles.settings]}>
        <MasterSection classNames={[styles.aside]}>
          <SettingsNav canSetFeatureFlags={canSetFeatureFlags} />
        </MasterSection>
        <DetailSection classNames={[styles.section]}>
          <Routes>
            <Route path="/">
              <Route index element={<AvatarPlaceholder text="Select a Section" />} />
              <Route path="my-account" element={<MyAccountScreen />} />
              {canSetFeatureFlags && (
                <Route path="feature-flags" element={<FeatureFlagsScreen />} />
              )}
              <Route path="calls" element={<CallsScreen />} />
            </Route>
          </Routes>
        </DetailSection>
      </MasterDetailLayout>
    </article>
  );
};

export default Settings;
