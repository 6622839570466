/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Input } from 'common-src/components/base';
import useOnClickOutside from 'common-src/hooks/useOnClickOutside';
import usePaper from 'common-src/hooks/usePaper';

import styles from './Autocomplete.module.scss';

const Autocomplete = ({
  id,
  label,
  getRequest,
  tooltipText,
  shouldReset,
  renderOption,
  applyCustomOptions,
  classNames = [],
  disabled = false,
  onBlur = () => {},
  autoFocus = false,
  searchOptions = [],
  placeholder = 'Search',
  minSearchTextLength = 2,
  initialOptionsCount = 50,
  paperStyles = { left: 0 },
  onOptionSelect = () => {},
  filterFunc = (options) => options,
}) => {
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(initialOptionsCount);

  const hasMore = useRef(false);

  const { openPaper, closePaper, open } = usePaper();
  const { ref } = useOnClickOutside(() => closePaper());

  useEffect(() => {
    if (searchText.length < minSearchTextLength) {
      setOptions([]);
      setCount(initialOptionsCount);
      return;
    }

    // filter locally
    if (!getRequest) {
      const filteredOptions = filterFunc(searchOptions, searchText).slice(0, count);

      hasMore.current = filteredOptions.length === count;

      setOptions(filteredOptions);
      openPaper();

      return;
    }

    setIsLoading(true);
    getRequest(searchText, count)
      .then((res) => {
        if (res.status !== 200) throw new Error('Error while retrieving results');
        return res.json();
      })
      .then((data) => {
        const results = Object.values(data)?.[0] || [];
        hasMore.current = results.length === count;
        setOptions(applyCustomOptions?.(data) || results);
        openPaper();
      })
      .catch(() => {
        // Do nothing on error
      })
      .finally(() => setIsLoading(false));
  }, [searchText, count]);

  useEffect(() => {
    if (autoFocus) {
      openPaper();
    }
  }, [autoFocus]);

  const renderPaper = () => {
    if (!open) return null;

    return (
      <div
        ref={ref}
        className={[styles.paper, 'absolute', 'm-t-5', 'secondary-border', 'border-r-6'].join(' ')}
        style={{ ...paperStyles, opacity: _.isEmpty(options) ? '0' : '1' }}
      >
        {options.map((op, i) => (
          <div
            key={i}
            id={`option-${i}`}
            role="presentation"
            onClick={() => {
              if (typeof op === 'string') return;

              onOptionSelect(op);
              closePaper();
            }}
            style={{ cursor: typeof op === 'string' ? 'default' : 'pointer' }}
          >
            {renderOption(op)}
          </div>
        ))}
        {hasMore.current && (
          <button
            className={styles.viewMore}
            type="button"
            onClick={() => setCount((prevCount) => prevCount + initialOptionsCount)}
          >
            View more results
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={['relative', ...classNames].join(' ')}>
      <Input
        id={id}
        value={searchText}
        label={label}
        placeholder={placeholder}
        tooltipText={tooltipText}
        leftContent={
          <img
            className={['search-icon', 'disable-select'].join(' ')}
            src={CommonIcons.searchIcon}
            alt="search-icon"
          />
        }
        withError={false}
        disabled={disabled}
        autoFocus={autoFocus}
        onBlur={() => onBlur()}
        onClick={(e) => openPaper(e)}
        debounced
        onFinishEditing={(value) => setSearchText(value)}
        isLoading={isLoading}
        shouldReset={shouldReset}
      />
      {renderPaper()}
    </div>
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  getRequest: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
  shouldReset: PropTypes.bool,
  renderOption: PropTypes.func,
  placeholder: PropTypes.string,
  tooltipText: PropTypes.string,
  paperStyles: PropTypes.object,
  onOptionSelect: PropTypes.func,
  applyCustomOptions: PropTypes.func,
  initialOptionsCount: PropTypes.number,
  minSearchTextLength: PropTypes.number,
  searchOptions: PropTypes.array,
  filterFunc: PropTypes.func,
};

export default Autocomplete;
