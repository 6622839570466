export const PlatformStatus = Object.freeze({
  Active: 1,
  Hold: 2,
  Closed: 3,
  Gray: 4,
});

export const OnboardingStatus = Object.freeze({
  NeedsApproval: 1,
  DeviceShipped: 2,
  NeedsFirstInput: 3,
  NeedsClinicalOnboarding: 4,
  Onboarded: 5,
  Rejected: 6,
});

export const RiskLevelTypes = Object.freeze({
  Low: { id: 1, text: 'Low' },
  Medium: { id: 2, text: 'Medium' },
  High: { id: 3, text: 'High' },
});

export const unavailableActionsStatuses = [PlatformStatus.Closed, PlatformStatus.Hold];

// Risk level
export const RiskLevelUnset = -1;

export const languageOptions = ['English', 'Spanish'];

export const sexOptions = ['Male', 'Female'];

export const ReadingFrequencyTypes = Object.freeze({
  Minimum: {
    id: 'Minimum',
    text: 'Minimum (1x/day)',
  },
  Daily: {
    id: 'Daily',
    text: 'Daily (3x/day)',
  },
  Extensive: {
    id: 'Extensive',
    text: 'Extensive (5x/day)',
  },
});

export const TestingOrdersOptions = [
  'Continue current BS regimen (see below for more details)',
  'SMBG: AM Fasting and 2 hours postprandial (after largest meal)',
  'SMBG: Before meals/snacks, before and after exercise, and before bed',
  'SMBG: Once a Day AM Fasting',
  'SMBG: Once a day, at varying points during day',
];

export const DiagnosisShortNames = ['Prediabetes', 'Type 2', 'Type 1'];

export const HasInsulinPump = Object.freeze({
  Yes: {
    id: true,
    text: 'Patient Uses Insulin Pump',
  },
  No: {
    id: false,
    text: 'N/A',
  },
});

export const TobaccoUse = Object.freeze({
  Endorses: { value: 1, label: 'Endorses' },
  Denies: { value: 2, label: 'Denies' },
  PastUse: { value: 3, label: 'Past Use' },
});

export const AlcoholUse = Object.freeze({
  Endorses: { value: 1, label: 'Endorses' },
  Social: { value: 2, label: 'Social / Recreational' },
  Denies: { value: 3, label: 'Denies' },
  PastUse: { value: 4, label: 'Past Use' },
});

export const RecreationalDrugUse = Object.freeze({
  Endorses: { value: 1, label: 'Endorses' },
  Denies: { value: 2, label: 'Denies' },
  PastUse: { value: 3, label: 'Past Use' },
});

export const EmploymentStatus = Object.freeze({
  Active: { value: 1, label: 'Active' },
  Inactive: { value: 2, label: 'Inactive' },
  PartTime: { value: 3, label: 'Part-time' },
  Retired: { value: 4, label: 'Retired' },
});

export const Housing = Object.freeze({
  LivesAlone: { value: 1, label: 'Lives alone' },
  Spouse: { value: 2, label: 'Spouse / Partner' },
  OtherNonRelative: { value: 3, label: 'Other non relative' },
  Children: { value: 4, label: 'Children' },
  GrandChildren: { value: 5, label: 'Grand children' },
  Assisted: { value: 6, label: 'Assisted / Senior Living' },
  Caretaker: { value: 7, label: 'Caretaker' },
  OtherRelative: { value: 8, label: 'Other relative' },
});

export const Religion = Object.freeze({
  Christianity: { value: 1, label: 'Christianity' },
  Islam: { value: 2, label: 'Islam' },
  Hinduism: { value: 3, label: 'Hinduism' },
  Buddhism: { value: 4, label: 'Buddhism' },
  Judaism: { value: 5, label: 'Judaism' },
  Other: { value: 6, label: 'Other' },
});

export const defaultAddressValues = {
  city: '',
  state: '',
  street1: '',
  street2: '',
  zipcode: '',
};

export const Race = Object.freeze({
  American: { value: 1, label: 'American Indian or Alaska Native' },
  Asian: { value: 2, label: 'Asian' },
  Black: { value: 3, label: 'Black or African American' },
  Hispanic: { value: 4, label: 'Hispanic or Latino' },
  NativeHawaiian: { value: 5, label: 'Native Hawaiian or Other Pacific Islander' },
  White: { value: 6, label: 'White' },
  Other: { value: 7, label: 'Other' },
});

export const Gender = Object.freeze({
  Male: { value: 1, label: 'Male' },
  Female: { value: 2, label: 'Female' },
  TransgenderMan: { value: 3, label: 'Transgender man' },
  TransgenderWoman: { value: 4, label: 'Transgender woman' },
  NonBinary: { value: 5, label: 'Non-binary/non-conforming' },
  Other: { value: 6, label: 'Other' },
});
