import _ from 'lodash';

import { validatePatientCareProvider } from 'common-src/models/PatientCareProvider';
import { getProvidersRequests } from 'common-src/models/PatientCareProvider/helpers';

export const extractCareProvider = (provider) => ({
  id: provider.id,
  typeId: provider.typeId,
  name: provider.name,
  phone: provider.phone,
  fax: provider.fax,
  patient: provider.patient?.id,
});

export const getInitialProviderData = (patientId) => ({
  patientId,
  phone: '',
  name: '',
  fax: '',
  typeId: 1,
});

export const resetCareProviderErrors = (careProviders, careProvider) => {
  const index = _.indexOf(careProviders, careProvider);
  return {
    [`${index}.name`]: '',
    [`${index}.typeId`]: '',
    [`${index}.phone`]: '',
    [`${index}.fax`]: '',
  };
};

export const catchErrors = (formData) => {
  const errors = {};
  formData.forEach((p, i) => {
    if (!p.patientId) return;
    Object.entries(p).forEach(([field, value]) => {
      const err = validatePatientCareProvider(field, value);
      if (err[field]) {
        Object.assign(errors, { [`${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequests = (formData, careProviders) => {
  const requests = [];

  const oldProviders = careProviders.map((provider) => extractCareProvider(provider));
  if (!_.isEqual(formData, oldProviders)) {
    requests.push(...getProvidersRequests(oldProviders, formData));
  }

  return requests;
};
