import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'common-src/components/base';

import styles from './MultiTextItem.module.scss';

const MultiTextItem = ({ subtitle, onClick, isActive = true, title = '-' }) => (
  <Tooltip title={title}>
    <div
      style={{ opacity: isActive ? 1 : 0.5 }}
      className={[styles.container, 'flex-column'].join(' ')}
      onClick={onClick}
      role="presentation"
    >
      <span
        className={[styles.title, 'font-w-500', 'font-s-14'].join(' ')}
        style={{ textDecorationLine: onClick ? 'underline' : 'none' }}
      >
        {title || '-'}
      </span>
      <span className={[styles.subtitle, 'font-w-500', 'font-s-12'].join(' ')}>
        {subtitle || '-'}
      </span>
    </div>
  </Tooltip>
);

MultiTextItem.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default MultiTextItem;
