import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserId, getRoleConfig } from 'common-src/features/auth';
import { apiRequest } from 'common-src/features/rest';
import Client from 'common-src/models/Client';
import PatientInteraction, {
  createPatientInteractionApiRequest,
  getDocumentationNote,
  getPatientInteractionAddendums,
  moduleTypes,
} from 'common-src/models/PatientInteraction';
import { convertJSXToHTML } from 'common-src/utils/domUtils';
import { uuidv4 } from 'common-src/utils/stringUtils';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsButton } from 'src/components/buttons';
import { EmptyPlaceholder, HighlightedText } from 'src/components/elements';
import { openModal } from 'src/features/modals';
import { getPatientNote } from 'src/features/tabsState';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';

import DocumentationItem from '../DocumentationItem';
import styles from './DocumentationContent.module.scss';

const DocumentationContent = ({
  patientId,
  documentation,
  client,
  textToHighlight,
  clients,
  allAddendums = [],
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { serviceAt, reasons, note, id, reason, type } = documentation || {};

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [addendumsData, setAddendumsData] = useState([]);

  // redux
  const reduxAddendums = useCustomSelector((state) => getPatientInteractionAddendums(state, id));
  const currentUserId = useCustomSelector((state) => getCurrentUserId(state));
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.documentation,
  );
  const patientNoteId = useCustomSelector((state) => getPatientNote(state, patientId));

  const { showAlert, AlertType } = useAlert();

  // memo
  const addendums = useMemo(
    () =>
      _.sortBy(
        _.uniqBy(
          [
            ...addendumsData,
            ...allAddendums.filter((addendum) => addendum.parentInteractionId === id),
            ...reduxAddendums,
          ],
          'id',
        ),
        'serviceAt',
      ),
    [allAddendums, id, addendumsData, reduxAddendums],
  );
  const documentationNote = useMemo(
    () => getDocumentationNote(note, addendums.reverse(), clients),
    [note, addendums],
  );

  const sendAddendumsRequest = () => {
    if (isLoading) return;

    setIsLoading(true);
    apiRequest({
      endpoint: 'patientInteractions',
      queryParams: {
        patientId,
        parent_interaction_id: id,
        isDraft: false,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAddendumsData(data.PatientInteraction || []);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    sendAddendumsRequest();
  }, [id]);

  const canAddAddendum = () =>
    moment().subtract(72, 'hours').isSameOrBefore(documentation.serviceAt) &&
    (actions?.addendAnyNote || currentUserId === client?.id);

  const addAddendum = () => {
    if (patientNoteId) {
      dispatch(
        openModal(ModalType.WARNING, {
          title: 'Notice',
          message:
            'A note is currently in progress. Please pause or finish the current note before attempting to resume another note on the patient’s chart.',
          onSubmit: () => {},
          submitButtonText: 'Close',
          showCancelButton: false,
        }),
      );
      return;
    }

    const documentationId = uuidv4();
    dispatch(
      openModal(ModalType.ADD_ADDENDUM, {
        patientId,
        callback: () =>
          dispatch(
            createPatientInteractionApiRequest({
              body: {
                patientId,
                id: documentationId,
                moduleTypes: [moduleTypes.Addendum.id],
                parentInteractionId: id,
                reason,
                type,
              },
              successBlock: () =>
                navigate(
                  `/dashboard-client/patients/details/${patientId}/note/${documentationId}/edit`,
                ),
              errorBlock: (err) =>
                showAlert(
                  AlertType.Error,
                  '',
                  `Failed to create documentation.  Err status: ${err.status}.  Err message: ${err.message}`,
                ),
            }),
          ),
      }),
    );
  };

  const canRequestAdjustments = () =>
    (actions?.requestAdjustments || currentUserId === client?.id) &&
    ![moduleTypes.CloseRequest.id, moduleTypes.HoldRequest.id].includes(
      documentation.moduleTypes?.[0],
    );

  const requestAdjustments = () =>
    dispatch(
      openModal(ModalType.REQUEST_ADJUSTMENTS, { patientId, interactionId: documentation.id }),
    );

  const copyDocTextToClipboard = () => {
    const element = convertJSXToHTML(documentationNote);

    navigator.clipboard
      .writeText(element.textContent)
      .then(() => showAlert(AlertType.Success, '', 'Text copied to clipboard.'));
  };

  const options = _.compact([
    canAddAddendum() && {
      id: 'add-addendum',
      text: 'Add Addendum',
      iconSrc: Icons.boldGearIcon,
      onClick: async () => addAddendum(),
    },
    canRequestAdjustments() && {
      id: 'request-adjustments',
      text: 'Request Adjustments',
      iconSrc: Icons.boldGearIcon,
      onClick: async () => requestAdjustments(),
    },
    {
      id: 'copy-text',
      text: 'Copy Body Text',
      iconSrc: Icons.boldCheckIcon,
      onClick: async () => copyDocTextToClipboard(),
    },
  ]);

  if (_.isEmpty(documentation)) return <EmptyPlaceholder text="No documentation selected" />;

  return (
    <>
      <div className={[styles.wrapper, 'vertically-centered', 'primary-border-b'].join(' ')}>
        <DocumentationItem
          id={id}
          classNames={[styles.item]}
          date={serviceAt}
          type={reasons?.[0]}
          reasons={reasons}
          client={client}
          showChevron={false}
          iconSrc={Icons.docIcon}
          textToHighlight={textToHighlight}
        />
        <ActionsButton options={options} />
      </div>
      <div className="font-s-15">
        <HighlightedText
          className={styles.note}
          text={documentationNote}
          textToHighlight={textToHighlight}
        />
      </div>
    </>
  );
};

DocumentationContent.propTypes = {
  documentation: PropTypes.exact(PatientInteraction.rawSchema),
  patientId: PropTypes.number,
  client: PropTypes.exact(Client.schema),
  textToHighlight: PropTypes.string,
  allAddendums: PropTypes.arrayOf(PropTypes.exact(PatientInteraction.rawSchema)),
  clients: PropTypes.arrayOf(PropTypes.exact(Client.schema)),
};

export default DocumentationContent;
