import { isEmailValid, isMBIValid, isNameValid } from 'common-src/utils/validationUtils';

export const validatePatient = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'bgmHyperglycemicThreshold':
      if (!value) {
        errMsg = 'Value should not be empty';
      } else if (value < 250 || value > 500) {
        errMsg = 'Value should be between 250 and 500';
      }
      break;
    case 'bgmHypoglycemicThreshold':
      if (!value) {
        errMsg = 'Value should not be empty';
      } else if (value < 40 || value > 100) {
        errMsg = 'Value should be between 40 and 100';
      }
      break;
    case 'firstName':
      if (!value) {
        errMsg = 'First name should not be empty';
      } else if (!isNameValid(value)) {
        errMsg = 'Invalid first name';
      } else if (value.length > 35) {
        errMsg = 'First name should be a maximum of 35 characters';
      }
      break;
    case 'lastName':
      if (!value) {
        errMsg = 'Last name should not be empty';
      } else if (!isNameValid(value)) {
        errMsg = 'Invalid last name';
      } else if (value.length > 35) {
        errMsg = 'Last name should be a maximum of 35 characters';
      }
      break;
    case 'middleName':
      if (value && !isNameValid(value)) {
        errMsg = 'Invalid middle name';
      } else if (value?.length > 35) {
        errMsg = 'Middle name should be a maximum of 35 characters';
      }
      break;
    case 'preferredName':
      if (value && !isNameValid(value)) {
        errMsg = 'Invalid preferred name';
      }
      break;
    case 'email':
      if (value && !isEmailValid(value)) {
        errMsg = 'Invalid email';
      } else if (value?.length > 35) {
        errMsg = 'Email should be a maximum of 35 characters';
      }
      break;
    case 'suffix':
      if (value?.length > 35) {
        errMsg = 'Suffix should be a maximum of 35 characters';
      }
      break;
    case 'dob':
      if (!value) {
        errMsg = 'Date of birth should not be empty';
      }
      break;
    case 'mbiNumber':
      if (value && !isMBIValid(value)) {
        errMsg = 'Invalid medicare number';
      }
      break;
    case 'address.street1':
    case 'shippingAddress.street1':
      if (!value) {
        errMsg = 'Address1 should not be empty';
      } else if (value.length > 35) {
        errMsg = 'Address1 should be a maximum of 35 characters';
      }
      break;
    case 'address.street2':
    case 'shippingAddress.street2':
      if (value?.length > 35) {
        errMsg = 'Address2 should be a maximum of 35 characters';
      }
      break;
    case 'address.city':
    case 'shippingAddress.city':
      if (!value) {
        errMsg = 'City should not be empty';
      } else if (value.length > 35) {
        errMsg = 'City should be a maximum of 35 characters';
      }
      break;
    case 'address.state':
    case 'shippingAddress.state':
      if (!value) {
        errMsg = 'State should not be empty';
      } else if (value.length > 2) {
        errMsg = 'State should be a maximum of 2 characters';
      }
      break;
    case 'address.zipcode':
    case 'shippingAddress.zipcode':
      if (!value) {
        errMsg = 'Zip code should not be empty';
      } else if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
        errMsg = 'Invalid zip code';
      } else if (value.length > 9) {
        errMsg = 'Zip code should be a maximum of 9 characters';
      }
      break;
    case 'sex':
      if (!value) {
        errMsg = 'Sex should not be empty';
      }
      break;
    case 'glucoseStripCount':
      if (!value && value !== 0) {
        errMsg = 'Strip count should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
