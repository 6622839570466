import _ from 'lodash';

export const mapObject = (object, mapperFn) => {
  if (_.isEmpty(object)) return {};

  const mappedObj = Object.entries(object).map(mapperFn);

  return Object.values(mappedObj).reduce((map, [key, value]) => {
    map[key] = value;
    return map;
  }, {});
};

export const getChangedValues = (obj1, obj2, excludedFields = []) => {
  if (!obj1 || !obj2 || typeof obj1 !== 'object' || typeof obj2 !== 'object') return null;

  const res = {};
  Object.keys(obj1).forEach((key) => {
    if (!_.isEqual(obj1[key], obj2[key]) && !excludedFields.includes(key)) {
      Object.assign(res, { [key]: obj2[key] === '' ? null : obj2[key] });
    }
  });

  return res;
};
