import PropTypes from 'prop-types';
import React from 'react';

import styles from './ReadingItem.module.scss';

const ReadingItem = ({ title, content, addition, id }) => {
  const renderContent = () => {
    if (typeof content === 'object') {
      return content;
    }

    return <span className={[styles.content, 'font-s-14'].join(' ')}>{content}</span>;
  };

  return (
    <div id={id} className={[styles.container, 'flex-column', 'font-w-500'].join(' ')}>
      <span className="font-s-10">{title}</span>
      <div className={['vertically-centered', 'gap-6', 'm-t-3'].join(' ')}>
        {renderContent()}
        {addition && <span className="font-s-12">{addition}</span>}
      </div>
    </div>
  );
};

ReadingItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  addition: PropTypes.string,
  content: PropTypes.any,
};

export default ReadingItem;
