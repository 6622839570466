import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import styles from './Toggle.module.scss';

const Toggle = ({ options, classNames, onClick, value, defaultValue, size = 'medium' }) => {
  const isSmall = size === 'small';

  const containerClasses = [styles.container, 'border-r-6'];
  if (classNames) {
    containerClasses.push(...classNames);
  }

  useEffect(() => {
    const initialOption = _.find(Object.values(options), (option) => !option?.disabled);
    onClick(defaultValue || initialOption?.value);
  }, []);

  return (
    <div className={containerClasses.join(' ')}>
      {Object.values(options).map((o) => {
        const optionClasses = [styles.option, 'font-w-500'];
        if (value === o?.value) {
          optionClasses.push(styles.selected);
        }

        if (o?.disabled) {
          optionClasses.push(styles.disabled);
        }

        if (isSmall) {
          optionClasses.push(styles.small);
        }

        return (
          <button
            key={o?.label}
            id={`${o?.label}-button`}
            className={optionClasses.join(' ')}
            type="button"
            onClick={() => onClick(o?.value)}
            disabled={!!o?.disabled}
          >
            {o?.label}
          </button>
        );
      })}
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.any,
  classNames: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default Toggle;
