/* eslint-disable no-unneeded-ternary */
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CustomCheckbox, DatePickerNew, Input, Select } from 'common-src/components/base';
import { createSmartGoal, updateSmartGoal } from 'common-src/models/PatientSmartGoal';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { CheckBoxesData, goalOriginOptions, initialState } from './constants';
import { getIsButtonEnabled } from './helpers';
import styles from './SmartGoalPopup.module.scss';

const SmartGoalPopup = ({ open, onClose, smartGoalId, patientId }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const smartGoalToEdit = useCustomSelector((state) =>
    singleModelSelector(state, 'PatientSmartGoal', smartGoalId),
  );

  const { errors, setErrors, renderButtons } = usePopup();

  const isButtonEnabled = getIsButtonEnabled(smartGoalToEdit?.ref, data, errors);

  useEffect(() => {
    if (!smartGoalToEdit) return;

    const {
      goal,
      typeIds,
      isPatientOriginated,
      startDate,
      followUpDate,
      isActive,
      progressStatus,
      stageOfChangeStatus,
    } = smartGoalToEdit;
    setData({
      goal,
      typeIds,
      isPatientOriginated,
      startDate,
      followUpDate,
      isActive,
      progressStatus,
      stageOfChangeStatus,
    });
  }, [smartGoalToEdit]);

  const onChangeHandler = (key, value) => {
    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [key]: 'Required field',
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [key]: null,
      }));
    }

    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onDateChangeHandler = (key, keyToCompare, labelForKeyToCompare, isBefore, value) => {
    const compareCondition = isBefore
      ? moment(value).isAfter(moment(data?.[keyToCompare]))
      : moment(value).isBefore(moment(data?.[keyToCompare]));

    switch (true) {
      case !value: {
        setErrors((prev) => ({
          ...prev,
          [key]: 'Required field',
        }));
        break;
      }
      case compareCondition: {
        setErrors((prev) => ({
          ...prev,
          [key]: `Date should be ${isBefore ? 'before' : 'after'} ${labelForKeyToCompare}`,
        }));
        break;
      }
      default: {
        setErrors((prev) => ({
          ...prev,
          [key]: null,
          [keyToCompare]: null,
        }));
        break;
      }
    }

    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const checkboxHandler = (key, isChecked) => {
    setData((prev) => ({
      ...prev,
      typeIds: isChecked ? [...prev.typeIds, key] : prev.typeIds.filter((c) => c !== key),
    }));
  };

  const submitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    loadingCallback();

    if (smartGoalToEdit) {
      dispatch(
        updateSmartGoal(
          { ...data, id: smartGoalToEdit.id, patientId },
          {
            successBlock: () => {
              successCallback('SMART Goal edited!');
              onClose(true);
            },
            errorBlock: (err) => errorCallback(err),
          },
        ),
      );

      return;
    }

    dispatch(
      createSmartGoal(
        { ...data, patientId },
        {
          successBlock: () => {
            successCallback('SMART Goal added!');

            if (!shouldClose) {
              setData(initialState);
              return;
            }

            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  const renderDatePicker = ({ id, errorText, placeholder, floatLabel, value, onDateSelected }) => (
    <DatePickerNew
      id={id}
      required
      width="50%"
      value={value}
      header={floatLabel}
      errorText={errorText}
      placeholder={placeholder}
      onDateSelected={onDateSelected}
    />
  );

  return (
    <BasePopup id="smart-goal" open={open} onClose={onClose}>
      <div className={[styles.container, 'flex-column'].join(' ')}>
        <span className={[styles.title, 'font-s-20', 'm-b-16'].join(' ')}>{`${
          smartGoalId ? 'Edit' : 'Add a new'
        } SMART Goal`}</span>
        <Input
          id="goal-input"
          required
          errorText={errors?.goal}
          onTextChange={(value) => onChangeHandler('goal', value)}
          value={data?.goal}
          placeholder="Goal"
          label="Goal"
        />
        <span className={styles.label}>Type(s) of Goal *</span>
        <div className={[styles.checkboxes, 'm-b-16'].join(' ')} style={{ gap: '10px' }}>
          {CheckBoxesData.map((checkbox) => (
            <CustomCheckbox
              id={checkbox.key}
              key={checkbox.key}
              classNames={[styles.checkbox]}
              checked={data.typeIds?.includes(checkbox.id) || false}
              header={checkbox.label}
              onChange={(value) => {
                checkboxHandler(checkbox.id, value);
              }}
            />
          ))}
        </div>
        <Select
          id="goal-origin-select"
          classNames={[styles.select, 'm-b-16']}
          errorText={errors?.isPatientOriginated}
          label="Goal Origin"
          placeholder="Goal Origin"
          options={goalOriginOptions}
          onChange={(option) => {
            setData((prev) => ({ ...prev, isPatientOriginated: option?.value === 'Patient' }));
          }}
          value={
            data.isPatientOriginated !== null
              ? _.find(goalOriginOptions, {
                  value: data.isPatientOriginated ? 'Patient' : 'Provider',
                })
              : null
          }
          required
        />
        <div className={[styles.dates, 'gap-32', 'm-b-20'].join(' ')}>
          {renderDatePicker({
            id: 'goal-start-date',
            errorText: errors?.startDate,
            placeholder: 'Goal Start Date',
            floatLabel: 'Goal Start Date',
            value: data?.startDate || null,
            onDateSelected: (date) =>
              onDateChangeHandler(
                'startDate',
                'followUpDate',
                'follow-up date',
                true,
                date ? moment(date).format('YYYY-MM-DD') : null,
              ),
          })}
          {renderDatePicker({
            id: 'goal-follow-up-date',
            errorText: errors?.followUpDate,
            placeholder: 'Goal Follow-up Date',
            floatLabel: 'Goal Follow-up Date',
            value: data?.followUpDate || null,
            onDateSelected: (date) =>
              onDateChangeHandler(
                'followUpDate',
                'startDate',
                'start date',
                false,
                date ? moment(date).format('YYYY-MM-DD') : null,
              ),
          })}
        </div>
        {renderButtons({
          containerClassName: styles.buttonsContainerRedesigned,
          buttonClassName: styles.button,
          onClose,
          onSubmit: submitHandler,
          isSubmitEnabled: isButtonEnabled,
          submitButtonText: 'Save and close',
          renderSaveButton: !smartGoalId,
        })}
      </div>
    </BasePopup>
  );
};

SmartGoalPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number,
  smartGoalId: PropTypes.number,
};

export default SmartGoalPopup;
