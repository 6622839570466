import { DocumentationTabs } from './constants';

export const getTabInfo = (drafts) => ({
  DOCUMENTATION: {
    id: 0,
    text: DocumentationTabs.DOCUMENTATION.text,
    count: 0,
  },
  DRAFTS: {
    id: 1,
    text: DocumentationTabs.DRAFTS.text,
    count: drafts.length,
  },
});
