import _ from 'lodash';
import moment from 'moment-timezone';

export const generateDailyProfileDates = (dates) => {
  const res = [];

  const startDate = moment(dates[0]);
  const startDay = startDate.isoWeekday();
  if (startDay !== 1) {
    for (let i = startDay - 1; i > 0; i--) {
      res.push(moment(startDate).subtract(i, 'days').format('YYYY-MM-DD'));
    }
  }

  res.push(...dates);

  const endDate = moment([...dates].pop());
  const endDay = endDate.isoWeekday();
  if (endDay !== 7) {
    for (let i = 1; endDay + i <= 7; i++) {
      res.push(moment(endDate).add(i, 'days').format('YYYY-MM-DD'));
    }
  }

  return res;
};

export const hasEmptyChartData = (data) => {
  if (_.isEmpty(data) || !data.averages) return true;
  return [].concat(...data.averages).every((v) => !v);
};
