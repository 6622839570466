import PropTypes from 'prop-types';
import React from 'react';

import styles from './SeparatorLine.module.scss';

const SeparatorLine = ({ vertical, classNames }) => {
  const separatorLineClasses = [];

  if (vertical) {
    separatorLineClasses.push(styles.verticalLine);
  } else {
    separatorLineClasses.push(styles.horizontalLine);
  }

  if (classNames) {
    separatorLineClasses.push(...classNames);
  }

  return <span id="separator-line" className={separatorLineClasses.join(' ')} />;
};

SeparatorLine.propTypes = {
  vertical: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default SeparatorLine;
