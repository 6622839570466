import moment from 'moment-timezone';

import { SurgicalHistoryTabs } from './constants';

export const getTabInfo = (surgicalHistory = []) => {
  const activeSurgicalHistory = [];
  const inactiveSurgicalHistory = [];

  [...surgicalHistory]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((sh) => {
      if (sh.activeTill && moment(sh.activeTill).isBefore(moment.now())) {
        inactiveSurgicalHistory.push(sh);
        return;
      }

      activeSurgicalHistory.push(sh);
    });

  const tabInfo = {
    ACTIVE: {
      text: SurgicalHistoryTabs.ACTIVE.text,
      count: activeSurgicalHistory.length,
    },
    NOT_ACTIVE: {
      text: SurgicalHistoryTabs.NOT_ACTIVE.text,
      count: inactiveSurgicalHistory.length,
    },
  };

  return {
    tabInfo,
    activeSurgicalHistory,
    inactiveSurgicalHistory,
  };
};
