import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import PatientInteraction, {
  getDocumentationById,
  getDocumentationNote,
  getPatientInteractionAddendums,
  moduleTypes,
} from 'common-src/models/PatientInteraction';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { RequestHandlerScreen } from 'src/components/base';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useDocumentationRequests from 'src/hooks/useDocumentationRequests';
import { SectionHeader } from 'src/pages/DocumentationIndex/components';

import styles from '../commonStyles.module.scss';

const ReadOnlyForm = ({ patientId, documentationId, documentation }) => {
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const addendums = useCustomSelector((state) =>
    getPatientInteractionAddendums(state, documentationId),
  );
  const parentInteraction = useCustomSelector((state) =>
    getDocumentationById(state, documentation?.parentInteractionId),
  );

  const isAddendum = documentation?.moduleTypes?.[0] === moduleTypes.Addendum.id;

  const documentationNote = useMemo(() => {
    const note = isAddendum ? parentInteraction?.note : documentation?.note;
    return getDocumentationNote(note || '', isAddendum ? [documentation] : addendums.reverse());
  }, [documentation?.note, parentInteraction?.note, addendums]);

  const { isRequestRunning } = useDocumentationRequests(
    documentationId,
    documentation?.parentInteractionId,
    patientId,
  );

  if (isRequestRunning) {
    return <RequestHandlerScreen isRequesting requestingText="Retrieving documentation..." />;
  }

  return (
    <>
      <SectionHeader patient={patient} isReadOnly />
      <section className={[styles.section, styles.readOnly].join(' ')}>
        <p className={[styles.note, 'm-t-22'].join(' ')}>{documentationNote}</p>
      </section>
    </>
  );
};

ReadOnlyForm.propTypes = {
  patientId: PropTypes.number,
  documentation: PropTypes.exact(PatientInteraction.schema),
  documentationId: PropTypes.string,
};

export default ReadOnlyForm;
