import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import styles from './InfoCard.module.scss';

const InfoCard = ({
  onClick,
  addition,
  id = '',
  date = '-',
  title = '-',
  content = '-',
  subtitle = '-',
  classNames = [],
}) => (
  <div
    id={id}
    className={[styles.container, 'vertically-centered', 'primary-border', ...classNames].join(' ')}
  >
    <div className={[styles.wrapper, 'flex-column', 'gap-4'].join(' ')}>
      <span className={[styles.title, 'font-w-600', 'font-s-12'].join(' ')}>{title}</span>
      <p className={[styles.content, 'font-w-700', 'font-s-16'].join(' ')}>
        <span>{content}</span>
        {addition && <span className={['font-s-12', 'm-l-5'].join(' ')}>{addition}</span>}
      </p>
      <span className={[styles.subtitle, 'font-w-500', 'font-s-14'].join(' ')}>{subtitle}</span>
      <span className={[styles.date, 'font-w-500', 'font-s-12'].join(' ')}>{date}</span>
    </div>
    {onClick && (
      <img
        id={`${id}-action-button`}
        className={['icon-btn', 'm-r-16'].join(' ')}
        src={CommonIcons.rightArrowIcon}
        alt="right-btn"
        onClick={onClick}
        role="presentation"
      />
    )}
  </div>
);

InfoCard.propTypes = {
  id: PropTypes.string,
  date: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.any,
  subtitle: PropTypes.string,
  addition: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default InfoCard;
