import { ACTIVE_STATUSES } from 'common-src/models/PreAuthorization';

import { PreAuthsTabs } from './constants';

export const getTabInfo = (auths = []) => {
  const activeAuths = [];
  const inactiveAuths = [];

  const sortedAuths = [...auths].sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  sortedAuths.forEach((auth) => {
    if (ACTIVE_STATUSES.includes(auth.status)) {
      activeAuths.push(auth);
      return;
    }

    inactiveAuths.push(auth);
  });

  const tabInfo = {
    ACTIVE: {
      text: PreAuthsTabs.ACTIVE.text,
      count: activeAuths.length,
    },
    NOT_ACTIVE: {
      text: PreAuthsTabs.NOT_ACTIVE.text,
      count: inactiveAuths.length,
    },
    ALL: {
      text: PreAuthsTabs.ALL.text,
      count: sortedAuths.length,
    },
  };

  return {
    tabInfo,
    activeAuths,
    inactiveAuths,
    allAuths: sortedAuths,
  };
};
