import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Patient from 'common-src/models/Patient';

import styles from './SectionHeader.module.scss';

const SectionHeader = ({ patient, isReadOnly }) => (
  <header className={[styles.header, 'gap-10', 'primary-border-b', 'font-s-22'].join(' ')}>
    <span className="font-w-700">{isReadOnly ? 'Documentation' : 'Edit Documentation'}</span>
    <span>{!_.isEmpty(patient) ? `${patient.getName()} (${patient.id})` : ''}</span>
  </header>
);

SectionHeader.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  isReadOnly: PropTypes.bool,
};

export default SectionHeader;
