import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientCgmAuthRequestsRequest = (patientId, params) =>
  restRequest({
    uri: 'cgmAuthRequest',
    query: { patient_id: patientId },
    method: HttpMethods.Get,
    ...params,
  });

export const createCgmAuthRequest = (body, params) =>
  restRequest({
    uri: 'cgmAuthRequest',
    method: HttpMethods.Post,
    body,
    ...params,
  });
