import _ from 'lodash';

export const getResult = (data) => {
  if (!data || !Array.isArray(data) || _.isEmpty(data)) return 'No results found';

  const filteredData = [];

  if (data.length > 20) {
    filteredData.push(...data.slice(0, 10));
    filteredData.push('Too Many Results, please add more detail');
  } else {
    filteredData.push(...data);
  }

  return filteredData;
};

export const applyCodes = (data) => {
  const mainCodesResult = getResult(data?.MainCodes);
  const secondaryCodesResult = getResult(data?.SecondaryCodes);

  const res = ['Recommended based on search'];
  if (Array.isArray(mainCodesResult)) {
    res.push(...mainCodesResult);
  } else {
    res.push(mainCodesResult);
  }

  res.push('Other matching results');
  if (Array.isArray(secondaryCodesResult)) {
    res.push(...secondaryCodesResult);
  } else {
    res.push(secondaryCodesResult);
  }

  return res;
};
