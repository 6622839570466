// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commonStyles-module__date___vno5J {
  height: 40px !important;
}
.commonStyles-module__date___vno5J .commonStyles-module__inputDateContainer___C_fIM {
  padding: 8px 16px;
}
.commonStyles-module__date___vno5J .commonStyles-module__inputDateContainer___C_fIM .commonStyles-module__dateInput___s1fyW {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.commonStyles-module__inputText___aox5W {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10px 14px !important;
  line-height: 16px !important;
}

.commonStyles-module__inputNumber___HNsnH input {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 9px 14px !important;
  line-height: 16px !important;
}
.commonStyles-module__inputNumber___HNsnH input:disabled {
  opacity: 0.8;
}

.commonStyles-module__checkbox___o_o21 input:checked ~ span {
  border: 1px solid get-color(greenNew, dark) !important;
}
.commonStyles-module__checkbox___o_o21 input:checked ~ span small {
  border-right: 2.5px solid #375844 !important;
  border-bottom: 2.5px solid #375844 !important;
  height: 9px !important;
  width: 3.5px !important;
  right: 4px !important;
  bottom: 2px !important;
}
.commonStyles-module__checkbox___o_o21 span {
  border: 1px solid get-color(gray) !important;
  background-color: transparent !important;
  border-radius: 2px !important;
  min-height: 16px !important;
  min-width: 16px !important;
}
.commonStyles-module__checkbox___o_o21 div {
  font-weight: 400 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/DocumentationIndex/components/commonStyles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;EACE,iBAAA;AACJ;AACI;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AACN;;AAIA;EACE,0BAAA;EACA,2BAAA;EACA,6BAAA;EACA,4BAAA;AADF;;AAKE;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,4BAAA;AAFJ;AAII;EACE,YAAA;AAFN;;AAQE;EACE,sDAAA;AALJ;AAOI;EACE,4CAAA;EACA,6CAAA;EACA,sBAAA;EACA,uBAAA;EACA,qBAAA;EACA,sBAAA;AALN;AASE;EACE,4CAAA;EACA,wCAAA;EACA,6BAAA;EACA,2BAAA;EACA,0BAAA;AAPJ;AAUE;EACE,2BAAA;AARJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `commonStyles-module__date___vno5J`,
	"inputDateContainer": `commonStyles-module__inputDateContainer___C_fIM`,
	"dateInput": `commonStyles-module__dateInput___s1fyW`,
	"inputText": `commonStyles-module__inputText___aox5W`,
	"inputNumber": `commonStyles-module__inputNumber___HNsnH`,
	"checkbox": `commonStyles-module__checkbox___o_o21`
};
module.exports = ___CSS_LOADER_EXPORT___;
