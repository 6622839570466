import { CommonIcons } from 'common-src/assets/Icons';
import { FulfillmentStatus } from 'common-src/models/PatientFulfillmentOrder/constants';

export const getFulfillmentStatusConfig = (statusId) => {
  switch (statusId) {
    case FulfillmentStatus.Open:
      return { text: 'Opened', iconSrc: CommonIcons.orderInProgressIcon };
    case FulfillmentStatus.Shipped:
      return { text: 'Shipped', iconSrc: CommonIcons.orderInProgressIcon };
    case FulfillmentStatus.Complete:
      return { text: 'Completed', iconSrc: CommonIcons.orderCompleted };
    case FulfillmentStatus.Cancelled:
      return { text: 'Cancelled', iconSrc: CommonIcons.orderInProgressIcon };
    case FulfillmentStatus.Failed:
      return { text: 'Failed', iconSrc: CommonIcons.orderInProgressIcon };
    case FulfillmentStatus.Requested:
      return { text: 'Requested', iconSrc: CommonIcons.orderInProgressIcon };
    default:
      return { text: 'Unknown', iconSrc: CommonIcons.orderInProgressIcon };
  }
};
