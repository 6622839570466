import { attr, fk, Model } from 'redux-orm';

class PatientCareProvider extends Model {
  static modelName = 'PatientCareProvider';

  static fields = {
    id: attr(),
    name: attr(),
    phone: attr(),
    fax: attr(),
    typeId: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'careProviderChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientCareProvider',
    }),
  };
}

export default PatientCareProvider;
