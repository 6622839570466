import { attr, fk, Model } from 'redux-orm';

class PatientEmergencyContact extends Model {
  static modelName = 'PatientEmergencyContact';

  static fields = {
    id: attr(),
    phone: attr(),
    name: attr(),
    relationship: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'patientEmergencyContactChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientEmergencyContact',
    }),
  };
}

export default PatientEmergencyContact;
