import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Autocomplete, Input, Select, TextArea } from 'common-src/components/base';
import { getCurrentRoleId } from 'common-src/features/auth';
import { apiRequest } from 'common-src/features/rest';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { roles } from 'common-src/models/Client';
import PatientMedication, {
  addMedication,
  editMedication,
  Frequencies,
  isManuallyCreated,
  makeMedicationInactive,
  MedicationStatus,
  Routes,
  Sources,
  validatePatientMedication,
} from 'common-src/models/PatientMedication';
import { getChangedValues } from 'common-src/utils/objectUtils';

import { InfoItem } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import {
  catchErrors,
  extractPatientMedication,
  getIsButtonEnabled,
  getRequestBody,
  getStatusesOptions,
} from './helpers';
import styles from './MedicationPopup.module.scss';

const UPDATABLE_FIELDS = ['route', 'frequency', 'source'];

const MedicationPopup = ({ patientId, medication, onClose, isDuplicate = false }) => {
  const dispatch = useDispatch();

  const roleId = useCustomSelector(getCurrentRoleId);

  const [data, setData] = useState(initialState);
  const [shouldReset, setShouldReset] = useState(false);
  const [title, setTitle] = useState(medication ? 'Edit medication' : 'Add medication');

  const isEdit = useMemo(() => !!medication?.id && !isDuplicate, [medication?.id, isDuplicate]);

  const isManuallyCreatedMedication = !medication || isManuallyCreated(medication?.source);

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!roleId) return;
    setData(extractPatientMedication(medication));
  }, [roleId]);

  const getCalculatedInitialState = () => {
    const initialStatus = [roles.MXSpecialist.id, roles.Enrollment.id].includes(roleId)
      ? MedicationStatus.NeedsReview
      : MedicationStatus.Active;

    return {
      ...initialState,
      status: getStatusesOptions().find((option) => option.value === initialStatus),
    };
  };

  useEffect(() => {
    if (!medication && !roleId) return;

    const calculatedInitialState = getCalculatedInitialState();

    setData(medication ? extractPatientMedication(medication) : calculatedInitialState);
  }, [medication, roleId]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const updatedFields = Object.keys(getChangedValues(data, extractPatientMedication(medication)));
    const shouldEdit =
      !isDuplicate && _.isEmpty(updatedFields.filter((field) => !UPDATABLE_FIELDS.includes(field)));

    const requests = [];
    if (shouldEdit) {
      requests.push(editMedication(getRequestBody(data, medication?.id, patientId, medication)));
    } else {
      requests.push(addMedication(getRequestBody(data, null, patientId, medication)));
      if (medication && !isDuplicate) {
        requests.push(makeMedicationInactive(medication.id));
      }
    }

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Medication ${shouldEdit ? 'edited' : 'added'}!`);

          if (!shouldClose) {
            setData(getCalculatedInitialState());
            setAutoFocus(true);
            setShouldReset(true);
            setTitle('Add medication');
            setTimeout(() => setShouldReset(false), 0);
            return;
          }

          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientMedication(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onClear = () => {
    setData(getCalculatedInitialState());
    setShouldReset(true);
    setTimeout(() => setShouldReset(false), 0);
  };

  const renderAutoComplete = () => (
    <div className={styles.searchContainer}>
      <Autocomplete
        id="search-medications"
        classNames={[styles.autocomplete, 'm-b-16']}
        label="Search"
        tooltipText="Search by brand and generic drug name from the NDC database."
        disabled={isEdit}
        autoFocus={autoFocus}
        onBlur={() => setAutoFocus(false)}
        shouldReset={shouldReset}
        initialOptionsCount={50}
        renderOption={(option) => (
          <span className={[styles.option, 'font-w-500', 'font-s-14'].join(' ')}>
            {option.fullName}
          </span>
        )}
        onOptionSelect={(option) => onChangeHandler('med', option)}
        getRequest={(searchText, count) =>
          apiRequest({
            endpoint: 'medications',
            queryParams: { input: searchText, all: true, limit: count },
          })
        }
      />
    </div>
  );

  const renderSource = () => {
    if (!isManuallyCreatedMedication) {
      return (
        <InfoItem
          id="source"
          classNames={['gap-14']}
          title="Source"
          content={data?.source?.label || '-'}
          textId="source"
        />
      );
    }

    return (
      <Select
        id="source"
        label="Source"
        options={Sources.filter((source) => source.isSelectable)}
        onChange={(op) => onChangeHandler('source', op)}
        value={data.source}
        placeholder="Select source"
      />
    );
  };

  const renderInstructions = () => {
    if (!isManuallyCreatedMedication) {
      return (
        <>
          <InfoItem
            id="prescriber"
            title="Prescriber"
            content={data?.prescriber || '-'}
            textId="prescriber"
          />
          <InfoItem
            id="instructions"
            classNames={[styles.width75, 'gap-14']}
            title="Instructions"
            content={data?.instructions?.replace(/\s+/g, ' ') || '-'}
            textId="instructions"
            tooltipText="These instructions are imported from the pharmacy or PBM if included. They cannot be edited."
            multiline
          />
        </>
      );
    }

    return (
      <>
        <Input
          id="prescriber"
          placeholder="blank if manually added"
          label="Prescriber"
          value={null}
          disabled
        />
        <Input
          id="instructions"
          classNames={[styles.width75, styles.gap6]}
          placeholder="Instructions"
          label="Instructions"
          value={data.instructions}
          onTextChange={(value) => onChangeHandler('instructions', value)}
          tooltipText="These instructions are imported from the pharmacy or PBM if included. They cannot be edited."
        />
      </>
    );
  };

  return (
    <BasePopup
      id="medication"
      open
      onClose={onClose}
      title={title}
      customStyle={{ minWidth: '50vw' }}
    >
      {!medication ? renderAutoComplete() : null}
      <div className={styles.grid}>
        <InfoItem
          id="med"
          classNames={[styles.width75, 'gap-14']}
          title="Medication Name*"
          content={data?.med?.fullName || ''}
          textId="medication-name"
          multiline
        />
        <Select
          id="status"
          label="Status"
          options={getStatusesOptions()}
          onChange={(op) => onChangeHandler('status', op)}
          value={data.status}
          placeholder="Select status"
        />
        <Select
          id="route"
          label="Route"
          options={Routes}
          onChange={(op) => onChangeHandler('route', op)}
          value={data.route}
          placeholder="Select route"
        />
        <Select
          id="frequency"
          label="Frequency"
          options={Frequencies}
          onChange={(op) => onChangeHandler('frequency', op)}
          value={data.frequency}
          placeholder="Select frequency"
          paperHeight={300}
        />
        {renderSource()}
        {renderInstructions()}
        <TextArea
          id="notes"
          classNames={[styles.width100]}
          placeholder="Free text notes by the clinician"
          value={data.notes}
          onTextChange={(value) => onChangeHandler('notes', value)}
          fixedRows={4}
          label="Notes"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, medication, isDuplicate),
        submitButtonText: 'Save and close',
        onClear: !isEdit ? onClear : null,
        renderSaveButton: !isEdit,
      })}
    </BasePopup>
  );
};

MedicationPopup.propTypes = {
  patientId: PropTypes.number,
  medication: PropTypes.exact(PatientMedication.schema),
  onClose: PropTypes.func,
  isDuplicate: PropTypes.bool,
};

export default MedicationPopup;
