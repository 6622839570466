import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'common-src/components/base';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';

import styles from './ActionsRow.module.scss';

const ActionsRow = ({ options }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({});

  return (
    <div className="flex-row gap-12">
      {_.compact(options).map((op) => {
        const onClickHandler = () => {
          const action = op.onClick;

          if (typeof action === 'function') {
            action();
            return;
          }

          const successBlock = action?.payload?.successBlock;
          const errorBlock = action?.payload?.errorBlock;

          if (action?.type?.startsWith('rest')) {
            action.payload.successBlock = () => {
              setLoading((prev) => ({ ...prev, [op.text]: false }));
              successBlock?.();
            };
            action.payload.errorBlock = (err) => {
              setLoading((prev) => ({ ...prev, [op.text]: false }));
              errorBlock?.(err);
            };

            setLoading((prev) => ({ ...prev, [op.text]: true }));
            dispatch(action);
            return;
          }

          dispatch(action);
        };

        return (
          <Button
            key={op.id}
            id={op.id}
            classNames={[styles.button]}
            text={op.text}
            disabled={op.disabled}
            onClick={() => {
              if (op.showWarning) {
                dispatch(
                  openModal(ModalType.WARNING, {
                    title: 'Remove Entry?',
                    message: 'Are you sure you want to remove this record?',
                    onSubmit: () => onClickHandler(),
                  }),
                );

                return;
              }

              onClickHandler();
            }}
            textColor={op.color}
            backgroundColor={op.backgroundColor}
            borderColor="transparent"
            isLoading={loading[op.text]}
          />
        );
      })}
    </div>
  );
};

ActionsRow.propTypes = {
  options: PropTypes.array,
};

export default ActionsRow;
