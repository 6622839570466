import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { getRoleConfig } from 'common-src/features/auth';
import { getPatientCgmGlucoseReadingsRequest } from 'common-src/models/PatientCgmGlucoseReading';
import { getPatientCgmOrders, getPatientCgmOrdersRequest } from 'common-src/models/PatientCgmOrder';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { RequestHandlerScreen } from 'src/components/base';
import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import styles from './CGMOrdersFragment.module.scss';
import { OrderHistoryList, SubHeader } from './components';

const CGMOrdersFragment = ({ patientId }) => {
  const dispatch = useDispatch();
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const cgmOrders = useCustomSelector((state) => getPatientCgmOrders(state, patientId));
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.cgmOrders,
  );

  const { isRequesting } = useRequestLoading([
    getPatientCgmOrdersRequest({ patientId }),
    getPatientCgmGlucoseReadingsRequest({
      patientId: patient.id,
      limit: 1,
    }),
  ]);

  useInterval(() => {
    dispatch(getPatientCgmOrdersRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  const showCgmAuthPopup = () => dispatch(openModal(ModalType.CGM_AUTH, { patient }));

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling CGM orders..." />;
  }

  return (
    <section className={[styles.container, 'flex-column'].join(' ')}>
      <SubHeader patient={patient} actions={actions} onAddCgm={() => showCgmAuthPopup()} />
      <article className={styles.scrollableContainer}>
        <OrderHistoryList cgmOrders={cgmOrders} actions={actions} />
      </article>
    </section>
  );
};

CGMOrdersFragment.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CGMOrdersFragment;
