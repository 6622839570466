import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'common-src/components/base';

import { shouldExpandContent } from 'src/pages/DocumentationIndex/helpers';

import LinkItem from '../LinkItem';
import styles from './ModuleItem.module.scss';

const ModuleItem = ({
  module,
  setSelectedLink,
  selectedLink,
  onChangeHandler,
  disabled = false,
}) => {
  const getLabel = () => {
    if (!module.isSelected) return module.title;
    return (
      <LinkItem
        linkedKey={`module-${module.moduleType}`}
        title={module.title}
        selectedLink={selectedLink}
        disabled={disabled}
        moduleId={module.moduleType}
        setSelectedLink={setSelectedLink}
      />
    );
  };

  const renderSections = () => {
    const sections =
      module.isSelected &&
      module.sections.map((section, sectionIndex) => {
        const shouldExpand = shouldExpandContent(module.sections, section);

        if (!shouldExpand) return null;

        return (
          <LinkItem
            key={`question-${module.moduleType}-${section.header}`}
            linkedKey={`question-${module.moduleType}-${sectionIndex}`}
            title={section.header}
            small
            selectedLink={selectedLink}
            disabled={disabled}
            moduleId={module.moduleType}
            setSelectedLink={setSelectedLink}
          />
        );
      });

    if (!sections || _.isEmpty(sections.filter((i) => i))) return null;

    return (
      <div className={[styles.instructions, 'gap-10', 'flex-column'].join(' ')}>{sections}</div>
    );
  };

  return (
    <div
      className={[styles.checkboxWrapper, module.isSelected ? styles.selected : ''].join(' ')}
      key={module.moduleType}
    >
      <Checkbox
        id={`module-${module.title.split(' ').join('-').toLowerCase()}`}
        checked={module.isSelected || false}
        onChange={onChangeHandler}
        label={getLabel()}
        disabled={disabled}
      />
      {renderSections()}
    </div>
  );
};

ModuleItem.propTypes = {
  module: PropTypes.object,
  disabled: PropTypes.bool,
  selectedLink: PropTypes.object,
  setSelectedLink: PropTypes.func,
  onChangeHandler: PropTypes.func,
};

export default ModuleItem;
