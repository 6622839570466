import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Colors } from 'common-src/styles';

import styles from './CircleLoader.module.scss';

const CircleLoader = ({
  style,
  label,
  classNames = [],
  strokeWidth = 3,
  circleRadius = 18,
  color = Colors.white,
}) => {
  const [progress, setProgress] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const progressOffset = 2 * Math.PI * (circleRadius - strokeWidth / 2) * progress;
    setOffset(progressOffset);
  }, [progress, circleRadius, strokeWidth]);

  const renderLoader = () => (
    <svg
      className={[styles.container, ...classNames].join(' ')}
      style={style}
      width={circleRadius * 2}
      height={circleRadius * 2}
    >
      <circle
        className={styles.background}
        cx={circleRadius}
        cy={circleRadius}
        r={circleRadius - strokeWidth / 2}
        strokeWidth={strokeWidth}
      />
      <circle
        className={styles.foreground}
        style={{ stroke: color }}
        cx={circleRadius}
        cy={circleRadius}
        r={circleRadius - strokeWidth / 2}
        strokeWidth={strokeWidth}
        strokeDasharray={`${2 * Math.PI * (circleRadius - strokeWidth / 2)}`}
        strokeDashoffset={offset}
      />
    </svg>
  );

  if (label) {
    return (
      <div className={[styles.wrapper, 'flex-row', 'gap-10', 'p-b-12', 'p-t-12'].join(' ')}>
        <span className={[styles.label, 'font-w-600'].join(' ')}>{label}</span>
        {renderLoader()}
      </div>
    );
  }

  return renderLoader();
};

CircleLoader.propTypes = {
  strokeWidth: PropTypes.number,
  circleRadius: PropTypes.number,
  color: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  label: PropTypes.string,
};

export default CircleLoader;
