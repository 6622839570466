import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { CircleLoader, Select } from 'common-src/components/base';
import {
  createCgmAuthRequest,
  getLatestCgmAuthRequest,
  getPatientCgmAuthRequestsRequest,
} from 'common-src/models/CgmAuthRequest';
import Patient from 'common-src/models/Patient';
import { getLatestCgmGlucoseReading } from 'common-src/models/PatientCgmGlucoseReading';
import { ColorsNew } from 'common-src/styles';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './CGMAuthPopup.module.scss';
import { CGMManufacturers } from './constants';
import { findManufacturerOption } from './helpers';

const CGMAuthPopup = ({ open, onClose, patient }) => {
  const dispatch = useDispatch();
  const lastAuthRequest = useCustomSelector((state) => getLatestCgmAuthRequest(state, patient.id));
  const latestCgmReading = useCustomSelector((state) =>
    getLatestCgmGlucoseReading(state, patient.id),
  );

  const [manufacturer, setManufacturer] = useState(
    findManufacturerOption(patient?.cgmType) || null,
  );
  const [isInitialManufacturerSet, setIsInitialManufacturerSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { renderButtons } = usePopup();

  useEffect(() => {
    if (!patient?.id) return;

    dispatch(getPatientCgmAuthRequestsRequest(patient.id));
  }, [patient?.id]);

  useEffect(() => {
    if (!lastAuthRequest || isInitialManufacturerSet) return;

    setManufacturer(findManufacturerOption(lastAuthRequest.deviceType) || null);
    setIsInitialManufacturerSet(true);
  }, [lastAuthRequest]);

  const sendCgmAuthRequest = () => {
    if (!manufacturer) {
      setError('Please select manufacturer');
      return;
    }

    setError(null);
    setIsLoading(true);
    dispatch(
      createCgmAuthRequest(
        { patientId: patient.id, deviceType: manufacturer.value },
        {
          successBlock: () => setIsLoading(false),
          errorBlock: () => setIsLoading(false),
        },
      ),
    );
  };

  const renderManufacturerRow = () => (
    <div className={[styles.manufacturerRow, 'p-r-30', 'primary-border-b'].join(' ')}>
      <span className={[styles.text, 'font-w-500', 'font-s-16'].join(' ')}>Manufacturer</span>
      <Select
        id="manufacturer-select"
        classNames={[styles.select]}
        placeholder="Manufacturer"
        options={Object.values(CGMManufacturers)}
        value={manufacturer}
        onChange={setManufacturer}
        errorText={error}
        isClearable
      />
    </div>
  );

  const renderAuthUrlContent = () => (
    <div className={[styles.authContainer, 'flex-column', 'p-r-30', 'gap-6'].join(' ')}>
      <span className={[styles.title, 'font-w-500', 'font-s-10', 'font-s-14'].join(' ')}>
        Authentication URL
      </span>
      <div className={[styles.textContainer, 'border-r-6'].join(' ')}>
        {lastAuthRequest?.authUrl && (
          <span
            id="auth-url-value"
            className={[styles.content, 'font-w-500', 'font-s-14', 'font-s-16'].join(' ')}
          >
            {lastAuthRequest?.authUrl}
          </span>
        )}
      </div>
    </div>
  );

  const renderStatusInfo = () => (
    <div id="status-info" className={[styles.statusInfo, 'm-b-40'].join(' ')}>
      <div className={[styles.item, 'flex-column'].join(' ')}>
        <span className={[styles.title, 'font-w-500', 'font-s-10'].join(' ')}>
          Authentication Status
        </span>
        <span id="auth-status" className={[styles.content, 'font-w-500'].join(' ')}>
          {lastAuthRequest?.status || 'Not authenticated'}
        </span>
      </div>
      <div className={[styles.item, 'flex-column'].join(' ')}>
        <span className={[styles.title, 'font-w-500', 'font-s-10'].join(' ')}>
          Data Last Received
        </span>
        <span id="last-received-data" className={[styles.content, 'font-w-500'].join(' ')}>
          {latestCgmReading?.recordedAt
            ? moment(latestCgmReading?.recordedAt).format('MM/DD/YYYY')
            : '-'}
        </span>
      </div>
    </div>
  );

  return (
    <BasePopup id="connect-cgm" open={open} onClose={onClose} title="Connect CGM">
      <div className={['flex-column', 'p-t-20', 'gap-18', 'm-l-30'].join(' ')}>
        {renderManufacturerRow()}
        {renderAuthUrlContent()}
        <div
          id="auth-url-button"
          className={[
            styles.authButton,
            'gap-8',
            'vertically-centered',
            'm-b-40',
            'font-s-16',
          ].join(' ')}
          onClick={sendCgmAuthRequest}
          role="presentation"
        >
          <img className="icon-btn" src={CommonIcons.plusIcon} alt="plus-icon" />
          <span>Generate Authentication URL</span>
          {isLoading && (
            <CircleLoader
              style={{ margin: 0 }}
              color={ColorsNew.darkGreen}
              strokeWidth={2}
              circleRadius={9}
            />
          )}
        </div>
        {renderStatusInfo()}
        {renderButtons({
          containerClassName: styles.buttonsContainer,
          buttonClassName: styles.button,
          onClose,
          onSubmit: () => onClose(),
          submitButtonText: 'Save and Close',
          isSubmitEnabled: !!lastAuthRequest?.authUrl,
        })}
      </div>
    </BasePopup>
  );
};

CGMAuthPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patient: PropTypes.exact(Patient.schema),
};

export default CGMAuthPopup;
