import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';
import {
  createPatientEmergencyContactRequest,
  deletePatientEmergencyContactRequest,
  updatePatientEmergencyContactRequest,
} from 'common-src/models/PatientEmergencyContact/actions';
import PatientEmergencyContact from 'common-src/models/PatientEmergencyContact/PatientEmergencyContact';

export const getEmergencyContactRequests = (oldContacts, newContacts) => {
  if (!Array.isArray(oldContacts) || !Array.isArray(newContacts)) return [];

  const requests = [];

  newContacts.forEach((contact) => {
    const contactExists = oldContacts.find(({ id }) => id === contact.id);

    if (!contactExists) {
      // Add new emergency contact
      requests.push(createPatientEmergencyContactRequest(contact));
      return;
    }

    if (!_.isEqual(contact, contactExists)) {
      // Edit emergency contact
      const payload = {
        phone: contact.phone,
        name: contact.name,
        relationship: contact.relationship,
      };

      requests.push(updatePatientEmergencyContactRequest(contact.id, payload));
    }
  });

  // Delete emergency contact
  oldContacts.forEach(({ id }) => {
    const insuranceExists = newContacts.find((i) => i.id === id);

    if (!insuranceExists) {
      requests.push(
        deletePatientEmergencyContactRequest(id, {
          successBlock: () => {
            deleteOrmItem(PatientEmergencyContact.modelName, id);
          },
        }),
      );
    }
  });

  return requests;
};
