import 'common-src/styles/config.scss';
import 'common-src/utils/selectorUtils';

import * as Sentry from '@sentry/react';
import { Chart, registerables } from 'chart.js';
import React from 'react';
import { createRoot } from 'react-dom/client';

import Config from 'common-src/config';

import { initializeStore } from 'src/store';

import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';

if (Config.SENTRY_DSN) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    environment: Config.NODE_ENV,
    beforeSend(event) {
      // intercept Google SSO errors and do not send them
      // Error 1: signal is aborted without reason / fetch is aborted
      // Error 2: Cannot read properties of null (reading 'postMessage')
      // Error 3: Cannot read properties of null (reading 'contentWindow')
      // Error 4: Cannot read properties of null (reading 'location')
      if (event.exception && event.exception.values) {
        const errorMessage = event.exception.values[0].value;

        if (
          errorMessage.includes('aborted') ||
          errorMessage.includes('postMessage') ||
          errorMessage.includes('contentWindow') ||
          errorMessage.includes('location')
        ) {
          return null;
        }
      }

      return event;
    },
  });
}

Chart.register(...registerables);

initializeStore();
createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
