import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, Tooltip } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import { getDeviceType, getPatientMostRecentDevice } from 'common-src/models/PatientDevice';
import PatientFulfillmentOrder, {
  getLineColor,
  getStripsPercentage,
} from 'common-src/models/PatientFulfillmentOrder';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { ProgressHeaderItem } from 'src/components/elements';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './DevicesHeader.module.scss';

const DevicesHeader = ({
  onGlucoseStripEdit,
  actions,
  patient = {},
  onAddOrder = () => {},
  glucoseStripCount = 0,
  onAddDevice = () => {},
  patientFulfillmentOrders = [],
}) => {
  const dispatch = useDispatch();

  const [refillData, setRefillData] = useState({});

  const mostRecentDevice = useCustomSelector((state) =>
    getPatientMostRecentDevice(state, patient.id),
  );

  const stripsPercentage = useMemo(
    () => getStripsPercentage(patientFulfillmentOrders, glucoseStripCount),
    [patientFulfillmentOrders, glucoseStripCount],
  );

  const showPatientDevicesPopup = () =>
    dispatch(openModal(ModalType.PATIENT_DEVICES, { patientId: patient.id }));

  useEffect(() => {
    apiRequest({
      endpoint: `members/${patient.id}/nextRefill`,
    })
      .then((res) => res.json())
      .then((data) => setRefillData(data));
  }, [glucoseStripCount]);

  const getStatusInfo = () => {
    switch (refillData.status) {
      case 'Active':
        return `Est. Refresh ${moment(refillData.refillDate).format('MM/DD/YYYY')}`;
      case 'Suspended':
        return (
          <div className={['flex-row', 'gap-4'].join(' ')}>
            <span>Suspended</span>
            <Tooltip title={refillData.reason}>
              <img src={CommonIcons.tooltipIcon} alt="tooltip-icon" />
            </Tooltip>
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div id="devices-header" className={[styles.content, 'gap-24'].join(' ')}>
      <ProgressHeaderItem
        id="glucose-strips"
        header="Test Strips"
        mainText={`${glucoseStripCount} remaining`}
        supportingText={getStatusInfo()}
        progressPercent={stripsPercentage}
        progressFillColor={getLineColor(stripsPercentage)}
        onClick={onGlucoseStripEdit}
        showProgressBar
        disabled={!actions?.setStripCount}
      />
      <ProgressHeaderItem
        id="bgm"
        header="Blood Glucose Meter"
        mainText={`${
          !mostRecentDevice?.lastReadingAt
            ? 'No data'
            : `Last Used ${moment(mostRecentDevice.lastReadingAt)
                .tz(patient.timeZone)
                .format('MM/DD/YYYY')}`
        }`}
        supportingText={`${
          !mostRecentDevice
            ? 'No device connected'
            : `${getDeviceType(mostRecentDevice.typeId)} #${mostRecentDevice.id}`
        }`}
        progressPercent={0}
        onClick={onAddDevice}
        showProgressBar={!mostRecentDevice}
        showDeviceStatus={!!mostRecentDevice}
        deviceStatusSrc={
          mostRecentDevice?.deletedAt ? CommonIcons.inactiveDevice : CommonIcons.activeDevice
        }
        disabled={!actions?.addBGMDevice}
        onContentClick={() => showPatientDevicesPopup()}
      />
      <Button
        id="new-bgm-order"
        classNames={[styles.button]}
        iconSrc={CommonIcons.shippingIcon}
        text="New Order"
        disabled={!actions?.placeOrder}
        onClick={onAddOrder}
        textColor={ColorsNew.darkGreen}
        backgroundColor={Colors.white}
        borderColor={ColorsNew.darkGreen}
      />
    </div>
  );
};

DevicesHeader.propTypes = {
  glucoseStripCount: PropTypes.number,
  onGlucoseStripEdit: PropTypes.func,
  patient: PropTypes.exact(Patient.schema),
  patientFulfillmentOrders: PropTypes.arrayOf(PropTypes.exact(PatientFulfillmentOrder.schema)),
  onAddDevice: PropTypes.func,
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onAddOrder: PropTypes.func,
};

export default DevicesHeader;
