import PropTypes from 'prop-types';
import React from 'react';

import Client from 'common-src/models/Client';
import { getDateWithTimeZone } from 'common-src/utils/dateUtils';

import Icons from 'src/assets/Icons';
import { HighlightedText } from 'src/components/elements';

import styles from './DocumentationItem.module.scss';

const DocumentationItem = ({
  id,
  date,
  client,
  classNames,
  iconSrc,
  reasons,
  type,
  textToHighlight,
  onClick = () => {},
  isSelected = false,
  showChevron = true,
}) => {
  const containerClasses = [styles.container, 'vertically-centered', 'primary-border-b'];
  if (isSelected) {
    containerClasses.push(styles.selected);
  }
  if (classNames) {
    containerClasses.push(...classNames);
  }

  return (
    <div id={id} className={containerClasses.join(' ')} onClick={onClick} role="presentation">
      {iconSrc && <img className={styles.icon} src={iconSrc} alt="icon" />}
      <div className={[styles.content, 'flex-column', 'gap-4'].join(' ')}>
        <HighlightedText
          className={[styles.name, 'font-w-500', 'font-s-12'].join(' ')}
          text={client?.getName(true, true)}
          textToHighlight={textToHighlight}
        />
        <HighlightedText
          className={[styles.type, 'font-w-600', 'font-s-16'].join(' ')}
          text={type}
          textToHighlight={textToHighlight}
        />
        {reasons && (
          <HighlightedText
            className={[styles.reason, 'font-w-500', 'font-s-14'].join(' ')}
            text={`Reason for visit: ${reasons.join(', ')}`}
            textToHighlight={textToHighlight}
          />
        )}
        <span className={[styles.date, 'font-w-500', 'm-t-3', 'font-s-12'].join(' ')}>
          {getDateWithTimeZone(date, 'MM/DD/YYYY [at] h:mm a z')}
        </span>
      </div>
      {showChevron && <img src={Icons.chevron} alt="chevron-icon" />}
    </div>
  );
};

DocumentationItem.propTypes = {
  id: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string,
  reasons: PropTypes.arrayOf(PropTypes.string),
  client: PropTypes.exact(Client.schema),
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  showChevron: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  iconSrc: PropTypes.string,
  textToHighlight: PropTypes.string,
};

export default DocumentationItem;
