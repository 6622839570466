import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CustomCheckbox, Input, Select } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import Patient from 'common-src/models/Patient';
import { getPatientPhoneNumbers } from 'common-src/models/PatientPhoneNumber';
import { getFormattedPhoneNumber } from 'common-src/models/PatientPhoneNumber/helpers';
import { cleanedText, PHONE_MASK } from 'common-src/presenters';

import { AddButton, DialButton, RemoveButton } from 'src/components/buttons';
import { Indicator } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import { phoneTypeOptions } from './constants';
import styles from './ContactInformation.module.scss';
import {
  catchErrors,
  extractPhoneNumber,
  getErrorMessage,
  getInitialPhoneData,
  getRequests,
  resetPhoneNumberErrors,
} from './helpers';

const ContactInformation = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const phones = useCustomSelector((state) => getPatientPhoneNumbers(state, patient.id));

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    const { requests, deleteRequests } = getRequests(formData, phones, patient);

    const onSuccess = () => {
      setIsEditMode(false);
      setIsLoading(false);
      showAlert(AlertType.Success, '', 'You have successfully updated contact information.');
    };

    const onError = (err) => {
      setIsLoading(false);
      showAlert(AlertType.Error, '', err);
    };

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          if (_.isEmpty(deleteRequests)) {
            onSuccess();
            return;
          }

          dispatch(
            restRequestMultiple({
              restRequests: deleteRequests,
              successBlock: () => onSuccess(),
              errorBlock: (err) => onError(err),
            }),
          );
        },
        errorBlock: (err) => onError(err),
      }),
    );
  };

  const {
    isEditMode,
    setIsEditMode,
    errors,
    setIsLoading,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'contacts',
  );

  useEffect(() => {
    const data = {
      patient: { email: patient.email },
      phones: !_.isEmpty(phones) ? phones.map((phone) => extractPhoneNumber(phone)) : [],
    };

    setInitialData(data);
    setFormData(data);
  }, [phones, patient.email]);

  if (_.isEmpty(formData)) return null;

  const handleInputChange = (model, field, newValue) => {
    handleErrors({
      [`${model}.${field}`]: getErrorMessage(model, field, newValue, formData.phones),
    });
    setFormData((prev) => ({
      ...prev,
      [model]: _.set(prev[model], field, newValue === null ? null : newValue),
    }));
  };

  const renderRemoveButton = (phone) => {
    if (phone.isPrimary) return null;

    return (
      <RemoveButton
        id="remove-phone"
        onClick={() => {
          handleErrors(resetPhoneNumberErrors(formData.phones, phone));
          setFormData((prev) => ({
            ...prev,
            phones: _.without(prev.phones, phone),
          }));
        }}
      />
    );
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-new-phone"
        classNames={[styles.button]}
        label="Add Phone Number"
        onClick={() =>
          setFormData((prev) => ({
            ...prev,
            phones: [...prev.phones, getInitialPhoneData(patient.id)],
          }))
        }
      />
    );
  };

  const renderDefaultButton = (phone) => {
    if (!phone.isPrimary) return null;

    return <Indicator text="Default" />;
  };

  const renderCheckbox = (phone, index) => (
    <>
      <CustomCheckbox
        id={`checkbox-${index}`}
        classNames={[styles.checkbox]}
        checked={phone.isPrimary}
        header="Set as default"
        onChange={() => {
          setFormData((prev) => ({
            ...prev,
            phones: prev.phones.map((phone, i) => ({
              ...phone,
              isPrimary: i === index,
            })),
          }));
        }}
      />
      {renderRemoveButton(phone)}
    </>
  );

  const renderForm = () => (
    <>
      {formData.phones.map((phone, index) => (
        <div key={`phone-number-${index}`} className={styles.grid}>
          <Input
            id={`phone-${index}`}
            label="Phone Number"
            onTextChange={(value) => {
              if (_.isEqual(cleanedText(value), phone.phoneNumber)) return;
              handleInputChange('phones', `${index}.phoneNumber`, cleanedText(value));
            }}
            value={isEditMode ? phone.phoneNumber : getFormattedPhoneNumber(phone.phoneNumber)}
            placeholder="Enter phone number"
            mask={PHONE_MASK}
            readOnly={!isEditMode}
            required
            size="small"
            errorText={errors[`phones.${index}.phoneNumber`]}
            readOnlyLeftContent={
              <DialButton phoneNumber={phone.phoneNumber} patientId={patient?.id} />
            }
          />
          <Select
            id={`type-${index}`}
            label="Phone Type"
            placeholder="Enter phone type"
            options={phoneTypeOptions}
            onChange={(selection) => handleInputChange('phones', `${index}.type`, selection?.value)}
            value={phoneTypeOptions.find((op) => op.value === phone.type)}
            readOnly={!isEditMode}
            isClearable
            position="absolute"
            size="small"
            errorText={errors[`phones.${index}.type`]}
          />
          {isEditMode ? renderCheckbox(phone, index) : renderDefaultButton(phone)}
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
      <div className={styles.grid}>
        <Input
          id="email"
          classNames={[styles.width50]}
          label="Email"
          onTextChange={(value) => handleInputChange('patient', 'email', value)}
          value={formData.patient?.email || undefined}
          placeholder="Enter email"
          readOnly={!isEditMode}
          errorText={errors['patient.email']}
          size="small"
        />
      </div>
    </>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

ContactInformation.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default ContactInformation;
