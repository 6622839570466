import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// eslint-disable-next-line import/no-mutable-exports
export let store;

export class SagaError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SagaError';
  }
}

export function initializeStore() {
  const enhancers = [];

  const sagaMonitor = {
    rootSagaStarted: () => {},
    effectTriggered: () => {},
    effectResolved: () => {},
    effectRejected: (eid, err) => console.error('Rejected Saga Effect', err),
    effectCancelled: () => {},
    actionDispatched: () => {},
  };

  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor,
    onError: (err) =>
      Sentry.captureException(new SagaError(`(errType: ${err.name}): ${err.message}`)),
  });

  const middlewares = [sagaMiddleware];

  enhancers.push(applyMiddleware(...middlewares));

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__');
    if (typeof devToolsExtension === 'function') {
      composeEnhancers = devToolsExtension({ maxAge: 500 });
    }
  }

  const enhancer = composeEnhancers(...enhancers);

  store = createStore(rootReducer, {}, enhancer);

  sagaMiddleware.run(rootSaga);
}
