import PropTypes from 'prop-types';
import React from 'react';

import { List } from 'common-src/components/base';
import PatientInteraction from 'common-src/models/PatientInteraction';
import { getSortedDocuments } from 'common-src/models/PatientInteraction/helpers';

import { DraftRow } from 'src/components/rows';

const DraftList = ({ callback, drafts, withSeparator = false }) => {
  const renderItem = ({ date, id, index, parentInteractionId, patient, moduleTypes }) => (
    <DraftRow
      draftId={id}
      date={date}
      callback={callback}
      isAddendum={!!parentInteractionId}
      parentInteractionId={parentInteractionId}
      patientId={patient?.id}
      moduleTypeIds={moduleTypes}
      showSeparator={withSeparator && index < drafts.length - 1}
    />
  );

  const getItems = () =>
    getSortedDocuments(drafts, true, true).map((draft, index) => ({
      date: draft.savedAt,
      parentInteractionId: draft.parentInteractionId,
      moduleTypes: draft.moduleTypes,
      patient: draft.patient,
      id: draft.id,
      index,
    }));

  return <List items={getItems()} renderItem={renderItem} emptyText="There are no drafts" />;
};

DraftList.propTypes = {
  callback: PropTypes.func,
  withSeparator: PropTypes.bool,
  drafts: PropTypes.arrayOf(PropTypes.exact(PatientInteraction.schema)),
};

export default DraftList;
