import _ from 'lodash';

import { createReducer } from 'common-src/utils/reducerUtils';

import { CLOSE_MODAL, OPEN_MODAL, RESET_MODAL_STATE } from './actionTypes';

const initialState = {
  patientId: null,
  activeModals: [],
};

const entityHandlers = {
  [OPEN_MODAL]: (state, payload) => ({
    ...state,
    patientId: payload?.props?.patientId || payload?.props?.patient?.id,
    activeModals: _.uniqBy([...state.activeModals, payload], 'modalName'),
  }),
  [CLOSE_MODAL]: (state, payload) => ({
    ...state,
    activeModals: state.activeModals.filter((modal) => modal.modalName !== payload.modalName),
  }),
  [RESET_MODAL_STATE]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
