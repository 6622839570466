export const CGMModelOptions = Object.freeze({
  DexcomG7: {
    value: 'Dexcom G7',
    label: 'Dexcom G7',
  },
  FreestyleLibre2: {
    value: 'Freestyle Libre 2',
    label: 'Freestyle Libre 2',
  },
  FreestyleLibre3: {
    value: 'Freestyle Libre 3',
    label: 'Freestyle Libre 3',
  },
});

export const DiagnosisOptions = Object.freeze({
  'E10.9': {
    value: 'E10.9',
    label: 'E10.9',
  },
  'E11.65': {
    value: 'E11.65',
    label: 'E11.65',
  },
  'E10.65': {
    value: 'E10.65',
    label: 'E10.65',
  },
  'E11.8': {
    value: 'E11.8',
    label: 'E11.8',
  },
  'E11.9': {
    value: 'E11.9',
    label: 'E11.9',
  },
});

export const BooleanOptions = Object.freeze({
  YES: {
    value: true,
    label: 'Yes',
  },
  NO: {
    value: false,
    label: 'No',
  },
});
