import moment from 'moment-timezone';

import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

import { getInputFromPatientGlucoseReading, inputsFilterByDate } from './helpers';

export const getPatientGlucoseReadings = (state, patientId) =>
  allModelsSelector(state, 'PatientGlucoseReading', (r) => r.patientId === patientId).sort(
    (a, b) => new Date(b.recordedAt) - new Date(a.recordedAt),
  );

export const getLastMeasurement = (state, patientId) => {
  const glucoseReadings = allModelsSelector(
    state,
    'PatientGlucoseReading',
    (r) => r.patientId === Number(patientId),
  );
  const patient = singleModelSelector(state, 'Patient', patientId);
  const patientTimeZone = patient?.timeZone;
  const inputs = glucoseReadings.map((r) => getInputFromPatientGlucoseReading(r, patientTimeZone));
  const sortedInputs = [...inputs].sort(
    (a, b) =>
      new Date(b.getRecordedAt('YYYY-MM-DD HH:mm:ss')) -
      new Date(a.getRecordedAt('YYYY-MM-DD HH:mm:ss')),
  );
  const lastMeasurement = sortedInputs[0];

  if (!lastMeasurement) {
    return {
      recordedAt: '',
      value: null,
    };
  }

  const dateTime = lastMeasurement.getRecordedAt('MM/DD/YYYY hh:mm A');
  const time = lastMeasurement.getRecordedAt('hh:mm A');
  const isToday = moment(dateTime).isSame(moment(), 'day');

  return {
    recordedAt: `${isToday ? time : dateTime} ${lastMeasurement.timeZoneAbbr}`,
    value: lastMeasurement.data.v,
  };
};

export const getData = (state, patientId, days, timeOfDayCondition, endDate) => {
  const patient = singleModelSelector(state, 'Patient', patientId);
  const patientTimeZone = patient?.timeZone;

  if (days > 90) {
    return {
      data: [],
      minValue: 0,
      maxValue: 0,
      avgValue: 0,
      timeZone: patientTimeZone,
    };
  }

  let sumV = 0;
  const patientReadings = allModelsSelector(
    state,
    'PatientGlucoseReading',
    (r) => r.patientId === Number(patientId),
  )
    .map((r) => getInputFromPatientGlucoseReading(r, patientTimeZone))
    .filter((reading) => {
      const shouldFilter =
        inputsFilterByDate(reading, days, endDate) && timeOfDayCondition(reading);

      if (shouldFilter) {
        sumV += reading.data.v;
      }

      return shouldFilter;
    });

  return {
    data: patientReadings,
    avgValue: sumV && Math.round(sumV / patientReadings.length),
    timeZone: patientTimeZone,
  };
};

export const getBGMTableData = (state, patientId, days, endDate, timeOfDayCondition) => {
  const patient = singleModelSelector(state, 'Patient', patientId);
  const patientTimeZone = patient?.timeZone;

  if (days > 90) {
    return { patientReadings: [], patientTimeZone };
  }

  const patientReadings = allModelsSelector(
    state,
    'PatientGlucoseReading',
    (r) => r.patientId === patientId,
  )
    .map((r) => getInputFromPatientGlucoseReading(r, patientTimeZone))
    .filter((reading) => inputsFilterByDate(reading, days, endDate) && timeOfDayCondition(reading));

  return { patientReadings, patientTimeZone };
};
