import moment from 'moment-timezone';

export const generateBGMTableColumns = () => {
  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');

  const hours = [];

  while (startDate <= endDate) {
    hours.push(startDate.format('YYYY-MM-DD HH:mm'));
    startDate.add(1, 'hour');
  }

  return hours;
};
