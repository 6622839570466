// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Preferences-module__grid___uynS_ {
  display: grid;
  column-gap: 16px;
  margin-bottom: 26px;
  grid-template-columns: 1fr 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Profile/forms/Preferences/Preferences.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,kCAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `Preferences-module__grid___uynS_`
};
module.exports = ___CSS_LOADER_EXPORT___;
