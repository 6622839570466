import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { getCurrentRoleId, getRoleConfig } from 'common-src/features/auth';
import { roles } from 'common-src/models/Client';
import {
  PayorType,
  PlanSource,
  PlanStatus,
  PlanType,
  SubscriberRelationship,
  UPDATABLE_COVERAGE_FIELDS,
  updatePatientCoverage,
  validatePatientCoverage,
} from 'common-src/models/PatientCoverage';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { InfoItem } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './EditPlanPopup.module.scss';
import { getIsButtonEnabled } from './helpers';

const EditPlanPopup = ({ onClose, planId }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const plan = useCustomSelector((state) => singleModelSelector(state, 'PatientCoverage', planId));
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.knownPlans,
  );
  const roleId = useCustomSelector((state) => getCurrentRoleId(state));

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (_.isEmpty(plan)) return;

    setData(_.pick(plan, UPDATABLE_COVERAGE_FIELDS));
  }, [plan]);

  const handleInputChange = (field, value) => {
    const newData = { ...data, [field]: value };

    const partData =
      plan.planName === 'Part B' ? { partA: false, partB: true } : { partA: false, partB: false };

    setData(newData);
    setErrors((prev) => ({
      ...prev,
      ...validatePatientCoverage(field, value, { ...newData, payorId: plan.payorId, ...partData }),
    }));
  };

  const handleDateChange = (key, keyToCompare, isBefore, value) => {
    const newData = { ...data, [key]: value };
    const err = { ...validatePatientCoverage(key, value, newData) };

    const compareCondition = isBefore
      ? moment(value).isAfter(moment(newData?.[keyToCompare]))
      : moment(value).isBefore(moment(newData?.[keyToCompare]));

    if (!compareCondition) {
      if (newData[key]) {
        Object.assign(err, { ...validatePatientCoverage(key, value, newData) });
      }
      if (newData[keyToCompare]) {
        Object.assign(err, {
          ...validatePatientCoverage(keyToCompare, newData?.[keyToCompare], newData),
        });
      }
    }

    setErrors((prev) => ({ ...prev, ...err }));
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const getStatusBasedOnRole = () => {
    if (![roles.Admin.id, roles.RCM.id].includes(roleId)) {
      return PlanStatus.NeedsVerification.value;
    }

    return data.status;
  };

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    dispatch(
      updatePatientCoverage(
        plan.id,
        {
          ..._.mapKeys(data, (value, key) =>
            key === 'subscriberDateOfBirth' ? 'subscriberDob' : key,
          ),
          status: getStatusBasedOnRole(),
        },
        {
          successBlock: () => {
            successCallback('Plan updated!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  const onClearHandler = () => {
    setData(_.pick(plan, UPDATABLE_COVERAGE_FIELDS));
    setErrors({});
  };

  const renderPlanNameRow = () => (
    <>
      {plan.payorType === PayorType.MA.value && (
        <div className={['flex-row', 'gap-50'].join(' ')}>
          <InfoItem title="Contract ID" content={plan.contractId} textId="contract-id" />
          <InfoItem title="Plan ID" content={plan.planId} textId="plan-id" />
        </div>
      )}
      {plan.payorType === PayorType.Supplemental.value && (
        <InfoItem
          title="Plan Type"
          content={Object.values(PlanType).find((x) => x.value === plan.planType)?.label || ''}
          textId="plan-type"
        />
      )}
      <InfoItem
        classNames={[styles.width75]}
        title="Plan Name"
        content={plan.planName}
        textId="plan-name"
      />
      {plan.payorType !== PayorType.MA.value && <span />}
    </>
  );

  const renderSubscriberRow = () => {
    if (
      plan.payorType !== PayorType.Commercial.value ||
      plan.subscriberRelationship === SubscriberRelationship.Self.value
    ) {
      return null;
    }

    return (
      <>
        <Input
          id="subscriber-first-name"
          placeholder="Enter first name"
          label="Subscriber First Name"
          value={data.subscriberFirstName}
          onTextChange={(value) => handleInputChange('subscriberFirstName', value)}
          errorText={errors.subscriberFirstName}
          required
        />
        <Input
          id="subscriber-last-name"
          placeholder="Enter last name"
          label="Subscriber Last Name"
          value={data.subscriberLastName}
          onTextChange={(value) => handleInputChange('subscriberLastName', value)}
          errorText={errors.subscriberLastName}
          required
        />
        <DatePickerNew
          id="subscriber-dob"
          header="Subscriber Date of Birth"
          placeholder="Select date"
          maxDate={new Date()}
          value={data.subscriberDateOfBirth}
          onDateSelected={(date) =>
            handleInputChange(
              'subscriberDateOfBirth',
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          onBlur={(date) =>
            handleInputChange(
              'subscriberDateOfBirth',
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          errorText={errors.subscriberDateOfBirth}
          required
        />
      </>
    );
  };

  const renderForm = () => (
    <>
      <InfoItem
        classNames={[styles.width75, 'm-b-10']}
        title="Payor Name"
        content={plan.payorName}
        textId="payor-name"
      />
      <InfoItem title="Payor ID" content={plan.payorId} textId="payor-id" />
      <Input
        id="member-id"
        placeholder="Enter member id"
        label="Member ID"
        value={data.memberId || ''}
        onTextChange={(value) => handleInputChange('memberId', value)}
        errorText={errors.memberId}
      />
      <Select
        id="status"
        label="Status"
        options={Object.values(PlanStatus)}
        onChange={(op) => {
          setErrors((prev) => ({
            ...prev,
            ...validatePatientCoverage('endDate', data.endDate, { ...data, status: op.value }),
          }));

          handleInputChange('status', op.value);
        }}
        value={Object.values(PlanStatus).find((op) => op.value === data.status)}
        disabled={!actions?.changePlanStatus}
        required
      />
      <InfoItem
        title="Source"
        content={Object.values(PlanSource).find((op) => op.value === plan.source)?.label}
        textId="source"
      />
      <DatePickerNew
        id="start-date"
        header="Start date"
        placeholder="Select date"
        value={data.startDate}
        onDateSelected={(date) =>
          handleDateChange(
            'startDate',
            'endDate',
            true,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validatePatientCoverage('startDate', date, data) }))
        }
        errorText={errors.startDate}
        required
      />
      <DatePickerNew
        id="end-date"
        header="End date"
        placeholder="Select date"
        value={data.endDate}
        minDate={new Date(data.startDate)}
        onDateSelected={(date) =>
          handleDateChange(
            'endDate',
            'startDate',
            false,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validatePatientCoverage('endDate', date, data) }))
        }
        filterDate={(date) => {
          const day = date.getDate();
          const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
          return day === lastDay; // Allow only the last day of each month
        }}
        errorText={errors.endDate}
        required={data.status === PlanStatus.Expired.value}
      />
      <span />
      {renderPlanNameRow()}
      {renderSubscriberRow()}
    </>
  );

  return (
    <BasePopup id="edit-plan" open onClose={onClose} title="Edit Plan">
      <div className={styles.wrapper}>{renderForm()}</div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, _.pick(plan, UPDATABLE_COVERAGE_FIELDS), errors),
        submitButtonText: 'Save and Close',
        onClear: onClearHandler,
      })}
    </BasePopup>
  );
};

EditPlanPopup.propTypes = {
  onClose: PropTypes.func,
  planId: PropTypes.string,
};

export default EditPlanPopup;
