export const TableHeaders = [
  { column: 'payorName', label: 'Payor Name', width: '20%', sortBy: 'text' },
  { column: 'planName', label: 'Plan Name', width: '20%', sortBy: 'text' },
  { column: 'payorType', label: 'Plan Category', width: '15%', sortBy: 'text' },
  { column: 'startDate', label: 'Plan Start Date', width: '15%', sortBy: 'text' },
  { column: 'endDate', label: 'Plan End Date', width: '15%', sortBy: 'text' },
  { column: 'hierarchy', label: 'Hierarchy', width: '15%', sortBy: 'text' },
];

export const Operation = Object.freeze({
  Create: 'create',
  Update: 'update',
});
