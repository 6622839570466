// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BGMHeader-module__header___oau7P {
  justify-content: space-between;
  display: flex;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Biometrics/headers/BGMHeader/BGMHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,aAAA;EACA,UAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `BGMHeader-module__header___oau7P`
};
module.exports = ___CSS_LOADER_EXPORT___;
