// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AwsConnectDialer-module__container___PFiku {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: max(60vh, 400px);
  bottom: calc(var(--patient-details-footer) + 4px);
  left: 70px;
  box-shadow: 0 0 2px 0 get-color(greenNew, dark);
}
.AwsConnectDialer-module__container___PFiku.AwsConnectDialer-module__hidden___HD8cw {
  display: none;
}
.AwsConnectDialer-module__container___PFiku .AwsConnectDialer-module__closeBtn___GTDjA {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 100%;
  left: 100%;
  margin-bottom: -10px;
  margin-left: -10px;
  cursor: pointer;
}
.AwsConnectDialer-module__container___PFiku .AwsConnectDialer-module__closeBtn___GTDjA.AwsConnectDialer-module__hidden___HD8cw {
  display: none;
}
.AwsConnectDialer-module__container___PFiku .AwsConnectDialer-module__softphone___YfY0p {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/AwsConnectDialer/AwsConnectDialer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,wBAAA;EACA,iDAAA;EACA,UAAA;EACA,+CAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;EACA,oBAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAEI;EACE,aAAA;AAAN;AAIE;EACE,YAAA;AAFJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AwsConnectDialer-module__container___PFiku`,
	"hidden": `AwsConnectDialer-module__hidden___HD8cw`,
	"closeBtn": `AwsConnectDialer-module__closeBtn___GTDjA`,
	"softphone": `AwsConnectDialer-module__softphone___YfY0p`
};
module.exports = ___CSS_LOADER_EXPORT___;
