import { UsStateByAbbr } from 'common-src/constants/usStates';
import { PayorType, SubscriberRelationship } from 'common-src/models/PatientCoverage';

import {
  CommercialDefaultState,
  MADefaultState,
  MedicaidDefaultState,
  MedicareDefaultState,
  requiredPlanFields,
  requiredSubscriberFields,
  SupplementalDefaultState,
  temporaryFields,
} from './constants';

export const getDefaultState = (payorType, patient) => {
  switch (payorType) {
    case PayorType.Medicaid.value: {
      const state = patient?.address?.state || null;
      const payorName = state ? `Medicaid ${UsStateByAbbr[state]}` : undefined;
      const payorId = state ? `MD${state}` : undefined;

      return {
        ...MedicaidDefaultState,
        state,
        payorName,
        payorId,
      };
    }
    case PayorType.Medicare.value:
      return MedicareDefaultState;
    case PayorType.MA.value:
      return MADefaultState;
    case PayorType.Commercial.value:
      return CommercialDefaultState;
    case PayorType.Supplemental.value:
      return SupplementalDefaultState;
    default:
      return {};
  }
};

export const getIsButtonEnabled = (data, errors) => {
  if (Object.values(errors).some((e) => e)) {
    return false;
  }

  switch (data.payorType) {
    case PayorType.Medicare.value:
    case PayorType.MA.value:
    case PayorType.Medicaid.value:
    case PayorType.Supplemental.value:
      return requiredPlanFields.map((field) => data[field]).every((value) => value);
    case PayorType.Commercial.value:
      if (data.subscriberRelationship === SubscriberRelationship.Self.value) {
        return requiredPlanFields.map((field) => data[field]).every((value) => value);
      }

      return [...requiredPlanFields, ...requiredSubscriberFields]
        .map((field) => data[field])
        .every((value) => value);

    default:
      return false;
  }
};

export const generatePlanName = ({ partA, partB }) => {
  switch (true) {
    case partA && partB:
      return 'Part A and B';
    case partA:
      return 'Part A';
    case partB:
      return 'Part B';
    default:
      return undefined;
  }
};

export const getPayload = (patientId, data) => {
  const dataCopy = JSON.parse(JSON.stringify(data));

  if (data.payorType === PayorType.Medicare.value) {
    dataCopy.planName = generatePlanName(data);
  }

  temporaryFields.forEach((field) => {
    delete dataCopy[field];
  });

  return { patientId, ...dataCopy };
};
