import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Select } from 'common-src/components/base';

import styles from './FormRow.module.scss';

const FormRow = ({ title, options, value, placeholder, onChangeHandler, field }) => (
  <div className={[styles.row, 'vertically-centered'].join(' ')}>
    <span className={[styles.title, 'font-w-500', 'm-b-20', 'font-s-16'].join(' ')}>{title}*</span>
    <Select
      id={`${title.toLowerCase().split(' ').join('-')}-select`}
      classNames={[styles.select]}
      options={Object.values(options)}
      value={_.find(Object.values(options), (option) => option.value === value) || null}
      onChange={(option) => onChangeHandler((data) => ({ ...data, [field]: option.value }))}
      placeholder={placeholder}
    />
  </div>
);

FormRow.propTypes = {
  title: PropTypes.string,
  options: PropTypes.object,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func,
  field: PropTypes.string,
};

export default FormRow;
