import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Badge } from 'common-src/components/base';
import PatientInteraction from 'common-src/models/PatientInteraction';
import { Colors } from 'common-src/styles';

import { DraftList } from 'src/components/lists';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './DocumentationWarningPopup.module.scss';

const DocumentationWarningPopup = ({ open, drafts, onClose, onSubmit, selectDraftsTab }) => {
  const { renderButtons } = usePopup();

  const onClickHandler = () => {
    onClose();
    selectDraftsTab?.();
  };

  return (
    <BasePopup
      id="documentation-warning"
      open={open}
      onClose={onClose}
      customStyle={{ minWidth: '55vw' }}
    >
      <div className={[styles.wrapper, 'flex-column'].join(' ')}>
        <div className={[styles.titleContainer, 'm-b-40'].join(' ')}>
          <span className={styles.title}>Open a draft document?</span>
          <img
            id="close-button"
            className="icon-btn"
            src={CommonIcons.closeIcon}
            alt="close-icon"
            onClick={onClose}
            role="presentation"
          />
        </div>
        <div className="vertically-centered">
          <span className={styles.subtitle}>Your drafts</span>
          <Badge id="drafts-counter" count={drafts.length} backgroundColor={Colors.darkBlue} />
        </div>
        <div className={styles.drafts}>
          <DraftList withSeparator callback={onClickHandler} drafts={drafts} />
        </div>
        {renderButtons({
          containerClassName: styles.buttons,
          buttonClassName: styles.button,
          onClose,
          onSubmit: (loadingCallback) => {
            loadingCallback();
            onSubmit();
          },
          submitButtonText: 'Continue to new document',
          isSubmitEnabled: true,
        })}
      </div>
    </BasePopup>
  );
};

DocumentationWarningPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectDraftsTab: PropTypes.func,
  drafts: PropTypes.arrayOf(PropTypes.exact(PatientInteraction.schema)),
};

export default DocumentationWarningPopup;
