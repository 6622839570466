import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientSupplementsRequest = (query, params) =>
  restRequest({
    uri: 'patientSupplements',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addSupplement = (body, params) =>
  restRequest({
    uri: 'patientSupplements',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const addSupplementOld = (body, params) =>
  restRequest({
    uri: 'patientSupplements',
    method: HttpMethods.Post,
    body: { ...body, status: body?.status || 1, patientReported: body?.patientReported || true },
    ...params,
  });

export const updateSupplement = (id, supplement, params) =>
  restRequest({
    uri: `patientSupplements/${id}`,
    method: HttpMethods.Put,
    body: {
      name: supplement?.name,
      dosage: supplement?.dosage,
      units: supplement?.units,
      dosageText: supplement?.dosageText,
      form: supplement?.form,
      frequency: supplement?.frequency,
      patientReported: supplement?.patientReported,
      comments: supplement?.comments,
      status: supplement?.status,
    },
    ...params,
  });

export const makeSupplementInactive = (id, params) =>
  restRequest({
    uri: `patientSupplements/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
