import PropTypes from 'prop-types';
import React from 'react';

import styles from './EmptyPlaceholder.module.scss';

const EmptyPlaceholder = ({ icon, text }) => (
  <section
    className={[
      styles.container,
      'vertically-centered',
      'horizontally-centered',
      'flex-column',
    ].join(' ')}
  >
    {icon}
    <span className={[styles.text, 'm-t-16'].join(' ')}>{text}</span>
  </section>
);

EmptyPlaceholder.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
};

export default EmptyPlaceholder;
