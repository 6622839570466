import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { getDeviceType, getPatientDevices } from 'common-src/models/PatientDevice';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import BasePopup from 'src/popups/BasePopup';

import styles from './PatientDevicesPopup.module.scss';

const PatientDevicesPopup = ({ patientId, onClose }) => {
  const devices = useCustomSelector((state) => getPatientDevices(state, patientId));
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));

  return (
    <BasePopup id="devices" open onClose={onClose} title="Patient Devices">
      <div className="p-30">
        <div className={[styles.grid, 'm-b-10', 'font-w-600'].join(' ')}>
          <span>Device</span>
          <span>Last reading at</span>
        </div>
        <div className={styles.grid}>
          {devices.map((device) => (
            <Fragment key={device.id}>
              <span>
                {getDeviceType(device.typeId)} #{device.id}
              </span>
              <span>
                {device.lastReadingAt
                  ? moment(device.lastReadingAt).tz(patient.timeZone).format('MM/DD/YYYY HH:mm A z')
                  : '-'}
              </span>
            </Fragment>
          ))}
        </div>
      </div>
    </BasePopup>
  );
};

PatientDevicesPopup.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.number,
};

export default PatientDevicesPopup;
