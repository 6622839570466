import { Payors, PayorType, PlanStatus, PrescriptionDestinations } from './constants';

export const getPlanCategory = (planId) =>
  Object.values(PayorType).find((type) => type.value === planId)?.label || '-';

export const getPlanStatus = (statusId) =>
  Object.values(PlanStatus).find((status) => status.value === statusId);

export const getPayor = (insurance) => {
  if (!insurance) return 'Unknown';

  if (insurance.payorName) return insurance.payorName;

  return Object.values(Payors).find((p) => p.value === insurance.payorId)?.label || 'Unknown';
};

export const getDestinationMessage = (insuranceType, payor, destination) => {
  switch (destination) {
    case PrescriptionDestinations.FaxWrittenOrder:
      return 'Patient’s insurance is not supported by ePrescription. Please use the Fax Written Order instead.';
    case PrescriptionDestinations.LocalPharmacy:
      return `Insurance type: ${insuranceType}\nPrimary Payor: ${payor}\n\nPlease use the patient’s Local Pharmacy for the order.`;
    default:
      return `Insurance type: ${insuranceType}\nPrimary Payor: ${payor}\n\nPlease select ${destination} as the pharmacy for the patient.`;
  }
};
