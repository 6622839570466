import { activeStatusesIds } from 'common-src/models/PatientMedicalProblem';

import { MedicalHistoryTabs } from './constants';

export const getTabInfo = (medicalProblems = []) => {
  const activeMedicalProblems = [];
  const inactiveMedicalProblems = [];

  [...medicalProblems]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((mp) => {
      if (!mp?.dxStatus || activeStatusesIds.includes(mp?.dxStatus)) {
        activeMedicalProblems.push(mp);
        return;
      }

      inactiveMedicalProblems.push(mp);
    });

  const tabInfo = {
    ACTIVE: {
      text: MedicalHistoryTabs.ACTIVE.text,
      count: activeMedicalProblems.length,
    },
    NOT_ACTIVE: {
      text: MedicalHistoryTabs.NOT_ACTIVE.text,
      count: inactiveMedicalProblems.length,
    },
    ALL: {
      text: MedicalHistoryTabs.ALL.text,
      count: medicalProblems.length,
    },
  };

  return {
    tabInfo,
    activeMedicalProblems,
    inactiveMedicalProblems,
    allMedicalProblems: medicalProblems,
  };
};
