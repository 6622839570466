import PropTypes from 'prop-types';
import React from 'react';

import Tab from './Tab';
import styles from './Tabs.module.scss';

const Tabs = ({ tabs, selectedTab, onTabSelect, onTabClose, classNames = [] }) => (
  <div
    style={{ gridTemplateColumns: `repeat(${tabs.length}, minmax(90px, max-content))` }}
    className={[styles.container, ...classNames].join(' ')}
  >
    {tabs.map((tab) => (
      <Tab
        key={tab.text}
        tabName={tab.text}
        isSelected={tab.text === selectedTab}
        onTabSelect={onTabSelect}
        onTabClose={onTabClose}
        showCloseButton={tab.showCloseButton}
      />
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.string,
  onTabSelect: PropTypes.func,
  onTabClose: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default Tabs;
