import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'common-src/components/base';
import PatientInteraction from 'common-src/models/PatientInteraction';
import {
  getPatientOncehubBookings,
  OncehubBookingStatuses,
} from 'common-src/models/PatientOncehubBooking';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';

import ScheduledVisitItem from './ScheduledVisitItem';
import styles from './ScheduledVisits.module.scss';

const ScheduledVisits = ({
  patientId,
  isEncounterNotScheduled,
  setIsEncounterNotScheduled,
  linkedVisitId,
  setLinkedVisitId,
  documentationToEdit,
}) => {
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const scheduledVisits = useCustomSelector((state) => getPatientOncehubBookings(state, patientId));

  return (
    <>
      <p className={[styles.header, 'font-w-500', 'font-s-14'].join(' ')}>
        Please link a scheduled visit to this encounter, only if the visit was completed.
      </p>
      {scheduledVisits
        .filter(
          (visit) =>
            moment(visit.scheduledAt).isAfter(
              moment(documentationToEdit.createdAt).subtract(24, 'hours'),
            ) &&
            moment(visit.scheduledAt).isBefore(
              moment(documentationToEdit.createdAt).add(24, 'hours'),
            ) &&
            [
              OncehubBookingStatuses.scheduled.text.toLowerCase(),
              OncehubBookingStatuses.rescheduled.text.toLowerCase(),
              OncehubBookingStatuses.missed.text.toLowerCase(),
            ].includes(visit.status),
        )
        .map((visit) => (
          <ScheduledVisitItem
            key={visit.id}
            visit={visit}
            isChecked={visit.id === linkedVisitId}
            checkboxHandler={() => {
              if (isEncounterNotScheduled) return;
              setLinkedVisitId((prev) => (prev === visit.id ? null : visit.id));
            }}
            timeZone={patient.timeZone}
            disabled={isEncounterNotScheduled}
          />
        ))}
      <Checkbox
        id="is-encounter-not-scheduled"
        classNames={[styles.checkbox, 'm-t-16']}
        label=" There is no visit to link to, visit was missed or not scheduled, or the encounter was not a live interaction"
        checked={isEncounterNotScheduled}
        onChange={setIsEncounterNotScheduled}
        disabled={linkedVisitId}
      />
    </>
  );
};

ScheduledVisits.propTypes = {
  patientId: PropTypes.number,
  isEncounterNotScheduled: PropTypes.bool,
  setIsEncounterNotScheduled: PropTypes.func,
  linkedVisitId: PropTypes.number,
  setLinkedVisitId: PropTypes.func,
  documentationToEdit: PropTypes.exact(PatientInteraction.schema),
};

export default ScheduledVisits;
