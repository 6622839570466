import React from 'react';
import { Route, Routes } from 'react-router-dom';

import useProtectedRoutes from 'src/hooks/useProtectedRoutes';
import useVersionCheck from 'src/hooks/useVersionCheck';
import { Dashboard, Login } from 'src/pages';

const AppRouter = () => {
  useVersionCheck();
  useProtectedRoutes();

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<Dashboard />} />
    </Routes>
  );
};

export default AppRouter;
