import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PreAuthorization extends Model {
  static modelName = 'PreAuthorization';

  static fields = {
    id: attr(),
    serviceType: attr(),
    authCode: attr(),
    status: attr(),
    startDate: attr(),
    endDate: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    patientCoverageId: fk({
      to: 'PatientCoverage',
      as: 'patientCoverage',
      relatedName: 'authorizations',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      patientCoverageId: PropTypes.string,
      authCode: PropTypes.string,
      serviceType: PropTypes.number,
      status: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    patientCoverageId: PropTypes.string,
  };
}

export default PreAuthorization;
