import { CommonIcons } from 'common-src/assets/Icons';

import { CGMOrderStatus } from './constants';

export const getCgmOrderStatusConfig = (statusId) => {
  switch (statusId) {
    case CGMOrderStatus.New.value:
      return { text: 'New', iconSrc: CommonIcons.orderInProgressIcon };
    case CGMOrderStatus.InProgress.value:
      return { text: 'In Progress', iconSrc: CommonIcons.orderInProgressIcon };
    case CGMOrderStatus.Shipped.value:
      return { text: 'Shipped', iconSrc: CommonIcons.orderInProgressIcon };
    case CGMOrderStatus.Delivered.value:
      return { text: 'Delivered', iconSrc: CommonIcons.orderCompleted };
    case CGMOrderStatus.Canceled.value:
      return { text: 'Canceled', iconSrc: CommonIcons.orderInProgressIcon };
    default:
      return { text: 'Unknown', iconSrc: CommonIcons.orderInProgressIcon };
  }
};
