import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientHistoryEvents = (state, patientId) =>
  allModelsSelector(state, 'PatientHistoryEvent', (phe) => phe.patientId === patientId).sort(
    (a, b) => {
      const aDateToCompare = a?.meta?.sentAt || a.createdAt;
      const bDateToCompare = b?.meta?.sentAt || b.createdAt;

      return new Date(aDateToCompare) - new Date(bDateToCompare);
    },
  );
