import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { getClient } from 'common-src/models/Client';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './InfoBar.module.scss';

const Item = ({ title, content, prefix }) => (
  <div className="flex-column">
    <span
      id={`${prefix}-care-provider-title`}
      className={[styles.title, 'font-w-500', 'font-s-10'].join(' ')}
    >
      {title}
    </span>
    <span
      id={`${prefix}-care-provider-name`}
      className={[styles.content, 'font-w-500', 'font-s-14'].join(' ')}
    >
      {content}
    </span>
  </div>
);

Item.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  prefix: PropTypes.string,
};

const InfoBar = ({ patientId, rightContent }) => {
  const patient = useCustomSelector((state) =>
    singleModelSelector(state, 'Patient', Number(patientId)),
  );
  const assignedCoach = useCustomSelector((state) => getClient(state, patient?.assignedCoachId));
  const assignedCDE = useCustomSelector((state) => getClient(state, patient?.assignedCDCESId));
  const assignedRD = useCustomSelector((state) => getClient(state, patient?.assignedDietitianId));

  return (
    <div className={[styles.container, 'gap-40'].join(' ')}>
      <Item
        title="Primary Coach"
        content={!_.isEmpty(assignedCoach) ? assignedCoach.getName() : 'Not assigned'}
        prefix="primary-coach"
      />
      <Item
        title="CDE"
        content={!_.isEmpty(assignedCDE) ? assignedCDE.getName() : 'Not assigned'}
        prefix="cde"
      />
      <Item
        title="RD"
        content={!_.isEmpty(assignedRD) ? assignedRD.getName() : 'Not assigned'}
        prefix="rd"
      />
      {rightContent && <div className={styles.right}>{rightContent}</div>}
    </div>
  );
};

InfoBar.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightContent: PropTypes.element,
};

export default InfoBar;
