import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, Checkbox, Menu, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import { getRoleConfig } from 'common-src/features/auth';
import { apiRequest } from 'common-src/features/rest';
import {
  activeStatusesIds,
  getDosageText,
  getFormText,
  getNameText,
  getPatientMedications,
  getPatientMedicationsRequest,
  getSourceById,
  getStatusById,
  makeMedicationInactive,
  MedicationStatus,
  StatusesConfig,
  updateMedicationStatus,
} from 'common-src/models/PatientMedication';
import { Colors, ColorsNew } from 'common-src/styles';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultiTextItem,
  TablePagination,
  TextItem,
  TextWithTooltipItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import {
  getPatientLastMedicationsPull,
  savePatientLastMedicationsPull,
} from 'src/features/tabsState';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, Filters, MedicationsTabs, TableHeaders } from './constants';
import { getTabInfo } from './helpers';
import styles from './Medications.module.scss';

const Medications = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(MedicationsTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredMedications, setFilteredMedications] = useState([]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [filters, setFilters] = useState(Filters);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });
  const [isLoading, setIsLoading] = useState(false);

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const medications = useCustomSelector((state) => getPatientMedications(state, patientId));
  const canRequestUpdates = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.actions?.canRequestMedicationUpdates,
  );
  const lastPullAt = useCustomSelector((state) => getPatientLastMedicationsPull(state, patientId));

  const { isRequesting } = useRequestLoading([getPatientMedicationsRequest({ patientId })]);
  const { showAlert, AlertType } = useAlert();

  useInterval(() => {
    dispatch(getPatientMedicationsRequest({ patientId, sort_by: 'desc', limit: 15 }, {}));
  });

  useEffect(() => {
    const { tabInfo, activeMedications, inactiveMedications, allMedications } = getTabInfo(
      medications,
      filters,
    );

    setTabInfo(tabInfo);

    let medicationsToShow;
    switch (selectedTab) {
      case tabs[0]:
        medicationsToShow = activeMedications;
        break;
      case tabs[1]:
        medicationsToShow = inactiveMedications;
        break;
      case tabs[2]:
        medicationsToShow = allMedications;
        break;
      default:
        medicationsToShow = [];
    }

    setFilteredMedications(medicationsToShow);
  }, [selectedTab, medications, filters]);

  const requestMedicationsUpdate = () => {
    setIsLoading(true);
    apiRequest({
      endpoint: `patientMedications/${patientId}/requestUpdate`,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          showAlert(AlertType.Error, '', 'An error occurred while pulling medications data');
          return;
        }

        dispatch(savePatientLastMedicationsPull(patientId, new Date()));
        showAlert(AlertType.Success, '', 'Successfully sent request for medications pull');
      })
      .finally(() => setIsLoading(false));
  };

  const showAddMedicationPopup = () => dispatch(openModal(ModalType.MEDICATION, { patientId }));

  const showMedicationDetailsPopup = (medication) =>
    dispatch(openModal(ModalType.MEDICATION_DETAILS, { medication }));

  const renderRightHeaderContent = () => (
    <div className={[styles.rightContent, 'flex-row', 'flex-1', 'gap-20', 'm-b-10'].join(' ')}>
      <Checkbox
        id="display-only-imported-filter"
        classNames={['flex-1']}
        checked={filters.showOnlyImported}
        onChange={(checked) =>
          setFilters((prevState) => ({ ...prevState, showOnlyImported: checked }))
        }
        label="Display only imported records"
      />
      <Button
        id="new-medication-button"
        text="Add Medication"
        onClick={() => showAddMedicationPopup()}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
        iconSrc={CommonIcons.plusIcon}
        disabled={!actionsAllowed}
        classNames={[styles.button]}
      />
      {canRequestUpdates && (
        <Button
          id="request-update-button"
          text="Request Update"
          onClick={() => requestMedicationsUpdate()}
          backgroundColor={Colors.white}
          textColor={ColorsNew.darkGreen}
          iconSrc={CommonIcons.refreshIcon}
          disabled={
            !actionsAllowed ||
            isLoading ||
            (lastPullAt && moment().diff(moment(lastPullAt), 'minutes') < 1)
          }
          classNames={[styles.button]}
        />
      )}
    </div>
  );

  const renderFooter = () => {
    // no footer case
    if (!patient?.scriptsureLastPullAt && filteredMedications?.length <= ResultsCount[25])
      return null;

    return (
      <div className="flex-row m-b-10 p-t-12">
        {patient?.scriptsureLastPullAt && (
          <span className={[styles.lastImported, 'vertically-centered', 'font-s-10'].join(' ')}>
            {`Last imported: ${moment(patient.scriptsureLastPullAt).format('MM/DD/YYYY')}`}
          </span>
        )}
        <TablePagination
          onChangePageHandler={setPaginationData}
          results={filteredMedications}
          selectedTab={selectedTab}
          filters={filters}
          containerClassNames={[styles.pagination]}
        />
      </div>
    );
  };

  const getData = () =>
    filteredMedications.map((medication) => {
      const isDisabled =
        (medication.activeTill && moment(medication.activeTill).isBefore(moment.now())) ||
        !activeStatusesIds.includes(medication?.status) ||
        !actionsAllowed;
      const currentStatus = getStatusById(medication?.status);
      const options = StatusesConfig.filter(
        (status) => status.isSelectable && !status.isArchived,
      ).map((op) => ({
        ...op,
        onClick: (option) => dispatch(updateMedicationStatus(medication.id, option.id, medication)),
      }));

      return {
        name: (
          <MultiTextItem
            isActive
            title={getNameText(medication?.med)}
            subtitle={`${getDosageText(medication?.med)} ${getFormText(medication?.med)}`}
            onClick={() => showMedicationDetailsPopup(medication)}
          />
        ),
        source: (
          <TextWithTooltipItem
            headerText={getSourceById(medication?.source)?.label || 'Unknown'}
            tooltipText={getSourceById(medication?.source)?.tooltipText}
            value={getSourceById(medication?.source)?.label || 'Unknown'}
          />
        ),
        createdAt: (
          <TextItem
            isActive
            text={moment(medication.startDate || medication.createdAt).format('MM/DD/YYYY')}
            value={medication.startDate || medication.createdAt}
          />
        ),
        filledDate: (
          <TextItem
            isActive
            text={medication.filledDate ? moment(medication.filledDate).format('MM/DD/YYYY') : '-'}
            value={medication?.filledDate}
          />
        ),
        status: (
          <Menu
            button={
              currentStatus ? (
                <StatusItem
                  isActive
                  text={currentStatus.label}
                  textColor={currentStatus.textColor}
                  backgroundColor={currentStatus.backgroundColor}
                />
              ) : (
                '-'
              )
            }
            options={options}
            menuStatus={currentStatus?.id || '-'}
            disabled={isDisabled}
            text={currentStatus?.label || '-'}
          />
        ),
        actions: (
          <ActionsRow
            options={[
              medication?.status === MedicationStatus.NeedsReview && {
                id: 'reviewed-button',
                text: 'Reviewed',
                backgroundColor: '#375844CC',
                color: 'white',
                disabled: isDisabled,
                onClick: updateMedicationStatus(
                  medication.id,
                  MedicationStatus.Active,
                  medication,
                  {},
                ),
              },
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.MEDICATION, { patientId, medication }),
              },
              medication?.status !== MedicationStatus.Archived && {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeMedicationInactive(medication.id, {}),
                showWarning: true,
              },
              isDisabled && {
                id: 'duplicate-button',
                text: 'Duplicate',
                backgroundColor: ColorsNew.baseGreen,
                color: 'white',
                disabled: !actionsAllowed,
                onClick: openModal(ModalType.MEDICATION, {
                  patientId,
                  medication,
                  isDuplicate: true,
                }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling medications..." />;
  }

  return (
    <>
      <div className="flex-row">
        <TabsViewNew
          classNames={['flex-1 medical-tabs']}
          options={tabInfo}
          onTabSelected={setSelectedTab}
          tabClassNames={['flex-none']}
          rightContent={renderRightHeaderContent()}
        />
      </div>
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="medication"
        classNames={['medical-table-new']}
        emptyText="No Medications found for patient. Please add medications manually."
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'createdAt',
          label: 'Date Written',
          width: '10%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      {renderFooter()}
    </>
  );
};

Medications.propTypes = {
  patientId: PropTypes.number.isRequired,
  actionsAllowed: PropTypes.bool,
};

export default Medications;
