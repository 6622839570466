import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getSdohCodesRequest = (params) =>
  restRequest({
    uri: 'sdohCodes',
    query: {},
    method: HttpMethods.Get,
    ...params,
  });
