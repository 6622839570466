// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SurgicalHistoryDetailsPopup-module__firstGrid___WDEKG {
  display: grid;
  padding: 15px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}

.SurgicalHistoryDetailsPopup-module__comments___y8HuI {
  margin: 10px 0;
  padding: 15px 30px;
}

.SurgicalHistoryDetailsPopup-module__secondGrid___mcc9F {
  display: grid;
  padding: 15px 30px 32px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/popups/SurgicalHistoryDetailsPopup/SurgicalHistoryDetailsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,4BAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstGrid": `SurgicalHistoryDetailsPopup-module__firstGrid___WDEKG`,
	"comments": `SurgicalHistoryDetailsPopup-module__comments___y8HuI`,
	"secondGrid": `SurgicalHistoryDetailsPopup-module__secondGrid___mcc9F`
};
module.exports = ___CSS_LOADER_EXPORT___;
