import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import { getSectionName } from 'common-src/models/PatientInteraction';
import { getAllowedDocumentationModules } from 'common-src/models/PatientInteraction/helpers';

import useCustomSelector from 'src/hooks/useCustomSelector';
import { excludedModulesIds } from 'src/pages/DocumentationIndex/constants';

import { ModuleItem } from './items';
import styles from './SectionAside.module.scss';

const SectionAside = ({ modules, areModulesDisabled, onModuleSelect, isEmbedded }) => {
  const [selectedLink, setSelectedLink] = useState({});

  const allowedModules = useCustomSelector((state) => getAllowedDocumentationModules(state));

  const renderModules = () =>
    Object.entries(allowedModules).map(([section, ids]) => {
      if (ids.size === 0) return;

      return (
        <Fragment key={section}>
          <p
            className={[styles.header, 'font-w-600', 'p-l-20', 'font-s-18'].join(' ')}
          >{`${getSectionName(section)} Documents`}</p>
          {modules
            .filter(
              (module) =>
                ids &&
                ids.has(module.moduleType) &&
                !excludedModulesIds.includes(module.moduleType),
            )
            .map((module) => (
              <ModuleItem
                key={module.id}
                module={module}
                disabled={areModulesDisabled}
                setSelectedLink={setSelectedLink}
                selectedLink={selectedLink}
                onChangeHandler={() => {
                  onModuleSelect(module);
                  if (selectedLink[module.id]) {
                    setSelectedLink({});
                  }
                }}
              />
            ))}
        </Fragment>
      );
    });

  return (
    <aside
      className={[
        styles.aside,
        isEmbedded ? styles.isEmbedded : '',
        'p-t-30',
        'primary-border-r',
      ].join(' ')}
    >
      <p className={[styles.description, 'font-s-14'].join(' ')}>
        Select relevant form templates from this menu, then record your responses.
      </p>
      {renderModules()}
    </aside>
  );
};

SectionAside.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  onModuleSelect: PropTypes.func,
  areModulesDisabled: PropTypes.bool,
  isEmbedded: PropTypes.bool,
};

export default SectionAside;
