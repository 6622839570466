import _ from 'lodash';

import { validateClient } from 'common-src/models/Client';

import { initialState, superAdminOptions } from './constants';

export const getIsSuperAdminValue = (value) => {
  if (typeof value === 'object') return value;
  return superAdminOptions.find((option) => option.value === value);
};

export const getRequestBody = (data) => ({
  ...data,
  roleId: data.roleId.value,
  npi: data.npi || null,
  credentials: data.credentials || null,
  isSuper: getIsSuperAdminValue(data.isSuper).value,
});

export const getIsButtonEnabled = (data, errors) => {
  if (_.isEqual(data, initialState) || Object.values(errors).some((e) => e)) return false;
  return true;
};

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validateClient(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};
