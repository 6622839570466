export const RestCallState = Object.freeze({
  REQUEST: 'REQUEST',
  RESPONSE: 'RESPONSE_RECEIVED',
  ERROR: 'ERROR',
  REQUEST_MULTIPLE: 'REQUEST_MULTIPLE',
});

export const HttpMethods = Object.freeze({
  Get: 'get',
  Post: 'post',
  Put: 'put',
  Patch: 'PATCH',
  Delete: 'delete',
});
