import moment from 'moment-timezone';

import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';
import { activeStatusesIds } from 'common-src/models/PatientMedication';

export const getPatientMedicationsRequest = (query, params) =>
  restRequest({
    uri: 'patientMedications',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addMedication = (body, params) =>
  restRequest({
    uri: 'patientMedications',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const editMedication = (body, params) =>
  restRequest({
    uri: `patientMedications/${body.id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const updateMedicationStatus = (id, status, medication, params) =>
  restRequest({
    uri: `patientMedications/${id}`,
    method: HttpMethods.Put,
    body: {
      id,
      patientId: medication?.patient?.id,
      medicationId: medication?.med?.ndc,
      form: medication?.form,
      dosage: medication?.dosage,
      instructions: medication?.instructions,
      route: medication?.route,
      frequency: medication?.frequency,
      source: medication?.source,
      activeTill: activeStatusesIds.includes(status) ? null : moment().utc().toISOString(),
      filledDate: medication?.filledDate,
      endDate: medication?.endDate,
      notes: medication?.notes,
      status,
    },
    ...params,
  });

export const makeMedicationInactive = (id, params) =>
  restRequest({
    uri: `patientMedications/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
