import {
  UPDATE_AGENT_STATE,
  UPDATE_AWS_DIALER_VISIBILITY,
  UPDATE_CONTACT_TO_DIAL,
} from './actionTypes';

export const updateAwsDialerVisibility = (isVisible) => ({
  type: UPDATE_AWS_DIALER_VISIBILITY,
  payload: { isVisible },
});

export const updateContactToDial = (payload) => ({
  type: UPDATE_CONTACT_TO_DIAL,
  payload: { phoneToDial: payload?.phoneToDial, patientId: payload?.patientId },
});

export const updateAgentState = (agentState) => ({
  type: UPDATE_AGENT_STATE,
  payload: { agentState },
});
