import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'common-src/components/base';

import styles from './MultilineTextItem.module.scss';

const MultilineTextItem = ({
  isActive,
  isErrored,
  text = '-',
  maxLines = 1,
  isUnderlined = false,
  disableTooltip = false,
  splitSentences = false,
}) => {
  const sentences = text.split(/(?<=[.!?])\s+/);

  const renderText = () => {
    if (!splitSentences) return text;

    return sentences.map((sentence, index) => <div key={index}>{sentence}</div>);
  };

  return (
    <Tooltip title={text} disableHoverListener={disableTooltip}>
      <span
        className={[styles.text, 'font-w-500', 'font-s-14'].join(' ')}
        style={{
          opacity: isActive ? 1 : 0.5,
          textDecoration: isErrored ? 'line-through' : isUnderlined ? 'underline' : 'initial',
          cursor: isUnderlined ? 'pointer' : 'inherit',
          WebkitLineClamp: maxLines,
        }}
      >
        {renderText()}
      </span>
    </Tooltip>
  );
};

MultilineTextItem.propTypes = {
  isActive: PropTypes.bool,
  isErrored: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  disableTooltip: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLines: PropTypes.number,
  splitSentences: PropTypes.bool,
};

export default MultilineTextItem;
