import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import {
  getLabReadingCategory,
  getPatientLabResultsByCategory,
} from 'common-src/models/PatientLabReading';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { MedicalRecordsTable } from 'src/components/table';
import useCustomSelector from 'src/hooks/useCustomSelector';
import BasePopup from 'src/popups/BasePopup';

import styles from './ResultsDetailsPopup.module.scss';

const ResultsDetailsPopup = ({ onClose, labReadingId, patientId }) => {
  const labReading = useCustomSelector((state) =>
    singleModelSelector(state, 'PatientLabReading', labReadingId),
  );
  const labReadings = useCustomSelector((state) =>
    getPatientLabResultsByCategory(
      state,
      patientId,
      getLabReadingCategory(labReading.typeId),
      labReading.readingDate,
    ),
  );

  if (!labReading) return null;

  return (
    <BasePopup id="results-details" open onClose={onClose} title="Results Details">
      <div className={[styles.info, 'font-w-500', 'primary-border-b'].join(' ')}>
        <p className={[styles.source, 'font-s-12'].join(' ')}>Medical Record Import</p>
        <p className={[styles.category, 'font-w-600', 'm-b-10', 'font-s-22'].join(' ')}>
          {getLabReadingCategory(labReading.typeId)}
        </p>
        <div className={[styles.date, 'm-b-6', 'font-s-14'].join(' ')}>
          <span>Resulted:</span>
          <span>{moment(labReading.readingDate).format('MM/DD/YYYY')}</span>
        </div>
        <div className={[styles.date, 'm-b-6'].join(' ')}>
          <span>Created:</span>
          <span>{moment(labReading.createdAt).format('MM/DD/YYYY')}</span>
        </div>
      </div>
      <div className={[styles.table, 'm-b-6'].join(' ')}>
        <MedicalRecordsTable records={labReadings} patientId={patientId} />
      </div>
    </BasePopup>
  );
};

ResultsDetailsPopup.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.number,
  labReadingId: PropTypes.number,
};

export default ResultsDetailsPopup;
