import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select, TextArea } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  addFamilyHistory,
  getPatientSingleFamilyHistory,
  makeFamilyHistoryInactive,
  updateFamilyHistory,
  validatePatientFamilyHistory,
} from 'common-src/models/PatientFamilyHistory';
import { getChangedValues } from 'common-src/utils/objectUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import { catchErrors } from 'src/popups/AllergyPopup/helpers';
import BasePopup from 'src/popups/BasePopup';

import { initialState, relationOptions } from './constants';
import styles from './FamilyHistoryPopup.module.scss';
import { extractPatientFamilyHistory, getRequestBody } from './helpers';

const UPDATABLE_FIELDS = ['comments'];

const FamilyHistoryPopup = ({ open, onClose, id, patientId, isDuplicate = false }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const isEdit = useMemo(() => !!id && !isDuplicate, [id, isDuplicate]);

  const familyHistory =
    useCustomSelector((state) => getPatientSingleFamilyHistory(state, id)) || {};

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (_.isEmpty(familyHistory)) return;
    setData(extractPatientFamilyHistory(familyHistory));
  }, [familyHistory?.id]);

  const onSubmit = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const updatedFields = Object.keys(
      getChangedValues(data, extractPatientFamilyHistory(familyHistory)),
    );
    const shouldEdit =
      !isDuplicate && _.isEmpty(updatedFields.filter((field) => !UPDATABLE_FIELDS.includes(field)));

    const requests = [];
    if (shouldEdit) {
      requests.push(updateFamilyHistory(id, getRequestBody(data)));
    } else {
      requests.push(addFamilyHistory(getRequestBody(data, patientId)));
      if (id && !isDuplicate) {
        requests.push(makeFamilyHistoryInactive(id));
      }
    }

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Family history ${shouldEdit ? 'edited' : 'added'}!`);

          if (!shouldClose) {
            setData(initialState);
            setAutoFocus(true);
            return;
          }

          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChange = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientFamilyHistory(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const getIsButtonEnabled = () =>
    data.diagnosis &&
    data.relationship &&
    Object.values(errors).every((e) => !e) &&
    (isDuplicate || !_.isEqual(data, extractPatientFamilyHistory(familyHistory)));

  return (
    <BasePopup
      id="family-history"
      open={open}
      onClose={onClose}
      title={isEdit ? 'Edit History' : 'Add History'}
    >
      <div className={styles.grid}>
        <Input
          id="diagnosis"
          placeholder="Enter diagnosis"
          label="Diagnosis"
          required
          value={data.diagnosis}
          errorText={errors.diagnosis}
          onTextChange={(value) => onChange('diagnosis', value)}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
        />
        <Select
          id="relation-select-input"
          label="Relation"
          options={relationOptions}
          onChange={(value) => onChange('relationship', value?.value || null)}
          value={_.find(relationOptions, { value: data.relationship }) || null}
          required
          placeholder="Relation"
          errorText={errors.relationship}
        />
        <span />
        <TextArea
          id="comments"
          classNames={[styles.width100]}
          placeholder="Enter comments"
          value={data.comments}
          onTextChange={(value) => onChange('comments', value)}
          fixedRows={4}
          label="Comments"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit,
        isSubmitEnabled: getIsButtonEnabled(),
        submitButtonText: 'Save and Close',
        renderSaveButton: !isEdit,
      })}
    </BasePopup>
  );
};

FamilyHistoryPopup.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number,
  isDuplicate: PropTypes.bool,
};

export default FamilyHistoryPopup;
