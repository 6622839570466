// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List-module__list___MC5pq {
  width: 100%;
}

.List-module__empty___T1jmv {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./../common/src/components/base/List/List.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,OAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `List-module__list___MC5pq`,
	"empty": `List-module__empty___T1jmv`
};
module.exports = ___CSS_LOADER_EXPORT___;
