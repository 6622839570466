import PropTypes from 'prop-types';
import React from 'react';

import { CustomCheckbox, DatePickerNew, Select, Toggle } from 'common-src/components/base';
import { LabReadingFilterNew } from 'common-src/models/PatientLabReading';

import Icons from 'src/assets/Icons';
import { LabsTabs } from 'src/pages/PatientDetails/fragments/Labs/constants';

import styles from './FiltersHeader.module.scss';

const FiltersHeader = ({ filters, onChangeHandler }) => (
  <div className={[styles.container, 'vertically-centered', 'gap-12'].join(' ')}>
    <span className={[styles.title, 'font-w-500', 'font-s-22'].join(' ')}>Results</span>
    <div className={[styles.filters, 'vertically-centered', 'gap-16'].join(' ')}>
      <Toggle
        options={LabsTabs}
        value={filters.tab}
        onClick={(value) => onChangeHandler('tab', value)}
        defaultValue={LabsTabs.List.value}
      />
      <DatePickerNew
        id="jump-date"
        classNames={[styles.picker]}
        inputClassNames={[styles.input]}
        startDate={filters.startDate}
        endDate={filters.endDate}
        selectsRange
        maxDate={new Date()}
        placeholder="Jump to Date"
        onRangeSelected={(dates) => {
          const [start, end] = dates;
          onChangeHandler('startDate', start);
          onChangeHandler('endDate', end);
        }}
      />
      <Select
        id="filter-type-select"
        options={Object.values(LabReadingFilterNew)}
        onChange={(value) => onChangeHandler('types', value)}
        value={filters.types}
        iconSrc={Icons.filterIcon}
        placeholder="Filter Type"
        multiple
        isClearable
      />
      <CustomCheckbox
        id="reported-values-chechbox"
        header="Hide Patient Reported Values"
        checked={filters.hideReported}
        onChange={(value) => onChangeHandler('hideReported', value)}
      />
    </div>
  </div>
);

FiltersHeader.propTypes = {
  filters: PropTypes.object,
  onChangeHandler: PropTypes.func,
};

export default FiltersHeader;
