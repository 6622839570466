import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getRoleConfig, isViewOnly } from 'common-src/features/auth';
import { deleteOrmItem } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import PatientLabReading, {
  deleteLabReading,
  getLabReadingCategory,
  getLabReadingStudy,
  getLabReadingUnit,
  LabReadingStatus,
} from 'common-src/models/PatientLabReading';
import { updateStatus } from 'common-src/models/PatientLabReading/actions';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsButton } from 'src/components/buttons';
import { BaseTable, CreatorItem, TextItem } from 'src/components/table';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import { getDeleteMessage, getStatusChangeIds } from './helpers';
import styles from './ListTab.module.scss';

const headers = [
  { column: 'type', label: 'Study', width: '18%', sortBy: 'text' },
  { column: 'result', label: 'Result', width: '10%', sortBy: 'text' },
  { column: 'createdBy', label: 'Created By', width: '17%', sortBy: 'value' },
  { column: 'source', label: 'Source', width: '16%', sortBy: 'text' },
  {
    column: 'dateResulted',
    label: 'Date Resulted',
    width: '16%',
    sortBy: 'value',
  },
  { column: 'dateCreated', label: 'Date Created', width: '16%', sortBy: 'value' },
  { column: 'actions', label: 'Actions', width: '7%' },
];

const ListTab = ({ patient, labResults }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.vitalsAndLabResults,
  );

  const groupedResults = useMemo(() => {
    const res = [];
    const filters = [];

    labResults.forEach((r) => {
      const { patientReported, typeId, readingDate } = r;
      const category = getLabReadingCategory(typeId);

      if (patientReported) {
        res.push(r);
        return;
      }

      if (!filters.find((x) => x.category === category && x.readingDate === readingDate)) {
        res.push(r);
        filters.push({ category, readingDate });
      }
    });

    return res;
  }, [labResults]);

  const showLabResultPopup = (labReadingId) =>
    dispatch(openModal(ModalType.LAB, { patientId: patient.id, labReadingId }));

  const showTrendedResultsPopup = (labReadingType) =>
    dispatch(
      openModal(ModalType.TRENDED_RESULTS, { patientId: patient.id, typeId: labReadingType }),
    );

  const showResultsDetailsPopup = (labReadingId) =>
    dispatch(openModal(ModalType.RESULTS_DETAILS, { patientId: patient.id, labReadingId }));

  useEffect(() => {
    setData(
      groupedResults.map((item) => {
        const isErrored = item?.status === LabReadingStatus.Inactive;
        return {
          type: (
            <TextItem isActive={!isErrored} isErrored={isErrored} text={getLabReadingStudy(item)} />
          ),
          result: item.patientReported ? (
            <TextItem
              isActive={!isErrored}
              isErrored={isErrored}
              text={
                item?.readingValue ? `${item.readingValue} ${getLabReadingUnit(item.typeId)}` : '-'
              }
            />
          ) : (
            <button
              disabled={item?.status === LabReadingStatus.Inactive}
              className={[styles.viewButton, 'font-w-500', 'font-s-14'].join(' ')}
              style={{
                opacity: !isErrored ? 1 : 0.5,
                textDecoration: isErrored ? 'line-through' : 'initial',
              }}
              type="button"
              onClick={() => showResultsDetailsPopup(item.id)}
            >
              View
            </button>
          ),
          createdBy: (
            <CreatorItem
              isActive={!isErrored}
              creator={item?.createdBy}
              creatorName={item?.createdBy?.getName(false, true)}
              value={item?.createdBy?.getName(false, true)}
            />
          ),
          source: <TextItem isActive={!isErrored} isErrored={isErrored} text={item?.source} />,
          dateResulted: (
            <TextItem
              isActive={!isErrored}
              isErrored={isErrored}
              text={item?.readingDate ? moment(item?.readingDate).format('MM/DD/YYYY') : '-'}
              value={item?.readingDate}
            />
          ),
          dateCreated: (
            <TextItem
              isActive={!isErrored}
              isErrored={isErrored}
              text={item?.createdAt ? moment(item?.createdAt).format('MM/DD/YYYY') : '-'}
              value={item?.createdAt}
            />
          ),
          actions: (
            <ActionsButton
              id={item.id}
              disabled={isViewOnly(actions)}
              options={_.compact([
                item.patientReported && {
                  id: 'view-trended-results',
                  text: 'View Trended Results',
                  iconSrc: Icons.overviewIcon,
                  onClick: async () => showTrendedResultsPopup(item.typeId),
                },
                !item.patientReported && {
                  id: 'view-results-details',
                  text: 'View Results Details',
                  iconSrc: Icons.overviewIcon,
                  onClick: async () => showResultsDetailsPopup(item.id),
                },
                actions?.editLabResult &&
                  item.patientReported && {
                    id: 'edit-result',
                    text: 'Edit Result',
                    iconSrc: Icons.boldGearIcon,
                    onClick: async () => showLabResultPopup(item.id),
                  },
                actions?.deleteLabResult &&
                  item.patientReported && {
                    id: 'remove-result',
                    text: 'Remove Result',
                    iconSrc: Icons.boldCloseIcon,
                    onClick: async () =>
                      dispatch(
                        openModal(ModalType.REMOVE_LAB_RESULT, {
                          patientId: patient.id,
                          callback: () =>
                            dispatch(
                              openModal(ModalType.WARNING, {
                                message: getDeleteMessage(item),
                                type: 'error',
                                title: 'Delete Vitals and Lab Results Entry?',
                                onSubmit: () =>
                                  dispatch(
                                    deleteLabReading(item.id, {
                                      successBlock: () =>
                                        deleteOrmItem(PatientLabReading.modelName, item.id),
                                    }),
                                  ),
                              }),
                            ),
                        }),
                      ),
                  },
                actions?.deleteLabResult &&
                  !isErrored && {
                    id: 'mark-as-error',
                    text: 'Mark as Error',
                    iconSrc: Icons.boldCloseIcon,
                    onClick: async () =>
                      dispatch(
                        updateStatus(
                          getStatusChangeIds(item, labResults),
                          LabReadingStatus.Inactive,
                        ),
                      ),
                  },
                actions?.deleteLabResult &&
                  isErrored && {
                    id: 'unmark-as-error',
                    text: 'Unmark as Error',
                    iconSrc: Icons.plusIcon,
                    onClick: async () =>
                      dispatch(
                        updateStatus(getStatusChangeIds(item, labResults), LabReadingStatus.Active),
                      ),
                  },
              ])}
            />
          ),
        };
      }),
    );
  }, [groupedResults]);

  return (
    <BaseTable
      headers={headers}
      data={data}
      name="vitals-labs"
      classNames={[styles.table, 'p-b-10']}
      emptyText={_.isEmpty(labResults) ? 'No lab results' : ''}
      emptyClassNames={[styles.empty]}
      initialSortColumn={{
        column: 'dateResulted',
        label: 'Date Resulted',
        width: '16%',
        sortBy: 'value',
      }}
    />
  );
};

ListTab.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  labResults: PropTypes.arrayOf(PropTypes.exact(PatientLabReading.schema)),
};

export default ListTab;
