export const KnownPlansTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
  ALL: {
    text: 'All',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: KnownPlansTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: KnownPlansTabs.NOT_ACTIVE.text,
    count: 0,
  },
  ALL: {
    text: KnownPlansTabs.ALL.text,
    count: 0,
  },
});
