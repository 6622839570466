import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CircleLoader } from 'common-src/components/base';
import { getFeatureFlags, updateFeatureFlagApiRequest } from 'common-src/models/FeatureFlag';
import { Colors } from 'common-src/styles';

import useCustomSelector from 'src/hooks/useCustomSelector';

import FeatureFlagItem from './FeatureFlagItem';
import styles from './FeatureFlagsScreen.module.scss';

const FeatureFlagsScreen = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const featureFlags = useCustomSelector((state) => getFeatureFlags(state));

  return (
    <div className={[styles.container, 'flex-column'].join(' ')}>
      <header className={[styles.headerContainer, 'vertically-centered'].join(' ')}>
        <span className={[styles.text, 'font-s-20', 'font-w-700'].join(' ')}>Feature flags</span>
      </header>
      {featureFlags
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((f) => (
          <FeatureFlagItem
            key={f.name}
            featureFlag={f}
            onChangeHandler={(state) => {
              setIsLoading(true);
              dispatch(
                updateFeatureFlagApiRequest(
                  { name: f.name, state: state.value },
                  { successBlock: () => setIsLoading(false) },
                ),
              );
            }}
          />
        ))}
      {isLoading && <CircleLoader color={Colors.avenirBlue} classNames={[styles.progress]} />}
    </div>
  );
};

export default FeatureFlagsScreen;
