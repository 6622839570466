export const EncounterStatus = Object.freeze({
  Planned: {
    id: 1,
    value: 1,
    label: 'Planned',
    textColor: '#ffffff',
    backgroundColor: '#9D97C7',
  },
  Completed: {
    id: 2,
    value: 2,
    label: 'Completed',
    textColor: '#ffffff',
    backgroundColor: '#637768',
  },
  InProgress: {
    id: 3,
    value: 3,
    label: 'In Progress',
    textColor: '#ffffff',
    backgroundColor: '#F1BF72',
  },
  Archived: {
    id: 4,
    value: 4,
    label: 'Archived',
    textColor: '#ffffff',
    backgroundColor: '#637768',
  },
});

export const EncounterType = Object.freeze({
  AllergyAndImmunology: { value: 1, label: 'Allergy and immunology' },
  Cardiology: { value: 2, label: 'Cardiology' },
  Dermatology: { value: 3, label: 'Dermatology' },
  DiagnosticRadiology: { value: 4, label: 'Diagnostic radiology' },
  EmergencyMedicine: { value: 5, label: 'Emergency medicine' },
  Endocrinology: { value: 6, label: 'Endocrinology' },
  FamilyMedicine: { value: 7, label: 'Family medicine' },
  InternalMedicine: { value: 8, label: 'Internal medicine' },
  Nephrology: { value: 9, label: 'Nephrology' },
  Neurology: { value: 10, label: 'Neurology' },
  NuclearMedicine: { value: 11, label: 'Nuclear medicine' },
  ObstetricsAndGynecology: { value: 12, label: 'Obstetrics and gynecology' },
  Oncology: { value: 13, label: 'Oncology' },
  Ophthalmology: { value: 14, label: 'Ophthalmology' },
  PhysicalMedicineAndRehabilitation: { value: 15, label: 'Physical medicine and rehabilitation' },
  Podiatry: { value: 16, label: 'Podiatry' },
  PrimaryCare: { value: 17, label: 'Primary care' },
  Psychiatry: { value: 18, label: 'Psychiatry / Behavioral' },
  Surgery: { value: 19, label: 'Surgery' },
  Urology: { value: 20, label: 'Urology' },
  Other: { value: 21, label: 'Other' },
});
