import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { ColorsNew } from 'common-src/styles';

import {
  CGM_MAX_TARGET_RANGE,
  CGM_MIN_TARGET_RANGE,
  DAILY_PROFILE_POINTS_COUNT,
  DEFAULT_CGM_MAX_VALUE,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';

import styles from './DailyProfileChart.module.scss';

const DailyProfileChart = ({ data, day }) => {
  const lineData = {
    datasets: [
      {
        label: '50%',
        data,
        pointRadius: () => {},
        backgroundColor: ColorsNew.darkGreen,
        borderColor: ColorsNew.darkGreen,
        borderWidth: 2,
      },
      {
        label: 'min',
        data: Array(DAILY_PROFILE_POINTS_COUNT).fill(CGM_MIN_TARGET_RANGE),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderColor: 'transparent',
        datalabels: { display: false },
      },
      {
        label: 'max',
        data: Array(DAILY_PROFILE_POINTS_COUNT).fill(CGM_MAX_TARGET_RANGE),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        backgroundColor: 'rgba(241, 191, 114, 0.2)',
        borderColor: 'transparent',
        datalabels: { display: false },
        fill: '-1',
        order: '-1',
      },
    ],
    labels: Array(DAILY_PROFILE_POINTS_COUNT).fill(0),
  };

  const lineOptions = {
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    hover: { mode: null },
    animation: { duration: 0 },
    tension: 0.5,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          color: (content) => (content.index % 6 === 0 ? 'rgba(91, 91, 91, 0.2)' : ''),
        },
        ticks: { display: false },
      },
      y: {
        display: false,
        grid: { display: false },
        ticks: { display: false },
        min: 0,
        max: DEFAULT_CGM_MAX_VALUE,
      },
    },
  };

  const renderChart = () => {
    if (_.isEmpty(data)) {
      return null;
    }

    return (
      <div className={styles.chart}>
        <Line data={lineData} options={lineOptions} />
      </div>
    );
  };

  return (
    <div className={[styles.container, 'primary-border'].join(' ')}>
      <span className={[styles.day, 'font-w-600'].join(' ')}>{day}</span>
      {renderChart()}
    </div>
  );
};

DailyProfileChart.propTypes = {
  data: PropTypes.array,
  day: PropTypes.number,
};

export default DailyProfileChart;
