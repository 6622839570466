import { getScrollableParent } from 'common-src/utils/domUtils';

const MARGIN_TOP = 10;
const WINDOW_DEVIATION = 70;
const MAX_PAPER_HEIGHT = 450;
const PAPER_MARGIN_BOTTOM = 30;
const SUB_PAPER_WIDTH_DEVIATION = 7;
const SUB_PAPER_MARGIN_TOP = 40;

export const getPaperStyles = (paperRef, buttonRef, useButtonHeight, paperAlignment) => {
  const {
    height: paperHeight,
    right: paperRight,
    width: paperWidth,
  } = paperRef.current.getBoundingClientRect();
  const {
    height: buttonHeight,
    width: buttonWidth,
    bottom: buttonBottom,
    top: buttonTop,
  } = buttonRef.current.getBoundingClientRect();

  const scrollableParent = getScrollableParent(buttonRef.current);

  const styles = {
    marginTop: `${
      useButtonHeight
        ? buttonHeight
        : scrollableParent.scrollTop === 0
          ? MARGIN_TOP
          : MARGIN_TOP - scrollableParent.scrollTop
    }px`,
    maxHeight: `${MAX_PAPER_HEIGHT}px`,
  };

  if (
    window.innerHeight - PAPER_MARGIN_BOTTOM <= buttonBottom + paperHeight &&
    buttonTop - paperHeight > 0
  ) {
    Object.assign(styles, {
      marginTop: `-${
        useButtonHeight
          ? buttonHeight + MARGIN_TOP
          : scrollableParent.scrollTop === 0
            ? paperHeight + buttonHeight + MARGIN_TOP
            : paperHeight + buttonHeight + MARGIN_TOP + scrollableParent.scrollTop
      }px`,
    });
  } else if (
    Math.floor(buttonBottom) + Math.floor(paperHeight) + PAPER_MARGIN_BOTTOM >=
    window.innerHeight
  ) {
    Object.assign(styles, {
      maxHeight: `${window.innerHeight - buttonBottom - PAPER_MARGIN_BOTTOM}px`,
    });
  }

  if (window.innerWidth - WINDOW_DEVIATION < paperRight + paperWidth) {
    Object.assign(styles, { marginLeft: `-${paperWidth - buttonWidth}px` });
  }

  if (paperAlignment === 'right') {
    Object.assign(styles, { left: `${paperRight - paperWidth}px` });
  }

  return styles;
};

export const getSubPaperStyles = (paperRef, customPaperWidth, paperPosition) => {
  if (!paperRef.current)
    return {
      width: `${customPaperWidth}px`,
      marginLeft:
        paperPosition === 'left'
          ? `-${customPaperWidth + SUB_PAPER_WIDTH_DEVIATION}px`
          : `${customPaperWidth + SUB_PAPER_WIDTH_DEVIATION}px`,
      marginTop: `-${SUB_PAPER_MARGIN_TOP}px`,
    };

  const { width } = paperRef.current.getBoundingClientRect();
  const paperWidth = customPaperWidth || width;

  const styles = {
    width: `${paperWidth}px`,
    maxHeight: `${MAX_PAPER_HEIGHT}px`,
    marginLeft:
      paperPosition === 'left'
        ? `-${paperWidth + SUB_PAPER_WIDTH_DEVIATION}px`
        : `${paperWidth + SUB_PAPER_WIDTH_DEVIATION}px`,
    marginTop: `-${SUB_PAPER_MARGIN_TOP}px`,
  };

  return styles;
};
