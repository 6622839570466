import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './MonthItem.module.scss';

const MonthItem = ({ date, fillCircle }) => (
  <div className={[styles.item, 'primary-border-b'].join(' ')}>
    <span className={[styles.circle, fillCircle ? styles.fill : ''].join(' ')} />
    <p className={[styles.month, 'font-w-500'].join(' ')}>{moment(date).format('MMMM')}</p>
  </div>
);

MonthItem.propTypes = {
  date: PropTypes.string,
  fillCircle: PropTypes.bool,
};

export default MonthItem;
