import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientPhoneNumbers = (state, patientId) =>
  allModelsSelector(state, 'PatientPhoneNumber', (pn) => pn.patientId === patientId);

export const getSearchPhoneNumbersByPatient = (state) => {
  const res = {};
  const allPhoneNumbers = allModelsSelector(state, 'PatientPhoneNumber');

  allPhoneNumbers.forEach((pn) => {
    if (!res[pn.patient?.id]) {
      res[pn.patient?.id] = [];
    }
    res[pn.patient?.id].push(pn.phoneNumber);
  });

  return res;
};

export const getPrimaryPatientPhoneNumber = (state, patientId) => {
  const primaryPhoneNumbers = allModelsSelector(
    state,
    'PatientPhoneNumber',
    (pn) => pn.patientId === patientId && pn.isPrimary,
  );

  return primaryPhoneNumbers?.[0] || null;
};
