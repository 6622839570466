import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientInteraction extends Model {
  static modelName = 'PatientInteraction';

  static fields = {
    id: attr(),
    serviceAt: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    note: attr(),
    moduleTypes: attr(),
    reasons: attr(),
    isDraft: attr(),
    savedAt: attr(),
    parentInteractionId: attr(),
    primaryModuleTypeId: attr(),
    draftData: attr(),
    duration: attr(),
    type: attr(),
    reason: attr(),
    status: attr(),
    startedAt: attr(),
    endedAt: attr(),
    hadLiveInteraction: attr(),
    isBillable: attr(),
    billingCategory: attr(),
    updatedBy: fk('Client', 'interactions'),
    clientId: fk({
      to: 'Client',
      as: 'client',
      relatedName: 'handledInteractions',
    }),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'interactions',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      serviceAt: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      note: PropTypes.string,
      moduleTypes: PropTypes.arrayOf(PropTypes.number),
      reasons: PropTypes.arrayOf(PropTypes.string),
      isDraft: PropTypes.bool,
      savedAt: PropTypes.string,
      parentInteractionId: PropTypes.string,
      primaryModuleTypeId: PropTypes.number,
      draftData: PropTypes.shape({
        docs: PropTypes.array,
        timeLogs: PropTypes.array,
        sdoh: PropTypes.object,
      }),
      duration: PropTypes.number,
      type: PropTypes.number,
      reason: PropTypes.number,
      status: PropTypes.number,
      startedAt: PropTypes.string,
      endedAt: PropTypes.string,
      hadLiveInteraction: PropTypes.bool,
      isBillable: PropTypes.bool,
      billingCategory: PropTypes.string,
      updatedBy: PropTypes.number,
      clientId: PropTypes.number,
      patientId: PropTypes.number,
    }),
    clientId: PropTypes.number,
    patientId: PropTypes.number,
  };

  static rawSchema = {
    id: PropTypes.string,
    serviceAt: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    note: PropTypes.string,
    moduleTypes: PropTypes.arrayOf(PropTypes.number),
    reasons: PropTypes.arrayOf(PropTypes.string),
    isDraft: PropTypes.bool,
    savedAt: PropTypes.string,
    parentInteractionId: PropTypes.string,
    primaryModuleTypeId: PropTypes.number,
    draftData: PropTypes.shape({
      docs: PropTypes.array,
      timeLogs: PropTypes.array,
      sdoh: PropTypes.object,
    }),
    duration: PropTypes.number,
    type: PropTypes.number,
    reason: PropTypes.number,
    status: PropTypes.number,
    startedAt: PropTypes.string,
    endedAt: PropTypes.string,
    hadLiveInteraction: PropTypes.bool,
    isBillable: PropTypes.bool,
    billingCategory: PropTypes.string,
    updatedBy: PropTypes.number,
    clientId: PropTypes.number,
    patientId: PropTypes.number,
    oncehubBookingId: PropTypes.string,
    attachedReadings: PropTypes.array,
    effectiveDate: PropTypes.string,
  };
}

export default PatientInteraction;
