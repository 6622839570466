/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';

import useTime from 'src/hooks/useTime';

import styles from './NavItem.module.scss';

const NavItem = ({
  timeZone,
  classNames,
  onClick,
  iconSrc,
  onIconClick,
  title = '-',
  value = '-',
  withSeparator = false,
}) => {
  const { time } = timeZone ? useTime(5000, 'h:mm A z', timeZone) : {};
  const idPrefix = title.split(' ').join('-').toLowerCase();
  const containerClassNames = ['flex-column', 'm-r-20'];

  if (classNames) {
    containerClassNames.push(...classNames);
  }

  const getValue = () => {
    if (timeZone) {
      return value ? `${value} (${time})` : 'N/A';
    }

    return value || 'N/A';
  };

  return (
    <>
      <div className={containerClassNames.join(' ')} onClick={() => onClick?.()}>
        <span
          id={`${idPrefix}-title`}
          className={[styles.title, 'font-w-500', 'm-b-2', 'font-s-10'].join(' ')}
        >
          {title}
        </span>
        <span
          id={`${idPrefix}-value`}
          className={[styles.value, 'font-w-500', 'font-s-14'].join(' ')}
        >
          {getValue()}
        </span>
      </div>
      {iconSrc && (
        <img
          src={iconSrc}
          alt="icon-item"
          className="m-r-20"
          role="presentation"
          onClick={() => onIconClick?.()}
          style={{ cursor: onIconClick ? 'pointer' : 'initial' }}
        />
      )}
      {withSeparator && (
        <div className={[styles.separator, 'm-r-20', 'primary-border'].join(' ')} />
      )}
    </>
  );
};

NavItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func,
  timeZone: PropTypes.string,
  withSeparator: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  iconSrc: PropTypes.string,
  onIconClick: PropTypes.func,
};

export default NavItem;
