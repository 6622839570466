import { roles } from 'common-src/models/Client';

export const DispositionOptions = Object.freeze({
  [roles.MXSpecialist.id]: {
    isDismissable: false,
    options: [
      'Connected & Completed',
      'Connected & Unable to Complete',
      'Left Voicemail',
      'Not connected & Unable to Leave Voicemail',
    ],
  },
  [roles.RN.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
  [roles.CDE.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
  [roles.Physician.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
  [roles.AccountabilityCoach.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
  [roles.RD.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
  [roles.NP.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
  [roles.SocialWorker.id]: {
    isDismissable: true,
    options: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
  },
});
