import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPreAuthsRequest = (query, params) =>
  restRequest({
    uri: 'preAuths',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addPreAuth = (body, params) =>
  restRequest({
    uri: 'preAuths',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePreAuth = (id, body, params) =>
  restRequest({
    uri: `preAuths/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });
