import _ from 'lodash';

import { validatePatientCgmOrder } from 'common-src/models/PatientCgmOrder';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientCgmOrder(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => {
  const body = {
    product: data.product || null,
    supplier: data.supplier || null,
    status: data.status || null,
    sentAt: data.sentAt || null,
    shippedAt: data.shippedAt || null,
    orderedAt: data.orderedAt || null,
    notes: data.notes || '',
  };

  if (patientId) {
    Object.assign(body, { patientId });
  }

  return body;
};

export const extractPatientCgmOrder = (cgmOrder = {}) => ({
  product: cgmOrder.product || null,
  supplier: cgmOrder.supplier || null,
  status: cgmOrder.status || null,
  orderedAt: cgmOrder.orderedAt || null,
  sentAt: cgmOrder.sentAt || null,
  shippedAt: cgmOrder.shippedAt || null,
  notes: cgmOrder.notes || '',
});
