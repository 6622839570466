import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Menu } from 'common-src/components/base';
import Config from 'common-src/config';
import { apiRequest } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import {
  getDestinationMessage,
  getPrescriptionConfig,
  PrescriptionProviderOptions,
} from 'common-src/models/PatientCoverage';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from '../Menu.module.scss';

const PrescriptionProviderMenu = ({ patient }) => {
  const dispatch = useDispatch();

  const { preferredType, destination, payorId, insuranceType } = useCustomSelector((state) =>
    getPrescriptionConfig(state, patient?.id),
  );

  const openScriptsureIframe = () =>
    apiRequest({ endpoint: 'clients/scriptsure/login' })
      .then((res) => res.json())
      .then((data) => {
        if (!data?.session_token) return;

        let url = `${Config.SCRIPTSURE_URL}?sessiontoken=${data.session_token}`;
        if (patient?.scriptsureId) {
          url += `#/chart/${patient.scriptsureId}/drug-history`;
        }

        window.open(url, '', 'width=1200,height=800,left=300,top=100');
      })
      .catch(() => {
        // do nothing on error
      });

  const showCGMOrderPopup = () =>
    dispatch(openModal(ModalType.CGM_ORDER, { patientId: patient.id }));

  const showNoticePopup = () =>
    dispatch(
      openModal(ModalType.NOTICE, {
        patientId: patient.id,
        callback: () =>
          dispatch(
            openModal(ModalType.WARNING, {
              message: getDestinationMessage(insuranceType, payorId, destination),
              title: 'Notice',
              onSubmit: () => openScriptsureIframe(),
              submitButtonText: 'Continue',
            }),
          ),
      }),
    );

  const handleOptionSelect = (option) => {
    switch (option.value) {
      case PrescriptionProviderOptions.ePrescription.value:
        showNoticePopup();
        break;
      case PrescriptionProviderOptions.faxWrittenOrder.value:
        showCGMOrderPopup();
        break;
      default:
        break;
    }
  };

  const options = Object.values(PrescriptionProviderOptions).map((x) => ({
    ...x,
    label: (
      <div
        id={`link-${x.label}`}
        className="p-12"
        onClick={() => handleOptionSelect(x)}
        role="presentation"
      >
        <span className={[styles.link, 'font-s-12', 'font-w-500'].join(' ')}>{x.label}</span>
        {preferredType === x.value && (
          <span className={[styles.preferred, 'font-s-10', 'font-w-700', 'm-l-20'].join(' ')}>
            preferred
          </span>
        )}
      </div>
    ),
  }));

  return (
    <Menu
      button={
        <img
          id="prescription-provider-button"
          className={styles.actionButton}
          src={Icons.pillIcon}
          alt="pill-icon"
        />
      }
      customOptions={options}
    />
  );
};

PrescriptionProviderMenu.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default PrescriptionProviderMenu;
