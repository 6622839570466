export const validatePatientMedication = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'med':
      if (!value) {
        errMsg = 'Medication should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
