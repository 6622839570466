import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, List } from 'common-src/components/base';
import { getActiveCuriePatientTasks } from 'common-src/models/PatientTask';
import { Colors, ColorsNew } from 'common-src/styles';

import Icons from 'src/assets/Icons';
import { getIsCurieAISelected, saveIsCurieAISelected } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

import CurieAICard from './CurieAICard';
import styles from './CurieAISidebar.module.scss';

const CurieAISidebar = ({ patientId }) => {
  const dispatch = useDispatch();

  const isCurieAISelected = useCustomSelector((state) => getIsCurieAISelected(state, patientId));
  const activeCurieTasks = useCustomSelector((state) =>
    getActiveCuriePatientTasks(state, patientId),
  );

  return (
    <aside className={[styles.aside, isCurieAISelected ? styles.extended : ''].join(' ')}>
      <header className={[styles.header, 'flex-row', 'primary-border-b'].join(' ')}>
        <Button
          id="collapse-button"
          classNames={[styles.collapseButton]}
          iconSrc={Icons.collapseIcon}
          onClick={() => dispatch(saveIsCurieAISelected(patientId, false))}
          backgroundColor="transparent"
          textColor={ColorsNew.darkGreen}
          borderColor={Colors.lightGray}
        />
        <div className={['flex-row', 'gap-5', 'vertically-centered'].join(' ')}>
          <img className={styles.icon} src={Icons.overviewIcon} alt="overview-icon" />
          <span className={['font-s-14', 'font-w-500'].join(' ')}>Curie AI</span>
        </div>
      </header>
      <List
        classNames={[styles.list]}
        items={activeCurieTasks}
        renderItem={(task) => <CurieAICard task={task} patientId={patientId} />}
        emptyText="No active CurieAI tasks"
        emptyTextClassNames={[styles.emptyText]}
      />
    </aside>
  );
};

CurieAISidebar.propTypes = {
  patientId: PropTypes.number,
};

export default CurieAISidebar;
