import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  label,
  checked,
  onChange,
  description,
  id = '',
  classNames = [],
  disabled = false,
}) => {
  const ref = useRef(null);

  const containerClasses = [styles.container, 'vertically-centered', ...classNames];
  const labelClasses = [styles.label, 'font-w-500', 'font-s-14'];
  const checkBoxClasses = [styles.checkbox];

  if (checked) {
    checkBoxClasses.push(styles.checked);
  }

  if (disabled) {
    containerClasses.push(styles.disabled);
    checkBoxClasses.push(styles.disabled);
    labelClasses.push(styles.disabled);
  }

  const onChangeHandler = (e) => {
    if (disabled) return;

    e.stopPropagation();
    onChange(!checked);
    ref.current.checked = !checked;
  };

  const renderDescription = () => {
    if (!description) return null;

    return (
      <span className={[styles.description, 'font-w-400', 'font-s-12'].join(' ')}>
        {description}
      </span>
    );
  };

  return (
    <>
      <div className={containerClasses.join(' ')}>
        <input
          ref={ref}
          type="checkbox"
          className={styles.input}
          defaultChecked={checked}
          disabled={disabled}
        />
        <span
          id={id}
          className={checkBoxClasses.join(' ')}
          role="presentation"
          onClick={(e) => onChangeHandler(e)}
        >
          <small className={[styles.check, 'm-t-2'].join(' ')} />
        </span>
        <div
          className={labelClasses.join(' ')}
          role="presentation"
          onClick={(e) => onChangeHandler(e)}
        >
          {label}
        </div>
      </div>
      {renderDescription()}
    </>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
};

export default Checkbox;
