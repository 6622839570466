import { CACHE_PATIENT, SET_CLIENTS_CACHED, SET_ORGANIZATIONS_CACHED } from './actionTypes';

export const setOrganizationsCached = (isCached) => ({
  type: SET_ORGANIZATIONS_CACHED,
  payload: { isCached },
});

export const setClientsCached = (isCached) => ({
  type: SET_CLIENTS_CACHED,
  payload: { isCached },
});

export const cachePatient = (patientId, tab) => ({
  type: CACHE_PATIENT,
  payload: { patientId, tab },
});
