export const SurgicalHistoryTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: SurgicalHistoryTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: SurgicalHistoryTabs.NOT_ACTIVE.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'procedure', label: 'Procedure Name', width: '27%', sortBy: 'title' },
  { column: 'comments', label: 'Comments', width: '20%', sortBy: 'value' },
  { column: 'dateOfSurgery', label: 'Procedure Date', width: '17%', sortBy: 'value' },
  { column: 'createdAt', label: 'Date Added', width: '13%', sortBy: 'value' },
  { column: 'actions', label: 'Actions', width: '23%' },
];
