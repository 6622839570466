import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import { AlertType } from 'src/components/base/AlertGroup';

import { DismissTime } from '../constants';
import styles from './Alert.module.scss';

const Alert = ({ type, title, message, isAutoDismiss, onClose, classNames = [] }) => {
  const alertClasses = [styles.alert, 'primary-border', ...classNames];
  let iconSrc = '';

  switch (type) {
    case AlertType.Error:
      alertClasses.push(styles.error);
      iconSrc = CommonIcons.alertErrorIcon;
      break;
    case AlertType.Success:
      alertClasses.push(styles.success);
      iconSrc = CommonIcons.alertSuccessIcon;
      break;
    default:
      break;
  }

  const getMessage = () => {
    if (typeof message === 'string') {
      return message;
    }

    if (typeof message === 'object') {
      const detail = message?.body?.detail;
      if (typeof detail === 'string') {
        return detail;
      }

      if (Array.isArray(detail)) {
        return detail[0]?.msg || '';
      }
    }

    if (Array.isArray(message)) {
      const err = message[0];
      if (err) {
        return `${err.type?.split('.')?.[0]}: ${err.msg}, (${err.loc?.[1]})`;
      }
    }
  };

  useEffect(() => {
    if (isAutoDismiss) {
      setTimeout(() => {
        onClose();
      }, DismissTime[type]);
    }
  }, [isAutoDismiss]);

  return (
    <div id={`alert-${type}`} className={alertClasses.join(' ')}>
      <div className={[styles.wrapper, 'gap-10'].join(' ')}>
        <img src={iconSrc} alt="alert-type-icon" />
        <p className={styles.textContainer}>
          {title && <span className={styles.title}>{title}</span>}
          <span className={styles.message}>{getMessage()}</span>
        </p>
        <img
          className={styles.closeBtn}
          src={CommonIcons.whiteCloseIcon}
          alt="close-btn"
          role="presentation"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['Error', 'Success']).isRequired,
  title: PropTypes.string,
  message: PropTypes.any.isRequired,
  isAutoDismiss: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default Alert;
