import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentUserId } from 'common-src/features/auth';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { getClientRequest } from 'common-src/models/Client';
import { getFeatureFlagsApiRequest } from 'common-src/models/FeatureFlag';
import { getSettingsRequest } from 'common-src/models/Setting';

import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';

const SETTINGS_POLLING_FREQUENCY = 5 * 60 * 1000;

const useSettingsPolling = () => {
  const dispatch = useDispatch();

  const clientId = useCustomSelector((state) => getCurrentUserId(state));

  const getAppSettings = (clientId) => {
    const restRequests = [getFeatureFlagsApiRequest(), getSettingsRequest()];
    if (clientId) {
      restRequests.push(getClientRequest(clientId));
    }

    dispatch(restRequestMultiple({ restRequests }));
  };

  useInterval(() => {
    getAppSettings(clientId);
  }, SETTINGS_POLLING_FREQUENCY);

  useEffect(() => {
    getAppSettings();
  }, []);
};

export default useSettingsPolling;
