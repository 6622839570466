import { attr, fk, Model } from 'redux-orm';

class Setting extends Model {
  static modelName = 'Setting';

  static fields = {
    id: attr(),
    name: attr(),
    value: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'settingUpdater'),
  };
}

export default Setting;
