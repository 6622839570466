import _ from 'lodash';

import { allModelsSelector } from 'common-src/utils/selectorUtils';

import { activeStatusesIds } from './constants';

export const getPatientSdoh = (state, patientId) =>
  allModelsSelector(state, 'PatientSdoh', (pmp) => pmp.patientId === patientId);

export const getActivePatientSdohNames = (state, patientId) => {
  const patientSdoh = getPatientSdoh(state, patientId);
  const activeSdoh = patientSdoh.filter((sdoh) => activeStatusesIds.includes(sdoh.status));

  return _.uniq(activeSdoh.map((sdoh) => sdoh?.sdohCodeId?.name));
};
