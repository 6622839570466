import PropTypes from 'prop-types';
import React from 'react';

import { Accordion } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';

import { AssignedProviders } from './forms';
import styles from './Team.module.scss';

const Team = ({ patient }) => (
  <article className={[styles.article, 'relative'].join(' ')}>
    <div className={[styles.wrapper, 'flex-column', 'gap-16'].join(' ')}>
      <Accordion
        title="Assigned providers"
        isDefaultExpanded
        content={<AssignedProviders patient={patient} />}
      />
    </div>
  </article>
);

Team.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Team;
