import _ from 'lodash';
import moment from 'moment-timezone';

import { calculateBMI } from 'common-src/models/PatientVital';

import {
  ALL_LAB_READING_TYPES,
  LabReadingSort,
  LabReadingStatus,
  VitalsLabsTypes,
} from './constants';

export const getLabReadingLabel = (typeId) =>
  Object.values(VitalsLabsTypes).find((t) => t.value === typeId)?.label || 'Unknown';

export const getLabReadingUnit = (typeId) =>
  Object.values(VitalsLabsTypes).find((t) => t.value === typeId)?.unit || '';

export const getLabReadingCategory = (typeId) =>
  Object.values(VitalsLabsTypes).find((t) => t.value === typeId)?.category || '';

export const getLabReadingStudy = (labReading) => {
  if (!labReading) return '';

  const type = Object.values(VitalsLabsTypes).find((t) => t.value === labReading?.typeId);
  if (!type) return '';

  return labReading?.patientReported ? type.label : type.category;
};

export const getFilteredLabResults = (labResults, filters) => {
  let filteredLabResults;

  switch (filters.sort.value) {
    case LabReadingSort.OLDEST_FIRST: {
      filteredLabResults = labResults.sort(
        (a, b) =>
          new Date(a.readingDate) - new Date(b.readingDate) ||
          new Date(a.updatedAt) - new Date(b.updatedAt),
      );
      break;
    }
    case LabReadingSort.NEWEST_FIRST:
    default: {
      filteredLabResults = labResults.sort(
        (a, b) =>
          new Date(b.readingDate) - new Date(a.readingDate) ||
          new Date(b.updatedAt) - new Date(a.updatedAt),
      );
      break;
    }
  }

  if (filters.startingDate && filters.startingDate !== 'Invalid date') {
    filteredLabResults = filteredLabResults.filter((labResult) =>
      moment(moment(labResult.readingDate).format('YYYY-MM-DD')).isSameOrAfter(
        filters.startingDate,
      ),
    );
  }

  if (filters.endingDate && filters.endingDate !== 'Invalid date') {
    filteredLabResults = filteredLabResults.filter((labResult) =>
      moment(moment(labResult.readingDate).format('YYYY-MM-DD')).isSameOrBefore(filters.endingDate),
    );
  }

  const labResultType = filters.type.value;
  if (!!labResultType && labResultType !== ALL_LAB_READING_TYPES.id) {
    filteredLabResults = filteredLabResults.filter(
      (labResult) => labResult.typeId === labResultType,
    );
  }

  return filteredLabResults || [];
};

export const getFilteredVitalsAndLabs = (labResults, filters, excludedTypes = []) => {
  const filterFunctions = [];

  // start date
  if (filters.startDate) {
    filterFunctions.push((result) =>
      moment(new Date(result.readingDate)).isSameOrAfter(
        moment(new Date(filters.startDate)).startOf('day'),
      ),
    );
  }

  // end date
  if (filters.endDate) {
    filterFunctions.push((result) =>
      moment(new Date(result.readingDate)).isSameOrBefore(
        moment(new Date(filters.endDate)).endOf('day'),
      ),
    );
  }

  // types
  if (!_.isEmpty(filters.types)) {
    const typeIds = [];
    filters.types.forEach((type) => {
      typeIds.push(...type.value);
    });

    filterFunctions.push((result) => typeIds.includes(result.typeId));
  }

  // excluded types
  if (!_.isEmpty(excludedTypes)) {
    filterFunctions.push((result) => !excludedTypes.includes(result.typeId));
  }

  // reported
  if (filters.hideReported) {
    filterFunctions.push((result) => !result.patientReported);
  }

  return _.isEmpty(filterFunctions)
    ? labResults
    : _.filter(labResults, _.overEvery(filterFunctions));
};

export const getReadingValue = (reading) => (reading ? reading.value : 'N/A');

export const getReadingPeriod = (reading) =>
  reading && `${moment(reading.date).format('MM/DD/YYYY')}`;

export const getLastLabResults = (labResults) => {
  const data = {};

  const heightResults = labResults.filter((r) => r.typeId === VitalsLabsTypes.Height.value);
  const lastHeightResult = heightResults[0];
  const prevHeightResult = heightResults[0];

  const weightResults = labResults.filter((r) => r.typeId === VitalsLabsTypes.Weight.value);
  const lastWeightResult = weightResults[0];
  const prevWeightResult = weightResults[1];

  const hbA1cResults = labResults.filter((r) => r.typeId === VitalsLabsTypes.HbA1c.value);
  const lastHbA1c = hbA1cResults[0];
  const prevHbA1c = hbA1cResults[1];

  if (lastHeightResult) {
    Object.assign(data, {
      lastHeightResult: {
        value: lastHeightResult.readingValue,
        date: lastHeightResult.readingDate,
        updatedBy: lastHeightResult?.ref?.updatedBy,
        source: lastHeightResult.source,
      },
    });
  }

  if (lastWeightResult) {
    Object.assign(data, {
      lastWeightResult: {
        value: lastWeightResult.readingValue,
        date: lastWeightResult.readingDate,
        updatedBy: lastWeightResult?.ref?.updatedBy,
        source: lastWeightResult.source,
      },
    });

    if (prevWeightResult) {
      const diff =
        Math.round((lastWeightResult.readingValue - prevWeightResult.readingValue) * 10) / 10;

      Object.assign(data, {
        weightPriorDiff: `(${diff > 0 ? `+${diff}` : diff}lbs vs prior)`,
      });
    }
  }

  if (lastHbA1c) {
    Object.assign(data, {
      lastHbA1c: {
        value: lastHbA1c.readingValue,
        date: lastHbA1c.readingDate,
        updatedBy: lastHbA1c?.ref?.updatedBy,
        source: lastHbA1c.source,
      },
    });

    if (prevHbA1c) {
      const diff = Math.round((lastHbA1c.readingValue - prevHbA1c.readingValue) * 10) / 10;

      Object.assign(data, {
        hbA1cPriorDiff: `(${diff > 0 ? `+${diff}` : diff} vs prior)`,
      });
    }
  }

  if (lastHeightResult && lastWeightResult) {
    Object.assign(data, {
      estimatedBMI: {
        value: calculateBMI(lastWeightResult.readingValue, lastHeightResult.readingValue),
      },
    });
  }

  if (data.estimatedBMI && prevHeightResult && prevWeightResult) {
    const prevEstimatedBMI = calculateBMI(
      prevWeightResult.readingValue,
      prevHeightResult.readingValue,
    );
    const diff = Math.round((Number(data.estimatedBMI.value) - Number(prevEstimatedBMI)) * 10) / 10;

    Object.assign(data, {
      estimatedBMIPriorDiff: `(${diff > 0 ? `+${diff}` : diff} since vs prior)`,
    });
  }

  return data;
};

export const getLabResultsByCategoryAndDate = (labResults, category, readingDate) => {
  if (_.isEmpty(labResults) || !category || !readingDate) return [];

  return labResults.filter(
    (reading) =>
      getLabReadingCategory(reading.typeId) === category && reading.readingDate === readingDate,
  );
};

export const getActiveLabResults = (labResults) => {
  if (_.isEmpty(labResults)) return [];

  return labResults.filter((result) => result?.status !== LabReadingStatus.Inactive);
};
