import moment from 'moment-timezone';

import { activeStatusesIds, Sources } from 'common-src/models/PatientMedication';

import { MedicationsTabs } from './constants';

export const getTabInfo = (medications = [], filters) => {
  const activeMedications = [];
  const inactiveMedications = [];

  const filteredMedications = !filters.showOnlyImported
    ? medications
    : medications.filter((medication) => medication.source !== Sources[0].value);
  const sortedMedications = [...filteredMedications].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );

  sortedMedications.forEach((medication) => {
    if (
      (medication.activeTill && moment(medication.activeTill).isBefore(moment.now())) ||
      !activeStatusesIds.includes(medication?.status)
    ) {
      inactiveMedications.push(medication);
      return;
    }

    activeMedications.push(medication);
  });

  const tabInfo = {
    ACTIVE: {
      text: MedicationsTabs.ACTIVE.text,
      count: activeMedications.length,
    },
    NOT_ACTIVE: {
      text: MedicationsTabs.NOT_ACTIVE.text,
      count: inactiveMedications.length,
    },
    ALL: {
      text: MedicationsTabs.ALL.text,
      count: sortedMedications.length,
    },
  };

  return {
    tabInfo,
    activeMedications,
    inactiveMedications,
    allMedications: sortedMedications,
  };
};
