import PropTypes from 'prop-types';
import React from 'react';

import { DailyProfileChart } from 'src/pages/PatientDetails/fragments/Biometrics/charts';
import {
  CGMPeriodRange,
  DAILY_PROFILES_TABLE_COLUMNS,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';

import styles from './DailyProfilesTable.module.scss';
import { generateDailyProfileDates, hasEmptyChartData } from './helpers';

const DailyProfilesTable = ({ selectedPeriod, data }) => {
  if (hasEmptyChartData(data)) return null;

  const renderHeader = () => (
    <div className={[styles.header, 'p-r-50', 'gap-14'].join(' ')}>
      {DAILY_PROFILES_TABLE_COLUMNS.map((column) => (
        <p key={column} className={[styles.column, 'font-w-500', 'font-s-14'].join(' ')}>
          {column}
        </p>
      ))}
    </div>
  );

  const renderBody = () => {
    const { averages, dates } = data;

    return (
      <div className={[styles.body, 'p-r-50', 'm-b-14', 'gap-14'].join(' ')}>
        {generateDailyProfileDates(dates).map((date) => {
          const index = dates.findIndex((d) => d === date);

          return (
            <DailyProfileChart key={date} day={Number(date.split('-')[2])} data={averages[index]} />
          );
        })}
      </div>
    );
  };

  // Do not display daily profiles for period less than 7 days
  if (selectedPeriod < CGMPeriodRange[2].value) {
    return null;
  }

  return (
    <div className="p-l-30 m-t-32">
      <h2 className={[styles.title, 'p-b-5', 'primary-border-b', 'font-s-22'].join(' ')}>
        Daily Profiles
      </h2>
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

DailyProfilesTable.propTypes = {
  data: PropTypes.object,
  selectedPeriod: PropTypes.number,
};

export default DailyProfilesTable;
