export const SmartGoalTabs = {
  ACTIVE: {
    text: 'Active',
  },
  PAST_GOALS: {
    text: 'Past Goals',
  },
};

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: SmartGoalTabs.ACTIVE.text,
    count: 0,
  },
  PAST_GOALS: {
    text: SmartGoalTabs.PAST_GOALS.text,
    count: 0,
  },
});
