// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CGMHeader-module__header___hA1Ym {
  width: 90%;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Biometrics/headers/CGMHeader/CGMHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `CGMHeader-module__header___hA1Ym`
};
module.exports = ___CSS_LOADER_EXPORT___;
