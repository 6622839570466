import _ from 'lodash';

import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

import {
  Payors,
  PayorType,
  PrescriptionDestinations,
  PrescriptionProviderOptions,
} from './constants';
import { getPayor, getPlanCategory } from './helpers';

export const getPatientCoverages = (state, patientId) =>
  allModelsSelector(state, 'PatientCoverage', (coverage) => coverage.patientId === patientId);

export const getPrescriptionConfig = (state, patientId) => {
  const patientInsurances = getPatientCoverages(state, patientId).sort((a, b) => {
    const aHierarchy = a.coverageHierarchy?.hierarchy || 0;
    const bHierarchy = b.coverageHierarchy?.hierarchy || 0;
    return aHierarchy - bHierarchy;
  });
  const patient = singleModelSelector(state, 'Patient', patientId);

  if (_.isEmpty(patientInsurances) || !patient?.address)
    return {
      preferredType: PrescriptionProviderOptions.faxWrittenOrder.value,
      destination: _.isEmpty(patientInsurances)
        ? PrescriptionDestinations.FaxWrittenOrder
        : PrescriptionDestinations.LocalPharmacy,
      payorId: getPayor(patientInsurances[0]),
      insuranceType: getPlanCategory(patientInsurances?.[0]?.payorType),
    };

  const { address } = patient;
  const validInsurances = patientInsurances.filter((ins) => ins.coverageHierarchy?.hierarchy > 0);

  if (_.isEmpty(validInsurances))
    return {
      preferredType: PrescriptionProviderOptions.faxWrittenOrder.value,
      destination: PrescriptionDestinations.FaxWrittenOrder,
      payorId: 'Unknown',
      insuranceType: 'Unknown',
    };

  const insurance = validInsurances[0];

  switch (insurance.payorType) {
    case PayorType.Medicare.value:
      return {
        preferredType: PrescriptionProviderOptions.faxWrittenOrder.value,
        destination: ['CO', 'TN', 'KY'].includes(address?.state)
          ? PrescriptionDestinations.AdvancedDiabetesSupply
          : PrescriptionDestinations.Smiles,
        payorId: getPayor(insurance),
        insuranceType: PayorType.Medicare.label,
      };
    case PayorType.MA.value:
      switch (insurance.payorId) {
        case Payors.UnitedHealthcare.value:
          return {
            preferredType: PrescriptionProviderOptions.ePrescription.value,
            destination: PrescriptionDestinations.USMed,
            payorId: Payors.UnitedHealthcare.label,
            insuranceType: PayorType.MA.label,
          };
        case Payors.Humana.value:
          return {
            preferredType: PrescriptionProviderOptions.ePrescription.value,
            destination: PrescriptionDestinations.CCSMedical,
            payorId: Payors.Humana.label,
            insuranceType: PayorType.MA.label,
          };
        default:
          return {
            preferredType: PrescriptionProviderOptions.faxWrittenOrder.value,
            destination: PrescriptionDestinations.AdvancedDiabetesSupply,
            payorId: getPayor(insurance),
            insuranceType: PayorType.MA.label,
          };
      }
    case PayorType.Commercial.value:
      switch (insurance.payorId) {
        case Payors.UnitedHealthcare.value:
          return {
            preferredType: PrescriptionProviderOptions.ePrescription.value,
            destination: PrescriptionDestinations.USMed,
            payorId: Payors.UnitedHealthcare.label,
            insuranceType: PayorType.Commercial.label,
          };
        case Payors.Humana.value:
          return {
            preferredType: PrescriptionProviderOptions.ePrescription.value,
            destination: PrescriptionDestinations.CCSMedical,
            payorId: Payors.Humana.label,
            insuranceType: PayorType.Commercial.label,
          };
        default:
          return {
            preferredType: PrescriptionProviderOptions.ePrescription.value,
            destination: PrescriptionDestinations.LocalPharmacy,
            payorId: getPayor(insurance),
            insuranceType: PayorType.Commercial.label,
          };
      }
    default:
      return {
        preferredType: PrescriptionProviderOptions.faxWrittenOrder.value,
        destination: PrescriptionDestinations.FaxWrittenOrder,
        payorId: getPayor(insurance),
        insuranceType: PayorType.Other.label,
      };
  }
};
