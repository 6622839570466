export const defaultTimeValues = {
  hours: 12,
  minutes: 0,
  period: 'am',
};

export const hoursPM = {
  1: 13,
  2: 14,
  3: 15,
  4: 16,
  5: 17,
  6: 18,
  7: 19,
  8: 20,
  9: 21,
  10: 22,
  11: 23,
  12: 12,
};
