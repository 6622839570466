import _ from 'lodash';

import { booleanFields, textFields } from './constants';

export const getIsButtonEnabled = (formData) =>
  !(
    _.some(_.pick(formData, booleanFields), _.isNil) ||
    _.some(_.pick(formData, textFields), _.isEmpty) ||
    (formData.duration !== 0 && !formData.duration)
  );
