import _ from 'lodash';

import { DiagnosisOptions, DxStatusesConfig, Relation } from './constants';

export const getDxStatusById = (id) => _.find(DxStatusesConfig, (status) => status.id === id);

export const getIcd10CodeDescription = (codesArray, code) =>
  _.find(codesArray, (codeObj) => codeObj.code === code)?.description || '-';

export const getRelationById = (id) =>
  _.find(Object.values(Relation), (relation) => relation.id === id);

export const getDiagnosisOptionByCode = (icd10Code) =>
  _.find(DiagnosisOptions, (option) => option.value === icd10Code);
