export const TabsData = Object.freeze({
  BGM: {
    value: 0,
    text: 'BGM',
  },
  CGM: {
    value: 1,
    text: 'CGM',
  },
});

export const CGMPeriodRange = Object.freeze({
  0: {
    value: -1,
    label: 'Today',
  },
  1: {
    value: -2,
    label: 'Yesterday',
  },
  2: {
    value: 7,
    label: '7 days',
  },
  3: {
    value: 14,
    label: '14 days',
  },
  4: {
    value: 28,
    label: '28 days',
  },
});

export const CGM_MIN_TARGET_RANGE = 70;

export const CGM_MAX_TARGET_RANGE = 180;

export const DEFAULT_CGM_MAX_VALUE = 400;

export const CGM_POLLING_FREQUENCY = 60000;

export const DAILY_PROFILE_POINTS_COUNT = 24;

export const DAILY_PROFILES_TABLE_COLUMNS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const BGMPeriodRange = Object.freeze({
  0: {
    value: 2,
    label: '2 days',
  },
  1: {
    value: 7,
    label: '7 days',
  },
  2: {
    value: 30,
    label: '30 days',
  },
  3: {
    value: 90,
    label: '90 days',
  },
});

export const TimeOfDay = Object.freeze({
  MORNING: 'Morning',
  AFTERNOON: 'Afternoon',
  EVENING: 'Evening',
  NIGHT: 'Night',
  ALL: 'All',
});

export const dayTimePeriods = [
  { value: TimeOfDay.ALL, label: 'All Times of Day' },
  { value: TimeOfDay.MORNING, label: 'Morning' },
  { value: TimeOfDay.AFTERNOON, label: 'Afternoon' },
  { value: TimeOfDay.EVENING, label: 'Evening' },
  { value: TimeOfDay.NIGHT, label: 'Night' },
];

export const TimeOfDayConditions = Object.freeze({
  MORNING: (reading) => {
    const { hour } = reading;
    return hour >= 3 && hour < 12;
  },
  AFTERNOON: (reading) => {
    const { hour } = reading;
    return hour >= 12 && hour < 18;
  },
  EVENING: (reading) => {
    const { hour } = reading;
    return hour >= 18 && hour < 22;
  },
  NIGHT: (reading) => {
    const { hour } = reading;
    return hour >= 22 || hour < 3;
  },
  ALL: (reading) => reading,
});

export const BGMPeriodsFilter = Object.freeze({
  [TimeOfDay.MORNING]: TimeOfDayConditions.MORNING,
  [TimeOfDay.AFTERNOON]: TimeOfDayConditions.AFTERNOON,
  [TimeOfDay.EVENING]: TimeOfDayConditions.EVENING,
  [TimeOfDay.NIGHT]: TimeOfDayConditions.NIGHT,
  [TimeOfDay.ALL]: TimeOfDayConditions.ALL,
});

export const BGMColorRanges = Object.freeze({
  RED: {
    min: 70,
    max: 300,
  },
  ORANGE: {
    min: 180,
    max: 299,
  },
});
