import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { CommonImages } from 'common-src/assets/Images';

import styles from './RequestHandlerScreen.module.scss';

const RequestHandlerScreen = ({
  isOffline,
  isRequesting,
  isErrored,
  requestingText,
  classNames = [],
}) => {
  const renderLogo = () => <img src={CommonImages.logoBird} alt="alt" className={styles.img} />;

  const renderErrorIcon = () => (
    <img
      src={CommonIcons.errorOutlineIcon}
      alt="error-outline-icon"
      style={{ width: '72px', height: '72px' }}
    />
  );

  const renderDecoration = () => {
    if (isOffline || isRequesting) {
      return renderLogo();
    }

    if (isErrored) {
      return renderErrorIcon();
    }

    return null;
  };

  const renderStatusText = () => {
    if (isOffline || isRequesting) {
      return <span className={styles.statusText}>{requestingText || 'Requesting...'}</span>;
    }

    if (isErrored) {
      return (
        <div className={[styles.statusContainer, 'vertically-centered', 'flex-column'].join(' ')}>
          <span className={styles.statusText}>An Error Occured</span>
          <span className={[styles.statusTextSecondary, 'm-t-8'].join(' ')}>
            Our team has been notified and we are looking into it.
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={[styles.container, 'vertically-centered', ...classNames].join(' ')}>
      <div className={['vertically-centered', 'horizontally-centered', 'flex-column'].join(' ')}>
        <div className={styles.imgContainer}>{renderDecoration()}</div>
        {renderStatusText()}
      </div>
    </div>
  );
};

RequestHandlerScreen.propTypes = {
  isOffline: PropTypes.bool,
  isRequesting: PropTypes.bool,
  isErrored: PropTypes.bool,
  requestingText: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default RequestHandlerScreen;
