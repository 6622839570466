import { uuidv4 } from 'common-src/utils/stringUtils';

import { CLOSE_ALERT, RESET_ALERT_STATE, SHOW_ALERT } from './actionTypes';

export const showAlert = ({ type, title, message, isAutoDismiss }) => ({
  type: SHOW_ALERT,
  payload: { id: uuidv4(), type, title, message, isAutoDismiss },
});

export const closeAlert = (id) => ({
  type: CLOSE_ALERT,
  payload: { id },
});

export const resetAlertState = () => ({
  type: RESET_ALERT_STATE,
  payload: {},
});
