import { useEffect, useRef } from 'react';

import {
  STORAGE_KEY_APP_BACKGROUND_AT,
  STORAGE_KEY_USER_LAST_ACTIVITY,
} from 'src/constants/storageKeys';

const USER_ACTION_EVENTS = ['mousemove', 'touchmove', 'click', 'keydown', 'scroll', 'resize'];

const useIdleSession = (
  shouldListen,
  inactivitySeconds,
  delaySeconds,
  isAppInBackground,
  callback = () => {},
) => {
  const lastAcivity = useRef();

  const handleActivity = () => {
    lastAcivity.current = new Date();
  };

  const handleInactivity = () => {
    const appBackgroundAt = sessionStorage.getItem(STORAGE_KEY_APP_BACKGROUND_AT);
    if (appBackgroundAt && isAppInBackground) {
      const diffMs = Math.abs(new Date(appBackgroundAt) - new Date());
      const diffSeconds = Math.floor(diffMs / 1000);

      if (diffSeconds >= inactivitySeconds) {
        callback(inactivitySeconds);
        sessionStorage.removeItem(STORAGE_KEY_USER_LAST_ACTIVITY);
        sessionStorage.removeItem(STORAGE_KEY_APP_BACKGROUND_AT);
      }

      return;
    }

    const userLastActivity = sessionStorage.getItem(STORAGE_KEY_USER_LAST_ACTIVITY);
    if (!userLastActivity) return;

    const diffMs = Math.abs(new Date(userLastActivity) - new Date());
    const diffSeconds = Math.floor(diffMs / 1000);

    if (diffSeconds >= inactivitySeconds) {
      callback(0);
      sessionStorage.removeItem(STORAGE_KEY_USER_LAST_ACTIVITY);
    }
  };

  useEffect(() => {
    const id = setInterval(handleInactivity, delaySeconds * 1000);

    if (shouldListen) {
      lastAcivity.current = new Date();

      USER_ACTION_EVENTS.forEach((event) => {
        window.addEventListener(event, handleActivity);
      });
    } else {
      clearInterval(id);
      sessionStorage.removeItem(STORAGE_KEY_USER_LAST_ACTIVITY);
    }

    return () => {
      USER_ACTION_EVENTS.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });

      clearInterval(id);
      sessionStorage.removeItem(STORAGE_KEY_USER_LAST_ACTIVITY);
    };
  }, [shouldListen, isAppInBackground]);

  useEffect(() => {
    if (!lastAcivity.current) return;

    const debounceFn = setTimeout(() => {
      if (!shouldListen) return;
      sessionStorage.setItem(STORAGE_KEY_USER_LAST_ACTIVITY, lastAcivity.current);
    }, 1000);

    return () => clearTimeout(debounceFn);
  }, [lastAcivity.current, shouldListen]);
};

export default useIdleSession;
