import _ from 'lodash';
import moment from 'moment-timezone';

import { Operation } from './constants';

export const validateCoverageHierarchy = (data) => {
  const hierarchies = data
    .map((item) => item.hierarchy)
    .filter((h) => typeof h === 'number')
    .sort((a, b) => a - b);
  const duplicates = _.uniq(
    _.filter(hierarchies, (value, i, arr) => arr.indexOf(value) !== i && value !== 0),
  );

  if (!_.isEmpty(duplicates)) {
    return 'There is a duplicate hierarchy';
  }

  if (hierarchies.every((v) => !v)) return '';

  if (hierarchies.length === 1 && hierarchies[0] > 1) {
    return 'There is no primary hierarchy';
  }

  const isIncreasedByOne = _.uniq(hierarchies).every((value, i, arr) => {
    const prev = arr[i - 1];
    return !i || value === prev + 1;
  });

  if (!isIncreasedByOne) {
    return 'Hierarchy should increase by 1';
  }

  return '';
};

export const getUpdatePayload = (data, effectiveDate) =>
  data.map((x) => ({
    ...x,
    id: typeof x.id === 'number' ? null : x.id,
    hierarchy: !x.hierarchy ? 0 : x.hierarchy,
    effectiveDate: Number.isInteger(x.hierarchy)
      ? moment(effectiveDate).format('YYYY-MM-DD')
      : null,
  }));

export const getCreatePayload = (data, effectiveDate, patientId) =>
  data.map((x) => {
    if (!Number.isInteger(x.hierarchy)) return null;

    return {
      ...x,
      id: undefined,
      effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
      hierarchy: x.hierarchy,
      patientId,
    };
  });

export const getRequestOperations = (data, effectiveDate, coveragePriorities, patientId) => {
  const payload = getUpdatePayload(data, effectiveDate);

  const operationsArray = payload.map((priority) => {
    const existingRecord = coveragePriorities.find((cp) => cp.id === priority.id);
    const operation =
      existingRecord?.effectiveDate !== moment(effectiveDate).format('YYYY-MM-DD')
        ? Operation.Create
        : Operation.Update;

    return {
      operation,
      payload:
        operation === Operation.Update
          ? priority
          : !priority.effectiveDate
            ? null
            : { ...priority, id: undefined, patientId },
    };
  });

  const grouped = _.groupBy(operationsArray, 'operation');
  const result = _.mapValues(grouped, (items) => items.map((item) => item.payload));

  return {
    update: result[Operation.Update] || [],
    create: result[Operation.Create] || [],
  };
};

export const findPriorityByDate = (coveragePriorities, coveragePlan, effectiveDate) =>
  _.find(coveragePriorities, (p) => {
    const isMatchingId = p.patientCoverageId === coveragePlan.id;
    const isSameEffectiveDate = moment(effectiveDate)
      .startOf('day')
      .isSame(moment(p.effectiveDate));

    return isMatchingId && (!effectiveDate || isSameEffectiveDate);
  });
