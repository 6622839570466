import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';

import {
  createPatientPreferredPharmacy,
  deletePatientPreferredPharmacy,
  updatePatientPreferredPharmacy,
} from './actions';
import PatientPreferredPharmacy from './PatientPreferredPharmacy';

export const getPharmaciesRequests = (oldPharmacies, newPharmacies) => {
  if (!Array.isArray(oldPharmacies) || !Array.isArray(newPharmacies)) return [];

  const requests = [];

  newPharmacies.forEach((pharmacy) => {
    const pharmacyExists = oldPharmacies.find(({ id }) => id === pharmacy.id);

    if (!pharmacyExists) {
      // Add new pharmacy
      requests.push(createPatientPreferredPharmacy(pharmacy));
      return;
    }

    if (!_.isEqual(pharmacy, pharmacyExists)) {
      // Edit pharmacy
      const payload = {
        name: pharmacy.name,
        phone: pharmacy.phone,
        address: pharmacy.address,
      };

      requests.push(updatePatientPreferredPharmacy(pharmacy.id, payload));
    }
  });

  // Delete pharmacy
  oldPharmacies.forEach(({ id }) => {
    const pharmacyExists = newPharmacies.find((p) => p.id === id);

    if (!pharmacyExists) {
      requests.push(
        deletePatientPreferredPharmacy(id, {
          successBlock: () => {
            deleteOrmItem(PatientPreferredPharmacy.modelName, id);
          },
        }),
      );
    }
  });

  return requests;
};
