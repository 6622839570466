import _ from 'lodash';

import {
  ProgressStatuses,
  SmartGoalTypes,
  StageOfChangeStatuses,
} from 'common-src/models/PatientSmartGoal';

export const CheckBoxesData = Object.entries(SmartGoalTypes)
  .map((type) => ({
    key: `${type[0]}-checkbox`,
    label: type[1].label,
    field: type[0],
    id: type[1].id,
    priority: type[1].priority,
  }))
  .sort((a, b) => a.priority - b.priority);

const goalOriginOptionsArray = ['Provider', 'Patient'];

export const goalOriginOptions = _.map(goalOriginOptionsArray, (option) => ({
  value: option,
  label: option,
}));

export const initialState = {
  goal: undefined,
  typeIds: [],
  isPatientOriginated: null,
  startDate: null,
  followUpDate: null,
  isActive: true,
  progressStatus: ProgressStatuses.DidNotProgress.id,
  stageOfChangeStatus: StageOfChangeStatuses.Action.id,
};

export const excludedFields = [
  'createdAt',
  'id',
  'isActive',
  'isOnServer',
  'originalServerModelJSON',
  'patient',
  'progressStatus',
  'stageOfChangeStatus',
  'syncStatus',
  'updatedAt',
  'updatedBy',
];
