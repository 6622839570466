import _ from 'lodash';
import moment from 'moment-timezone';

export const validatePatientLabReading = (field, value, isInches = false, canBeDecimal = true) => {
  let errMsg = '';
  switch (field) {
    case 'typeId':
      if (!value) {
        errMsg = 'Type should not be empty';
      }
      break;
    case 'readingValue':
      if (!value) {
        errMsg = 'Value should not be empty';
      } else if (Number.isNaN(+value)) {
        errMsg = 'Value should be numeric';
      } else if (+value < 0) {
        errMsg = 'Value should be positive';
      } else if (canBeDecimal && value.split('.')[1]?.length > 2) {
        errMsg = 'Value should have maximum 2 decimal places';
      } else if (!canBeDecimal && !Number.isInteger(+value)) {
        errMsg = 'Value should be integer';
      } else if (isInches && +value >= 12) {
        errMsg = 'Value should be less than 12';
      }
      break;
    case 'readingDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        errMsg = 'Date should not be in the future';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};

export const validateLabReadingUniqueness = (allReadings, currentLabReading) =>
  !allReadings.find((lr) =>
    _.isEqual(
      {
        typeId: lr?.typeId,
        readingDate: lr?.readingDate,
        readingValue: lr?.readingValue,
        patientReported: lr?.patientReported,
      },
      currentLabReading,
    ),
  );
