import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import PatientLabReading, { getLabReadingCategory } from 'common-src/models/PatientLabReading';

import { NoneView } from 'src/components/elements';
import { DateItem, LabItem, MonthItem } from 'src/pages/PatientDetails/fragments/Labs/items';

import { getIsSame } from './helpers';
import styles from './TimelineTab.module.scss';

const TimelineTab = ({ labResults = [] }) => {
  const ref = useRef();

  const [lineHeight, setLineHeight] = useState(0);
  const [hasAccordionChange, setHasAccordionChange] = useState();

  useEffect(() => {
    if (!ref.current) return;

    setLineHeight(0);
    setTimeout(() => {
      setLineHeight(ref.current.scrollHeight - 40);
    }, 0);
  }, [ref.current, labResults.length, hasAccordionChange]);

  if (_.isEmpty(labResults)) {
    return <NoneView text="No Lab Results" />;
  }

  const filters = [];
  return (
    <div className={styles.timeline} ref={ref}>
      <div className={styles.line} style={{ height: `${lineHeight}px` }} />
      {[...labResults]
        .sort((a, b) => new Date(b.readingDate) - new Date(a.readingDate))
        .map((r, i, arr) => {
          const { id, typeId, readingDate, patientReported } = r;
          const displayMonth =
            (i > 0 && !getIsSame(arr[i - 1].readingDate, readingDate, 'month')) || i === 0;
          const displayDate =
            (i > 0 && !getIsSame(arr[i - 1].readingDate, readingDate, 'day')) || i === 0;
          const category = getLabReadingCategory(typeId);
          const records =
            !patientReported &&
            !filters.find((x) => x.category === category && x.readingDate === readingDate)
              ? arr.filter(
                  (item) =>
                    !item.patientReported &&
                    getLabReadingCategory(item.typeId) === category &&
                    item.readingDate === readingDate,
                )
              : [];

          if (!patientReported) {
            filters.push({ category, readingDate });
          }

          return (
            <Fragment key={id}>
              {displayMonth && <MonthItem date={readingDate} fillCircle={i > 0} />}
              {displayDate && <DateItem date={readingDate} />}
              <LabItem
                labResult={r}
                withMargin={!displayDate}
                records={records}
                accordionHandler={() => setHasAccordionChange(new Date())}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

TimelineTab.propTypes = {
  labResults: PropTypes.arrayOf(PropTypes.exact(PatientLabReading.schema)),
};

export default TimelineTab;
