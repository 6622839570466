import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Scatter } from 'react-chartjs-2';

import { getPatientCGMReadings } from 'common-src/models/PatientCgmGlucoseReading';
import { ColorsNew } from 'common-src/styles';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import {
  CGM_MAX_TARGET_RANGE,
  CGM_MIN_TARGET_RANGE,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';

import styles from './CGMScatterChart.module.scss';
import { generateScatterData, getMaxYValue } from './helpers';

const CGMScatterChart = ({ patientId, startDate, endDate }) => {
  const readings = useCustomSelector((state) =>
    getPatientCGMReadings(state, patientId, startDate, endDate),
  );
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const timeZone = patient?.timeZone;
  const hasData = !_.isEmpty(readings);

  const scatterData = {
    datasets: [
      { data: generateScatterData(readings, timeZone), backgroundColor: ColorsNew.darkGreen },
      {
        label: 'min',
        data: Array(readings.length).fill(CGM_MIN_TARGET_RANGE),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderColor: 'transparent',
        datalabels: { display: false },
      },
      {
        label: 'max',
        data: Array(readings.length).fill(CGM_MAX_TARGET_RANGE),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        backgroundColor: 'rgba(241, 191, 114, 0.2)',
        borderColor: 'transparent',
        datalabels: { display: false },
        fill: '-1',
        order: -1,
      },
    ],
    labels: readings.map((r) => moment(r.recordedAtLocal).tz(timeZone)),
  };

  const scatterOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: hasData,
        mode: 'index',
        intersect: false,
        position: 'nearest',
        backgroundColor: ColorsNew.lightGreen,
        bodyColor: ColorsNew.darkGreen,
        footerColor: ColorsNew.darkGreen,
        borderColor: 'rgba(155, 206, 182, 0.9)',
        borderWidth: 1,
        displayColors: false,
        bodyFont: { weight: 500, size: 12 },
        footerFont: { weight: 400, size: 10 },
        caretPadding: 20,
        caretSize: 0,
        padding: 10,
        filter: (item) => !['min', 'max'].includes(item.dataset.label),
        callbacks: {
          label: (item) => `${item.parsed.y} mg/dL`,
          footer: (items) => {
            const dateTime = items[0]?.parsed?.x;
            return dateTime
              ? `${moment(dateTime).format('h:mm A')} ${moment().tz(timeZone).format('z')}`
              : '-';
          },
          title: () => '',
        },
      },
    },
    animation: { duration: 0 },
    responsive: true,
    maintainAspectRatio: false,
    clip: false,
    scales: {
      x: {
        distribution: 'series',
        type: 'time',
        time: {
          unit: 'hour',
          stepSize: 1,
          tooltipFormat: 'MM/DD/YYYY HH:mm',
          displayFormats: { hour: 'h a', day: 'M/D' },
        },
        ticks: { source: 'auto', color: ColorsNew.mediumGreen, padding: 10 },
        grid: { display: false },
        display: hasData,
      },
      y: {
        ticks: { color: ColorsNew.mediumGreen, padding: 10, stepSize: 50 },
        grid: { drawTicks: false },
        min: 0,
        max: getMaxYValue(readings),
      },
    },
  };

  return (
    <div className={styles.chart}>
      {!hasData && (
        <span
          className={[styles.emptyPlaceholder, 'absolute-centered', 'font-w-500', 'font-s-12'].join(
            ' ',
          )}
        >
          No data for period
        </span>
      )}
      <Scatter data={scatterData} options={scatterOptions} />
    </div>
  );
};

CGMScatterChart.propTypes = {
  patientId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default CGMScatterChart;
