// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EncounterDetailsPopup-module__grid___z5fBG {
  display: grid;
  padding: 15px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}
.EncounterDetailsPopup-module__grid___z5fBG .EncounterDetailsPopup-module__width75___OiNaf {
  grid-column: 2 span;
}

.EncounterDetailsPopup-module__comments___RwXV4 {
  margin: 10px 0;
  padding: 15px 30px;
}`, "",{"version":3,"sources":["webpack://./src/popups/EncounterDetailsPopup/EncounterDetailsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,cAAA;EACA,kBAAA;AAAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `EncounterDetailsPopup-module__grid___z5fBG`,
	"width75": `EncounterDetailsPopup-module__width75___OiNaf`,
	"comments": `EncounterDetailsPopup-module__comments___RwXV4`
};
module.exports = ___CSS_LOADER_EXPORT___;
