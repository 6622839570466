import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import {
  getPatientFamilyHistory,
  getPatientFamilyHistoryRequest,
  makeFamilyHistoryInactive,
} from 'common-src/models/PatientFamilyHistory';
import { Relation } from 'common-src/models/PatientMedicalProblem';
import { getRelationById } from 'common-src/models/PatientMedicalProblem/helpers';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultilineTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, FamilyHistoryTabs, TableHeaders } from './constants';
import styles from './Family.module.scss';
import { getTabInfo } from './helpers';

const Family = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(FamilyHistoryTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredFamilyHistory, setFilteredFamilyHistory] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const familyHistory = useCustomSelector((state) => getPatientFamilyHistory(state, patientId));

  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);

  const { isRequesting } = useRequestLoading([getPatientFamilyHistoryRequest({ patientId })]);

  useInterval(() => {
    if (!patientId) return;
    dispatch(getPatientFamilyHistoryRequest({ patientId, limit: 5 }));
  }, 30000);

  useEffect(() => {
    const { tabInfo, activeFamilyHistory, inactiveFamilyHistory } = getTabInfo(familyHistory);
    setTabInfo(tabInfo);
    setFilteredFamilyHistory(selectedTab === tabs[0] ? activeFamilyHistory : inactiveFamilyHistory);
  }, [selectedTab, familyHistory]);

  const showAddFamilyHistoryPopup = () =>
    dispatch(openModal(ModalType.FAMILY_HISTORY, { patientId }));

  const getData = () =>
    filteredFamilyHistory?.map((item) => {
      const isDisabled =
        (item.activeTill && moment(item.activeTill).isBefore(moment.now())) || !actionsAllowed;

      return {
        name: <TextItem isActive text={item?.diagnosis || '-'} />,
        comments: <MultilineTextItem isActive text={item?.comments || '-'} maxLines={2} />,
        relation: (
          <TextItem
            isActive
            text={getRelationById(item?.relationship || Relation.Other.id).label}
          />
        ),
        createdAt: (
          <TextItem
            isActive
            text={item?.createdAt ? moment(item.createdAt).format('MM/DD/YYYY') : '-'}
            value={item?.createdAt}
          />
        ),
        actions: (
          <ActionsRow
            options={[
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.FAMILY_HISTORY, { patientId, id: item.id }),
              },
              {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeFamilyHistoryInactive(item.id, {}),
                showWarning: true,
              },
              isDisabled && {
                id: 'duplicate-button',
                text: 'Duplicate',
                backgroundColor: ColorsNew.baseGreen,
                color: 'white',
                disabled: !actionsAllowed,
                onClick: openModal(ModalType.FAMILY_HISTORY, {
                  patientId,
                  id: item.id,
                  isDuplicate: true,
                }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling family history..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="add-button"
            text="Add History"
            onClick={() => showAddFamilyHistoryPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="family-history"
        classNames={[
          'medical-table-new',
          filteredFamilyHistory?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No family history"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'createdAt',
          label: 'Date Added',
          width: '10%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredFamilyHistory}
        selectedTab={selectedTab}
      />
    </>
  );
};

Family.propTypes = {
  patientId: PropTypes.number.isRequired,
  actionsAllowed: PropTypes.bool,
};

export default Family;
