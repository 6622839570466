import PropTypes from 'prop-types';
import React from 'react';

import { OncehubBookingStatuses } from 'common-src/models/PatientOncehubBooking';

import styles from './StatusItem.module.scss';

const StatusItem = ({ status }) => {
  const statusData = OncehubBookingStatuses[status];

  return (
    <div className={[styles.container, 'gap-10'].join(' ')}>
      <img className={styles.icon} src={statusData.iconSrc} alt="scheduled-call-status-icon" />
      <span
        className={[styles.text, 'font-w-500', 'font-s-14'].join(' ')}
        style={{ color: statusData.textColor }}
      >
        {statusData.text}
      </span>
    </div>
  );
};

StatusItem.propTypes = {
  status: PropTypes.string,
};

export default StatusItem;
