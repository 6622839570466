export const moduleTypes = {
  ClinicalNote: {
    id: 1,
    title: 'Clinical Note',
  },
  QuickNote: {
    id: 2,
    title: 'Progress Note - General',
  },
  MonthlyCall: {
    id: 3,
    title: 'Progress Note - Monthly Call',
  },
  CallUnanswered: {
    id: 4,
    title: 'Call Unanswered',
  },
  Hypoglycemia: {
    id: 5,
    title: 'Hypoglycemia',
  },
  Hyperglycemia: {
    id: 6,
    title: 'Hyperglycemia',
  },
  LowEngagement: {
    id: 7,
    title: 'Progress Note - Low Engagement',
  },
  TechnicalSupport: {
    id: 8,
    title: 'Technical Support',
  },
  Onboarding: {
    id: 9,
    title: 'Intake',
  },
  RPMStartOrder: {
    id: 10,
    title: 'RPM Start Order',
  },
  PreAppointment: {
    id: 11,
    title: 'Pre-Appointment',
  },
  ClinicalIntake: {
    id: 12,
    title: 'Clinical Initial Visit',
  },
  HoldRequest: {
    id: 13,
    title: 'Hold Request',
  },
  HyperglycemicTrend: {
    id: 14,
    title: 'Hyperglycemic Trend',
  },
  PhysicianNote: {
    id: 15,
    title: 'Physician Note',
  },
  DeviceWalkthrough: {
    id: 16,
    title: 'Device Setup & Training',
  },
  EnrollmentNote: {
    id: 17,
    title: 'Enrollment Note',
  },
  Addendum: {
    id: 18,
    title: 'Addendum',
  },
  PatientRounds: {
    id: 19,
    title: 'Member Rounds',
  },
  NutritionConsult: {
    id: 20,
    title: 'Progress Note - Nutrition Assessment',
  },
  EducationDelivery: {
    id: 21,
    title: 'Progress Note - Education Delivery',
  },
  CloseRequest: {
    id: 22,
    title: 'Account Closure Request',
  },
  NutritionFollowUp: {
    id: 23,
    title: 'Progress Note - Nutrition Follow Up',
  },
  CoachingCall: {
    id: 24,
    title: 'Coaching Call',
  },
  Diabetes101: {
    id: 25,
    title: 'Diabetes 101',
  },
  MedicationReconciliation: {
    id: 26,
    title: 'Medication Reconciliation',
  },
  MFARequest: {
    id: 27,
    title: 'MFA Request',
  },
  MFADecision: {
    id: 28,
    title: 'MFA Decision',
  },
  CommercialPlanReview: {
    id: 29,
    title: 'Commercial Plan Review',
  },
  SelfServeIntakeConfirmation: {
    id: 30,
    title: 'Self-Serve Intake - Confirmation',
  },
  SelfServeIntakeMedical: {
    id: 31,
    title: 'Self-Serve Intake - Medical',
  },
  SelfServeIntakeLifestyle: {
    id: 32,
    title: 'Self-Serve Intake - Lifestyle',
  },
  QuarterlyCheckIn: {
    id: 33,
    title: 'Progress Note - Quarterly Check In',
  },
  ClinicalFirstVisitNP: {
    id: 34,
    title: 'Clinical First Visit (NP)',
  },
  ClinicalFirstVisitRpmOrder: {
    id: 35,
    title: 'Clinical First Visit',
  },
  InitialCoachingCall: {
    id: 36,
    title: 'Initial Coaching Call',
  },
  MonthlyWellnessVisitAC: {
    id: 37,
    title: 'Progress Note - Monthly Wellness Visit',
  },
  CGMAssessment: {
    id: 38,
    title: 'CGM Assessment',
  },
  CGMQualificationExistingMembers: {
    id: 39,
    title: 'CGM Qualification - Established Patient',
  },
  MNTNote: {
    id: 40,
    title: 'Medical Nutrition Therapy (MNT)',
  },
  InformedConsentNote: {
    id: 41,
    title: 'Informed Consent Note',
  },
  CareCoordination: {
    id: 42,
    title: 'Care Coordination',
  },
  BiannualVisit: {
    id: 43,
    title: 'Biannual Patient Visit ',
  },
  ChartReview: {
    id: 44,
    title: 'Chart Review',
  },
  PerryHealthAssessment: {
    id: 45,
    title: 'Perry Health Assessment',
  },
  CoachIntroduction: {
    id: 46,
    title: 'Coach Introduction',
  },
  PerryMotivationQuestionnaire: {
    id: 47,
    title: 'Program Motivation Questionnaire',
  },
  PHQ9GAD7Assessment: {
    id: 48,
    title: 'PHQ-9 / GAD-7 Assessment',
  },
  CaseWorkAssessment: {
    id: 49,
    title: 'Case Work Assessment',
  },
  CaseWorkProgressNote: {
    id: 50,
    title: 'Case Work Progress Note',
  },
  MFARenewalRequest: {
    id: 51,
    title: 'MFA Renewal Request',
  },
  InsulinPumpAssessment: {
    id: 52,
    title: 'Insulin Pump Assessment',
  },
};
