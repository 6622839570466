import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select, TextArea } from 'common-src/components/base';
import { booleanOptions } from 'common-src/constants/booleanOptions';
import {
  adjustPatientInteraction,
  getDocumentationById,
  getPatientInteractionApiRequest,
  InteractionBillingCategory,
} from 'common-src/models/PatientInteraction';
import { PatientTaskStatus } from 'common-src/models/PatientTask';
import { updatePatientTask } from 'common-src/models/PatientTask/actions';
import { ColorsNew } from 'common-src/styles';
import { minutesToSeconds, secondsToMinutes } from 'common-src/utils/dateUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import useRequestLoading from 'src/hooks/useRequestLoading';
import BasePopup from 'src/popups/BasePopup';

import { defaultState, editableFields } from './constants';
import { getIsButtonEnabled } from './helpers';
import styles from './InteractionAdjustmentsPopup.module.scss';

const InteractionAdjustmentsPopup = ({ onClose, interactionId, taskId, reason }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({ ...defaultState, reason: reason || undefined });

  const interaction = useCustomSelector((state) => getDocumentationById(state, interactionId));

  useRequestLoading([getPatientInteractionApiRequest(interactionId)]);
  const { renderButtons } = usePopup();

  const onCloseWithoutChanges = () =>
    dispatch(
      updatePatientTask(
        taskId,
        { status: PatientTaskStatus.New.value },
        { successBlock: () => onClose() },
      ),
    );

  const onChange = (key, value) => setFormData((prev) => ({ ...prev, [key]: value }));

  const onCloseWithChanges = () =>
    dispatch(
      updatePatientTask(
        taskId,
        { status: PatientTaskStatus.Completed.value },
        { successBlock: () => onClose() },
      ),
    );

  useEffect(() => {
    if (_.isEmpty(interaction)) return;

    setFormData((prev) => ({
      ...prev,
      ..._.pick(interaction, editableFields),
      duration: secondsToMinutes(interaction.duration),
    }));
  }, [interaction]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();
    dispatch(
      adjustPatientInteraction(
        interactionId,
        { ...formData, duration: minutesToSeconds(formData.duration) },
        {
          successBlock: () => {
            successCallback('Interaction adjusted!');
            onCloseWithChanges();
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  return (
    <BasePopup
      id="interaction-adjustments"
      open
      onClose={onCloseWithoutChanges}
      title="Interaction Adjustments"
    >
      <div className={styles.container}>
        <Select
          id="is-billable"
          label="Is billable?"
          options={booleanOptions}
          onChange={(op) => onChange('isBillable', op.value)}
          value={booleanOptions.find((option) => option.value === formData?.isBillable)}
          required
          placeholder="Is billable?"
        />
        <Select
          id="billing-category"
          label="Billing category"
          options={Object.values(InteractionBillingCategory)}
          onChange={(op) => onChange('billingCategory', op.value)}
          value={Object.values(InteractionBillingCategory).find(
            (option) => option.value === formData?.billingCategory,
          )}
          required
          placeholder="Billing category"
        />
        <Input
          id="duration"
          type="number"
          label="Duration (minutes)"
          value={formData?.duration}
          onTextChange={(value) => onChange('duration', value)}
          required
          placeholder="Duration"
        />
        <Select
          id="had-live-interaction"
          label="Had live interaction?"
          options={booleanOptions}
          onChange={(op) => onChange('hadLiveInteraction', op.value)}
          value={booleanOptions.find((option) => option.value === formData?.hadLiveInteraction)}
          required
          placeholder="Had live interaction"
        />
        <TextArea
          id="reason"
          placeholder="Reason"
          value={formData?.reason}
          onTextChange={(value) => onChange('reason', value)}
          fixedRows={3}
          label="Reason"
          requiredColor={ColorsNew.darkGreen}
          required
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose: onCloseWithoutChanges,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(formData),
        submitButtonText: 'Save and Close',
      })}
    </BasePopup>
  );
};

InteractionAdjustmentsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  interactionId: PropTypes.string,
  taskId: PropTypes.string,
  reason: PropTypes.string,
};

export default InteractionAdjustmentsPopup;
