export const ResultsCount = Object.freeze({
  25: 25,
  50: 50,
  100: 100,
});

export const resultsCountOptions = Object.values(ResultsCount).map((count) => ({
  label: `${count}`,
  value: count,
}));

export const initialCurrentPageState = { value: 1, index: 0 };

export const PAGINATION_LENGTH = 6;

export const THREE_DOTS_INDEX = 3;
