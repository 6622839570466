import _ from 'lodash';

import { TimeOfDay } from 'src/pages/PatientDetails/fragments/Biometrics/constants';

export const timeOfDayForHour = (hour) => {
  if (!hour && hour !== 0) {
    return TimeOfDay.ANY_TIME;
  }
  if (hour >= 22 || hour < 3) {
    return TimeOfDay.NIGHT;
  }
  if (hour >= 18 && hour < 22) {
    return TimeOfDay.EVENING;
  }
  if (hour >= 12 && hour < 18) {
    return TimeOfDay.AFTERNOON;
  }
  if (hour >= 3 && hour < 12) {
    return TimeOfDay.MORNING;
  }
};

export const getFilteredData = (patientReadings) =>
  _.unionBy(
    patientReadings
      .sort(
        (a, b) =>
          new Date(b.getRecordedAt('YYYY-MM-DD HH:mm:ss')) -
          new Date(a.getRecordedAt('YYYY-MM-DD HH:mm:ss')),
      )
      .map((reading) => {
        const { effectiveDate, getRecordedAt, timeZoneAbbr } = reading;

        return {
          getRecordedAt,
          effectiveDate,
          timeZoneAbbr,
          ..._.without(Object.values(TimeOfDay), TimeOfDay.ALL).reduce((readingObj, timeOfDay) => {
            readingObj[timeOfDay] = _.filter(
              patientReadings,
              (r) => r.effectiveDate === effectiveDate && timeOfDayForHour(r.hour) === timeOfDay,
            );

            return readingObj;
          }, {}),
        };
      }),
    'effectiveDate',
  );
