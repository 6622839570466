import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

import { activeStatusesIds, DiagnosisOptions, InsulinUseOptions } from './constants';

export const getPatientMedicalProblems = (state, patientId) =>
  allModelsSelector(state, 'PatientMedicalProblem', (pmp) => pmp.patientId === patientId);

export const getPatientMedicalProblem = (state, id) =>
  singleModelSelector(state, 'PatientMedicalProblem', id);

export const getPrimaryDiagnosis = (state, patientId) => {
  const medicalProblems = getPatientMedicalProblems(state, patientId).sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );
  const diagnosisCodes = DiagnosisOptions.map((diagnosis) => diagnosis.value);

  return medicalProblems.find(
    (problem) =>
      diagnosisCodes.includes(String(problem.icd10Code)) &&
      (activeStatusesIds.includes(problem.dxStatus) || !problem.dxStatus),
  );
};

export const getInsulinUse = (state, patientId) => {
  const medicalProblems = getPatientMedicalProblems(state, patientId);
  const insulinUseCodes = InsulinUseOptions.map((insulinUse) => insulinUse.value);

  return medicalProblems.find(
    (problem) =>
      insulinUseCodes.includes(String(problem.icd10Code)) &&
      (activeStatusesIds.includes(problem.dxStatus) || !problem.dxStatus),
  );
};
