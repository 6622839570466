export const ProviderTypes = Object.freeze({
  Pharmacy: { id: 1 },
  PrimaryCare: { id: 2 },
  Endocrinology: { id: 3 },
  Cardiology: { id: 4 },
  Nephrology: { id: 5 },
  Orthopedics: { id: 6 },
  Neurology: { id: 7 },
  Ophthamology: { id: 8 },
  Podiatry: { id: 9 },
  Other: { id: 10 },
});

export const providerTypeOptions = Object.keys(ProviderTypes).map((key) => ({
  value: ProviderTypes[key].id,
  label: key,
}));
