import PropTypes from 'prop-types';
import React from 'react';

import styles from './DetailSection.module.scss';

const DetailSection = ({ children, classNames }) => {
  const containerClassNames = [styles.container, 'flex-column'];
  if (classNames) {
    containerClassNames.push(classNames);
  }

  return (
    <section id="detail-section" className={containerClassNames.join(' ')}>
      {children}
    </section>
  );
};

DetailSection.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default DetailSection;
