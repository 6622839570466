import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getRoleConfig } from 'common-src/features/auth';
import { cachePatient, getCachedPatient } from 'common-src/features/cache';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { updatePatient } from 'common-src/models/Patient';
import { getPatientCgmGlucoseReadingsRequest } from 'common-src/models/PatientCgmGlucoseReading';
import {
  createPatientDevice,
  getPatientDevices,
  getPatientDevicesRequest,
} from 'common-src/models/PatientDevice';
import {
  createPatientFulfillmentOrder,
  getPatientFulfillmentOrders,
  getPatientFulfillmentOrdersRequest,
} from 'common-src/models/PatientFulfillmentOrder';
import { getPatientGlucoseReadingsRequest } from 'common-src/models/PatientGlucoseReading';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { RequestHandlerScreen } from 'src/components/base';
import { ModalType } from 'src/components/base/ModalGroup';
import { closeModal, openModal, resetModalState } from 'src/features/modals';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';

import { DevicesHeader, OrderHistoryList } from './components';
import styles from './DevicesFragment.module.scss';

const RequestType = Object.freeze({
  ADD_DEVICE: 'AddDevice',
  ORDER_STRIPS: 'OrderStrips',
});

const DevicesFragment = ({ patientId }) => {
  const dispatch = useDispatch();
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const patientDevices = useCustomSelector((state) => getPatientDevices(state, patientId));
  const patientFulfillmentOrders = useCustomSelector((state) =>
    getPatientFulfillmentOrders(state, patientId),
  );
  const isPatientOrdersCached = useCustomSelector(
    (state) => getCachedPatient(state, patientId)?.orders,
  );
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.devicesAndOrders,
  );

  const [patientUpdate, setPatientUpdate] = useState(null);
  const [fulfillmentOrder, setFulfillmentOrder] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);

  const { showAlert, AlertType } = useAlert();

  useInterval(() => {
    dispatch(getPatientFulfillmentOrdersRequest(patient.id));
  });

  const handleError = (error) => {
    dispatch(resetModalState());
    setRequestType(null);
    showAlert(AlertType.Error, '', typeof error === 'string' ? error : error.message);
  };

  const handleSuccess = (message) => {
    dispatch(resetModalState());
    setRequestType(null);
    showAlert(AlertType.Success, '', message);
  };

  const createPatientDeviceRequest = () => {
    const { patientDevice, address, glucoseStripCount } = patientUpdate;

    const requests = [
      createPatientDevice(
        {
          id: patientDevice?.deviceId.toString(),
          patientId: patient.id,
          typeId: Number(patientDevice?.deviceType),
        },
        {
          successBlock: () => handleSuccess('You have successfully added the device.'),
        },
      ),
    ];

    const data = {};
    if (glucoseStripCount) {
      Object.assign(data, {
        glucoseStripCount: (patient?.glucoseStripCount ?? 0) + glucoseStripCount,
      });
    }

    if (address) {
      Object.assign(data, { address });
    }

    if (!_.isEmpty(data)) {
      requests.push(updatePatient(patient.id, { ...data }));
    }

    dispatch(
      restRequestMultiple({
        restRequests: requests,
        errorBlock: (err) => handleError(err),
      }),
    );
  };

  const createOrderStripsRequest = () => {
    const address = patient.shouldShipToHomeAddress ? patient.address : patient.shippingAddress;

    dispatch(
      createPatientFulfillmentOrder(
        {
          patientId: patient?.id,
          shippingAddress: _.mapValues(address, (v) => (!v ? '' : v)),
          ...fulfillmentOrder,
        },
        {
          successBlock: () => handleSuccess('Your order has been processed successfully.'),
          errorBlock: handleError,
        },
      ),
    );
  };

  const showPatientAddressPopup = () =>
    dispatch(
      openModal(ModalType.PATIENT_ADDRESS, {
        onSubmit: (address) => {
          switch (requestType) {
            case RequestType.ADD_DEVICE: {
              setPatientUpdate({
                ...patientUpdate,
                address,
                shouldSendRequest: true,
              });

              break;
            }
            case RequestType.ORDER_STRIPS: {
              setFulfillmentOrder({
                ...fulfillmentOrder,
                shippingAddress: address,
              });

              break;
            }
            default: {
              break;
            }
          }
        },
      }),
    );

  useEffect(() => {
    if (!patient?.id || isPatientOrdersCached) return;

    setIsRequesting(true);
    dispatch(
      restRequestMultiple({
        restRequests: [
          getPatientFulfillmentOrdersRequest(patient.id, {
            successBlock: () => dispatch(cachePatient(patient.id, 'orders')),
          }),
          getPatientGlucoseReadingsRequest({
            patientId: patient.id,
            deviceIds: _.isEmpty(patientDevices) ? null : _.map(patientDevices, (pd) => pd.id),
          }),
          getPatientCgmGlucoseReadingsRequest({
            patientId: patient.id,
            limit: 1,
          }),
          getPatientDevicesRequest({ patientId: patient.id }),
        ],
        successBlock: () => {
          setIsRequesting(false);
        },
        errorBlock: () => {
          setIsRequesting(false);
        },
      }),
    );
  }, []);

  // listener for add patient device request
  useEffect(() => {
    if (!patientUpdate) return;

    if (patientUpdate?.shouldSendRequest) {
      createPatientDeviceRequest();
    } else {
      showPatientAddressPopup();
    }
  }, [patientUpdate]);

  // listener for fulfillment order request
  useEffect(() => {
    if (!fulfillmentOrder) return;

    if (!fulfillmentOrder?.shippingAddress && !patient?.hasAddress) {
      showPatientAddressPopup();
    } else {
      createOrderStripsRequest();
    }
  }, [fulfillmentOrder]);

  const showAddPopup = () =>
    dispatch(
      openModal(ModalType.PATIENT_DEVICE, {
        patientId,
        buttonText: patient?.hasAddress ? 'Submit' : 'Next',
        onActionButtonClick: (result) => {
          setRequestType(RequestType.ADD_DEVICE);
          setPatientUpdate({
            ...patientUpdate,
            patientDevice: {
              deviceType: result?.deviceType,
              deviceId: result?.deviceId,
            },
            address: result?.address,
            shouldSendRequest: patient.hasAddress || false,
          });

          if (!patient?.hasAddress) {
            dispatch(closeModal(ModalType.PATIENT_DEVICE));
          }
        },
      }),
    );

  const showEditStripsPopup = () =>
    dispatch(
      openModal(ModalType.EDIT_STRIP_COUNT, {
        patientId,
        glucoseStripCount: patient?.glucoseStripCount,
        onEditStripsSubmit: (result) => {
          dispatch(
            updatePatient(
              patientId,
              { glucoseStripCount: result },
              {
                successBlock: () => {
                  handleSuccess('You have successfully changed strips count.');
                  dispatch(closeModal(ModalType.EDIT_STRIP_COUNT));
                },
                errorBlock: (error) => handleError(error),
              },
            ),
          );
        },
      }),
    );

  const showOrderStripsPopup = () =>
    dispatch(
      openModal(ModalType.ORDER_STRIPS, {
        patientId,
        patientFulfillmentOrders,
        onOrderStripsSubmit: (result) => {
          setRequestType(RequestType.ORDER_STRIPS);
          setFulfillmentOrder({
            ...fulfillmentOrder,
            patientId: patient?.id,
            lineItems: result,
          });
        },
      }),
    );

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling data..." />;
  }

  return (
    <section className={[styles.container, 'flex-column'].join(' ')}>
      <DevicesHeader
        glucoseStripCount={patient?.glucoseStripCount}
        patient={patient}
        patientFulfillmentOrders={patientFulfillmentOrders}
        onGlucoseStripEdit={() => showEditStripsPopup()}
        onAddDevice={() => showAddPopup()}
        actions={actions}
        onAddOrder={() => showOrderStripsPopup()}
      />
      <article className={styles.scrollableContainer}>
        <OrderHistoryList patientFulfillmentOrders={patientFulfillmentOrders} />
      </article>
    </section>
  );
};

DevicesFragment.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DevicesFragment;
