import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Badge, Menu } from 'common-src/components/base';
import { FilterTypeOptions } from 'common-src/models/PatientOncehubBooking';
import { Colors } from 'common-src/styles';

import styles from './Filter.module.scss';

const Filter = ({ types, setTypes }) => {
  const getOptions = () =>
    FilterTypeOptions.map((type) => ({
      id: type,
      text: type,
      isChecked: types.includes(type),
      onClick: () => {
        if (types.includes(type)) {
          setTypes((prev) => prev.filter((x) => x !== type));
          return;
        }

        setTypes((prev) => [...prev, type]);
      },
    }));

  return (
    <div id="scheduled-calls-filter" className={styles.container}>
      <div className={styles.type}>
        <Menu
          classNames={['m-l-8']}
          button={
            <img
              id="schedule-call-filter-type"
              src={
                _.isEmpty(types)
                  ? CommonIcons.tableFilterUnselected
                  : CommonIcons.tableFilterSelected
              }
              alt="filter-icon"
            />
          }
          options={getOptions()}
          multiple
          onClearSelection={() => setTypes([])}
        />
        <Badge
          id="scheduled-call-type-badge-counter"
          classNames={[styles.badge]}
          count={types.length}
          backgroundColor={Colors.badgeRed}
          showZero={false}
          height={18}
        />
      </div>
    </div>
  );
};

Filter.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  setTypes: PropTypes.func,
};

export default Filter;
