import PropTypes from 'prop-types';
import React from 'react';

import styles from './CGMIndicator.module.scss';

const CGMIndicator = ({ id, value, isCritical }) => {
  const containerClasses = [styles.container, 'font-w-500', 'primary-border', 'font-s-14'];
  if (isCritical) {
    containerClasses.push(styles.critical);
  }

  if (value === '-') {
    return <span>-</span>;
  }

  return (
    <span id={id} className={containerClasses.join(' ')}>
      {value}
    </span>
  );
};

CGMIndicator.propTypes = {
  value: PropTypes.string,
  isCritical: PropTypes.bool,
  id: PropTypes.string,
};

export default CGMIndicator;
