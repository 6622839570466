import _ from 'lodash';
import { useEffect, useRef } from 'react';

import { apiRequest, HttpMethods } from 'common-src/features/rest';
import { QuestionnaireDocuments } from 'common-src/models/PatientInteraction';

import { checkModuleRequiredFields } from 'src/pages/DocumentationIndex/helpers';

const useDocumentationDynamicResult = (modules, setModules, hasDetectedChanges) => {
  const finishedQuestionnaires = useRef([]);

  const shouldSendRequest = (questionnaire) => {
    const finishedQuestionnaire = finishedQuestionnaires.current.find(
      (q) => q.id === questionnaire.id,
    );

    if (!finishedQuestionnaire) {
      finishedQuestionnaires.current.push(questionnaire);
      return true;
    }

    if (_.isEqual(finishedQuestionnaire, questionnaire)) return false;

    const objIndex = finishedQuestionnaires.current.findIndex((obj) => obj.id === questionnaire.id);
    finishedQuestionnaires.current[objIndex] = questionnaire;

    return true;
  };

  const updateQuestionnaireResult = (questionnaire, instructions) => {
    const modulesCopy = JSON.parse(JSON.stringify(modules));
    const questionnaireCopy = JSON.parse(JSON.stringify(questionnaire));
    const questionnaireIndex = modulesCopy.findIndex((m) => m.id === questionnaire.id);

    questionnaireCopy.sections = questionnaireCopy.sections.map((section) => {
      if (section.header === 'Result') {
        section.instructions = instructions;
        return section;
      }

      return section;
    });

    modulesCopy[questionnaireIndex] = questionnaireCopy;

    setModules(modulesCopy);
  };

  const updateResultSection = (questionnaire) => {
    const { hasMissingFields } = checkModuleRequiredFields([questionnaire]);

    if (hasMissingFields) {
      updateQuestionnaireResult(
        questionnaire,
        'Please, complete the questionnaire to see the result.',
      );

      finishedQuestionnaires.current = finishedQuestionnaires.current.filter(
        (q) => q.id !== questionnaire.id,
      );

      return;
    }

    if (!shouldSendRequest(questionnaire)) return;

    apiRequest({
      endpoint: 'interactionTemplates/evaluateQuestionnaire',
      method: HttpMethods.Post,
      body: {
        moduleType: questionnaire.moduleType,
        sections: questionnaire.sections,
      },
    })
      .then((res) => res.json())
      .then((res) => updateQuestionnaireResult(questionnaire, res.evaluation));
  };

  useEffect(() => {
    if (_.isEmpty(modules) || !hasDetectedChanges) return;

    const selectedModules = modules.filter((m) => m.isSelected);
    const selectedModuleTypes = selectedModules.map((m) => m.moduleType);
    const selectedQuestionnaireTypes = _.intersection(selectedModuleTypes, QuestionnaireDocuments);

    if (_.isEmpty(selectedQuestionnaireTypes)) return;

    const questionnaires = selectedModules.filter((m) =>
      QuestionnaireDocuments.includes(m.moduleType),
    );

    questionnaires.forEach((questionnaire) => updateResultSection(questionnaire));
  }, [hasDetectedChanges]);
};

export default useDocumentationDynamicResult;
