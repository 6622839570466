import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientPreferredPharmacy extends Model {
  static modelName = 'PatientPreferredPharmacy';

  static fields = {
    id: attr(),
    name: attr(),
    phone: attr(),
    address: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'preferredPharmacyCreator'),
    updatedBy: fk('Client', 'preferredPharmacyChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'preferredPharmacies',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        zipcode: PropTypes.string,
      }),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      createdBy: PropTypes.number,
      updatedBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientPreferredPharmacy;
