import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import styles from './Option.module.scss';

const Option = ({ option, isSelected, onClick, showIcon }) => (
  <div
    className={[styles.option, isSelected ? styles.selected : '', 'vertically-centered'].join(' ')}
    onClick={(e) => onClick(e)}
    role="presentation"
  >
    {showIcon && (
      <img
        className={styles.icon}
        alt="option-icon"
        src={
          isSelected ? CommonIcons.checkIcon : option.icon ? option.icon : CommonIcons.uncheckIcon
        }
      />
    )}
    <span id={`option-${option.label}`} className={['font-w-500', 'font-s-14'].join(' ')}>
      {option.label}
    </span>
  </div>
);

Option.propTypes = {
  onClick: PropTypes.func,
  option: PropTypes.object,
  isSelected: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default Option;
