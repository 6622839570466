import moment from 'moment-timezone';

import { badEmailCharacters } from 'common-src/constants/badEmailCharacters';
import { badNameCharacters } from 'common-src/constants/badNameCharacters';

export const isEmailValid = (email) =>
  email &&
  !badEmailCharacters.split('').some((char) => email.includes(char)) &&
  email.includes('@');

export const isPhoneStringValid = (phone) => /[0-9]{10}/.test(phone);

export const isNPIValid = (npi) => /^[0-9]{10}$/.test(npi);

export const validateStartEndDates = (startDate, endDate) => {
  let message = '';
  if (startDate && endDate && moment(startDate).isAfter(endDate)) {
    message = 'End date should be after start date';
  }

  return {
    endDate: message,
  };
};

export const isNumberValid = (str) => {
  const numberValue = Number(str);

  return (
    !str ||
    (str.length === 1 && numberValue === 0) ||
    (str.match(/[0-9]+/g) && !str.startsWith('00') && !Number.isNaN(numberValue))
  );
};

export const isMBIValid = (mbi) =>
  /\d(?![SLOIBZ])[A-Z](?![SLOIBZ])[A-Z\d]\d(?![SLOIBZ])[A-Z](?![SLOIBZ])[A-Z\d]\d(?![SLOIBZ])[A-Z](?![SLOIBZ])[A-Z]\d{2}$/.test(
    mbi,
  );

export const isNameValid = (name) =>
  !badNameCharacters.split('').some((char) => name.includes(char));
