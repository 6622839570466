import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Select } from 'common-src/components/base';
import PatientBillingExemption, {
  addExemption,
  ExemptionSource,
  ExemptionType,
  updateExemption,
  validateExemption,
} from 'common-src/models/PatientBillingExemption';

import { InfoItem } from 'src/components/elements';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import styles from './ExemptionPopup.module.scss';
import { extractExemption, getIsButtonEnabled } from './helpers';

const ExemptionPopup = ({ patientId, exemption, onClose, open }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (!exemption) return;
    setData(extractExemption(exemption));
  }, [exemption]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    if (exemption?.id) {
      dispatch(
        updateExemption(
          exemption.id,
          { endDate: data.endDate },
          {
            successBlock: () => {
              successCallback('Exemption edited!');
              onClose(true);
            },
            errorBlock: (err) => errorCallback(err),
          },
        ),
      );

      return;
    }

    dispatch(
      addExemption(
        { patientId, ...data },
        {
          successBlock: () => {
            successCallback('Exemption added!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  const onClearHandler = () => {
    setErrors({});
    setData(exemption ? extractExemption(exemption) : initialState);
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validateExemption(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onDateChangeHandler = (key, keyToCompare, isBefore, value) => {
    const newData = { ...data, [key]: value };
    const err = { ...validateExemption(key, value, newData) };

    const compareCondition = isBefore
      ? moment(value).isAfter(moment(newData?.[keyToCompare]))
      : moment(value).isBefore(moment(newData?.[keyToCompare]));

    if (!compareCondition) {
      if (newData[key]) {
        Object.assign(err, { ...validateExemption(key, value, newData) });
      }
      if (newData[keyToCompare]) {
        Object.assign(err, {
          ...validateExemption(keyToCompare, newData?.[keyToCompare], newData),
        });
      }
    }

    setErrors((prev) => ({ ...prev, ...err }));
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const renderExemptionType = () => {
    if (exemption) {
      return (
        <InfoItem
          title="Exemption Type"
          content={
            Object.values(ExemptionType).find((type) => type.value === data.exemptionType)?.label
          }
          textId="plan-name"
        />
      );
    }

    return (
      <Select
        id="type"
        classNames={[styles.typeSelect]}
        label="Exemption Type"
        options={Object.values(ExemptionType).filter((x) => x.isSelectable)}
        onChange={(op) => onChangeHandler('exemptionType', op.value)}
        value={Object.values(ExemptionType).find((type) => type.value === data.exemptionType)}
        required
        placeholder="Select exemption type"
        errorText={errors.exemptionType}
      />
    );
  };

  const renderStartDate = () => {
    if (exemption) {
      return (
        <InfoItem
          title="Start Date"
          content={moment(data.startDate).format('MM/DD/YYYY')}
          textId="start-date"
        />
      );
    }

    return (
      <DatePickerNew
        id="start-date"
        header="Start Date"
        value={data.startDate}
        errorText={errors.startDate}
        onDateSelected={(date) =>
          onDateChangeHandler(
            'startDate',
            'endDate',
            true,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validateExemption('startDate', date, data) }))
        }
        required
      />
    );
  };

  return (
    <BasePopup
      id="exemption"
      open={open}
      onClose={onClose}
      title={`${exemption ? 'Edit' : 'Add'} Exemption`}
    >
      <div className={styles.grid}>
        {renderExemptionType()}
        <span />
        <span />
        {renderStartDate()}
        <DatePickerNew
          id="end-date"
          header="End Date"
          value={data.endDate}
          errorText={errors.endDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'endDate',
              'startDate',
              false,
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          onBlur={(date) =>
            setErrors((prev) => ({ ...prev, ...validateExemption('endDate', date, data) }))
          }
        />
        <Select
          id="source"
          label="Source"
          options={Object.values(ExemptionSource)}
          onChange={(op) => onChangeHandler('source', op)}
          value={Object.values(ExemptionSource).find((source) => source.value === data.source)}
          required
          placeholder="Select source"
          errorText={errors.source}
          readOnly
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, exemption),
        submitButtonText: 'Save and Close',
        onClear: onClearHandler,
      })}
    </BasePopup>
  );
};

ExemptionPopup.propTypes = {
  exemption: PropTypes.exact(PatientBillingExemption.schema),
  patientId: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ExemptionPopup;
