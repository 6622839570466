import { attr, fk, Model } from 'redux-orm';

class InteractionTemplate extends Model {
  static modelName = 'InteractionTemplate';

  static fields = {
    id: attr(),
    moduleType: attr(),
    version: attr(),
    isCurrent: attr(),
    sections: attr(),
    title: attr(),
    instructions: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'templateCreator'),
    updatedBy: fk('Client', 'templateChanger'),
  };
}

export default InteractionTemplate;
