import { VitalsLabsTypes } from 'common-src/models/PatientLabReading/constants';

export const TypeOptions = Object.values(VitalsLabsTypes).filter((type) => type.creatable);

export const SourceOptions = [
  { value: true, label: 'Patient Statement' },
  { value: false, label: 'Medical Record' },
];

export const initialState = {
  typeId: VitalsLabsTypes.HbA1c.value,
  readingDate: null,
  readingValue: '',
  patientReported: true,
};
