import PropTypes from 'prop-types';
import React from 'react';

import { hoursPM } from '../../constants';
import styles from './TimeInput.module.scss';
import TimeItem from './TimeItem';

const TimeInput = ({ time, setTime, onChange, withTime, onSaveHandler, onClearHandler }) => {
  const onTimeChange = (value, callback) => {
    const { hours, minutes, period } = value;

    if (period === 'pm') {
      callback(`${hoursPM[hours]}:${minutes < 10 ? `0${minutes}` : minutes}`);
      return;
    }

    callback(`${hours === 12 ? '00' : hours}:${minutes < 10 ? `0${minutes}` : minutes}`);
  };

  const renderButtons = () => (
    <div className={[styles.buttons, 'horizontally-centered', 'gap-12'].join(' ')}>
      <button
        className={[styles.button, 'font-w-500', 'border-r-6', 'font-s-14'].join(' ')}
        type="button"
        onClick={onClearHandler}
      >
        Clear
      </button>
      <button
        className={[styles.button, 'font-w-500', 'border-r-6', 'font-s-14'].join(' ')}
        type="button"
        onClick={onSaveHandler}
      >
        Apply
      </button>
    </div>
  );

  const renderTimefields = () => {
    if (!withTime) return null;

    return (
      <div className={[styles.timeWrapper, 'horizontally-centered'].join(' ')}>
        <TimeItem
          type="number"
          value={time?.hours}
          increaseHandler={() => {
            setTime((prev) => {
              const newTime = { ...prev, hours: prev.hours === 12 ? 1 : prev.hours + 1 };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
          decreaseHandler={() => {
            setTime((prev) => {
              const newTime = { ...prev, hours: prev.hours === 1 ? 12 : prev.hours - 1 };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
          onChangeHandler={(value) => {
            if (value < 1 || value > 12) return;

            setTime((prev) => {
              const newTime = { ...prev, hours: +value };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
        />
        <TimeItem
          type="number"
          value={time?.minutes}
          increaseHandler={() => {
            setTime((prev) => {
              const newTime = { ...prev, minutes: prev.minutes === 59 ? 0 : prev.minutes + 1 };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
          decreaseHandler={() => {
            setTime((prev) => {
              const newTime = { ...prev, minutes: prev.minutes === 0 ? 59 : prev.minutes - 1 };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
          onChangeHandler={(value) => {
            if (value < 0 || value > 59) return;

            setTime((prev) => {
              const newTime = { ...prev, minutes: +value };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
        />
        <TimeItem
          type="text"
          value={time?.period}
          increaseHandler={() => {
            setTime((prev) => {
              const newTime = { ...prev, period: prev.period === 'am' ? 'pm' : 'am' };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
          decreaseHandler={() => {
            setTime((prev) => {
              const newTime = { ...prev, period: prev.period === 'am' ? 'pm' : 'am' };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
          onChangeHandler={(value) => {
            setTime((prev) => {
              const newTime = { ...prev, period: value };
              onTimeChange(newTime, onChange);
              return newTime;
            });
          }}
        />
      </div>
    );
  };

  return (
    <>
      {renderTimefields()}
      {renderButtons()}
    </>
  );
};

TimeInput.propTypes = {
  time: PropTypes.object,
  setTime: PropTypes.func,
  onChange: PropTypes.func,
  withTime: PropTypes.bool,
  onSaveHandler: PropTypes.func,
  onClearHandler: PropTypes.func,
};

export default TimeInput;
