import PropTypes from 'prop-types';
import React from 'react';

import { NoneView } from 'common-src/components/elements';

import styles from './List.module.scss';

const List = ({
  emptyText,
  classNames,
  customStyles,
  size,
  items = [],
  renderItem = () => {},
  emptyTextClassNames = [],
}) => {
  const ulClasses = [styles.list, 'flex-column'];
  if (classNames) {
    ulClasses.push(...classNames);
  }

  const renderItems = () =>
    items.map((item, index) => (
      <li key={item?.key || index.toString()} className="flex-column">
        {renderItem(item)}
      </li>
    ));

  const renderList = () => (
    <ul style={customStyles} className={ulClasses.join(' ')}>
      {renderItems()}
    </ul>
  );
  const renderEmpty = () => (
    <section
      id="empty-list"
      className={[styles.empty, 'vertically-centered', 'horizontally-centered', 'flex-column'].join(
        ' ',
      )}
    >
      <NoneView text={emptyText} size={size} classNames={emptyTextClassNames} />
    </section>
  );

  return items.length > 0 ? renderList() : renderEmpty();
};

List.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func,
  emptyText: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.number,
  emptyTextClassNames: PropTypes.arrayOf(PropTypes.string),
};

export default List;
