import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import styles from './Radio.module.scss';

const Radio = ({
  id = '',
  label = '',
  checked = false,
  classNames = [],
  disabled = false,
  vertical = false,
  onChange = () => {},
  applyBackgroundColor = false,
}) => {
  const ref = useRef();

  const radioClasses = [
    styles.radio,
    'vertically-centered',
    'horizontally-centered',
    'border-r-50',
  ];
  const containerClasses = [
    styles.container,
    'vertically-centered',
    'font-w-500',
    'gap-16',
    'font-s-14',
    ...classNames,
  ];

  if (vertical) {
    containerClasses.push(...[styles.vertical, 'gap-10']);
  }
  if (disabled) {
    containerClasses.push(styles.disabled);
  }
  if (checked) {
    radioClasses.push(styles.checked);
  }

  useEffect(() => {
    if (!ref.current) return;
    ref.current.checked = checked;
  }, [checked]);

  const onClikHandler = (e) => {
    e.stopPropagation();
    if (disabled || checked) return;
    onChange();
  };

  return (
    <div
      className={[...containerClasses, applyBackgroundColor && checked ? styles.checked : ''].join(
        ' ',
      )}
      role="presentation"
      onClick={(e) => onClikHandler(e)}
    >
      <div id={id} className={radioClasses.join(' ')}>
        <input hidden ref={ref} type="radio" defaultChecked={checked} disabled={disabled} />
        <span className={styles.check} />
      </div>
      <p>{label}</p>
    </div>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  vertical: PropTypes.bool,
  applyBackgroundColor: PropTypes.bool,
};

export default Radio;
