import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientTask extends Model {
  static modelName = 'PatientTask';

  static fields = {
    id: attr(),
    subject: attr(),
    status: attr(),
    scheduledFor: attr(),
    link: attr(),
    curie: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    typeId: attr(),
    reasonId: attr(),
    data: attr(),
    createdBy: fk('Client', 'patientTaskCreator'),
    updatedBy: fk('Client', 'patientTaskUpdater'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientTasks',
    }),
    assignee: fk('Client', 'patientTaskAssignee'),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      subject: PropTypes.string,
      status: PropTypes.number,
      scheduledFor: PropTypes.string,
      link: PropTypes.string,
      curie: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
      typeId: PropTypes.number,
      reasonId: PropTypes.number,
      data: PropTypes.object,
      assignee: PropTypes.number,
    }),
    patientId: PropTypes.number,
    typeId: PropTypes.number,
    reasonId: PropTypes.number,
    assignee: PropTypes.number,
  };
}

export default PatientTask;
