import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import PatientAllergy, {
  allergyReactionPresenter,
  allergySeverityPresenter,
  allergyTypePresenter,
  longAllergyTypeLabels,
} from 'common-src/models/PatientAllergy';

import { InfoItem } from 'src/components/elements';
import BasePopup from 'src/popups/BasePopup';

import styles from './AllergyDetailsPopup.module.scss';

const AllergyDetailsPopup = ({ allergy, onClose }) => (
  <BasePopup id="allergy-details" open onClose={onClose} title={allergy.name}>
    <div className={styles.grid}>
      <InfoItem
        title="Date Added"
        content={allergy.createdAt && moment(allergy.createdAt).format('MM/DD/YYYY')}
        textId="date-added"
        small
      />
      <InfoItem
        classNames={[styles.width75]}
        title="Added By"
        content={allergy.createdBy?.getName(true, false)}
        textId="added-by"
        small
      />
      <InfoItem
        title="Start Date"
        content={allergy.startDate && moment(allergy.startDate).format('MM/DD/YYYY')}
        textId="start-date"
        small
      />
      <InfoItem
        title="End Date"
        content={allergy.endDate && moment(allergy.endDate).format('MM/DD/YYYY')}
        textId="end-date"
        small
      />
      <div />
      <InfoItem
        classNames={[longAllergyTypeLabels.includes(allergy.type) ? styles.width75 : '']}
        title="Type"
        content={allergyTypePresenter(allergy.type)}
        textId="type"
        small
      />
      <InfoItem
        title="Reaction"
        content={allergyReactionPresenter(allergy.reaction)}
        textId="reaction"
        small
      />
      <InfoItem
        title="Severity"
        content={allergySeverityPresenter(allergy.severity)}
        textId="severity"
        small
      />
    </div>
    <InfoItem
      classNames={[styles.comments, 'primary-border-t']}
      title="Comments"
      content={allergy.comments}
      textId="comments"
      small
    />
  </BasePopup>
);

AllergyDetailsPopup.propTypes = {
  onClose: PropTypes.func,
  allergy: PropTypes.exact(PatientAllergy.schema),
};

export default AllergyDetailsPopup;
