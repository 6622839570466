import PropTypes from 'prop-types';
import React from 'react';

import {
  platformStatusDescriptionForPopup,
  platformStatusTitleForPopup,
} from 'common-src/models/Patient/presenters';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './PatientStatusPopup.module.scss';

const PatientStatusPopup = ({ open, onClose, onSubmit, patientStatus, title, subtitle }) => {
  const { renderButtons } = usePopup();

  return (
    <BasePopup
      id="patient-status"
      open={open}
      onClose={onClose}
      title={title || platformStatusTitleForPopup(patientStatus)}
    >
      <div className={[styles.subtitle, 'font-s-16'].join(' ')}>
        {subtitle || platformStatusDescriptionForPopup(patientStatus)}
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        buttonClassName: styles.button,
        onClose,
        onSubmit: (loadingCallback) => {
          loadingCallback();
          onSubmit();
        },
        submitButtonText: 'Continue',
        isSubmitEnabled: true,
      })}
    </BasePopup>
  );
};

PatientStatusPopup.propTypes = {
  patientStatus: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PatientStatusPopup;
