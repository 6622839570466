export const SdohTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  RESOLVED: {
    text: 'Resolved',
  },
  ALL: {
    text: 'All',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: SdohTabs.ACTIVE.text,
    count: 0,
  },
  RESOLVED: {
    text: SdohTabs.RESOLVED.text,
    count: 0,
  },
  ALL: {
    text: SdohTabs.ALL.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'socialDeterminant', label: 'Social Determinant', width: '29%', sortBy: 'value' },
  { column: 'note', label: 'Notes', width: '23%', sortBy: 'value' },
  { column: 'createdAt', label: 'Created', width: '10%', sortBy: 'value' },
  { column: 'updatedAt', label: 'Updated', width: '10%', sortBy: 'value' },
  { column: 'status', label: 'Status', width: '10%', sortBy: 'value' },
  { column: 'actions', label: 'Actions', width: '18%' },
];
