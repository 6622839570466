// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HighlightedText-module__highlighted___LrXXc {
  background-color: #f1bf72;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/HighlightedText/HighlightedText.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted": `HighlightedText-module__highlighted___LrXXc`
};
module.exports = ___CSS_LOADER_EXPORT___;
