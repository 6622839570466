import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientMedicalProblem extends Model {
  static modelName = 'PatientMedicalProblem';

  static fields = {
    id: attr(),
    icd10Code: attr(),
    comments: attr(),
    dxDate: attr(),
    dxStatus: attr(),
    resolvedDate: attr(),
    problemType: attr(),
    patientRelationship: attr(),
    activeTill: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'medicalProblemsChanger'),
    createdBy: fk('Client', 'medicalProblemsCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'medicalProblems',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      icd10Code: PropTypes.string,
      comments: PropTypes.string,
      dxDate: PropTypes.string,
      dxStatus: PropTypes.number,
      resolvedDate: PropTypes.string,
      problemType: PropTypes.number,
      patientRelationship: PropTypes.number,
      activeTill: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientMedicalProblem;
