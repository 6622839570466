// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PatientDevicePopup-module__input___ISRlX {
  padding: 20px 30px;
}

.PatientDevicePopup-module__buttonsContainer___nBglv {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 19px 30px;
  gap: 16px;
  border-top: 1px solid rgba(91, 91, 91, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/popups/PatientDevicePopup/PatientDevicePopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,SAAA;EACA,2CAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `PatientDevicePopup-module__input___ISRlX`,
	"buttonsContainer": `PatientDevicePopup-module__buttonsContainer___nBglv`
};
module.exports = ___CSS_LOADER_EXPORT___;
