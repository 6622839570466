import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientSdoh extends Model {
  static modelName = 'PatientSdoh';

  static fields = {
    id: attr(),
    status: attr(),
    note: attr(),
    deletedAt: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'sdohCreator'),
    updatedBy: fk('Client', 'sdohChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'sdoh',
    }),
    sdohCodeId: fk('SdohCode', 'sdohCode'),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      status: PropTypes.number,
      note: PropTypes.string,
      deletedAt: PropTypes.string,
      updatedAt: PropTypes.string,
      createdAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
      sdohCodeId: PropTypes.string,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientSdoh;
