export const validatePatientFamilyHistory = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'diagnosis':
      if (!value) {
        errMsg = 'Diagnosis should not be empty';
      }
      break;
    case 'relationship':
      if (!value) {
        errMsg = 'Relationship should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
