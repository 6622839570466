export const DocumentationTabs = {
  DOCUMENTATION: {
    id: 0,
    text: 'Completed Notes',
    count: 0,
  },
  DRAFTS: {
    id: 1,
    text: 'Paused',
    count: 0,
  },
};

export const DOCUMENTATION_POLLING_FREQUENCY = 5000;
export const REQUEST_PAGE_SIZE = 20;
