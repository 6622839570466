import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Select } from 'common-src/components/base';

import Icons from 'src/assets/Icons';

import {
  initialCurrentPageState,
  PAGINATION_LENGTH,
  ResultsCount,
  resultsCountOptions,
  THREE_DOTS_INDEX,
} from './constants';
import styles from './TablePagination.module.scss';

const TablePagination = ({
  results,
  onChangePageHandler,
  selectedTab,
  filters,
  containerClassNames,
}) => {
  const [resultsCount, setResultsCount] = useState(ResultsCount[25]);
  const [pagesCount, setPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(initialCurrentPageState);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState();

  const prevCurrentPageIndex = useRef(initialCurrentPageState.index);

  const containerClasses = [
    'flex-row',
    'vertically-centered',
    'gap-10',
    'm-l-auto',
    'p-r-30',
    'm-t-10',
    'm-b-10',
  ];
  if (containerClassNames) {
    containerClasses.push(...containerClassNames);
  }

  useEffect(() => {
    setTo(pagesCount);
  }, [pagesCount]);

  useEffect(() => {
    setCurrentPage(initialCurrentPageState);
    setFrom(0);
    setTo();
  }, [selectedTab, filters]);

  useEffect(() => {
    if (_.isEmpty(results)) return;
    setPagesCount(Math.ceil(results.length / resultsCount));
    setCurrentPage(initialCurrentPageState);
  }, [resultsCount]);

  useEffect(() => {
    if (_.isEmpty(results)) return;
    setPagesCount(Math.ceil(results.length / resultsCount));
  }, [results]);

  useEffect(() => {
    const from = currentPage.value === 1 ? 0 : currentPage.value * resultsCount - resultsCount;
    const to = currentPage.value === 1 ? resultsCount : currentPage.value * resultsCount;
    onChangePageHandler({ from, to });
  }, [currentPage.value, pagesCount, results.length]);

  useEffect(() => {
    // when user clicks page before three dots
    if (currentPage.index === THREE_DOTS_INDEX - 1) {
      if (to - from === PAGINATION_LENGTH) return;
      if (pagesCount - (from + 2) > PAGINATION_LENGTH) {
        setFrom((prev) => (!prev ? 2 : prev + 2));
        setCurrentPage((prev) => ({ ...prev, index: 0 }));
      } else if (pagesCount - (from + 2) >= PAGINATION_LENGTH) {
        setFrom((prev) => (!prev ? 2 : prev + 2));
        setCurrentPage((prev) => ({
          ...prev,
          index: pagesCount - currentPage.value + 1 === PAGINATION_LENGTH ? 0 : 1,
        }));
      } else if (pagesCount - (from + 1) >= PAGINATION_LENGTH) {
        setFrom((prev) => prev + 1);
        setCurrentPage((prev) => ({ ...prev, index: 1 }));
      }
      return;
    }

    // when user clicks first page
    if (currentPage.value === initialCurrentPageState.value) {
      setFrom(0);
      setTo(pagesCount);
      setCurrentPage(initialCurrentPageState);
      return;
    }

    // when user clicks previuos button and previuos pages are hidden
    if (currentPage.index < 0 && from - 2 >= 0) {
      setFrom((prev) => prev - 2);
      setCurrentPage((prev) => ({ ...prev, index: 1 }));
      return;
    }

    // when user clicks page after three dots
    if (currentPage.index > THREE_DOTS_INDEX && currentPage.value <= pagesCount) {
      if (prevCurrentPageIndex.current < currentPage.index && to + 1 <= pagesCount) {
        if (to === PAGINATION_LENGTH) {
          setCurrentPage((prev) => ({ ...prev, index: 2 }));
          setFrom((prev) => (!prev ? 2 : prev + 2));
          setTo(pagesCount);
        } else {
          setTo((prev) => prev + 1);
        }
      } else if (
        prevCurrentPageIndex.current > currentPage.index &&
        to - 1 - from >= PAGINATION_LENGTH
      ) {
        setTo((prev) => prev - 1);
      }
    }

    prevCurrentPageIndex.current = currentPage.index;
  }, [currentPage.index]);

  const renderPages = () =>
    Array.from({ length: pagesCount }, (_, i) => i + 1)
      .slice(from, to)
      .map((page, i, arr) => {
        const classNames = [styles.button, 'font-s-14', 'primary-border-r'];
        if (i === 0) {
          classNames.push('primary-border-l');
        }
        if (page === currentPage.value) {
          classNames.push(styles.selected);
        }
        if (arr.length > PAGINATION_LENGTH) {
          if (i === THREE_DOTS_INDEX) {
            return (
              <span
                key={`three-dots-${page}`}
                className={[
                  'font-s-14',
                  'primary-border-r',
                  'vertically-centered',
                  'horizontally-centered',
                ].join(' ')}
              >
                ...
              </span>
            );
          }
          if (i >= THREE_DOTS_INDEX + 1 && i + 2 < arr.length) {
            return null;
          }
        }

        return (
          <button
            key={`page-${page}`}
            className={classNames.join(' ')}
            type="button"
            onClick={() => setCurrentPage({ value: page, index: i })}
          >
            {page}
          </button>
        );
      });

  const renderPagination = () => (
    <div className={[styles.pagination, 'flex-row', 'border-r-3'].join(' ')}>
      <button
        className={styles.arrowButton}
        type="button"
        disabled={currentPage.value === 1}
        onClick={() => setCurrentPage((prev) => ({ value: prev.value - 1, index: prev.index - 1 }))}
      >
        <img src={Icons.arrowPreviousIcon} alt="arrow-left" />
      </button>
      {renderPages()}
      <button
        className={styles.arrowButton}
        type="button"
        disabled={currentPage.value >= pagesCount}
        onClick={() => setCurrentPage((prev) => ({ value: prev.value + 1, index: prev.index + 1 }))}
      >
        <img src={Icons.arrowNextIcon} alt="arrow-right" />
      </button>
    </div>
  );

  if (_.isEmpty(results) || results?.length <= ResultsCount[25]) return null;

  return (
    <div className={containerClasses.join(' ')}>
      {renderPagination()}
      <Select
        id="results-count-select"
        classNames={[styles.select]}
        options={resultsCountOptions}
        onChange={(op) => setResultsCount(op.value)}
        value={resultsCountOptions.find((op) => op.value === resultsCount)}
        withError={false}
        size="small"
        downArrowIconSrc={Icons.chevronDownIcon}
        showOptionIcon={false}
        useRoundedCorners={false}
        usePrimaryBorder
      />
    </div>
  );
};

TablePagination.propTypes = {
  results: PropTypes.array,
  onChangePageHandler: PropTypes.func,
  selectedTab: PropTypes.string,
  filters: PropTypes.object,
  containerClassNames: PropTypes.arrayOf(PropTypes.string),
};

export default TablePagination;
