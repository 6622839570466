import _ from 'lodash';

import {
  Gender,
  languageOptions as languageOptionsArray,
  Race,
  sexOptions as sexOptionsArray,
} from 'common-src/models/Patient/constants';

export const sexOptions = _.map(sexOptionsArray, (option) => ({ value: option, label: option }));

export const languageOptions = _.map(languageOptionsArray, (option) => ({
  value: option,
  label: option,
}));

export const genderOptions = Object.values(Gender);

export const raceOptions = Object.values(Race);
