import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientWellnessPlansRequest = (query, params) =>
  restRequest({
    uri: 'patientWellnessPlans',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createWellnessPlan = (body, params = {}) =>
  restRequest({
    uri: 'patientWellnessPlans',
    method: HttpMethods.Post,
    body: { ...body },
    ...params,
  });

export const updateWellnessPlan = (id, body, params = {}) =>
  restRequest({
    uri: `patientWellnessPlans/${id}`,
    method: HttpMethods.Patch,
    body: { ...body },
    ...params,
  });
