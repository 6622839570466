import _ from 'lodash';

export const extractExemption = (exemption = {}) => ({
  exemptionType: exemption.exemptionType,
  startDate: exemption.startDate || null,
  endDate: exemption.endDate || null,
  source: exemption.source,
});

export const getIsButtonEnabled = (data, errors, exemption) => {
  if (
    !data.startDate ||
    Object.values(errors).some((e) => e) ||
    (exemption && _.isEqual(data, extractExemption(exemption)))
  )
    return false;

  return true;
};
