/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
import _ from 'lodash';

import { fulfillmentProducts } from './constants';

export const getStripsPercentage = (orders, remainingStrips) => {
  if (!Array.isArray(orders) || _.isEmpty(orders)) return 0;

  const totalStrips = _.reduce(
    orders,
    (result, order) => {
      let currentResult = result;
      _.forEach(order?.lineItems || [], (productItem) => {
        const fulfillmentProduct = _.find(
          Object.values(fulfillmentProducts),
          (value) => productItem.product === value.id,
        );
        const stripsQuantity = fulfillmentProduct?.stripsQuantity || 0;
        currentResult += stripsQuantity;
      });

      return currentResult;
    },
    0,
  );

  if (totalStrips === 0) return 0;

  return (remainingStrips / totalStrips) * 100;
};

export const getLineColor = (percentage) => {
  let color;
  switch (true) {
    case percentage > 50:
      color = '#0ECB87';
      break;
    case percentage >= 25:
      color = '#FFAB48';
      break;
    default:
      color = '#FB7873';
      break;
  }

  return color;
};
