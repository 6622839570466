import { VitalsLabsTypes } from 'common-src/models/PatientLabReading';
import { calculateBMI } from 'common-src/models/PatientVital';

export const getLastLabResults = (labResults) => {
  const data = {};
  const lastHeightResult = labResults.find(
    (result) => result.typeId === VitalsLabsTypes.Height.value,
  );
  const lastWeightResult = labResults.find(
    (result) => result.typeId === VitalsLabsTypes.Weight.value,
  );
  const lastHbA1c = labResults.find((result) => result.typeId === VitalsLabsTypes.HbA1c.value);

  if (lastHeightResult) {
    Object.assign(data, {
      lastHeightResult: {
        value: lastHeightResult.readingValue,
        date: lastHeightResult.updatedAt,
      },
    });
  }

  if (lastWeightResult) {
    Object.assign(data, {
      lastWeightResult: {
        value: lastWeightResult.readingValue,
        date: lastWeightResult.updatedAt,
      },
    });
  }

  if (lastHbA1c) {
    Object.assign(data, {
      lastHbA1c: {
        value: lastHbA1c.readingValue,
        date: lastHbA1c.updatedAt,
      },
    });
  }

  if (lastHeightResult && lastWeightResult) {
    Object.assign(data, {
      estimatedBMI: {
        value: calculateBMI(lastWeightResult.readingValue, lastHeightResult.readingValue),
      },
    });
  }

  return data;
};
