import { roles } from 'common-src/models/Client/constants';
import { InteractionReason, InteractionType } from 'common-src/models/PatientInteraction/constants';
import { moduleTypes } from 'common-src/models/PatientInteraction/protocolModules';

export const defaultPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: false,
  patientChart: {
    actions: {
      canAddLabResult: false,
      canCloseAccount: false,
      canAddTask: false,
      canAddDocumentation: false,
      canPlaceAccountOnHold: false,
      canChangeStatusToActive: false,
      canRpmApprove: false,
      canAddSmartGoal: false,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: false,
      canSendDocumentation: false,
      canAddMedication: false,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: false,
      contacts: false,
      glucoseReadings: false,
      vitalsAndLabResults: {
        editLabResult: false,
        deleteLabResult: false,
      },
      smartGoals: false,
      conversationHistory: false,
      physicianOrders: {
        setRiskLevel: false,
        setAssignedProviders: false,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: false,
        setInsulinUse: false,
        setCGMUse: false,
        setInsulinPump: false,
        setStripOrders: false,
        setTestingOrders: false,
        addAdditionalTestingInfo: false,
      },
      alerts: false,
      medicationsList: false,
      allergyList: false,
      medicalHistory: false,
      familyHistory: false,
      socialHistory: false,
      supplementsList: false,
      encountersList: false,
      surgicalHistory: false,
      scheduledCalls: {
        canEditScheduledCall: false,
      },
      careProviders: false,
      documentation: {
        addendAnyNote: false,
        addDocumentation: false,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: false,
        placeOrder: false,
        setStripCount: false,
        addCGMDevice: false,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: false,
        addPlan: false,
        editPlan: false,
        changePlanStatus: false,
      },
      payorPriority: {
        editHierarchy: false,
      },
      preAuths: {
        addAuth: false,
        editAuth: false,
      },
      exemptions: {
        addExemption: false,
        editExemption: false,
      },
      cgmOrders: {
        addCGMDevice: false,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: false,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: false,
        addTask: false,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {},
    other: {},
    questionnaire: {},
    caseWork: {},
  },
  note: {},
});

export const adminPermissions = Object.freeze({
  canSetFeatureFlags: true,
  canCreateClient: true,
  canManageClients: true,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: true,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: true,
      canAddSmartGoal: true,
      canAddPrescription: true,
      canReopenAccount: true,
      canRequestMedicationUpdates: true,
      canSendDocumentation: true,
      canAddMedication: true,
      canAddNewIntake: true,
      canMfaApprove: true,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: true,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: true,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: true,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: true,
        addDocumentation: true,
        requestAdjustments: true,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: true,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: true,
        markAsFailed: true,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: true,
      },
      payorPriority: {
        editHierarchy: true,
      },
      preAuths: {
        addAuth: true,
        editAuth: true,
      },
      exemptions: {
        addExemption: true,
        editExemption: true,
      },
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: true,
        viewCGMOrder: true,
      },
      team: true,
      sdohList: {
        addSdoh: true,
        editSdoh: true,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {
      [moduleTypes.Onboarding.id]: true,
      [moduleTypes.CoachIntroduction.id]: true,
      [moduleTypes.SelfServeIntakeConfirmation.id]: true,
      [moduleTypes.SelfServeIntakeLifestyle.id]: true,
      [moduleTypes.SelfServeIntakeMedical.id]: true,
    },
    clinical: {
      [moduleTypes.Hypoglycemia.id]: true,
      [moduleTypes.Hyperglycemia.id]: true,
      [moduleTypes.MonthlyCall.id]: true,
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.LowEngagement.id]: true,
      [moduleTypes.NutritionFollowUp.id]: true,
      [moduleTypes.EducationDelivery.id]: true,
      [moduleTypes.MedicationReconciliation.id]: true,
      [moduleTypes.PhysicianNote.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.CloseRequest.id]: true,
      [moduleTypes.QuarterlyCheckIn.id]: true,
      [moduleTypes.ClinicalFirstVisitRpmOrder.id]: true,
      [moduleTypes.InitialCoachingCall.id]: true,
      [moduleTypes.MonthlyWellnessVisitAC.id]: true,
      [moduleTypes.CGMAssessment.id]: true,
      [moduleTypes.CGMQualificationExistingMembers.id]: true,
      [moduleTypes.MNTNote.id]: true,
      [moduleTypes.CareCoordination.id]: true,
      [moduleTypes.BiannualVisit.id]: true,
      [moduleTypes.ChartReview.id]: true,
      [moduleTypes.InsulinPumpAssessment.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.DeviceWalkthrough.id]: true,
      [moduleTypes.MFADecision.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.CommercialPlanReview.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {
      [moduleTypes.CaseWorkAssessment.id]: true,
      [moduleTypes.CaseWorkProgressNote.id]: true,
    },
  },
  note: {
    [InteractionType.Telehealth.id]: [
      InteractionReason.FirstVisit.id,
      InteractionReason.BiAnnualCheckup.id,
      InteractionReason.Psychotherapy.id,
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherEvaluationAndManagement.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.PhoneCall.id]: [
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.Psychotherapy.id,
      InteractionReason.OtherRpm.id,
      InteractionReason.OtherEvaluationAndManagement.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
    [InteractionType.Enrollment.id]: [InteractionReason.Intake.id],
  },
});

export const physicianPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: true,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: true,
      canAddSmartGoal: true,
      canAddPrescription: true,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: true,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: true,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: true,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: true,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: true,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: true,
        viewCGMOrder: true,
      },
      team: true,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {
      [moduleTypes.Onboarding.id]: true,
      [moduleTypes.SelfServeIntakeConfirmation.id]: true,
      [moduleTypes.SelfServeIntakeLifestyle.id]: true,
      [moduleTypes.SelfServeIntakeMedical.id]: true,
    },
    clinical: {
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.MedicationReconciliation.id]: true,
      [moduleTypes.PhysicianNote.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.CloseRequest.id]: true,
      [moduleTypes.QuarterlyCheckIn.id]: true,
      [moduleTypes.ClinicalFirstVisitRpmOrder.id]: true,
      [moduleTypes.CGMAssessment.id]: true,
      [moduleTypes.CGMQualificationExistingMembers.id]: true,
      [moduleTypes.CareCoordination.id]: true,
      [moduleTypes.BiannualVisit.id]: true,
      [moduleTypes.ChartReview.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.DeviceWalkthrough.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.CommercialPlanReview.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.Telehealth.id]: [
      InteractionReason.FirstVisit.id,
      InteractionReason.BiAnnualCheckup.id,
      InteractionReason.Psychotherapy.id,
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherEvaluationAndManagement.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const cdePermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: true,
        assignedAsLcsw: false,
        setDiagnosis: true,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: true,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: false,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: true,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {
      [moduleTypes.Hypoglycemia.id]: true,
      [moduleTypes.Hyperglycemia.id]: true,
      [moduleTypes.MonthlyCall.id]: true,
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.LowEngagement.id]: true,
      [moduleTypes.EducationDelivery.id]: true,
      [moduleTypes.MedicationReconciliation.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.QuarterlyCheckIn.id]: true,
      [moduleTypes.CGMAssessment.id]: true,
      [moduleTypes.CareCoordination.id]: true,
      [moduleTypes.ChartReview.id]: true,
      [moduleTypes.InsulinPumpAssessment.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.PhoneCall.id]: [
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherRpm.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const rnPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: true,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: true,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: false,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: true,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {
      [moduleTypes.Hypoglycemia.id]: true,
      [moduleTypes.Hyperglycemia.id]: true,
      [moduleTypes.MonthlyCall.id]: true,
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.LowEngagement.id]: true,
      [moduleTypes.EducationDelivery.id]: true,
      [moduleTypes.MedicationReconciliation.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.CGMAssessment.id]: true,
      [moduleTypes.QuarterlyCheckIn.id]: true,
      [moduleTypes.CareCoordination.id]: true,
      [moduleTypes.ChartReview.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.PhoneCall.id]: [
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherRpm.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const accountabilityCoachPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: true,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: false,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: 'view-only',
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: false,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: true,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {
      [moduleTypes.CoachIntroduction.id]: true,
    },
    clinical: {
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.LowEngagement.id]: true,
      [moduleTypes.EducationDelivery.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.InitialCoachingCall.id]: true,
      [moduleTypes.MonthlyWellnessVisitAC.id]: true,
      [moduleTypes.CGMAssessment.id]: true,
      [moduleTypes.CareCoordination.id]: true,
      [moduleTypes.ChartReview.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.DeviceWalkthrough.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.CommercialPlanReview.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.PhoneCall.id]: [
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherRpm.id,
    ],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const externalPhysicianPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: false,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: false,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: false,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: false,
      canSendDocumentation: false,
      canAddMedication: false,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: 'view-only',
      smartGoals: 'view-only',
      conversationHistory: true,
      physicianOrders: 'view-only',
      alerts: 'view-only',
      medicationsList: 'view-only',
      allergyList: 'view-only',
      medicalHistory: 'view-only',
      familyHistory: 'view-only',
      socialHistory: 'view-only',
      supplementsList: 'view-only',
      encountersList: 'view-only',
      surgicalHistory: 'view-only',
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: false,
        requestAdjustments: false,
      },
      devicesAndOrders: 'view-only',
      knownPlans: 'view-only',
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: 'view-only',
      team: false,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  note: {},
});

export const viewerPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: false,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: false,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: false,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: false,
      canSendDocumentation: false,
      canAddMedication: false,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: 'view-only',
      smartGoals: 'view-only',
      conversationHistory: true,
      physicianOrders: 'view-only',
      alerts: 'view-only',
      medicationsList: 'view-only',
      allergyList: 'view-only',
      medicalHistory: 'view-only',
      familyHistory: 'view-only',
      socialHistory: 'view-only',
      supplementsList: 'view-only',
      encountersList: 'view-only',
      surgicalHistory: 'view-only',
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: false,
        requestAdjustments: false,
      },
      devicesAndOrders: 'view-only',
      knownPlans: 'view-only',
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: 'view-only',
      team: false,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  note: {},
});

export const enrollmentPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: false,
      canChangeStatusToActive: false,
      canRpmApprove: false,
      canAddSmartGoal: false,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: false,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: true,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: 'view-only',
      conversationHistory: true,
      physicianOrders: 'view-only',
      alerts: false,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: 'view-only',
      supplementsList: true,
      encountersList: 'view-only',
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: 'view-only',
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: {
        editHierarchy: true,
      },
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: 'view-only',
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {
      [moduleTypes.Onboarding.id]: true,
      [moduleTypes.SelfServeIntakeConfirmation.id]: true,
      [moduleTypes.SelfServeIntakeLifestyle.id]: true,
      [moduleTypes.SelfServeIntakeMedical.id]: true,
    },
    clinical: {
      [moduleTypes.QuickNote.id]: true,
    },
    other: {
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.CommercialPlanReview.id]: true,
    },
    questionnaire: {},
    caseWork: {},
  },
  note: {
    [InteractionType.Enrollment.id]: [InteractionReason.Intake.id],
  },
});

export const mxPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: false,
      canAddSmartGoal: false,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: false,
      canSendDocumentation: true,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: 'view-only',
      conversationHistory: true,
      physicianOrders: 'view-only',
      alerts: false,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: 'view-only',
      supplementsList: true,
      encountersList: 'view-only',
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: true,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: true,
        viewCGMOrder: true,
      },
      team: 'view-only',
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {
      [moduleTypes.Onboarding.id]: true,
      [moduleTypes.SelfServeIntakeConfirmation.id]: true,
      [moduleTypes.SelfServeIntakeLifestyle.id]: true,
      [moduleTypes.SelfServeIntakeMedical.id]: true,
    },
    clinical: {
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.LowEngagement.id]: true,
      [moduleTypes.HoldRequest.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.DeviceWalkthrough.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.CommercialPlanReview.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.Telehealth.id]: [InteractionReason.OtherNonBillable.id],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [InteractionReason.OtherNonBillable.id],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const rdPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: true,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: true,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: true,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: false,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: true,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {
      [moduleTypes.Hypoglycemia.id]: true,
      [moduleTypes.Hyperglycemia.id]: true,
      [moduleTypes.MonthlyCall.id]: true,
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.LowEngagement.id]: true,
      [moduleTypes.NutritionFollowUp.id]: true,
      [moduleTypes.EducationDelivery.id]: true,
      [moduleTypes.MedicationReconciliation.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.QuarterlyCheckIn.id]: true,
      [moduleTypes.CGMAssessment.id]: true,
      [moduleTypes.MNTNote.id]: true,
      [moduleTypes.CareCoordination.id]: true,
      [moduleTypes.ChartReview.id]: true,
      [moduleTypes.InsulinPumpAssessment.id]: true,
    },
    other: {
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.PhoneCall.id]: [
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherRpm.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const npPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: true,
      canRpmApprove: true,
      canAddSmartGoal: true,
      canAddPrescription: true,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: true,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: true,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: false,
        setDiagnosis: true,
        setInsulinUse: true,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: true,
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: true,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: true,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: false,
      },
      payorPriority: 'view-only',
      preAuths: 'view-only',
      exemptions: 'view-only',
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: true,
        viewCGMOrder: true,
      },
      team: true,
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.ClinicalFirstVisitRpmOrder.id]: true,
      [moduleTypes.CGMQualificationExistingMembers.id]: true,
      [moduleTypes.BiannualVisit.id]: true,
    },
    other: {
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PerryHealthAssessment.id]: true,
      [moduleTypes.PerryMotivationQuestionnaire.id]: true,
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {},
  },
  note: {
    [InteractionType.Telehealth.id]: [
      InteractionReason.FirstVisit.id,
      InteractionReason.BiAnnualCheckup.id,
      InteractionReason.Psychotherapy.id,
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.OtherEvaluationAndManagement.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const rcmPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: false,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: false,
      canChangeStatusToActive: false,
      canRpmApprove: false,
      canAddSmartGoal: false,
      canAddPrescription: false,
      canReopenAccount: false,
      canRequestMedicationUpdates: false,
      canSendDocumentation: true,
      canAddMedication: false,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: 'view-only',
      smartGoals: 'view-only',
      conversationHistory: true,
      physicianOrders: 'view-only',
      alerts: false,
      medicationsList: 'view-only',
      allergyList: 'view-only',
      medicalHistory: 'view-only',
      familyHistory: 'view-only',
      socialHistory: 'view-only',
      supplementsList: 'view-only',
      encountersList: 'view-only',
      surgicalHistory: 'view-only',
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: 'view-only',
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: true,
      },
      payorPriority: {
        editHierarchy: true,
      },
      preAuths: {
        addAuth: true,
        editAuth: true,
      },
      exemptions: {
        addExemption: true,
        editExemption: true,
      },
      cgmOrders: 'view-only',
      team: 'view-only',
      sdohList: {
        addSdoh: false,
        editSdoh: false,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {
      [moduleTypes.QuickNote.id]: true,
    },
    other: {
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.CommercialPlanReview.id]: true,
    },
    questionnaire: {},
    caseWork: {},
  },
  note: {
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const systemPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: false,
  patientChart: {
    actions: {},
    sections: {},
  },
  documentation: {
    enrollment: {},
    clinical: {},
    other: {},
    questionnaire: {},
    caseWork: {},
  },
  note: {},
});

export const socialWorkerPermissions = Object.freeze({
  canSetFeatureFlags: false,
  canCreateClient: false,
  canManageClients: false,
  isVisibleInApp: true,
  patientChart: {
    actions: {
      canAddLabResult: true,
      canCloseAccount: false,
      canAddTask: true,
      canAddDocumentation: true,
      canPlaceAccountOnHold: true,
      canChangeStatusToActive: false,
      canRpmApprove: false,
      canAddSmartGoal: true,
      canAddPrescription: true,
      canReopenAccount: false,
      canRequestMedicationUpdates: true,
      canSendDocumentation: false,
      canAddMedication: true,
      canAddNewIntake: false,
      canMfaApprove: false,
    },
    sections: {
      memberInformation: true,
      contacts: true,
      glucoseReadings: true,
      vitalsAndLabResults: {
        editLabResult: true,
        deleteLabResult: false,
      },
      smartGoals: true,
      conversationHistory: true,
      physicianOrders: {
        setRiskLevel: false,
        setAssignedProviders: true,
        assignedAsAC: false,
        assignedAsRD: false,
        assignedAsCDCES: false,
        assignedAsLcsw: true,
        setDiagnosis: false,
        setInsulinUse: false,
        setCGMUse: true,
        setInsulinPump: true,
        setStripOrders: true,
        setTestingOrders: true,
        addAdditionalTestingInfo: true,
      },
      alerts: 'view-only',
      medicationsList: true,
      allergyList: true,
      medicalHistory: true,
      familyHistory: true,
      socialHistory: true,
      supplementsList: true,
      encountersList: true,
      surgicalHistory: true,
      scheduledCalls: {
        canEditScheduledCall: true,
      },
      careProviders: true,
      documentation: {
        addendAnyNote: false,
        addDocumentation: true,
        requestAdjustments: false,
      },
      devicesAndOrders: {
        addBGMDevice: true,
        placeOrder: false,
        setStripCount: true,
        addCGMDevice: true,
        markAsCanceled: false,
        markAsFailed: false,
      },
      knownPlans: {
        editBeneficiaryInfo: true,
        addPlan: true,
        editPlan: true,
        changePlanStatus: true,
      },
      payorPriority: {
        editHierarchy: true,
      },
      preAuths: {
        addAuth: true,
        editAuth: true,
      },
      exemptions: {
        addExemption: true,
        editExemption: true,
      },
      cgmOrders: {
        addCGMDevice: true,
        addCGMOrder: false,
        viewCGMOrder: false,
      },
      team: true,
      sdohList: {
        addSdoh: true,
        editSdoh: true,
      },
      tasks: {
        editTask: true,
        addTask: true,
      },
    },
  },
  documentation: {
    enrollment: {},
    clinical: {
      [moduleTypes.QuickNote.id]: true,
      [moduleTypes.ChartReview.id]: true,
      [moduleTypes.CareCoordination.id]: true,
    },
    other: {
      [moduleTypes.HoldRequest.id]: true,
      [moduleTypes.MFARequest.id]: true,
      [moduleTypes.TechnicalSupport.id]: true,
      [moduleTypes.MFARenewalRequest.id]: true,
    },
    questionnaire: {
      [moduleTypes.PHQ9GAD7Assessment.id]: true,
    },
    caseWork: {
      [moduleTypes.CaseWorkAssessment.id]: true,
      [moduleTypes.CaseWorkProgressNote.id]: true,
    },
  },
  note: {
    [InteractionType.PhoneCall.id]: [
      InteractionReason.RemoteMonitoring.id,
      InteractionReason.Psychotherapy.id,
      InteractionReason.OtherRpm.id,
    ],
    [InteractionType.CaseManagement.id]: [InteractionReason.CaseManagement.id],
    [InteractionType.ChartReview.id]: [InteractionReason.ChartReview.id],
    [InteractionType.CareCoordination.id]: [
      InteractionReason.ExternalConsult.id,
      InteractionReason.InternalConsult.id,
      InteractionReason.RecordsRequest.id,
      InteractionReason.EmergencyContactOutreach.id,
      InteractionReason.EmsEscalation.id,
      InteractionReason.OtherNonBillable.id,
    ],
    [InteractionType.Message.id]: [InteractionReason.Message.id],
    [InteractionType.Administrative.id]: [InteractionReason.Administrative.id],
  },
});

export const defaultRolePermissions = Object.freeze({
  [roles.Admin.id]: adminPermissions,
  [roles.Viewer.id]: viewerPermissions,
  [roles.MXSpecialist.id]: mxPermissions,
  [roles.RN.id]: rnPermissions,
  [roles.CDE.id]: cdePermissions,
  [roles.Physician.id]: physicianPermissions,
  [roles.ExternalPhysician.id]: externalPhysicianPermissions,
  [roles.Enrollment.id]: enrollmentPermissions,
  [roles.AccountabilityCoach.id]: accountabilityCoachPermissions,
  [roles.RD.id]: rdPermissions,
  [roles.NP.id]: npPermissions,
  [roles.RCM.id]: rcmPermissions,
  [roles.System.id]: systemPermissions,
  [roles.SocialWorker.id]: socialWorkerPermissions,
});

export const strictRolePermissions = Object.freeze({
  [roles.Admin.id]: { canSetCallDisposition: false },
  [roles.Viewer.id]: { canSetCallDisposition: false },
  [roles.MXSpecialist.id]: { canSetCallDisposition: true },
  [roles.RN.id]: { canSetCallDisposition: true },
  [roles.CDE.id]: { canSetCallDisposition: true },
  [roles.Physician.id]: { canSetCallDisposition: true },
  [roles.ExternalPhysician.id]: { canSetCallDisposition: false },
  [roles.Enrollment.id]: { canSetCallDisposition: false },
  [roles.AccountabilityCoach.id]: { canSetCallDisposition: true },
  [roles.RD.id]: { canSetCallDisposition: true },
  [roles.NP.id]: { canSetCallDisposition: true },
  [roles.RCM.id]: { canSetCallDisposition: false },
  [roles.System.id]: { canSetCallDisposition: false },
  [roles.SocialWorker.id]: { canSetCallDisposition: true },
});
