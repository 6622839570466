import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, TextArea } from 'common-src/components/base';
import PatientSdoh, {
  addSdoh,
  getPatientSdoh,
  getStatusById,
  SdohStatus,
  updateSdoh,
  validatePatientSdoh,
} from 'common-src/models/PatientSdoh';
import { getSdohCodes } from 'common-src/models/SdohCode';

import { InfoItem } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import {
  catchErrors,
  extractPatientSdoh,
  getIsButtonEnabled,
  getRequestBody,
  getSocialIssuesOptions,
} from './helpers';
import styles from './SdohPopup.module.scss';

const SdohPopup = ({ patientId, sdoh, onClose, open, isReadOnly }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const patientSdoh = useCustomSelector((state) => getPatientSdoh(state, patientId));
  const sdohCodes = useCustomSelector((state) => getSdohCodes(state));

  const isEdit = !!sdoh?.id;

  const socialIssuesOptions = useMemo(
    () => getSocialIssuesOptions(sdohCodes, patientSdoh, isEdit),
    [sdohCodes, patientSdoh, isEdit],
  );

  const { errors, setErrors, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!sdoh) return;
    setData(extractPatientSdoh(sdoh, socialIssuesOptions));
  }, [sdoh]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const getRequestParams = () => ({
      successBlock: () => {
        successCallback(`Sdoh ${isEdit ? 'edited' : 'added'}!`);

        if (!shouldClose) {
          setData(initialState);
          setAutoFocus(true);
          return;
        }

        onClose(true);
      },
      errorBlock: (err) => errorCallback(err),
    });

    loadingCallback();
    if (isEdit) {
      dispatch(updateSdoh(sdoh.id, getRequestBody(data), getRequestParams()));
    } else {
      dispatch(addSdoh(getRequestBody(data, patientId), getRequestParams()));
    }
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientSdoh(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onClear = () => setData(initialState);

  const renderStatus = () => {
    if (isReadOnly) {
      return (
        <InfoItem
          classNames={[styles.width75]}
          textId="status"
          title="Status"
          content={getStatusById(data.status.value)?.label}
          small
        />
      );
    }

    return (
      <Select
        id="status"
        classNames={[styles.width75]}
        label="Status"
        options={Object.values(SdohStatus)}
        onChange={(op) => onChangeHandler('status', op)}
        value={data.status}
        required
        placeholder="Select status"
        errorText={errors.status}
        size="small"
      />
    );
  };

  const renderNotes = () => {
    if (isReadOnly) {
      return (
        <InfoItem
          classNames={[styles.width100]}
          textId="notes"
          title="Notes"
          content={data.note}
          small
          multiline
        />
      );
    }

    return (
      <TextArea
        id="notes"
        classNames={[styles.width100]}
        placeholder="Enter notes"
        value={data.note || ''}
        onTextChange={(value) => onChangeHandler('note', value)}
        fixedRows={4}
        label="Notes"
        errorText={errors.note}
        size="small"
      />
    );
  };

  const getTitle = () => {
    if (isReadOnly) {
      return 'Social Determinant Details';
    }

    return `${isEdit ? 'Edit' : 'Add'} Social Determinant`;
  };

  return (
    <BasePopup
      id="sdoh"
      open={open}
      onClose={onClose}
      title={getTitle()}
      customStyle={{ width: '55vw' }}
    >
      <div className={styles.grid}>
        <Select
          id="social-issue"
          classNames={[styles.width75]}
          label="Social issue area"
          options={socialIssuesOptions}
          onChange={(op) => onChangeHandler('sdohCodeId', op)}
          value={data.sdohCodeId}
          required
          placeholder="Select social issue"
          errorText={errors.sdohCodeId}
          readOnly={isEdit || isReadOnly}
          size="small"
        />
        {renderStatus()}
        {renderNotes()}
      </div>
      {!isReadOnly &&
        renderButtons({
          containerClassName: styles.buttonsContainer,
          onClose,
          onSubmit: onSubmitHandler,
          onClear: !sdoh?.id ? onClear : null,
          isSubmitEnabled: getIsButtonEnabled(data, errors, sdoh),
          submitButtonText: 'Save and Close',
        })}
    </BasePopup>
  );
};

SdohPopup.propTypes = {
  patientId: PropTypes.number,
  sdoh: PropTypes.exact(PatientSdoh.schema),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default SdohPopup;
