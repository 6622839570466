import { useEffect } from 'react';

import { deleteOrmItems } from 'common-src/features/rest';

const useOrmCleanup = (modelNames) => {
  useEffect(
    () => () => {
      modelNames.map((model) => deleteOrmItems(model));
    },
    [],
  );
};

export default useOrmCleanup;
