import PropTypes from 'prop-types';
import React from 'react';

import styles from './Icd10CodeOption.module.scss';

const Icd10CodeOption = ({ icd10Code, clickHandler }) => {
  if (typeof icd10Code === 'string') {
    switch (icd10Code) {
      case 'No results found':
      case 'Too Many Results, please add more detail':
        return (
          <span className={[styles.error, 'font-w-500', 'font-s-14'].join(' ')}>{icd10Code}</span>
        );
      default:
        return (
          <span className={[styles.title, 'font-w-500', 'p-t-12', 'font-s-10'].join(' ')}>
            {icd10Code}
          </span>
        );
    }
  }

  const { code, description, friendlyName } = icd10Code;
  return (
    <div
      className={[styles.container, 'vertically-centered'].join(' ')}
      onClick={clickHandler}
      role="presentation"
    >
      <span className={[styles.description, 'font-w-500', 'font-s-14'].join(' ')}>
        {friendlyName ? `${description} (${friendlyName})` : description}
      </span>
      <span className={[styles.code, 'font-w-500', 'font-s-10'].join(' ')}>{code}</span>
    </div>
  );
};

Icd10CodeOption.propTypes = {
  clickHandler: PropTypes.func,
  icd10Code: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Icd10CodeOption;
