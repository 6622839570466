import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import {
  addPatientCoverage,
  PayorType,
  PayorTypeOptions,
  validatePatientCoverage,
} from 'common-src/models/PatientCoverage';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './AddPlanPopup.module.scss';
import { Commercial, Medicaid, Medicare, MedicareAdvantage, Supplemental } from './forms';
import { getDefaultState, getIsButtonEnabled, getPayload } from './helpers';

const AddPlanPopup = ({ patientId, onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [shouldReset, setShouldReset] = useState(false);

  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.knownPlans,
  );
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (!data.payorType) return;

    setTimeout(() => {
      setData(getDefaultState(data.payorType, patient));
    }, 0);
  }, [data.payorType]);

  useEffect(() => {
    if (!data.payorId || !data.memberId) return;

    setErrors((prev) => ({ ...prev, ...validatePatientCoverage('memberId', data.memberId, data) }));
  }, [data.payorId]);

  const handleInputChange = (field, value) => {
    const newData = { ...data, [field]: value };

    setData(newData);
    setErrors((prev) => ({ ...prev, ...validatePatientCoverage(field, value, newData) }));
  };

  const handleMultipleValuesChange = (obj) => {
    setData((prev) => ({ ...prev, ...obj }));
  };

  const handleDateChange = (key, keyToCompare, isBefore, value) => {
    const newData = { ...data, [key]: value };
    const err = { ...validatePatientCoverage(key, value, newData) };

    const compareCondition = isBefore
      ? moment(value).isAfter(moment(newData?.[keyToCompare]))
      : moment(value).isBefore(moment(newData?.[keyToCompare]));

    if (!compareCondition) {
      if (newData[key]) {
        Object.assign(err, { ...validatePatientCoverage(key, value, newData) });
      }
      if (newData[keyToCompare]) {
        Object.assign(err, {
          ...validatePatientCoverage(keyToCompare, newData?.[keyToCompare], newData),
        });
      }
    }

    setErrors((prev) => ({ ...prev, ...err }));
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const onClearHandler = () => {
    setData(getDefaultState(data.payorType, patient));
    setErrors({});
    setShouldReset(true);
    setTimeout(() => setShouldReset(false), 0);
  };

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    dispatch(
      addPatientCoverage(getPayload(patientId, data), {
        successBlock: () => {
          successCallback('Plan added!');
          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const renderForm = () => {
    if (!data.payorType) {
      return (
        <Select
          id="insurance-type"
          label="Please select insurance type"
          options={PayorTypeOptions}
          onChange={(op) => handleInputChange('payorType', op.value)}
          value={Object.values(PayorType).find((op) => op.value === data.payorType)}
          required
          placeholder="-"
        />
      );
    }

    switch (data.payorType) {
      case PayorType.Medicare.value:
        return (
          <Medicare
            data={data}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            isChangeStatusAllowed={actions?.changePlanStatus}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case PayorType.MA.value:
        return (
          <MedicareAdvantage
            data={data}
            handleInputChange={handleInputChange}
            handleMultipleValuesChange={handleMultipleValuesChange}
            handleDateChange={handleDateChange}
            isChangeStatusAllowed={actions?.changePlanStatus}
            errors={errors}
            setErrors={setErrors}
            shouldReset={shouldReset}
          />
        );
      case PayorType.Commercial.value:
        return (
          <Commercial
            data={data}
            handleInputChange={handleInputChange}
            handleMultipleValuesChange={handleMultipleValuesChange}
            handleDateChange={handleDateChange}
            isChangeStatusAllowed={actions?.changePlanStatus}
            errors={errors}
            setErrors={setErrors}
            shouldReset={shouldReset}
          />
        );
      case PayorType.Medicaid.value:
        return (
          <Medicaid
            data={data}
            handleInputChange={handleInputChange}
            handleMultipleValuesChange={handleMultipleValuesChange}
            handleDateChange={handleDateChange}
            isChangeStatusAllowed={actions?.changePlanStatus}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case PayorType.Supplemental.value:
        return (
          <Supplemental
            data={data}
            handleInputChange={handleInputChange}
            handleMultipleValuesChange={handleMultipleValuesChange}
            handleDateChange={handleDateChange}
            isChangeStatusAllowed={actions?.changePlanStatus}
            errors={errors}
            setErrors={setErrors}
            shouldReset={shouldReset}
          />
        );
      default:
        return null;
    }
  };

  return (
    <BasePopup
      id="add-plan"
      open
      onClose={onClose}
      title="Add Plan"
      customStyle={{ minWidth: data.payorType === PayorType.MA.value && '55vw' }}
    >
      <div className={styles.wrapper}>{renderForm()}</div>
      {data.payorType &&
        renderButtons({
          containerClassName: styles.buttonsContainer,
          onClose,
          onSubmit: onSubmitHandler,
          isSubmitEnabled: getIsButtonEnabled(data, errors),
          submitButtonText: 'Save and Close',
          onClear: onClearHandler,
        })}
    </BasePopup>
  );
};

AddPlanPopup.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.number,
};

export default AddPlanPopup;
