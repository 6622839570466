import _ from 'lodash';

import { validatePatientFamilyHistory } from 'common-src/models/PatientFamilyHistory';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientFamilyHistory(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => {
  const body = {
    diagnosis: data.diagnosis,
    relationship: data.relationship,
    comments: data.comments,
  };

  if (patientId) {
    Object.assign(body, { patientId });
  }

  return body;
};

export const extractPatientFamilyHistory = (familyHistory = {}) => ({
  diagnosis: familyHistory.diagnosis || '',
  relationship: familyHistory.relationship || null,
  comments: familyHistory.comments || '',
});
