import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';
import MaxTabLimitRow from 'src/popups/MaxTabLimitPopup/MaxTabLimitRow';

import styles from './MaxTabLimitPopup.module.scss';

const MaxTabLimitPopup = ({ requestedTab, tabs, onClose, onSubmit }) => {
  const [selectedTabs, setSelectedTabs] = useState([]);

  const { renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback) => {
    loadingCallback();
    onClose();
    onSubmit([...selectedTabs, requestedTab], selectedTabs);
  };

  return (
    <BasePopup
      id="max-tab-limit"
      open
      onClose={onClose}
      title="There is a limit of 5 open tabs"
      subtitle="Please close tabs before continuing."
    >
      <div className={[styles.container, 'flex-column'].join(' ')}>
        <div>
          {tabs?.map((tab) => (
            <MaxTabLimitRow
              key={tab}
              title={tab}
              isSelected={selectedTabs.includes(tab)}
              onSelect={(title, isChecked) => {
                const newTabs = isChecked
                  ? [...selectedTabs, title]
                  : selectedTabs.filter((tab) => tab !== title);

                setSelectedTabs(newTabs);
              }}
            />
          ))}
        </div>
        {renderButtons({
          containerClassName: styles.buttonsContainer,
          buttonClassName: styles.button,
          onClose,
          onSubmit: onSubmitHandler,
          submitButtonText: 'Close selected tabs',
          isSubmitEnabled: !_.isEmpty(selectedTabs),
        })}
      </div>
    </BasePopup>
  );
};

MaxTabLimitPopup.propTypes = {
  requestedTab: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default MaxTabLimitPopup;
