import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientsPhoneNumbersRequest = (query, params) =>
  restRequest({ uri: 'patientPhoneNumbers', method: HttpMethods.Get, query, ...params });

export const createPatientPhoneNumbersRequest = (body, params) =>
  restRequest({
    uri: 'patientPhoneNumbers',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientPhoneNumbersRequest = (id, body, params) =>
  restRequest({
    uri: `patientPhoneNumbers/${id}`,
    body,
    method: HttpMethods.Put,
    ...params,
  });

export const deletePatientPhoneNumbersRequest = (id, params) =>
  restRequest({
    uri: `patientPhoneNumbers/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
