import _ from 'lodash';
import moment from 'moment-timezone';

import { PatientTaskStatus } from 'common-src/models/PatientTask';

export const getIsButtonEnabled = (data, task, errors) => {
  if (
    (data.status?.value === PatientTaskStatus.Scheduled.value &&
      ((errors && Object.values(errors).some((e) => e)) ||
        _.isEqual(
          moment(data.scheduledFor).format('YYYY-MM-DD HH:mm'),
          moment(task.scheduledFor).format('YYYY-MM-DD HH:mm'),
        ) ||
        Object.values(data).some((e) => !e))) ||
    _.isEqual(data.status?.value, task.status)
  )
    return false;

  return true;
};
