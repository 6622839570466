import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientSurgicalHistoryRequest = (query, params) =>
  restRequest({
    uri: 'patientSurgicalHistory',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addSurgicalHistory = (body, params) =>
  restRequest({
    uri: 'patientSurgicalHistory',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateSurgicalHistory = (id, body, params) =>
  restRequest({
    uri: `patientSurgicalHistory/${id}`,
    method: HttpMethods.Put,
    body: { ...body, id },
    ...params,
  });

export const makeSurgicalHistoryInactive = (id, params) =>
  restRequest({
    uri: `patientSurgicalHistory/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
