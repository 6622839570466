import _ from 'lodash';
import moment from 'moment-timezone';

import { FulfillmentStatus } from 'common-src/models/PatientFulfillmentOrder';

export const hasOrderInProgress = (patientFulfillmentOrders) => {
  if (_.isEmpty(patientFulfillmentOrders)) return false;

  const orderInProgress = _.find(patientFulfillmentOrders, (order) =>
    [FulfillmentStatus.Open, FulfillmentStatus.Shipped, FulfillmentStatus.Requested].includes(
      order.orderStatus,
    ),
  );

  return !!orderInProgress;
};

export const hasPatientTakenEnoughReadings = (readings) => {
  const lastReadings = readings.filter((r) =>
    moment(moment(r.recordedAt).format('YYYY-MM-DD')).isAfter(
      moment().subtract(30, 'days').format('YYYY-MM-DD'),
    ),
  );
  const dates = lastReadings.map((r) => moment(r.recordedAt).format('YYYY-MM-DD'));
  const uniqueDates = new Set(dates);

  return Array.from(uniqueDates).length >= 16;
};
