import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { getRoleConfig } from 'common-src/features/auth';
import Patient, { PlatformStatus } from 'common-src/models/Patient';
import { reopenAccount, updatePatient } from 'common-src/models/Patient/actions';
import {
  createPatientInteractionApiRequest,
  InteractionReason,
  InteractionType,
  moduleTypes,
} from 'common-src/models/PatientInteraction';
import { uuidv4 } from 'common-src/utils/stringUtils';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsButton } from 'src/components/buttons';
import { openModal } from 'src/features/modals';
import { getPatientNote } from 'src/features/tabsState';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useNoteCreation from 'src/hooks/useNoteCreation';

import styles from '../commonStyles.module.scss';

const PatientActionsButton = ({ patient }) => {
  const { id: patientId, isOnHold, canBeClosed, platformStatus } = patient;

  const dispatch = useDispatch();

  const actions = useCustomSelector((state) => getRoleConfig(state)?.patientChart?.actions) || {};
  const patientNoteId = useCustomSelector((state) => getPatientNote(state, patientId));

  const { createNote } = useNoteCreation(patient);
  const { showAlert, AlertType } = useAlert();

  const createDraft = (moduleTypes = []) => {
    if (patientNoteId) {
      dispatch(
        openModal(ModalType.WARNING, {
          title: 'Notice',
          message:
            'A note is currently in progress. Please pause or finish the current note before attempting to resume another note on the patient’s chart.',
          onSubmit: () => {},
          submitButtonText: 'Close',
          showCancelButton: false,
        }),
      );
      return;
    }

    const documentationId = uuidv4();
    dispatch(
      createPatientInteractionApiRequest({
        body: { patientId, id: documentationId, moduleTypes },
        successBlock: () => {
          window.open(
            `/dashboard-client/documentation/details/${patientId}/${documentationId}/edit`,
            '',
            'width=1200,height=800,left=300,top=100',
          );
        },
        errorBlock: (err) => {
          dispatch(
            showAlert(
              AlertType.Error,
              '',
              `Failed to create documentation.  Err status: ${err.status}.  Err message: ${err.message}`,
            ),
          );
        },
      }),
    );
  };

  const createNewNote = (typeId, reasonId, moduleTypes = []) => {
    if (patientNoteId) {
      dispatch(
        openModal(ModalType.WARNING, {
          title: 'Notice',
          message:
            'A note is currently in progress. Please pause or finish the current note before attempting to resume another note on the patient’s chart.',
          onSubmit: () => {},
          submitButtonText: 'Close',
          showCancelButton: false,
        }),
      );
      return;
    }

    createNote(typeId, reasonId, moduleTypes);
  };

  const options = useMemo(
    () =>
      _.compact([
        actions.canAddTask && {
          id: 'add-task',
          text: 'Add Task',
          iconSrc: CommonIcons.plusIcon,
          onClick: async () => dispatch(openModal(ModalType.ADD_TASK, { patientId })),
        },
        actions.canMfaApprove && {
          id: 'approve-mfa',
          text: 'Approve MFA',
          iconSrc: Icons.moneyIcon,
          onClick: async () =>
            createNewNote(InteractionType.Administrative.id, InteractionReason.Administrative.id, [
              moduleTypes.MFADecision.id,
            ]),
        },
        actions.canAddNewIntake && {
          id: 'new-intake',
          text: 'New Intake',
          iconSrc: Icons.docIcon,
          onClick: async () =>
            createNewNote(InteractionType.Enrollment.id, InteractionReason.Intake.id),
        },
        isOnHold &&
          actions.canChangeStatusToActive && {
            id: 'status-active',
            text: 'Change status to Active',
            iconSrc: CommonIcons.checkIcon,
            onClick: async () => {
              dispatch(updatePatient(patientId, { platformStatus: PlatformStatus.Active }));
            },
          },
        canBeClosed &&
          actions.canCloseAccount && {
            id: 'close-account',
            text: 'Close Account',
            iconSrc: Icons.boldCloseIcon,
            onClick: async () => createDraft([moduleTypes.CloseRequest.id]),
          },
        platformStatus === PlatformStatus.Active &&
          actions.canPlaceAccountOnHold && {
            id: 'hold-account',
            text: 'Place Account on Hold',
            iconSrc: Icons.pauseIcon,
            onClick: async () => createDraft([moduleTypes.HoldRequest.id]),
          },
        platformStatus === PlatformStatus.Closed &&
          actions.canReopenAccount && {
            id: 'reopen-account',
            text: 'Re-open Account',
            iconSrc: CommonIcons.checkIcon,
            onClick: async () => {
              dispatch(
                openModal(ModalType.WARNING, {
                  title: 'Re-open Account',
                  message: 'Are you sure?',
                  onSubmit: () => dispatch(reopenAccount(patientId)),
                }),
              );
            },
          },
        actions.canSendDocumentation && {
          id: 'send-documentation',
          text: 'Generate Documentation',
          iconSrc: Icons.newDocIcon,
          onClick: async () => dispatch(openModal(ModalType.SEND_DOCUMENTATION, { patientId })),
        },
      ]),
    [isOnHold, canBeClosed, platformStatus, patientNoteId],
  );

  return (
    <ActionsButton
      id="patient-actions-button"
      classNames={[styles.actionsMenu]}
      options={options}
    />
  );
};

PatientActionsButton.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default PatientActionsButton;
