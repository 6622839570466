export const getBloodPressureValues = (bpValue) => {
  if (!bpValue || typeof bpValue !== 'string') {
    return { bloodPressureSystolic: null, bloodPressureDiastolic: null };
  }

  const regexMatcher = bpValue.match(/\d+/g);

  if (regexMatcher) {
    const bloodPressureSystolic = parseInt(regexMatcher[0]);
    const bloodPressureDiastolic = parseInt(regexMatcher[1]);

    return { bloodPressureSystolic, bloodPressureDiastolic };
  }

  return { bloodPressureSystolic: null, bloodPressureDiastolic: null };
};

export const calculateBMI = (weight, height) => {
  if (!weight || !height) return null;
  return Number.parseFloat((703 * weight) / (height * height)).toFixed(1);
};
