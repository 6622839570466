import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select } from 'common-src/components/base';
import Patient, { updatePatient, validatePatient } from 'common-src/models/Patient';

import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

import { genderOptions, languageOptions, raceOptions, sexOptions } from './constants';
import { catchErrors } from './helpers';
import styles from './PatientDemographics.module.scss';

const PatientDemographics = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        { ...formData },
        {
          successBlock: () => {
            setIsEditMode(false);
            setIsLoading(false);
            showAlert(AlertType.Success, '', 'You have successfully updated patient demographics.');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'demographics',
  );

  useEffect(() => {
    const data = _.pick(patient, [
      'firstName',
      'middleName',
      'lastName',
      'preferredName',
      'dob',
      'sex',
      'gender',
      'preferredLanguage',
      'race',
    ]);
    setFormData(data);
    setInitialData(data);
  }, [
    JSON.stringify(
      _.pick(patient, [
        'firstName',
        'middleName',
        'lastName',
        'preferredName',
        'dob',
        'sex',
        'gender',
        'preferredLanguage',
        'race',
      ]),
    ),
  ]);

  if (_.isEmpty(formData)) return null;

  const handleInputChange = (field, value) => {
    handleErrors(validatePatient(field, value));
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderForm = () => (
    <div className={styles.grid}>
      <Input
        id="first-name"
        label="First Name"
        errorText={errors.firstName}
        onTextChange={(value) => handleInputChange('firstName', value)}
        value={formData.firstName}
        placeholder="Enter first name"
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        id="middle-name"
        label="Middle Name"
        errorText={errors.middleName}
        onTextChange={(value) => handleInputChange('middleName', value)}
        value={formData.middleName}
        placeholder="Enter middle name"
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        id="last-name"
        label="Last Name"
        errorText={errors.lastName}
        onTextChange={(value) => handleInputChange('lastName', value)}
        value={formData.lastName}
        placeholder="Enter last name"
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        id="preferred-name"
        label="Preferred Name"
        errorText={errors.preferredName}
        onTextChange={(value) => handleInputChange('preferredName', value)}
        value={formData.preferredName}
        placeholder="Enter preferred name"
        readOnly={!isEditMode}
        size="small"
      />
      <DatePickerNew
        id="date-of-birth"
        minDate={undefined}
        maxDate={new Date()}
        required
        errorText={errors.dob}
        placeholder="Choose date of birth"
        header="Date of birth"
        value={formData.dob || null}
        onDateSelected={(date) =>
          handleInputChange('dob', date ? moment(date).format('YYYY-MM-DD') : null)
        }
        onBlur={(date) => {
          if (!date) {
            handleErrors({ dob: 'Invalid date format' });
            return;
          }
          handleErrors({ dob: '' });
        }}
        readOnly={!isEditMode}
        size="small"
      />
      <Select
        id="sex"
        errorText={errors.sex}
        label="Sex at birth"
        placeholder="Select sex"
        options={sexOptions}
        onChange={(selection) => handleInputChange('sex', selection.value)}
        value={sexOptions.find((op) => op.value === formData.sex)}
        required
        position="absolute"
        readOnly={!isEditMode}
        size="small"
      />
      <Select
        id="gender"
        errorText={errors.gender}
        label="Gender identity"
        placeholder="Select gender"
        options={genderOptions}
        onChange={(selection) => handleInputChange('gender', selection.value)}
        value={genderOptions.find((op) => op.value === formData.gender)}
        position="absolute"
        readOnly={!isEditMode}
        size="small"
      />
      <Select
        id="preferred-language"
        errorText={errors.preferredLanguage}
        label="Language"
        placeholder="Select language"
        options={languageOptions}
        onChange={(selection) => handleInputChange('preferredLanguage', selection.value)}
        value={languageOptions.find((op) => op.value === formData.preferredLanguage)}
        position="absolute"
        readOnly={!isEditMode}
        size="small"
      />
      <Select
        id="race"
        errorText={errors.race}
        label="Race"
        placeholder="Select race"
        options={raceOptions}
        onChange={(selection) => handleInputChange('race', selection.value)}
        value={raceOptions.find((op) => op.value === formData.race)}
        position="absolute"
        readOnly={!isEditMode}
        size="small"
      />
    </div>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

PatientDemographics.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default PatientDemographics;
