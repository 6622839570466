import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { hasEmptyChartData } from 'common-src/models/PatientCgmGlucoseReading';
import { ColorsNew } from 'common-src/styles';
import { singleModelSelector } from 'common-src/utils/selectorUtils';
import { bolderize } from 'common-src/utils/stringUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import {
  CGM_MAX_TARGET_RANGE,
  CGM_MIN_TARGET_RANGE,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';

import styles from './CGMChart.module.scss';
import { generateHourlyLineLabels, generateLine, generateLineData, getMaxYValue } from './helpers';

const CGMChart = ({ patientId, data }) => {
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const timeZone = patient?.timeZone;
  const labels = generateHourlyLineLabels(timeZone);
  const lineData = {
    datasets: [
      generateLine(
        '10%',
        generateLineData(data?.p10, labels),
        ColorsNew.baseGreen,
        ColorsNew.baseGreen,
        ColorsNew.mediumLightOrange,
        2,
        true,
        false,
        -1,
      ),
      generateLine(
        '25%',
        generateLineData(data?.p25, labels),
        ColorsNew.lighterGreen,
        ColorsNew.baseGreen,
        ColorsNew.mediumLightOrange,
        2,
        false,
        false,
        1,
      ),
      generateLine(
        '50%',
        generateLineData(data?.p50, labels),
        ColorsNew.darkGreen,
        ColorsNew.darkGreen,
        ColorsNew.lightGreen,
        4,
        false,
        false,
        -2,
      ),
      generateLine(
        '75%',
        generateLineData(data?.p75, labels),
        ColorsNew.lighterGreen,
        ColorsNew.baseGreen,
        ColorsNew.mediumLightOrange,
        2,
        false,
        '-2',
        1,
      ),
      generateLine(
        '90%',
        generateLineData(data?.p90, labels),
        ColorsNew.baseGreen,
        ColorsNew.baseGreen,
        ColorsNew.baseRed,
        2,
        true,
        false,
        -1,
      ),
      {
        label: 'min',
        data: Array(labels.length).fill(CGM_MIN_TARGET_RANGE),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderColor: 'transparent',
        datalabels: { display: false },
      },
      {
        label: 'max',
        data: Array(labels.length).fill(CGM_MAX_TARGET_RANGE),
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        backgroundColor: 'rgba(241, 191, 114, 0.2)',
        borderColor: 'transparent',
        datalabels: { display: false },
        fill: '-1',
        order: -1,
      },
    ],
    labels,
  };

  const lineOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: !hasEmptyChartData(data),
        mode: 'index',
        intersect: false,
        position: 'nearest',
        backgroundColor: ColorsNew.lightGreen,
        bodyColor: ColorsNew.darkGreen,
        footerColor: ColorsNew.darkGreen,
        borderColor: 'rgba(155, 206, 182, 0.9)',
        borderWidth: 1,
        displayColors: false,
        bodyFont: { weight: 400, size: 12, lineHeight: 1.4 },
        footerFont: { weight: 400, size: 10 },
        caretPadding: 20,
        caretSize: 0,
        padding: 10,
        itemSort: (a, b) => b.dataset.label.slice(0, -1) - a.dataset.label.slice(0, -1),
        filter: (item) => !['min', 'max'].includes(item.dataset.label),
        callbacks: {
          label: (item) => {
            const label = `${item.dataset.label}: ${item.formattedValue} mg/dL`;
            return item.dataset.label === '50%' ? label.replace(/./g, bolderize) : label;
          },
          footer: (items) => {
            const dateTime = items[0]?.parsed?.x;
            return dateTime
              ? `${moment(dateTime).format('h:mm A')} ${moment().tz(timeZone).format('z')}`
              : '-';
          },
          title: () => '',
        },
      },
    },
    animation: { duration: 0 },
    tension: 0.3,
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    clip: false,
    elements: { point: { radius: 0 } },
    hover: { mode: 'index', intersect: false },
    layout: { padding: { right: 50 } },
    scales: {
      x: {
        distribution: 'series',
        type: 'time',
        time: {
          unit: 'hour',
          stepSize: 1,
          tooltipFormat: 'MM/DD/YYYY HH:mm',
          displayFormats: { hour: 'h a', day: 'M/D' },
        },
        ticks: { source: 'auto', color: ColorsNew.mediumGreen, padding: 10 },
        grid: { display: false },
      },
      y: {
        min: 0,
        max: getMaxYValue(data),
        grid: { drawTicks: false },
        ticks: { color: ColorsNew.mediumGreen, padding: 10, stepSize: 50 },
        border: {
          display: false,
        },
      },
    },
  };

  return (
    <div className={styles.chart}>
      {hasEmptyChartData(data) && (
        <span
          className={[styles.emptyPlaceholder, 'absolute-centered', 'font-w-500', 'font-s-12'].join(
            ' ',
          )}
        >
          No data for period
        </span>
      )}
      <Line data={lineData} options={lineOptions} plugins={[ChartDataLabels]} />
    </div>
  );
};

CGMChart.propTypes = {
  data: PropTypes.object,
  patientId: PropTypes.number,
};

export default CGMChart;
