// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialButton-module__button___Olp_V {
  width: 16px;
  height: 16px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/DialButton/DialButton.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `DialButton-module__button___Olp_V`
};
module.exports = ___CSS_LOADER_EXPORT___;
