import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import { getBGMTableData } from 'common-src/models/PatientGlucoseReading/selectors';

import useCustomSelector from 'src/hooks/useCustomSelector';
import { BGMPeriodsFilter } from 'src/pages/PatientDetails/fragments/Biometrics/constants';
import { generateBGMTableColumns } from 'src/pages/PatientDetails/fragments/Biometrics/helpers';
import { TableRow } from 'src/pages/PatientDetails/fragments/Biometrics/items';

import styles from './BGMTable.module.scss';
import { getFilteredData } from './helpers';

const BMGTable = ({ patientId, selectedPeriod, endingDate, dayTimePeriod }) => {
  const [filteredData, setFilteredData] = useState([]);
  const { patientReadings } = useCustomSelector((state) =>
    getBGMTableData(
      state,
      patientId,
      selectedPeriod,
      endingDate,
      BGMPeriodsFilter[dayTimePeriod.value],
    ),
  );

  useEffect(() => {
    setFilteredData(getFilteredData(patientReadings));
  }, [patientReadings.length]);

  const renderHeader = () => (
    <div id="bgm-table-header" className={[styles.header, 'primary-border-b'].join(' ')}>
      {generateBGMTableColumns().map((time, i) => (
        <Fragment key={i}>
          {i === 0 && <p className={[styles.title, 'font-w-500', 'font-s-14'].join(' ')}>Date</p>}
          <p className={[styles.column, 'font-w-500', 'font-s-12'].join(' ')}>
            {i % 2 === 0 ? moment(time).format('h a') : ''}
          </p>
        </Fragment>
      ))}
    </div>
  );

  const renderBody = () => (
    <div className={styles.body}>
      {filteredData.map((reading) => (
        <TableRow key={reading.effectiveDate} reading={reading} />
      ))}
    </div>
  );

  const renderTable = () => {
    if (_.isEmpty(filteredData)) return null;

    return (
      <>
        {renderHeader()}
        {renderBody()}
      </>
    );
  };

  return <div className={styles.table}>{renderTable()}</div>;
};

BMGTable.propTypes = {
  patientId: PropTypes.number,
  endingDate: PropTypes.string,
  dayTimePeriod: PropTypes.object,
  selectedPeriod: PropTypes.number,
};

export default BMGTable;
