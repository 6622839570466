import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getNoteScroll, saveNoteScroll } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

const useNoteScroll = (patientId) => {
  const scrollRef = useRef(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const currentScroll = useRef(0);

  const scroll = useCustomSelector((state) => getNoteScroll(state, patientId)) || 0;

  useEffect(() => {
    currentScroll.current = scrollRef.current?.scrollTop;
  }, [scrollRef.current?.scrollTop]);

  useEffect(() => {
    currentScroll.current = scroll;
  }, [scroll]);

  useEffect(() => {
    setTimeout(() => {
      if (!scrollRef.current) return;
      setLoading(false);
      scrollRef.current.scrollTop = scroll;
    }, 0);
  }, [scroll, scrollRef.current]);

  useEffect(
    () => () => {
      dispatch(saveNoteScroll(patientId, currentScroll.current));
    },
    [],
  );

  return { scrollRef, scrollLoading: loading };
};

export default useNoteScroll;
