export const UsTimeZones = Object.freeze({
  0: 'Africa/Cairo',
  1: 'Africa/Casablanca',
  2: 'Africa/Khartoum',
  3: 'Africa/Kinshasa',
  4: 'Africa/Monrovia',
  5: 'Africa/Nairobi',
  6: 'America/Adak',
  7: 'America/Anchorage',
  8: 'America/Antigua',
  9: 'America/Argentina/Buenos_Aires',
  10: 'America/Asuncion',
  11: 'America/Bogota',
  12: 'America/Boise',
  13: 'America/Caracas',
  14: 'America/Chicago',
  15: 'America/Costa_Rica',
  16: 'America/Denver',
  17: 'America/Detroit',
  18: 'America/El_Salvador',
  19: 'America/Guatemala',
  20: 'America/Guayaquil',
  21: 'America/Halifax',
  22: 'America/Indiana/Indianapolis',
  23: 'America/Indiana/Knox',
  24: 'America/Indiana/Marengo',
  25: 'America/Indiana/Petersburg',
  26: 'America/Indiana/Tell_City',
  27: 'America/Indiana/Vevay',
  28: 'America/Indiana/Vincennes',
  29: 'America/Indiana/Winamac',
  30: 'America/Juneau',
  31: 'America/Kentucky/Louisville',
  32: 'America/Kentucky/Monticello',
  33: 'America/La_Paz',
  34: 'America/Lima',
  35: 'America/Los_Angeles',
  36: 'America/Managua',
  37: 'America/Menominee',
  38: 'America/Montevideo',
  39: 'America/Nassau',
  40: 'America/New_York',
  41: 'America/Nome',
  42: 'America/North_Dakota/Beulah',
  43: 'America/North_Dakota/Center',
  44: 'America/North_Dakota/New_Salem',
  45: 'America/Panama',
  46: 'America/Phoenix',
  47: 'America/Puerto_Rico',
  48: 'America/Santiago',
  49: 'America/Santo_Domingo',
  50: 'America/Sao_Paulo',
  51: 'America/St_Johns',
  52: 'America/Tegucigalpa',
  53: 'America/Thule',
  54: 'America/Toronto',
  55: 'America/Yakutat',
  56: 'Asia/Amman',
  57: 'Asia/Bahrain',
  58: 'Asia/Bangkok',
  59: 'Asia/Hong_Kong',
  60: 'Asia/Jakarta',
  61: 'Asia/Jerusalem',
  62: 'Asia/Karachi',
  63: 'Asia/Manila',
  64: 'Asia/Riyadh',
  65: 'Asia/Seoul',
  66: 'Asia/Singapore',
  67: 'Asia/Tokyo',
  68: 'Atlantic/Azores',
  69: 'Atlantic/Bermuda',
  70: 'Atlantic/Reykjavik',
  71: 'Australia/Adelaide',
  72: 'Australia/Darwin',
  73: 'Australia/Melbourne',
  74: 'Australia/Perth',
  75: 'Australia/Sydney',
  76: 'Europe/Amsterdam',
  77: 'Europe/Athens',
  78: 'Europe/Berlin',
  79: 'Europe/Brussels',
  80: 'Europe/Copenhagen',
  81: 'Europe/Helsinki',
  82: 'Europe/Istanbul',
  83: 'Europe/Lisbon',
  84: 'Europe/London',
  85: 'Europe/Madrid',
  86: 'Europe/Oslo',
  87: 'Europe/Paris',
  88: 'Europe/Rome',
  89: 'Indian/Mahe',
  90: 'Pacific/Auckland',
  91: 'Pacific/Guam',
  92: 'Pacific/Honolulu',
  93: 'Pacific/Kwajalein',
  94: 'Pacific/Majuro',
  95: 'Pacific/Midway',
  96: 'Pacific/Wake',
});
