export const initialState = {
  types: {
    chartNotes: false,
    bgmReadingLogs: false,
    cgmReadingLogs: false,
    ordersAndConsent: false,
    rpmClaimDocumentation: false,
    emClaimDocumentation: false,
    hawbClaimDocumentation: false,
    ccmClaimDocumentation: false,
  },
  startDate: null,
  endDate: null,
  email: '',
};
