export const VitalsAndLabsFieldIds = Object.freeze({
  height: 'height-input',
  weight: 'weight-input',
  weightDate: 'weight-date',
  weightDateContainer: 'weight-date-container',
  hbA1cContainer: 'hbA1c-container',
  hbA1c: 'hbA1c-input',
  hbA1cDate: 'hbA1c-date',
  hbA1cDateContainer: 'hbA1c-date-container',
  additionalDetails: 'additional-detail-input',
});
