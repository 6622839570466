import PropTypes from 'prop-types';
import { attr, Model } from 'redux-orm';

class FeatureFlag extends Model {
  static modelName = 'FeatureFlag';

  static fields = {
    name: attr(),
    state: attr(),
    description: attr(),
  };

  static options = {
    idAttribute: 'name',
  };

  static schema = {
    _fields: PropTypes.exact({
      name: PropTypes.string,
      state: PropTypes.number,
      description: PropTypes.string,
    }),
  };
}

export default FeatureFlag;
