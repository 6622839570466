import { createReducer } from 'common-src/utils/reducerUtils';

import { LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT } from './actionTypes';
import { createRoleConfigPayload } from './helpers';

export const initialState = {
  isLoggedIn: false,
  bearerToken: null,
  userId: null,
  orgId: null,
  email: null,
  perryClinicOrgId: null,
  roleConfig: {},
  loginError: null,
  isSuper: false,
};

const loggedInState = (state, payload) => {
  const { bearerToken, userId, orgId, email, roleId, perryClinicOrgId, isSuper } = payload;

  return {
    ...state,
    bearerToken,
    userId,
    orgId,
    email,
    roleId,
    isLoggedIn: true,
    perryClinicOrgId,
    roleConfig: createRoleConfigPayload(roleId, isSuper),
    loginError: null,
    isSuper,
  };
};

const loginErrorState = (state, payload) => {
  const { errorMessage } = payload;

  return {
    ...state,
    loginError: errorMessage,
  };
};

export const entityHandlers = {
  [LOGIN_SUCCESS]: loggedInState,
  [LOGIN_ERROR]: loginErrorState,
  [LOGOUT]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
