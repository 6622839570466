import React from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from 'src/AppRouter';

import { store } from './store';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Helmet>
        <meta name="env" content={process.env.NODE_ENV} />
        <meta name="commit" content={process.env.COMMIT_HASH} />
      </Helmet>
      <AppRouter />
    </BrowserRouter>
  </Provider>
);

export default App;
