/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useRequestLoading = (requests = [], onFinish = () => {}, shouldNotSendRequests = false) => {
  const dispatch = useDispatch();
  const [isRequesting, setIsRequesting] = useState(!shouldNotSendRequests);
  const [errMessage, setErrorMessage] = useState('');
  const [runningRequests, setRunningRequests] = useState(requests.length);

  const onSuccess = () => setRunningRequests((prev) => prev - 1);

  const onError = (err) =>
    setErrorMessage(`Request failed. Err status: ${err.status}. Err message: ${err.message}`);

  useEffect(() => {
    if (shouldNotSendRequests) return;

    requests.forEach((r) => {
      const successBlock = r.payload?.successBlock;
      const errorBlock = r.payload?.errorBlock;

      r.payload.successBlock = () => {
        onSuccess();
        successBlock?.();
      };
      r.payload.errorBlock = (err) => {
        onError(err);
        errorBlock?.();
      };

      dispatch(r);
    });
  }, [shouldNotSendRequests]);

  useEffect(() => {
    if (!runningRequests) {
      setIsRequesting(false);
      onFinish();
    }
  }, [runningRequests]);

  useEffect(() => {
    if (errMessage) {
      setIsRequesting(false);
    }
  }, [errMessage]);

  return { isRequesting, errMessage };
};

export default useRequestLoading;
