import { attr, fk, Model } from 'redux-orm';

class PatientDevice extends Model {
  static modelName = 'PatientDevice';

  static fields = {
    id: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    deletedAt: attr(),
    typeId: attr(),
    lastReadingAt: attr(),
    updatedBy: fk('Client', 'patientDevices'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientDevices',
    }),
  };
}

export default PatientDevice;
