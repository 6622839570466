import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, Checkbox, CircleLoader, Tooltip } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import useOnClickOutside from 'common-src/hooks/useOnClickOutside';
import usePaper from 'common-src/hooks/usePaper';
import { Colors, ColorsNew } from 'common-src/styles';

import { getPaperStyles, getSubPaperStyles } from './helpers';
import styles from './Menu.module.scss';

const Menu = ({
  id,
  button,
  paperWidth,
  menuStatus,
  customOptions,
  onClearSelection,
  subOptionHoverColor,
  tooltipText,
  options = [],
  classNames = [],
  paperStyles = {},
  arrowStyles = {},
  disabled = false,
  multiple = false,
  isLoading = false,
  withArrow = false,
  onCloseMenu = () => {},
  paperAlignment = 'left',
  useButtonHeight = false,
  subPaperPosition = 'right',
}) => {
  const [positionStyles, setPositionStyles] = useState({});
  const [initialWidth, setInitialWidth] = useState(0);
  const [hoveredOption, setHoveredOption] = useState('');
  const [isSubPaperVisible, setIsSubPaperVisible] = useState(false);

  const buttonRef = useRef(null);
  const subPaperRef = useRef(null);

  const { openPaper, closePaper, open } = usePaper();
  const { ref: paperRef } = useOnClickOutside((e) => {
    if (!buttonRef.current || buttonRef.current.contains(e.target)) return;
    closePaper();
  });

  useEffect(() => {
    if (!paperRef.current || !buttonRef.current) return;

    setPositionStyles(getPaperStyles(paperRef, buttonRef, useButtonHeight, paperAlignment));
    setInitialWidth(buttonRef.current.scrollWidth);
  }, [paperRef.current, buttonRef.current, open, window.innerHeight, window.innerWidth]);

  useEffect(() => {
    if (open) return;

    onCloseMenu();
    setHoveredOption('');
    setIsSubPaperVisible(false);
  }, [open]);

  const onClickOption = (e, option) => {
    e.stopPropagation();

    option.onClick(option);

    closePaper();
  };

  const renderSubPaper = (options) => (
    <div
      ref={subPaperRef}
      id="menu-sub-paper"
      className={[styles.subPaper, 'fixed', 'secondary-border', 'border-r-6'].join(' ')}
      style={{ ...getSubPaperStyles(subPaperRef, paperWidth, subPaperPosition) }}
    >
      <div
        className={[
          styles.backWrapper,
          subPaperPosition === 'left' ? styles.rotate : '',
          'flex-row',
          'vertically-centered',
          'gap-6',
        ].join(' ')}
        onClick={() => {
          setIsSubPaperVisible(false);
          setHoveredOption('');
        }}
        role="presentation"
      >
        <img
          className={styles.backIcon}
          src={CommonIcons.arrowLeftCircleIcon}
          alt="arrow-left-circle-icon"
        />
        <span className="font-s-12">BACK</span>
      </div>
      {options.map((op) => (
        <div
          key={`sub-option-${op.id}`}
          id={`sub-option-${op.label}`}
          className={[styles.subOption, 'font-s-14', 'font-w-500'].join(' ')}
          role="presentation"
          onClick={() => {
            op.onClick(op.id);
            closePaper();
          }}
        >
          {op.label}
        </div>
      ))}
    </div>
  );

  const renderOptions = () => {
    if (multiple) {
      return (
        <>
          {options.map((option) => (
            <div key={option.text} onClick={option.onClick} role="presentation">
              <Checkbox
                id={option.id ? option.id.toLowerCase().split(' ').join('-') : ''}
                classNames={['p-12']}
                label={option.text}
                checked={option.isChecked}
                onChange={option.onClick}
              />
            </div>
          ))}
          <button
            id="clear-selection-button"
            className={[styles.clearButton, 'font-s-14', 'm-b-14'].join(' ')}
            onClick={() => {
              onClearSelection();
              closePaper();
            }}
            type="button"
          >
            Clear selection
          </button>
        </>
      );
    }

    if (customOptions) {
      return customOptions.map((op, i) => {
        const isHovered = op.title === hoveredOption;

        return (
          <div
            key={op.value || i}
            style={{
              backgroundColor: !_.isEmpty(op.subOptions) && isHovered && subOptionHoverColor,
            }}
            className={styles.customOption}
            onMouseEnter={() => {
              setHoveredOption(op.title);
              setIsSubPaperVisible(true);
            }}
            onClick={() => {
              if (!_.isEmpty(op.subOptions)) return;

              if (typeof op.onClick === 'function') {
                op.onClick(op.value);
              }

              closePaper();
            }}
            role="presentation"
          >
            {op.label}
            {!_.isEmpty(op.subOptions) &&
              isHovered &&
              isSubPaperVisible &&
              renderSubPaper(op.subOptions)}
          </div>
        );
      });
    }

    if (menuStatus) {
      return options.map((option) => {
        const isSelected = menuStatus === option.id;
        return (
          <div
            key={option.label}
            id={option.label ? option.label.toLowerCase().split(' ').join('-') : ''}
            className={[
              styles.statusOption,
              'primary-border-b',
              isSelected ? styles.selected : '',
            ].join(' ')}
          >
            <StatusItem
              isActive
              text={option.label}
              textColor={option.textColor}
              backgroundColor={option.backgroundColor}
              withArrow={false}
              onClick={(e) => onClickOption(e, option)}
              small
            />
          </div>
        );
      });
    }

    return options.map((option) => (
      <Button
        key={option.text}
        id={option.text ? option.text.toLowerCase().split(' ').join('-') : ''}
        classNames={[styles.button]}
        disabled={option.disabled}
        onClick={(e) => onClickOption(e, option)}
        text={option.text}
        iconSrc={option.iconSrc}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
        borderColor="transparent"
      />
    ));
  };

  const renderPaper = () => {
    if (!open) return null;

    return (
      <div
        ref={paperRef}
        id="menu-paper"
        className={[styles.paper, 'fixed', 'secondary-border', 'border-r-6'].join(' ')}
        style={{ ...positionStyles, ...paperStyles, width: paperWidth && `${paperWidth}px` }}
      >
        {renderOptions()}
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <CircleLoader color={ColorsNew.darkGreen} strokeWidth={2} circleRadius={9} />;
    }

    return button;
  };

  const renderArrow = () => {
    if (!withArrow) return null;

    return (
      <img
        className={[styles.icon, open ? styles.up : '', 'icon-btn'].join(' ')}
        src={CommonIcons.karetDownIcon}
        alt="karet-down-icon"
        style={arrowStyles}
      />
    );
  };

  const renderButton = () => {
    if (tooltipText) {
      return (
        <Tooltip title={tooltipText}>
          {renderContent()}
          {renderArrow()}
        </Tooltip>
      );
    }

    return (
      <>
        {renderContent()}
        {renderArrow()}
      </>
    );
  };

  return (
    <div id={id} className={['relative', ...classNames].join(' ')}>
      <div
        ref={buttonRef}
        className={[styles.buttonWrapper, disabled ? styles.disabled : ''].join(' ')}
        style={{ minWidth: `${initialWidth}px` }}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled || isLoading) return;
          openPaper(e);
        }}
        role="presentation"
      >
        {renderButton()}
      </div>
      {renderPaper()}
    </div>
  );
};

Menu.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  withArrow: PropTypes.bool,
  isLoading: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  onCloseMenu: PropTypes.func,
  paperWidth: PropTypes.number,
  arrowStyles: PropTypes.object,
  customOptions: PropTypes.array,
  useButtonHeight: PropTypes.bool,
  onClearSelection: PropTypes.func,
  paperStyles: PropTypes.object,
  paperAlignment: PropTypes.oneOf(['left', 'right']),
  subOptionHoverColor: PropTypes.string,
  subPaperPosition: PropTypes.oneOf(['left', 'right']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  button: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  menuStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipText: PropTypes.string,
};

export default Menu;
