import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientPreferredPharmaciesRequest = (query, params) =>
  restRequest({
    uri: 'patientPreferredPharmacies',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientPreferredPharmacy = (body, params) =>
  restRequest({
    uri: 'patientPreferredPharmacies',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientPreferredPharmacy = (id, body, params) =>
  restRequest({
    uri: `patientPreferredPharmacies/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const deletePatientPreferredPharmacy = (id, params) =>
  restRequest({
    uri: `patientPreferredPharmacies/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
