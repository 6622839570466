import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';
import { getLatestCgmGlucoseReading } from 'common-src/models/PatientCgmGlucoseReading';
import { CGMAuthStatus, getCGMAuthStatus } from 'common-src/models/PatientCgmOrder';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { ProgressHeaderItem } from 'src/components/elements';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './SubHeader.module.scss';

const SubHeader = ({ actions, patient = {}, onAddCgm = () => {} }) => {
  const dispatch = useDispatch();

  const latestCgmReading = useCustomSelector((state) =>
    getLatestCgmGlucoseReading(state, patient.id),
  );

  const cgmAuthStatus = useMemo(
    () => getCGMAuthStatus(patient, latestCgmReading),
    [patient, latestCgmReading],
  );

  return (
    <div id="cgm-orders-subheader" className={[styles.content, 'gap-24'].join(' ')}>
      <ProgressHeaderItem
        id="cgm"
        header="Continuous Glucose Meter"
        mainText={cgmAuthStatus.deviceStatus}
        supportingText={cgmAuthStatus.cgmStatus}
        progressPercent={0}
        showProgressBar={cgmAuthStatus.cgmStatus === CGMAuthStatus.NotApplicable}
        showDeviceStatus={cgmAuthStatus.cgmStatus !== CGMAuthStatus.NotApplicable}
        deviceStatusSrc={
          cgmAuthStatus.cgmStatus === CGMAuthStatus.Transmitting
            ? CommonIcons.activeDevice
            : CommonIcons.inactiveDevice
        }
        onClick={onAddCgm}
        disabled={!actions?.addCGMDevice}
      />
      <Button
        id="new-cgm-order"
        iconSrc={CommonIcons.plusIcon}
        classNames={[styles.button]}
        text="Add Order"
        disabled={!actions?.addCGMOrder}
        onClick={() => dispatch(openModal(ModalType.MANUAL_CGM_ORDER, { patientId: patient.id }))}
        textColor={ColorsNew.darkGreen}
        backgroundColor={Colors.white}
        borderColor={ColorsNew.darkGreen}
      />
    </div>
  );
};

SubHeader.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onAddCgm: PropTypes.func,
};

export default SubHeader;
