import PropTypes from 'prop-types';
import React from 'react';

import { Accordion, RequestHandlerScreen } from 'common-src/components/base';
import { getPatientCareProvidersRequest } from 'common-src/models/PatientCareProvider';
import { getPatientEmergencyContactsRequest } from 'common-src/models/PatientEmergencyContact';
import { getPatientPreferredPharmaciesRequest } from 'common-src/models/PatientPreferredPharmacy';

import useRequestLoading from 'src/hooks/useRequestLoading';

import styles from './Contacts.module.scss';
import { EmergencyContacts, PreferredPharmacy, Providers } from './forms';

const Contacts = ({ patientId }) => {
  const { isRequesting } = useRequestLoading([
    getPatientCareProvidersRequest(patientId),
    getPatientEmergencyContactsRequest({ patientId }),
    getPatientPreferredPharmaciesRequest({ patientId }),
  ]);

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling contacts..." />;
  }

  return (
    <article className={[styles.article, 'flex-column', 'gap-16', 'relative'].join(' ')}>
      <Accordion
        classNames={[styles.acccordion]}
        title="Emergency contacts"
        isDefaultExpanded
        content={<EmergencyContacts patientId={patientId} />}
      />
      <Accordion
        classNames={[styles.acccordion]}
        title="Providers"
        isDefaultExpanded
        content={<Providers patientId={patientId} />}
      />
      <Accordion
        classNames={[styles.acccordion]}
        title="Preferred Pharmacy"
        isDefaultExpanded
        content={<PreferredPharmacy patientId={patientId} />}
      />
    </article>
  );
};

Contacts.propTypes = {
  patientId: PropTypes.number,
};

export default Contacts;
