import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { getActiveCuriePatientTasks } from 'common-src/models/PatientTask';

import Icons from 'src/assets/Icons';
import {
  getAgentStateColor,
  isAwsDialerVisible,
  updateAwsDialerVisibility,
} from 'src/features/awsConnect';
import { getIsCurieAISelected, saveIsCurieAISelected } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

import LinkItem from './LinkItem';
import styles from './PatientDetailsFooter.module.scss';

const PatientDetailsFooter = ({ patientId, medsNeededAction }) => {
  const dispatch = useDispatch();

  const isDialerVisible = useCustomSelector((state) => isAwsDialerVisible(state));
  const agentStateColor = useCustomSelector((state) => getAgentStateColor(state));
  const isCurieAISelected = useCustomSelector((state) => getIsCurieAISelected(state, patientId));
  const activeCurieTasks = useCustomSelector((state) =>
    getActiveCuriePatientTasks(state, patientId),
  );

  return (
    <footer className={[styles.footer, 'flex-row', 'vertically-centered', 'gap-32'].join(' ')}>
      {/* TODO: get correct badge count */}
      <LinkItem
        title="Phone"
        iconSrc={Icons.callIcon}
        badgeCount={0}
        patientId={patientId}
        onClick={() => dispatch(updateAwsDialerVisibility(true))}
        statusColor={agentStateColor}
        isSelected={isDialerVisible}
      />
      <LinkItem
        route="conversation-history"
        title="Timeline"
        iconSrc={Icons.chatInIcon}
        badgeCount={0}
        patientId={patientId}
      />
      <LinkItem
        title="Curie AI"
        iconSrc={Icons.overviewIcon}
        badgeCount={activeCurieTasks.length}
        patientId={patientId}
        onClick={() => dispatch(saveIsCurieAISelected(patientId, !isCurieAISelected))}
        isSelected={isCurieAISelected}
      />
      <LinkItem
        route="medications"
        title="Medications"
        iconSrc={Icons.pillIcon}
        badgeCount={medsNeededAction}
        patientId={patientId}
      />
      <LinkItem
        route="lab-results"
        title="Labs"
        iconSrc={Icons.labsIcon}
        badgeCount={0}
        patientId={patientId}
      />
    </footer>
  );
};

PatientDetailsFooter.propTypes = {
  patientId: PropTypes.number,
  medsNeededAction: PropTypes.number,
};

export default PatientDetailsFooter;
