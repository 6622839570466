export const SupplementsTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: SupplementsTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: SupplementsTabs.NOT_ACTIVE.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'name', label: 'Supplement Name', width: '25%', sortBy: 'title' },
  { column: 'comments', label: 'Comments', width: '20%', sortBy: 'value' },
  { column: 'createdAt', label: 'Date Added', width: '16%', sortBy: 'value' },
  { column: 'status', label: 'Status', width: '12%' },
  { column: 'actions', label: 'Actions', width: '27%' },
];
