import { Relation } from 'common-src/models/PatientMedicalProblem';

export const initialState = {
  diagnosis: '',
  relationship: null,
  comments: '',
};

export const relationOptions = Object.values(Relation)
  .filter((r) => r.id !== Relation.Self.id)
  .map((relation) => ({
    value: relation.id,
    label: relation.label,
  }));
