import _ from 'lodash';

import {
  PreAuthServiceType,
  PreAuthStatus,
  validatePreAuthorization,
} from 'common-src/models/PreAuthorization';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePreAuthorization(field, value, data);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, id) => {
  const payload = {
    patientCoverageId: data.patientCoverage?.value,
    authCode: data.authCode,
    startDate: data.startDate,
    endDate: data.endDate,
    status: data.status.value,
    serviceType: data.serviceType?.value || null,
  };

  if (id) payload.id = id;

  return payload;
};

export const extractPreAuthorization = (auth = {}) => ({
  patientCoverage: auth?.patientCoverage
    ? {
        value: auth?.patientCoverage?.id,
        label: auth?.patientCoverage?.name,
      }
    : null,
  authCode: auth?.authCode || '',
  startDate: auth?.startDate || null,
  endDate: auth?.endDate || null,
  status: Object.values(PreAuthStatus).find((x) => x.value === auth?.status) || null,
  serviceType: Object.values(PreAuthServiceType).find((x) => x.value === auth?.serviceType) || null,
});

export const getIsButtonEnabled = (data, errors, preAuthorization) => {
  if (
    Object.values(data).every((e) => !e) ||
    Object.values(errors).some((e) => e) ||
    (preAuthorization && _.isEqual(data, extractPreAuthorization(preAuthorization)))
  )
    return false;

  return true;
};
