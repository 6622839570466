import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import PatientFulfillmentOrder, {
  fulfillmentProducts,
} from 'common-src/models/PatientFulfillmentOrder';

import BasePopup from 'src/popups/BasePopup';

import Item from './Item';

const OrderDetailsPopup = ({ open, onClose, order }) => (
  <BasePopup id="order-details" open={open} onClose={onClose} title="Order Details">
    <div
      id="products-list-container"
      className={['flex-column', 'p-t-18', 'gap-18', 'm-l-30'].join(' ')}
    >
      {_.map(order?.lineItems || [], (item) => {
        const product = _.find(
          Object.values(fulfillmentProducts),
          (product) => item.product === product.id,
        );

        return (
          <Item
            key={product?.lineName}
            productName={product?.lineName}
            quantity={String(item?.quantity)}
          />
        );
      })}
    </div>
  </BasePopup>
);

OrderDetailsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  order: PropTypes.exact(PatientFulfillmentOrder.schema),
};

export default OrderDetailsPopup;
