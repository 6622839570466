import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { stateOptions } from 'common-src/constants/stateOptions';
import { UsStateByAbbr } from 'common-src/constants/usStates';
import {
  PayorType,
  PayorTypeOptions,
  PlanSource,
  PlanStatus,
  validatePatientCoverage,
} from 'common-src/models/PatientCoverage';

import { InfoItem } from 'src/components/elements';

import styles from '../commonStyles.module.scss';

const Medicaid = ({
  data,
  handleInputChange,
  handleMultipleValuesChange,
  handleDateChange,
  isChangeStatusAllowed,
  errors,
  setErrors,
}) => (
  <>
    <Select
      id="insurance-type"
      label="Please select insurance type"
      options={PayorTypeOptions}
      onChange={(op) => handleInputChange('payorType', op.value)}
      value={Object.values(PayorType).find((op) => op.value === data.payorType)}
      required
      placeholder="-"
    />
    <Select
      id="state"
      label="Please Select State"
      placeholder="-"
      options={stateOptions}
      onChange={(op) => {
        handleMultipleValuesChange({
          state: op.value,
          payorName: `Medicaid ${UsStateByAbbr[op.value]}`,
          payorId: `MD${op.value}`,
        });
      }}
      value={stateOptions.find((op) => op.value === data.state)}
      paperHeight={350}
      isSearchable
      required
    />
    <span />
    <InfoItem
      classNames={[styles.width75]}
      title="Payor Name"
      content={data.payorName}
      textId="payor-name"
    />
    <InfoItem title="Payor ID*" content={data.payorId} textId="payor-id" />
    <Input
      id="member-id"
      placeholder="Enter member id"
      label="Member ID"
      value={data.memberId}
      onTextChange={(value) => handleInputChange('memberId', value)}
      errorText={errors.memberId}
    />
    <Select
      id="status"
      label="Status"
      options={Object.values(PlanStatus)}
      onChange={(op) => {
        setErrors((prev) => ({
          ...prev,
          ...validatePatientCoverage('endDate', data.endDate, { ...data, status: op.value }),
        }));

        handleInputChange('status', op.value);
      }}
      value={Object.values(PlanStatus).find((op) => op.value === data.status)}
      disabled={!isChangeStatusAllowed}
      required
    />
    <InfoItem
      title="Source"
      content={Object.values(PlanSource).find((op) => op.value === data.source)?.label}
      textId="source"
    />
    <DatePickerNew
      id="start-date"
      header="Start date"
      placeholder="Select date"
      value={data.startDate}
      onDateSelected={(date) =>
        handleDateChange(
          'startDate',
          'endDate',
          true,
          date ? moment(date).format('YYYY-MM-DD') : null,
        )
      }
      onBlur={(date) =>
        setErrors((prev) => ({ ...prev, ...validatePatientCoverage('startDate', date, data) }))
      }
      errorText={errors.startDate}
      required
    />
    <DatePickerNew
      id="end-date"
      header="End date"
      placeholder="Select date"
      value={data.endDate}
      onDateSelected={(date) =>
        handleDateChange(
          'endDate',
          'startDate',
          false,
          date ? moment(date).format('YYYY-MM-DD') : null,
        )
      }
      onBlur={(date) =>
        setErrors((prev) => ({ ...prev, ...validatePatientCoverage('endDate', date, data) }))
      }
      filterDate={(date) => {
        const day = date.getDate();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        return day === lastDay; // Allow only the last day of each month
      }}
      errorText={errors.endDate}
      required={data.status === PlanStatus.Expired.value}
    />
  </>
);

Medicaid.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  isChangeStatusAllowed: PropTypes.bool,
  handleMultipleValuesChange: PropTypes.func,
};

export default Medicaid;
