import _ from 'lodash';

import { capitalizeFirstLetters, titleizeAlt } from 'common-src/utils/stringUtils';

const getDosageBySubstances = (substances) => {
  if (_.isEmpty(substances)) return '';

  let formattedDosages = '';

  Object.keys(substances).forEach((key) => {
    if (formattedDosages !== '') {
      formattedDosages += ' / ';
    }

    formattedDosages += substances[key];
  });

  return formattedDosages;
};

const getDosageByLists = (dosageList, unitList) => {
  const dosageArr = dosageList.split(';').map((item) => item.trim());
  const unitArr = unitList.split(';').map((item) => item.trim());

  if (dosageArr.length !== unitArr.length) {
    return '';
  }

  const combinedArr = [];
  for (let i = 0; i < dosageArr.length; i++) {
    combinedArr.push(`${dosageArr[i]} ${unitArr[i]}`);
  }

  return combinedArr.join(' / ');
};

export const getDosageText = (medication) => {
  if (!medication) return '';

  const { substances, dosageList, unitList } = medication;

  if (!dosageList || !unitList) return getDosageBySubstances(substances);

  const combinedDosages = getDosageByLists(dosageList, unitList);

  return _.isEmpty(combinedDosages) ? getDosageBySubstances(substances) : combinedDosages;
};

export const formDisplay = (form) => titleizeAlt(form).split(' ')[0];

export const getFormText = (medication) => (medication ? formDisplay(medication?.form) : '');

export const getNameText = (medication) =>
  medication ? capitalizeFirstLetters(medication?.brandNameFull || medication?.brandName) : '';
