import _ from 'lodash';
import moment from 'moment-timezone';

import { DEFAULT_CGM_MAX_VALUE } from 'src/pages/PatientDetails/fragments/Biometrics/constants';

export const generateScatterData = (readings, timeZone) =>
  readings.map((r) => ({
    x: moment(r.recordedAtLocal).tz(timeZone),
    y: r.value,
  }));

export const getMaxYValue = (readings) => {
  const values = readings.map((r) => r.value);
  const maxReadingValue = !_.isEmpty(values) ? Math.max(...values) : DEFAULT_CGM_MAX_VALUE;

  const maxYPoint = maxReadingValue + 100;
  const remainderFromOffset = maxYPoint % 100;

  return maxYPoint - remainderFromOffset;
};
