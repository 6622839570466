import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Select } from 'common-src/components/base';
import {
  ActivePatientTaskStatuses,
  getStatusById,
  getTaskReasonById,
  getTaskTypeById,
  PatientTaskStatus,
  validatePatientTask,
} from 'common-src/models/PatientTask';
import { updatePatientTask } from 'common-src/models/PatientTask/actions';

import { InfoItem } from 'src/components/elements';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './EditTaskPopup.module.scss';
import { getIsButtonEnabled } from './helpers';

const EditTaskPopup = ({ onClose, task, isReadOnly }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (!task) return;
    setData({
      status: Object.values(PatientTaskStatus).find((status) => status.value === task.status),
      scheduledFor: task.scheduledFor ? moment(task.scheduledFor).format('YYYY-MM-DD HH:mm') : null,
    });
  }, [task]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    dispatch(
      updatePatientTask(
        task.id,
        {
          status: data.status.value,
          scheduledFor:
            data.status.value === PatientTaskStatus.Scheduled.value
              ? moment(data.scheduledFor).utc().toISOString()
              : null,
        },
        {
          successBlock: () => {
            successCallback('Task edited!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  const renderStatus = () => {
    if (isReadOnly) {
      return (
        <InfoItem
          textId="status"
          title="Status"
          content={getStatusById(task.status)?.label}
          isSmall
        />
      );
    }

    return (
      <Select
        id="status"
        label="Status"
        options={Object.values(PatientTaskStatus)}
        onChange={(status) => setData((prev) => ({ ...prev, status }))}
        value={data.status}
        required
        placeholder="Select status"
        size="small"
        disabled={!ActivePatientTaskStatuses.includes(task.status)}
      />
    );
  };

  return (
    <BasePopup
      id="edit-task"
      open
      onClose={onClose}
      title={isReadOnly ? 'Task Details' : 'Edit Task'}
      customStyle={{ minWidth: '50vw' }}
    >
      <div className={styles.grid}>
        <InfoItem
          classNames={[styles.width100]}
          textId="type"
          title="Type"
          content={getTaskTypeById(task?.typeId)?.label}
          isSmall
        />
        <InfoItem
          textId="created-at"
          title="Created"
          content={moment(task.createdAt).format('MM/DD/YYYY')}
          isSmall
        />
        <InfoItem
          textId="created-by"
          title="Created By"
          content={task.createdBy?.getName(true)}
          isSmall
        />
        {renderStatus()}
        <InfoItem
          textId="reason"
          title="Reason"
          content={getTaskReasonById(task?.reasonId)?.label}
          isSmall
        />
        {data.status?.value === PatientTaskStatus.Scheduled.value && (
          <DatePickerNew
            id="scheduled-for"
            header="Select date and time"
            withTime
            required
            minDate={new Date()}
            value={data.scheduledFor}
            onDateSelected={(date) => {
              setData((prev) => ({
                ...prev,
                scheduledFor: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null,
              }));
              setErrors((prev) => ({ ...prev, ...validatePatientTask('scheduledFor', date) }));
            }}
            onBlur={(date) => {
              setData((prev) => ({
                ...prev,
                scheduledFor: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null,
              }));
              setErrors((prev) => ({ ...prev, ...validatePatientTask('scheduledFor', date) }));
            }}
            errorText={errors.scheduledFor}
            size="small"
          />
        )}
        <InfoItem
          classNames={[styles.width100]}
          textId="subject"
          title="Subject"
          content={task.subject}
          isSmall
          multiline
        />
      </div>
      {!isReadOnly &&
        renderButtons({
          containerClassName: styles.buttonsContainer,
          onClose,
          onSubmit: onSubmitHandler,
          isSubmitEnabled: getIsButtonEnabled(data, task, errors),
          submitButtonText: 'Save and Close',
        })}
    </BasePopup>
  );
};

EditTaskPopup.propTypes = {
  onClose: PropTypes.func,
  task: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default EditTaskPopup;
