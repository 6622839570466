import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientBillingExemption extends Model {
  static modelName = 'PatientBillingExemption';

  static fields = {
    id: attr(),
    status: attr(),
    source: attr(),
    exemptionType: attr(),
    exemptionRate: attr(),
    startDate: attr(),
    endDate: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'billingExemptions',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      exemptionType: PropTypes.number,
      exemptionRate: PropTypes.number,
      status: PropTypes.number,
      source: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientBillingExemption;
