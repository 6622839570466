import _ from 'lodash';

import {
  getDosageText,
  getFormText,
  getFrequencyById,
  getRouteById,
  getSourceById,
  Sources,
  StatusesConfig,
  validatePatientMedication,
} from 'common-src/models/PatientMedication';

export const getStatusesOptions = () =>
  Object.values(StatusesConfig)
    .filter((status) => status.isSelectable && !status.isArchived)
    .map((status) => ({ value: status.id, label: status.label }));

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientMedication(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, id, patientId, medication) => {
  const body = {
    medicationId: data?.med?.ndc,
    form: getFormText(data?.med),
    dosage: getDosageText(data?.med),
    instructions: _.isEmpty(data?.instructions) ? null : data.instructions,
    notes: _.isEmpty(data?.notes) ? null : data.notes,
    status: data?.status?.value || StatusesConfig[5].id,
    route: data?.route?.value,
    frequency: data?.frequency?.value,
    source: data?.source?.value || Sources[0].value,
    activeTill: null,
    filledDate: medication?.filledDate,
    endDate: medication?.endDate,
  };

  if (id) {
    Object.assign(body, { id });
  }

  if (patientId) {
    Object.assign(body, { patientId });
  }

  return body;
};

export const extractPatientMedication = (medication = {}) => ({
  med: medication?.med,
  status: getStatusesOptions().find((option) => option.value === medication?.status),
  route: getRouteById(medication?.route),
  frequency: getFrequencyById(medication?.frequency),
  source: getSourceById(medication?.source),
  instructions: medication?.instructions || '',
  notes: medication?.notes || '',
  prescriber: medication?.prescriber,
});

export const getIsButtonEnabled = (data, errors, medication, isDuplicate) => {
  if (
    !data?.med ||
    Object.values(errors).some((e) => e) ||
    (medication && !isDuplicate && _.isEqual(data, extractPatientMedication(medication)))
  )
    return false;

  return true;
};
