import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { getIcd10Codes } from 'common-src/models/Icd10Code';
import {
  activeStatusesIds,
  DxStatusesConfig,
  getIcd10CodeDescription,
  getPatientMedicalProblems,
  getPatientMedicalProblemsRequest,
  makeMedicalProblemInactive,
  updateMedicalProblemStatus,
} from 'common-src/models/PatientMedicalProblem';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  CreatorItem,
  MultilineTextItem,
  MultiTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import BaseTable from 'src/components/table/BaseTable';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import styles from './Conditions.module.scss';
import { DefaultTabInfo, MedicalHistoryTabs, TableHeaders } from './constants';
import { getTabInfo } from './helpers';

const Conditions = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(MedicalHistoryTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const medicalProblems = useCustomSelector((state) => getPatientMedicalProblems(state, patientId));
  const icd10Codes = useCustomSelector((state) => getIcd10Codes(state));

  const { isRequesting } = useRequestLoading([getPatientMedicalProblemsRequest({ patientId })]);

  useInterval(() => {
    if (!patientId) return;
    dispatch(getPatientMedicalProblemsRequest({ patientId, limit: 5 }));
  }, 30000);

  useEffect(() => {
    const { tabInfo, activeMedicalProblems, inactiveMedicalProblems, allMedicalProblems } =
      getTabInfo(medicalProblems);

    setTabInfo(tabInfo);

    let problemsToShow;
    switch (selectedTab) {
      case tabs[0]:
        problemsToShow = activeMedicalProblems;
        break;
      case tabs[1]:
        problemsToShow = inactiveMedicalProblems;
        break;
      case tabs[2]:
        problemsToShow = allMedicalProblems;
        break;
      default:
        problemsToShow = [];
    }

    setFilteredProblems(problemsToShow);
  }, [selectedTab, medicalProblems]);

  const showMedicalProblemPopup = (id, description) =>
    dispatch(openModal(ModalType.MEDICAL_PROBLEM, { patientId, id, description }));

  const showProblemDetailsPopup = (medicalProblem, description) =>
    dispatch(openModal(ModalType.PROBLEM_DETAILS, { medicalProblem, description }));

  const getData = () =>
    filteredProblems?.map((item) => {
      const isDisabled =
        (item.activeTill && moment(item.activeTill).isBefore(moment.now())) ||
        (item.dxStatus && !activeStatusesIds.includes(item.dxStatus)) ||
        !actionsAllowed;
      return {
        name: (
          <MultiTextItem
            isActive
            title={getIcd10CodeDescription(icd10Codes, item?.icd10Code)}
            subtitle={item?.icd10Code || '-'}
            onClick={() =>
              showProblemDetailsPopup(item, getIcd10CodeDescription(icd10Codes, item?.icd10Code))
            }
          />
        ),
        comments: <MultilineTextItem isActive text={item?.comments || '-'} maxLines={2} />,
        dxDate: (
          <TextItem isActive text={item?.dxDate ? moment(item.dxDate).format('MM/DD/YYYY') : '-'} />
        ),
        createdBy: (
          <CreatorItem
            isActive
            creator={item?.createdBy}
            creatorName={item?.createdBy?.getName(false, true)}
            value={item?.createdBy?.getName(false, true)}
          />
        ),
        actions: (
          <ActionsRow
            options={[
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.MEDICAL_PROBLEM, {
                  patientId,
                  id: item?.id,
                  description: getIcd10CodeDescription(icd10Codes, item?.icd10Code),
                }),
              },
              (activeStatusesIds.includes(item?.dxStatus) || !item?.dxStatus) && {
                id: 'resolve-button',
                text: 'Resolve',
                backgroundColor: '#375844CC',
                color: 'white',
                disabled: isDisabled,
                onClick: updateMedicalProblemStatus(item.id, DxStatusesConfig[5].id, item, {}),
              },
              {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeMedicalProblemInactive(item.id, {}),
                showWarning: true,
              },
              isDisabled && {
                id: 'duplicate-button',
                text: 'Duplicate',
                backgroundColor: ColorsNew.baseGreen,
                color: 'white',
                disabled: !actionsAllowed,
                onClick: openModal(ModalType.MEDICAL_PROBLEM, {
                  patientId,
                  id: item?.id,
                  description: getIcd10CodeDescription(icd10Codes, item?.icd10Code),
                  isDuplicate: true,
                }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling medical history..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="add-button"
            text="Add Diagnosis"
            onClick={() => showMedicalProblemPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="past-medical-history"
        classNames={[
          'medical-table-new',
          filteredProblems?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No medical history"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{ column: 'dxDate', label: 'Dx Date', width: '10%', sortBy: 'text' }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredProblems}
        selectedTab={selectedTab}
      />
    </>
  );
};

Conditions.propTypes = {
  patientId: PropTypes.number.isRequired,
  actionsAllowed: PropTypes.bool,
};

export default Conditions;
