import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Select, TextArea } from 'common-src/components/base';
import { deleteOrmItems } from 'common-src/features/rest';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  addCgmOrder,
  CGMOrderStatus,
  CGMSuppliers,
  getPatientSingleCgmOrder,
  updateCgmOrder,
  validatePatientCgmOrder,
  validatePatientCgmOrderDates,
} from 'common-src/models/PatientCgmOrder';
import PatientHistoryEvent from 'common-src/models/PatientHistoryEvent';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import { catchErrors } from 'src/popups/AllergyPopup/helpers';
import BasePopup from 'src/popups/BasePopup';

import { initialState, productOptions } from './constants';
import { extractPatientCgmOrder, getRequestBody } from './helpers';
import styles from './ManualCGMOrderPopup.module.scss';

const ManualCGMOrderPopup = ({ open, onClose, id, patientId }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);
  const [dateErrors, setDateErrors] = useState({});

  const cgmOrder = useCustomSelector((state) => getPatientSingleCgmOrder(state, id)) || {};

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (_.isEmpty(cgmOrder)) return;
    setData(extractPatientCgmOrder(cgmOrder));
  }, [cgmOrder?.id]);

  const onSubmit = (loadingCallback, successCallback, errorCallback) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const requests = [];
    requests.push(
      id ? updateCgmOrder(id, getRequestBody(data)) : addCgmOrder(getRequestBody(data, patientId)),
    );

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Cgm order ${id ? 'edited' : 'added'}!`);
          deleteOrmItems(PatientHistoryEvent.modelName);
          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChange = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientCgmOrder(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onDateChange = (field, value) => {
    setDateErrors(validatePatientCgmOrderDates(field, value, data));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const getIsButtonEnabled = () =>
    data.product &&
    data.supplier &&
    data.status &&
    data.orderedAt &&
    Object.values(errors).every((e) => !e) &&
    Object.values(dateErrors).every((e) => !e) &&
    !_.isEqual(data, extractPatientCgmOrder(cgmOrder));

  return (
    <BasePopup
      id="cgm-order"
      open={open}
      onClose={onClose}
      title={id ? 'View & Edit Order' : 'Add Order'}
    >
      <div className={styles.grid}>
        <Select
          id="product-select-input"
          classNames={[styles.width66]}
          label="Supplies Ordered"
          options={!id ? productOptions.filter((p) => !!p.canBeAddedManually) : productOptions}
          onChange={(value) => onChange('product', value?.value || null)}
          value={_.find(productOptions, { value: data.product }) || null}
          required
          placeholder="Choose supply"
          errorText={errors.product}
          disabled={!!id}
        />
        <span />
        <Select
          id="supplier-select-input"
          classNames={[styles.width66]}
          label="Supplier"
          options={Object.values(CGMSuppliers)}
          onChange={(value) => onChange('supplier', value?.value || null)}
          value={_.find(Object.values(CGMSuppliers), { value: data.supplier }) || null}
          required
          placeholder="Choose supplier"
          errorText={errors.supplier}
        />
        <Select
          id="status-select-input"
          label="Status"
          options={Object.values(CGMOrderStatus)}
          onChange={(value) => onChange('status', value?.value || null)}
          value={_.find(Object.values(CGMOrderStatus), { value: data.status }) || null}
          required
          placeholder="Choose status"
          errorText={errors.status}
        />
        <DatePickerNew
          id="order-date"
          value={data?.orderedAt || null}
          header="Order Date"
          placeholder="Order Date"
          onDateSelected={(date) =>
            onDateChange('orderedAt', date ? moment(date).utc().toISOString() : null)
          }
          errorText={dateErrors.orderedAt}
          required
        />
        <DatePickerNew
          id="sent-at-date"
          value={data?.sentAt || null}
          header="Date Sent"
          placeholder="Date Sent"
          onDateSelected={(date) =>
            onDateChange('sentAt', date ? moment(date).utc().toISOString() : null)
          }
          errorText={dateErrors.sentAt}
        />
        <DatePickerNew
          id="shipped-at-date"
          value={data?.shippedAt || null}
          header="Date Shipped"
          placeholder="Date Shipped"
          onDateSelected={(date) =>
            onDateChange('shippedAt', date ? moment(date).utc().toISOString() : null)
          }
          errorText={dateErrors.shippedAt}
        />
        <TextArea
          id="notes"
          classNames={[styles.width100]}
          value={data.notes}
          onTextChange={(value) => onChange('notes', value)}
          fixedRows={4}
          label="Notes (Limit 250 Characters)"
          errorText={errors.notes}
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit,
        isSubmitEnabled: getIsButtonEnabled(),
        submitButtonText: 'Save and Close',
      })}
    </BasePopup>
  );
};

ManualCGMOrderPopup.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number,
};

export default ManualCGMOrderPopup;
