import _ from 'lodash';

import { Frequencies, Routes, Sources, StatusesConfig } from './constants';

export const getStatusById = (id) => _.find(StatusesConfig, (status) => status.id === id);

export const getSourceById = (id) => _.find(Sources, (source) => source.value === id);

export const getRouteById = (id) => _.find(Routes, (route) => route.value === id);

export const getFrequencyById = (id) => _.find(Frequencies, (fr) => fr.value === id);

export const isManuallyCreated = (id) =>
  _.find(Sources, (source) => source.value === id)?.isSelectable === true;
