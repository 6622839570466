import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientOncehubBooking extends Model {
  static modelName = 'PatientOncehubBooking';

  static fields = {
    id: attr(),
    name: attr(),
    eventType: attr(),
    eventName: attr(),
    status: attr(),
    scheduledAt: attr(),
    durationMinutes: attr(),
    rescheduleUrl: attr(),
    visitType: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'bookingChanger'),
    clientId: fk({
      to: 'Client',
      as: 'client',
      relatedName: 'oncehubBookings',
    }),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'oncehubBookings',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
      eventType: PropTypes.string,
      eventName: PropTypes.string,
      status: PropTypes.string,
      scheduledAt: PropTypes.string,
      durationMinutes: PropTypes.number,
      rescheduleUrl: PropTypes.string,
      visitType: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      clientId: PropTypes.number,
      patientId: PropTypes.number,
    }),
    clientId: PropTypes.number,
    patientId: PropTypes.number,
  };
}

export default PatientOncehubBooking;
