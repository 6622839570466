import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';
import PatientCareProvider from 'common-src/models/PatientCareProvider/PatientCareProvider';

import { createCareProvider, deleteCareProvider, updateCareProvider } from './actions';

export const getProvidersRequests = (oldProviders, newProviders) => {
  if (!Array.isArray(oldProviders) || !Array.isArray(newProviders)) return [];

  const requests = [];

  newProviders.forEach((provider) => {
    const providerExists = oldProviders.find(({ id }) => id === provider.id);

    if (!providerExists) {
      // Add new provider
      requests.push(createCareProvider(provider));
      return;
    }

    if (!_.isEqual(provider, providerExists)) {
      // Edit provider
      const payload = {
        patientId: provider.patient,
        phone: provider.phone,
        name: provider.name,
        fax: provider.fax,
        typeId: provider.typeId,
      };

      requests.push(updateCareProvider({ id: provider.id, ...payload }));
    }
  });

  // Delete emergency contact
  oldProviders.forEach(({ id }) => {
    const providerExists = newProviders.find((p) => p.id === id);

    if (!providerExists) {
      requests.push(
        deleteCareProvider(id, {
          successBlock: () => {
            deleteOrmItem(PatientCareProvider.modelName, id);
          },
        }),
      );
    }
  });

  return requests;
};
