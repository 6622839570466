/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'common-src/components/base';

import Images from 'src/assets/Images';

import styles from './AvatarCircle.module.scss';
import { CircleSize } from './constants';

const AvatarCircle = ({
  user,
  classNames,
  imgSrc,
  clickHandler,
  withTooltip = false,
  showUserInitials = false,
  showOriginalImgSize = false,
  circleSize = CircleSize.REGULAR,
}) => {
  const decryptedImageData = null;

  const containerClassNames = [
    styles.circularView,
    'vertically-centered',
    'horizontally-centered',
    'border-r-50',
  ];
  const imageIconClassNames = [styles.imageIcon, 'border-r-50'];

  if (circleSize === CircleSize.REGULAR) {
    containerClassNames.push(styles.regular);
  } else {
    containerClassNames.push(styles.large);
  }

  if (classNames) {
    containerClassNames.push(...classNames);
  }

  if ((imgSrc || decryptedImageData) && showOriginalImgSize) {
    imageIconClassNames.push(styles.avatarImg);
  }

  const getUserInitials = () =>
    `${user?.firstName?.charAt(0) || ''}${user?.lastName?.charAt(0) || ''}`;

  const getUserImage = () => {
    if (imgSrc || decryptedImageData) {
      return (
        <img className={imageIconClassNames.join(' ')} src={imgSrc || decryptedImageData} alt="" />
      );
    }

    if (showUserInitials && !_.isEmpty(user)) {
      return (
        <span
          className={[
            styles.userText,
            'vertically-centered',
            'horizontally-centered',
            'border-r-50',
            'font-s-12',
          ].join(' ')}
        >
          {getUserInitials()}
        </span>
      );
    }

    return <img className={styles.personIcon} src={Images.avatar} alt="" />;
  };

  const renderAvatar = () => (
    <div id="circle-icon" className={containerClassNames.join(' ')} onClick={clickHandler}>
      {getUserImage()}
    </div>
  );

  if (withTooltip && !_.isEmpty(user)) {
    return <Tooltip title={user.getName(true)}>{renderAvatar()}</Tooltip>;
  }

  return renderAvatar();
};

AvatarCircle.propTypes = {
  user: PropTypes.object,
  imgSrc: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  showOriginalImgSize: PropTypes.bool,
  showUserInitials: PropTypes.bool,
  clickHandler: PropTypes.func,
  withTooltip: PropTypes.bool,
  circleSize: PropTypes.oneOf([CircleSize.REGULAR, CircleSize.LARGE]),
};

export default AvatarCircle;
