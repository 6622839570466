import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Input } from 'common-src/components/base';
import { DeviceType, validatePatientDevice } from 'common-src/models/PatientDevice';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { getIsButtonEnabled } from './helpers';
import styles from './PatientDevicePopup.module.scss';

const PatientDevicePopup = ({ onClose, onActionButtonClick, buttonText, open = true }) => {
  const [deviceId, setDeviceId] = useState('');

  const { errors, setErrors, renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback) => {
    loadingCallback();
    onActionButtonClick({
      deviceId,
      deviceType: DeviceType.TranstekGlucometer,
    });
  };

  const onChangeHandler = (key, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientDevice(key, value) }));
    setDeviceId(value);
  };

  return (
    <BasePopup id="patient-device" open={open} onClose={onClose} title="Add BGM device">
      <Input
        id="device-id-number-input"
        classNames={[styles.input]}
        errorText={errors.deviceId}
        label="Device ID number"
        onTextChange={(value) => onChangeHandler('deviceId', value)}
        value={deviceId}
        placeholder="Enter device ID"
        required
      />
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(deviceId, errors),
        submitButtonText: buttonText,
      })}
    </BasePopup>
  );
};

PatientDevicePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onActionButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default PatientDevicePopup;
