import _ from 'lodash';

import { RestCallState } from './constants';

const restActionTypeFunc = (actionSegment) => (uri) => `rest/${[uri, actionSegment].join('_')}`;

export const requestActionType = restActionTypeFunc(RestCallState.REQUEST);
export const responseReceivedActionType = restActionTypeFunc(RestCallState.RESPONSE);
export const errorActionType = restActionTypeFunc(RestCallState.ERROR);
export const requestMultipleActionType = restActionTypeFunc(RestCallState.REQUEST_MULTIPLE);

const restActionTypeRegexFunc = (actionSegments) =>
  new RegExp(`^rest/${actionSegments.join('_')}$`);

const restActionTestFunc = (actionSegment) => (uri) => (action) =>
  restActionTypeRegexFunc(_.compact([uri, actionSegment])).test(action.type);

export const requestActionTest = restActionTestFunc(RestCallState.REQUEST);
export const responseReceivedActionTest = restActionTestFunc(RestCallState.RESPONSE);
export const errorActionTest = restActionTestFunc(RestCallState.ERROR);
export const requestMultipleActionTest = restActionTestFunc(RestCallState.REQUEST_MULTIPLE);
