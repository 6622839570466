// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs-module__container___ArSUc {
  display: flex;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/MainNavigation/components/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Tabs-module__container___ArSUc`
};
module.exports = ___CSS_LOADER_EXPORT___;
