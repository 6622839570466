export const ExemptionsTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
  ALL: {
    text: 'All',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: ExemptionsTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: ExemptionsTabs.NOT_ACTIVE.text,
    count: 0,
  },
  ALL: {
    text: ExemptionsTabs.ALL.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'exemptionType', label: 'Exemption', width: '44%', sortBy: 'text' },
  { column: 'exemptionRate', label: 'Details', width: '20%', sortBy: 'value' },
  { column: 'status', label: 'Status', width: '16%', sortBy: 'text' },
  { column: 'startDate', label: 'Start Date', width: '14%', sortBy: 'value' },
  { column: 'endDate', label: 'End Date', width: '14%', sortBy: 'value' },
];
