import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { ColorsNew } from 'common-src/styles';

import styles from './StatusItem.module.scss';

const StatusItem = ({
  isActive,
  text,
  disabled,
  small = false,
  withArrow = true,
  onClick = () => {},
  textColor = 'white',
  backgroundColor = ColorsNew.darkGreen,
}) => {
  const containerClasses = [styles.container, 'vertically-centered'];
  if (!isActive) {
    containerClasses.push(styles.notActive);
  }
  if (disabled) {
    containerClasses.push(styles.disabled);
  }

  return (
    <div className={containerClasses.join(' ')} role="presentation" onClick={onClick}>
      <span
        style={{ color: textColor, backgroundColor }}
        className={[
          styles.text,
          'font-w-500',
          'primary-border',
          'font-s-14',
          small ? styles.small : '',
        ].join(' ')}
      >
        {text || '-'}
      </span>
      {withArrow && <img className={styles.downIcon} src={CommonIcons.downIcon} alt="down-icon" />}
    </div>
  );
};

StatusItem.propTypes = {
  isActive: PropTypes.bool,
  text: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  withArrow: PropTypes.bool,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default StatusItem;
