import { getScrollableParent } from 'common-src/utils/domUtils';

const MARGIN_TOP = 30;

export const getTooltipStyles = (tooltipRef, wrapperRef) => {
  const { height: tooltipHeight, width: tooltipWidth } = tooltipRef.current.getBoundingClientRect();
  const {
    width: wrapperWidth,
    bottom: wrapperBottom,
    right: wrapperRight,
  } = wrapperRef.current.getBoundingClientRect();

  const scrollableParent = getScrollableParent(tooltipRef.current);
  const parentScrollHeight = scrollableParent.scrollHeight;
  const parentOffsetHeight = scrollableParent.offsetHeight;
  const hasScroll = parentScrollHeight !== parentOffsetHeight;
  const containerHeight = hasScroll ? parentScrollHeight : window.innerHeight;

  const styles = {
    marginTop: `${
      scrollableParent.scrollTop === 0 ? MARGIN_TOP : MARGIN_TOP - scrollableParent.scrollTop
    }px`,
  };

  if (containerHeight <= wrapperBottom + tooltipHeight) {
    Object.assign(styles, {
      marginTop: `-${
        scrollableParent.scrollTop === 0
          ? tooltipHeight + wrapperBottom + MARGIN_TOP
          : tooltipHeight + wrapperBottom + MARGIN_TOP + scrollableParent.scrollTop
      }px`,
    });
  }

  if (parentOffsetHeight < wrapperBottom + tooltipHeight) {
    Object.assign(styles, { marginTop: `-${tooltipHeight + scrollableParent.scrollTop}px` });
  }

  if (window.innerWidth < wrapperRight + tooltipWidth) {
    Object.assign(styles, { marginLeft: `-${tooltipWidth - wrapperWidth}px` });
  }

  return styles;
};
