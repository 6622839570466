import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import Icons from 'src/assets/Icons';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './Tab.module.scss';

const Tab = ({ tabName, isSelected, onTabSelect, onTabClose, style, showCloseButton = false }) => {
  const patientId = tabName?.replace(/^\D+/g, '');
  const patient = useCustomSelector((state) =>
    patientId ? singleModelSelector(state, 'Patient', Number(patientId)) : null,
  );

  const patientName = useMemo(() => {
    if (_.isEmpty(patient)) return '';
    return patient.getName();
  }, [patient]);

  return (
    <div
      id={`tab-${tabName}`}
      className={[styles.tabItem, 'vertically-centered'].join(' ')}
      style={style}
      onClick={() => {
        if (!isSelected) {
          onTabSelect?.(tabName);
        }
      }}
      role="presentation"
    >
      <p
        style={style}
        className={
          isSelected
            ? [styles.text, styles.selectedText, 'font-w-700', 'font-s-16'].join(' ')
            : [styles.text, 'font-w-400', 'font-s-16'].join(' ')
        }
      >
        {patientName || (tabName?.startsWith('Member Details') ? 'Member Details' : tabName)}
      </p>
      {showCloseButton && (
        <img
          id={`close-tab-${tabName}-btn`}
          className={styles.closeIcon}
          src={isSelected ? Icons.boldCloseIcon : CommonIcons.closeIcon}
          alt="close-icon"
          onClick={(e) => {
            e.stopPropagation();
            onTabClose?.(tabName, isSelected);
          }}
          role="presentation"
        />
      )}
      {isSelected && <div id="tab-line" className={styles.line} />}
    </div>
  );
};

Tab.propTypes = {
  tabName: PropTypes.string,
  isSelected: PropTypes.bool,
  onTabSelect: PropTypes.func,
  onTabClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  style: PropTypes.object,
};

export default Tab;
