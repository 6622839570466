import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { DatePickerNew, Toggle } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';

import { CGMPeriodRange } from 'src/pages/PatientDetails/fragments/Biometrics/constants';

import styles from './CGMFiltersHeader.module.scss';

const CGMFiltersHeader = ({
  patient,
  selectedPeriod,
  setSelectedPeriod,
  startingDate,
  setStartingDate,
  endingDate,
  setEndingDate,
}) => {
  const onRangeSelectedHandler = (dates) => {
    const [start, end] = dates;
    if (!start && !end) {
      const period = CGMPeriodRange[3].value;
      setStartingDate(start || moment().subtract(period, 'days').format('YYYY-MM-DD HH:mm'));
      setEndingDate(end || moment().format('YYYY-MM-DD HH:mm'));
      setSelectedPeriod(period);
      return;
    }

    setStartingDate(moment(start).format('YYYY-MM-DD HH:mm'));
    setEndingDate(moment(end).format('YYYY-MM-DD HH:mm'));
    setSelectedPeriod(moment(end).diff(moment(start), 'days'));
  };

  const getDateErrorText = () =>
    moment(endingDate).diff(moment(startingDate), 'days') > 28
      ? 'Timespan should be less than 28 days'
      : '';

  const renderDeviceInfo = () => {
    if (!patient?.cgmType) return null;

    return (
      <span id="device-name" className={[styles.text, 'font-w-700', 'font-s-10'].join(' ')}>
        {patient.cgmType}
      </span>
    );
  };

  return (
    <div className={[styles.container, 'flex-row', 'p-l-30'].join(' ')}>
      <div className={styles.filters}>
        <Toggle
          options={CGMPeriodRange}
          value={selectedPeriod}
          onClick={setSelectedPeriod}
          defaultValue={CGMPeriodRange[3].value}
          size="small"
        />
        <DatePickerNew
          id="range-date-picker"
          inputClassNames={[styles.date]}
          startDate={startingDate}
          endDate={endingDate}
          selectsRange
          maxDate={new Date()}
          onRangeSelected={onRangeSelectedHandler}
          errorText={getDateErrorText()}
          size="small"
        />
      </div>
      {renderDeviceInfo()}
    </div>
  );
};

CGMFiltersHeader.propTypes = {
  selectedPeriod: PropTypes.number,
  setSelectedPeriod: PropTypes.func,
  startingDate: PropTypes.string,
  setStartingDate: PropTypes.func,
  endingDate: PropTypes.string,
  setEndingDate: PropTypes.func,
  patient: PropTypes.exact(Patient.schema),
};

export default CGMFiltersHeader;
