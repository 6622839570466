import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { TabNew } from 'src/components/tabs';

import styles from './TabsViewNew.module.scss';

const TabsViewNew = ({
  options,
  onTabSelected,
  classNames,
  callback,
  showZero,
  rightContent,
  tabClassNames,
  initialTab = 0,
}) => {
  const [selectedTab, setSelectedTab] = useState();

  const containerClasses = [styles.container];
  if (classNames) {
    containerClasses.push(...classNames);
  }

  const tabClasses = [styles.tabs];
  if (tabClassNames) {
    tabClasses.push(...tabClassNames);
  }

  useEffect(() => {
    if (!Number.isInteger(initialTab)) return;

    setSelectedTab(initialTab);
  }, [initialTab]);

  const handleChange = (newValue) => {
    setSelectedTab(newValue);
    onTabSelected?.(Object.keys(options)[newValue]);
    callback?.(newValue);
  };

  const renderTabs = () => (
    <div className={tabClasses.join(' ')}>
      {Object.values(options).map((tab, index) => (
        <TabNew
          key={index.toString()}
          text={tab?.text || ''}
          selected={index === selectedTab}
          count={tab?.count}
          onClick={() => handleChange(index)}
          showZero={showZero}
          disabled={tab?.disabled}
        />
      ))}
    </div>
  );

  return (
    <div className={containerClasses.join(' ')}>
      {renderTabs()}
      {rightContent || null}
    </div>
  );
};

TabsViewNew.propTypes = {
  options: PropTypes.object.isRequired,
  initialTab: PropTypes.number,
  onTabSelected: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
  callback: PropTypes.func,
  showZero: PropTypes.bool,
  rightContent: PropTypes.element,
  tabClassNames: PropTypes.arrayOf(PropTypes.string),
};

export default TabsViewNew;
