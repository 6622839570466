import { moduleTypes } from 'common-src/models/PatientInteraction';

export const VitalsAndLabsModules = [
  moduleTypes.QuarterlyCheckIn.id,
  moduleTypes.NutritionFollowUp.id,
  moduleTypes.MonthlyWellnessVisitAC.id,
  moduleTypes.MNTNote.id,
  moduleTypes.ClinicalFirstVisitNP.id,
  moduleTypes.ClinicalFirstVisitRpmOrder.id,
];

export const WellnessPlanModules = [
  moduleTypes.InitialCoachingCall.id,
  moduleTypes.MonthlyWellnessVisitAC.id,
];

export const CGMAlertsModules = [moduleTypes.CGMAssessment.id];

export const CGMEvaluationModules = [moduleTypes.CGMAssessment.id];
