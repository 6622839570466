import { createReducer } from 'common-src/utils/reducerUtils';

import { CLOSE_ALERT, RESET_ALERT_STATE, SHOW_ALERT } from './actionTypes';

const MaxAlertsSize = 5;

const initialState = {
  active: [],
};

const entityHandlers = {
  [SHOW_ALERT]: (state, payload) => {
    const currentAlertsSize = state.active.length;

    // hit the max limit
    if (currentAlertsSize === MaxAlertsSize) {
      return {
        ...state,
        active: [...state.active.slice(0, -1), payload],
      };
    }

    return {
      ...state,
      active: [...state.active, payload],
    };
  },
  [CLOSE_ALERT]: (state, payload) => ({
    ...state,
    active: state.active.filter((alert) => alert.id !== payload.id),
  }),
  [RESET_ALERT_STATE]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
