export const initialState = {
  patientCoverage: null,
  authCode: '',
  startDate: null,
  endDate: null,
  status: null,
  serviceType: null,
};

export const UPDATABLE_FIELDS = ['endDate', 'status', 'serviceType'];
