import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Menu } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import Patient from 'common-src/models/Patient';
import {
  getPatientInteractions,
  getPatientInteractionsApiRequest,
  moduleTypes,
} from 'common-src/models/PatientInteraction';
import {
  getPatientOncehubBookings,
  getPatientOncehubBookingsRequest,
  getScheduleVisitQueryParams,
  ScheduleVisitLinks,
} from 'common-src/models/PatientOncehubBooking';
import { getPrimaryPatientPhoneNumber } from 'common-src/models/PatientPhoneNumber';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from '../commonStyles.module.scss';
import { getWarningMessage } from './helpers';

const ScheduleVisitMenu = ({ patient, tooltipText }) => {
  const dispatch = useDispatch();

  const primaryPatientPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patient?.id),
  );
  const scheduledVisits = useCustomSelector((state) =>
    getPatientOncehubBookings(state, patient?.id),
  );
  const patientInteractions = useCustomSelector((state) =>
    getPatientInteractions(state, patient?.id),
  );

  useEffect(() => {
    if (!patient?.id) return;

    dispatch(
      restRequestMultiple({
        restRequests: [
          getPatientOncehubBookingsRequest({ patientId: patient?.id }, {}),
          getPatientInteractionsApiRequest({
            patientId: patient?.id,
            isDraft: false,
            module_types: [
              moduleTypes.MNTNote.id,
              moduleTypes.NutritionConsult.id,
              moduleTypes.NutritionFollowUp.id,
              moduleTypes.BiannualVisit.id,
            ],
          }),
        ],
      }),
    );
  }, [patient?.id]);

  const options = Object.values(ScheduleVisitLinks).map((op) => ({
    text: op.label,
    id: op.value,
    onClick: () => {
      if (op.label === ScheduleVisitLinks.DeviceTraining.label) {
        dispatch(openModal(ModalType.ADD_TASK, { patientId: patient.id }));
        return;
      }

      const warningMessage = getWarningMessage(
        op.label,
        scheduledVisits,
        patientInteractions,
        patient,
      );
      if (warningMessage) {
        dispatch(
          openModal(ModalType.WARNING, {
            message: warningMessage,
            type: 'error',
            title: 'Warning',
            onSubmit: () => {
              window.open(
                `${op.value}${getScheduleVisitQueryParams(
                  patient,
                  primaryPatientPhoneNumber,
                  op.linkType,
                )}`,
                '_blank',
              );
            },
            submitButtonText: 'Continue',
          }),
        );

        return;
      }

      window.open(
        `${op.value}${getScheduleVisitQueryParams(
          patient,
          primaryPatientPhoneNumber,
          op.linkType,
        )}`,
        '_blank',
      );
    },
  }));

  return (
    <Menu
      button={
        <img
          id="schedule-visit-button"
          className={styles.actionButton}
          src={Icons.calendarPlusIcon}
          alt="schedule-visit-icon"
        />
      }
      options={options}
      tooltipText={tooltipText}
    />
  );
};

ScheduleVisitMenu.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  tooltipText: PropTypes.string,
};

export default ScheduleVisitMenu;
