import { CLOSE_MODAL, OPEN_MODAL, RESET_MODAL_STATE } from './actionTypes';

export const openModal = (modalName, props) => ({
  type: OPEN_MODAL,
  payload: { modalName, props },
});

export const closeModal = (modalName) => ({
  type: CLOSE_MODAL,
  payload: { modalName },
});

export const resetModalState = () => ({
  type: RESET_MODAL_STATE,
  payload: {},
});
