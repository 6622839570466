export const AllergyTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: AllergyTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: AllergyTabs.NOT_ACTIVE.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'name', label: 'Allergy Name', width: '39%', sortBy: 'title' },
  { column: 'reaction', label: 'Reaction', width: '20%', sortBy: 'text' },
  { column: 'createdAt', label: 'Date Added', width: '13%', sortBy: 'value' },
  { column: 'severity', label: 'Severity', width: '10%', sortBy: 'text' },
  { column: 'actions', label: 'Actions', width: '18%' },
];
