import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import { deleteOrmItem } from 'common-src/features/rest';
import PatientInteraction, {
  deleteDraftApiRequest,
  InteractionStatus,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import { getNoteData, getPatientNote } from 'src/features/tabsState';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './SectionFooter.module.scss';

const initialLoadingState = { save: false, delete: false };

const SectionFooter = ({
  isLoading,
  isUpdating,
  showBillingInfo,
  handleButtonClick,
  handleBackButtonClick,
  isDraftSaving,
  draftSavedAt,
  onClose,
  isEmbedded,
  handleError,
  patientId,
  timer,
  withBackButton = true,
}) => {
  const dispatch = useDispatch();

  const [actionLoading, setActionLoading] = useState(initialLoadingState);

  const noteId = useCustomSelector((state) => getPatientNote(state, patientId));
  const noteData = useCustomSelector((state) => getNoteData(state, patientId));

  const isButtonEnabled = !((showBillingInfo || !withBackButton) && isDraftSaving);

  const { showAlert, AlertType } = useAlert();

  const renderLoading = () => (
    <>
      <img className={styles.icon} src={CommonIcons.checkIcon} alt="check-icon" />
      <span className={['m-l-5', 'font-s-13'].join(' ')} style={{ color: Colors.mediumDarkGray }}>
        Saving changes...
      </span>
    </>
  );

  const renderUpdating = () => (
    <>
      <img className={styles.icon} src={CommonIcons.checkIcon} alt="check-icon" />
      <span className={['m-l-5', 'font-s-13'].join(' ')} style={{ color: Colors.mediumDarkGray }}>
        Updating draft...
      </span>
    </>
  );

  const renderTime = () => (
    <>
      <img src={CommonIcons.checkIcon} alt="check-icon" />
      <span
        id="last-saved-at"
        className={['m-l-5', 'font-s-13'].join(' ')}
        style={{ color: ColorsNew.darkGreen }}
      >
        Draft last saved {moment(draftSavedAt).format('MM/DD/YYYY')} at{' '}
        {moment(draftSavedAt).format('h:mm A')}
      </span>
    </>
  );

  const renderDraftInfo = () => (
    <div className={['vertically-centered', 'm-r-24'].join(' ')}>
      {!showBillingInfo
        ? isDraftSaving
          ? renderLoading()
          : draftSavedAt
            ? renderTime()
            : isUpdating
              ? renderUpdating()
              : null
        : isDraftSaving
          ? renderLoading()
          : null}
    </div>
  );

  const renderOldButtonsContent = () => (
    <>
      <Button
        id="cancel-button"
        text="Cancel"
        onClick={() => window.close()}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
      />
      <Button
        id={showBillingInfo || !withBackButton ? 'finish-button' : 'done-button'}
        text={showBillingInfo || !withBackButton ? 'Save and close' : 'Done'}
        isLoading={isLoading}
        disabled={!isButtonEnabled}
        onClick={handleButtonClick}
      />
    </>
  );

  const successHandler = (message) => {
    showAlert(AlertType.Success, '', message);
    setActionLoading(false);
  };

  const errorHandler = (err) => {
    setActionLoading(false);
    handleError({ generalError: err, fieldErrors: new Map() });
  };

  const renderNewButtonsContent = () => (
    <>
      {(!showBillingInfo || !withBackButton) && (
        <Button
          id="save-and-pause"
          text="Save and Pause"
          onClick={() => {
            setActionLoading((prev) => ({ ...prev, save: true }));
            dispatch(
              updatePatientDraftApiRequest(
                noteId,
                {
                  ...noteData,
                  status: InteractionStatus.Paused,
                  duration: timer,
                },
                {
                  successBlock: () => {
                    successHandler('The note was successfully saved and paused!');
                    setTimeout(() => {
                      onClose();
                    }, 500);
                  },
                  errorBlock: (err) => errorHandler(err),
                },
              ),
            );
          }}
          backgroundColor={Colors.white}
          textColor={ColorsNew.darkGreen}
          isLoading={actionLoading.save}
        />
      )}
      <Button
        id="delete"
        classNames={[styles.deleteBtn]}
        text="Delete"
        onClick={() => {
          dispatch(
            openModal(ModalType.WARNING, {
              message:
                'Are you sure you want to delete this note? A draft will not be saved and no time will be recorded.',
              type: 'error',
              title: 'Delete Note?',
              onSubmit: () => {
                setActionLoading((prev) => ({ ...prev, delete: true }));
                dispatch(
                  deleteDraftApiRequest(noteId, {
                    successBlock: () => {
                      deleteOrmItem(PatientInteraction.modelName, noteId);
                      successHandler('The note was successfully deleted!');
                      setTimeout(() => {
                        onClose();
                      }, 500);
                    },
                    errorBlock: (err) => errorHandler(err),
                  }),
                );
              },
            }),
          );
        }}
        backgroundColor={ColorsNew.mediumDarkRed}
        textColor={Colors.white}
        isLoading={actionLoading.delete}
      />
      <Button
        id={showBillingInfo || !withBackButton ? 'finish-button' : 'done-button'}
        text="Submit"
        isLoading={isLoading}
        disabled={!isButtonEnabled}
        onClick={handleButtonClick}
      />
    </>
  );

  const renderButtons = () => (
    <div className={[styles.buttons, 'gap-16'].join(' ')}>
      {showBillingInfo && withBackButton && (
        <Button
          id="back-button"
          text="Back"
          onClick={handleBackButtonClick}
          backgroundColor={Colors.white}
          textColor={ColorsNew.darkGreen}
        />
      )}
      {isEmbedded ? renderNewButtonsContent() : renderOldButtonsContent()}
    </div>
  );

  return (
    <footer
      className={[
        styles.footer,
        isEmbedded ? styles.embedded : '',
        'gap-16',
        'vertically-centered',
        'primary-border-t',
      ].join(' ')}
    >
      {renderDraftInfo()}
      {renderButtons()}
    </footer>
  );
};

SectionFooter.propTypes = {
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isDraftSaving: PropTypes.bool,
  withBackButton: PropTypes.bool,
  draftSavedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  showBillingInfo: PropTypes.bool,
  handleButtonClick: PropTypes.func,
  handleBackButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  isEmbedded: PropTypes.bool,
  handleError: PropTypes.func,
  patientId: PropTypes.number,
  timer: PropTypes.number,
};

export default SectionFooter;
