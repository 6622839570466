import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientRestRequest = (id, params) =>
  restRequest({ uri: `members/${id}`, method: HttpMethods.Get, ...params });

export const reopenAccount = (id, params) =>
  restRequest({
    uri: `members/${id}/reopen`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });

export const updatePatient = (id, body, params = {}) =>
  restRequest({
    uri: `members/${id}`,
    method: HttpMethods.Patch,
    body: { ...body },
    ...params,
  });
