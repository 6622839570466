import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientEmergencyContactsRequest = (query, params) =>
  restRequest({
    uri: 'patientEmergencyContacts',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientEmergencyContactRequest = (body, params) =>
  restRequest({
    uri: 'patientEmergencyContacts',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientEmergencyContactRequest = (id, body, params) =>
  restRequest({
    uri: `patientEmergencyContacts/${id}`,
    body,
    method: HttpMethods.Put,
    ...params,
  });

export const deletePatientEmergencyContactRequest = (id, params) =>
  restRequest({
    uri: `patientEmergencyContacts/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
