import { attr, fk, Model } from 'redux-orm';

class PatientCoverageHierarchy extends Model {
  static modelName = 'PatientCoverageHierarchy';

  static fields = {
    id: attr(),
    hierarchy: attr(),
    effectiveFrom: attr(),
    effectiveTo: attr(),
    effectiveDate: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    patientCoverageId: fk({
      to: 'PatientCoverage',
      as: 'patientCoverage',
      relatedName: 'coverageHierarchy',
    }),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'coverageHierarchies',
    }),
  };
}

export default PatientCoverageHierarchy;
