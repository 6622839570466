import { attr, fk, Model } from 'redux-orm';

class CgmAuthRequest extends Model {
  static modelName = 'CgmAuthRequest';

  static fields = {
    id: attr(),
    deviceType: attr(),
    status: attr(),
    target: attr(),
    authUrl: attr(),
    validUntil: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    requestedBy: fk('Client', 'cgmAuthRequester'),
    updatedBy: fk('Client', 'cgmAuthUpdater'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'cgmAuthRequests',
    }),
  };
}

export default CgmAuthRequest;
