import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input } from 'common-src/components/base';
import {
  createVital,
  getLastPatientVital,
  validatePatientVital,
} from 'common-src/models/PatientVital';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import { catchErrors, getInitialState, getIsButtonEnabled, getRequestBody } from './helpers';
import styles from './VitalsPopup.module.scss';

const VitalsPopup = ({ patientId, onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const lastPatientVital = useCustomSelector((state) => getLastPatientVital(state, patientId));

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!lastPatientVital) return;
    setData(getInitialState(lastPatientVital));
  }, [lastPatientVital]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    loadingCallback();
    dispatch(
      createVital(getRequestBody(data, patientId), {
        successBlock: () => {
          successCallback('Vital added!');

          if (!shouldClose) {
            setData(initialState);
            setAutoFocus(true);
            return;
          }

          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientVital(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <BasePopup id="vitals" open onClose={onClose} title="Add Vitals">
      <div className={styles.grid}>
        <Input
          id="height"
          type="number"
          placeholder="Enter height"
          label="Height (in)"
          value={data.height}
          errorText={errors.height}
          onTextChange={(value) => onChangeHandler('height', value)}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
          isFloat
        />
        <Input
          id="weight"
          type="number"
          placeholder="Enter weight"
          label="Weight (lbs)"
          value={data.weight}
          errorText={errors.weight}
          onTextChange={(value) => onChangeHandler('weight', value)}
          isFloat
        />
        <Input
          id="bloodPressure"
          mask="999/999"
          placeholder="Enter blood pressure"
          label="Blood Pressure (mmHg)"
          value={data.bloodPressure}
          errorText={errors.bloodPressure}
          onTextChange={(value) => onChangeHandler('bloodPressure', value)}
        />
        <DatePickerNew
          id="date-observed"
          header="Date Observed"
          value={data.dateObserved}
          errorText={errors.dateObserved}
          onDateSelected={(date) =>
            onChangeHandler('dateObserved', date ? moment(date).format('YYYY-MM-DD') : null)
          }
          onBlur={(date) =>
            onChangeHandler('dateObserved', date ? moment(date).format('YYYY-MM-DD') : date)
          }
          required
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, lastPatientVital),
        submitButtonText: 'Save and Close',
        renderSaveButton: true,
      })}
    </BasePopup>
  );
};

VitalsPopup.propTypes = {
  patientId: PropTypes.number,
  onClose: PropTypes.func,
};

export default VitalsPopup;
