import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { Autocomplete, DatePickerNew, Input, Select } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';
import {
  PayorType,
  PayorTypeOptions,
  PlanSource,
  PlanStatus,
  validatePatientCoverage,
} from 'common-src/models/PatientCoverage';

import { InfoItem } from 'src/components/elements';

import styles from '../commonStyles.module.scss';

const MedicareAdvantage = ({
  data,
  handleInputChange,
  handleMultipleValuesChange,
  handleDateChange,
  isChangeStatusAllowed,
  errors,
  setErrors,
  shouldReset,
}) => (
  <>
    <Select
      id="insurance-type"
      label="Please select insurance type"
      options={PayorTypeOptions}
      onChange={(op) => handleInputChange('payorType', op.value)}
      value={Object.values(PayorType).find((op) => op.value === data.payorType)}
      required
      placeholder="-"
    />
    <span />
    <span />
    <Autocomplete
      id="search-payor"
      classNames={[styles.autocomplete, 'm-b-16']}
      label="Payor Search"
      shouldReset={shouldReset}
      initialOptionsCount={50}
      renderOption={(option) => (
        <span className={[styles.option, 'font-w-500', 'font-s-14'].join(' ')}>
          {`${option.id} (${option.payorName})`}
        </span>
      )}
      onOptionSelect={(option) =>
        handleMultipleValuesChange({ payorName: option.payorName, payorId: option.id })
      }
      getRequest={(searchText, count) =>
        apiRequest({
          endpoint: 'payorDetails',
          queryParams: { input: searchText, limit: count },
        })
      }
      paperStyles={{ left: 0, width: 'max-content' }}
    />
    <span />
    <span />
    <InfoItem
      classNames={[styles.width75]}
      title="Payor Name"
      content={data.payorName}
      textId="payor-name"
    />
    <InfoItem title="Payor ID*" content={data.payorId} textId="payor-id" />
    <Input
      id="member-id"
      placeholder="Enter member id"
      label="Member ID"
      value={data.memberId}
      onTextChange={(value) => handleInputChange('memberId', value)}
      errorText={errors.memberId}
    />
    <Select
      id="status"
      label="Status"
      options={Object.values(PlanStatus)}
      onChange={(op) => {
        setErrors((prev) => ({
          ...prev,
          ...validatePatientCoverage('endDate', data.endDate, { ...data, status: op.value }),
        }));

        handleInputChange('status', op.value);
      }}
      value={Object.values(PlanStatus).find((op) => op.value === data.status)}
      disabled={!isChangeStatusAllowed}
      required
    />
    <InfoItem
      title="Source"
      content={Object.values(PlanSource).find((op) => op.value === data.source)?.label}
      textId="source"
    />
    <DatePickerNew
      id="start-date"
      header="Start date"
      placeholder="Select date"
      value={data.startDate}
      onDateSelected={(date) =>
        handleDateChange(
          'startDate',
          'endDate',
          true,
          date ? moment(date).format('YYYY-MM-DD') : null,
        )
      }
      onBlur={(date) =>
        setErrors((prev) => ({ ...prev, ...validatePatientCoverage('startDate', date, data) }))
      }
      errorText={errors.startDate}
      required
    />
    <DatePickerNew
      id="end-date"
      header="End date"
      placeholder="Select date"
      value={data.endDate}
      onDateSelected={(date) =>
        handleDateChange(
          'endDate',
          'startDate',
          false,
          date ? moment(date).format('YYYY-MM-DD') : null,
        )
      }
      onBlur={(date) =>
        setErrors((prev) => ({ ...prev, ...validatePatientCoverage('endDate', date, data) }))
      }
      filterDate={(date) => {
        const day = date.getDate();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        return day === lastDay; // Allow only the last day of each month
      }}
      errorText={errors.endDate}
      required={data.status === PlanStatus.Expired.value}
    />
    <span />
    <div className={['flex-row', 'gap-10'].join(' ')}>
      <Input
        id="contract-id"
        placeholder="Enter contract id"
        label="Contract ID"
        value={data.contractId}
        onTextChange={(value) => handleInputChange('contractId', value)}
      />
      <Input
        id="plan-id"
        placeholder="Enter plan id"
        label="Plan ID"
        value={data.planId}
        onTextChange={(value) => handleInputChange('planId', value)}
      />
    </div>
    <Input
      id="plan-name"
      classNames={[styles.width75]}
      placeholder="Enter plan name"
      label="Plan Name"
      value={data.planName}
      onTextChange={(value) => handleInputChange('planName', value)}
    />
  </>
);

MedicareAdvantage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  shouldReset: PropTypes.bool,
  handleDateChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  isChangeStatusAllowed: PropTypes.bool,
  handleMultipleValuesChange: PropTypes.func,
};

export default MedicareAdvantage;
