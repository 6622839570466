import { attr, fk, Model } from 'redux-orm';

class PatientWellnessPlan extends Model {
  static modelName = 'PatientWellnessPlan';

  static fields = {
    id: attr(),
    generalNotes: attr(),
    valuesMotivators: attr(),
    strengthsSuccesses: attr(),
    barriersChallenges: attr(),
    supportAccountability: attr(),
    longTermGoals: attr(),
    shortTermGoals: attr(),
    acProgressOnGoals: attr(),
    rdProgressOnGoals: attr(),
    cdcesProgressOnGoals: attr(),
    providerInstructions: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'wellnessPlanChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'wellnessPlanModel',
    }),
  };
}

export default PatientWellnessPlan;
