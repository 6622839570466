import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getTooltipStyles } from './helpers';
import styles from './Tooltip.module.scss';

const Tooltip = ({ title, children, disableHoverListener = false, classNames = [] }) => {
  const [open, setOpen] = useState(false);
  const [positionStyles, setPositionStyles] = useState({});

  const tooltipRef = useRef(null);
  const wrapperRef = useRef(null);

  const onChangeActiveHandler = (value) => {
    if (disableHoverListener) return;
    setOpen(value);
  };

  useEffect(() => {
    if (!tooltipRef.current || !wrapperRef.current) return;

    setPositionStyles(getTooltipStyles(tooltipRef, wrapperRef));
  }, [tooltipRef.current, wrapperRef.current, open, window.innerHeight, window.innerWidth]);

  return (
    <div
      ref={wrapperRef}
      className={[styles.wrapper, 'relative', 'flex-row', ...classNames].join(' ')}
      onMouseEnter={() => onChangeActiveHandler(true)}
      onMouseLeave={() => onChangeActiveHandler(false)}
    >
      {children}
      {open && (title || Number.isInteger(title)) && (
        <div
          ref={tooltipRef}
          className={[styles.title, 'fixed', 'font-s-10', 'border-r-6', 'font-w-500'].join(' ')}
          style={positionStyles}
          onClick={(e) => e.stopPropagation()}
          role="presentation"
        >
          {title}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  title: PropTypes.any,
  disableHoverListener: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default Tooltip;
