import { HttpMethods, restRequest } from 'common-src/features/rest';

export const createPatientFulfillmentOrder = (body, params) =>
  restRequest({
    uri: 'patientFulfillmentOrders',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const getPatientFulfillmentOrdersRequest = (patientId, params) =>
  restRequest({
    uri: 'patientFulfillmentOrders',
    query: { patient_id: patientId },
    method: HttpMethods.Get,
    ...params,
  });

export const markAsCanceled = (id, params) =>
  restRequest({
    uri: `patientFulfillmentOrders/${id}/markAsCanceled`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });

export const markAsFailed = (id, params) =>
  restRequest({
    uri: `patientFulfillmentOrders/${id}/markAsFailed`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
