import { LOGOUT } from 'common-src/features/auth';
import orm from 'common-src/orm';
import { createReducer } from 'common-src/utils/reducerUtils';

import { CLEAR, SET_ORM } from './actionTypes';

const initialState = orm.getEmptyState();

const setOrm = (state, payload) => {
  const { orm } = payload;

  return {
    ...orm,
  };
};

const entityHandlers = {
  [SET_ORM]: setOrm,
  [LOGOUT]: () => initialState,
  [CLEAR]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
