// Ripped from the Dan Abramov himself: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import { useEffect, useRef } from 'react';

import Config from 'common-src/config';

function useInterval(callback, delayMs = Config.POLLING_FREQUENCY) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delayMs != null) {
      const id = setInterval(tick, delayMs);
      return () => clearInterval(id);
    }
  }, [delayMs]);
}

export default useInterval;
