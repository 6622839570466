// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmbeddedInfo-module__wrapper___cM2tI {
  margin: 0 30px 30px 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DocumentationIndex/components/EmbeddedInfo/EmbeddedInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EmbeddedInfo-module__wrapper___cM2tI`
};
module.exports = ___CSS_LOADER_EXPORT___;
