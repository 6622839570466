export const TableHeaders = [
  { column: 'clientName', label: 'Client Name', width: '20%', sortBy: 'text' },
  { column: 'patientName', label: 'Patient Name', width: '20%', sortBy: 'text' },
  { column: 'initiationMethod', label: 'Type', width: '10%', sortBy: 'text' },
  { column: 'disposition', label: 'Disposition', width: '10%', sortBy: 'text' },
  { column: 'agentInteractionDuration', label: 'Duration', width: '10%', sortBy: 'text' },
  {
    column: 'afterContactWorkDuration',
    label: 'After Work Duration',
    width: '10%',
    sortBy: 'text',
  },
  { column: 'isLinked', label: 'Is Linked?', width: '10%', sortBy: 'text' },
  { column: 'createdAt', label: 'Created At', width: '10%', sortBy: 'value' },
];
