export const ExemptionStatus = Object.freeze({
  Active: { value: 1, label: 'Active', color: '#637768' },
  Expired: { value: 2, label: 'Expired', color: '#F06C67' },
});

export const ExemptionType = Object.freeze({
  MFA: { value: 1, label: 'MFA Decision', longLabel: 'MFA Decision', isSelectable: false },
  QMB: {
    value: 2,
    label: 'QMB',
    longLabel: 'Qualified Medicare Beneficiary (QMB)',
    isSelectable: true,
  },
});

export const ExemptionSource = Object.freeze({
  System: { value: 1, label: 'System' },
  UserInput: { value: 2, label: 'User Input' },
});
