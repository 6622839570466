import { cleanedText } from 'common-src/presenters';

export const validatePatientPreferredPharmacy = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'name':
      if (!value) {
        errMsg = 'Name should not be empty';
      }
      break;
    case 'phone':
      if (value) {
        errMsg = cleanedText(value).length !== 10 ? 'Phone number is not valid' : '';
      }
      break;
    case 'address.street1':
      if (value.length > 35) {
        errMsg = 'Address1 should be a maximum of 35 characters';
      }
      break;
    case 'address.street2':
      if (value.length > 35) {
        errMsg = 'Address2 should be a maximum of 35 characters';
      }
      break;
    case 'address.city':
      if (value.length > 35) {
        errMsg = 'City should be a maximum of 35 characters';
      }
      break;
    case 'address.state':
      if (value.length > 2) {
        errMsg = 'State should be a maximum of 2 characters';
      }
      break;
    case 'address.zipcode':
      if (value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
        errMsg = 'Invalid zip code';
      } else if (value.length > 9) {
        errMsg = 'Zip code should be a maximum of 9 characters';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
