import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import Patient from 'common-src/models/Patient';
import PatientInteraction, { getReason, getType } from 'common-src/models/PatientInteraction';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import useTime from 'src/hooks/useTime';

import styles from './NoteHeader.module.scss';

const NoteHeader = ({ documentation, patient, serviceAt, setServiceAt, classNames = [] }) => {
  const dispatch = useDispatch();

  const { type, reason, client, createdAt } = documentation;
  const { timeZone } = patient;
  const isAddendum = !!documentation?.parentInteractionId;

  const { time } = timeZone ? useTime(0, 'MM/DD/YYYY [at] h:mm a z', timeZone, createdAt) : {};

  const openDateOfServicePopup = () =>
    dispatch(
      openModal(ModalType.DATE_OF_SERVICE, {
        serviceAt,
        setServiceAt,
      }),
    );

  return (
    <div className={[styles.header, ...classNames].join(' ')}>
      <div className={styles.textContainer}>
        <span id="documentation-type" className={styles.title}>
          {getType(type)}
        </span>
        <span id="documentation-reason" className={styles.subtitle}>
          {isAddendum ? 'Addendum' : getReason(reason)}
        </span>
        <span id="documentation-time-and-provider" className={styles.timeProvider}>
          {`Created on ${time} with ${client?.getName() || '-'}`}
        </span>
      </div>
      <img
        id="date-of-service-btn"
        src={CommonIcons.calendar}
        alt="calendar-icon"
        role="presentation"
        style={{ width: '28px', height: '28px', cursor: 'pointer' }}
        onClick={openDateOfServicePopup}
      />
    </div>
  );
};

NoteHeader.propTypes = {
  documentation: PropTypes.exact(PatientInteraction.schema),
  patient: PropTypes.exact(Patient.schema),
  serviceAt: PropTypes.string,
  setServiceAt: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default NoteHeader;
