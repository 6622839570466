export const DxStatusesConfig = [
  {
    id: 1,
    label: 'Chronic',
    textColor: '#FFFFFF',
    backgroundColor: '#AEC082',
    isDeprecated: false,
  },
  {
    id: 2,
    label: 'Temporary',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isDeprecated: false,
  },
  {
    id: 3,
    label: 'Permanent',
    textColor: '#FFFFFF',
    backgroundColor: '#F06C67',
    isDeprecated: false,
  },
  {
    id: 4,
    label: 'Acute',
    textColor: '#FFFFFF',
    backgroundColor: '#9D97C7',
    isDeprecated: false,
  },
  {
    id: 5,
    label: 'Inactive',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isDeprecated: false,
  },
  {
    id: 6,
    label: 'Resolved',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isDeprecated: false,
  },
  {
    id: 7,
    label: 'Active',
    textColor: '#FFFFFF',
    backgroundColor: '#AEC082',
    isDeprecated: true,
  },
];

export const activeStatusesIds = [
  DxStatusesConfig[0].id,
  DxStatusesConfig[1].id,
  DxStatusesConfig[2].id,
  DxStatusesConfig[3].id,
  DxStatusesConfig[6].id,
];

export const Relation = {
  Self: {
    id: 1,
    label: 'Self',
  },
  Father: {
    id: 2,
    label: 'Father',
  },
  Mother: {
    id: 3,
    label: 'Mother',
  },
  Sibling: {
    id: 4,
    label: 'Sibling',
  },
  Grandparent: {
    id: 5,
    label: 'Grandparent',
  },
  Other: {
    id: 6,
    label: 'Other',
  },
};

export const DiagnosisOptions = [
  {
    value: 'E10.65',
    label: 'Type 1 diabetes mellitus with hyperglycemia',
    shortDescription: 'Type 1',
  },
  {
    value: 'E10.9',
    label: 'Type 1 diabetes mellitus without complications',
    shortDescription: 'Type 1',
  },
  {
    value: 'E11.65',
    label: 'Type 2 diabetes mellitus with hyperglycemia',
    shortDescription: 'Type 2',
  },
  {
    value: 'E11.8',
    label: 'Type 2 diabetes mellitus with unspecified complications',
    shortDescription: 'Type 2',
  },
  {
    value: 'E11.9',
    label: 'Type 2 diabetes mellitus without complications',
    shortDescription: 'Type 2',
  },
  {
    value: 'R73.03',
    label: 'Prediabetes',
    shortDescription: 'Prediabetes',
  },
];

export const InsulinUseOptions = [{ value: 'Z79.4', label: 'Z79.4 Insulin Use' }];
