import { cleanedText } from 'common-src/presenters';

export const validatePatientPhoneNumber = (field, value, existingPhones = []) => {
  let errMsg = '';
  switch (field) {
    case 'phoneNumber':
      if (!value) {
        errMsg = 'Phone number should not be empty';
      } else if (cleanedText(value).length !== 10) {
        errMsg = 'Phone number is not valid';
      } else if (existingPhones.find((phone) => phone.phoneNumber === value)) {
        errMsg = 'Phone number already exists';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
