import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import PatientCgmOrder, {
  CGMOrderStatus,
  getCgmOrderStatusConfig,
  getProductNameById,
  getSupplierNameById,
} from 'common-src/models/PatientCgmOrder';
import { ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';

import styles from './Item.module.scss';

const Item = ({ actions, order = {} }) => {
  const dispatch = useDispatch();

  const renderProductInfo = () => (
    <div className={[styles.textContainer, 'flex-column'].join(' ')}>
      <span
        id="order-ordered-by"
        className={[styles.orderedBy, 'font-w-600', 'font-s-12'].join(' ')}
      >
        {order?.createdBy?.getName(false, true) || '-'}
      </span>
      <span id="order-product" className={[styles.product, 'font-w-700', 'font-s-16'].join(' ')}>
        {getProductNameById(order?.product)}
      </span>
      <span
        id="order-ordered-date"
        className={[styles.orderedAt, 'font-w-500', 'm-t-2', 'font-s-12'].join(' ')}
      >
        {`Date ordered: ${moment(order?.orderedAt).format('MM/DD/YYYY')}`}
      </span>
    </div>
  );

  const renderNotes = () => (
    <span
      id="order-notes"
      className={[styles.notes, 'flex-1', 'font-w-400', 'font-s-12'].join(' ')}
    >
      {order?.notes || ''}
    </span>
  );

  const renderDate = (labelId, valueId, label, date) => (
    <p className="flex-column">
      <span id={labelId} className={[styles.dateLabel, 'font-w-500', 'font-s-12'].join(' ')}>
        {label}
      </span>
      <span id={valueId} className={[styles.date, 'font-w-700', 'font-s-16'].join(' ')}>
        {date ? moment(date).format('MM/DD/YYYY') : '-'}
      </span>
    </p>
  );

  // TODO: render view order button in case of existing pdf file, add model field
  const renderViewOrderButton = () => (
    <button
      className={[styles.viewOrderButton, 'gap-8', 'vertically-centered'].join(' ')}
      type="button"
    >
      <img className={styles.icon} src={CommonIcons.docIcon} alt="doc-icon" />
      <span className={[styles.text, 'font-w-400', 'font-s-14'].join(' ')}>View Order</span>
    </button>
  );

  const renderStatus = () => (
    <div
      className={[
        styles.status,
        'vertically-centered',
        'font-w-500',
        'gap-6',
        'primary-border',
        'font-s-14',
        'm-l-24',
      ].join(' ')}
    >
      <img src={getCgmOrderStatusConfig(order?.status).iconSrc} alt="status-icon" />
      <span id="order-status">{getCgmOrderStatusConfig(order?.status).text}</span>
    </div>
  );

  const renderFirstRow = () => (
    <div
      className={[
        'flex-row',
        'flex-1',
        'gap-10',
        'primary-border-b',
        'p-b-12',
        'p-r-16',
        'p-l-20',
      ].join(' ')}
    >
      <img className={styles.icon} src={CommonIcons.shippingIcon} alt="shipping-icon" />
      {renderProductInfo()}
      {renderNotes()}
      <Button
        id={`${order?.id}-action-btn`}
        iconSrc={CommonIcons.rightArrowIcon}
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={() =>
          dispatch(
            openModal(ModalType.MANUAL_CGM_ORDER, {
              id: order?.id,
              patientId: order?.patient?.id,
            }),
          )
        }
        disabled={!actions?.addCGMOrder}
      />
    </div>
  );

  const renderSecondRow = () => (
    <div
      className={[
        'flex-row',
        'flex-1',
        'gap-10',
        'p-b-12',
        'p-t-12',
        'p-r-16',
        'p-l-20',
        'vertically-centered',
      ].join(' ')}
    >
      <img className={styles.icon} src={CommonIcons.folderIcon} alt="folder-icon" />
      <p className={[styles.supplierContainer, 'flex-column'].join(' ')}>
        <span
          id="order-supplier-label"
          className={[styles.supplierLabel, 'font-w-500', 'font-s-12'].join(' ')}
        >
          Supplier
        </span>
        <span
          id="order-supplier"
          className={[styles.supplier, 'font-w-700', 'font-s-18'].join(' ')}
        >
          {getSupplierNameById(order?.supplier)}
        </span>
      </p>
      <div className={['flex-row', 'flex-1', 'gap-30'].join(' ')}>
        {renderDate('order-date-sent-label', 'order-date-sent', 'Date Sent', order?.sentAt)}
        {renderDate(
          'order-date-shipped-label',
          'order-date-shipped',
          'Date Shipped',
          order?.shippedAt,
        )}
        <div className="flex-row flex-1" />
      </div>
      {actions?.viewCGMOrder && order?.pdfUrl && renderViewOrderButton()}
      {renderStatus()}
    </div>
  );

  return (
    <div
      style={{
        backgroundColor:
          order?.status === CGMOrderStatus.Delivered.value
            ? ColorsNew.ultraLightGreen
            : ColorsNew.ultraLightGray,
      }}
      className={[styles.container, 'gap-10', 'primary-border'].join(' ')}
    >
      {renderFirstRow()}
      {renderSecondRow()}
    </div>
  );
};

Item.propTypes = {
  order: PropTypes.exact(PatientCgmOrder.schema),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Item;
