/* eslint-disable no-case-declarations */
import _ from 'lodash';
import moment from 'moment-timezone';

import { CGMAuthStatus, CGMManufacturers, CGMProducts, CGMSuppliers } from './constants';

export const getCGMAuthStatus = (patient, latestReading) => {
  if (!patient)
    return { cgmStatus: CGMAuthStatus.NotApplicable, deviceStatus: 'No device connected' };

  const isAuthenticated = () => {
    switch (true) {
      case !patient.isCGM || !patient.cgmType:
        return false;
      case patient.cgmType === CGMManufacturers.Dexcom.value:
        return !!patient?.dexcomLastAuthAt;
      case patient.cgmType === CGMManufacturers.FreestyleLibre.value:
        return !!patient?.terraLastAuthAt;
      default:
        return false;
    }
  };

  switch (true) {
    case isAuthenticated() === true:
      if (_.isEmpty(latestReading) || !latestReading?.recordedAt)
        return { cgmStatus: CGMAuthStatus.Authenticated, deviceStatus: 'No data' };

      const deviceStatus = `Last Received ${moment(latestReading.recordedAt).format('MM/DD/YYYY')}`;
      return moment().diff(latestReading.recordedAt, 'hours') <= 72
        ? { cgmStatus: CGMAuthStatus.Transmitting, deviceStatus }
        : { cgmStatus: CGMAuthStatus.NotTransmitting, deviceStatus };
    case patient.isCGM === true && isAuthenticated() === false:
      return {
        cgmStatus: CGMAuthStatus.Unauthenticated,
        deviceStatus: CGMAuthStatus.Unauthenticated,
      };
    default:
      return { cgmStatus: CGMAuthStatus.NotApplicable, deviceStatus: 'No device connected' };
  }
};

export const getProductNameById = (id) =>
  Object.values(CGMProducts).find((product) => product.value === id)?.label || '-';

export const getSupplierNameById = (id) =>
  Object.values(CGMSuppliers).find((supplier) => supplier.value === id)?.label || '-';
