import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox } from 'common-src/components/base';

import AwscCallItem from './AwscCallItem';
import styles from './Conversations.module.scss';
import RegalInfoItem from './RegalInfoItem';

const Conversations = ({
  hasRelevantConversations,
  setHasRelevantConversations,
  regalTaskIds,
  setRegalTaskIds,
  regalTaskInfos,
  awscCallIds,
  setAwscCallIds,
  awscCalls,
  clients,
}) => (
  <>
    <p className={[styles.header, 'font-w-500', 'font-s-14'].join(' ')}>
      Please link this note to any recent conversations. Include all tasks, including reminders,
      that were associated with this note.
    </p>
    {regalTaskInfos.map((rti) => (
      <RegalInfoItem
        key={rti.id}
        info={rti}
        regalTaskIds={regalTaskIds}
        setRegalTaskIds={setRegalTaskIds}
        relatedClients={clients}
      />
    ))}
    {awscCalls.map((call) => (
      <AwscCallItem
        key={call.id}
        call={call}
        awscCallIds={awscCallIds}
        setAwscCallIds={setAwscCallIds}
        relatedClients={clients}
      />
    ))}
    <Checkbox
      id="relevant-conversations"
      classNames={[styles.checkbox, 'm-t-16']}
      label="I don't see relevant conversations"
      checked={hasRelevantConversations}
      onChange={setHasRelevantConversations}
    />
  </>
);

Conversations.propTypes = {
  hasRelevantConversations: PropTypes.bool,
  setHasRelevantConversations: PropTypes.func,
  regalTaskIds: PropTypes.array,
  setRegalTaskIds: PropTypes.func,
  regalTaskInfos: PropTypes.arrayOf(PropTypes.object),
  awscCallIds: PropTypes.array,
  setAwscCallIds: PropTypes.func,
  awscCalls: PropTypes.arrayOf(PropTypes.object),
  clients: PropTypes.arrayOf(PropTypes.object),
};

export default Conversations;
