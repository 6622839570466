import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { RadioGroup } from 'common-src/components/base';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './NoteConfirmationPopup.module.scss';

const RADIO_GROUP_OPTIONS = ['Had live interaction', 'Did not have live interaction'];

const NoteConfirmationPopup = ({ onClose, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const isSubmitClicked = useRef(false);

  const { renderButtons } = usePopup();

  const onSubmitHandler = () => {
    if (isSubmitClicked.current) return;

    isSubmitClicked.current = true;
    onSubmit(selectedOption === RADIO_GROUP_OPTIONS[0]);
    onClose();
  };

  return (
    <BasePopup
      id="note-confirmation"
      customStyle={{ minWidth: '0px', width: '330px' }}
      open
      onClose={onClose}
      title="Confirmation"
    >
      <div className={styles.container}>
        <RadioGroup
          id="confirm-radio-group"
          options={RADIO_GROUP_OPTIONS}
          value={selectedOption}
          onChange={(value) => setSelectedOption(value)}
          classNames={[styles.radioItem]}
          applyBackgroundColor
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: selectedOption !== null,
        submitButtonText: 'Submit',
      })}
    </BasePopup>
  );
};

NoteConfirmationPopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NoteConfirmationPopup;
