import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { getModuleNames, InteractionType } from 'common-src/models/PatientInteraction';
import { formatDuration, getDateWithTimeZone } from 'common-src/utils/dateUtils';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import BaseTable from 'src/components/table/BaseTable';
import useCustomSelector from 'src/hooks/useCustomSelector';

import { ActionsRow } from '../../items';
import styles from './DraftsTab.module.scss';

const headers = [
  { column: 'noteType', label: 'Note Type', width: '15%', sortBy: 'text' },
  { column: 'reasons', label: 'Document Type', width: '25%' },
  { column: 'createdBy', label: 'Created By', width: '10%' },
  { column: 'createdAt', label: 'Created Date / Time', width: '20%', sortBy: 'text' },
  { column: 'duration', label: 'Duration', width: '10%' },
  { column: 'actions', label: 'Actions', width: '20%' },
];

const DraftsTab = ({ patientId, drafts }) => {
  const dispatch = useDispatch();

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));

  const getData = () => {
    if (_.isEmpty(drafts)) return [];

    return drafts.map((draft) => ({
      noteType:
        Object.values(InteractionType).find((type) => type.id === draft?.type)?.label || 'N/A',
      reasons: getModuleNames(draft),
      createdBy: draft?.client?.getName(false, true),
      createdAt: getDateWithTimeZone(
        draft.createdAt,
        'MM/DD/YYYY [at] h:mm a z',
        patient?.timeZone,
      ),
      duration: formatDuration(draft?.duration),
      actions: <ActionsRow draft={draft} dispatch={dispatch} />,
    }));
  };

  return (
    <BaseTable
      classNames={[styles.table]}
      emptyClassNames={[styles.empty]}
      headers={headers}
      data={getData()}
      name="documentation-drafts"
      emptyText="There are no drafts"
    />
  );
};

DraftsTab.propTypes = {
  patientId: PropTypes.number,
  drafts: PropTypes.array,
};

export default DraftsTab;
