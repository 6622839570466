import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import Icons from 'src/assets/Icons';

import styles from './DateItem.module.scss';

const DateItem = ({ date }) => (
  <div id={`date-${date}`} className={styles.item}>
    <img className={styles.icon} src={Icons.labsCircleIcon} alt="labs-circle-icon" />
    <p className={[styles.date, 'font-w-500', 'font-s-18'].join(' ')}>
      {moment(date).format('MM/DD/YYYY')}
    </p>
  </div>
);

DateItem.propTypes = {
  date: PropTypes.string,
};

export default DateItem;
