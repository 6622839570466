import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import { RiskLevelUnset } from 'common-src/models/Patient/constants';
import {
  platformStatusColor,
  platformStatusPresenter,
  riskLevelColor,
  riskLevelPresenter,
} from 'common-src/models/Patient/presenters';
import {
  getFormattedPhoneNumber,
  getPrimaryPatientPhoneNumber,
} from 'common-src/models/PatientPhoneNumber';
import { ColorsNew } from 'common-src/styles';
import { getAge } from 'common-src/utils/dateUtils';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { NavItem } from 'src/components/navigation';
import { updateContactToDial } from 'src/features/awsConnect';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import {
  PatientActionsButton,
  PrescriptionProviderMenu,
  ScheduleVisitMenu,
  StatusItem,
} from './items';
import styles from './PatientDetailsNavigation.module.scss';

const PatientDetailsNavigation = ({ patientId }) => {
  const dispatch = useDispatch();

  const patient = useCustomSelector((state) =>
    singleModelSelector(state, 'Patient', Number(patientId)),
  );
  const primaryPatientPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patientId),
  );
  const permissions = useCustomSelector(getRoleConfig)?.patientChart?.actions;

  return (
    <header className={[styles.header, 'flex-row', 'vertically-centered'].join(' ')}>
      <div className="flex-row">
        <NavItem title="DOB" value={moment(patient.dob).format('MM/DD/YYYY')} />
        <NavItem title="Age" value={getAge(patient.dob)} />
        <NavItem title="Sex" value={patient.sex} withSeparator />
        <NavItem
          title="Location"
          value={patient.address?.state}
          timeZone={patient.timeZone}
          withSeparator
        />
        <NavItem
          title="Primary Phone"
          value={getFormattedPhoneNumber(primaryPatientPhoneNumber?.cleanedPhoneNumber)}
          withSeparator
          iconSrc={Icons.callIcon}
          onIconClick={() =>
            dispatch(
              updateContactToDial({
                phoneToDial: primaryPatientPhoneNumber?.phoneNumber,
                patientId,
              }),
            )
          }
        />
        <NavItem title="Language" value={patient.preferredLanguage} withSeparator />
        <NavItem
          title="Status"
          value={
            <StatusItem
              value={platformStatusPresenter(patient.platformStatus)}
              backgroundColor={platformStatusColor(patient.platformStatus)}
            />
          }
        />
        <NavItem
          title="Risk Level"
          value={
            <StatusItem
              value={riskLevelPresenter(patient?.riskLevel || RiskLevelUnset)}
              backgroundColor={riskLevelColor(patient?.riskLevel || RiskLevelUnset)}
            />
          }
        />
        <NavItem
          title="Lead Source"
          value={
            <StatusItem value={patient.leadSource || '-'} backgroundColor={ColorsNew.mediumGreen} />
          }
        />
      </div>
      <div className={['flex-row', 'vertically-centered', 'gap-50'].join(' ')}>
        <div className={['flex-row', 'gap-6'].join(' ')}>
          {permissions?.canAddPrescription && (
            <PrescriptionProviderMenu patient={patient} tooltipText="Prescribe Rx" />
          )}
          {permissions?.canAddMedication && (
            <Button
              id="new-medication-button"
              classNames={[styles.actionButton]}
              iconSrc={Icons.pillIcon}
              onClick={() => dispatch(openModal(ModalType.MEDICATION, { patientId: patient.id }))}
              backgroundColor="transparent"
              tooltipText="Add medication"
            />
          )}
          {permissions?.canAddLabResult && (
            <Button
              id="new-vitals-button"
              classNames={[styles.actionButton]}
              iconSrc={Icons.vitalsTabIcon}
              onClick={() => dispatch(openModal(ModalType.VITALS, { patientId: patient.id }))}
              backgroundColor="transparent"
              tooltipText="Add vitals"
            />
          )}
          <ScheduleVisitMenu patient={patient} tooltipText="Schedule" />
          {permissions?.canAddSmartGoal && (
            <Button
              id="new-smart-goal-button"
              classNames={[styles.actionButton]}
              iconSrc={Icons.newSmartIcon}
              onClick={() => dispatch(openModal(ModalType.SMART_GOAL, { patientId: patient.id }))}
              backgroundColor="transparent"
              tooltipText="Add goal"
            />
          )}
          {permissions?.canAddLabResult && (
            <Button
              id="new-lab-result-button"
              classNames={[styles.actionButton]}
              iconSrc={Icons.newLabsIcon}
              onClick={() =>
                dispatch(
                  openModal(ModalType.LAB, {
                    patientId: patient.id,
                  }),
                )
              }
              backgroundColor="transparent"
              tooltipText="Add lab result"
            />
          )}
        </div>
        <PatientActionsButton patient={patient} />
      </div>
    </header>
  );
};

PatientDetailsNavigation.propTypes = {
  patientId: PropTypes.number,
};

export default PatientDetailsNavigation;
