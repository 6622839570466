import _ from 'lodash';

import { updatePatient, validatePatient } from 'common-src/models/Patient';
import {
  getPhoneNumbersRestRequests,
  validatePatientPhoneNumber,
} from 'common-src/models/PatientPhoneNumber';

export const extractPhoneNumber = (phone) => ({
  id: phone.id,
  isPrimary: phone.isPrimary,
  phoneNumber: phone.phoneNumber.substring(2),
  type: phone.type,
});

export const getInitialPhoneData = (patientId) => ({
  patientId,
  phoneNumber: '',
  type: 'Unknown',
  isPrimary: false,
});

export const resetPhoneNumberErrors = (careProviders, careProvider) => {
  const index = _.indexOf(careProviders, careProvider);
  return {
    [`phones.${index}.phoneNumber`]: '',
    [`phones.${index}.type`]: '',
    [`phones.${index}.isPrimary`]: '',
  };
};

export const getErrorMessage = (model, field, newValue, phones) => {
  switch (model) {
    case 'patient': {
      const err = validatePatient(field, newValue);
      return err[field];
    }
    case 'phones': {
      const validationField = field.split('.')[1];
      const err = validatePatientPhoneNumber(validationField, newValue, phones);
      return err[validationField];
    }
    default:
      return '';
  }
};

export const catchErrors = (formData) => {
  const errors = {};
  formData.phones.forEach((phone, i) => {
    if (!phone.patientId) return;
    Object.entries(phone).forEach(([field, value]) => {
      const err = validatePatientPhoneNumber(field, value);
      if (err[field]) {
        Object.assign(errors, { [`phones.${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequests = (formData, phones, patient) => {
  const requests = [];
  const deleteRequests = [];

  const newPhones = formData.phones;
  const oldPhones = phones.map((phone) => extractPhoneNumber(phone));
  if (!_.isEqual(oldPhones, newPhones)) {
    const { ops, deleteOps } = getPhoneNumbersRestRequests(
      patient.id,
      phones.map((phone) => extractPhoneNumber(phone)),
      formData.phones,
    );

    requests.push(...ops);
    deleteRequests.push(...deleteOps);
  }

  if (formData.patient?.email !== patient.email) {
    requests.push(updatePatient(patient.id, { ...formData.patient }));
  }

  return { requests, deleteRequests };
};
