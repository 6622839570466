import PropTypes from 'prop-types';
import React from 'react';

import { platformStatusPresenter } from 'common-src/models/Patient/presenters';

import styles from './AutoCompleteOption.module.scss';

const AutoCompleteOption = ({ patient }) => {
  const { id, dob, platformStatus, firstName, lastName, preferredName } = patient;

  const getPatientName = () =>
    preferredName ? `${preferredName} ${lastName}` : `${firstName} ${lastName}`;

  return (
    <div className="vertically-centered">
      <p className={[styles.name, 'font-w-500', 'font-s-14'].join(' ')}>{getPatientName()}</p>
      <p className={[styles.info, 'font-s-10'].join(' ')}>
        <span>MRN {id},</span>
        <span>DOB {dob},</span>
        <span>{platformStatusPresenter(platformStatus)}</span>
      </p>
    </div>
  );
};

AutoCompleteOption.propTypes = {
  patient: PropTypes.shape({
    dob: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    platformStatus: PropTypes.number,
    preferredName: PropTypes.string,
  }),
};

export default AutoCompleteOption;
