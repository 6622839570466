import PropTypes from 'prop-types';
import React from 'react';

import { ColorsNew } from 'common-src/styles';

import Button from '../Button';
import Radio from '../Radio';
import styles from './SingleChoice.module.scss';

const choices = [
  { label: 'YES', value: true },
  { label: 'NO', value: false },
];

const SingleChoice = ({
  value,
  label,
  onClear,
  onChange,
  classNames = [],
  disabled = false,
  radioClassnames = [],
}) => (
  <div
    id={`choice-${label}`}
    className={[styles.container, 'vertically-centered', ...classNames].join(' ')}
  >
    <p
      className={[styles.label, 'font-w-500', 'font-s-14', disabled ? styles.disabled : ''].join(
        ' ',
      )}
    >
      {label}
    </p>
    <div className={[styles.choices, 'gap-20'].join(' ')}>
      {choices.map((choice) => (
        <Radio
          key={choice.label}
          id={`${label}-${choice.label}`}
          classNames={radioClassnames}
          checked={value === choice.value}
          label={choice.label}
          disabled={disabled}
          onChange={() => onChange(choice.value)}
          vertical
        />
      ))}
      <Button
        id={`${label}-clear-btn`}
        classNames={[styles.button, 'font-s-14']}
        text="clear input"
        disabled={value === null || disabled}
        backgroundColor="transparent"
        borderColor="transparent"
        textColor={ColorsNew.mediumDarkGreen}
        onClick={onClear}
      />
    </div>
  </div>
);

SingleChoice.propTypes = {
  label: PropTypes.string,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOf([true, false, null]),
  radioClassnames: PropTypes.arrayOf(PropTypes.string),
};

export default SingleChoice;
