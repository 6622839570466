import { useState } from 'react';

import useAlert from 'src/hooks/useAlert';

const useDocumentationErrors = () => {
  const [errors, setErrors] = useState(new Map());

  const { showAlert, AlertType } = useAlert();

  const handleError = ({ generalError, fieldErrors }) => {
    if (generalError) {
      showAlert(AlertType.Error, '', generalError);
    }
    setErrors(fieldErrors);
  };

  return { handleError, errors };
};

export default useDocumentationErrors;
