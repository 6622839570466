import moment from 'moment-timezone';

export const validatePatientAllergy = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'name':
      if (!value) {
        errMsg = 'Name should not be empty';
      }
      break;
    case 'startDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (value && moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        errMsg = 'Date should not be in the future';
      }
      break;
    case 'endDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      }
      break;
    case 'type':
      if (!value) {
        errMsg = 'Type should not be empty';
      }
      break;
    case 'reaction':
      if (!value) {
        errMsg = 'Reaction should not be empty';
      }
      break;
    case 'severity':
      if (!value) {
        errMsg = 'Severity should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
