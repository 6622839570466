import moment from 'moment-timezone';

import {
  getLabReadingCategory,
  getLabResultsByCategoryAndDate,
} from 'common-src/models/PatientLabReading';
import { LabReadingTypes } from 'common-src/models/PatientLabReading/constants';

export const getDeleteMessage = (item) =>
  `Are you sure you want to delete the ${
    LabReadingTypes[item?.typeId]?.label || 'Unknown'
  } value of ${item?.readingValue} from ${moment(item?.readingDate).format('MM/DD/YYYY')}?`;

export const getStatusChangeIds = (item, allLabResults) => {
  if (!item) return [];

  if (item?.patientReported) return [item.id];

  const labResultsByCategoryAndDate = getLabResultsByCategoryAndDate(
    allLabResults,
    getLabReadingCategory(item?.typeId),
    item?.readingDate,
  );

  return labResultsByCategoryAndDate.map((result) => result.id);
};
