import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientMedicalProblemsRequest = (query, params) =>
  restRequest({
    uri: 'patientMedicalProblems',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addMedicalProblem = (body, params) =>
  restRequest({
    uri: 'patientMedicalProblems',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const editMedicalProblem = (id, body, params) =>
  restRequest({
    uri: `patientMedicalProblems/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const updateMedicalProblemStatus = (id, status, problem, params) =>
  restRequest({
    uri: `patientMedicalProblems/${id}`,
    method: HttpMethods.Put,
    body: {
      id,
      patientId: problem?.patient?.id,
      icd10Code: problem.icd10Code,
      comments: problem.comments,
      dxDate: problem.dxDate,
      dxStatus: status,
      resolvedDate: problem.resolvedDate,
      problemType: problem.problemType,
      patientRelationship: problem.patientRelationship,
    },
    ...params,
  });

export const makeMedicalProblemInactive = (id, params) =>
  restRequest({
    uri: `patientMedicalProblems/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
