// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddClientPopup-module__grid___NOwkj {
  display: grid;
  padding: 25px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(2, 1fr);
}
.AddClientPopup-module__grid___NOwkj .AddClientPopup-module__width100___DTd1f {
  grid-column: 2 span;
}

.AddClientPopup-module__buttonsContainer___Y3EXe {
  gap: 16px;
  padding: 30px;
  display: flex;
  justify-content: end;
  border-top: 1px solid rgba(91, 91, 91, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/popups/AddClientPopup/AddClientPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,SAAA;EACA,aAAA;EACA,aAAA;EACA,oBAAA;EACA,2CAAA;AAAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `AddClientPopup-module__grid___NOwkj`,
	"width100": `AddClientPopup-module__width100___DTd1f`,
	"buttonsContainer": `AddClientPopup-module__buttonsContainer___Y3EXe`
};
module.exports = ___CSS_LOADER_EXPORT___;
