import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientInteractionsApiRequest = (query, params) =>
  restRequest({
    uri: 'patientInteractions',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const getPatientInteractionApiRequest = (id, params) =>
  restRequest({
    uri: `patientInteractions/${id}`,
    method: HttpMethods.Get,
    ...params,
  });

export const getPatientInteractionAddendumsRequest = (parentInteractionId, params) =>
  restRequest({
    uri: 'patientInteractions',
    query: {
      parent_interaction_id: parentInteractionId,
      isDraft: false,
    },
    method: HttpMethods.Get,
    ...params,
  });

export const getLatestPatientInteractionRequest = (patientId, params) =>
  restRequest({
    uri: 'patientInteractions',
    query: {
      patientId,
      isDraft: false,
      skip: 0,
      limit: 1,
      sortOrder: 'desc',
    },
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientInteractionApiRequest = (params) =>
  restRequest({ uri: 'patientInteractions', method: HttpMethods.Post, ...params });

export const updatePatientDraftApiRequest = (id, body, params) =>
  restRequest({
    uri: `patientInteractions/update/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const finalizePatientInteraction = (id, body, params) =>
  restRequest({
    uri: `patientInteractions/finalize/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const adjustPatientInteraction = (id, body, params) =>
  restRequest({
    uri: `patientInteractions/adjust/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const deleteDraftApiRequest = (id, params) =>
  restRequest({
    uri: `patientInteractions/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
