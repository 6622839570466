import { getSourceById, Sources } from 'common-src/models/PatientMedication';

export const initialState = {
  med: undefined,
  status: null,
  route: null,
  frequency: null,
  source: getSourceById(Sources[0].value),
  instructions: '',
  notes: '',
  prescriber: null,
};
