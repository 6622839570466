import PropTypes from 'prop-types';
import React from 'react';

const CircleProgressBar = ({ size, percent, fillColor = 'green', showText = false }) => {
  const strokeWidth = 10;
  const radius = (size - strokeWidth * 2) / 2;
  const viewBoxSize = radius * 2 + strokeWidth * 2;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference * (percent / 100);

  return (
    <div style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          fill="transparent"
          stroke="rgba(91, 91, 91, 0.2)"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          fill="transparent"
          stroke={fillColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          transform={`rotate(-90 ${radius + strokeWidth} ${radius + strokeWidth})`}
        />
        {showText && (
          <text x={radius + strokeWidth} y={radius + strokeWidth + 5} textAnchor="middle">
            {`${percent}%`}
          </text>
        )}
      </svg>
    </div>
  );
};

CircleProgressBar.propTypes = {
  size: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  fillColor: PropTypes.string,
  showText: PropTypes.bool,
};

export default CircleProgressBar;
