/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { CustomCheckbox } from 'common-src/components/base';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './MaxTabLimitRow.module.scss';

const MaxTabLimitRow = ({ title, isSelected, onSelect }) => {
  const patientId = title?.replace(/^\D+/g, '');

  const patient = useCustomSelector((state) =>
    patientId ? singleModelSelector(state, 'Patient', Number(patientId)) : null,
  );

  const patientName = useMemo(() => {
    if (_.isEmpty(patient)) return '';
    return patient.getName();
  }, [patient]);

  const getTitle = () => (_.isEmpty(patientName) ? title : `${patientName} Patient Details`);

  return (
    <CustomCheckbox
      key={title}
      classNames={[styles.checkbox]}
      labelClassNames={[styles.checkboxLabel, 'font-s-14']}
      header={getTitle()}
      checked={isSelected}
      onChange={(isChecked) => onSelect(title, isChecked)}
    />
  );
};

MaxTabLimitRow.propTypes = {
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default MaxTabLimitRow;
