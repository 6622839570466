import _ from 'lodash';

const getIsFieldRequired = (field) =>
  ['readingDate', 'typeId', 'patientReported', 'readingValue'].includes(field);

export const extractLabResult = (labResult) =>
  _.pick(labResult, ['typeId', 'readingDate', 'readingValue', 'patientReported']);

export const getIsButtonEnabled = (labResult, labResultToEdit, errors) => {
  if (
    _.isEmpty(labResult) ||
    Object.values(errors).some((e) => e) ||
    Object.entries(labResult).some(
      ([key, value]) => getIsFieldRequired(key) && [null, ''].includes(value),
    ) ||
    _.isEqual(labResult, extractLabResult(labResultToEdit))
  )
    return false;

  return true;
};
