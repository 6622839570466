import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Tooltip } from 'common-src/components/base';

import CGMIndicator from '../CGMIndicator';
import styles from './CGMInfoItem.module.scss';

const CGMInfoItem = ({ indicatorId, title, value, isCritical, tooltipMessage }) => (
  <div className="vertically-centered">
    <div className={[styles.wrapper, 'gap-5', 'vertically-centered'].join(' ')}>
      <p className={[styles.title, 'font-w-600', 'font-s-14'].join(' ')}>{title}</p>
      <Tooltip title={tooltipMessage}>
        <img src={CommonIcons.tooltipIcon} alt="tooltip-icon" />
      </Tooltip>
    </div>
    <CGMIndicator id={indicatorId} value={value} isCritical={isCritical} />
  </div>
);

CGMInfoItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isCritical: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  indicatorId: PropTypes.string,
};

export default CGMInfoItem;
