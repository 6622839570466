import PropTypes from 'prop-types';
import React from 'react';

import PatientVital, { calculateBMI, getBMIStyle } from 'common-src/models/PatientVital';

import styles from './BMI.module.scss';

const BMI = ({ vital }) => {
  const bmiValue = calculateBMI(vital?.weight, vital?.height);

  return (
    <span id="bmi-value" className={styles.text} style={{ ...getBMIStyle(bmiValue) }}>
      {bmiValue || '-'}
    </span>
  );
};

BMI.propTypes = {
  vital: PropTypes.exact(PatientVital.schema),
};

export default BMI;
