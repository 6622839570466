import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import PatientLabReading from 'common-src/models/PatientLabReading';
import { ColorsNew } from 'common-src/styles';
import { bolderize } from 'common-src/utils/stringUtils';

import styles from './GraphTab.module.scss';
import { generateLine, getChartData } from './helpers';

const GraphTab = ({ results = [] }) => {
  const { data, labels, minValue, maxValue, resultsCount, deviation } = useMemo(
    () => getChartData(results),
    [results],
  );

  const lineData = {
    datasets: [
      {
        data,
        label: 'visible',
        animation: false,
        borderColor: 'rgb(149, 163, 155)',
        pointStyle: 'circle',
        borderWidth: 3,
        hoverBorderWidth: 3,
        pointRadius: 6,
        pointHoverRadius: 7,
        pointBorderColor: 'white',
        pointHoverBorderColor: 'white',
        backgroundColor: ColorsNew.lightGreen,
        hoverBackgroundColor: ColorsNew.lightGreen,
        datalabels: {
          formatter: () => '',
        },
      },
      generateLine('max', maxValue, resultsCount),
      generateLine('min', minValue, resultsCount),
    ],
    labels,
  };

  const lineOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        position: 'nearest',
        backgroundColor: ColorsNew.lightGreen,
        bodyColor: ColorsNew.darkGreen,
        footerColor: ColorsNew.darkGreen,
        borderColor: 'rgba(155, 206, 182, 0.9)',
        borderWidth: 1,
        displayColors: false,
        bodyFont: { size: 12, lineHeight: 1.4 },
        footerFont: { weight: 400, size: 11 },
        caretPadding: 20,
        caretSize: 0,
        padding: { top: 5, bottom: 5, left: 10, right: 10 },
        filter: (item) => !['min', 'max'].includes(item.dataset.label),
        callbacks: {
          label: (item) =>
            item.formattedValue ? item.formattedValue.replace(/./g, bolderize) : '-',
          footer: (items) => {
            if (_.isEmpty(items)) return '';
            const dateTime = items[0]?.label;
            return dateTime ? moment(dateTime).format('MM/DD/YYYY') : '-';
          },
          title: () => '',
        },
      },
    },
    animation: { duration: 0 },
    clip: false,
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: { top: 50, right: 40, left: 40 } },
    scales: {
      x: {
        distribution: 'series',
        type: 'time',
        time: { unit: 'day', stepSize: 30, displayFormats: { day: 'MM/YY' } },
        grid: { drawOnChartArea: false, drawTicks: false },
        ticks: { padding: 20, stepSize: 30 },
      },
      y: {
        min: deviation ? minValue - deviation : 0,
        max: maxValue + (deviation || 10),
        grid: { drawOnChartArea: false, drawTicks: false },
        ticks: { display: false },
        afterFit(scaleInstance) {
          scaleInstance.width =
            Math.max(minValue.toString().length, maxValue.toString().length) * 2;
        },
      },
    },
  };

  return (
    <div className={styles.chart}>
      <Line data={lineData} options={lineOptions} plugins={[ChartDataLabels]} />
    </div>
  );
};

GraphTab.propTypes = {
  results: PropTypes.arrayOf(PropTypes.exact(PatientLabReading.schema)),
};

export default GraphTab;
