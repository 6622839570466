import { DeviceType } from './constants';

export const getDeviceType = (typeId) => {
  switch (typeId) {
    case DeviceType.BioTelGlucometer:
      return 'Biotel';
    case DeviceType.TranstekGlucometer:
      return 'Transtek';
    default:
      return '-';
  }
};
