export const StatusesConfig = [
  {
    id: 1,
    label: 'Active',
    textColor: '#FFFFFF',
    backgroundColor: '#9D97C7',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 2,
    label: 'Needs Update',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 3,
    label: 'Discontinued',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: true,
    isArchived: true,
  },
  {
    id: 4,
    label: 'Archived',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 5,
    label: 'None',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: false,
    isArchived: false,
  },
];

export const activeStatusesIds = [StatusesConfig[0].id, StatusesConfig[1].id, StatusesConfig[4].id];
