import { LOGOUT_COMPLETE } from 'common-src/features/auth/actionTypes';
import { createReducer } from 'common-src/utils/reducerUtils';

import {
  UPDATE_AGENT_STATE,
  UPDATE_AWS_DIALER_VISIBILITY,
  UPDATE_CONTACT_TO_DIAL,
} from './actionTypes';

const initialState = {
  isVisible: false,
  contactToDial: null,
  agentState: null,
};

const entityHandlers = {
  [UPDATE_AWS_DIALER_VISIBILITY]: (state, payload) => {
    const isVisible = payload?.isVisible;
    const updatedState = { ...state, isVisible };

    if (!isVisible) {
      updatedState.contactToDial = null;
    }

    return updatedState;
  },
  [UPDATE_CONTACT_TO_DIAL]: (state, payload) => ({
    ...state,
    isVisible: true,
    contactToDial: payload?.phoneToDial
      ? { phoneToDial: payload?.phoneToDial, patientId: payload?.patientId }
      : null,
  }),
  [UPDATE_AGENT_STATE]: (state, payload) => ({
    ...state,
    agentState: payload.agentState,
  }),
  [LOGOUT_COMPLETE]: () => initialState,
};

export const reducer = createReducer(initialState, entityHandlers);
