// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestAdjustmentsPopup-module__container___VRCNT {
  padding: 25px 30px;
}

.RequestAdjustmentsPopup-module__buttonsContainer___u0WwF {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/RequestAdjustmentsPopup/RequestAdjustmentsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RequestAdjustmentsPopup-module__container___VRCNT`,
	"buttonsContainer": `RequestAdjustmentsPopup-module__buttonsContainer___u0WwF`
};
module.exports = ___CSS_LOADER_EXPORT___;
