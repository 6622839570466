import { attr, fk, Model } from 'redux-orm';

class PatientEncounter extends Model {
  static modelName = 'PatientEncounter';

  static fields = {
    id: attr(),
    type: attr(),
    status: attr(),
    provider: attr(),
    startDate: attr(),
    endDate: attr(),
    comments: attr(),
    patientReported: attr(),
    activeTill: attr(),
    updatedAt: attr(),
    createdAt: attr(),
    updatedBy: fk('Client', 'encounterChanger'),
    createdBy: fk('Client', 'encounterCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'encounters',
    }),
  };

  get source() {
    return this.patientReported ? 'Patient Statement' : 'Medical Record';
  }
}

export default PatientEncounter;
