export const MedicationStatus = Object.freeze({
  Active: 1,
  Discontinued: 2,
  NeedsUpdate: 3,
  NeedsRefill: 4,
  Archived: 5,
  None: 6,
  NeedsReview: 7,
  NeedsVisit: 8,
  Expired: 9,
});

export const StatusesConfig = [
  {
    id: 1,
    label: 'Active',
    textColor: '#FFFFFF',
    backgroundColor: '#9D97C7',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 2,
    label: 'Discontinued',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 3,
    label: 'Needs Update',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isSelectable: false,
    isArchived: true,
  },
  {
    id: 4,
    label: 'Needs Refill',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isSelectable: true,
    isArchived: true,
  },
  {
    id: 5,
    label: 'Archived',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 6,
    label: 'None',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: false,
    isArchived: false,
  },
  {
    id: 7,
    label: 'Needs Review',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isSelectable: true,
    isArchived: false,
  },
  {
    id: 8,
    label: 'Needs Visit',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
    isSelectable: true,
    isArchived: true,
  },
  {
    id: 9,
    label: 'Expired',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
    isSelectable: true,
    isArchived: false,
  },
];

export const activeStatusesIds = [
  StatusesConfig[0].id,
  StatusesConfig[2].id,
  StatusesConfig[3].id,
  StatusesConfig[5].id,
  StatusesConfig[6].id,
  StatusesConfig[7].id,
];

export const Sources = [
  {
    value: 1,
    label: 'Patient Statement',
    isSelectable: true,
  },
  {
    value: 2,
    label: 'Perry Health',
    isSelectable: false,
  },
  {
    value: 3,
    label: 'Medical Record',
    isSelectable: true,
  },
  {
    value: 4,
    label: 'ScriptSure',
    isSelectable: false,
    tooltipText:
      'We connect to PBMs, Pharmacies and Insurers to obtain medication history for the patient.  IT MAY CONTAIN ERRORS or OMISSIONS and we cannot guarantee this is complete history. We pull the information every 90 days and look for updates.',
  },
  {
    value: 5,
    label: 'CMS Part D-Import',
    isSelectable: false,
    tooltipText:
      'We connect to PBMs, Pharmacies and Insurers to obtain medication history for the patient.  IT MAY CONTAIN ERRORS or OMISSIONS and we cannot guarantee this is complete history. We pull the information every 30 days and look for updates.',
  },
];

export const Routes = [
  {
    value: 1,
    label: 'PO (by mouth)',
  },
  {
    value: 2,
    label: 'IntraMuscular',
  },
  {
    value: 3,
    label: 'Intravenous',
  },
  {
    value: 4,
    label: 'Subcutaneous',
  },
  {
    value: 5,
    label: 'Sublingual',
  },
  {
    value: 6,
    label: 'Inhaled',
  },
  {
    value: 7,
    label: 'Dermal',
  },
  {
    value: 8,
    label: 'Other',
  },
];

export const Frequencies = [
  {
    value: 1,
    label: 'QD - Daily',
  },
  {
    value: 2,
    label: 'BID - Twice a day',
  },
  {
    value: 3,
    label: 'TID - Three times a day',
  },
  {
    value: 4,
    label: 'QID - Four times a day',
  },
  {
    value: 5,
    label: 'QHS - Before bed',
  },
  {
    value: 6,
    label: 'Daily',
  },
  {
    value: 7,
    label: 'Daily before breakfast',
  },
  {
    value: 8,
    label: 'Daily before lunch',
  },
  {
    value: 9,
    label: 'Daily before dinner',
  },
  {
    value: 10,
    label: 'Weekly',
  },
  {
    value: 11,
    label: 'Every morning',
  },
  {
    value: 12,
    label: 'Every evening',
  },
  {
    value: 13,
    label: 'With meals',
  },
  {
    value: 14,
    label: 'Other',
  },
];
