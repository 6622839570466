import moment from 'moment-timezone';

import Config from 'common-src/config';

import { ScheduledCallTabs } from './constants';

export const canceledFilter = (booking) => booking?.status === 'canceled';

export const getScheduledCallTabs = (oncehubBookings) => {
  const upcoming =
    oncehubBookings?.filter((b) => !moment().isAfter(moment(b.scheduledAt)) && !canceledFilter(b))
      ?.length || 0;
  const past =
    oncehubBookings?.filter((b) => moment().isAfter(moment(b.scheduledAt)) && !canceledFilter(b))
      ?.length || 0;
  const canceled = oncehubBookings?.filter((b) => canceledFilter(b))?.length || 0;

  return {
    UPCOMING: {
      text: ScheduledCallTabs.UPCOMING.text,
      count: upcoming,
    },
    PAST: {
      text: ScheduledCallTabs.PAST.text,
      count: past,
    },
    CANCELED: {
      text: ScheduledCallTabs.CANCELED.text,
      count: canceled,
    },
    ALL: {
      text: ScheduledCallTabs.ALL.text,
      count: upcoming + past + canceled,
    },
  };
};

export const getScheduledCallType = (scheduledCall) => {
  const { eventName, eventType } = scheduledCall;
  // return event name if exists
  if (eventName) return eventName;

  const typeId = Number(JSON.parse(Config.ONCEHUB_EVENT_MAPPING)[eventType]);

  switch (typeId) {
    case 4:
      return 'Needs Onboarding';
    case 6:
      return 'Upcoming Appointment';
    case 19:
      return 'Needs Clinical Initial Visit';
    default:
      return '';
  }
};
