import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientCgmOrder extends Model {
  static modelName = 'PatientCgmOrder';

  static fields = {
    id: attr(),
    product: attr(),
    supplier: attr(),
    status: attr(),
    notes: attr(),
    orderedAt: attr(),
    sentAt: attr(),
    shippedAt: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'cgmOrderChanger'),
    createdBy: fk('Client', 'cgmOrderCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'cgmOrders',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      patientId: PropTypes.number,
      product: PropTypes.number,
      supplier: PropTypes.number,
      status: PropTypes.number,
      notes: PropTypes.string,
      orderedAt: PropTypes.string,
      sentAt: PropTypes.string,
      shippedAt: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientCgmOrder;
