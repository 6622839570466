import { CommonIcons } from 'common-src/assets/Icons';

export const getDataByChannel = (channel) => {
  if (channel.includes('VOICE')) {
    return { label: 'Phone Call', icon: CommonIcons.phoneCallIncon };
  }

  if (channel.includes('SMS')) {
    return { label: 'SMS Conversation', icon: CommonIcons.smsIcon };
  }

  return { label: 'Reminder', icon: CommonIcons.reminderIcon };
};

export const getClientById = (clients, id) => clients?.find((client) => client.id === id);

export const getClientName = (client) => {
  if (!client) return '';
  if (client.id === 1) return 'System user';

  const firstName = client.firstName || '';
  const lastName = client.lastName || '';

  return client.middleName
    ? `${firstName} ${client.middleName.charAt(0)}. ${lastName}`
    : `${firstName} ${lastName}`;
};
