import { combineReducers } from 'redux';

import { reducer as cache } from 'common-src/features/cache/reducer';
import { reducer as orm } from 'common-src/features/orm/reducer';

import { reducer as alerts } from 'src/features/alerts/reducer';
import { reducer as appVersion } from 'src/features/appVersion/reducer';
import { reducer as auth } from 'src/features/auth/reducer';
import { reducer as awsConnect } from 'src/features/awsConnect/reducer';
import { reducer as modals } from 'src/features/modals/reducer';
import { reducer as tabsState } from 'src/features/tabsState/reducer';
import { reducer as timeTracker } from 'src/features/timeTracker';

const rootReducer = combineReducers({
  alerts,
  appVersion,
  auth,
  awsConnect,
  orm,
  cache,
  tabsState,
  modals,
  timeTracker,
});

export default rootReducer;
