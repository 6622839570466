import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TextArea } from 'common-src/components/base';
import { PatientTaskStatus, TaskReason, TaskType } from 'common-src/models/PatientTask';
import { createPatientTask } from 'common-src/models/PatientTask/actions';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './RequestAdjustmentsPopup.module.scss';

const RequestAdjustmentsPopup = ({ onClose, patientId, interactionId }) => {
  const dispatch = useDispatch();

  const [details, setDetails] = useState('');
  const [reason, setReason] = useState('');

  const { renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();
    dispatch(
      createPatientTask(
        {
          patientId,
          typeId: TaskType.Administrative.value,
          reasonId: TaskReason.InteractionAdjustment.value,
          subject: details,
          status: PatientTaskStatus.New.value,
          data: { interactionId, interactionAdjustmentReason: reason },
        },
        {
          successBlock: () => {
            successCallback('Task created!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  return (
    <BasePopup id="request-adjustments" open onClose={onClose} title="Request Adjustments">
      <div className={styles.container}>
        <TextArea
          id="details"
          placeholder="Enter details"
          value={details}
          onTextChange={(value) => setDetails(value)}
          fixedRows={3}
          label="Details"
          required
        />
        <TextArea
          id="reason"
          placeholder="Enter reason"
          value={reason}
          onTextChange={(value) => setReason(value)}
          fixedRows={3}
          label="Reason"
          required
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: !!details && !!reason,
        submitButtonText: 'Save and Close',
      })}
    </BasePopup>
  );
};

RequestAdjustmentsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number,
  interactionId: PropTypes.string,
};

export default RequestAdjustmentsPopup;
