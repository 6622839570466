import moment from 'moment';
import { useEffect } from 'react';

import { PlatformStatus, unavailableActionsStatuses } from 'common-src/models/Patient/constants';
import { platformStatusTitleForBanner } from 'common-src/models/Patient/presenters';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';

const HEADER = '62px';
const HEADER_WITH_BANNER = '101px';

const usePatientStatusBanner = (patientId) => {
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', +patientId));
  const withFutureOnHold = patient?.holdStartsAt
    ? moment(moment(patient?.holdStartsAt).format('MM/DD/YYYY')).isAfter(
        moment().format('YYYY-MM-DD'),
      )
    : false;
  const showBanner =
    unavailableActionsStatuses.includes(patient?.platformStatus) || withFutureOnHold;

  const getHoldExpiration = () =>
    patient?.holdExpiresAt && patient?.platformStatus === PlatformStatus.Hold
      ? `(until ${moment(patient?.holdExpiresAt).format('MM/DD/YYYY')})`
      : '';

  const getStatusMessage = () => {
    if (!showBanner) return '';

    if (patient?.holdStartsAt) {
      return `Member will go on hold starting on ${moment(patient?.holdStartsAt).format(
        'MM/DD/YYYY',
      )}`;
    }

    return `${platformStatusTitleForBanner(
      patient?.platformStatus,
    )} ${getHoldExpiration()} - Some actions unavailable`;
  };

  useEffect(() => {
    if (showBanner) {
      document.documentElement.style.setProperty('--patient-details-header', HEADER_WITH_BANNER);
    } else {
      document.documentElement.style.setProperty('--patient-details-header', HEADER);
    }
  }, [patient?.platformStatus, withFutureOnHold]);

  return {
    showBanner,
    statusMessage: getStatusMessage(),
  };
};

export default usePatientStatusBanner;
