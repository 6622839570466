/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Checkbox, DatePickerNew, RequestHandlerScreen } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';

import { AddButton } from 'src/components/buttons';

import styles from './BillingInfo.module.scss';
import { RegalInfoItem, TimeLogItem } from './items';

const BillingInfo = ({
  patientId,
  timeLogs,
  onTimeLogChange,
  setIsServiceAtValid,
  serviceAt,
  setServiceAt,
  regalTaskIds,
  setRegalTaskIds,
  hasRelevantConversations,
  setHasRelevantConversations,
}) => {
  const [regalTaskInfos, setRegalTaskInfos] = useState([]);
  const [clients, setClients] = useState([]);
  const [isRequesting, setIsRequesting] = useState(false);

  const headerClasses = [styles.header, 'font-w-700', 'primary-border-b'];
  const subHeaderClasses = [styles.subHeader, 'font-w-500', 'font-s-14', 'm-t-16'];

  useEffect(() => {
    setIsRequesting(true);
    apiRequest({
      endpoint: `regalTaskInfos/latest/${patientId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        setRegalTaskInfos(data?.RegalTaskInfo || []);
        setClients(data?.Client || []);
      })
      .finally(() => setIsRequesting(false));
  }, []);

  const renderDateOfServicePicker = () => {
    const allowedTimeInterval = moment(serviceAt).subtract(3, 'hours');
    const showHoursWarning = moment(moment().format('YYYY-MM-DD HH:mm')).isBefore(
      allowedTimeInterval,
    );

    return (
      <DatePickerNew
        id="date-of-service"
        inputClassNames={[styles.dateInput]}
        classNames={['m-b-16']}
        header="Date & Time of Service"
        value={serviceAt}
        required
        withTime
        warningText={
          showHoursWarning ? 'Warning: Please review date and time of service for accuracy' : ''
        }
        onDateSelected={(date) => {
          setServiceAt(date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null);
        }}
        onBlur={(date) => setIsServiceAtValid(date !== undefined)}
      />
    );
  };

  const renderRecentConversations = () => (
    <>
      <p className={headerClasses.join(' ')}>Link this note to recent conversations</p>
      <p className={subHeaderClasses.join(' ')}>
        Please link this note to any recent conversations. Include all tasks, including reminders,
        that were associated with this note. The system will automatically remove time that occurred
        simultaneously to prevent duplicate counting.
      </p>
      {regalTaskInfos.map((rti) => (
        <RegalInfoItem
          key={rti.id}
          info={rti}
          regalTaskIds={regalTaskIds}
          setRegalTaskIds={setRegalTaskIds}
          relatedClients={clients}
        />
      ))}
      <Checkbox
        id="relevant-conversations"
        classNames={['m-b-30', 'm-t-16']}
        label="I don't see relevant conversations"
        checked={hasRelevantConversations}
        onChange={setHasRelevantConversations}
      />
    </>
  );

  const renderManualTimeAddition = () => {
    const categoriesToExclude = _.map(timeLogs, 'category');
    return (
      <>
        <p className={headerClasses.join(' ')}>Manual Time Addition</p>
        <p className={subHeaderClasses.join(' ')}>
          If there was any manual time spent outside of Regal (e.g. chart review, care coordination,
          documentation, etc.) or calling an emergency contact, emergency services, or the member's
          healthcare provider (PCP, Endo, etc.), please add the minutes spent below in addition to
          the linked events above. If you cannot find a Regal event above assiciated with your
          visit, please submit a manual intervention ticket.
        </p>
        {timeLogs.map((timeLog, index) => (
          <TimeLogItem
            key={`time-log-${index}-${timeLog.category}`}
            index={index}
            timeLog={timeLog}
            onTimeLogChange={onTimeLogChange}
            categoriesToExclude={categoriesToExclude || []}
          />
        ))}
        <AddButton
          id="add-additional-entry"
          label="Add additional entry"
          onClick={() => onTimeLogChange()}
          disabled={!_.isEmpty(timeLogs) && !!timeLogs.find((tl) => !tl.category || !tl.timeSpent)}
        />
      </>
    );
  };

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling regal tasks..." />;
  }

  return (
    <div className={styles.container}>
      {renderDateOfServicePicker()}
      {renderRecentConversations()}
      {renderManualTimeAddition()}
    </div>
  );
};

BillingInfo.propTypes = {
  patientId: PropTypes.number,
  timeLogs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      timeSpent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onTimeLogChange: PropTypes.func,
  serviceAt: PropTypes.string,
  setServiceAt: PropTypes.func,
  setIsServiceAtValid: PropTypes.func,
  regalTaskIds: PropTypes.array,
  setRegalTaskIds: PropTypes.func,
  hasRelevantConversations: PropTypes.bool,
  setHasRelevantConversations: PropTypes.func,
};

export default BillingInfo;
