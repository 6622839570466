import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select, TextArea } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import PatientSupplement, {
  activeStatusesIds,
  addSupplement,
  makeSupplementInactive,
  StatusesConfig,
  updateSupplement,
  validatePatientSupplement,
} from 'common-src/models/PatientSupplement';
import { getChangedValues } from 'common-src/utils/objectUtils';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState, SourceOptions, StatusesOptions } from './constants';
import { catchErrors, extractSupplement, getIsButtonEnabled, getRequestBody } from './helpers';
import styles from './SupplementPopup.module.scss';

const UPDATABLE_FIELDS = ['dosageText', 'form', 'comments'];

const SupplementPopup = ({ patientId, supplement, onClose, isDuplicate = false }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const isEdit = useMemo(() => !!supplement?.id && !isDuplicate, [supplement?.id, isDuplicate]);

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!supplement) return;
    setData(extractSupplement(supplement));
  }, [supplement]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const updatedFields = Object.keys(getChangedValues(data, extractSupplement(supplement)));
    const shouldEdit =
      !isDuplicate &&
      (_.isEmpty(updatedFields.filter((field) => !UPDATABLE_FIELDS.includes(field))) ||
        (supplement &&
          !activeStatusesIds.includes(data.status ? data.status.value : StatusesConfig[4].id)));

    const requests = [];
    if (shouldEdit) {
      requests.push(updateSupplement(supplement.id, getRequestBody(data)));
    } else {
      requests.push(addSupplement(getRequestBody(data, patientId)));
      if (supplement && !isDuplicate) {
        requests.push(makeSupplementInactive(supplement.id));
      }
    }

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Supplement ${shouldEdit ? 'edited' : 'added'}!`);

          if (!shouldClose) {
            setData(initialState);
            setAutoFocus(true);
            return;
          }

          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientSupplement(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onClear = () => setData(initialState);

  return (
    <BasePopup
      id="supplement"
      open
      onClose={onClose}
      title={isEdit ? 'Edit Supplement' : 'Add Supplement'}
    >
      <div className={styles.grid}>
        <Input
          id="name"
          placeholder="Enter name"
          label="Supplement Name"
          required
          value={data.name}
          errorText={errors.name}
          onTextChange={(value) => onChangeHandler('name', value)}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
        />
        <Input
          id="dosage"
          placeholder="Enter dosage"
          label="Dosage"
          value={data.dosageText}
          onTextChange={(value) => onChangeHandler('dosageText', value)}
        />
        <Input
          id="form"
          placeholder="Form"
          label="Form"
          value={data.form}
          onTextChange={(value) => onChangeHandler('form', value)}
        />
        <Select
          id="status"
          classNames={[styles.width75]}
          label="Status"
          options={StatusesOptions}
          onChange={(op) => onChangeHandler('status', op)}
          value={data.status}
          placeholder="Select status"
        />
        <Input
          id="frequency"
          placeholder="Frequency"
          label="Frequency"
          value={data.frequency}
          onTextChange={(value) => onChangeHandler('frequency', value)}
        />
        <Select
          id="patientReported"
          label="Source"
          options={SourceOptions}
          onChange={(op) => onChangeHandler('patientReported', op)}
          value={data.patientReported}
          placeholder="Select source"
        />
        <TextArea
          id="comments"
          classNames={[styles.width100]}
          placeholder="Enter comments"
          value={data.comments}
          onTextChange={(value) => onChangeHandler('comments', value)}
          fixedRows={4}
          label="Comments"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, supplement, isDuplicate),
        submitButtonText: 'Save and Close',
        onClear: !isEdit ? onClear : null,
        renderSaveButton: !isEdit,
      })}
    </BasePopup>
  );
};

SupplementPopup.propTypes = {
  patientId: PropTypes.number,
  supplement: PropTypes.exact(PatientSupplement.schema),
  onClose: PropTypes.func,
  isDuplicate: PropTypes.bool,
};

export default SupplementPopup;
