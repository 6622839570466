import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import {
  ExemptionStatus,
  ExemptionType,
  getExemptionsRequest,
  getExemptionStatus,
  getExemptionType,
  getPatientBillingExemptions,
} from 'common-src/models/PatientBillingExemption';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { BaseTable, TablePagination, TextItem } from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, ExemptionsTabs, TableHeaders } from './constants';
import styles from './Exemptions.module.scss';
import { getTabInfo } from './helpers';

const Exemptions = ({ patientId, actionsAllowed }) => {
  const tabs = Object.keys(ExemptionsTabs);

  // state
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [filteredExemptions, setFilteredExemptions] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  // redux
  const dispatch = useDispatch();
  const exemptions = useCustomSelector((state) => getPatientBillingExemptions(state, patientId));

  const { isRequesting } = useRequestLoading([getExemptionsRequest({ patientId })]);

  useInterval(() => {
    dispatch(getExemptionsRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, allExemptions, activeExemptions, inactiveExemptions } = getTabInfo(exemptions);

    let exemptionsToShow;
    switch (selectedTab) {
      case tabs[0]:
        exemptionsToShow = activeExemptions;
        break;
      case tabs[1]:
        exemptionsToShow = inactiveExemptions;
        break;
      case tabs[2]:
        exemptionsToShow = allExemptions;
        break;
      default:
        exemptionsToShow = [];
    }

    setTabInfo(tabInfo);
    setFilteredExemptions(exemptionsToShow);
  }, [selectedTab, exemptions]);

  const showExemptionPopup = (patientId, exemption) =>
    dispatch(openModal(ModalType.EXEMPTION, { patientId, exemption }));

  const renderStatus = (status) => {
    const data = getExemptionStatus(status);
    return <StatusItem isActive withArrow={false} text={data.label} backgroundColor={data.color} />;
  };

  const getData = () =>
    filteredExemptions.map((exemption) => ({
      exemptionType: (
        <TextItem
          classNames={[styles.tableCell]}
          isActive
          text={getExemptionType(exemption.exemptionType)}
          isUnderlined={
            exemption.status === ExemptionStatus.Active.value &&
            exemption.exemptionType !== ExemptionType.MFA.value &&
            actionsAllowed
          }
          onClick={() => {
            if (
              exemption.exemptionType === ExemptionType.MFA.value ||
              exemption.status !== ExemptionStatus.Active.value ||
              !actionsAllowed
            )
              return;
            showExemptionPopup(patientId, exemption);
          }}
        />
      ),
      exemptionRate: (
        <TextItem
          isActive
          text={`${exemption.exemptionRate}% Waiver`}
          value={exemption.exemptionRate}
        />
      ),
      status: renderStatus(exemption.status),
      startDate: (
        <TextItem
          isActive
          text={exemption.startDate ? moment(exemption.startDate).format('MM/DD/YYYY') : '-'}
          value={exemption.startDate}
        />
      ),
      endDate: (
        <TextItem
          isActive
          text={exemption.endDate ? moment(exemption.endDate).format('MM/DD/YYYY') : '-'}
          value={exemption.endDate}
        />
      ),
    }));

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling exemptions..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        showZero
        rightContent={
          <Button
            id="new-exemption-button"
            text="New Exemption"
            onClick={() => showExemptionPopup(patientId)}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.actionButton]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="exemptions"
        classNames={[
          'medical-table-new',
          filteredExemptions?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No exemptions"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'startDate',
          label: 'Start Date',
          width: '14%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredExemptions}
        selectedTab={selectedTab}
      />
    </>
  );
};

Exemptions.propTypes = {
  patientId: PropTypes.number,
  actionsAllowed: PropTypes.bool,
};

export default Exemptions;
