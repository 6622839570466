import PropTypes from 'prop-types';
import React from 'react';

import Patient from 'common-src/models/Patient';
import { getPatientGlucoseValues } from 'common-src/models/PatientCgmGlucoseReading';
import { getAge } from 'common-src/utils/dateUtils';

import { CGMIndicator, ReadingItem } from 'src/pages/PatientDetails/fragments/Biometrics/items';

import styles from './CGMHeader.module.scss';

const CGMHeader = ({ patient, data, a1c }) => {
  const transformedData = getPatientGlucoseValues(data, getAge(patient.dob), a1c);

  return (
    <div className={[styles.header, 'm-t-16', 'p-l-30', 'gap-24'].join(' ')}>
      <ReadingItem
        id="average-glucose"
        title="Average Glucose"
        content={transformedData.averageGlucose.value}
      />
      <ReadingItem
        id="time-cgm-active"
        title="% Time CGM Active"
        content={
          <CGMIndicator
            value={transformedData.timeCgmActive.value}
            isCritical={transformedData.timeCgmActive.isCritical}
          />
        }
      />
      <ReadingItem
        id="very-high-cgm"
        title="Very High (>250 mg/dL)"
        content={
          <CGMIndicator
            value={transformedData.veryHigh.value}
            isCritical={transformedData.veryHigh.isCritical}
          />
        }
      />
      <ReadingItem
        id="high-cgm"
        title="High (180-250 mg/dL)"
        content={
          <CGMIndicator
            value={transformedData.high.value}
            isCritical={transformedData.high.isCritical}
          />
        }
      />
      <ReadingItem
        id="target-cgm-range"
        title="Target Range (70-180 mg/dL)"
        content={
          <CGMIndicator
            value={transformedData.targetRange.value}
            isCritical={transformedData.targetRange.isCritical}
          />
        }
      />
      <ReadingItem
        id="low-cgm"
        title="Low (54-70 mg/dL)"
        content={
          <CGMIndicator
            value={transformedData.low.value}
            isCritical={transformedData.low.isCritical}
          />
        }
      />
      <ReadingItem
        id="very-low-cgm"
        title="Very Low (<54 mg/dL)"
        content={
          <CGMIndicator
            value={transformedData.veryLow.value}
            isCritical={transformedData.veryLow.isCritical}
          />
        }
      />
    </div>
  );
};

CGMHeader.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  data: PropTypes.object,
  a1c: PropTypes.number,
};

export default CGMHeader;
