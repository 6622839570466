import _ from 'lodash';

import {
  activeStatusesIds,
  getSdohName,
  SdohStatus,
  validatePatientSdoh,
} from 'common-src/models/PatientSdoh';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientSdoh(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => {
  const body = {
    status: data.status?.value,
    note: data.note,
  };

  if (patientId) {
    Object.assign(body, { sdohCodeId: data.sdohCodeId?.value, patientId });
  }

  return body;
};

export const extractPatientSdoh = (sdoh = {}, socialIssuesOptions = []) => ({
  sdohCodeId: socialIssuesOptions.find((x) => x.value === sdoh?.sdohCodeId?.id) || null,
  status: Object.values(SdohStatus).find((x) => x.value === sdoh.status) || null,
  note: sdoh.note,
});

export const getIsButtonEnabled = (data, errors, sdoh) => {
  if (
    Object.values(data).every((e) => !e) ||
    Object.values(errors).some((e) => e) ||
    (sdoh && _.isEqual(data, extractPatientSdoh(sdoh)))
  )
    return false;

  return true;
};

export const getSocialIssuesOptions = (sdohCodes = [], patientSdoh = [], isEdit) => {
  let filteredSdohCodes = sdohCodes;

  if (!isEdit) {
    const patientSdohCodes = patientSdoh
      .filter((ps) => activeStatusesIds.includes(ps.status))
      .map((ps) => ps?.sdohCodeId?.id);

    filteredSdohCodes = sdohCodes.filter((sc) => !patientSdohCodes.includes(sc.id));
  }

  return filteredSdohCodes.map((sc) => ({ id: sc.id, value: sc.id, label: getSdohName(sc) }));
};
