import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { AlertType } from 'src/components/base/AlertGroup';
import { getAlerts, showAlert as showAlertAction } from 'src/features/alerts';
import useCustomSelector from 'src/hooks/useCustomSelector';

const useAlert = () => {
  const dispatch = useDispatch();

  const alerts = useCustomSelector((state) => getAlerts(state));

  const showAlert = (type, title, message, isAutoDismiss = true) => {
    dispatch(
      showAlertAction({
        type,
        title,
        message,
        isAutoDismiss,
      }),
    );
  };

  return {
    showAlert,
    AlertType,
    hasAlert: !_.isEmpty(alerts),
  };
};

export default useAlert;
