import PropTypes from 'prop-types';
import React from 'react';

import { Menu } from 'common-src/components/base';
import FeatureFlag, { getFeatureFlagColor } from 'common-src/models/FeatureFlag';

import { stateOptions } from './constants';
import styles from './FeatureFlagItem.module.scss';

const FeatureFlagItem = ({ featureFlag, onChangeHandler }) => {
  const options = stateOptions.map((x) => ({
    text: x.label,
    id: x.value,
    onClick: () => onChangeHandler(x),
  }));

  return (
    <div className={[styles.container, 'vertically-centered'].join(' ')}>
      <Menu
        button={
          <div
            id={`menu-${featureFlag.name}`}
            className={[styles.button, 'primary-border', 'border-r-6', 'm-r-20', 'font-s-14'].join(
              ' ',
            )}
          >
            <span
              className={styles.point}
              style={{ backgroundColor: getFeatureFlagColor(featureFlag.state) }}
            />
            <span>
              {stateOptions.find((state) => state.value === featureFlag.state)?.label || ''}
            </span>
          </div>
        }
        options={options}
        paperWidth={180}
        withArrow
        arrowStyles={{ right: '24px', width: '16px', height: '16px' }}
      />
      <div className={[styles.wrapper, 'font-w-500'].join(' ')}>
        <span>{featureFlag.name}</span>
        <span className={styles.dash}>-</span>
        <span className="font-s-14">{featureFlag.description}</span>
      </div>
    </div>
  );
};

FeatureFlagItem.propTypes = {
  featureFlag: PropTypes.exact(FeatureFlag.schema),
  onChangeHandler: PropTypes.func,
};

export default FeatureFlagItem;
