const MARGIN_TOP = 5;
const ERROR_HEIGHT = 15;
const WINDOW_DEVIATION = 70;

export const getPaperStyles = (paperRef, selectRef, position, withError) => {
  const { height: paperHeight } = paperRef.current.getBoundingClientRect();
  const {
    width: selectWidth,
    height: selectHeight,
    bottom: selectBottom,
  } = selectRef.current.getBoundingClientRect();

  const styles = { width: `${selectWidth}px` };

  if (position === 'absolute') {
    Object.assign(styles, { top: 0, left: 0 });

    if (selectBottom + paperHeight > window.innerHeight) {
      let y = paperHeight;
      if (withError) {
        y -= ERROR_HEIGHT;
      }

      Object.assign(styles, { transform: `translate3d(0px, -${y}px, 10px)` });
    } else {
      let y = selectHeight + 2 * MARGIN_TOP;
      if (withError) {
        y += ERROR_HEIGHT + MARGIN_TOP;
      }

      Object.assign(styles, { transform: `translate3d(0px, ${y}px, 10px)` });
    }

    return styles;
  }

  if (window.innerHeight - WINDOW_DEVIATION > selectBottom + paperHeight) {
    let marginTop = MARGIN_TOP;
    if (withError) {
      marginTop -= ERROR_HEIGHT;
    }

    Object.assign(styles, { marginTop: `${marginTop}px` });
  } else {
    let marginTop = paperHeight + selectHeight + 2 * MARGIN_TOP;
    if (withError) {
      marginTop += ERROR_HEIGHT;
    }

    Object.assign(styles, { marginTop: `-${marginTop}px` });
  }

  return styles;
};
