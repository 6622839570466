import _ from 'lodash';
import { useEffect, useState } from 'react';

import { apiRequest } from 'common-src/features/rest';

import useInterval from 'src/hooks/useInterval';

const useEventsLinking = (patientId) => {
  const [regalTaskInfos, setRegalTaskInfos] = useState([]);
  const [awscCalls, setAwscCalls] = useState([]);
  const [clients, setClients] = useState([]);

  useInterval(() => {
    apiRequest({
      endpoint: `awscCall/latest/${patientId}`,
    })
      .then((res) => res.json())
      .then((awscData) => {
        setAwscCalls(awscData?.AwscCall || []);

        const uniqueClients = _.uniqBy([...clients, ...(awscData?.Client || [])], 'id');
        setClients(uniqueClients);
      });
  }, 20 * 1000); // INFO: poll awsc calls on every 20 seconds

  useEffect(() => {
    const requests = [
      apiRequest({
        endpoint: `regalTaskInfos/latest/${patientId}`,
      }),
      apiRequest({
        endpoint: `awscCall/latest/${patientId}`,
      }),
    ];

    Promise.all(requests)
      .then((responses) => {
        const regalPromise = responses[0]?.json();
        const awscPromise = responses[1]?.json();

        return { regalPromise, awscPromise };
      })
      .then((data) => {
        const regalDataPromise = data.regalPromise;
        const awscDataPromise = data.awscPromise;

        return Promise.all([regalDataPromise, awscDataPromise]);
      })
      .then(([regalData, awscData]) => {
        // Processing the resolved JSON data directly
        setRegalTaskInfos(regalData?.RegalTaskInfo || []);
        setAwscCalls(awscData?.AwscCall || []);

        const regalClients = regalData?.Client || [];
        const awscClients = awscData?.Client || [];

        const uniqueClients = _.uniqBy([...regalClients, ...awscClients], 'id');
        setClients(uniqueClients);
      });
  }, []);

  return { regalTaskInfos, awscCalls, clients };
};

export default useEventsLinking;
