import { attr, Model } from 'redux-orm';

class Organization extends Model {
  static modelName = 'Organization';

  static fields = {
    id: attr(),
    name: attr(),
    isManaged: attr(),
  };
}

export default Organization;
