import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import styles from './CustomCheckbox.module.scss';

const CustomCheckbox = ({
  header,
  checked,
  onChange,
  classNames,
  info,
  id = 'checkbox',
  disabled = false,
}) => {
  const ref = useRef(null);

  const containerClasses = [styles.container, 'vertically-centered', 'border-r-6'];
  const checkBoxClasses = [styles.checkbox];

  if (classNames) {
    containerClasses.push(...classNames);
  }
  if (checked) {
    containerClasses.push(styles.checked);
    checkBoxClasses.push(styles.checked);
  }
  if (disabled) {
    containerClasses.push(styles.disabled);
  }

  const onChangeHandler = (e) => {
    if (disabled) return;

    e.stopPropagation();
    onChange(!checked);
    ref.current.checked = !checked;
  };

  return (
    <div
      className={containerClasses.join(' ')}
      onClick={(e) => onChangeHandler(e)}
      role="presentation"
    >
      <input
        id={`${id}-input`}
        ref={ref}
        type="checkbox"
        className={styles.input}
        defaultChecked={checked}
      />
      <span className={checkBoxClasses.join(' ')}>
        <small className={styles.check} />
      </span>
      <div className="flex-column">
        <span id={id} className={[styles.label, 'font-w-500', 'font-s-14'].join(' ')}>
          {header}
        </span>
        {info && <span className={[styles.info, 'font-w-500', 'font-s-12'].join(' ')}>{info}</span>}
      </div>
    </div>
  );
};

CustomCheckbox.propTypes = {
  id: PropTypes.string,
  info: PropTypes.string,
  checked: PropTypes.bool,
  header: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default CustomCheckbox;
