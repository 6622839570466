import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientLabReadingsRequest = (query, params) =>
  restRequest({
    uri: 'patientLabReadings',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createLabReading = (body, params) =>
  restRequest({
    uri: 'patientLabReadings',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateLabReading = (body, params) =>
  restRequest({
    uri: `patientLabReadings/${body.id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const deleteLabReading = (id, params) =>
  restRequest({
    uri: `patientLabReadings/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });

export const updateStatus = (ids, status, params) =>
  restRequest({
    uri: `patientLabReadings/update/status`,
    method: HttpMethods.Put,
    body: { ids, status },
    ...params,
  });
