import moment from 'moment-timezone';

import { defaultTimeValues } from './constants';

export const getTimeValues = (value) => {
  if (value) {
    const [hours, minutes] = moment(value).format('hh mm A').split(' ');
    const period = +moment(value).format('HH') >= 12 ? 'pm' : 'am';

    return {
      hours: +hours,
      minutes: minutes === '00' ? 0 : +minutes,
      period,
    };
  }

  return defaultTimeValues;
};

export const getFormattedDate = (date) =>
  date ? moment(moment(date).toISOString()).toDate() : null;

export const generateDate = (date, isMonthly) => {
  const [month, year] = date.split('-');

  if (isMonthly) {
    return `${month}-01-${year}`;
  }

  return date;
};
