import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import styles from './Accordion.module.scss';

const Accordion = ({ title, content, classNames = [], isDefaultExpanded = false }) => {
  const [isExtended, setIsExtended] = useState(isDefaultExpanded);

  return (
    <div className={[styles.container, ...classNames, 'primary-border'].join(' ')}>
      <div
        className={[
          'flex-row',
          'vertically-centered',
          'gap-16',
          'cursor-pointer',
          'p-16',
          'disable-select',
        ].join(' ')}
        onClick={() => setIsExtended((prev) => !prev)}
        role="presentation"
      >
        <img
          src={CommonIcons.arrowDownIcon}
          alt="arrow-down-icon"
          className={[styles.arrow, isExtended ? styles.rotate : ''].join(' ')}
        />
        <p
          id={`accordion-title-${title.toLowerCase()}`}
          className={['font-w-600', 'font-s-16'].join(' ')}
        >
          {title}
        </p>
      </div>
      {isExtended && <div className={styles.content}>{content}</div>}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  classNames: PropTypes.arrayOf(PropTypes.string),
  isDefaultExpanded: PropTypes.bool,
};

export default Accordion;
