import moment from 'moment-timezone';

import { FamilyHistoryTabs } from './constants';

export const getTabInfo = (familyHistory = []) => {
  const activeFamilyHistory = [];
  const inactiveFamilyHistory = [];

  [...familyHistory]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((fh) => {
      if (fh.activeTill && moment(fh.activeTill).isBefore(moment.now())) {
        inactiveFamilyHistory.push(fh);
        return;
      }

      activeFamilyHistory.push(fh);
    });

  const tabInfo = {
    ACTIVE: {
      text: FamilyHistoryTabs.ACTIVE.text,
      count: activeFamilyHistory.length,
    },
    NOT_ACTIVE: {
      text: FamilyHistoryTabs.NOT_ACTIVE.text,
      count: inactiveFamilyHistory.length,
    },
  };

  return {
    tabInfo,
    activeFamilyHistory,
    inactiveFamilyHistory,
  };
};
