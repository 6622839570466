import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { STORAGE_KEY_APP_BACKGROUND_AT } from 'src/constants/storageKeys';
import { PatientTabs } from 'src/features/tabsState';
import {
  clearTimer as clearTimerAction,
  getIsTimerRunning,
  getTimer,
  setTimer as setTimerAction,
} from 'src/features/timeTracker';

import useCustomSelector from './useCustomSelector';
import useIdleSession from './useIdleSession';

const useTimeTracker = (patientId, documentation, isNoteTabOpened, mainTab, onStopCallback) => {
  const dispatch = useDispatch();

  const [isAppInBackground, setIsAppInBackground] = useState(false);

  const prevTimer = useRef(0);
  const lastUpdateTime = useRef(Date.now());
  const refId = useRef(null);

  const isTimerRunning = useCustomSelector((state) => getIsTimerRunning(state, patientId));
  const timer = useCustomSelector((state) => getTimer(state, patientId));

  const setTimer = (timer) => {
    dispatch(setTimerAction(patientId, timer));
  };

  const clearTimer = () => {
    dispatch(clearTimerAction(patientId));
  };

  // Check every 30 seconds for 10 minutes of inactivity
  useIdleSession(isTimerRunning, 600, 30, isAppInBackground, (timeInBackground) => {
    setTimer(prevTimer.current + timeInBackground);
    onStopCallback(prevTimer.current + timeInBackground, true);
  });

  // Start timer
  useEffect(() => {
    const updateTimer = () => {
      if (isAppInBackground || !isTimerRunning) return;

      const now = Date.now();
      const elapsedSeconds = Math.floor((now - lastUpdateTime.current) / 1000);
      if (elapsedSeconds > 0) {
        prevTimer.current += elapsedSeconds;
        setTimer(prevTimer.current);
        lastUpdateTime.current = now;
      }

      refId.current = requestAnimationFrame(updateTimer);
    };

    if (isTimerRunning) {
      lastUpdateTime.current = Date.now();
      refId.current = requestAnimationFrame(updateTimer);
    }

    return () => {
      if (refId.current) {
        cancelAnimationFrame(refId.current);
      }
    };
  }, [isTimerRunning, isAppInBackground, patientId]);

  useEffect(() => {
    if (!documentation?.duration) return;

    const duration = Math.max(documentation.duration, timer);
    setTimer(duration);
    prevTimer.current = duration;
  }, [documentation?.duration]);

  useEffect(() => {
    prevTimer.current = timer;
  }, [timer]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isNoteTabOpened) return;

      onStopCallback(prevTimer.current);
      event.preventDefault();
      event.returnValue = '';
    };

    // Handle close/refresh application
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Handle close browser
    window.addEventListener('unload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleBeforeUnload);
    };
  }, [isNoteTabOpened]);

  // Handle foreground/background application
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsAppInBackground(true);
        sessionStorage.setItem(STORAGE_KEY_APP_BACKGROUND_AT, new Date());
      } else {
        setIsAppInBackground(false);

        const appBackgroundAt = sessionStorage.getItem(STORAGE_KEY_APP_BACKGROUND_AT);
        if (!appBackgroundAt) return;

        const diffMs = Math.abs(new Date(appBackgroundAt) - new Date());
        const diffSeconds = Math.floor(diffMs / 1000);

        if (isTimerRunning) {
          prevTimer.current += diffSeconds;
        }

        sessionStorage.removeItem(STORAGE_KEY_APP_BACKGROUND_AT);
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isTimerRunning]);

  // 1. Automatically starts the timer if the user is in the Note tab
  // 2. Handle navigate back to patient with opened Note tab
  useEffect(() => {
    if (!mainTab || !documentation) return;

    if ((mainTab.text === PatientTabs.Note.text || isNoteTabOpened) && !isTimerRunning) {
      setTimer(0);
    }
  }, [mainTab, isNoteTabOpened, documentation]);

  return { timer: prevTimer.current || timer, isTimerRunning, clearTimer };
};

export default useTimeTracker;
