import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import useOnClickOutside from 'common-src/hooks/useOnClickOutside';
import usePaper from 'common-src/hooks/usePaper';

import Input from '../Input';
import { getPaperStyles } from './helpers';
import Option from './Option';
import styles from './Select.module.scss';

const Select = ({
  id,
  value,
  label,
  prefix,
  iconSrc,
  paperHeight,
  options = [],
  errorText = '',
  classNames = [],
  size = 'medium',
  placeholder = '',
  paperStyles = {},
  required = false,
  disabled = false,
  readOnly = false,
  multiple = false,
  withError = true,
  position = 'fixed',
  rotateIcon = false,
  onChange = () => {},
  isClearable = false,
  isSearchable = false,
  showOptionIcon = true,
  emptyReadOnlyText = '-',
  usePrimaryBorder = false,
  useRoundedCorners = true,
  downArrowIconSrc = CommonIcons.karetDownIcon,
}) => {
  const [searchText, setSearchText] = useState('');
  const [positionStyles, setPositionStyles] = useState({});
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const selectRef = useRef(null);

  const { openPaper, closePaper, open } = usePaper();
  const { ref: paperRef } = useOnClickOutside(() => closePaper());

  const isSmall = size === 'small';

  useEffect(() => {
    if (!paperRef.current || !selectRef.current) return;

    setPositionStyles(getPaperStyles(paperRef, selectRef, position, withError));
  }, [
    paperRef.current,
    selectRef.current,
    open,
    window.innerHeight,
    window.innerWidth,
    filteredOptions,
  ]);

  useEffect(() => {
    if (!isSearchable) return;

    setFilteredOptions(
      options.filter((op) => op.label.toLowerCase().startsWith(searchText.toLowerCase())),
    );
  }, [searchText, options]);

  useEffect(() => {
    if (open) return;

    setSearchText('');

    if (multiple) {
      onChange(selectedOptions);
    }
  }, [open]);

  useEffect(() => {
    if (!Array.isArray(value) || !multiple) return;
    setSelectedOptions(value);
  }, [multiple, value]);

  const onOptionClick = (e, op) => {
    if (multiple) {
      e.stopPropagation();
      if (selectedOptions.find((o) => o.value === op.value)) {
        setSelectedOptions((prev) => prev.filter((o) => o.value !== op.value));
        return;
      }

      setSelectedOptions((prev) => [...prev, op]);
      return;
    }

    closePaper();
    e.stopPropagation();
    onChange(op);
  };

  const renderOptions = () => {
    if (_.isEmpty(options) || (isSearchable && _.isEmpty(filteredOptions))) {
      return <p className={styles.emptyPlaceholder}>No options</p>;
    }

    const opts = isSearchable ? filteredOptions : options;

    return opts.map((op) => (
      <Option
        key={`${op.label}-${op.value}`}
        option={op}
        isSelected={
          multiple
            ? !!selectedOptions.find((o) => o?.value === op.value)
            : op.value === value?.value
        }
        onClick={(e) => onOptionClick(e, op)}
        showIcon={showOptionIcon}
      />
    ));
  };

  const renderResetButton = () => {
    if (!isClearable || _.isEmpty(value) || disabled) return null;

    return (
      <img
        className={styles.icon}
        src={CommonIcons.closeIcon}
        alt="reset-icon"
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
          onChange(multiple ? [] : null);
        }}
      />
    );
  };

  const renderPaper = () => {
    if (!open) return null;

    return (
      <div
        ref={paperRef}
        id="select-paper"
        className={[
          styles.paper,
          usePrimaryBorder ? 'primary-border' : 'secondary-border',
          useRoundedCorners ? 'border-r-6' : 'border-r-3',
        ].join(' ')}
        style={{
          ...positionStyles,
          maxHeight: paperHeight && `${paperHeight}px`,
          position,
          ...paperStyles,
        }}
      >
        {renderOptions()}
      </div>
    );
  };

  const renderHeader = () => {
    if (!label) return null;

    return (
      <p
        className={[
          styles.label,
          isSmall ? 'font-s-12' : 'font-s-14',
          errorText ? styles.withError : '',
          'font-w-500',
          'm-b-6',
        ].join(' ')}
      >
        {`${label}${required ? '*' : ''}`}
      </p>
    );
  };

  const renderValue = () => {
    if (isSearchable && open) {
      return (
        <Input
          id={id}
          classNames={[styles.inputWrapper]}
          inputClassNames={[styles.input]}
          value={searchText}
          onTextChange={setSearchText}
          withError={false}
          autoFocus={open}
          size={isSmall ? 'small' : 'medium'}
        />
      );
    }

    return (
      <button
        id={id}
        className={[
          styles.value,
          !value ? styles.opacity : '',
          isSmall ? 'font-s-14' : 'font-s-16',
          errorText ? styles.withError : '',
          'font-w-500',
        ].join(' ')}
        type="button"
        onClick={() => {}}
        disabled={disabled}
      >
        {multiple
          ? _.isEmpty(selectedOptions)
            ? placeholder
            : selectedOptions.length > 1
              ? `${selectedOptions[0].label} +${selectedOptions.length - 1}`
              : selectedOptions[0].label
          : value
            ? value.label
            : placeholder}
      </button>
    );
  };

  const renderContent = () => {
    if (readOnly) {
      return (
        <p id={id} className={['font-w-500', isSmall ? 'font-s-14' : 'font-s-18'].join(' ')}>
          {value?.label || emptyReadOnlyText}
        </p>
      );
    }

    return (
      <div
        ref={selectRef}
        className={[
          styles.select,
          disabled ? styles.disabled : '',
          errorText ? styles.withError : '',
          'flex-row',
          usePrimaryBorder ? 'primary-border' : 'secondary-border',
          useRoundedCorners ? 'border-r-6' : 'border-r-3',
          'gap-5',
        ].join(' ')}
        onClick={(e) => {
          if (disabled) return;
          openPaper(e);
        }}
        onMouseDown={() => {
          if (!open) return;
          setTimeout(() => {
            closePaper();
          }, 50);
        }}
        role="presentation"
      >
        {iconSrc && (
          <img
            className={[styles.icon, rotateIcon ? styles.rotate : ''].join(' ')}
            src={iconSrc}
            alt="icon"
          />
        )}
        {prefix && <span className="font-w-500">{prefix}</span>}
        {renderValue()}
        {renderResetButton()}
        <img className={open ? styles.upIcon : ''} src={downArrowIconSrc} alt="arrow-icon" />
      </div>
    );
  };

  const renderError = () => {
    if (!withError) return null;

    const isSmall = size === 'small';
    return (
      <span className={[styles.error, 'font-w-400', isSmall ? 'font-s-10' : 'font-s-12'].join(' ')}>
        {errorText}
      </span>
    );
  };

  return (
    <div className={[styles.container, 'relative', ...classNames].join(' ')}>
      {renderHeader()}
      {renderContent()}
      {renderError()}
      {renderPaper()}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  prefix: PropTypes.string,
  withError: PropTypes.bool,
  iconSrc: PropTypes.string,
  rotateIcon: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  paperHeight: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
  position: PropTypes.oneOf(['absolute', 'fixed']),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downArrowIconSrc: PropTypes.string,
  showOptionIcon: PropTypes.bool,
  usePrimaryBorder: PropTypes.bool,
  useRoundedCorners: PropTypes.bool,
  emptyReadOnlyText: PropTypes.string,
  paperStyles: PropTypes.object,
};

export default Select;
