import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { apiRequest } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import { getData, getLastMeasurement } from 'common-src/models/PatientGlucoseReading/selectors';

import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import {
  BGMPeriodRange,
  TimeOfDayConditions,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';
import { ReadingItem } from 'src/pages/PatientDetails/fragments/Biometrics/items';

import styles from './BGMHeader.module.scss';

const BGMHeader = ({ patient, selectedPeriod }) => {
  const [daysCount, setDaysCount] = useState(null);

  const { recordedAt, value } = useCustomSelector((state) => getLastMeasurement(state, patient.id));
  const { avgValue: avgMorningValue } = useCustomSelector((state) =>
    getData(state, patient.id, selectedPeriod, TimeOfDayConditions.MORNING),
  );
  const { avgValue: avgAfternoonValue } = useCustomSelector((state) =>
    getData(state, patient.id, selectedPeriod, TimeOfDayConditions.AFTERNOON),
  );
  const { avgValue: avgEveningValue } = useCustomSelector((state) =>
    getData(state, patient.id, selectedPeriod, TimeOfDayConditions.EVENING),
  );
  const { avgValue: avgNightValue } = useCustomSelector((state) =>
    getData(state, patient.id, selectedPeriod, TimeOfDayConditions.NIGHT),
  );

  const getDaysCount = () => {
    apiRequest({
      endpoint: 'patientGlucoseReadings/daysCount',
      queryParams: {
        patient_id: patient.id,
        end_time: moment().toISOString(),
        start_time: moment().subtract(BGMPeriodRange[2].value, 'days').toISOString(),
      },
    })
      .then((res) => res.json())
      .then((data) => setDaysCount(data?.count || 0));
  };

  useInterval(() => getDaysCount());

  useEffect(() => {
    getDaysCount();
  }, []);

  const getContent = (value, sign = undefined) => {
    if (!sign) {
      return value ? `${value} mg/dL` : 'N/A';
    }

    return Number.isInteger(value) ? `${sign} ${value} mg/dL` : 'N/A';
  };

  return (
    <div className={[styles.header, 'm-b-40', 'p-l-30', 'gap-24'].join(' ')}>
      <ReadingItem
        id="last-reading"
        title="Last Reading"
        content={getContent(value)}
        addition={recordedAt}
      />
      <ReadingItem
        id="avg-morning-reading"
        title="Morning (3AM-12PM)"
        content={getContent(avgMorningValue)}
      />
      <ReadingItem
        id="avg-afternoon-reading"
        title="Afternoon (12PM-6PM)"
        content={getContent(avgAfternoonValue)}
      />
      <ReadingItem
        id="avg-evening-reading"
        title="Evening (6PM-10PM)"
        content={getContent(avgEveningValue)}
      />
      <ReadingItem
        id="avg-night-reading"
        title="Night (10PM-3AM)"
        content={getContent(avgNightValue)}
      />
      <ReadingItem
        id="high-reading-range"
        title="High Reading Range"
        content={getContent(patient.bgmHyperglycemicThreshold, '>')}
      />
      <ReadingItem
        id="low-reading-range"
        title="Low Reading Range"
        content={getContent(patient.bgmHypoglycemicThreshold, '<')}
      />
      <ReadingItem
        id="days-with-readings"
        title="Days with Readings (Last 30)"
        content={daysCount}
      />
    </div>
  );
};

BGMHeader.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  selectedPeriod: PropTypes.number,
};

export default BGMHeader;
