import moment from 'moment-timezone';

import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getLatestCgmGlucoseReading = (state, patientId) =>
  allModelsSelector(state, 'PatientCgmGlucoseReading', (p) => p.patientId === patientId)?.[0];

export const getPatientCGMReadings = (state, patientId, startDate, endDate) =>
  allModelsSelector(state, 'PatientCgmGlucoseReading', (r) => {
    const recordedAtLocal = moment(r.recordedAtLocal).format('MM/DD/YYYY');
    return (
      r.patientId === patientId &&
      moment(recordedAtLocal).isSameOrAfter(moment(startDate).format('MM/DD/YYYY')) &&
      moment(recordedAtLocal).isSameOrBefore(moment(endDate).format('MM/DD/YYYY'))
    );
  });
