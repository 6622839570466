import _ from 'lodash';

import { getBloodPressureValues, validatePatientVital } from 'common-src/models/PatientVital';
import { cleanedText } from 'common-src/presenters';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePatientVital(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequestBody = (data, patientId) => {
  const { bloodPressureSystolic, bloodPressureDiastolic } = getBloodPressureValues(
    data.bloodPressure,
  );

  return {
    patientId,
    height: data?.height || null,
    weight: data?.weight || null,
    bloodPressureSystolic,
    bloodPressureDiastolic,
    dateObserved: data?.dateObserved || null,
  };
};

const generateBloodPressure = (vital) => {
  const { bloodPressureSystolic, bloodPressureDiastolic } = vital;

  if (!Number.isInteger(bloodPressureSystolic) || !Number.isInteger(bloodPressureDiastolic))
    return '';

  let systolic = '___';
  let diastolic = '___';

  bloodPressureSystolic
    .toString()
    .split('')
    .forEach((d) => (systolic = systolic.replace('_', d)));

  bloodPressureDiastolic
    .toString()
    .split('')
    .forEach((d) => (diastolic = diastolic.replace('_', d)));

  return `${systolic}/${diastolic}`;
};

export const getInitialState = (lastVital) => ({
  height: lastVital.height || '',
  weight: lastVital.weight || '',
  bloodPressure: generateBloodPressure(lastVital),
  dateObserved: lastVital.dateObserved,
});

export const getIsButtonEnabled = (data, errors, lastVital) => {
  if (
    _.isEmpty([data.height, data.weight, cleanedText(data.bloodPressure)].filter((v) => v)) ||
    !data.dateObserved ||
    (lastVital && _.isEqual(data, getInitialState(lastVital))) ||
    Object.values(errors).some((e) => e)
  )
    return false;

  return true;
};
