import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select, TextArea } from 'common-src/components/base';
import {
  addEncounter,
  EncounterStatus,
  EncounterType,
  getPatientEncounter,
  updateEncounter,
  validatePatientEncounter,
} from 'common-src/models/PatientEncounter';
import { validateStartEndDates } from 'common-src/utils/validationUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState, SourceOptions } from './constants';
import styles from './EncounterPopup.module.scss';
import {
  catchErrors,
  extractPatientEncounter,
  getIsButtonEnabled,
  getRequestBody,
} from './helpers';

const EncounterPopup = ({ onClose, encounterId, patientId }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const encounter = useCustomSelector((state) => getPatientEncounter(state, encounterId));

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (_.isEmpty(encounter)) return;
    setData(extractPatientEncounter(encounter));
  }, [encounter?.id]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const callback = {
      successBlock: () => {
        successCallback(`${encounterId ? 'Edited' : 'Added'} Encounter!`);

        if (!shouldClose) {
          setData(initialState);
          return;
        }

        onClose(true);
      },
      errorBlock: (error) => errorCallback(error),
    };

    loadingCallback();
    if (encounterId) {
      dispatch(updateEncounter(encounterId, getRequestBody(data), callback));
      return;
    }

    dispatch(addEncounter(getRequestBody(data, patientId), callback));
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientEncounter(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDateError = (field, dateToValidate, dateToCompare) => {
    const error =
      field === 'startDate'
        ? validateStartEndDates(dateToValidate, dateToCompare)
        : validateStartEndDates(dateToCompare, dateToValidate);

    setErrors((prev) => ({
      ...prev,
      ...validatePatientEncounter(field, dateToValidate),
      ...error,
    }));
  };

  const onDateChangeHandler = (field, dateToValidate, dateToCompare) => {
    handleDateError(field, dateToValidate, dateToCompare);
    setData((prev) => ({ ...prev, [field]: dateToValidate }));
  };

  return (
    <BasePopup
      id="encounter"
      open
      onClose={onClose}
      title={encounterId ? 'Edit Encounter' : 'Add Encounter'}
    >
      <div className={styles.grid}>
        <Select
          id="type"
          label="Type"
          options={Object.values(EncounterType)}
          onChange={(op) => onChangeHandler('type', op)}
          value={data.type}
          required
          placeholder="Select type"
          errorText={errors.type}
          paperHeight={300}
          isSearchable
        />
        <DatePickerNew
          id="start-date"
          header="Start Date"
          required
          value={data.startDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'startDate',
              date ? moment(date).format('YYYY-MM-DD') : null,
              data.endDate,
            )
          }
          onBlur={(date) => handleDateError('startDate', date, data.endDate)}
          errorText={errors.startDate}
        />
        <DatePickerNew
          id="end-date"
          header="End Date"
          value={data.endDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'endDate',
              date ? moment(date).format('YYYY-MM-DD') : null,
              data.startDate,
            )
          }
          onBlur={(date) => handleDateError('endDate', date, data.startDate)}
          errorText={errors.endDate}
        />
        <Select
          id="status"
          label="Status"
          options={Object.values(EncounterStatus)}
          onChange={(op) => onChangeHandler('status', op)}
          value={data.status}
          required
          placeholder="Select status"
          errorText={errors.status}
        />
        <Select
          id="patient-reported"
          label="Source"
          options={SourceOptions}
          onChange={(op) => onChangeHandler('patientReported', op)}
          value={data.patientReported}
          required
          placeholder="Select source"
          errorText={errors.patientReported}
        />
        <Input
          id="provider"
          placeholder="Enter provider"
          label="Provider"
          value={data.provider}
          onTextChange={(value) => onChangeHandler('provider', value)}
          errorText={errors.provider}
        />
        <TextArea
          id="comments"
          classNames={[styles.width100]}
          placeholder="Enter comments"
          value={data.comments}
          onTextChange={(value) => onChangeHandler('comments', value)}
          fixedRows={4}
          label="Comments"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, encounter),
        submitButtonText: 'Save and Close',
        renderSaveButton: !encounterId,
      })}
    </BasePopup>
  );
};

EncounterPopup.propTypes = {
  onClose: PropTypes.func,
  encounterId: PropTypes.string,
  patientId: PropTypes.number,
};

export default EncounterPopup;
