import _ from 'lodash';

import { PlatformStatus } from 'common-src/models/Patient';

import { HasInsulinPump, ReadingFrequencyTypes, RiskLevelTypes, RiskLevelUnset } from './constants';

export const platformStatusPresenter = (platformStatus) => {
  switch (platformStatus) {
    case PlatformStatus.Gray:
      return 'Pending';
    case PlatformStatus.Active:
      return 'Active';
    case PlatformStatus.Hold:
      return 'On Hold';
    case PlatformStatus.Closed:
      return 'Closed';
    default:
      return '';
  }
};

export const platformStatusColor = (platformStatus) => {
  switch (platformStatus) {
    case PlatformStatus.Gray:
      return '#9D97C7';
    case PlatformStatus.Active:
      return '#AEC082';
    case PlatformStatus.Hold:
      return '#F1BF72';
    case PlatformStatus.Closed:
      return '#F06C67';
    default:
      return '#F1BF72';
  }
};

export const platformStatusTitleForPopup = (platformStatus) => {
  switch (platformStatus) {
    case PlatformStatus.Hold:
      return 'This patient is currently on hold';
    case PlatformStatus.Closed:
      return 'This patient’s account has been closed';
    default:
      return '';
  }
};

export const platformStatusDescriptionForPopup = (platformStatus) => {
  if (platformStatus === PlatformStatus.Hold) {
    return `If this patient is currently active, please change their status from the Options Menu > Change status to Active.`;
  }

  return 'If you believe that this was done in error or want to reopen the account, please notify technical support.';
};

export const platformStatusTitleForBanner = (platformStatus) => {
  switch (platformStatus) {
    case PlatformStatus.Hold:
      return 'Patient On Hold';
    case PlatformStatus.Closed:
      return 'Patient account is Closed';
    default:
      return '';
  }
};

export const readingFrequencyPresenter = (readingFrequency) => {
  switch (readingFrequency) {
    case ReadingFrequencyTypes.Minimum.id:
      return ReadingFrequencyTypes.Minimum.text;
    case ReadingFrequencyTypes.Daily.id:
      return ReadingFrequencyTypes.Daily.text;
    case ReadingFrequencyTypes.Extensive.id:
      return ReadingFrequencyTypes.Extensive.text;
    default:
      return '';
  }
};

export const hasInsulinPumpPresenter = (InsulinPump) => {
  switch (InsulinPump) {
    case HasInsulinPump.Yes.id:
      return HasInsulinPump.Yes.text;
    default:
      return HasInsulinPump.No.text;
  }
};

export const riskLevelPresenter = (riskLevel) => {
  switch (riskLevel) {
    case RiskLevelTypes.Low.id:
      return RiskLevelTypes.Low.text;
    case RiskLevelTypes.Medium.id:
      return RiskLevelTypes.Medium.text;
    case RiskLevelTypes.High.id:
      return RiskLevelTypes.High.text;
    case RiskLevelUnset:
      return 'Unset';
    default:
      return '';
  }
};

export const riskLevelColor = (riskLevel) => {
  switch (riskLevel) {
    case RiskLevelTypes.Low.id:
      return '#AEC082';
    case RiskLevelTypes.Medium.id:
      return '#F1BF72';
    case RiskLevelTypes.High.id:
      return '#F06C67';
    case RiskLevelUnset:
    default:
      return '#9D97C7';
  }
};

export const getAccountabilityCoachName = (patient, clients) => {
  if (!patient || !patient.assignedCoachId) return null;

  const client = _.find(clients, (client) => client.id === patient.assignedCoachId);

  if (!client) return null;

  return client.getName();
};

export const getFormattedAddress = (addressValue) => {
  if (!addressValue) return '-';

  const { city, state, street1, street2, zipcode } = addressValue;
  const firstLine = [street1, street2].filter((v) => v).join(', ');
  const secondLine = [city, state, zipcode].filter((v) => v).join(', ');

  return [firstLine, secondLine].join('\n');
};
