export const CGMAuthStatus = Object.freeze({
  NotApplicable: 'Not Applicable',
  Authenticated: 'Authenticated',
  Transmitting: 'Transmitting',
  NotTransmitting: 'Not Transmitting',
  Unauthenticated: 'Unauthenticated',
});

export const CGMManufacturers = Object.freeze({
  Dexcom: {
    value: 'Dexcom',
    label: 'Dexcom',
  },
  FreestyleLibre: {
    value: 'FreestyleLibre',
    label: 'FreestyleLibre',
  },
});

export const CGMProducts = Object.freeze({
  DexcomG6: {
    value: 1,
    label: 'Dexcom G6',
    canBeAddedManually: false,
  },
  DexcomG7: {
    value: 2,
    label: 'Dexcom G7',
    canBeAddedManually: false,
  },
  FreestyleLibre2: {
    value: 3,
    label: 'Freestyle Libre 2',
    canBeAddedManually: false,
  },
  FreestyleLibre3: {
    value: 4,
    label: 'Freestyle Libre 3',
    canBeAddedManually: false,
  },
  DexcomG6Sensors: {
    value: 5,
    label: 'Dexcom G6 Sensors',
    canBeAddedManually: true,
  },
  DexcomG7Sensors: {
    value: 6,
    label: 'Dexcom G7 Sensors',
    canBeAddedManually: true,
  },
  FreestyleLibre2Sensors: {
    value: 7,
    label: 'Freestyle Libre 2 Sensors',
    canBeAddedManually: true,
  },
  FreestyleLibre3Sensors: {
    value: 8,
    label: 'Freestyle Libre 3 Sensors',
    canBeAddedManually: true,
  },
});

export const CGMSuppliers = Object.freeze({
  AdvancedDiabetesSupply: {
    value: 1,
    label: 'Advanced Diabetes Supply',
  },
  CCSMedical: {
    value: 2,
    label: 'CCS Medical',
  },
  Smiles: {
    value: 3,
    label: 'Smiles',
  },
  USMed: {
    value: 4,
    label: 'US Med',
  },
  Quantum: {
    value: 5,
    label: 'Quantum',
  },
});

export const CGMOrderStatus = Object.freeze({
  New: {
    value: 1,
    label: 'New',
  },
  InProgress: {
    value: 2,
    label: 'In Progress',
  },
  Shipped: {
    value: 3,
    label: 'Shipped',
  },
  Delivered: {
    value: 4,
    label: 'Delivered',
  },
  Canceled: {
    value: 5,
    label: 'Canceled',
  },
});
