// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Surgeries-module__button___uiyX0 {
  height: 38px;
  margin: -8px 0 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Surgeries/Surgeries.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,oBAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Surgeries-module__button___uiyX0`
};
module.exports = ___CSS_LOADER_EXPORT___;
