import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, CircleLoader } from 'common-src/components/base';
import { apiRequest, deleteOrmItems, HttpMethods } from 'common-src/features/rest';
import PatientHistoryEvent, {
  getPatientHistoryEventsRequest,
} from 'common-src/models/PatientHistoryEvent';
import { getPatientHistoryEvents } from 'common-src/models/PatientHistoryEvent/selectors';
import { getPrimaryPatientPhoneNumber } from 'common-src/models/PatientPhoneNumber';
import { ColorsNew } from 'common-src/styles';

import Icons from 'src/assets/Icons';
import { RequestHandlerScreen } from 'src/components/base';
import { DateSeparator } from 'src/components/elements';
import { getPatientTimelineTabInfo, savePatientTimelineTabInfo } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import usePagination from 'src/hooks/usePagination';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { BOTTOM_DEVIATION, initialFilters, initialSmsConfig, REQUEST_PAGE_SIZE } from './constants';
import Header from './Header';
import { getFilters, getIsSameDay } from './helpers';
import MessageBubble from './MessageBubble';
import SendSmsInput from './SendSmsInput';
import styles from './TimelineFragment.module.scss';

const TimelineFragment = ({ patientId }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [smsConfig, setSmsConfig] = useState(initialSmsConfig);

  const bottomRef = useRef();
  const listRef = useRef();
  const isEndReached = useRef(false);
  const prevScrollHeight = useRef();
  const shouldScrollToBottom = useRef(false);
  const prevSmsText = useRef('');

  const events = useCustomSelector((state) => getPatientHistoryEvents(state, patientId)) || [];
  const primaryPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patientId),
  );
  const tabInfo = useCustomSelector((state) => getPatientTimelineTabInfo(state, patientId));

  const { isRequesting, errMessage } = useRequestLoading(
    [getPatientHistoryEventsRequest({ patientId, skip: 0, limit: REQUEST_PAGE_SIZE })],
    () => {
      setTimeout(() => {
        if (bottomRef.current) {
          bottomRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });
        }
      }, 50);
    },
    !patientId,
  );

  const sendSmsRequest = () => {
    setSmsConfig({ ...smsConfig, isLoading: true });
    apiRequest({
      endpoint: 'awscChatMessage',
      method: HttpMethods.Post,
      body: {
        patientId,
        outboundPhoneNumber: primaryPhoneNumber?.phoneNumber,
        message: smsConfig.text,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw Error('Error while sending SMS.');
        }

        res.json();
      })
      .then(() => {
        setSmsConfig(initialSmsConfig);
        shouldScrollToBottom.current = true;
        prevSmsText.current = '';
      })
      .catch((error) => setSmsConfig({ ...smsConfig, error: error.message, isLoading: false }));
  };

  const sendHistoryEventsRequest = (
    query,
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    dispatch(
      getPatientHistoryEventsRequest(
        {
          patientId,
          ...getFilters(filters),
          ...query,
        },
        {
          successBlock: (res) => successCallback(res),
          errorBlock: () => errorCallback(),
        },
      ),
    );
  };

  useInterval(() => {
    sendHistoryEventsRequest({ skip: 0, limit: 5, sortOrder: 'desc' }, () => {
      if (!shouldScrollToBottom.current) return;

      if (bottomRef.current) {
        setTimeout(() => {
          if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });
          }
          shouldScrollToBottom.current = false;
        }, 500);
      }
    });
  });

  const loadNextPage = (skip, limit) => {
    if (isEndReached.current || isLoading) return;

    setIsLoading(true);
    sendHistoryEventsRequest(
      { skip: Math.max(skip, events.length), limit },
      (res) => {
        const events = res.PatientHistoryEvent || [];
        isEndReached.current = events.length === 0;
        setIsLoading(false);
      },
      () => setIsLoading(false),
    );
  };

  const { handleScroll, from, setFrom } = usePagination(
    isRequesting ? REQUEST_PAGE_SIZE : events.length,
    REQUEST_PAGE_SIZE,
    loadNextPage,
    false,
    true,
  );

  useEffect(
    () => () => {
      dispatch(savePatientTimelineTabInfo(patientId, { smsText: prevSmsText.current }));
    },
    [],
  );

  useEffect(() => {
    if (!tabInfo?.smsText) return;
    prevSmsText.current = tabInfo.smsText;
    setSmsConfig((prev) => ({ ...prev, text: tabInfo.smsText }));
  }, [JSON.stringify(tabInfo)]);

  useEffect(() => {
    if (isRequesting || isLoading || !listRef.current || from === REQUEST_PAGE_SIZE) return;

    setTimeout(() => {
      listRef.current.scrollTop = listRef.current.scrollHeight - prevScrollHeight.current;
    }, 50);
  }, [isLoading, isRequesting]);

  useEffect(() => {
    prevScrollHeight.current = listRef.current?.scrollHeight;
  }, [listRef.current?.scrollHeight]);

  useEffect(() => {
    if (isRequesting) return;

    isEndReached.current = false;
    setIsLoading(true);
    deleteOrmItems(PatientHistoryEvent.modelName);
    sendHistoryEventsRequest(
      { skip: 0, limit: REQUEST_PAGE_SIZE },
      () => {
        setIsLoading(false);
        setFrom(REQUEST_PAGE_SIZE);
        setTimeout(() => {
          if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });
          }
        }, 50);
      },
      () => setIsLoading(false),
    );
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (_.isEmpty(events)) {
      setIsButtonVisible(false);
    }
  }, [events.length]);

  const renderContent = () => (
    <>
      {events.map((e, i, arr) => (
        <Fragment key={e.id}>
          {((i > 0 && !getIsSameDay(arr[i - 1].createdAt, e.createdAt)) || i === 0) && (
            <DateSeparator
              classNames={[styles.separator, 'm-b-32']}
              date={e.createdAt}
              format="dddd, MMM Do, YYYY"
            />
          )}
          <MessageBubble event={e} />
        </Fragment>
      ))}
    </>
  );

  const renderEmpty = () =>
    !isLoading ? <span className={styles.empty}>No conversation history</span> : null;

  const renderButton = () => {
    if (!isButtonVisible) return null;

    return (
      <Button
        id="jump-back-to-today"
        classNames={[styles.button, 'gap-5']}
        iconSrc={Icons.arrowDownIcon}
        text="Jump back to Today"
        onClick={() => {
          if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });
          }
        }}
        rightIconSrc={Icons.arrowDownIcon}
      />
    );
  };

  if (isRequesting) {
    return (
      <RequestHandlerScreen
        isRequesting
        requestingText="Pulling history events..."
        isErrored={!!errMessage}
      />
    );
  }

  const onScrollHandler = (event) => {
    if (!isButtonVisible && event.target.scrollHeight > event.target.scrollTop + BOTTOM_DEVIATION) {
      setIsButtonVisible(true);
    }
    if (isButtonVisible && event.target.scrollHeight < event.target.scrollTop + BOTTOM_DEVIATION) {
      setIsButtonVisible(false);
    }

    handleScroll(event);
  };

  return (
    <>
      <Header filters={filters} setFilters={setFilters} />
      <section
        className={[styles.container, 'flex-column'].join(' ')}
        onScroll={(event) => onScrollHandler(event)}
        ref={listRef}
      >
        {isLoading && (
          <CircleLoader
            color={ColorsNew.darkGreen}
            classNames={[styles.progress]}
            circleRadius={14}
            strokeWidth={2}
          />
        )}
        {_.isEmpty(events) ? renderEmpty() : renderContent()}
        {renderButton()}
        <div className={styles.bottom} ref={bottomRef} />
        <SendSmsInput
          phoneNumber={primaryPhoneNumber?.phoneNumber}
          text={smsConfig.text}
          onTextChange={(text) => {
            prevSmsText.current = text;
            setSmsConfig({ ...smsConfig, text, error: '' });
          }}
          error={smsConfig.error}
          isLoading={smsConfig.isLoading}
          onSmsSendClick={sendSmsRequest}
        />
      </section>
    </>
  );
};

TimelineFragment.propTypes = {
  patientId: PropTypes.number,
};

export default TimelineFragment;
