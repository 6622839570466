import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { generateBGMTableColumns } from 'src/pages/PatientDetails/fragments/Biometrics/helpers';

import BGMReadingItem from '../BGMReadingItem';
import styles from './TableRow.module.scss';

const TableRow = ({ reading }) => {
  const { effectiveDate, Morning, Afternoon, Evening, Night } = reading;
  const readings = [...Morning, ...Afternoon, ...Evening, ...Night];

  const getWeekday = () => {
    if (effectiveDate === moment().format('YYYY-MM-DD')) {
      return 'Today';
    }

    if (effectiveDate === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
      return 'Yesterday';
    }

    return moment(effectiveDate).format('dddd');
  };

  return (
    <div className={styles.row}>
      <div>
        <p className={[styles.weekday, 'font-w-500', 'font-s-10'].join(' ')}>{getWeekday()}</p>
        <span className={[styles.date, 'font-w-500', 'font-s-14'].join(' ')}>
          {moment(effectiveDate).format('MM/DD/YYYY')}
        </span>
      </div>
      {generateBGMTableColumns().map((time) => {
        const readingsInSameHour = readings.filter((r) => r.hour === moment(time).hour());

        if (_.isEmpty(readingsInSameHour)) return <span key={time} />;

        return (
          <div className="flex-column gap-10" key={time}>
            {readingsInSameHour.map((r) => (
              <BGMReadingItem key={r.id} reading={r} />
            ))}
          </div>
        );
      })}
    </div>
  );
};

TableRow.propTypes = {
  reading: PropTypes.object,
};

export default TableRow;
