import { ProgressStatuses, StageOfChangeStatuses } from 'common-src/models/PatientSmartGoal';

export const TypesConfig = [
  {
    id: ProgressStatuses.DidNotProgress.id,
    label: 'Not Progressing',
    textColor: '#FFFFFF',
    backgroundColor: '#9D97C7',
  },
  {
    id: ProgressStatuses.Progressing.id,
    label: 'Progressing',
    textColor: '#FFFFFF',
    backgroundColor: '#F1BF72',
  },
  {
    id: ProgressStatuses.Achieved.id,
    label: 'Completed',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
  },
  {
    id: ProgressStatuses.Canceled.id,
    label: 'Canceled',
    textColor: '#FFFFFF',
    backgroundColor: '#637768',
  },
];

export const StageOfChangeConfig = [
  {
    id: StageOfChangeStatuses.PreContemplation.id,
    label: 'Pre contemplation',
    textColor: '#375844',
    backgroundColor: '#FBF7F2',
  },
  {
    id: StageOfChangeStatuses.Contemplation.id,
    label: 'Contemplation',
    textColor: '#375844',
    backgroundColor: '#FBF7F2',
  },
  {
    id: StageOfChangeStatuses.Preparation.id,
    label: 'Preparation',
    textColor: '#375844',
    backgroundColor: '#FBF7F2',
  },
  {
    id: StageOfChangeStatuses.Action.id,
    label: 'Action',
    textColor: '#375844',
    backgroundColor: '#FBF7F2',
  },
  {
    id: StageOfChangeStatuses.Maintenance.id,
    label: 'Maintenance',
    textColor: '#375844',
    backgroundColor: '#FBF7F2',
  },
  {
    id: StageOfChangeStatuses.Relapse.id,
    label: 'Relapse',
    textColor: '#375844',
    backgroundColor: '#FBF7F2',
  },
];
