// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PatientDemographics-module__grid___f_sfz {
  display: grid;
  column-gap: 16px;
  margin-bottom: 16px;
  grid-template-columns: repeat(4, minmax(160px, 1fr));
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Profile/forms/PatientDemographics/PatientDemographics.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,oDAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `PatientDemographics-module__grid___f_sfz`
};
module.exports = ___CSS_LOADER_EXPORT___;
