import { ColorsNew } from 'common-src/styles';

import { BGMColorRanges } from 'src/pages/PatientDetails/fragments/Biometrics/constants';

export const getColor = (value) => {
  if (value <= BGMColorRanges.RED.min || value >= BGMColorRanges.RED.max) {
    return ColorsNew.baseRed;
  }
  if (value >= BGMColorRanges.ORANGE.min && value <= BGMColorRanges.ORANGE.max) {
    return ColorsNew.mediumLightOrange;
  }

  return ColorsNew.lightGreen;
};

const MARGIN_BOTTOM = 10;

export const getTooltipStyles = (itemRef, tooltipRef) => {
  const { top: itemTop, width: itemWidth } = itemRef.current.getBoundingClientRect();
  const { width: tooltipWidth, height: tooltipHeight } =
    tooltipRef.current?.getBoundingClientRect() ?? {};

  return {
    top: `${itemTop - tooltipHeight - MARGIN_BOTTOM}px`,
    marginLeft: `-${(tooltipWidth - itemWidth) / 2}px`,
  };
};
