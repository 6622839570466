import * as Sentry from '@sentry/react';
import { all, delay, put, takeEvery } from 'redux-saga/effects';

import { logoutComplete } from 'common-src/features/auth/actions';
import { LOGIN_SUCCESS, LOGOUT } from 'common-src/features/auth/actionTypes';

import { STORAGE_KEY_AUTH_STATUS } from 'src/constants/storageKeys';

function saveAuthInfo({ payload }) {
  Sentry.setTag('auth_user_id', payload.userId);
  localStorage.setItem(STORAGE_KEY_AUTH_STATUS, JSON.stringify(payload));
}

function* handleLogout() {
  localStorage.removeItem(STORAGE_KEY_AUTH_STATUS);

  // delay 1 second before call logout complete action in order to clear all redux states
  yield delay(1000);

  yield put(logoutComplete());
}

export function* authWatcherWeb() {
  yield all([takeEvery(LOGIN_SUCCESS, saveAuthInfo), takeEvery(LOGOUT, handleLogout)]);
}
