import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';

import { getCurrentUserId } from 'common-src/features/auth';
import { setOrganizationsCached } from 'common-src/features/cache';
import { getClientRequest } from 'common-src/models/Client';
import { getOrganizationsRequest } from 'common-src/models/Organization';

import useCustomSelector from 'src/hooks/useCustomSelector';
import { DocumentComponent } from 'src/pages/DocumentationIndex/forms';

const DocumentationIndex = () => {
  const dispatch = useDispatch();
  const { patientId, documentationId } = useParams();

  const clientId = useCustomSelector((state) => getCurrentUserId(state));

  useEffect(() => {
    if (!clientId) return;

    dispatch(
      getOrganizationsRequest({
        successBlock: () => {
          dispatch(setOrganizationsCached(true));
          dispatch(getClientRequest(clientId));
        },
      }),
    );
  }, [clientId]);

  return (
    <Routes>
      <Route path="/">
        <Route
          path="edit"
          element={<DocumentComponent documentationId={documentationId} patientId={+patientId} />}
        />
        <Route
          path="read"
          element={
            <DocumentComponent
              documentationId={documentationId}
              patientId={+patientId}
              isReadOnly
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default DocumentationIndex;
