import { activeStatusesIds } from 'common-src/models/PatientSdoh';

import { SdohTabs } from './constants';

export const getTabInfo = (sdohRecords = []) => {
  const activeSdohRecords = [];
  const resolvedSdohRecords = [];

  [...sdohRecords]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((sdoh) => {
      if (!activeStatusesIds.includes(sdoh.status)) {
        resolvedSdohRecords.push(sdoh);
        return;
      }

      activeSdohRecords.push(sdoh);
    });

  const tabInfo = {
    ACTIVE: {
      text: SdohTabs.ACTIVE.text,
      count: activeSdohRecords.length,
    },
    RESOLVED: {
      text: SdohTabs.RESOLVED.text,
      count: resolvedSdohRecords.length,
    },
    ALL: {
      text: SdohTabs.ALL.text,
      count: sdohRecords.length,
    },
  };

  return {
    tabInfo,
    activeSdohRecords,
    resolvedSdohRecords,
    allSdohRecords: sdohRecords,
  };
};
