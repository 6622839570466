import { PatientTabs } from 'src/features/tabsState/constants';

export const getTabByPath = (path) => {
  if (!path) return null;

  const segments = path.split('/');
  const lastSegment = segments[segments.length - 1];

  return Object.values(PatientTabs).find(
    (tabConfig) => tabConfig.routes.includes(lastSegment) || tabConfig.text === 'Note',
  );
};

export const getTabByText = (text) => Object.values(PatientTabs).find((tab) => tab.text === text);

export const getTabPath = (tab, patientId, docId) => {
  switch (tab) {
    case PatientTabs.Note:
      return `/dashboard-client/patients/details/${patientId}/note/${docId}/edit`;
    default:
      return `/dashboard-client/patients/details/${patientId}/${tab.routes[0]}`;
  }
};
