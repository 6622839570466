import { getBloodPressureValues } from './helpers';

export const validatePatientVital = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'height':
      if (!!value && value <= 0) {
        errMsg = 'Height should be a positive number';
      }
      break;
    case 'weight':
      if (!!value && value <= 0) {
        errMsg = 'Weight should be a positive number';
      }
      break;
    case 'bloodPressure':
      if (value) {
        const { bloodPressureSystolic, bloodPressureDiastolic } = getBloodPressureValues(value);

        if (isNaN(bloodPressureSystolic) || isNaN(bloodPressureDiastolic)) {
          errMsg = 'Please enter a valid blood pressure';
        }
      }
      break;
    case 'dateObserved':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
