import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import {
  EncounterStatus,
  encounterTypePresenter,
  getPatientEncounters,
  getPatientEncountersRequest,
  getStatusById,
  makeEncounterInactive,
} from 'common-src/models/PatientEncounter';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultilineTextItem,
  MultiTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { DefaultTabInfo, EncounterTabs, TableHeaders } from './constants';
import styles from './Encounters.module.scss';
import { getTabInfo } from './helpers';

const Encounters = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(EncounterTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [filteredEncounters, setFilteredEncounters] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const encounters = useCustomSelector((state) => getPatientEncounters(state, patientId));

  const { isRequesting } = useRequestLoading([getPatientEncountersRequest({ patientId })]);

  useInterval(() => {
    dispatch(getPatientEncountersRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, inProgressEncounters, completedEncounters, archivedEncounters } =
      getTabInfo(encounters);
    setTabInfo(tabInfo);

    let encountersToShow;
    switch (selectedTab) {
      case tabs[0]:
        encountersToShow = completedEncounters;
        break;
      case tabs[1]:
        encountersToShow = inProgressEncounters;
        break;
      case tabs[2]:
        encountersToShow = archivedEncounters;
        break;
      default:
        encountersToShow = [];
    }

    setFilteredEncounters(encountersToShow);
  }, [selectedTab, encounters]);

  const showAddEncounterPopup = () => dispatch(openModal(ModalType.ENCOUNTER, { patientId }));

  const showEncounterDetailsPopup = (encounterId) =>
    dispatch(openModal(ModalType.ENCOUNTER_DETAILS, { patientId, encounterId }));

  const getData = () =>
    filteredEncounters.map((encounter) => {
      const isDisabled = encounter.status === EncounterStatus.Archived.value || !actionsAllowed;
      const { id, type, provider, comments, startDate, status } = encounter;
      const currentStatus = getStatusById(status);

      return {
        type: (
          <MultiTextItem
            isActive
            title={encounterTypePresenter(type)}
            subtitle={provider}
            onClick={() => showEncounterDetailsPopup(id)}
          />
        ),
        comments: <MultilineTextItem isActive text={comments || '-'} maxLines={2} />,
        startDate: (
          <TextItem
            isActive
            text={startDate ? moment(startDate).format('MM/DD/YYYY') : '-'}
            value={startDate}
          />
        ),
        status: (
          <StatusItem
            isActive
            withArrow={false}
            text={currentStatus?.label || '-'}
            textColor={currentStatus?.textColor}
            backgroundColor={currentStatus?.backgroundColor}
          />
        ),
        actions: (
          <ActionsRow
            options={[
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.ENCOUNTER, { patientId, encounterId: encounter.id }),
              },
              {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeEncounterInactive(encounter.id, {}),
                showWarning: true,
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling encounters..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="new-encounter-button"
            text="Add Encounter"
            onClick={() => showAddEncounterPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="encounter"
        classNames={[
          'medical-table-new',
          filteredEncounters?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No encounters"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'startDate',
          label: 'Start Date',
          width: '11%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredEncounters}
        selectedTab={selectedTab}
      />
    </>
  );
};

Encounters.propTypes = {
  patientId: PropTypes.number,
  actionsAllowed: PropTypes.bool,
};

export default Encounters;
