import _ from 'lodash';
import moment from 'moment-timezone';

import { moduleTypes } from 'common-src/models/PatientInteraction';

import Icons from 'src/assets/Icons';

import { initialFilters, MessageTypes } from './constants';

export const getDocumentationModuleNames = (moduleIds) => {
  const res = [];
  moduleIds.forEach((id) => {
    const type = Object.values(moduleTypes).find((m) => m.id === id);
    if (type) {
      res.push(type.title);
    }
  });

  return res.join(', ');
};

export const getIsSameDay = (str1, str2) => {
  const date1 = moment(str1).format('YYYY-MM-DD');
  const date2 = moment(str2).format('YYYY-MM-DD');

  return moment(date1).isSame(date2, 'day');
};

export const deviceOrderStatusPresenter = (type) => {
  switch (type) {
    case MessageTypes.DeviceOrdered:
      return 'Ordered';
    case MessageTypes.DeviceShipped:
      return 'Shipped';
    case MessageTypes.DeviceDelivered:
      return 'Delivered';
    default:
      return '';
  }
};

export const getMessageTypeIcon = (type, isOutbound) => {
  switch (type) {
    case MessageTypes.TextMessage:
      return isOutbound ? Icons.outgoingIcon : Icons.incomingIcon;
    case MessageTypes.PlatformStatus:
    case MessageTypes.HoldRequest:
      return Icons.gearIcon;
    case MessageTypes.Appointment:
      return Icons.appointmentIcon;
    case MessageTypes.ClinicalAlert:
    case MessageTypes.CgmUsageAlert:
      return Icons.warningIcon;
    case MessageTypes.Documentation:
      return Icons.documentIcon;
    case MessageTypes.Reminder:
      return isOutbound ? Icons.outgoingIcon : Icons.incomingIcon;
    case MessageTypes.DeviceOrdered:
    case MessageTypes.DeviceShipped:
    case MessageTypes.DeviceDelivered:
      return Icons.shippingIcon;
    case MessageTypes.MissedCall:
    case MessageTypes.PotentialMissedCall:
      return Icons.missedCallIcon;
    case MessageTypes.CompletedCall:
      return Icons.completeCallIcon;
    case MessageTypes.SmartGoalCreated:
    case MessageTypes.SmartGoalAccomplished:
      return Icons.starIcon;
    case MessageTypes.AwscCall:
      return isOutbound ? Icons.outgoingIcon : Icons.incomingIcon;
    default:
      return isOutbound ? Icons.outgoingIcon : Icons.incomingIcon;
  }
};

export const getFilters = (data) => {
  if (_.isEqual(data, initialFilters)) return {};

  const filters = { ...data };
  if (_.isEmpty(data.types)) {
    delete filters.types;
  } else {
    Object.assign(filters, { types: data.types.map((x) => x.value) });
  }

  return filters;
};
