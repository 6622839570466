export const CGMEvaluationFieldIds = Object.freeze({
  startDateContainer: 'start-date-container',
  startDate: 'start-date',
  endDateContainer: 'end-date-container',
  endDate: 'end-date',
  timeBelowRange70: 'time-below-range-70-input',
  timeBelowRange54: 'time-below-range-54-input',
  gv: 'gv-input',
  gmi: 'gmi-input',
  timeInRange: 'time-in-range-input',
  timeAboveRange180: 'time-above-range-180-input',
  timeAboveRange250: 'time-above-range-250-input',
  additionalDetails: 'additional-detail-input',
});
