export const validatePatientDevice = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'deviceId':
      if (!/^[A-Z\d]+$/.test(value)) {
        errMsg = 'Device ID should be not empty and should contain only capital letters or digits';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
