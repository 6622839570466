import { attr, fk, Model } from 'redux-orm';

class ClientTimeLog extends Model {
  static modelName = 'ClientTimeLog';

  static fields = {
    id: attr(),
    timeSpent: attr(),
    category: attr(),
    effectiveDate: attr(),
    interaction: fk('PatientInteraction', 'timeLogs'),
    patient: fk('Patient', 'timeLogs'),
    client: fk('Client', 'timeLogs'),
  };
}

export default ClientTimeLog;
