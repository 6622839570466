import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import PatientLabReading from 'common-src/models/PatientLabReading';
import {
  getLabReadingCategory,
  getLabReadingLabel,
  getLabReadingUnit,
} from 'common-src/models/PatientLabReading/helpers';
import { Colors, ColorsNew } from 'common-src/styles';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { MedicalRecordsTable } from 'src/components/table';
import { openModal } from 'src/features/modals';

import styles from './LabItem.module.scss';

const LabItem = ({ labResult, withMargin, accordionHandler, records = [] }) => {
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);

  const showTrendedResultsPopup = (labReadingType) =>
    dispatch(
      openModal(ModalType.TRENDED_RESULTS, {
        patientId: labResult.patient?.id,
        typeId: labReadingType,
      }),
    );

  const getType = () => {
    if (labResult.patientReported) {
      return getLabReadingLabel(labResult.typeId);
    }

    return getLabReadingCategory(labResult.typeId);
  };

  const getValue = () => {
    if (labResult.patientReported) {
      return `${labResult.readingValue} ${getLabReadingUnit(labResult.typeId)}`;
    }

    return 'Results Received';
  };

  const getDescription = () => {
    if (labResult.patientReported) {
      return labResult.createdBy?.getName(true, true);
    }

    return 'Created by Medical Record Import';
  };

  const renderButton = () => {
    if (labResult.patientReported) {
      return (
        <Button
          id="trended-results-button"
          classNames={[styles.button, 'gap-6']}
          iconSrc={Icons.overviewIcon}
          rightIconSrc={CommonIcons.linkOutIcon}
          text="Trended Results"
          onClick={() => showTrendedResultsPopup(labResult.typeId)}
          textColor={ColorsNew.darkGreen}
          backgroundColor="transparent"
          borderColor={Colors.lightGray}
        />
      );
    }

    return (
      <img
        className={[styles.toggleButton, isExpanded ? '' : styles.collapsed].join(' ')}
        src={CommonIcons.downIcon}
        alt="expand-collapse-btn"
        role="presentation"
        onClick={() => {
          setIsExpanded((prev) => !prev);
          accordionHandler();
        }}
      />
    );
  };

  const renderAccordion = () => {
    if (!isExpanded) return null;

    return (
      <div className={[styles.accordion, 'primary-border'].join(' ')}>
        <MedicalRecordsTable records={records} patientId={labResult.patient?.id} />
      </div>
    );
  };

  if (!labResult.patientReported && _.isEmpty(records)) return null;

  return (
    <>
      <div
        id={`lab-${labResult.id}`}
        className={[
          styles.item,
          'vertically-centered',
          'primary-border',
          withMargin ? 'm-t-15' : '',
          isExpanded ? styles.borderRadius : '',
        ].join(' ')}
      >
        <div className={[styles.content, 'gap-10'].join(' ')}>
          <img className={styles.icon} src={Icons.newLabsIcon} alt="new-labs-icon" />
          <div>
            <p className={[styles.type, 'font-w-500', 'font-s-12'].join(' ')}>{getType()}</p>
            <p className={[styles.value, 'font-w-600', 'font-s-16'].join(' ')}>{getValue()}</p>
            <p className={[styles.description, 'font-w-500', 'font-s-12'].join(' ')}>
              {getDescription()}
            </p>
          </div>
        </div>
        {renderButton()}
      </div>
      {renderAccordion()}
    </>
  );
};

LabItem.propTypes = {
  labResult: PropTypes.exact(PatientLabReading.schema),
  withMargin: PropTypes.bool,
  records: PropTypes.array,
  accordionHandler: PropTypes.func,
};

export default LabItem;
