/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import React from 'react';

import { BoldedText } from 'common-src/components/elements';
import { getRoleConfig } from 'common-src/features/auth';
import { getDateWithTimeZone } from 'common-src/utils/dateUtils';
import { uuidv4 } from 'common-src/utils/stringUtils';

import {
  ALL_DOCUMENT_TYPES,
  DocumentationSort,
  InteractionReason,
  InteractionType,
} from './constants';

export const getSortedDocuments = (docs, areDrafts, isReversed = false) =>
  _.orderBy(
    docs,
    ({ createdAt, savedAt, serviceAt }) => {
      const lastSavedDate = savedAt || createdAt;
      const sortField = areDrafts ? lastSavedDate : serviceAt || lastSavedDate;
      return new Date(sortField);
    },
    [isReversed ? 'desc' : 'asc'],
  );

export const getFilteredDrafts = (drafts, filters, openedDraftId) => {
  let filteredDrafts = [...drafts];

  const moduleType = filters.type?.value;
  if (!!moduleType && moduleType !== ALL_DOCUMENT_TYPES.id) {
    filteredDrafts = filteredDrafts.filter((draft) => draft.moduleTypes?.includes(moduleType));
  }

  if (openedDraftId) {
    filteredDrafts = filteredDrafts.filter((draft) => draft.id !== openedDraftId);
  }

  switch (filters.sort?.value) {
    case DocumentationSort.OLDEST_FIRST: {
      filteredDrafts = getSortedDocuments(filteredDrafts, true, false);
      break;
    }
    case DocumentationSort.NEWEST_FIRST:
    default: {
      filteredDrafts = getSortedDocuments(filteredDrafts, true, true);
      break;
    }
  }

  return filteredDrafts;
};

export const getAllowedDocumentationModules = (state) => {
  const permissions = getRoleConfig(state)?.documentation;
  const res = {};

  for (const [section, modules] of Object.entries(permissions)) {
    res[section] = new Set();

    for (const [key, value] of Object.entries(modules)) {
      if (value) res[section].add(Number(key));
    }
  }

  return res;
};

export const getDocumentationNote = (note, addendums, clients) => {
  if (_.isEmpty(addendums))
    return (
      <BoldedText
        text={note || ''}
        regex={new RegExp(/(---(.*?)---)|(\*\*\*(.*?)\*\*\*)/, 'gi')}
        splitBy={'\n\n'}
      />
    );

  const getClient = (id) => clients?.find((c) => c.id === id);

  let updatedNote = note || '';
  addendums.forEach((addendum) => {
    updatedNote += `\n\n*** Addendum ${getDateWithTimeZone(
      addendum?.serviceAt || addendum?.savedAt || addendum?.createdAt,
      'MM/DD/YYYY hh:mm A z',
    )} (by ${
      addendum?.client?.getName(false, true) ||
      getClient(addendum?.clientId)?.getName(false, true) ||
      'System'
    }) *** \n${addendum.note || ''}`;
  });

  return (
    <BoldedText
      text={updatedNote}
      regex={new RegExp(/(---(.*?)---)|(\*\*\*(.*?)\*\*\*)/, 'gi')}
      splitBy={'\n\n'}
    />
  );
};

export const getType = (typeId) =>
  _.find(Object.values(InteractionType), (type) => type.id === typeId)?.label || 'Unknown type';

export const getReason = (reasonId) =>
  _.find(Object.values(InteractionReason), (reason) => reason.id === reasonId)?.label ||
  'Unknown reason';

/**
 * This is a temporary function in order to help the migration of the existing modules to the new interaction templates
 * NB: does not include conditions logic!
 * @param input
 * @returns {{isCurrent: boolean, moduleType, title, sections: *[]}}
 */
export const transformModuleToTemplate = (input) => {
  const output = {
    moduleType: input.id,
    title: input.title,
    isCurrent: true,
    sections: [],
  };

  input.questions.forEach((question) => {
    const shouldCreateNewSection = !_.isEmpty(question?.instructions);
    const section = shouldCreateNewSection
      ? {
          id: uuidv4(),
          header: question.instructions.title,
          instructions: question?.instructions?.content || null,
          contents: [],
        }
      : output.sections[output.sections.length - 1];

    if (question.question) {
      if (question.responseType === 'boolean') {
        section.contents.push({
          text: question.question,
          type: 'label',
        });
        question.opts.choices.forEach((choice) => {
          section.contents.push({
            id: uuidv4(),
            text: choice,
            type: 'bool',
            trueLabel: question?.opts?.prefix || 'Yes',
            falseLabel: question?.opts?.prefix2 || 'No',
          });
        });
      } else {
        section.contents.push({
          id: uuidv4(),
          text: question.question,
          type: question.responseType === 'textArea' ? 'text' : question.responseType,
          choices: question?.opts?.choices || null,
          prefix: question?.opts?.prefix || null,
          placeholder: question?.opts?.placeholder || null,
          required: question?.opts?.isRequired || false,
        });
      }
    }

    if (shouldCreateNewSection) output.sections.push(section);
  });

  return output;
};

// TODO: uncomment lines below and pass the js object from protocol modules.
// const jsonObject = transformModuleToTemplate({});
// console.log(JSON.stringify(jsonObject, null, 2));
