import { AllergyReaction, AllergySeverity, AllergyType } from './constants';

export const allergyTypePresenter = (type) =>
  Object.values(AllergyType).find((item) => item.value === type)?.label;

export const allergyReactionPresenter = (reaction) =>
  Object.values(AllergyReaction).find((item) => item.value === reaction)?.label;

export const allergySeverityPresenter = (severity) =>
  Object.values(AllergySeverity).find((item) => item.value === severity)?.label;

export const getSeverityById = (severity) =>
  Object.values(AllergySeverity).find((item) => item.value === severity);
