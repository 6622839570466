import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Autocomplete, DatePickerNew, Select, TextArea } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';
import {
  addMedicalProblem,
  editMedicalProblem,
  getPatientMedicalProblem,
  Relation,
} from 'common-src/models/PatientMedicalProblem';

import { InfoItem } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { dxStatusesOptions, initialState } from './constants';
import { applyCodes } from './helpers';
import Icd10CodeOption from './Icd10CodeOption';
import styles from './MedicalProblemPopup.module.scss';

const MedicalProblemPopup = ({
  open,
  onClose,
  id,
  description,
  patientId,
  isDuplicate = false,
}) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState(initialState);
  const [shouldReset, setShouldReset] = useState(false);
  const [isDxDateValid, setIsDxDateValid] = useState(true);

  const medicalProblem = useCustomSelector((state) => getPatientMedicalProblem(state, id));

  const isEdit = useMemo(() => !!id && !isDuplicate, [id, isDuplicate]);

  const { autoFocus, setAutoFocus, renderButtons } = usePopup();

  const editInitialState = {
    diagnosis: { code: medicalProblem?.icd10Code, description },
    status: medicalProblem?.dxStatus,
    dxDate: medicalProblem?.dxDate,
    comments: medicalProblem?.comments || '',
  };

  useEffect(() => {
    if (_.isEmpty(medicalProblem)) return;

    setForm(editInitialState);
  }, [medicalProblem?.id]);

  const onDateSelectedHandler = (date) => {
    const value = date ? moment(date).format('YYYY-MM-DD') : null;
    setForm((prev) => ({ ...prev, dxDate: value }));
  };

  const onSubmit = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const callback = {
      successBlock: () => {
        successCallback(`Diagnosis ${isEdit ? 'edited' : 'added'}!`);

        if (!shouldClose) {
          setForm(initialState);
          setAutoFocus(true);
          setShouldReset(true);
          setTimeout(() => setShouldReset(false), 0);
          return;
        }

        onClose(true);
      },
      errorBlock: (error) => errorCallback(error),
    };

    const payload = {
      patientId,
      icd10Code: form?.diagnosis?.code,
      comments: form?.comments || '',
      dxDate: form.dxDate,
      dxStatus: form.status,
      resolvedDate: null,
      problemType: 1,
      patientRelationship: Relation.Self.id,
    };

    loadingCallback();
    if (isEdit) {
      dispatch(editMedicalProblem(id, { ...payload, id }, callback));
    } else {
      dispatch(addMedicalProblem(payload, callback));
    }
  };

  const getIsButtonEnabled = () =>
    form.diagnosis && isDxDateValid && (isDuplicate || !_.isEqual(form, editInitialState));

  const onClear = () => {
    setForm(initialState);
    setShouldReset(true);
    setTimeout(() => setShouldReset(false), 0);
  };

  return (
    <BasePopup
      id="patient-problem"
      open={open}
      onClose={onClose}
      title={isEdit ? 'Edit Diagnosis' : 'Add Diagnosis'}
    >
      <div className={[styles.container, 'flex-column', 'gap-24'].join(' ')}>
        <Autocomplete
          id="search-icd-10-codes"
          classNames={[styles.autocomplete]}
          label="Search"
          disabled={isEdit}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
          shouldReset={shouldReset}
          initialOptionsCount={44}
          renderOption={(option) => <Icd10CodeOption icd10Code={option} />}
          onOptionSelect={(option) => setForm((prev) => ({ ...prev, diagnosis: option || null }))}
          getRequest={(searchText) =>
            apiRequest({
              endpoint: 'icd10Codes/search',
              queryParams: {
                search: searchText,
              },
            })
          }
          applyCustomOptions={(data) => applyCodes(data)}
          paperStyles={{ width: '430px' }}
        />
        <div className={['vertically-centered', 'gap-6'].join(' ')}>
          <InfoItem
            title="Diagnosis name*"
            content={form?.diagnosis?.description || '-'}
            classNames={[styles.item]}
            textId="diagnosis-name"
          />
          <InfoItem
            title="ICD-10*"
            content={form?.diagnosis?.code || '-'}
            classNames={[styles.item]}
            textId="icd-10"
          />
          <Select
            id="dx-status-select-input"
            classNames={[styles.select]}
            label="Status"
            placeholder="Select"
            options={dxStatusesOptions}
            value={_.find(dxStatusesOptions, { value: form.status }) || null}
            onChange={(value) => setForm((prev) => ({ ...prev, status: value?.value || null }))}
            withMinWidth
          />
        </div>
        <DatePickerNew
          id="dx-date-picker"
          classNames={[styles.dateWrapper, 'm-b-20']}
          header="Dx Date"
          placeholder="Dx Date"
          value={form.dxDate}
          onDateSelected={onDateSelectedHandler}
          tooltipText="The date, or approximate date the user was diagnosed."
          onBlur={(date) => setIsDxDateValid(date !== undefined)}
        />
        <TextArea
          id="text-input"
          placeholder="Enter comments"
          label="Comments"
          value={form?.comments || ''}
          onTextChange={(value) => setForm((prev) => ({ ...prev, comments: value }))}
          fixedRows={4}
        />
        {renderButtons({
          containerClassName: styles.buttonsContainer,
          onClose,
          onSubmit,
          onClear: !isEdit ? onClear : null,
          isSubmitEnabled: getIsButtonEnabled(),
          submitButtonText: 'Save and Close',
          renderSaveButton: !isEdit,
        })}
      </div>
    </BasePopup>
  );
};

MedicalProblemPopup.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number,
  isDuplicate: PropTypes.bool,
};

export default MedicalProblemPopup;
