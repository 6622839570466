import _ from 'lodash';
import { useEffect, useState } from 'react';

import {
  getPatientWellnessPlan,
  WellnessPlanFormFields,
} from 'common-src/models/PatientWellnessPlan';

import useCustomSelector from 'src/hooks/useCustomSelector';

const useWellnessPlan = (patientId) => {
  const [wellnessPlan, setWellnessPlan] = useState({});

  const patientWellnessPlan = useCustomSelector((state) =>
    getPatientWellnessPlan(state, patientId),
  );

  useEffect(() => {
    if (!patientWellnessPlan) return;
    setWellnessPlan(_.pick(patientWellnessPlan, WellnessPlanFormFields));
  }, [patientWellnessPlan]);

  return {
    wellnessPlan,
    wellnessPlanId: patientWellnessPlan?.id,
    wellnessPlanFullModel: patientWellnessPlan,
  };
};

export default useWellnessPlan;
