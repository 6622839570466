import _ from 'lodash';

import { moduleTypes } from './protocolModules';

const capitalizeFirstLetter = (match) => match.toUpperCase();

export const getSectionName = (section) => {
  if (typeof section !== 'string') return '';

  return section.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, capitalizeFirstLetter);
};

export const getModuleNames = (interaction) => {
  if (_.isEmpty(interaction.moduleTypes)) return 'No modules selected';

  const formatter = new Intl.ListFormat();
  const allModules = Object.values(moduleTypes);
  const modules = interaction.moduleTypes.map((moduleType) => {
    const selectedModule = allModules.find((module) => module.id === moduleType);
    return selectedModule?.title || 'Unknown module';
  });

  return formatter.format(modules) || 'No modules selected';
};

export const getModuleNamesByIds = (moduleTypesIds) => {
  if (_.isEmpty(moduleTypesIds)) return [''];

  const allModules = Object.values(moduleTypes);
  return moduleTypesIds.map((type) => {
    const selectedModule = allModules.find((module) => module.id === type);
    return selectedModule?.title || 'Unknown module';
  });
};
