import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Input } from 'common-src/components/base';
import Config from 'common-src/config';
import { loadScript } from 'common-src/utils/scriptUtils';

const AddressInput = ({
  id,
  value,
  label,
  onChange,
  errorText,
  classNames,
  placeholder,
  onTextChange,
  readOnly,
  size,
  required = false,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const loaded = useRef(false);

  const input = document.getElementById(id);
  const options = {
    types: ['address'],
    componentRestrictions: { country: 'us' },
    fields: ['address_components'],
  };

  const handleScriptLoad = () => {
    if (
      input?.tagName === 'INPUT' &&
      typeof window.google !== 'undefined' &&
      typeof window.google.maps !== 'undefined' &&
      typeof window.google.maps.places !== 'undefined'
    ) {
      setAutocomplete(new window.google.maps.places.Autocomplete(input, options));
    }
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`,
        document.querySelector('head'),
        'google-maps',
        handleScriptLoad,
      );
    }

    loaded.current = true;
  }

  const fillInAddress = () => {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    const info = { street1: null, zipcode: null, city: null, state: null };
    let address = '';

    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    const addressComponents = place?.address_components ? place.address_components : [];
    addressComponents.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address = `${component.long_name} ${address}`;
          info.street1 = address;
          break;
        }

        case 'route': {
          address += component.short_name;
          info.street1 = address;
          break;
        }

        case 'postal_code': {
          info.zipcode = component.long_name;
          break;
        }

        case 'locality':
          info.city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          info.state = component.short_name;
          break;
        }

        default: {
          break;
        }
      }
    });

    onChange?.(info);
  };

  useEffect(() => {
    if (
      input?.tagName === 'INPUT' &&
      typeof window.google !== 'undefined' &&
      typeof window.google.maps !== 'undefined' &&
      typeof window.google.maps.places !== 'undefined'
    ) {
      setAutocomplete(new window.google.maps.places.Autocomplete(input, options));
    }
  }, [value]);

  useEffect(() => {
    if (autocomplete) {
      window.google.maps.event.addListener(autocomplete, 'place_changed', fillInAddress);
    }
  }, [autocomplete]);

  return (
    <Input
      id={id}
      classNames={classNames}
      errorText={errorText}
      label={label}
      value={value}
      required={required}
      placeholder={placeholder}
      onTextChange={onTextChange}
      size={size}
      readOnly={readOnly}
    />
  );
};

AddressInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorText: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default AddressInput;
