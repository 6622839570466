import PropTypes from 'prop-types';
import React from 'react';

import styles from './RemoveButton.module.scss';

const RemoveButton = ({ id, onClick, classNames = [] }) => (
  <button
    id={id}
    className={[styles.button, 'font-w-500', 'font-s-10', ...classNames].join(' ')}
    type="button"
    onClick={onClick}
  >
    Remove
  </button>
);

RemoveButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default RemoveButton;
