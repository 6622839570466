export const TasksTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  COMPLETE: {
    text: 'Complete',
  },
  ALL: {
    text: 'All',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: TasksTabs.ACTIVE.text,
    count: 0,
  },
  COMPLETE: {
    text: TasksTabs.COMPLETE.text,
    count: 0,
  },
  ALL: {
    text: TasksTabs.ALL.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'type', label: 'Type', width: '20%', sortBy: 'text' },
  { column: 'reason', label: 'Reason', width: '20%', sortBy: 'text' },
  { column: 'subject', label: 'Subject', width: '25%', sortBy: 'text' },
  { column: 'createdAt', label: 'Created', width: '10%', sortBy: 'value' },
  { column: 'status', label: 'Status', width: '10%', sortBy: 'text' },
  { column: 'actions', label: 'Actions', width: '15%' },
];
