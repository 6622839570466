import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { StatusItem } from 'common-src/components/elements';
import {
  allergyReactionPresenter,
  allergyTypePresenter,
  getPatientAllergies,
  getPatientAllergiesRequest,
  getSeverityById,
  makeAllergyInactive,
} from 'common-src/models/PatientAllergy';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import {
  ActionsRow,
  BaseTable,
  MultiTextItem,
  TablePagination,
  TextItem,
} from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { TabsViewNew } from 'src/components/tabs';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import styles from './Allergies.module.scss';
import { AllergyTabs, DefaultTabInfo, TableHeaders } from './constants';
import { getTabInfo } from './helpers';

const Allergies = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const tabs = Object.keys(AllergyTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredAllergies, setFilteredAllergies] = useState([]);
  const [tabInfo, setTabInfo] = useState(DefaultTabInfo);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const allergies = useCustomSelector((state) => getPatientAllergies(state, patientId));

  const { isRequesting } = useRequestLoading([getPatientAllergiesRequest({ patientId })]);

  useInterval(() => {
    dispatch(getPatientAllergiesRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  useEffect(() => {
    const { tabInfo, activeAllergies, inactiveAllergies } = getTabInfo(allergies);
    setTabInfo(tabInfo);
    setFilteredAllergies(selectedTab === tabs[0] ? activeAllergies : inactiveAllergies);
  }, [selectedTab, allergies]);

  const showAddAllergyPopup = () => {
    dispatch(openModal(ModalType.ALLERGY, { patientId }));
  };

  const showAllergyDetailsPopup = (allergy) => {
    dispatch(openModal(ModalType.ALLERGY_DETAILS, { allergy }));
  };

  const renderSeverity = (severity) => {
    if (!severity) return '-';

    const data = getSeverityById(severity);
    return <StatusItem isActive withArrow={false} text={data.label} backgroundColor={data.color} />;
  };

  const getData = () =>
    filteredAllergies.map((allergy) => {
      const isDisabled =
        (allergy.activeTill && moment(allergy.activeTill).isBefore(moment.now())) ||
        !actionsAllowed;

      return {
        name: (
          <MultiTextItem
            isActive
            title={allergy.name}
            subtitle={allergyTypePresenter(allergy.type)}
            onClick={() => showAllergyDetailsPopup(allergy)}
          />
        ),
        reaction: <TextItem isActive text={allergyReactionPresenter(allergy.reaction)} />,
        createdAt: (
          <TextItem
            isActive
            text={moment(allergy.createdAt).format('MM/DD/YYYY')}
            value={allergy.createdAt}
          />
        ),
        severity: renderSeverity(allergy.severity),
        actions: (
          <ActionsRow
            options={[
              {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled: isDisabled,
                onClick: openModal(ModalType.ALLERGY, { patientId, allergy }),
              },
              {
                id: 'remove-button',
                text: 'Remove',
                backgroundColor: '#F01F51B2',
                color: 'white',
                disabled: isDisabled,
                onClick: makeAllergyInactive(allergy.id, {}),
                showWarning: true,
              },
              isDisabled && {
                id: 'duplicate-button',
                text: 'Duplicate',
                backgroundColor: ColorsNew.baseGreen,
                color: 'white',
                disabled: !actionsAllowed,
                onClick: openModal(ModalType.ALLERGY, { patientId, allergy, isDuplicate: true }),
              },
            ]}
          />
        ),
      };
    });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling allergies..." />;
  }

  return (
    <>
      <TabsViewNew
        classNames={['medical-tabs']}
        options={tabInfo}
        onTabSelected={setSelectedTab}
        rightContent={
          <Button
            id="new-allergy-button"
            text="Add Allergy"
            onClick={() => showAddAllergyPopup()}
            backgroundColor={Colors.white}
            textColor={ColorsNew.darkGreen}
            iconSrc={CommonIcons.plusIcon}
            disabled={!actionsAllowed}
            classNames={[styles.button]}
          />
        }
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="allergy"
        classNames={[
          'medical-table-new',
          filteredAllergies?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No allergies"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'updatedAt',
          label: 'Last Updated',
          width: '13%',
          sortBy: 'value',
        }}
        paginationData={paginationData}
      />
      <TablePagination
        onChangePageHandler={setPaginationData}
        results={filteredAllergies}
        selectedTab={selectedTab}
      />
    </>
  );
};

Allergies.propTypes = {
  patientId: PropTypes.number,
  actionsAllowed: PropTypes.bool,
};

export default Allergies;
