import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Badge } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';

import styles from './LinkItem.module.scss';

const LinkItem = ({
  title,
  iconSrc,
  badgeCount,
  patientId,
  route,
  onClick,
  statusColor,
  isSelected = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      id={`footer-item-${title}`}
      className={[
        styles.container,
        'flex-row',
        'vertically-centered',
        'gap-5',
        'cursor-pointer',
      ].join(' ')}
      role="presentation"
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        }

        navigate(`/dashboard-client/patients/details/${patientId}/${route}`);
      }}
    >
      <img className={styles.icon} src={iconSrc} alt="link-icon" />
      <span className={[styles.title, 'font-s-14'].join(' ')}>{title}</span>
      <Badge
        id={`badge-counter-${title}`}
        count={badgeCount}
        backgroundColor={ColorsNew.mediumDarkRed}
        showZero={false}
      />
      {statusColor && (
        <span id="footer-status" className={styles.dot} style={{ backgroundColor: statusColor }} />
      )}
      {(location?.pathname?.includes(route) || isSelected) && (
        <div id={`${title}-selected`} className={styles.selected} />
      )}
    </div>
  );
};

LinkItem.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  iconSrc: PropTypes.string,
  patientId: PropTypes.number,
  badgeCount: PropTypes.number,
  onClick: PropTypes.func,
  statusColor: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default LinkItem;
