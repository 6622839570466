import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientVitalsRequest = (query, params) =>
  restRequest({
    uri: 'patientVitals',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createVital = (body, params) =>
  restRequest({
    uri: 'patientVitals',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const deleteVital = (id, params = {}) =>
  restRequest({
    uri: `patientVitals/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
