import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import PatientFulfillmentOrder, {
  fulfillmentProducts,
} from 'common-src/models/PatientFulfillmentOrder';
import { getPatientGlucoseReadings } from 'common-src/models/PatientGlucoseReading';
import { getSettingByName, SettingName } from 'common-src/models/Setting';

import { ModalType } from 'src/components/base/ModalGroup';
import { SingleOrderRowNew } from 'src/components/rows';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { hasOrderInProgress, hasPatientTakenEnoughReadings } from './helpers';
import styles from './OrderStripsPopup.module.scss';

const OrderStripsPopup = ({
  patientId,
  onOrderStripsSubmit,
  patientFulfillmentOrders,
  open,
  onClose,
  buttonText,
}) => {
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);

  const patientReadings = useCustomSelector((state) => getPatientGlucoseReadings(state, patientId));
  const transtekSetting = useCustomSelector((state) =>
    getSettingByName(state, SettingName.Transtek),
  );

  const { renderButtons } = usePopup();

  const productsList = useMemo(
    () =>
      Object.entries(transtekSetting?.value?.products || {})
        .filter((value) => value[1]?.allowOrder)
        .map((value) => fulfillmentProducts[value[0]])
        .map((option, key) => ({
          id: option?.id,
          idName: `${key}-quantity`,
          label: option?.lineName,
          maxQuantity: option?.maxQuantity || 1,
          deviceId: option?.deviceId,
        }))
        .sort((a, b) => a.id - b.id),
    [JSON.stringify(transtekSetting)],
  );

  const getValidProductsForOrder = () => _.filter(products, (product) => product?.quantity > 0);

  const isOrderValid = () => !_.isEmpty(getValidProductsForOrder());

  const submitOrder = (loadingCallback) => {
    loadingCallback?.();
    onOrderStripsSubmit(getValidProductsForOrder());
  };

  const showWarningPopup = (message, loadingCallback) => {
    dispatch(
      openModal(ModalType.WARNING, {
        title: 'Are you sure?',
        message,
        onSubmit: () => {
          submitOrder(loadingCallback);
        },
      }),
    );
  };

  const validateAndSubmit = (loadingCallback) => {
    if (isOrderValid()) {
      switch (true) {
        case hasOrderInProgress(patientFulfillmentOrders):
          showWarningPopup(
            'Member has an order currently processing.\nAre you sure you want to submit a new order?',
            loadingCallback,
          );
          break;
        case !hasPatientTakenEnoughReadings(patientReadings):
          showWarningPopup(
            'Are you sure you want to submit this order?\nMember has not taken 16 days of readings in the last 30 days.',
            loadingCallback,
          );
          break;
        default:
          submitOrder(loadingCallback);
      }
    }
  };

  const updateState = (updatedProduct) => {
    const currentValue = products.filter((product) => product?.product === updatedProduct?.product);

    const stateForExtraction = !_.isEmpty(currentValue)
      ? _.filter(products, (p) => p.product !== updatedProduct.product)
      : products;

    setProducts([...stateForExtraction, updatedProduct]);
  };

  const renderProducts = () => (
    <div className={['flex-column', 'p-t-18', 'gap-8', 'm-l-30'].join(' ')}>
      {productsList.map((product) => (
        <SingleOrderRowNew
          id={product.idName}
          key={product.id}
          header={product?.label}
          maxQuantity={product?.maxQuantity}
          onChange={(selection) => {
            updateState({
              product: Number(product?.id),
              quantity: Number(selection?.value || 0),
            });
          }}
          value={products.find((p) => p.product === product.id)}
        />
      ))}
    </div>
  );

  return (
    <BasePopup id="order-devices" open={open} onClose={onClose} title="Order devices">
      {renderProducts()}
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        buttonClassName: styles.button,
        onClose,
        onSubmit: validateAndSubmit,
        submitButtonText: buttonText || 'Order and update supply counts',
        isSubmitEnabled: isOrderValid(),
      })}
    </BasePopup>
  );
};

OrderStripsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOrderStripsSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  patientFulfillmentOrders: PropTypes.arrayOf(PropTypes.exact(PatientFulfillmentOrder.schema)),
};

export default OrderStripsPopup;
