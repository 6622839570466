import { ColorsNew } from 'common-src/styles';

import { FeatureFlagState } from './constants';

export const getFeatureFlagColor = (state) => {
  switch (state) {
    case FeatureFlagState.Disabled:
      return ColorsNew.baseRed;
    case FeatureFlagState.Enabled:
      return ColorsNew.lightGreen;
    case FeatureFlagState.AdminOnly:
      return ColorsNew.mediumLightOrange;
    default:
      return '';
  }
};
