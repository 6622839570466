import {
  errorActionType,
  requestActionType,
  requestMultipleActionType,
  responseReceivedActionType,
} from './actionTypes';

export const restRequest = ({ uri, body, query, method, errorBlock, successBlock }) => ({
  type: requestActionType(uri),
  payload: {
    uri,
    body,
    query,
    method,
    successBlock,
    errorBlock,
  },
});

export const restRequestMultiple = ({ restRequests, successBlock, errorBlock }) => ({
  type: requestMultipleActionType(restRequests.map((x) => x.payload.uri).join('_')),
  payload: {
    restRequests,
    successBlock,
    errorBlock,
  },
});

export const restErrored = (uri, body, errMsg, errorBlock) => ({
  type: errorActionType(uri),
  payload: {
    body,
    errMsg,
    errorBlock,
  },
});

export const restResponseReceived = (uri, method, body, successBlock) => ({
  type: responseReceivedActionType(uri),
  payload: {
    body,
    uri,
    method,
    successBlock,
  },
});
