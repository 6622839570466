import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './BGMSubHeader.module.scss';

const BGMSubHeader = ({ endingDate, selectedPeriod }) => (
  <div
    className={[styles.subHeader, 'p-b-5', 'font-w-500', 'primary-border-b', 'font-s-16'].join(' ')}
  >
    {`${moment(endingDate).subtract(selectedPeriod, 'days').format('MM/DD')}-${moment(
      endingDate,
    ).format('MM/DD/YYYY')}`}
  </div>
);

BGMSubHeader.propTypes = {
  endingDate: PropTypes.string,
  selectedPeriod: PropTypes.number,
};

export default BGMSubHeader;
