import { attr, fk, Model } from 'redux-orm';

class PatientCgmGlucoseReading extends Model {
  static modelName = 'PatientCgmGlucoseReading';

  static fields = {
    id: attr(),
    recordedAt: attr(),
    recordedAtLocal: attr(),
    deviceId: attr(),
    value: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: attr('Client', 'patientCgmGlucoseReadingChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'cgmGlucoseReadings',
    }),
  };
}

export default PatientCgmGlucoseReading;
