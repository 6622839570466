import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import Patient from 'common-src/models/Patient';
import { WellnessPlanDataByField } from 'common-src/models/PatientWellnessPlan';

import Icons from 'src/assets/Icons';
import useWellnessPlan from 'src/hooks/useWellnessPlan';

import AccordionItem from './AccordionItem';
import styles from './WellnessPlan.module.scss';

const WellnessPlan = ({ patient }) => {
  const [isExtended, setIsExtended] = useState(false);

  const { wellnessPlan, wellnessPlanId, wellnessPlanFullModel } = useWellnessPlan(patient.id);

  return (
    <div className={styles.wrapper}>
      <div
        className={[
          styles.header,
          isExtended ? styles.isExtended : '',
          'flex-row',
          'cursor-pointer',
          'primary-border',
          'border-r-12',
          'disable-select',
        ].join(' ')}
        onClick={() => setIsExtended((prev) => !prev)}
        role="presentation"
      >
        <div className="flex-row vertically-centered flex-1 gap-10">
          <img src={Icons.wellnessOutlinedIcon} alt="wellness-icon" />
          <div className="flex-column">
            <span
              id="wellness-accordion-title"
              className={[styles.title, 'font-s-16', 'font-w-700'].join(' ')}
            >
              Wellness Plan
            </span>
            <span
              id="wellness-last-updated"
              className={[styles.subTitle, 'font-s-12', 'font-w-500'].join(' ')}
            >
              {wellnessPlanFullModel?.updatedAt
                ? `${moment(wellnessPlanFullModel.updatedAt).format('MM/DD/YYYY')} updated`
                : ''}
            </span>
          </div>
        </div>
        <img
          className={[styles.arrow, isExtended ? styles.rotate : ''].join(' ')}
          src={CommonIcons.downIcon}
          alt="down-icon"
        />
      </div>
      {isExtended && (
        <div className={[styles.content, 'flex-column', 'primary-border', 'gap-20'].join(' ')}>
          {Object.entries(wellnessPlan).map(([field, value]) => (
            <AccordionItem
              key={field}
              title={WellnessPlanDataByField[field]?.label}
              formField={field}
              value={value}
              wellnessPlanId={wellnessPlanId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

WellnessPlan.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default WellnessPlan;
