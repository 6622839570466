import _ from 'lodash';

import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientCoverageHierarchies = (state, patientId) =>
  _.orderBy(
    allModelsSelector(
      state,
      'PatientCoverageHierarchy',
      (coverageHierarchy) => coverageHierarchy.patientId === patientId,
    ),
    ['updatedAt'],
    ['desc'],
  );
