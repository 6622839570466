import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TextArea } from 'common-src/components/base';
import Patient, { updatePatient, validatePatient } from 'common-src/models/Patient';

import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

const Notes = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        { ...formData },
        {
          successBlock: () => {
            setIsEditMode(false);
            setIsLoading(false);
            showAlert(AlertType.Success, '', 'You have successfully updated administrative notes.');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    formData,
    true,
    'notes',
  );

  useEffect(() => {
    const data = _.pick(patient, ['notes']);
    setFormData(data);
    setInitialData(data);
  }, [patient.notes]);

  if (_.isEmpty(formData)) return null;

  const handleInputChange = (field, value) => {
    handleErrors(validatePatient(field, value));
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <TextArea
        id="notes"
        onTextChange={(value) => handleInputChange('notes', value)}
        value={formData.notes || undefined}
        errorText={errors.notes}
        placeholder="Enter notes"
        maxRows={10}
        readOnly={!isEditMode}
        size="small"
      />
      {renderContent()}
    </>
  );
};

Notes.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Notes;
