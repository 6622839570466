import { createReducer } from 'common-src/utils/reducerUtils';

import { CLEAR_TIMER, SET_TIMER } from './actionTypes';

const initialState = {};

const entityHandlers = {
  [CLEAR_TIMER]: (state, { patientId }) => {
    const stateCopy = JSON.parse(JSON.stringify(state));
    delete stateCopy[patientId];
    return stateCopy;
  },
  [SET_TIMER]: (state, { patientId, timer }) => ({
    ...state,
    [patientId]: {
      isTimerRunning: true,
      timer,
    },
  }),
};

export const reducer = createReducer(initialState, entityHandlers);
