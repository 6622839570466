import moment from 'moment-timezone';

export const validatePatientCgmOrder = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'product':
      if (!value) {
        errMsg = 'Product should not be empty';
      }
      break;
    case 'supplier':
      if (!value) {
        errMsg = 'Supplier should not be empty';
      }
      break;
    case 'status':
      if (!value) {
        errMsg = 'Status should not be empty';
      }
      break;
    case 'notes':
      if (value?.length > 250) {
        errMsg = 'Limit 250 Characters';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};

export const validatePatientCgmOrderDates = (field, value, data) => {
  const dateErrors = {};
  switch (field) {
    case 'orderedAt':
      if (!value) {
        dateErrors.orderedAt = 'Order date should not be empty';
      }

      // check sentAt
      if (data.sentAt) {
        if (moment(data.sentAt).diff(moment(value), 'days') < 0) {
          dateErrors.sentAt = 'Sent date should not be before ordered date';
        }
      }

      // check shippedAt
      if (data.shippedAt) {
        if (moment(data.shippedAt).diff(moment(data.sentAt || value), 'days') < 0) {
          dateErrors.shippedAt = 'Shipped date should not be before sent date and ordered date';
        }
      }
      break;
    case 'sentAt':
      // check sentAt
      if (value && moment(value).diff(moment(data.orderedAt), 'days') < 0) {
        dateErrors.sentAt = 'Sent date should not be before ordered date';
      }

      // check shippedAt
      if (data.shippedAt) {
        if (moment(data.shippedAt).diff(moment(value || data.orderedAt), 'days') < 0) {
          dateErrors.shippedAt = 'Shipped date should not be before sent date and ordered date';
        }
      }
      break;
    case 'shippedAt':
      // check sentAt and orderedAt
      if (data.sentAt && data.orderedAt) {
        if (moment(data.sentAt).diff(moment(data.orderedAt), 'days') < 0) {
          dateErrors.sentAt = 'Sent date should not be before ordered date';
        }
      }

      if (value) {
        if (moment(value).diff(moment(data.orderedAt), 'days') < 0) {
          dateErrors.shippedAt = 'Shipped date should not be before ordered date';
        }

        if (moment(value).diff(moment(data.sentAt), 'days') < 0) {
          dateErrors.shippedAt = 'Shipped date should not be before sent date';
        }
      }
      break;
    default:
      break;
  }

  return dateErrors;
};
