// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DiabeticMonitoring-module__form___ZHxR5 {
  display: grid;
  column-gap: 16px;
  margin-bottom: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}
.DiabeticMonitoring-module__form___ZHxR5 .DiabeticMonitoring-module__width75___AbYE7 {
  grid-column: 2 span;
}
.DiabeticMonitoring-module__form___ZHxR5 .DiabeticMonitoring-module__width100___GyBVa {
  grid-column: 3 span;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Orders/forms/DiabeticMonitoring/DiabeticMonitoring.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,kCAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `DiabeticMonitoring-module__form___ZHxR5`,
	"width75": `DiabeticMonitoring-module__width75___AbYE7`,
	"width100": `DiabeticMonitoring-module__width100___GyBVa`
};
module.exports = ___CSS_LOADER_EXPORT___;
