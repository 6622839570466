import { attr, fk, Model } from 'redux-orm';

class PatientPhoneNumber extends Model {
  static modelName = 'PatientPhoneNumber';

  static fields = {
    id: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: attr(),
    phoneNumber: attr(),
    isPrimary: attr(),
    type: attr(),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientPhoneNumbers',
    }),
  };

  get cleanedPhoneNumber() {
    return this.phoneNumber.substring(2);
  }
}

export default PatientPhoneNumber;
