import _ from 'lodash';

import { validatePatient } from 'common-src/models/Patient';

export const catchErrors = (formData) => {
  const errors = {};

  if (formData.shouldShipToHomeAddress === false) {
    Object.entries(formData.shippingAddress).forEach(([field, value]) => {
      const err = validatePatient(`shippingAddress.${field}`, value);
      if (err[`shippingAddress.${field}`]) {
        Object.assign(errors, {
          [`shippingAddress.${field}`]: err[`shippingAddress.${field}`],
        });
      }
    });
  }

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};
