import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientEncountersRequest = (query, params) =>
  restRequest({
    uri: 'patientEncounters',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addEncounter = (body, params) =>
  restRequest({
    uri: 'patientEncounters',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateEncounter = (id, body, params) =>
  restRequest({
    uri: `patientEncounters/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const makeEncounterInactive = (id, params) =>
  restRequest({
    uri: `patientEncounters/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
