import {
  SET_CURRENT_VERSION,
  SET_IS_RUNNING_LATEST_VERSION,
  SET_SHOULD_SHOW_UPDATE_MODAL,
} from './actionTypes';

export const setCurrentVersion = (currentVersion) => ({
  type: SET_CURRENT_VERSION,
  payload: { currentVersion },
});

export const setIsRunningLatestVersion = (isRunningLatestVersion) => ({
  type: SET_IS_RUNNING_LATEST_VERSION,
  payload: { isRunningLatestVersion },
});

export const setShouldShowUpdateModal = (shouldShowUpdateModal) => ({
  type: SET_SHOULD_SHOW_UPDATE_MODAL,
  payload: { shouldShowUpdateModal },
});
