import _ from 'lodash';

import {
  HasInsulinPump,
  ReadingFrequencyTypes,
  RiskLevelTypes,
  TestingOrdersOptions as testingOrdersOptionsArray,
} from 'common-src/models/Patient/constants';

export const hasInsulinPumpOptions = _.map(HasInsulinPump, (option) => ({
  value: option.id,
  label: option.text,
}));

export const riskLevelOptions = _.map(RiskLevelTypes, (option) => ({
  value: option.id,
  label: option.text,
}));

export const readingFrequencyOptions = _.map(ReadingFrequencyTypes, (option) => ({
  value: option.id,
  label: option.text,
}));

export const testingOrdersOptions = _.map(testingOrdersOptionsArray, (option) => ({
  value: option,
  label: option,
}));
