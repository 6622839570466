import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select } from 'common-src/components/base';
import Patient, { updatePatient, validatePatient } from 'common-src/models/Patient';

import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

import { sexOptions } from './constants';
import styles from './PatientDemographics.module.scss';

const PatientDemographics = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        { ...formData },
        {
          successBlock: () => {
            setIsLoading(false);
            setIsEditMode(false);
            showAlert(
              AlertType.Success,
              '',
              'You have successfully updated demographics information.',
            );
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const {
    isEditMode,
    renderContent,
    setInitialData,
    setIsLoading,
    setIsEditMode,
    errors,
    handleErrors,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    formData,
    true,
    'demographics',
  );

  useEffect(() => {
    const data = {
      firstName: patient.firstName,
      middleName: patient.middleName,
      lastName: patient.lastName,
      mbiNumber: patient.mbiNumber,
      dob: patient.dob,
      sex: patient.sex,
    };
    setInitialData(data);
    setFormData(data);
  }, [patient]);

  const handleInputChange = (field, value) => {
    const err = validatePatient(field, value);
    handleErrors({
      [field]: err[field],
    });

    setFormData((prev) => ({
      ...prev,
      ..._.set(prev, field, value === null ? null : value),
    }));
  };

  const renderForm = () => (
    <div className={styles.grid}>
      <Input
        id="first-name"
        label="First name"
        errorText={errors.firstName}
        onTextChange={(value) => handleInputChange('firstName', value)}
        value={formData?.firstName}
        placeholder="Enter first name"
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        id="middle-name"
        label="Middle name"
        onTextChange={(value) => handleInputChange('middleName', value)}
        value={formData?.middleName}
        placeholder="Enter middle name"
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        id="last-name"
        label="Last name"
        errorText={errors.lastName}
        onTextChange={(value) => handleInputChange('lastName', value)}
        value={formData?.lastName}
        placeholder="Enter last name"
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        id="mbi"
        label="MBI Number"
        errorText={errors.mbiNumber}
        onTextChange={(value) => handleInputChange('mbiNumber', value)}
        value={formData?.mbiNumber}
        placeholder="Enter medicare number"
        readOnly={!isEditMode}
        size="small"
      />
      <DatePickerNew
        id="date-of-birth"
        errorText={errors.dob}
        minDate={undefined}
        maxDate={new Date()}
        placeholder="Choose date of birth"
        header="Date of Birth"
        value={formData?.dob || null}
        onDateSelected={(date) =>
          handleInputChange('dob', date ? moment(date).format('YYYY-MM-DD') : null)
        }
        onBlur={(date) => {
          if (!date) {
            handleErrors({ dob: 'Invalid date format' });
            return;
          }
          handleErrors({ dob: '' });
        }}
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Select
        id="sex"
        errorText={errors.sex}
        label="Sex at birth"
        placeholder="Select sex"
        options={sexOptions}
        onChange={(selection) => handleInputChange('sex', selection.value)}
        value={sexOptions.find((op) => op.value === formData?.sex)}
        required
        readOnly={!isEditMode}
        size="small"
      />
    </div>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

PatientDemographics.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default PatientDemographics;
