import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Select } from 'common-src/components/base';

import styles from './SingleOrderRowNew.module.scss';

const SingleOrderRowNew = ({ id, maxQuantity, onChange, value, header = '' }) => {
  const getQuantityOptions = () => {
    const quantities = Array.from({ length: maxQuantity }, (_, i) => i + 1);
    return _.map(quantities, (item) => ({
      label: `Quantity: ${item.toString()}`,
      value: item.toString(),
    }));
  };

  return (
    <div id="single-order-row" className={[styles.row, 'p-r-30'].join(' ')}>
      <div className={[styles.textContainer, 'flex-column'].join(' ')}>
        <span className={['font-w-500', 'font-s-16'].join(' ')}>{header}</span>
      </div>
      <Select
        id={id}
        classNames={[styles.select]}
        placeholder="Quantity: 0"
        options={getQuantityOptions()}
        onChange={onChange}
        position="absolute"
        withError={false}
        value={
          value
            ? {
                label: `Quantity: ${value.quantity}`,
                value: value.product,
              }
            : null
        }
      />
    </div>
  );
};

SingleOrderRowNew.propTypes = {
  id: PropTypes.string,
  header: PropTypes.string,
  maxQuantity: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default SingleOrderRowNew;
