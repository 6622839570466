import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientSmartGoalsRequest = (query, params) =>
  restRequest({
    uri: 'patientSmartGoals',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createSmartGoal = (body, params) =>
  restRequest({
    uri: 'patientSmartGoals',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateSmartGoal = (body, params) =>
  restRequest({
    uri: `patientSmartGoals/${body.id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const changeStatus = (body, params) => {
  const {
    id,
    startDate,
    followUpDate,
    goal,
    isActive,
    isPatientOriginated,
    progressStatus,
    stageOfChangeStatus,
    typeIds,
    patientId,
  } = body;

  return updateSmartGoal(
    {
      id,
      startDate,
      followUpDate,
      goal,
      isActive,
      isPatientOriginated,
      progressStatus,
      stageOfChangeStatus,
      typeIds,
      patientId,
    },
    params,
  );
};

export const deleteSmartGoal = (id, params) =>
  restRequest({
    uri: `patientSmartGoals/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
