export const CGMManufacturers = Object.freeze({
  Dexcom: {
    value: 'Dexcom',
    label: 'Dexcom',
  },
  FreestyleLibre: {
    value: 'FreestyleLibre',
    label: 'FreestyleLibre',
  },
});
