import PropTypes from 'prop-types';
import React from 'react';

import { Input, Select } from 'common-src/components/base';
import { TimeLogTypeOptions } from 'common-src/models/ClientTimeLog';

import { RemoveButton } from 'src/components/buttons';

const TimeLogItem = ({ index, timeLog, onTimeLogChange, categoriesToExclude }) => {
  const { timeSpent, category } = timeLog;
  const moreThanAnHour = timeSpent > 60;
  const id = `time-log-${index}`;

  return (
    <div className="col-90 flex-row m-t-16 gap-30">
      <Input
        id={`${id}-input-time`}
        classNames={['flex-1']}
        warningText={moreThanAnHour ? 'Warning: Please review time log for accuracy' : ''}
        label="Time Spent (minutes)"
        placeholder="Enter time in minutes"
        value={timeSpent}
        onTextChange={(value) => onTimeLogChange(index, 'timeSpent', value)}
        type="number"
        minValue={1}
        required
      />
      <Select
        id={`${id}-select-type`}
        classNames={['flex-1']}
        label="Interaction Type"
        options={TimeLogTypeOptions.filter((option) => !categoriesToExclude.includes(option.value))}
        onChange={(op) => onTimeLogChange(index, 'category', op.value)}
        value={Object.values(TimeLogTypeOptions).find((op) => op.value === category) || null}
        placeholder="Select type"
        required
        paperHeight={300}
        position="absolute"
      />
      <RemoveButton id={`${id}-remove-button`} onClick={() => onTimeLogChange(index)} />
    </div>
  );
};

TimeLogItem.propTypes = {
  index: PropTypes.number,
  timeLog: PropTypes.object,
  onTimeLogChange: PropTypes.func,
  categoriesToExclude: PropTypes.arrayOf(PropTypes.string),
};

export default TimeLogItem;
