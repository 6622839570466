import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, RequestHandlerScreen } from 'common-src/components/base';
import { deleteOrmItem } from 'common-src/features/rest';
import PatientVital, {
  calculateBMI,
  deleteVital,
  getPatientVitals,
  getPatientVitalsRequest,
} from 'common-src/models/PatientVital';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsRow, BaseTable, TablePagination, TextItem } from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { TableHeaders } from './constants';
import { getVitalValue, getWeightChange } from './helpers';
import { BMI } from './items';
import styles from './Vitals.module.scss';

const Vitals = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const vitals = useCustomSelector((state) => getPatientVitals(state, patientId));

  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  useInterval(() => {
    dispatch(getPatientVitalsRequest({ patientId, sort_order: 'desc', limit: 5 }, {}));
  });

  const { isRequesting } = useRequestLoading([getPatientVitalsRequest({ patientId })]);

  const showAddVitalsPopup = () => dispatch(openModal(ModalType.VITALS, { patientId }));

  const getData = () =>
    vitals.map((vital, i) => ({
      readingDate: (
        <TextItem
          isActive
          text={moment(vital.dateObserved || vital.createdAt).format('MM/DD/YYYY')}
          value={vital.dateObserved || vital.createdAt}
        />
      ),
      height: (
        <TextItem isActive text={getVitalValue(vital.height)} value={getVitalValue(vital.height)} />
      ),
      weight: (
        <TextItem
          isActive
          text={getVitalValue(vital.weight)}
          value={getVitalValue(vital.weight)}
          addition={getWeightChange(vitals, vital, i)}
        />
      ),
      bloodPressure: (
        <TextItem
          isActive
          text={
            vital.bloodPressureSystolic
              ? `${vital.bloodPressureSystolic} / ${vital.bloodPressureDiastolic}`
              : '-'
          }
          value={vital.bloodPressureSystolic}
        />
      ),
      bmi: <BMI vital={vital} value={calculateBMI(vital?.weight, vital?.height)} />,
      actions: (
        <ActionsRow
          options={[
            {
              id: 'remove-button',
              text: 'Remove',
              backgroundColor: '#F01F51B2',
              color: 'white',
              onClick: deleteVital(vital.id, {
                successBlock: () => deleteOrmItem(PatientVital.modelName, vital.id),
              }),
              showWarning: true,
            },
          ]}
        />
      ),
    }));

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling vitals..." />;
  }

  return (
    <>
      <Button
        id="new-vitals-button"
        text="Add Vitals"
        onClick={() => showAddVitalsPopup()}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
        iconSrc={CommonIcons.plusIcon}
        disabled={!actionsAllowed}
        classNames={[styles.button]}
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="vitals"
        classNames={[
          'medical-table-new',
          vitals?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No vitals"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{ column: 'readingDate', label: 'Date', width: '15%', sortBy: 'value' }}
        paginationData={paginationData}
        trClassNames={[styles.tr]}
      />
      <TablePagination onChangePageHandler={setPaginationData} results={vitals} />
    </>
  );
};

Vitals.propTypes = {
  patientId: PropTypes.number.isRequired,
  actionsAllowed: PropTypes.bool,
};

export default Vitals;
