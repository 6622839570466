import PropTypes from 'prop-types';
import React from 'react';

import styles from './TableEmptyView.module.scss';

const TableEmptyView = ({ text = 'No data', classNames = [] }) => (
  <tbody className="primary-border-b">
    <tr>
      <td id="table-empty-view" className={[styles.text, 'font-s-14', ...classNames].join(' ')}>
        {text}
      </td>
    </tr>
  </tbody>
);

TableEmptyView.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

export default TableEmptyView;
