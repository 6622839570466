import _ from 'lodash';

import { validatePatientEmergencyContact } from 'common-src/models/PatientEmergencyContact';
import { getEmergencyContactRequests } from 'common-src/models/PatientEmergencyContact/helpers';

export const extractEmergencyContact = (contact) => ({
  id: contact.id,
  name: contact.name,
  relationship: contact.relationship,
  phone: contact.phone,
});

export const resetEmergencyContactErrors = (emergencyContacts, emergencyContact) => {
  const index = _.indexOf(emergencyContacts, emergencyContact);
  return {
    [`${index}.name`]: '',
    [`${index}.relationship`]: '',
    [`${index}.phone`]: '',
  };
};

export const getInitialEmergencyContactData = (patientId) => ({
  patientId,
  phone: '',
  name: '',
  relationship: '',
});

export const catchErrors = (formData) => {
  const errors = {};
  formData.forEach((c, i) => {
    if (!c.patientId) return;
    Object.entries(c).forEach(([field, value]) => {
      const err = validatePatientEmergencyContact(field, value);
      if (err[field]) {
        Object.assign(errors, { [`${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequests = (formData, emergencyContacts) => {
  const requests = [];

  const oldContacts = emergencyContacts.map((contact) => extractEmergencyContact(contact));
  if (!_.isEqual(formData, oldContacts)) {
    requests.push(...getEmergencyContactRequests(oldContacts, formData));
  }

  return requests;
};
