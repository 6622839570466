import _ from 'lodash';

import { getChangedValues } from 'common-src/utils/objectUtils';

import { excludedFields } from './constants';

export const getIsButtonEnabled = (smartGoalToEdit, smartGoal, errors) => {
  if (!_.isEmpty(Object.values(errors).filter((err) => err)) || _.isEmpty(smartGoal.typeIds)) {
    return false;
  }

  if (!smartGoalToEdit) {
    return !Object.values(smartGoal).some((value) => [null, undefined, ''].includes(value));
  }

  return !_.isEmpty(getChangedValues(smartGoalToEdit, smartGoal, excludedFields));
};
