import { all, fork } from 'redux-saga/effects';

import { authWatcher } from 'common-src/features/auth/sagas';
import { restWatcher } from 'common-src/features/rest';

import { authWatcherWeb } from 'src/features/auth/sagas';

export default function* root() {
  yield all([fork(authWatcher), fork(authWatcherWeb), fork(restWatcher)]);
}
