import { roles } from 'common-src/models/Client';

export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: null,
  npi: '',
  credentials: '',
  isSuper: false,
};

export const roleOptions = Object.values(roles)
  .filter((role) => role.id !== roles.System.id)
  .map((role) => ({
    value: role.id,
    label: role.label,
  }));

export const superAdminOptions = Object.values(['No', 'Yes']).map((option) => ({
  value: option === 'Yes',
  label: option,
}));
