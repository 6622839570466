import { attr, fk, Model } from 'redux-orm';

class PatientCoverage extends Model {
  static modelName = 'PatientCoverage';

  static fields = {
    id: attr(),
    payorId: attr(),
    payorName: attr(),
    memberId: attr(),
    payorType: attr(),
    status: attr(),
    source: attr(),
    startDate: attr(),
    endDate: attr(),
    contractId: attr(),
    planId: attr(),
    planName: attr(),
    planType: attr(),
    subscriberRelationship: attr(),
    subscriberFirstName: attr(),
    subscriberLastName: attr(),
    subscriberDateOfBirth: attr(),
    updatedAt: attr(),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'coverages',
    }),
  };

  get name() {
    return this.planName || this.payorName || this.payorId;
  }
}

export default PatientCoverage;
