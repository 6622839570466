import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox, DatePickerNew, Select, TextArea } from 'common-src/components/base';
import {
  CREATABLE_TASK_REASONS,
  CREATABLE_TASK_TYPES,
  CURIE_AI_REASONS,
  PatientTaskStatus,
  TASK_REASON_MAPPING,
  validatePatientTask,
} from 'common-src/models/PatientTask';
import { createPatientTask } from 'common-src/models/PatientTask/actions';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './AddTaskPopup.module.scss';
import { initialState } from './constants';
import { getIsButtonEnabled } from './helpers';

const AddTaskPopup = ({ patientId, onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  // memoized variables
  const typeOptions = useMemo(() => {
    if (!data.reason) return CREATABLE_TASK_TYPES;

    const keys = Object.entries(TASK_REASON_MAPPING)
      .filter(([, values]) => values.includes(data.reason?.value))
      .map(([key]) => key);

    return CREATABLE_TASK_TYPES.filter((taskType) => keys.includes(String(taskType?.value)));
  }, [data.reason]);

  const reasonOptions = useMemo(() => {
    if (!data.type) return CREATABLE_TASK_REASONS;

    return CREATABLE_TASK_REASONS.filter((taskReason) =>
      TASK_REASON_MAPPING[String(data.type?.value)].includes(taskReason.value),
    );
  }, [data.type]);

  const { errors, setErrors, renderButtons } = usePopup();

  // functions
  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();
    dispatch(
      createPatientTask(
        {
          patientId,
          typeId: data.type.value,
          reasonId: data.reason.value,
          subject: data.subject,
          status: data.isSchedule ? PatientTaskStatus.Scheduled.value : PatientTaskStatus.New.value,
          scheduledFor: data.isSchedule ? moment(data.scheduledFor).utc().toISOString() : null,
          curie: CURIE_AI_REASONS.includes(data.reason.value),
        },
        {
          successBlock: () => {
            successCallback('Task created!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  return (
    <BasePopup id="add-task" open onClose={onClose} title="Add task">
      <div className={styles.wrapper}>
        <Select
          id="type-select"
          label="Type"
          placeholder="Select type"
          options={typeOptions}
          onChange={(type) => setData((prev) => ({ ...prev, type }))}
          value={_.find(typeOptions, { value: data.type?.value })}
          required
          isClearable
          isSearchable
        />
        <Select
          id="reason-select"
          label="Reason"
          placeholder="Select reason"
          options={reasonOptions}
          onChange={(reason) => setData((prev) => ({ ...prev, reason }))}
          value={_.find(reasonOptions, { value: data.reason?.value })}
          required
          isClearable
          isSearchable
        />
        <TextArea
          id="subject-input"
          label="Subject"
          placeholder="Enter subject"
          value={data.subject}
          onTextChange={(subject) => setData((prev) => ({ ...prev, subject }))}
          fixedRows={4}
        />
        <Checkbox
          id="schedule"
          label="Schedule"
          checked={data.isSchedule}
          onChange={(isSchedule) => setData((prev) => ({ ...prev, isSchedule }))}
        />
        {data.isSchedule && (
          <DatePickerNew
            id="scheduled-for"
            classNames={['m-t-20', 'width-50']}
            header="Select date and time"
            withTime
            required
            minDate={new Date()}
            value={data.scheduledFor}
            onDateSelected={(date) => {
              setData((prev) => ({
                ...prev,
                scheduledFor: date ? moment(date).format('YYYY-MM-DD HH:mm') : null,
              }));
              setErrors((prev) => ({ ...prev, ...validatePatientTask('scheduledFor', date) }));
            }}
            onBlur={(date) => {
              setData((prev) => ({
                ...prev,
                scheduledFor: date ? moment(date).format('YYYY-MM-DD HH:mm') : null,
              }));
              setErrors((prev) => ({ ...prev, ...validatePatientTask('scheduledFor', date) }));
            }}
            errorText={errors.scheduledFor}
          />
        )}
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, reasonOptions, errors),
        submitButtonText: 'Save and close',
      })}
    </BasePopup>
  );
};

AddTaskPopup.propTypes = {
  patientId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default AddTaskPopup;
