import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientVital extends Model {
  static modelName = 'PatientVital';

  static fields = {
    id: attr(),
    height: attr(),
    weight: attr(),
    bloodPressureSystolic: attr(),
    bloodPressureDiastolic: attr(),
    dateObserved: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'patientVitalChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientVitals',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      height: PropTypes.number,
      weight: PropTypes.number,
      bloodPressureSystolic: PropTypes.number,
      bloodPressureDiastolic: PropTypes.number,
      dateObserved: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientVital;
