// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vitals-module__button___r641X {
  display: flex;
  margin: 16px 20px;
  height: 38px;
  width: 150px;
  align-self: end;
}

.Vitals-module__tr___liAED {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Vitals/Vitals.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,4BAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Vitals-module__button___r641X`,
	"tr": `Vitals-module__tr___liAED`
};
module.exports = ___CSS_LOADER_EXPORT___;
