import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientAllergy extends Model {
  static modelName = 'PatientAllergy';

  static fields = {
    id: attr(),
    name: attr(),
    activeTill: attr(),
    type: attr(),
    reaction: attr(),
    severity: attr(),
    startDate: attr(),
    endDate: attr(),
    comments: attr(),
    updatedAt: attr(),
    createdAt: attr(),
    updatedBy: fk('Client', 'allergyChanger'),
    createdBy: fk('Client', 'allergyCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'allergies',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
      activeTill: PropTypes.string,
      type: PropTypes.number,
      reaction: PropTypes.number,
      severity: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      comments: PropTypes.string,
      updatedAt: PropTypes.string,
      createdAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientAllergy;
