import { attr, fk, Model } from 'redux-orm';

class SdohCode extends Model {
  static modelName = 'SdohCode';

  static fields = {
    id: attr(),
    icd10Code: attr(),
    name: attr(),
    deletedAt: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'sdohCodeCreator'),
    updatedBy: fk('Client', 'sdohCodeUpdater'),
  };
}

export default SdohCode;
