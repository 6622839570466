import PropTypes from 'prop-types';
import React from 'react';

const BoldedText = ({ text, regex, classNames = [], splitBy = ' ' }) => {
  const words = text.split(splitBy);

  const textComponents = words.map((word, i) => {
    if (word.match(regex)) {
      return <b key={i}>{word + splitBy}</b>;
    }

    return <span key={i}>{word + splitBy}</span>;
  });

  return <p className={classNames.join(' ')}>{textComponents}</p>;
};

BoldedText.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  splitBy: PropTypes.string,
  regex: PropTypes.instanceOf(RegExp),
};

export default BoldedText;
