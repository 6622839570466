import ReactDOMServer from 'react-dom/server';

const regex = /(auto|scroll)/;

const getStyle = (node, prop) => getComputedStyle(node, null).getPropertyValue(prop);

const hasScroll = (node) =>
  regex.test(
    getStyle(node, 'overflow') + getStyle(node, 'overflow-y') + getStyle(node, 'overflow-x'),
  );

export const getScrollableParent = (node) =>
  !node || node === document.body
    ? document.body
    : hasScroll(node)
      ? node
      : getScrollableParent(node.parentNode);

export const convertJSXToHTML = (reactComponent) => {
  const htmlString = ReactDOMServer.renderToStaticMarkup(reactComponent);
  const output = document.createElement('div');

  output.innerHTML = htmlString;

  return output.firstChild;
};
