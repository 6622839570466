import moment from 'moment-timezone';

export const validatePatientTask = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'scheduledFor':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (moment(value).format('YYYY-MM-DD HH:mm') < moment().format('YYYY-MM-DD HH:mm')) {
        errMsg = 'Date should not be in the past';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
