import PropTypes from 'prop-types';
import React from 'react';

import { Badge } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';

import styles from './TabNew.module.scss';

const TabNew = ({ text, count, onClick, selected = false, showZero = false, disabled = false }) => {
  const textClassNames = [styles.text, 'font-s-16'];
  const lineClassNames = [styles.line];
  if (selected) {
    textClassNames.push(styles.selected);
    lineClassNames.push(styles.visible);
  }

  if (count) {
    lineClassNames.push(styles.withBadge);
  }

  return (
    <button id={`tab-${text?.toLowerCase()}`} type="button" onClick={onClick} disabled={disabled}>
      <div className={styles.tabContainer}>
        <p className="vertically-centered">
          <span className={textClassNames.join(' ')}>{text}</span>
          {(!!count || showZero) && (
            <Badge
              id={`${text?.toLowerCase()}-counter`}
              classNames={['m-l-6']}
              count={count}
              backgroundColor={selected ? ColorsNew.darkGreen : ColorsNew.mediumDarkGreen}
              showZero={showZero}
            />
          )}
        </p>
        <p className={lineClassNames.join(' ')} />
      </div>
    </button>
  );
};

TabNew.propTypes = {
  text: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  count: PropTypes.number,
  showZero: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TabNew;
