import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input } from 'common-src/components/base';
import Config from 'common-src/config';
import { UserCategory } from 'common-src/constants/user';
import { getLoginError, googleSsoRequest, loginRequest } from 'common-src/features/auth';
import { Colors, ColorsNew } from 'common-src/styles';
import { loadScript } from 'common-src/utils/scriptUtils';
import { isEmailValid } from 'common-src/utils/validationUtils';

import { getIsRunningLatestVersion, VersionUpdateMessage } from 'src/features/appVersion';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const dispatch = useDispatch();

  const loginError = useCustomSelector((state) => getLoginError(state));
  const isRunningLatestVersion = useCustomSelector((state) => getIsRunningLatestVersion(state));

  const error = useMemo(
    () => (!isRunningLatestVersion ? VersionUpdateMessage : loginError),
    [loginError, isRunningLatestVersion],
  );

  const googleApiRef = useRef(false);
  const googleScriptRef = useRef(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const shouldRenderInputs = ['production', 'staging', 'test4'].includes(Config.NODE_ENV);

  const handleGoogleResponse = (response) => {
    dispatch(
      googleSsoRequest({
        token: response.credential,
      }),
    );
  };

  const renderGoogleUi = () => {
    google.accounts.id.renderButton(document.getElementById('google-sign-in'), {
      theme: 'outline',
      size: 'large',
    });

    google.accounts.id.prompt();
  };

  const handleScriptLoad = () => {
    if (
      typeof google !== 'undefined' &&
      typeof google.accounts !== 'undefined' &&
      typeof google.accounts.id !== 'undefined'
    ) {
      googleScriptRef.current = true;

      /* global google */
      google.accounts.id.initialize({
        client_id: Config.GOOGLE_SSO_CLIENT_ID,
        callback: handleGoogleResponse,
        use_fedcm_for_prompt: false,
      });

      renderGoogleUi();
    }
  };

  if (typeof window !== 'undefined' && !googleApiRef.current) {
    if (!document.querySelector('#google-signin-script')) {
      loadScript(
        'https://accounts.google.com/gsi/client',
        document.body,
        'google-signin-script',
        handleScriptLoad,
      );
    }

    googleApiRef.current = true;
  }

  // logout case
  useEffect(() => {
    if (!googleApiRef.current) return;

    if (
      !googleScriptRef.current &&
      typeof google !== 'undefined' &&
      typeof google.accounts !== 'undefined' &&
      typeof google.accounts.id !== 'undefined'
    ) {
      googleScriptRef.current = true;
      renderGoogleUi();
    }
  }, [googleApiRef.current]);

  const validLoginState = () => password && isEmailValid(email);

  const onSubmit = () => {
    setIsLoading(true);
    dispatch(
      loginRequest({
        username: email,
        password,
        userCategoryId: UserCategory.CLIENT,
        errorBlock: () => setIsLoading(false),
      }),
    );
  };

  const renderLoginInputs = () => (
    <>
      <div className={styles.inputContainer}>
        <Input
          id="login-username-text"
          classNames={[styles.loginInput]}
          label="Email"
          placeholder="email@example.com"
          value={email}
          onTextChange={setEmail}
        />
      </div>
      <div className={styles.inputContainer}>
        <Input
          id="login-password-text"
          classNames={[styles.loginInput]}
          label="Password"
          placeholder="Password"
          value={password}
          type="password"
          onTextChange={setPassword}
        />
      </div>
    </>
  );

  return (
    <div className={styles.container}>
      {!shouldRenderInputs ? renderLoginInputs() : null}
      {error && (
        <div className={[styles.errorContainer, styles.loginInput].join(' ')}>
          <span className={styles.errorText}>{error}</span>
        </div>
      )}
      <div className={styles.buttonContainer}>
        {!shouldRenderInputs && (
          <Button
            id="login-login-button"
            classNames={[styles.loginButton]}
            isLoading={isLoading}
            disabled={!validLoginState() || !isRunningLatestVersion}
            text="Log in"
            textColor={!validLoginState() || !isRunningLatestVersion ? Colors.black : Colors.white}
            backgroundColor={
              validLoginState() && isRunningLatestVersion ? ColorsNew.darkGreen : Colors.lightGray
            }
            borderColor={
              validLoginState() && isRunningLatestVersion
                ? ColorsNew.darkGreen
                : Colors.mediumDarkGray
            }
            onClick={onSubmit}
          />
        )}
        <div id="google-sign-in" className={styles.googleButton} />
      </div>
    </div>
  );
};

export default LoginForm;
