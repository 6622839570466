import {
  AlcoholUse,
  EmploymentStatus,
  Housing,
  RecreationalDrugUse,
  Religion,
  TobaccoUse,
} from 'common-src/models/Patient';

export const getRequestBody = (data) => ({
  tobaccoUse: data.tobaccoUse?.value || null,
  alcoholUse: data.alcoholUse?.value || null,
  recreationalDrugUse: data.recreationalDrugUse?.value || null,
  employmentStatus: data.employmentStatus?.value || null,
  housing: data.housing?.value || null,
  religion: data.religion?.value || null,
  tobaccoAlcoholDrugNotes: data.tobaccoAlcoholDrugNotes || null,
  employmentHousingNotes: data.employmentHousingNotes || null,
});

export const getInitialData = (
  tobaccoUse,
  alcoholUse,
  recreationalDrugUse,
  employmentStatus,
  housing,
  religion,
  tobaccoAlcoholDrugNotes,
  employmentHousingNotes,
) => ({
  tobaccoUse: Object.values(TobaccoUse).find((x) => x.value === tobaccoUse) || null,
  alcoholUse: Object.values(AlcoholUse).find((x) => x.value === alcoholUse) || null,
  recreationalDrugUse:
    Object.values(RecreationalDrugUse).find((x) => x.value === recreationalDrugUse) || null,
  employmentStatus:
    Object.values(EmploymentStatus).find((x) => x.value === employmentStatus) || null,
  housing: Object.values(Housing).find((x) => x.value === housing) || null,
  religion: Object.values(Religion).find((x) => x.value === religion) || null,
  tobaccoAlcoholDrugNotes,
  employmentHousingNotes,
});
