export default {
  root: {
    padding: '0',
    minWidth: '700px',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(91, 91, 91, 0.2)',
    boxShadow: '0px 3px 12px 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
    overflowY: 'initial',
  },
};
