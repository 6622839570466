import 'chartjs-adapter-moment';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Patient from 'common-src/models/Patient';
import PatientCgmGlucoseReading, {
  getPatientCgmGlucoseReadingsRequest,
} from 'common-src/models/PatientCgmGlucoseReading';
import PatientGlucoseReading from 'common-src/models/PatientGlucoseReading';
import PatientLabReading, {
  getPatientLabReadingsRequest,
} from 'common-src/models/PatientLabReading';

import { TabsViewNew } from 'src/components/tabs';
import useOrmCleanup from 'src/hooks/useOrmCleanup';
import useRequestLoading from 'src/hooks/useRequestLoading';

import styles from './Biometrics.module.scss';
import { TabsData } from './constants';
import { BGMTab, CGMTab } from './tabs';

const Biometrics = ({ patient }) => {
  const [selectedTab, setSelectedTab] = useState(
    patient.isCGM ? TabsData.CGM.text : patient.isBGM ? TabsData.BGM.text : null,
  );

  const { options, initialTab } = useMemo(() => {
    const initialTab = patient.isCGM ? TabsData.CGM.text : patient.isBGM ? TabsData.BGM.text : null;

    const options = _.reduce(
      Object.values(TabsData),
      (acc, value) => {
        const disabled = value === TabsData.BGM ? !patient.isBGM : !patient.isCGM;

        acc[value === TabsData.BGM ? 'BGM' : 'CGM'] = {
          ...value,
          disabled,
        };

        return acc;
      },
      {},
    );

    return { options, initialTab };
  }, [patient.isBGM, patient.isCGM]);

  const renderTab = useMemo(() => {
    switch (selectedTab) {
      case TabsData.CGM.text:
        return <CGMTab patient={patient} />;
      case TabsData.BGM.text:
        return <BGMTab patient={patient} />;
      default:
        return <p className={styles.empty}>No Active Devices</p>;
    }
  }, [selectedTab]);

  useRequestLoading([
    getPatientLabReadingsRequest({ patientId: patient.id }),
    getPatientCgmGlucoseReadingsRequest({
      patientId: patient.id,
      limit: 1,
    }),
  ]);

  useOrmCleanup([
    PatientCgmGlucoseReading.modelName,
    PatientLabReading.modelName,
    PatientGlucoseReading.modelName,
  ]);

  return (
    <article className="relative">
      <TabsViewNew
        classNames={[styles.tabs, 'gap-30']}
        onTabSelected={setSelectedTab}
        options={options}
        initialTab={Object.values(TabsData).find((tab) => tab.text === initialTab)?.value}
      />
      {renderTab}
    </article>
  );
};

Biometrics.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Biometrics;
