// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllergyDetailsPopup-module__grid___mMbvk {
  display: grid;
  padding: 15px 30px;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}
.AllergyDetailsPopup-module__grid___mMbvk .AllergyDetailsPopup-module__width75___uEvNo {
  grid-column: 2 span;
}

.AllergyDetailsPopup-module__comments___HAJ87 {
  margin: 10px 0;
  padding: 15px 30px;
}`, "",{"version":3,"sources":["webpack://./src/popups/AllergyDetailsPopup/AllergyDetailsPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qCAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,cAAA;EACA,kBAAA;AAAF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `AllergyDetailsPopup-module__grid___mMbvk`,
	"width75": `AllergyDetailsPopup-module__width75___uEvNo`,
	"comments": `AllergyDetailsPopup-module__comments___HAJ87`
};
module.exports = ___CSS_LOADER_EXPORT___;
