import { allModelsSelector } from 'common-src/utils/selectorUtils';

import { ActivePatientTaskStatuses } from './constants';

export const getPatientTasks = (state, patientId) =>
  allModelsSelector(state, 'PatientTask', (pt) => pt.patientId === patientId);

export const getActiveCuriePatientTasks = (state, patientId) =>
  allModelsSelector(state, 'PatientTask', (pt) => pt.patientId === patientId).filter(
    (pt) => ActivePatientTaskStatuses.includes(pt.status) && pt.curie,
  );
