import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import {
  getInitialTemplateValues,
  getInteractionTemplates,
} from 'common-src/models/InteractionTemplate';
import { moduleTypes } from 'common-src/models/PatientInteraction';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';

import useWellnessPlan from './useWellnessPlan';

const useDocumentationConfig = (patientId, documentationId, moduleType) => {
  const [modules, setModules] = useState([]);
  const [primaryModuleTypeId, setPrimaryModuleTypeId] = useState();
  const [timeLogs, setTimeLogs] = useState([]);
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [serviceAt, setServiceAt] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
  const [regalTaskIds, setRegalTaskIds] = useState([]);
  const [awscCallIds, setAwscCallIds] = useState([]);
  const [hasRelevantConversations, setHasRelevantConversations] = useState(false);
  const [isEncounterNotScheduled, setIsEncounterNotScheduled] = useState(false);
  const [linkedVisitId, setLinkedVisitId] = useState(null);
  const [sdoh, setSdoh] = useState({});
  const [vitalsAndLabs, setVitalsAndLabs] = useState({});
  const [wellnessPlan, setWellnessPlan] = useState({});
  const [cgmAlerts, setCgmAlerts] = useState({});
  const [cgmEvaluation, setCgmEvaluation] = useState({});

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const documentationToEdit = useCustomSelector((state) =>
    singleModelSelector(state, 'PatientInteraction', documentationId),
  );
  const interactionTemplates = useCustomSelector((state) => getInteractionTemplates(state));

  const { wellnessPlan: patientWellnessPlan } = useWellnessPlan(patientId);

  useEffect(() => {
    if (!_.isEmpty(timeLogs)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.timeLogs)) return;

    setTimeLogs(documentationToEdit?.draftData?.timeLogs);
  }, [JSON.stringify(documentationToEdit?.draftData?.timeLogs)]);

  useEffect(() => {
    if (!_.isEmpty(sdoh)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.sdoh)) return;

    setSdoh(documentationToEdit?.draftData?.sdoh);
  }, [JSON.stringify(documentationToEdit?.draftData?.sdoh)]);

  useEffect(() => {
    if (!_.isEmpty(vitalsAndLabs)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.vitalsAndLabs)) return;

    setVitalsAndLabs(documentationToEdit?.draftData?.vitalsAndLabs);
  }, [JSON.stringify(documentationToEdit?.draftData?.vitalsAndLabs)]);

  useEffect(() => {
    if (!_.isEmpty(wellnessPlan)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.wellnessPlan)) {
      setWellnessPlan(patientWellnessPlan);
      return;
    }

    setWellnessPlan(documentationToEdit?.draftData?.wellnessPlan);
  }, [JSON.stringify(documentationToEdit?.draftData?.wellnessPlan), patientWellnessPlan]);

  useEffect(() => {
    if (!_.isEmpty(cgmAlerts)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.cgmAlerts)) return;

    setCgmAlerts(documentationToEdit?.draftData?.cgmAlerts);
  }, [JSON.stringify(documentationToEdit?.draftData?.cgmAlerts)]);

  useEffect(() => {
    if (!_.isEmpty(cgmEvaluation)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.cgmEvaluation)) return;

    setCgmEvaluation(documentationToEdit?.draftData?.cgmEvaluation);
  }, [JSON.stringify(documentationToEdit?.draftData?.cgmEvaluation)]);

  const onTimeLogChange = (index, field, value) => {
    if (field) {
      const timeLogsCopy = JSON.parse(JSON.stringify(timeLogs));
      timeLogsCopy[index][field] = value;
      setTimeLogs(timeLogsCopy);
      return;
    }

    if (index !== undefined) {
      setTimeLogs((prev) => prev.filter((_, indx) => indx !== index));
      return;
    }

    setTimeLogs((prev) => [...prev, { timeSpent: undefined, category: null }]);
  };

  const getEditedTemplates = (template) => {
    const templateIds = documentationToEdit.draftData?.docs?.map((t) => t.id);

    if (!templateIds.includes(template.id)) return { ...template.ref };

    return {
      ...template.ref,
      ...(documentationToEdit.draftData?.docs?.find((data) => data.id === template.id) || {}),
    };
  };

  const getTemplates = () => {
    const excludedIds = [
      moduleTypes.Addendum.id,
      moduleTypes.HoldRequest.id,
      moduleTypes.CloseRequest.id,
    ];

    if (!patient?.mfaApprovedAt) {
      excludedIds.push(moduleTypes.MFARenewalRequest.id);
    }

    // if moduleType is not passed that means the module is addendum, hold or close
    const templates = !moduleType
      ? interactionTemplates
          .filter((t) => !excludedIds.includes(t.moduleType))
          .map((t) =>
            _.isEmpty(documentationToEdit.draftData.docs)
              ? {
                  ...t.ref,
                  isSelected: documentationToEdit?.moduleTypes?.includes(t.moduleType),
                  isExpanded: documentationToEdit?.moduleTypes?.includes(t.moduleType),
                }
              : getEditedTemplates(t),
          )
      : interactionTemplates
          .filter((t) => t.moduleType === moduleType)
          .map((t) =>
            _.isEmpty(documentationToEdit.draftData.docs)
              ? { ...t.ref, isSelected: true, isExpanded: true }
              : documentationToEdit.draftData.docs[0],
          );

    return _.isEmpty(documentationToEdit.draftData.docs)
      ? getInitialTemplateValues(templates)
      : templates;
  };

  useEffect(() => {
    if (_.isEmpty(documentationToEdit) || !_.isEmpty(modules)) return;
    setModules(getTemplates());

    if (primaryModuleTypeId) return;
    setPrimaryModuleTypeId(
      documentationToEdit.primaryModuleTypeId || documentationToEdit.moduleTypes[0],
    );
  }, [interactionTemplates, documentationToEdit]);

  useEffect(() => {
    if (!showBillingInfo) return;
    setServiceAt(moment().format('YYYY-MM-DD HH:mm:ss'));
  }, [showBillingInfo]);

  return {
    patient,
    documentationToEdit,
    modules,
    setModules,
    primaryModuleTypeId,
    setPrimaryModuleTypeId,
    interactionTemplates,
    timeLogs,
    onTimeLogChange,
    serviceAt,
    setServiceAt,
    showBillingInfo,
    setShowBillingInfo,
    regalTaskIds,
    setRegalTaskIds,
    awscCallIds,
    setAwscCallIds,
    hasRelevantConversations,
    setHasRelevantConversations,
    isEncounterNotScheduled,
    setIsEncounterNotScheduled,
    linkedVisitId,
    setLinkedVisitId,
    sdoh,
    setSdoh,
    vitalsAndLabs,
    setVitalsAndLabs,
    wellnessPlan,
    setWellnessPlan,
    cgmAlerts,
    setCgmAlerts,
    cgmEvaluation,
    setCgmEvaluation,
  };
};

export default useDocumentationConfig;
