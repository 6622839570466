import { EncounterStatus } from 'common-src/models/PatientEncounter';

import { EncounterTabs } from './constants';

export const getTabInfo = (encounters = []) => {
  const completedEncounters = [];
  const inProgressEncounters = [];
  const archivedEncounters = [];

  [...encounters]
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    .forEach((encounter) => {
      if (encounter.status === EncounterStatus.Completed.value) {
        completedEncounters.push(encounter);
        return;
      }

      if (encounter.status === EncounterStatus.Archived.value) {
        archivedEncounters.push(encounter);
        return;
      }

      inProgressEncounters.push(encounter);
    });

  const tabInfo = {
    COMPLETED: {
      text: EncounterTabs.COMPLETED.text,
      count: completedEncounters.length,
    },
    IN_PROGRESS: {
      text: EncounterTabs.IN_PROGRESS.text,
      count: inProgressEncounters.length,
    },
    ARCHIVED: {
      text: EncounterTabs.ARCHIVED.text,
      count: archivedEncounters.length,
    },
  };

  return {
    tabInfo,
    completedEncounters,
    inProgressEncounters,
    archivedEncounters,
  };
};
