import { EncounterStatus, EncounterType } from './constants';

export const encounterTypePresenter = (type) =>
  Object.values(EncounterType).find((item) => item.value === type)?.label;

export const encounterStatusPresenter = (status) =>
  Object.values(EncounterStatus).find((item) => item.value === status)?.label;

export const getStatusById = (status) =>
  Object.values(EncounterStatus).find((item) => item.value === status);
