import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getFeatureFlagsApiRequest = (params) =>
  restRequest({
    uri: 'featureFlags',
    method: HttpMethods.Get,
    ...params,
  });

export const updateFeatureFlagApiRequest = (body, params) =>
  restRequest({
    uri: `featureFlags/${body.name}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });
