import { createReducer } from 'common-src/utils/reducerUtils';

import { STORAGE_KEY_APP_VERSION } from 'src/constants/storageKeys';

import {
  SET_CURRENT_VERSION,
  SET_IS_RUNNING_LATEST_VERSION,
  SET_SHOULD_SHOW_UPDATE_MODAL,
} from './actionTypes';

const initialState = {
  currentVersion: localStorage.getItem(STORAGE_KEY_APP_VERSION) || null,
  isRunningLatestVersion: true,
  shouldShowUpdateModal: true,
};

const entityHandlers = {
  [SET_CURRENT_VERSION]: (state, payload) => ({
    ...state,
    currentVersion: payload.currentVersion,
  }),
  [SET_IS_RUNNING_LATEST_VERSION]: (state, payload) => ({
    ...state,
    isRunningLatestVersion: payload.isRunningLatestVersion,
  }),
  [SET_SHOULD_SHOW_UPDATE_MODAL]: (state, payload) => ({
    ...state,
    shouldShowUpdateModal: payload.shouldShowUpdateModal,
  }),
};

export const reducer = createReducer(initialState, entityHandlers);
