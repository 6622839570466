import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomCheckbox } from 'common-src/components/base';

import styles from './AwscCallItem.module.scss';
import { getClientById, getClientName, getDataByChannel } from './helpers';

const AwscCallItem = ({ call, awscCallIds, setAwscCallIds, relatedClients }) => {
  if (!call) return null;

  const { id, clientId, channel, initiatedAt, createdAt, agentInteractionDuration } = call;
  const { label, icon } = getDataByChannel(channel);
  const isChecked = awscCallIds.includes(id);
  const client = getClientById(relatedClients, clientId);

  const checkboxHandler = () =>
    setAwscCallIds((prev) => (prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]));

  const getCallCreatedAt = () => {
    const callCreatedAt = initiatedAt || createdAt;
    const isToday = moment(callCreatedAt).isSame(moment(), 'day');

    if (isToday) {
      return `Today at ${moment(callCreatedAt).format('h:mmA')}`;
    }

    return moment(callCreatedAt).format('MM/DD/YYYY [at] h:mmA');
  };

  const getDuration = () => {
    if (!agentInteractionDuration) return '';

    const hours = Math.floor(agentInteractionDuration / 3600);
    const minutes = Math.floor(agentInteractionDuration / 60) % 60;
    const seconds = Math.ceil(agentInteractionDuration % 60);

    return `${hours ? `${hours} hours ` : ''}${minutes ? `${minutes} min ` : ''}${
      seconds ? `${seconds} seconds` : ''
    }`;
  };

  return (
    <div
      className={[
        styles.container,
        isChecked ? styles.checked : '',
        'flex-row',
        'p-12',
        'm-t-16',
        'primary-border',
        'border-r-12',
        'gap-10',
      ].join(' ')}
      role="presentation"
      onClick={checkboxHandler}
    >
      <img className={styles.icon} src={icon} alt="regal-event-icon" />
      <div className="flex-column flex-1 gap-4">
        <span id="provider-name" className={[styles.title, 'font-s-12', 'font-w-600'].join(' ')}>
          {getClientName(client)}
        </span>
        <p className={[styles.content, 'font-w-700'].join(' ')}>
          <span id="awsc-channel" className="font-s-16">
            {label}
          </span>
          <span id="awsc-time" className="font-s-10 m-l-5">
            {getCallCreatedAt()}
          </span>
        </p>
        <span
          id="awsc-duration-and-id"
          className={[styles.addition, 'font-s-10', 'font-w-500'].join(' ')}
        >
          {getDuration()} - ID {id}
        </span>
      </div>
      <CustomCheckbox
        id={`${id}-link-event-checkbox`}
        classNames={[styles.checkbox, 'm-r-20']}
        header={isChecked ? 'Event linked' : 'Link event'}
        onChange={checkboxHandler}
        checked={isChecked}
      />
    </div>
  );
};

AwscCallItem.propTypes = {
  call: PropTypes.object,
  awscCallIds: PropTypes.array,
  setAwscCallIds: PropTypes.func,
  relatedClients: PropTypes.array,
};

export default AwscCallItem;
