/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';

import CircleProgressBar from 'src/components/widgets/CircleProgressBar';

import styles from './ProgressHeaderItem.module.scss';

const ProgressHeaderItem = ({
  progressPercent,
  progressFillColor,
  onClick,
  showProgressBar,
  showDeviceStatus,
  deviceStatusSrc,
  disabled,
  onContentClick,
  header = '-',
  mainText = '-',
  id = 'header-item',
  supportingText = '-',
}) => (
  <div className={[styles.container, 'gap-14', 'primary-border'].join(' ')}>
    {showProgressBar && (
      <CircleProgressBar size={50} percent={progressPercent} fillColor={progressFillColor} />
    )}
    {showDeviceStatus && deviceStatusSrc && (
      <img className={styles.statusIcon} src={deviceStatusSrc} alt="device-status-icon" />
    )}
    <div
      className={[styles.textContainer, 'gap-2', 'flex-column'].join(' ')}
      style={{ cursor: onContentClick && 'pointer' }}
      onClick={() => onContentClick?.()}
    >
      <span className={[styles.header, 'font-w-600', 'font-s-12'].join(' ')}>{header}</span>
      <span className={[styles.mainText, 'font-w-700', 'font-s-16'].join(' ')}>{mainText}</span>
      <div className={[styles.supportingText, 'font-w-600', 'font-s-12'].join(' ')}>
        {supportingText}
      </div>
    </div>
    <img
      id={`${id}-action-button`}
      className={[styles.arrowBtn, disabled ? styles.disabled : ''].join(' ')}
      src={CommonIcons.rightArrowIcon}
      alt="right-btn"
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
    />
  </div>
);

ProgressHeaderItem.propTypes = {
  id: PropTypes.string,
  header: PropTypes.string,
  mainText: PropTypes.string,
  supportingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  progressPercent: PropTypes.number,
  progressFillColor: PropTypes.string,
  onClick: PropTypes.func,
  showProgressBar: PropTypes.bool,
  showDeviceStatus: PropTypes.bool,
  deviceStatusSrc: PropTypes.string,
  disabled: PropTypes.bool,
  onContentClick: PropTypes.func,
};

export default ProgressHeaderItem;
