import { ColorsNew } from 'common-src/styles';

import { AgentState } from './constants';

export const isAwsDialerVisible = (state) => state?.awsConnect?.isVisible || false;
export const getContactToDial = (state) => state?.awsConnect?.contactToDial || null;
export const getAgentState = (state) => state?.awsConnect?.agentState || null;
export const getAgentStateColor = (state) => {
  const agentState = state?.awsConnect?.agentState || null;
  switch (agentState) {
    case AgentState.ACW:
    case AgentState.Busy:
    case AgentState.FailedConnectAgent:
    case AgentState.CallingCustomer:
      return ColorsNew.mediumDarkRed;
    case AgentState.Available:
      return ColorsNew.lightGreen;
    case AgentState.Offline:
      return ColorsNew.darkGray;
    default:
      return null;
  }
};
