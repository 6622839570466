import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { getDocumentationById, moduleTypes } from 'common-src/models/PatientInteraction';

import { RequestHandlerScreen } from 'src/components/base';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useDocumentationRequests from 'src/hooks/useDocumentationRequests';
import useSaveNote from 'src/hooks/useSaveNote';

import AddendumForm from '../AddendumForm';
import CloseAccountForm from '../CloseAccountForm';
import DocumentationForm from '../DocumentationForm';
import HoldStatusForm from '../HoldStatusForm';
import ReadOnlyForm from '../ReadOnlyForm/ReadOnlyForm';

const DocumentComponent = (props) => {
  const documentation = useCustomSelector((state) =>
    getDocumentationById(state, props.documentationId),
  );

  useSaveNote(props.patientId, documentation, props.isEmbedded);

  const { isRequestRunning } = useDocumentationRequests(
    props.isEmbedded ? documentation?.id : props.documentationId,
    documentation?.parentInteractionId,
    props.patientId,
  );

  if (isRequestRunning) {
    return <RequestHandlerScreen isRequesting requestingText="Retrieving documentation..." />;
  }

  if (props.isReadOnly) {
    return <ReadOnlyForm {...props} documentation={documentation} />;
  }

  if (_.isEmpty(documentation)) return null;

  switch (documentation?.moduleTypes?.[0]) {
    case moduleTypes.CloseRequest.id:
      return <CloseAccountForm {...props} />;
    case moduleTypes.HoldRequest.id:
      return <HoldStatusForm {...props} />;
    case moduleTypes.Addendum.id:
      return <AddendumForm {...props} isRequestRunning={isRequestRunning} />;
    default:
      return <DocumentationForm {...props} isRequestRunning={isRequestRunning} />;
  }
};

DocumentComponent.propTypes = {
  isReadOnly: PropTypes.bool,
  documentationId: PropTypes.string,
  patientId: PropTypes.number,
  isEmbedded: PropTypes.bool,
};

export default DocumentComponent;
