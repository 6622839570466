import PropTypes from 'prop-types';
import React from 'react';

import styles from './Item.module.scss';

const Item = ({ productName = '-', quantity = '0' }) => (
  <div id="product-item" className={[styles.container, 'p-r-60', 'primary-border-b'].join(' ')}>
    <span id={`${productName}-name`} className={[styles.name, 'font-w-500', 'font-s-16'].join(' ')}>
      {productName}
    </span>
    <span
      id={`${productName}-quantity`}
      className={['font-w-500', 'font-s-16'].join(' ')}
    >{`Quantity: ${quantity}`}</span>
  </div>
);

Item.propTypes = {
  productName: PropTypes.string,
  quantity: PropTypes.string,
};

export default Item;
