import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew } from 'common-src/components/base';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './DateOfServicePopup.module.scss';

const DateOfServicePopup = ({ onClose, serviceAt, setServiceAt }) => {
  const [data, setData] = useState({ serviceAt: null });

  const { errors, setErrors, renderButtons } = usePopup();

  const allowedTimeInterval = moment(data.serviceAt).subtract(3, 'hours');
  const showHoursWarning = moment(moment().format('YYYY-MM-DD HH:mm')).isBefore(
    allowedTimeInterval,
  );

  useEffect(() => {
    if (!serviceAt) return;
    setData({ serviceAt });
  }, [serviceAt]);

  const validateServiceAt = (value) => {
    let errMsg;
    if (value === undefined) {
      errMsg = 'Invalid date format';
    } else if (!value) {
      errMsg = 'Date should not be empty';
    }

    return errMsg;
  };

  const onSubmitHandler = () => {
    const errorMessage = validateServiceAt(data.serviceAt);
    if (errorMessage) {
      setErrors({ serviceAt: errorMessage });
      return;
    }

    setServiceAt?.(data.serviceAt);
    onClose();
  };

  const onChangeHandler = (value) => {
    setErrors((prev) => ({ ...prev, ...{ serviceAt: validateServiceAt(value) } }));
    setData((prev) => ({ ...prev, serviceAt: value }));
  };

  return (
    <BasePopup
      id="date-of-service"
      open
      onClose={onClose}
      title="Date of Service"
      customStyle={{ minWidth: '400px' }}
    >
      <div className={styles.container}>
        <DatePickerNew
          id="date-of-service"
          inputClassNames={[styles.inputContainer]}
          textClassNames={[styles.dateInput]}
          header="Select date and time"
          withTime
          value={data.serviceAt}
          onDateSelected={(date) =>
            onChangeHandler(date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null)
          }
          errorText={errors.serviceAt}
          warningText={
            showHoursWarning ? 'Warning: Please review date and time of service for accuracy' : ''
          }
          iconSrc={CommonIcons.calendarCheckBoldIcon}
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: !validateServiceAt(data.serviceAt),
        submitButtonText: 'Save and Close',
      })}
    </BasePopup>
  );
};

DateOfServicePopup.propTypes = {
  onClose: PropTypes.func,
  serviceAt: PropTypes.string,
  setServiceAt: PropTypes.func,
};

export default DateOfServicePopup;
