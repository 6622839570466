import { cleanedText } from 'common-src/presenters';

export const validatePatientCareProvider = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'name':
      if (!value) {
        errMsg = 'Name should not be empty';
      }
      break;
    case 'typeId':
      if (!value) {
        errMsg = 'Type should not be empty';
      }
      break;
    case 'fax':
    case 'phone':
      if (value) {
        errMsg = cleanedText(value).length !== 10 ? 'Phone number is not valid' : '';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
