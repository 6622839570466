import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientFamilyHistoryRequest = (query, params) =>
  restRequest({
    uri: 'patientFamilyHistory',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addFamilyHistory = (body, params) =>
  restRequest({
    uri: 'patientFamilyHistory',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateFamilyHistory = (id, body, params) =>
  restRequest({
    uri: `patientFamilyHistory/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const makeFamilyHistoryInactive = (id, params) =>
  restRequest({
    uri: `patientFamilyHistory/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
