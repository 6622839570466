import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientFulfillmentOrder extends Model {
  static modelName = 'PatientFulfillmentOrder';

  static fields = {
    id: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    shippedAt: attr(),
    quantity: attr(),
    lineItems: attr(),
    orderStatus: attr(),
    product: attr(),
    trackingId: attr(),
    shippingAddress: attr(),
    orderedBy: attr(),
    provider: attr(),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'fulfillmentOrders',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      shippedAt: PropTypes.string,
      quantity: PropTypes.number,
      lineItems: PropTypes.arrayOf(
        PropTypes.shape({
          product: PropTypes.number,
          quantity: PropTypes.number,
        }),
      ),
      orderStatus: PropTypes.number,
      product: PropTypes.number,
      trackingId: PropTypes.string,
      shippingAddress: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        zipcode: PropTypes.string,
      }),
      orderedBy: PropTypes.number,
      provider: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientFulfillmentOrder;
