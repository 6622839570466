import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';
import PatientPhoneNumber, {
  createPatientPhoneNumbersRequest,
  deletePatientPhoneNumbersRequest,
  updatePatientPhoneNumbersRequest,
} from 'common-src/models/PatientPhoneNumber';

const getPayload = (patientId, number, includePatientId = false) => {
  const payload = {
    phoneNumber: number.phoneNumber,
    label: number.label || null,
    isPrimary: number.isPrimary,
    isVerified: true,
    type: number.type || null,
  };

  if (includePatientId) {
    payload.patientId = Number(patientId);
  }

  return payload;
};

export const getPhoneNumbersRestRequests = (patientId, oldNumbers, newNumbers) => {
  const ops = [];
  const deleteOps = [];

  if (!Array.isArray(oldNumbers) || !Array.isArray(newNumbers)) return { ops, deleteOps };

  oldNumbers.forEach((number) => {
    const numberExists = newNumbers.find(
      ({ id, phoneNumber }) =>
        number.id === id ||
        number.phoneNumber === `+1${phoneNumber}` ||
        number.phoneNumber === phoneNumber,
    );

    if (!numberExists) {
      deleteOps.push(
        deletePatientPhoneNumbersRequest(number.id, {
          successBlock: () => deleteOrmItem(PatientPhoneNumber.modelName, number.id),
        }),
      );
    }
  });

  newNumbers.forEach((number) => {
    const numberExists = oldNumbers.find(
      ({ id, phoneNumber }) =>
        number.id === id ||
        `+1${number.phoneNumber}` === phoneNumber ||
        number.phoneNumber === phoneNumber,
    );

    if (!numberExists) {
      ops.push(createPatientPhoneNumbersRequest(getPayload(patientId, number, true)));
    } else if (
      !_.isEqual(number, {
        ...numberExists,
        phoneNumber: numberExists.phoneNumber.startsWith('+')
          ? numberExists.phoneNumber.slice(2)
          : numberExists.phoneNumber,
      })
    ) {
      ops.push(
        updatePatientPhoneNumbersRequest(numberExists.id, getPayload(patientId, number, false)),
      );
    }
  });

  return { ops, deleteOps };
};

export const validatePhoneNumbers = (phones) => {
  if (!Array.isArray(phones)) return null;

  const phoneNumbers = phones.map((data) => data.phoneNumber.value) || [];
  const phoneNumbersCount = phoneNumbers.length;
  const emptyPhoneIndex = phoneNumbers.findIndex((phone) => phone === '' || phone.length !== 10);

  if (emptyPhoneIndex !== -1) {
    return {
      [`${emptyPhoneIndex}.phoneNumber`]: 'Invalid phone number',
    };
  }

  if (phoneNumbersCount > 1) {
    if (_.uniq(phoneNumbers).length === phoneNumbersCount) return null;

    const firstDuplicateIndex = phoneNumbers.findIndex(
      (item, index) => phoneNumbers.lastIndexOf(item) !== index,
    );

    return {
      [`${firstDuplicateIndex}.phoneNumber`]: 'Phone number already exists',
    };
  }

  return null;
};

export const getFormattedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || typeof phoneNumber !== 'string') return '-';
  if (phoneNumber.length !== 10) return phoneNumber;

  const firstDigits = phoneNumber.substring(0, 3);
  const secondDigits = phoneNumber.substring(3, 6);
  const lastDigits = phoneNumber.substring(6);

  return `(${firstDigits}) ${secondDigits} - ${lastDigits}`;
};
