import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { DatePickerNew, Select, Toggle } from 'common-src/components/base';
import Patient from 'common-src/models/Patient';

import {
  BGMPeriodRange,
  dayTimePeriods,
} from 'src/pages/PatientDetails/fragments/Biometrics/constants';

import styles from './BGMFiltersHeader.module.scss';

const BGMFiltersHeader = ({
  selectedPeriod,
  setSelectedPeriod,
  startingDate,
  setStartingDate,
  endingDate,
  setEndingDate,
  dayTimePeriod,
  setDayTimePeriod,
  patient,
}) => {
  const onRangeSelectedHandler = (dates) => {
    const [start, end] = dates;
    if (!start && !end) {
      const period = BGMPeriodRange[1].value;
      setStartingDate(start || moment().subtract(period, 'days').format('YYYY-MM-DD HH:mm'));
      setEndingDate(end || moment().format('YYYY-MM-DD HH:mm'));
      setSelectedPeriod(period);
      return;
    }

    setStartingDate(moment(start).format('YYYY-MM-DD HH:mm'));
    setEndingDate(moment(end).format('YYYY-MM-DD HH:mm'));
    setSelectedPeriod(moment(end).diff(moment(start), 'days'));
  };

  const getDateErrorText = () =>
    moment(endingDate).diff(moment(startingDate), 'days') > 90
      ? 'Timespan should be less than 90 days'
      : '';

  const renderDeviceInfo = () => {
    if (!patient.bgmType) return null;

    return (
      <span id="device-name" className={[styles.text, 'font-w-700', 'font-s-10'].join(' ')}>
        {patient.bgmType}
      </span>
    );
  };

  return (
    <div className={[styles.container, 'flex-row'].join(' ')}>
      <div className={[styles.filters, 'p-l-30'].join(' ')}>
        <Toggle
          options={BGMPeriodRange}
          value={selectedPeriod}
          onClick={setSelectedPeriod}
          defaultValue={BGMPeriodRange[1].value}
          size="small"
        />
        <DatePickerNew
          id="range-date-picker"
          inputClassNames={[styles.date]}
          startDate={startingDate}
          endDate={endingDate}
          selectsRange
          maxDate={new Date()}
          onRangeSelected={onRangeSelectedHandler}
          errorText={getDateErrorText()}
          size="small"
        />
        <Select
          id="day-time-periods-select"
          classNames={[styles.select]}
          options={dayTimePeriods}
          onChange={(timePeriod) => setDayTimePeriod(timePeriod)}
          value={dayTimePeriod}
          size="small"
        />
      </div>
      {renderDeviceInfo()}
    </div>
  );
};

BGMFiltersHeader.propTypes = {
  selectedPeriod: PropTypes.number,
  setSelectedPeriod: PropTypes.func,
  startingDate: PropTypes.string,
  setStartingDate: PropTypes.func,
  endingDate: PropTypes.string,
  setEndingDate: PropTypes.func,
  dayTimePeriod: PropTypes.object,
  setDayTimePeriod: PropTypes.func,
  patient: PropTypes.exact(Patient.schema),
};

export default BGMFiltersHeader;
