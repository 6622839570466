import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import {
  encounterStatusPresenter,
  encounterTypePresenter,
  getPatientEncounter,
} from 'common-src/models/PatientEncounter';

import { InfoItem } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import BasePopup from 'src/popups/BasePopup';

import styles from './EncounterDetailsPopup.module.scss';

const EncounterDetailsPopup = ({ onClose, encounterId }) => {
  const encounter = useCustomSelector((state) => getPatientEncounter(state, encounterId));

  return (
    <BasePopup
      id="encounter-details"
      open
      onClose={onClose}
      title="View Encounter"
      customStyle={{ minWidth: '50vw' }}
    >
      <div className={styles.grid}>
        <InfoItem
          title="Type"
          content={encounterTypePresenter(encounter?.type)}
          textId="type"
          small
        />
        <InfoItem
          title="Start Date"
          content={encounter?.startDate && moment(encounter.startDate).format('MM/DD/YYYY')}
          textId="start-date"
          small
        />
        <InfoItem
          title="End Date"
          content={encounter?.endDate && moment(encounter.endDate).format('MM/DD/YYYY')}
          textId="end-date"
          small
        />
        <InfoItem
          title="Status"
          content={encounterStatusPresenter(encounter?.status)}
          textId="status"
          small
        />
        <InfoItem title="Provider" content={encounter?.provider} textId="provider" small />
        <InfoItem title="Source" content={encounter?.source} textId="source" small />
        <InfoItem
          title="Date Added"
          content={encounter?.createdAt && moment(encounter.createdAt).format('MM/DD/YYYY')}
          textId="date-added"
          small
        />
        <InfoItem
          classNames={[styles.width75]}
          title="Added By"
          content={encounter?.createdBy?.getName(true, false)}
          textId="added-by"
          small
        />
        <InfoItem
          title="Last Modified"
          content={encounter?.updatedAt && moment(encounter.updatedAt).format('MM/DD/YYYY')}
          textId="last-modified"
          small
        />
        <InfoItem
          classNames={[styles.width75]}
          title="Modified By"
          content={encounter?.updatedBy?.getName(true, false)}
          textId="modified-by"
          small
        />
      </div>
      <InfoItem
        classNames={[styles.comments, 'primary-border-t']}
        title="Comments"
        content={encounter?.comments}
        textId="comments"
        small
      />
    </BasePopup>
  );
};

EncounterDetailsPopup.propTypes = {
  onClose: PropTypes.func,
  encounterId: PropTypes.string,
};

export default EncounterDetailsPopup;
