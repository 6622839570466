import { ColorsNew } from 'common-src/styles';

export const getBMIStyle = (bmiValue) => {
  switch (true) {
    case !bmiValue:
      return { backgroundColor: 'transparent', color: ColorsNew.darkGreen };
    case bmiValue < 18.5:
      return { backgroundColor: ColorsNew.baseOrange, color: ColorsNew.darkGreen };
    case bmiValue < 25.0:
      return { backgroundColor: ColorsNew.baseGreen, color: ColorsNew.darkGreen };
    case bmiValue < 30.0:
      return { backgroundColor: ColorsNew.baseOrange, color: ColorsNew.darkGreen };
    default:
      return { backgroundColor: '#F01F51', color: 'white' };
  }
};
