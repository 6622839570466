/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';

const usePaper = (callback = () => {}) => {
  const [open, setOpen] = useState(false);
  const rootElement = document.getElementById('root');

  const setRootElementStyles = (value) => {
    if (!rootElement) return;

    rootElement.style.pointerEvents = value;
  };

  const openPaper = (e) => {
    e?.stopPropagation();
    setOpen(true);
    setRootElementStyles('none');
  };

  const closePaper = () => {
    setOpen(false);
    setRootElementStyles('auto');
  };

  useEffect(() => {
    const closeOnPressEscape = (e) => {
      if (e.key === 'Escape') {
        setOpen(false);
        setRootElementStyles('auto');
        callback();
      }
    };

    document.addEventListener('keydown', closeOnPressEscape);

    return () => document.addEventListener('keydown', closeOnPressEscape);
  }, []);

  return { openPaper, closePaper, open };
};

export default usePaper;
