import React from 'react';

import styles from './Header.module.scss';

const Header = () => (
  <div
    id="order-list-header"
    className={[styles.container, 'vertically-centered', 'm-l-40', 'primary-border-b'].join(' ')}
  >
    <span className={[styles.title, 'font-w-700', 'font-s-22'].join(' ')}>Order History</span>
  </div>
);

export default Header;
