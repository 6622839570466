// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateOfServicePopup-module__container___fQI_X {
  display: flex;
  flex: 1;
  padding: 25px 30px;
}
.DateOfServicePopup-module__container___fQI_X .DateOfServicePopup-module__inputContainer___uHZGh {
  padding: 8px 16px;
}
.DateOfServicePopup-module__container___fQI_X .DateOfServicePopup-module__inputContainer___uHZGh .DateOfServicePopup-module__dateInput___diPYl {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.DateOfServicePopup-module__buttonsContainer___IETqm {
  border-top: 1px solid rgba(91, 91, 91, 0.2);
  justify-content: end;
  padding: 30px;
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/popups/DateOfServicePopup/DateOfServicePopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,kBAAA;AACF;AACE;EACE,iBAAA;AACJ;AACI;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AACN;;AAIA;EACE,2CAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;AADF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DateOfServicePopup-module__container___fQI_X`,
	"inputContainer": `DateOfServicePopup-module__inputContainer___uHZGh`,
	"dateInput": `DateOfServicePopup-module__dateInput___diPYl`,
	"buttonsContainer": `DateOfServicePopup-module__buttonsContainer___IETqm`
};
module.exports = ___CSS_LOADER_EXPORT___;
