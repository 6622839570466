import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientAllergiesRequest = (query, params) =>
  restRequest({
    uri: 'patientAllergies',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const addAllergy = (body, params) =>
  restRequest({
    uri: 'patientAllergies',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateAllergy = (id, body, params) =>
  restRequest({
    uri: `patientAllergies/${id}`,
    method: HttpMethods.Put,
    body,
    ...params,
  });

export const makeAllergyInactive = (id, params) =>
  restRequest({
    uri: `patientAllergies/${id}/activeTill`,
    method: HttpMethods.Put,
    body: {},
    ...params,
  });
