import moment from 'moment-timezone';

export const getChartData = (results = []) => {
  const values = [];
  const labels = [];
  const data = [];

  [...results]
    .sort((a, b) => new Date(b.readingDate) - new Date(a.readingDate))
    .forEach(({ readingValue, readingDate }) => {
      values.push(readingValue);
      labels.push(readingDate);
      data.push({ x: readingDate, y: readingValue });
    });

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  if (data.length === 1) {
    const prevDate = moment(labels[0]).subtract(1, 'month').format('YYYY-MM-DD');
    const nextDate = moment(labels[0]).add(1, 'month').format('YYYY-MM-DD');

    labels.unshift(prevDate);
    labels.push(nextDate);
    data.unshift({ x: prevDate, y: null });
    data.push({ x: nextDate, y: null });
  }

  return {
    data,
    labels,
    minValue,
    maxValue,
    deviation: maxValue - minValue,
    resultsCount: results.length,
  };
};

export const generateLine = (label, value, count) => ({
  label,
  data: Array(count).fill(value),
  pointBackgroundColor: 'transparent',
  pointBorderColor: 'transparent',
  borderColor: 'rgba(91, 91, 91, 0.2)',
  borderDash: [8, 4],
  borderWidth: 1,
  datalabels: {
    padding: { right: 15 },
    align: 'left',
    anchor: 'center',
    display: (ctx) => {
      const visibleLabels = ['max', 'min'];
      return visibleLabels.includes(ctx.dataset.label) && ctx.dataIndex === count - 1;
    },
    formatter: () => (count > 1 ? `${value}\n${label}` : ''),
  },
});
