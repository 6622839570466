import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ColorsNew } from 'common-src/styles';

import Button from '../Button';
import Radio from '../Radio/Radio';
import styles from './RadioGroup.module.scss';

const RadioGroup = ({
  value,
  onClear,
  applyBackgroundColor,
  description,
  id = '',
  options = [],
  classNames = [],
  disabled = false,
  onChange = () => {},
  containerClassNames = [],
}) => {
  const renderDescription = () => {
    if (!description) return null;

    return (
      <span className={[styles.description, 'font-w-400', 'font-s-12', 'm-b-6'].join(' ')}>
        {description}
      </span>
    );
  };

  return (
    <div className={[...containerClassNames].join(' ')}>
      {options.map((option, index) => (
        <Radio
          key={option}
          id={`${id}.${index}`}
          classNames={['m-b-16', ...classNames]}
          label={option}
          disabled={disabled}
          checked={_.isEqual(option, value)}
          onChange={() => onChange(option)}
          applyBackgroundColor={applyBackgroundColor}
        />
      ))}
      {renderDescription()}
      {onClear && (
        <Button
          id={`${id}-clear-btn`}
          classNames={['m-l-20']}
          text="clear input"
          disabled={disabled || value === ''}
          backgroundColor="transparent"
          borderColor="transparent"
          textColor={ColorsNew.mediumDarkGreen}
          onClick={onClear}
        />
      )}
    </div>
  );
};

RadioGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  containerClassNames: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.arrayOf(PropTypes.string),
  applyBackgroundColor: PropTypes.bool,
  description: PropTypes.string,
};

export default RadioGroup;
