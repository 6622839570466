import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  InteractionStatus,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';

import { getNoteData } from 'src/features/tabsState';
import { getTimer } from 'src/features/timeTracker';
import useCustomSelector from 'src/hooks/useCustomSelector';

const useSaveNote = (patientId, note, isEmbedded) => {
  const dispatch = useDispatch();

  const prevNote = useRef();
  const prevTimer = useRef(0);
  const prevNoteData = useRef({});

  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const noteData = useCustomSelector((state) => getNoteData(state, patientId));

  useEffect(() => {
    prevNote.current = note;
    prevTimer.current = timer;
    prevNoteData.current = noteData;
  }, [note, timer, noteData]);

  useEffect(
    () =>
      // Save note draft when user leaves Note page
      () => {
        if (
          !isEmbedded ||
          !prevNote.current ||
          _.isEmpty(prevNoteData.current) ||
          prevNote.current.status === InteractionStatus.Finalized
        )
          return;

        dispatch(
          updatePatientDraftApiRequest(
            prevNote.current.id,
            {
              ...prevNoteData.current,
              duration: Math.max(prevNote.current.duration, prevTimer.current),
            },
            {},
          ),
        );
      },
    [],
  );
};

export default useSaveNote;
