import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CustomCheckbox } from 'common-src/components/base';
import Patient, { updatePatient } from 'common-src/models/Patient';

import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from './Preferences.module.scss';

const Preferences = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        { ...formData },
        {
          successBlock: () => {
            setIsEditMode(false);
            setIsLoading(false);
            showAlert(AlertType.Success, '', 'You have successfully updated preferences.');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const { isEditMode, setIsEditMode, setIsLoading, renderContent, setInitialData } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    formData,
    true,
    'preferences',
  );

  useEffect(() => {
    const data = _.pick(patient, [
      'shouldSendDailyReminders',
      'shouldSendLowEngagement',
      'shouldSendVisitReminders',
    ]);
    setFormData(data);
    setInitialData(data);
  }, [
    JSON.stringify(
      _.pick(patient, [
        'shouldSendDailyReminders',
        'shouldSendLowEngagement',
        'shouldSendVisitReminders',
      ]),
    ),
  ]);

  if (_.isEmpty(formData)) return null;

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderForm = () => (
    <div className={styles.grid}>
      <CustomCheckbox
        id="send-daily-reminders-chechbox"
        header="Send daily reading reminders"
        info="Sends a daily reminder at 8am to test"
        checked={!!formData.shouldSendDailyReminders}
        onChange={(value) => handleInputChange('shouldSendDailyReminders', value)}
        disabled={!isEditMode}
      />
      <CustomCheckbox
        id="send-low-engagement-chechbox"
        header="Opt-out of low engagement texts"
        info="Stops low engagement texts"
        checked={!formData.shouldSendLowEngagement}
        onChange={(value) => {
          handleInputChange('shouldSendLowEngagement', !value);
        }}
        disabled={!isEditMode}
      />
      <CustomCheckbox
        id="send-visit-reminders-chechbox"
        header="Opt-out of appt. reminder texts"
        info="Stops appt reminder texts"
        checked={!formData.shouldSendVisitReminders}
        onChange={(value) => {
          handleInputChange('shouldSendVisitReminders', !value);
        }}
        disabled={!isEditMode}
      />
    </div>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

Preferences.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Preferences;
