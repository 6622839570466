import moment from 'moment-timezone';

import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientCgmAuthRequests = (state, patientId) =>
  allModelsSelector(state, 'CgmAuthRequest', (s) => s.patientId === patientId)?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  ) || [];

export const getLatestCgmAuthRequest = (state, patientId) => {
  const latestRequest = getPatientCgmAuthRequests(state, patientId)?.[0];
  if (!latestRequest) return null;
  if (moment().isAfter(latestRequest.validUntil)) return null;

  return latestRequest;
};
