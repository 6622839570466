import { useEffect, useState } from 'react';

import usePrevious from 'src/hooks/usePrevious';

export const PAGINATION_DEVIATION = 100;

const usePagination = (
  initialFrom,
  pageSize,
  loadNextPage,
  fetchAlwaysNewData = false,
  isReverse = false,
  isLoading,
) => {
  const [from, setFrom] = useState(initialFrom);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const previousFrom = usePrevious(from);
  const skipLoadingFirstPage = !fetchAlwaysNewData && initialFrom > 0;

  useEffect(() => {
    // do not load first page data if it's already pulled
    if (skipLoadingFirstPage) return;

    // load most recent data
    loadNextPage?.(0, pageSize);
  }, []);

  useEffect(() => {
    setLoadingNextPage(false);
    // Do not load next page here if first page is just loaded
    if (!skipLoadingFirstPage && previousFrom === undefined) return;
    loadNextPage?.(from, pageSize);
  }, [from]);

  const handleScroll = (event) => {
    if (isLoading) return;

    const { target } = event;
    if (loadingNextPage) {
      return;
    }

    if (isReverse) {
      if (target.scrollTop === 0) {
        setLoadingNextPage(true);
        setFrom((previousFrom) => previousFrom + pageSize);
      }
      return;
    }

    // Special handling of case where list of documentation is empty (I noticed this upon switching up
    // the filters after scolling to the end).
    if (target.scrollHeight === target.clientHeight) {
      return;
    }

    // bottom reached
    if (target.scrollHeight - target.scrollTop - PAGINATION_DEVIATION <= target.clientHeight) {
      setLoadingNextPage(true);
      setFrom((previousFrom) => previousFrom + pageSize);
    }
  };

  const loadMore = () => {
    setFrom((previousFrom) => previousFrom + pageSize);
  };

  return { handleScroll, loadMore, from, setFrom };
};

export default usePagination;
