import { StatusesConfig } from 'common-src/models/PatientSupplement';

export const SourceOptions = [
  { value: true, label: 'Patient Statement' },
  { value: false, label: 'Medical Record' },
];

export const initialState = {
  name: undefined,
  dosageText: '',
  form: '',
  status: null,
  frequency: '',
  patientReported: SourceOptions[0],
  comments: '',
};

export const StatusesOptions = Object.values(StatusesConfig)
  .filter((status) => status.isSelectable && !status.isArchived)
  .map((config) => ({
    value: config.id,
    label: config.label,
  }));
