export const FamilyHistoryTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: FamilyHistoryTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: FamilyHistoryTabs.NOT_ACTIVE.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'name', label: 'Diagnosis', width: '35%', sortBy: 'text' },
  { column: 'comments', label: 'Comments', width: '20%', sortBy: 'text' },
  {
    column: 'relation',
    label: 'Relation',
    width: '13%',
    sortBy: 'text',
  },
  { column: 'createdAt', label: 'Date Added', width: '10%', sortBy: 'value' },
  { column: 'actions', label: 'Actions', width: '22%' },
];
