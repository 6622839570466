import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Checkbox } from 'common-src/components/base';

import styles from './FormHeader.module.scss';

const FormHeader = ({
  title,
  isExpanded,
  onClick,
  moduleType,
  primaryModuleTypeId,
  classNames = [],
  onChange = () => {},
  showPrimaryModuleCheckbox = false,
}) => (
  <div className={[styles.header, 'm-b-24', ...classNames].join(' ')}>
    <span className={[styles.title, 'font-w-700', 'font-s-18'].join(' ')}>{title}</span>
    <div className={['flex-row', 'flex-1', 'm-l-auto', 'gap-20', 'horizontally-end'].join(' ')}>
      {showPrimaryModuleCheckbox && (
        <Checkbox
          id={`${moduleType}-primary-reason`}
          checked={primaryModuleTypeId === moduleType}
          label="Mark as primary reason for visit"
          onChange={onChange}
        />
      )}
      <img
        className={[styles.toggleButton, isExpanded ? '' : styles.collapsed].join(' ')}
        src={CommonIcons.downIcon}
        alt="expand-collapse-btn"
        role="presentation"
        onClick={onClick}
      />
    </div>
  </div>
);

FormHeader.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  isExpanded: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  showPrimaryModuleCheckbox: PropTypes.bool,
  onChange: PropTypes.func,
  moduleType: PropTypes.number,
  primaryModuleTypeId: PropTypes.number,
};

export default FormHeader;
