import _ from 'lodash';

import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientPreAuths = (state, patientId) => {
  const coverages = allModelsSelector(
    state,
    'PatientCoverage',
    (coverage) => coverage.patientId === patientId,
  );

  const authArrays = _.map(coverages, (coverage) => coverage?.authorizations?.toModelArray() || []);

  // return flattened array of auths
  return _.reduce(
    authArrays,
    (result, key) => {
      result.push(...key);
      return result;
    },
    [],
  );
};
