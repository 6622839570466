import { getCurrentRoleId, getIsSuperUser } from 'common-src/features/auth';
import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

import { FeatureFlagState } from './constants';

export const getFeatureFlags = (state) => allModelsSelector(state, 'FeatureFlag');

export const isFeatureEnabled = (state, featureFlagType) => {
  const currentRoleId = getCurrentRoleId(state);
  const isSuperUser = getIsSuperUser(state);
  const featureFlag = singleModelSelector(state, 'FeatureFlag', featureFlagType);

  if (!featureFlag) {
    return false;
  }

  return (
    featureFlag.state === FeatureFlagState.Enabled ||
    (featureFlag.state === FeatureFlagState.AdminOnly && (currentRoleId === 1 || isSuperUser))
  );
};
