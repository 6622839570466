import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientSurgicalHistory extends Model {
  static modelName = 'PatientSurgicalHistory';

  static fields = {
    id: attr(),
    procedure: attr(),
    comments: attr(),
    dateOfSurgery: attr(),
    activeTill: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'patientSurgeryHistoryChanger'),
    createdBy: fk('Client', 'patientSurgeryHistoryCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'surgeryHistory',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      procedure: PropTypes.string,
      comments: PropTypes.string,
      dateOfSurgery: PropTypes.string,
      activeTill: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientSurgicalHistory;
