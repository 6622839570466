export const PreAuthsTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
  ALL: {
    text: 'All',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: PreAuthsTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: PreAuthsTabs.NOT_ACTIVE.text,
    count: 0,
  },
  ALL: {
    text: PreAuthsTabs.ALL.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'planName', label: 'Plan Name', width: '26%', sortBy: 'text' },
  { column: 'authCode', label: 'Pre Auth Code', width: '18%', sortBy: 'text' },
  { column: 'serviceType', label: 'Service Type', width: '14%', sortBy: 'text' },
  { column: 'status', label: 'Status', width: '10%', sortBy: 'text' },
  { column: 'startDate', label: 'Start Date', width: '11%', sortBy: 'value' },
  { column: 'endDate', label: 'End Date', width: '11%', sortBy: 'value' },
];
