import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button, Menu } from 'common-src/components/base';
import { getCurrentUserId } from 'common-src/features/auth';
import { deleteOrmItem } from 'common-src/features/rest';
import Patient from 'common-src/models/Patient';
import PatientInteraction, {
  deleteDraftApiRequest,
  getDocumentationById,
  InteractionStatus,
  InteractionType,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import { ColorsNew } from 'common-src/styles';
import { formatTime } from 'common-src/utils/dateUtils';

import Icons from 'src/assets/Icons';
import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import { PatientTabs, saveNoteData, saveNoteScroll, savePatientNote } from 'src/features/tabsState';
import { clearTimer as clearTimerAction } from 'src/features/timeTracker';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useNoteCreation from 'src/hooks/useNoteCreation';
import useTimeTracker from 'src/hooks/useTimeTracker';

import { MENU_PAPER_WIDTH } from './constants';
import { getSubOptions } from './helpers';
import styles from './NewNoteMenu.module.scss';

const NewNoteMenu = ({
  patientId,
  isNoteTabOpened,
  permissions,
  mainTab,
  noteData,
  noteId,
  patient,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const prevNote = useRef({});
  const prevNoteData = useRef({});

  const note = useCustomSelector((state) => getDocumentationById(state, noteId));
  const currentUserId = useCustomSelector((state) => getCurrentUserId(state));

  const notePermissions = permissions?.note;

  const { createNote } = useNoteCreation(patient);
  const { showAlert, AlertType } = useAlert();

  useEffect(() => {
    setTimeout(() => {
      prevNote.current = note || {};
    }, 0);
  }, [note]);

  useEffect(() => {
    prevNoteData.current = noteData;
  }, [noteData]);

  const redirectHandler = (forceRedirect) => {
    dispatch(savePatientNote(patientId, null));
    dispatch(saveNoteData(patientId, {}));
    dispatch(clearTimerAction(patientId));
    setTimeout(() => dispatch(saveNoteScroll(patientId, 0)), 100);

    if (mainTab.text === PatientTabs.Note.text || forceRedirect) {
      setTimeout(() => {
        navigate(`/dashboard-client/patients/details/${patientId}/info`);
      }, 0);
    }
  };

  const saveNoteDuration = (timer, withRedirect) => {
    const note = prevNote.current;
    if (_.isEmpty(note) || _.isEmpty(prevNoteData.current)) return;

    dispatch(
      updatePatientDraftApiRequest(
        note.id,
        {
          ...prevNoteData.current,
          status: InteractionStatus.Paused,
          duration: Math.max(note.duration, timer),
          clientId: currentUserId,
        },
        {
          successBlock: () => {
            if (!withRedirect) return;
            redirectHandler(true);
          },
        },
      ),
    );
  };

  const { timer, isTimerRunning, clearTimer } = useTimeTracker(
    patientId,
    note,
    isNoteTabOpened,
    mainTab,
    (timer, withRedirect) => saveNoteDuration(timer, withRedirect),
  );

  if (_.isEmpty(notePermissions)) return null;

  const errorHandler = (err) => {
    showAlert(AlertType.Error, '', err);
    setIsLoading(false);
  };

  const successHandler = (message) => {
    clearTimer();
    showAlert(AlertType.Success, '', message);
    setIsLoading(false);
    redirectHandler();
  };

  const endNoteOptions = _.compact([
    mainTab?.text !== PatientTabs.Note.text && {
      id: 'submit-note',
      text: 'Submit',
      iconSrc: CommonIcons.checkIcon,
      onClick: () => {
        navigate(`/dashboard-client/patients/details/${patientId}/note/${noteId}/edit`, {
          state: { forceSubmit: true },
        });
      },
    },
    {
      id: 'save-and-pause-note',
      text: 'Save and Pause',
      iconSrc: CommonIcons.editIcon,
      onClick: () => {
        setIsLoading(true);
        dispatch(
          updatePatientDraftApiRequest(
            noteId,
            {
              ...noteData,
              status: InteractionStatus.Paused,
              duration: timer,
              clientId: currentUserId,
            },
            {
              successBlock: () => successHandler('The note was successfully saved and paused!'),
              errorBlock: (err) => errorHandler(err),
            },
          ),
        );
      },
    },
    {
      id: 'delete-note',
      text: 'Delete',
      iconSrc: Icons.boldCloseIcon,
      onClick: () => {
        dispatch(
          openModal(ModalType.WARNING, {
            message:
              'Are you sure you want to delete this note? A draft will not be saved and no time will be recorded.',
            type: 'error',
            title: 'Delete Note?',
            onSubmit: () => {
              setIsLoading(true);
              dispatch(
                deleteDraftApiRequest(noteId, {
                  successBlock: () => {
                    deleteOrmItem(PatientInteraction.modelName, noteId);
                    successHandler('The note was successfully deleted!');
                  },
                  errorBlock: (err) => errorHandler(err),
                }),
              );
            },
          }),
        );
      },
    },
  ]);

  const newNoteOptions = Object.values(InteractionType)
    .filter((type) => type.isActive && Object.keys(notePermissions).includes(String(type.id)))
    .map((type) => ({
      ...type,
      label: (
        <div
          id={`option-${type.label}`}
          className={[styles.option, 'flex-row', 'vertically-centered', 'gap-6'].join(' ')}
        >
          <img className={styles.icon} src={type.iconSrc} alt="option-icon" />
          <span className={['font-s-14', 'font-w-500'].join(' ')}>{type.label}</span>
        </div>
      ),
      title: type.label,
      subOptions: getSubOptions(notePermissions, type, createNote),
      onClick: () => createNote(type.id, type.reasonId),
    }));

  const renderMenu = () => {
    if (isTimerRunning && note) {
      return (
        <Menu
          key="end-note-menu"
          button={
            <Button
              id="end-note-button"
              classNames={['width-100']}
              text={formatTime(timer)}
              rightIconSrc={Icons.pauseCircleIcon}
              backgroundColor={ColorsNew.mediumDarkRed}
              borderColor={ColorsNew.mediumDarkRed}
              isLoading={isLoading}
            />
          }
          options={endNoteOptions}
          paperWidth={MENU_PAPER_WIDTH}
          paperStyles={{ marginTop: '4px' }}
          paperAlignment="right"
        />
      );
    }

    return (
      <Menu
        key="new-note-menu"
        button={
          <Button
            id="new-note-button"
            classNames={['width-100']}
            text="New note"
            rightIconSrc={Icons.chevronDownCircleIcon}
          />
        }
        customOptions={newNoteOptions}
        paperWidth={MENU_PAPER_WIDTH}
        paperStyles={{ marginTop: '4px' }}
        paperAlignment="right"
        subOptionHoverColor={ColorsNew.lighterGreen}
        subPaperPosition="left"
      />
    );
  };

  return <>{renderMenu()}</>;
};

NewNoteMenu.propTypes = {
  patientId: PropTypes.number,
  isNoteTabOpened: PropTypes.bool,
  permissions: PropTypes.object,
  mainTab: PropTypes.object,
  noteData: PropTypes.object,
  noteId: PropTypes.string,
  patient: PropTypes.exact(Patient.schema),
};

export default NewNoteMenu;
