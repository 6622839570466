import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select, TextArea } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import PatientAllergy, {
  addAllergy,
  AllergyReaction,
  AllergySeverity,
  AllergyType,
  makeAllergyInactive,
  updateAllergy,
  validatePatientAllergy,
} from 'common-src/models/PatientAllergy';
import { getChangedValues } from 'common-src/utils/objectUtils';
import { validateStartEndDates } from 'common-src/utils/validationUtils';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './AllergyPopup.module.scss';
import { initialState, UPDATABLE_FIELDS } from './constants';
import { catchErrors, extractPatientAllergy, getIsButtonEnabled, getRequestBody } from './helpers';

const AllergyPopup = ({ patientId, allergy, onClose, open, isDuplicate = false }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const isEdit = useMemo(() => !!allergy?.id && !isDuplicate, [allergy?.id, isDuplicate]);

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!allergy) return;
    setData(extractPatientAllergy(allergy));
  }, [allergy]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback, shouldClose) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const updatedFields = Object.keys(getChangedValues(data, extractPatientAllergy(allergy)));
    const shouldEdit =
      !isDuplicate && _.isEmpty(updatedFields.filter((field) => !UPDATABLE_FIELDS.includes(field)));

    const requests = [];
    if (shouldEdit) {
      requests.push(updateAllergy(allergy.id, getRequestBody(data)));
    } else {
      requests.push(addAllergy(getRequestBody(data, patientId)));
      if (allergy && !isDuplicate) {
        requests.push(makeAllergyInactive(allergy.id));
      }
    }

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Allergy ${shouldEdit ? 'edited' : 'added'}!`);

          if (!shouldClose) {
            setData(initialState);
            setAutoFocus(true);
            return;
          }

          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatientAllergy(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDateError = (field, dateToValidate, dateToCompare) => {
    const error =
      field === 'startDate'
        ? validateStartEndDates(dateToValidate, dateToCompare)
        : validateStartEndDates(dateToCompare, dateToValidate);

    setErrors((prev) => ({
      ...prev,
      ...validatePatientAllergy(field, dateToValidate),
      ...error,
    }));
  };

  const onDateChangeHandler = (field, dateToValidate, dateToCompare) => {
    handleDateError(field, dateToValidate, dateToCompare);
    setData((prev) => ({ ...prev, [field]: dateToValidate }));
  };

  return (
    <BasePopup id="allergy" open={open} onClose={onClose} title="Add Allergy">
      <div className={styles.grid}>
        <Input
          id="name"
          placeholder="Enter name"
          label="Allergy Name"
          required
          value={data.name}
          errorText={errors.name}
          onTextChange={(value) => onChangeHandler('name', value)}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
        />
        <DatePickerNew
          id="start-date"
          header="Start Date"
          maxDate={new Date()}
          value={data.startDate}
          errorText={errors.startDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'startDate',
              date ? moment(date).format('YYYY-MM-DD') : null,
              data.endDate,
            )
          }
          onBlur={(date) =>
            onDateChangeHandler(
              'startDate',
              date ? moment(date).format('YYYY-MM-DD') : date,
              data.endDate,
            )
          }
        />
        <DatePickerNew
          id="end-date"
          header="End Date"
          value={data.endDate}
          errorText={errors.endDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'endDate',
              date ? moment(date).format('YYYY-MM-DD') : null,
              data.startDate,
            )
          }
          onBlur={(date) =>
            onDateChangeHandler(
              'endDate',
              date ? moment(date).format('YYYY-MM-DD') : date,
              data.startDate,
            )
          }
        />
        <Select
          id="type"
          classNames={[styles.width75]}
          label="Type"
          options={Object.values(AllergyType)}
          onChange={(op) => onChangeHandler('type', op)}
          value={data.type}
          required
          placeholder="Select type"
          errorText={errors.type}
          isSearchable
        />
        <Select
          id="reaction"
          label="Reaction"
          options={Object.values(AllergyReaction)}
          onChange={(op) => onChangeHandler('reaction', op)}
          value={data.reaction}
          required
          placeholder="Select reaction"
          errorText={errors.reaction}
          paperHeight={260}
        />
        <Select
          id="severity"
          label="Severity"
          options={Object.values(AllergySeverity)}
          onChange={(op) => onChangeHandler('severity', op)}
          value={data.severity}
          required
          placeholder="Select severity"
          errorText={errors.severity}
        />
        <TextArea
          id="comments"
          classNames={[styles.width100]}
          placeholder="Enter comments"
          value={data.comments}
          onTextChange={(value) => onChangeHandler('comments', value)}
          fixedRows={4}
          label="Comments"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, allergy, isDuplicate),
        submitButtonText: 'Save and Close',
        renderSaveButton: !isEdit,
      })}
    </BasePopup>
  );
};

AllergyPopup.propTypes = {
  patientId: PropTypes.number,
  allergy: PropTypes.exact(PatientAllergy.schema),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isDuplicate: PropTypes.bool,
};

export default AllergyPopup;
