const SdohModuleTypeId = 10101;

export const SocialIssuesOptions = [
  'Z59.00 Homelessness unspecified',
  'Z59.10 Inadequate housing unspecified',
  'Z59.41 Food insecurity',
  'Z75.3 Unavailability and inaccessibility of health care',
  'Z59.86 Financial insecurity',
  'Z59.82 Transportation insecurity',
  'Unspecified, other',
];

export const SocialIssueContent = {
  id: '451fc6c7-d19b-41f7-b8a8-c763ca0509d1',
  text: 'Social issue',
  type: 'multiSelect',
  label: 'Select an area of concern',
  placeholder: 'Select an area of concern',
  target: 'socialIssue',
  choices: SocialIssuesOptions,
  value: [],
  required: false,
};

export const SdohContent = {
  id: '99a8cdd5-184d-4e5d-9975-5a2927a719ca',
  moduleType: SdohModuleTypeId,
  title: 'Social Determinants of Health (SDOH)',
  instructions:
    'Based on your conversation with the patient, are there any areas of SDOH that should be reviewed or potentially addressed by a case worker?',
  version: 1,
  isExpanded: true,
  isSelected: true,
  sections: [
    {
      id: 'c15de701-203a-497b-b036-595d7548f275',
      contents: [
        SocialIssueContent,
        {
          id: '9e949b9e-9c3a-4aec-9848-ae2935c31563',
          text: 'Patient interested in discussing with case worker?',
          type: 'radio',
          orientation: 'row',
          target: 'caseWorker',
          choices: ['Yes', 'No', 'Not Sure'],
          required: false,
        },
        {
          id: 'c9ad2d11-6570-458d-a784-aa9e3240a57c',
          text: 'Additional details:',
          type: 'textArea',
          required: false,
          maxChars: 512,
          placeholder: 'Provide additional details for the Case Worker to review',
        },
      ],
    },
  ],
};
