import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getCoverageHierarchyRequest = (query, params) =>
  restRequest({
    uri: 'patientCoverageHierarchy',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientCoverageHierarchy = (body, params) =>
  restRequest({
    uri: 'patientCoverageHierarchy',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientCoverageHierarchy = (body, params) =>
  restRequest({
    uri: 'patientCoverageHierarchy',
    method: HttpMethods.Put,
    body,
    ...params,
  });
