import {
  GOOGLE_SSO_LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_COMPLETE,
  LOGOUT_ERROR,
  LOGOUT_REQUEST,
} from './actionTypes';

export const loginRequest = ({
  username,
  password,
  userCategoryId,
  usernameType,
  successBlock,
  errorBlock,
  offlineBlock,
}) => ({
  type: LOGIN_REQUEST,
  payload: {
    username,
    password,
    userCategoryId,
    usernameType,
    successBlock,
    errorBlock,
    offlineBlock,
  },
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginError = (errorMessage) => ({
  type: LOGIN_ERROR,
  payload: { errorMessage },
});

export const googleSsoRequest = ({ token, successBlock, errorBlock }) => ({
  type: GOOGLE_SSO_LOGIN_REQUEST,
  payload: {
    token,
    successBlock,
    errorBlock,
  },
});

// TODO backendv2: implement logout request
export const logOutRequest = ({
  successBlock,
  errorBlock,
  offlineBlock,
  isSessionExpired,
} = {}) => ({
  type: LOGOUT_REQUEST,
  payload: {
    successBlock,
    errorBlock,
    offlineBlock,
    isSessionExpired,
  },
});

export const logOut = () => ({
  type: LOGOUT,
});

export const logOutError = () => ({
  type: LOGOUT_ERROR,
});

export const logoutComplete = () => ({
  type: LOGOUT_COMPLETE,
});
