import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { getFilteredDrafts, getPatientDrafts } from 'common-src/models/PatientInteraction';

import { SeparatorLine } from 'src/components/elements';
import { TabsViewNew } from 'src/components/tabs';
import { getPatientNote } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useDocumentationTabInfo from 'src/hooks/useDocumentationTabInfo';

import { DocumentationTabs } from './constants';
import styles from './DocumentationFragment.module.scss';
import { SubHeader } from './headers';
import { getTabInfo } from './helpers';
import { DocumentationTab, DraftsTab } from './tabs';

const DocumentationFragment = ({ patientId }) => {
  const tabs = Object.keys(DocumentationTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tabInfo, setTabInfo] = useState(DocumentationTabs);

  const drafts = useCustomSelector((state) => getPatientDrafts(state, patientId));
  const noteId = useCustomSelector((state) => getPatientNote(state, patientId));

  const { updatePrevTabInfo, filters, setFilters, selectedDocument, setSelectedDocument } =
    useDocumentationTabInfo(patientId);

  const filteredDrafts = useMemo(
    () => getFilteredDrafts(drafts, filters, noteId),
    [drafts, filters, noteId],
  );

  useEffect(() => {
    setTabInfo(getTabInfo(filteredDrafts));
  }, [filteredDrafts]);

  const renderTab = () => {
    switch (selectedTab) {
      case tabs[0]:
        return (
          <DocumentationTab
            patientId={patientId}
            filters={filters}
            selectedDocument={selectedDocument}
            updatePrevTabInfo={updatePrevTabInfo}
            setSelectedDocument={setSelectedDocument}
          />
        );
      case tabs[1]:
        return (
          <DraftsTab
            drafts={filteredDrafts}
            patientId={patientId}
            filters={filters}
            updatePrevTabInfo={updatePrevTabInfo}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SeparatorLine />
      <TabsViewNew
        classNames={[styles.tabs, 'gap-24']}
        onTabSelected={setSelectedTab}
        options={tabInfo}
      />
      <SeparatorLine />
      <SubHeader filters={filters} setFilters={setFilters} selectedTab={selectedTab} />
      <SeparatorLine />
      <div className={styles.wrapper}>{renderTab()}</div>
    </>
  );
};

DocumentationFragment.propTypes = {
  patientId: PropTypes.number,
};

export default DocumentationFragment;
