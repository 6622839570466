export const PopupTabs = {
  GRAPH: {
    id: 0,
    text: 'Graph',
  },
  TABLE: {
    id: 1,
    text: 'Table',
  },
};
