import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { convertJSXToHTML } from 'common-src/utils/domUtils';

import styles from './HighlightedText.module.scss';

const HighlightedText = ({ text, textToHighlight, className }) => {
  // Escape any special regex characters in the textToHighlight
  const escaped = textToHighlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(`(${escaped})`, 'gi');

  const getTextComponents = () => {
    if (typeof text === 'object') {
      const element = convertJSXToHTML(text);

      return Array.from(element.childNodes).map((c, i) => {
        const TagName = (c.tagName || element.tagName).toLowerCase();
        const parts = c.textContent.split(regex);

        return (
          <TagName key={i}>
            {parts.map((part, i) => {
              if (regex.test(part.toLowerCase())) {
                return (
                  <span key={i} className={styles.highlighted}>
                    {part}
                  </span>
                );
              }

              return part;
            })}
          </TagName>
        );
      });
    }

    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (regex.test(part.toLowerCase())) {
        return (
          <span key={index} className={styles.highlighted}>
            {part}
          </span>
        );
      }

      return part;
    });
  };

  if (_.isEmpty(textToHighlight)) {
    return <div className={className}>{text}</div>;
  }

  return <div className={className}>{getTextComponents()}</div>;
};

HighlightedText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  textToHighlight: PropTypes.string,
  className: PropTypes.string,
};

export default HighlightedText;
