import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientLabReading extends Model {
  static modelName = 'PatientLabReading';

  static fields = {
    id: attr(),
    typeId: attr(),
    readingValue: attr(),
    readingDate: attr(),
    patientReported: attr(),
    status: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'patientLabReadingChanger'),
    createdBy: fk('Client', 'patientLabReadingCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientLabReadings',
    }),
  };

  get source() {
    return this.patientReported ? 'Patient Statement' : 'Medical Record';
  }

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      typeId: PropTypes.number,
      readingValue: PropTypes.number,
      readingDate: PropTypes.string,
      patientReported: PropTypes.bool,
      status: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientLabReading;
