// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PatientDemographics-module__grid___gHnnj {
  display: grid;
  row-gap: 16px;
  column-gap: 50px;
  margin: 16px 7px;
  grid-template-columns: repeat(3, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/KnownPlans/forms/PatientDemographics/PatientDemographics.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,qCAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `PatientDemographics-module__grid___gHnnj`
};
module.exports = ___CSS_LOADER_EXPORT___;
