import { createRoleConfigPayload } from 'common-src/features/auth/helpers';
import { entityHandlers, initialState } from 'common-src/features/auth/reducer';
import { createReducer } from 'common-src/utils/reducerUtils';

import { STORAGE_KEY_AUTH_STATUS } from 'src/constants/storageKeys';

const persistedState = localStorage.getItem(STORAGE_KEY_AUTH_STATUS);

const userInfo = JSON.parse(persistedState);

export const state = persistedState
  ? {
      ...userInfo,
      roleConfig: createRoleConfigPayload(userInfo?.roleId, userInfo?.isSuper),
      isLoggedIn: true,
    }
  : initialState;

export const reducer = createReducer(state, entityHandlers);
