import moment from 'moment-timezone';

import { activeStatusesIds } from 'common-src/models/PatientSupplement';

import { SupplementsTabs } from './constants';

export const getTabInfo = (supplements = []) => {
  const activeSupplements = [];
  const inactiveSupplements = [];

  [...supplements]
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .forEach((supplement) => {
      if (
        (supplement.activeTill && moment(supplement.activeTill).isBefore(moment.now())) ||
        !activeStatusesIds.includes(supplement?.status)
      ) {
        inactiveSupplements.push(supplement);
        return;
      }

      activeSupplements.push(supplement);
    });

  const tabInfo = {
    ACTIVE: {
      text: SupplementsTabs.ACTIVE.text,
      count: activeSupplements.length,
    },
    NOT_ACTIVE: {
      text: SupplementsTabs.NOT_ACTIVE.text,
      count: inactiveSupplements.length,
    },
  };

  return {
    tabInfo,
    activeSupplements,
    inactiveSupplements,
  };
};
