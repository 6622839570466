import _ from 'lodash';

import {
  getPharmaciesRequests,
  validatePatientPreferredPharmacy,
} from 'common-src/models/PatientPreferredPharmacy';

const defaultAddressValues = {
  city: '',
  state: '',
  street1: '',
  street2: '',
  zipcode: '',
};

export const extractPharmacy = (pharmacy) => ({
  id: pharmacy.id,
  name: pharmacy.name,
  phone: pharmacy.phone,
  address: _.isEmpty(pharmacy.address) ? defaultAddressValues : pharmacy.address,
  patientId: pharmacy.patientId,
});

export const getInitialPharmacyData = (patientId) => ({
  patientId,
  name: '',
  phone: '',
  address: defaultAddressValues,
});

export const catchErrors = (formData) => {
  const errors = {};
  formData.forEach((x, i) => {
    if (!x.patientId) return;
    Object.entries(x).forEach(([field, value]) => {
      if (field === 'address') {
        Object.entries(value).forEach(([addressField, addressValue]) => {
          const key = `address.${addressField}`;
          const err = validatePatientPreferredPharmacy(key, addressValue);
          if (err[key]) {
            Object.assign(errors, { [`${i}.${key}`]: err[key] });
          }
        });

        return;
      }

      const err = validatePatientPreferredPharmacy(field, value);
      if (err[field]) {
        Object.assign(errors, { [`${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRequests = (formData, pharmacies) => {
  const requests = [];

  const oldPharmacies = pharmacies.map((pharmacy) => extractPharmacy(pharmacy));
  if (!_.isEqual(formData, oldPharmacies)) {
    requests.push(...getPharmaciesRequests(oldPharmacies, formData));
  }

  return requests;
};
