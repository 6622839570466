import PropTypes from 'prop-types';
import React from 'react';

import { CustomCheckbox, Select } from 'common-src/components/base';
import { HistoryEventTypes } from 'common-src/models/PatientHistoryEvent';

import Icons from 'src/assets/Icons';
import { initialFilters } from 'src/fragments/TimelineFragment/constants';

import styles from './Header.module.scss';

const Header = ({ filters, setFilters }) => {
  const checkboxHandler = (field, value) => {
    setFilters({
      ...initialFilters,
      [field]: value,
    });
  };

  const isSelectEnabled = !filters.alertsOnly && !filters.messagesOnly && !filters.appointmentsOnly;

  return (
    <div className={[styles.header, 'vertically-centered', 'gap-12'].join(' ')}>
      <Select
        id="event-types-select"
        options={Object.values(HistoryEventTypes)}
        placeholder="Filter Events"
        onChange={(types) => setFilters((prev) => ({ ...prev, types }))}
        value={filters.types}
        iconSrc={Icons.filterIcon}
        multiple
        disabled={!isSelectEnabled}
        isClearable
        isSearchable
      />
      <CustomCheckbox
        id="alerts-only-chechbox"
        header="Alerts only"
        checked={filters.alertsOnly}
        onChange={(value) => checkboxHandler('alertsOnly', value)}
      />
      <CustomCheckbox
        id="messages-only-chechbox"
        header="Messages only"
        checked={filters.messagesOnly}
        onChange={(value) => checkboxHandler('messagesOnly', value)}
      />
      <CustomCheckbox
        id="appointments-only-chechbox"
        header="Appointments only"
        checked={filters.appointmentsOnly}
        onChange={(value) => checkboxHandler('appointmentsOnly', value)}
      />
    </div>
  );
};

Header.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default Header;
