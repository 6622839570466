import { CGMProducts } from 'common-src/models/PatientCgmOrder';

export const initialState = {
  product: null,
  supplier: null,
  status: null,
  orderedAt: null,
  sentAt: null,
  shippedAt: null,
  notes: '',
};

export const productOptions = Object.values(CGMProducts);
