export const CgmAlerts = Object.freeze({
  UrgentLow: 'Urgent Low 55 mg/dl (*Can be turned off on Libre 2)',
  UrgentLowSoon: 'Urgent low soon',
  LowGlucoseAlert: 'Low glucose alert',
  HighGlucoseAlert: 'High glucose alert',
  RiseRate: 'Rise rate',
  SignalLoss:
    'Signal loss (Libre 2 alerts in 5 minutes, Dexcom 6 in 20 minutes, you can adjust time for Dexcom 7 and Libre 3)',
  NoReadingAlert: 'No reading alert (Dexcom)',
  BriefSensorIssue: 'Brief sensor issue (Dexcom 7)',
});

export const initialCgmAlertsState = {
  alerts: [],
  urgentLowSoonSetAt: '',
  lowGlucoseAlertSetAt: '',
  highGlucoseAlertSetAt: '',
  riseRate: [],
};

export const riseRateOptions = ['2-3 mg/dL per minute', '3 mg/dL or more per minute'];
