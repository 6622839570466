import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import PatientLabReading from 'common-src/models/PatientLabReading';

import styles from './TableTab.module.scss';

const TableTab = ({ results = [] }) => {
  const sortedResults = useMemo(
    () => results.sort((a, b) => new Date(b?.readingDate) - new Date(a?.readingDate)),
    [results],
  );

  return (
    <>
      <div className={[styles.grid, 'font-w-600', 'm-b-10'].join(' ')}>
        <span>Resulted Date</span>
        <span>Value</span>
        <span>Source</span>
      </div>
      <div className={styles.scroll}>
        {sortedResults.map(({ id, readingDate, readingValue, source }) => (
          <div key={id} id={`result-${id}`} className={[styles.grid, 'm-b-6'].join(' ')}>
            <span>{moment(readingDate).format('MM/DD/YYYY')}</span>
            <span>{readingValue}</span>
            <span>{source}</span>
          </div>
        ))}
      </div>
    </>
  );
};

TableTab.propTypes = {
  results: PropTypes.arrayOf(PropTypes.exact(PatientLabReading.schema)),
};

export default TableTab;
