export const defaultState = {
  isBillable: null,
  billingCategory: null,
  duration: undefined,
  hadLiveInteraction: null,
  reason: undefined,
};

export const editableFields = ['isBillable', 'billingCategory', 'duration', 'hadLiveInteraction'];

export const booleanFields = ['isBillable', 'hadLiveInteraction'];

export const textFields = ['billingCategory', 'reason'];
