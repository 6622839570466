export const MedicationsTabs = Object.freeze({
  ACTIVE: {
    text: 'Active',
  },
  NOT_ACTIVE: {
    text: 'Not Active',
  },
  ALL: {
    text: 'All',
  },
});

export const DefaultTabInfo = Object.freeze({
  ACTIVE: {
    text: MedicationsTabs.ACTIVE.text,
    count: 0,
  },
  NOT_ACTIVE: {
    text: MedicationsTabs.NOT_ACTIVE.text,
    count: 0,
  },
  ALL: {
    text: MedicationsTabs.ALL.text,
    count: 0,
  },
});

export const TableHeaders = [
  { column: 'name', label: 'Medication Name', width: '30%', sortBy: 'title' },
  { column: 'source', label: 'Source', width: '13%', sortBy: 'value' },
  { column: 'createdAt', label: 'Date Written', width: '10%', sortBy: 'value' },
  { column: 'filledDate', label: 'Date Filled', width: '10%', sortBy: 'value' },
  { column: 'status', label: 'Status', width: '15%', sortBy: 'text' },
  { column: 'actions', label: 'Actions', width: '22%' },
];

export const Filters = {
  showOnlyImported: false,
};
