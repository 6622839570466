import { attr, fk, Model } from 'redux-orm';

class Icd10Code extends Model {
  static modelName = 'Icd10Code';

  static fields = {
    code: attr(),
    description: attr(),
    friendlyName: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'icd10CodeCreator'),
  };

  static options = {
    idAttribute: 'code',
  };
}

export default Icd10Code;
