import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { CircleLoader } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';
import { getScrollableParent } from 'common-src/utils/domUtils';

import { TableEmptyView } from 'src/components/elements';

import styles from './BaseTable.module.scss';

const BaseTable = ({
  data = [],
  headers = [],
  classNames = [],
  name = 'unknown',
  trClassNames = [],
  paginationData = {},
  emptyClassNames = [],
  emptyText = 'No data',
  initialSortColumn = null,
  isLoading = false,
  onSort = null,
}) => {
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [sortDirection, setSortDirection] = useState('desc');
  const [hasScroll, setHasScroll] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    if (!tableRef.current) return;

    const scrollableParent = getScrollableParent(tableRef.current);
    const parentScrollHeight = scrollableParent.scrollHeight;
    const parentOffsetHeight = scrollableParent.offsetHeight;
    setHasScroll(parentScrollHeight !== parentOffsetHeight);
  }, [tableRef.current, data.length]);

  const handleSort = (header) => {
    if (!header?.sortBy) return;

    const newDirection =
      header?.column === sortColumn?.column ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc';

    setSortDirection(newDirection);

    if (header?.column !== sortColumn?.column) {
      setSortColumn(header);
    }

    if (onSort) {
      onSort({ column: header?.column, direction: newDirection });
    }
  };

  const sortedData = useMemo(() => {
    if (onSort || sortColumn === null) return data;

    return [...data]
      .sort((a, b) => {
        const sortFields = sortColumn.sortBy.split('.');
        const valueToSort = (accumulator) =>
          _.reduce(
            sortFields,
            (result, sortField) => (typeof result === 'object' ? result?.[sortField] : result),
            accumulator,
          );

        const aValue = valueToSort(
          typeof a?.[sortColumn.column] === 'object'
            ? a?.[sortColumn.column]?.props
            : a?.[sortColumn.column],
        );
        const bValue = valueToSort(
          typeof b?.[sortColumn.column] === 'object'
            ? b?.[sortColumn.column]?.props
            : b?.[sortColumn.column],
        );

        const firstValue = aValue ? String(aValue).toLowerCase() : aValue === 0 ? '-0' : '';
        const secondValue = bValue ? String(bValue).toLowerCase() : bValue === 0 ? '-0' : '';

        if (sortDirection === 'asc') {
          return firstValue.localeCompare(secondValue);
        }

        return secondValue.localeCompare(firstValue);
      })
      .slice(paginationData.from, paginationData.to);
  }, [data, sortColumn, sortDirection, paginationData]);

  const renderHeaders = () =>
    headers?.map((header) => (
      <th
        id={`${header.column}-header`}
        key={header.column}
        className={[styles.th, 'vertically-centered', 'font-w-400'].join(' ')}
        style={{ width: header.width }}
        onClick={() => handleSort(header)}
      >
        {header.label}
        {header.filter && header.filter}
        {!header.filter && sortColumn?.column === header?.column && (
          <img
            className={styles.sortBtn}
            style={{
              transform: sortDirection === 'asc' ? 'rotate(-180deg)' : 'rotate(0deg)',
            }}
            src={CommonIcons.sortDownIcon}
            alt="sort-btn"
          />
        )}
      </th>
    ));

  const renderContent = () => {
    switch (true) {
      case isLoading:
        return (
          <tbody className="flex-row">
            <tr className={['flex-row', 'flex-1', 'm-t-20'].join(' ')}>
              <td className="width-100">
                <CircleLoader color={ColorsNew.darkGreen} strokeWidth={2} circleRadius={12} />
              </td>
            </tr>
          </tbody>
        );
      case _.isEmpty(sortedData):
        return <TableEmptyView text={emptyText} classNames={emptyClassNames} />;
      default:
        return (
          <tbody className={[styles.tbody, 'flex-column'].join(' ')}>
            {sortedData.map((row, index, arr) => (
              <tr
                key={index.toString()}
                className={[
                  styles.tr,
                  _.isEmpty(paginationData) &&
                  hasScroll &&
                  arr.length > 1 &&
                  arr.length - 1 === index
                    ? ''
                    : 'primary-border-b',
                  'font-w-500',
                  'font-s-14',
                  ...trClassNames,
                ].join(' ')}
              >
                {headers.map((header) => (
                  <td
                    id={`${header.column}-${index.toString()}-data`}
                    key={`${index.toString()}-${header.column}`}
                    className={styles.td}
                    style={{ width: header.width }}
                  >
                    {row[header.column]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        );
    }
  };

  return (
    <div
      id={`${name}-table`}
      className={[styles.container, ...classNames].join(' ')}
      ref={tableRef}
    >
      <table className={['flex-column', 'primary-border-t'].join(' ')}>
        <thead className={[styles.thead, 'primary-border-b'].join(' ')}>
          <tr className={[styles.tr, 'font-w-500', 'font-s-14'].join(' ')}>{renderHeaders()}</tr>
        </thead>
        {renderContent()}
      </table>
    </div>
  );
};

BaseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  emptyText: PropTypes.string,
  emptyClassNames: PropTypes.arrayOf(PropTypes.string),
  initialSortColumn: PropTypes.any,
  isLoading: PropTypes.bool,
  onSort: PropTypes.func,
  paginationData: PropTypes.object,
  trClassNames: PropTypes.arrayOf(PropTypes.string),
};

export default BaseTable;
