/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import 'react-responsive-modal/styles.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-responsive-modal';

import { CommonIcons } from 'common-src/assets/Icons';

import moduleStyles from './BasePopup.module.scss';
import styles from './styles';

const BasePopup = ({
  children,
  open,
  id,
  title,
  subtitle,
  customStyle = {},
  onClose = () => null,
  closeOnOverlayClick = true,
  showCloseIcon = true,
}) => {
  const renderHeader = () => {
    if (!title) return null;

    return (
      <div
        className={[moduleStyles.titleContainer, 'vertically-centered', 'primary-border-b'].join(
          ' ',
        )}
      >
        <span
          id={`${id}-popup-title`}
          className={[moduleStyles.title, 'font-w-700', 'font-s-22'].join(' ')}
        >
          {title}
        </span>
        {subtitle && (
          <span className={[moduleStyles.subtitle, 'font-w-500', 'm-l-20', 'font-s-14'].join(' ')}>
            {subtitle}
          </span>
        )}
        {showCloseIcon && (
          <img
            className={['icon-btn', 'm-l-auto'].join(' ')}
            src={CommonIcons.closeIcon}
            alt="close-modal-btn"
            onClick={onClose}
            role="presentation"
          />
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      focusTrapped={false}
      closeOnOverlayClick={closeOnOverlayClick}
      styles={{
        modal: { ...styles.root, ...customStyle },
      }}
    >
      <section
        id={id ? `${id}-popup` : 'base-popup'}
        className={[moduleStyles.container, 'flex-column'].join(' ')}
        onClick={(e) => e.stopPropagation()}
      >
        {renderHeader()}
        {children}
      </section>
    </Modal>
  );
};

BasePopup.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  customStyle: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  closeOnOverlayClick: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
};

export default BasePopup;
