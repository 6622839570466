import _ from 'lodash';
import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

import { getTimeZone } from 'common-src/utils/dateUtils';

import { PlatformStatus } from './constants';

class Patient extends Model {
  static modelName = 'Patient';

  static fields = {
    id: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    firstName: attr(),
    middleName: attr(),
    lastName: attr(),
    suffix: attr(),
    email: attr(),
    dob: attr(),
    mbiNumber: attr(),
    platformStatus: attr(),
    platformStatusAt: attr(),
    snoozeAt: attr(),
    holdExpiresAt: attr(),
    holdStartsAt: attr(),
    approvedAt: attr(),
    onboardingStatus: attr(),
    insuredFirstName: attr(),
    insuredLastName: attr(),
    riskLevel: attr(),
    assignedCoachId: attr(),
    assignedDietitianId: attr(),
    assignedCDCESId: attr(),
    assignedLcswId: attr(),
    address: attr(),
    shippingAddress: attr(),
    sex: attr(),
    notes: attr(),
    glucoseStripCount: attr(),
    preferredLanguage: attr(),
    readingFrequency: attr(),
    shouldShipToHomeAddress: attr(),
    testingOrders: attr(),
    additionalTestingInfo: attr(),
    hasInsulinPump: attr(),
    shouldSendDailyReminders: attr(),
    shouldSendLowEngagement: attr(),
    shouldSendVisitReminders: attr(),
    preferredName: attr(),
    isCGM: attr(),
    isBGM: attr(),
    isTranstek: attr(),
    cgmType: attr(),
    cgmModel: attr(),
    terraLastAuthAt: attr(),
    dexcomLastAuthAt: attr(),
    planType: attr(),
    bgmHypoglycemicThreshold: attr(),
    bgmHyperglycemicThreshold: attr(),
    tobaccoUse: attr(),
    alcoholUse: attr(),
    recreationalDrugUse: attr(),
    tobaccoAlcoholDrugNotes: attr(),
    employmentStatus: attr(),
    housing: attr(),
    employmentHousingNotes: attr(),
    religion: attr(),
    scriptsureLastPullAt: attr(),
    scriptsureId: attr(),
    prescriptionConsent: attr(),
    wellnessPlan: attr(),
    wellnessPlanUpdatedAt: attr(),
    race: attr(),
    gender: attr(),
    leadSource: attr(),
    mfaApprovedAt: attr(),
    orgId: fk({
      to: 'Organization',
      as: 'organization',
      relatedName: 'patients',
    }),
  };

  get fullName() {
    return `${this.firstName + (this.middleName ? ` ${this.middleName.charAt(0)}.` : '')} ${
      this.lastName
    }${this.suffix ? ` ${this.suffix}` : ''}`;
  }

  get isActive() {
    return this.platformStatus === PlatformStatus.Active;
  }

  get isOnHold() {
    return this.platformStatus === PlatformStatus.Hold;
  }

  get isInactive() {
    return this.platformStatus === PlatformStatus.Gray;
  }

  get isClosed() {
    return this.platformStatus === PlatformStatus.Closed;
  }

  get canBeClosed() {
    return this.isActive || this.isOnHold || this.isInactive;
  }

  get bgmType() {
    return this.isTranstek ? 'Transtek' : 'Biotel';
  }

  getName() {
    if (this.preferredName) {
      return `${this.preferredName} ${this.lastName}`;
    }

    return `${this.firstName} ${this.lastName}`;
  }

  get hasAddress() {
    return !_.isEmpty(this.address);
  }

  get timeZone() {
    return (
      getTimeZone(this.address?.zipcode, this.address?.state) ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      suffix: PropTypes.string,
      email: PropTypes.string,
      dob: PropTypes.string,
      mbiNumber: PropTypes.string,
      platformStatus: PropTypes.number,
      platformStatusAt: PropTypes.string,
      snoozeAt: PropTypes.string,
      holdExpiresAt: PropTypes.string,
      holdStartsAt: PropTypes.string,
      approvedAt: PropTypes.string,
      onboardingStatus: PropTypes.number,
      insuredFirstName: PropTypes.string,
      insuredLastName: PropTypes.string,
      riskLevel: PropTypes.number,
      assignedCoachId: PropTypes.number,
      assignedDietitianId: PropTypes.number,
      assignedCDCESId: PropTypes.number,
      assignedLcswId: PropTypes.number,
      address: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        zipcode: PropTypes.string,
      }),
      shippingAddress: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        zipcode: PropTypes.string,
      }),
      sex: PropTypes.string,
      notes: PropTypes.string,
      glucoseStripCount: PropTypes.number,
      preferredLanguage: PropTypes.string,
      readingFrequency: PropTypes.string,
      shouldShipToHomeAddress: PropTypes.bool,
      testingOrders: PropTypes.string,
      additionalTestingInfo: PropTypes.string,
      hasInsulinPump: PropTypes.bool,
      shouldSendDailyReminders: PropTypes.bool,
      shouldSendLowEngagement: PropTypes.bool,
      shouldSendVisitReminders: PropTypes.bool,
      preferredName: PropTypes.string,
      isCGM: PropTypes.bool,
      isBGM: PropTypes.bool,
      isTranstek: PropTypes.bool,
      cgmType: PropTypes.string,
      cgmModel: PropTypes.string,
      terraLastAuthAt: PropTypes.string,
      dexcomLastAuthAt: PropTypes.string,
      planType: PropTypes.string,
      bgmHypoglycemicThreshold: PropTypes.number,
      bgmHyperglycemicThreshold: PropTypes.number,
      tobaccoUse: PropTypes.number,
      alcoholUse: PropTypes.number,
      recreationalDrugUse: PropTypes.number,
      tobaccoAlcoholDrugNotes: PropTypes.string,
      employmentStatus: PropTypes.number,
      housing: PropTypes.number,
      employmentHousingNotes: PropTypes.string,
      religion: PropTypes.number,
      scriptsureLastPullAt: PropTypes.string,
      scriptsureId: PropTypes.number,
      prescriptionConsent: PropTypes.bool,
      wellnessPlan: PropTypes.string,
      wellnessPlanUpdatedAt: PropTypes.string,
      race: PropTypes.number,
      gender: PropTypes.number,
      leadSource: PropTypes.string,
      orgId: PropTypes.number,
      mfaApprovedAt: PropTypes.string,
    }),
    orgId: PropTypes.number,
  };
}

export default Patient;
